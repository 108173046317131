<!-- table -->
<div class="table-responsive">
  <table class="table table-hover">
    <!-- table header -->
    <thead>
      <tr>
        <th class="col-2 align-middle" scope="col"><p class="table-header-title">{{ 'BANK.formLabels.bankLogo' | translate }}</p></th>
        <th class="col-4 align-middle" scope="col"><p class="table-header-title">{{ 'BANK.formLabels.bankName' | translate }}</p></th>
        <th class="col-3 align-middle" scope="col"><p class="table-header-title">{{ 'BANK.formLabels.bankCountries' | translate }}</p></th>
        <th class="col-2 align-middle text-center" scope="col"><p class="table-header-title">{{ 'STATUSES.status' | translate }}</p></th>
        <th class="col-1 align-middle" scope="col"></th>
      </tr>
    </thead>
    <!-- table body -->
    <tbody>
      <tr *ngFor="let bank of banks">
        <td class="col-2 align-middle table-user" scope="col">
          <div class="media">
            <div class="media-left">
              <img class="media-object rounded-circle" [src]="bank.bankLogo" [alt]="bank.bank" onError="src = 'assets/images/broken-image-slim.png'">
            </div>
          </div>
        </td>
        <td class="col-4 align-middle" scope="col">{{ bank.bankName }}</td>

        <td class="col-3 text-center align-middle table-user" scope="col">
          <div class="banks-container" style="--z-index-start: 1;">
            <div class="media small-media bank-item" *ngFor="let country of bank.bankCountries">
              <div class="media-left">
                <img class="media-object rounded-circle" [src]="country.countryFlag" [alt]="country.countryName" onError="src = 'assets/images/broken-avatar.png'">
              </div>
            </div>
          </div>
        </td>

        <td class="col-2 align-middle text-center">
          <p class="table-status-txt">
            <span class="status-approved" *ngIf="bank.bankStatus === true">{{ 'STATUSES.active' | translate }}</span>
            <span class="status-rejected" *ngIf="bank.bankStatus === false">{{ 'STATUSES.unactive' | translate }}</span>
          </p>
        </td>
        <td class="col-1 align-middle">
          <a class="btn btn-dark btn-xs btn-icon-only" (click)="editBank(bank)">
            <app-feather-icons [icon]="'edit-3'"></app-feather-icons>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>