import { Injectable } from '@angular/core';
declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor() { }
  showHidenModal(modal: any, option: any): void {
    if (option == true) {
      $('#' + modal).modal('show');
    } else {
      $('#' + modal).modal('hidden');
      $('body').removeClass('modal-open');
      $('.modal-backdrop').remove();
    }
  }
}
