<!-- table -->
<div class="table-responsive">
  <table class="table table-hover">
    <!-- table header -->
    <thead>
      <tr>
        <th class="col align-middle" scope="col"><p class="table-header-title">{{ 'LOAN.formLabels.loanNumber' | translate }}</p></th>
        <th class="col align-middle text-center" scope="col">
          <p class="table-header-title">{{ 'PRODUCT.product' | translate }}</p>
        </th>
        <th class="col align-middle" scope="col" *ngIf="!isClient">
          <p class="table-header-title" *ngIf="isAdmin || isBoard || isBusinessAgent || isTreasure">{{ 'LOAN.formLabels.loanClient' | translate }}</p>
          <p class="table-header-title" *ngIf="isCompanyAdmin || isCompanyAuxiliar">{{ 'LOAN.loanCompany' | translate }}</p>
        </th>
        <th class="col align-middle text-center" scope="col">
          <p class="table-header-title">{{ 'LOAN.formLabels.loanAmount' | translate }}</p>
          <p class="table-header-explainer">{{ 'LOAN.formLabels.loanRequested' | translate }} / {{ 'LOAN.formLabels.loanApproved' | translate }}</p>
        </th>
        <th class="col align-middle text-center" scope="col">
          <p class="table-header-title">{{ 'LOAN.formLabels.loanPaymentMonths' | translate }}</p>
          <p class="table-header-explainer">{{ 'LOAN.formLabels.loanRequested' | translate }} / {{ 'LOAN.formLabels.loanApproved' | translate }}</p>
        </th>
        <th class="col align-middle text-center" scope="col"><p class="table-header-title">{{ 'LOAN.formLabels.loanPayedPercentage' | translate }}</p></th>
        <th class="col align-middle text-center" scope="col"><p class="table-header-title">{{ 'LOAN.formLabels.loanDebtDays' | translate }}</p></th>
        <th class="col align-middle text-center" scope="col"><p class="table-header-title">{{ 'STATUSES.status' | translate }}</p></th>
      </tr>
    </thead>
    <!-- table body -->
    <tbody>
      <tr *ngFor="let loan of loans">
        <td class="col align-middle item-number" scope="col" (click)="openLoan(loan)">{{ loan.loanNumber }}</td>
        <td class="col align-middle" scope="col">{{ loan.productId.productName }}</td>
        <td class="col align-middle table-user" scope="col" *ngIf="!isClient">
          <div class="media">
            <div class="media-left">
              <img class="media-object rounded-circle" [src]="loan.user.avatar" [alt]="loan.user.firstName" onError="src = 'assets/images/broken-avatar.png'">
            </div>
            <div class="media-body m-l-20">
              <h6 class="media-heading user-name" (click)="openUser(loan.user)">{{ loan.user.firstName }}&nbsp;{{ loan.user.lastName }}</h6>
              <!-- <p><small>{{  }}</small></p> TODO: ADD WORKTYPE ON API LIST --> 
              <p>
                <a [href]="'mailto:' + loan.user.email" target="_blank" rel="noopener noreferrer">{{ loan.user.email }}</a><br>
                <span> <a [href]="'tel:+' + loan.user.cellphoneCountryCodeCod + loan.user.cellphone" target="_blank" rel="noopener noreferrer">+{{ loan.user.cellphoneCountryCodeCod }}&nbsp;{{ loan.user.cellphone | phonePipe }}</a></span>
              </p>
            </div>
          </div>
        </td>
        <td class="col align-middle text-center" scope="col">
          {{ loan.loanAmount | currency:'$' : 'symbol' : '1.0-0' }} / {{ loan.loanAmountApproved | currency:'$' : 'symbol' : '1.0-0' }}
        </td>
        <td class="col align-middle text-center" scope="col">
          {{ loan.loanPaymentMonths }} / {{ loan.loanPaymentMonthsApproved }}
        </td>
        <td class="col align-middle text-center" scope="col">
          {{ loan.loanPayedPercentage | percentagePipe }}
        </td>
        <td class="col align-middle text-center" scope="col">
          {{ loan.loanDebtDays }}
        </td>
        <td class="col align-middle text-center">
          <p class="table-status-txt">
            <span class="status-started" *ngIf="loan.loanStatusLast === 'started'">{{ 'LOAN.loanStatusStarted' | translate }}</span>
            <span class="status-sended" *ngIf="loan.loanStatusLast === 'sended'">{{ 'LOAN.loanStatusSended' | translate }}</span>
            <span class="status-accepted" *ngIf="loan.loanStatusLast === 'accepted'">{{ 'LOAN.loanStatusAccepted' | translate }}</span>
            <span class="status-validated" *ngIf="loan.loanStatusLast === 'validated'">{{ 'LOAN.loanStatusValidated' | translate }}</span>
            <span class="status-noPassedValidation" *ngIf="loan.loanStatusLast === 'noPassedValidation'">{{ 'LOAN.loanStatusNoPassedValidation' | translate }}</span>
            <span class="status-preApproved" *ngIf="loan.loanStatusLast === 'preApproved'">{{ 'LOAN.loanStatusPreApproved' | translate }}</span>
            <span class="status-readyToApprove" *ngIf="loan.loanStatusLast === 'readyToApprove'">{{ 'LOAN.loanStatusReadyToApprove' | translate }}</span>
            <span class="status-approved" *ngIf="loan.loanStatusLast === 'approved'">{{ 'LOAN.loanStatusApproved' | translate }}</span>
            <span class="status-rejected" *ngIf="loan.loanStatusLast === 'rejected'">{{ 'LOAN.loanStatusRejected' | translate }}</span>
            <span class="status-readyForDisbursement" *ngIf="loan.loanStatusLast === 'readyForDisbursement'">{{ 'LOAN.loanStatusReadyForDisbursement' | translate }}</span>
            <span class="status-disbursementOnProgress" *ngIf="loan.loanStatusLast === 'disbursementOnProgress'">{{ 'LOAN.loanStatusDisbursementOnProgress' | translate }}</span>
            <span class="status-disbursed" *ngIf="loan.loanStatusLast === 'disbursed'">{{ 'LOAN.loanStatusDisbursed' | translate }}</span>
            <span class="status-payed" *ngIf="loan.loanStatusLast === 'payed'">{{ 'LOAN.loanStatusPayed' | translate }}</span>
            <span class="status-preLegal" *ngIf="loan.loanStatusLast === 'preLegal'">{{ 'LOAN.loanStatusPreLegal' | translate }}</span>
            <span class="status-legal" *ngIf="loan.loanStatusLast === 'legal'">{{ 'LOAN.loanStatusLegal' | translate }}</span>
            <span class="status-negotiation" *ngIf="loan.loanStatusLast === 'negotiation'">{{ 'LOAN.loanStatusNegotiation' | translate }}</span>
            <span class="status-expired" *ngIf="loan.loanStatusLast === 'expired'">{{ 'LOAN.loanStatusExpired' | translate }}</span>
            <span class="status-canceled" *ngIf="loan.loanStatusLast === 'canceled'">{{ 'LOAN.loanStatusCanceled' | translate }}</span>
          </p>
          <p class="table-status-date">{{ loan.loanStatusDateLast | momentPipe: 'date': currentLang: 'DD MMM YYYY' }}</p>
          <p class="table-status-date">{{ loan.loanStatusDateLast | momentPipe: 'TimeAmPm': currentLang: 'hh:mm A' }}</p>
        </td>
      </tr>
    </tbody>
  </table>
</div>