// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 29/05/2023
// import
import { Component, EventEmitter, Input, OnInit, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
// import { CurrencyPipe } from '@angular/common';
// translate
import { TranslateService } from '@ngx-translate/core';
// plugins
import Swal from 'sweetalert2';
import * as moment from 'moment';
// services
import { AuthService } from 'src/app/shared/services/auth.service';
import { EncodingService } from 'src/app/shared/services/encoding.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
// interfaces
import { UserWorkInterface } from 'src/app/shared/interfaces/user-work.interface';
import { ProfessionInterface } from 'src/app/shared/interfaces/profession.interface';
import { OccupationInterface } from 'src/app/shared/interfaces/occupation.interface';
import { PositionInterface } from 'src/app/shared/interfaces/position.interface';
import { CompanyInterface } from 'src/app/shared/interfaces/company.interface';
import { OtherCompanyInterface } from 'src/app/shared/interfaces/other-company.interface';
import { UserInterface } from 'src/app/shared/interfaces/user.interface';
// import { UdpCurrencyMaskPipe } from 'src/app/shared/pipes/udp-currency-mask.pipe';
// component
@Component({
  selector: 'app-user-work-form',
  templateUrl: './user-work-form.component.html',
  styleUrls: ['./user-work-form.component.scss']
})
// class
export class UserWorkFormComponent implements OnInit, OnChanges {
  // variables
  @Input() workActivity: string = null;
  @Input() origin: string = null;
  @Input() userData: UserInterface = null;
  @Input() userWorkData: UserWorkInterface = null;
  @Input() editSalary: Boolean = false;
  @Output() loaderEvent = new EventEmitter();
  @Output() saveEvent = new EventEmitter();
  @Output() backEvent = new EventEmitter();
  // form data
  public mainFrm: FormGroup;
  savedData: boolean = false;
  // messages data
  formErrorMessages: any = {};
  // help data
  toggleHelp = false;
  // request data
  requestData: any = null;
  // work data
  salary: number = 0;
  salaryMin: number = 1000000;
  salaryMax: number = 9999999999;
  // other data
  professions: any[] = [];
  otherProfessions: any[] = [];
  occupations: any[] = [];
  otherOccupations: any[] = [];
  positions: any[] = [];
  otherPositions: any[] = [];
  // company data
  companyId: string = null;
  // otherCompany data
  otherCompanyId: string = null;
  // constructor
  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public authSv: AuthService,
    public encodingSv: EncodingService,
    public apiSv: ApiService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    private utilitiesSv: UtilitiesService,
  ) {
    // main form
    this.mainFrm = this.formBuilder.group({
      profession: [null],
      position: [null],
      occupation: [null],
      contractType: [null],
      contractSalary: [false, [Validators.required, this.checkSalaryMin(), this.checkSalaryMax()]],
      contractStart: [null, this.dateValidator()],
      contractEnd: [null, this.dateValidator()],
      contractActive: [false, [Validators.required]],
    });
    // check for form changes
    this.mainFrm.valueChanges.subscribe(async (val) => {
      // check salary
      if (typeof val.salary === 'string') {
        // mask value
        const maskedVal = this.utilitiesSv.convertStringToCurrency(val.salary);
        await this.translateMsgs();
        // check matched values
        if (val.salary !== maskedVal) {
          this.mainFrm.patchValue({salary: maskedVal});
        }
      }
    });
    // check editSalary
    if (this.editSalary) {
      this.mainFrm.controls.contractSalary.enable();
    } else {
      this.mainFrm.controls.contractSalary.disable();
    }
  }
  // life cycle
  async ngOnInit() {
    // translate data
    await this.translateMsgs();
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.userData) {
      await this.initData();
    }
    if (changes.workActivity) {
      await this.initData();
    }
    if (changes.userWorkData) {
      // check userWorkData
      if (this.userWorkData && this.userWorkData.workType) {
        // update workActivity
        this.workActivity = this.userWorkData.workType;
      }
      await this.initData();
    }
  }
  async initData() {
    // check for workActivity
    if (this.workActivity !== null) {
      // get data
      await this.getWorkActivityData();
      await this.getProfessions();
      await this.getOtherProfessions();
      await this.addOtherProfessionOption();

      console.log('this.workActivity', this.workActivity);

      // check workActivity
      switch (this.workActivity) {
        case 'employee':
          await this.getPositions();
          await this.getOtherPositions();
          await this.addOtherPositionOption();
          await this.getCompanyData();
          await this.getOtherCompanyData();
          // update validators
          this.mainFrm.get('position')?.setValidators([Validators.required]);
          this.mainFrm.controls['position'].updateValueAndValidity();
          this.mainFrm.get('contractType')?.setValidators([Validators.required]);
          this.mainFrm.controls['contractType'].updateValueAndValidity();
          this.mainFrm.get('occupation')?.setValidators(null);
          this.mainFrm.controls['occupation'].updateValueAndValidity();
          break;
        case 'entrepreneur':
          await this.getOccupations();
          await this.getOtherOccupations();
          await this.addOtherOccupationOption();
          await this.getCompanyData();
          await this.getOtherCompanyData();
          // update validators
          this.mainFrm.get('position')?.setValidators(null);
          this.mainFrm.controls['position'].updateValueAndValidity();
          this.mainFrm.get('contractType')?.setValidators(null);
          this.mainFrm.controls['contractType'].updateValueAndValidity();
          this.mainFrm.get('occupation')?.setValidators(null);
          this.mainFrm.controls['occupation'].updateValueAndValidity();
          break;
        case 'home':
          await this.getOccupations();
          await this.getOtherOccupations();
          await this.addOtherOccupationOption();
          // update validators
          this.mainFrm.get('position')?.setValidators(null);
          this.mainFrm.controls['position'].updateValueAndValidity();
          this.mainFrm.get('contractType')?.setValidators(null);
          this.mainFrm.controls['contractType'].updateValueAndValidity();
          this.mainFrm.get('occupation')?.setValidators(null);
          this.mainFrm.controls['occupation'].updateValueAndValidity();
          break;
        case 'student':
          await this.getOccupations();
          await this.getOtherOccupations();
          await this.addOtherOccupationOption();
          // update validators
          this.mainFrm.get('position')?.setValidators(null);
          this.mainFrm.controls['position'].updateValueAndValidity();
          this.mainFrm.get('contractType')?.setValidators(null);
          this.mainFrm.controls['contractType'].updateValueAndValidity();
          this.mainFrm.get('occupation')?.setValidators(null);
          this.mainFrm.controls['occupation'].updateValueAndValidity();
          break;
        case 'pensioner':
          await this.getOccupations();
          await this.getOtherOccupations();
          await this.addOtherOccupationOption();
          // update validators
          this.mainFrm.get('position')?.setValidators(null);
          this.mainFrm.controls['position'].updateValueAndValidity();
          this.mainFrm.get('contractType')?.setValidators(null);
          this.mainFrm.controls['contractType'].updateValueAndValidity();
          this.mainFrm.get('occupation')?.setValidators(null);
          this.mainFrm.controls['occupation'].updateValueAndValidity();
          break;
      }
      // get data
      await this.getUserWorkData();
      await this.setUserWork();
    }
  }
  // translate
  async translateMsgs () {
    this.formErrorMessages = {
      position: [
        { type: 'required', message: this.translate.instant('USERWORKS.formValidation.position.required') },
      ],
      positionOther: [
        { type: 'required', message: this.translate.instant('USERWORKS.formValidation.positionOther.required') },
      ],
      occupation: [
        { type: 'required', message: this.translate.instant('USERWORKS.formValidation.occupation.required') },
      ],
      occupationOther: [
        { type: 'required', message: this.translate.instant('USERWORKS.formValidation.occupationOther.required') },
      ],
      workActivity: [
        { type: 'required', message: this.translate.instant('USERWORKS.formValidation.workActivity.required') },
      ],
      contractType: [
        { type: 'required', message: this.translate.instant('USERWORKS.formValidation.contractType.required') },
      ],
      contractStart: [
        { type: 'invalidDate', message: this.translate.instant('HELPERS.invalidDate') },
      ],
      contractEnd: [
        { type: 'invalidDate', message: this.translate.instant('HELPERS.invalidDate') },
      ],
    };
  }
  // navigation
  async goBack() {
    // emit data
    this.backEvent.emit(); // Return dat
  }
  // data
  async getWorkActivityData() {
    // get stored data work activity
    const storedDataWorkActivity: any = localStorage.getItem('workActivity');
    // check work activity data
    if (storedDataWorkActivity != null) {
      // set workActivity  data
      this.workActivity = storedDataWorkActivity;
    }
  }
  async getUserWorkData() {
    // get stored data request
    const storedDataRequest: any = localStorage.getItem('requestData');
    // check request data
    if (storedDataRequest != null) {
      // get data request
      const requestData: any = JSON.parse(storedDataRequest);
      // set request data
      this.requestData = requestData;
    }
  }
  async setUserWork() {
    if (this.userWorkData !== null) {
      // check professionId
      if (this.userWorkData.professionId) {
        this.mainFrm.controls.profession.setValue(this.userWorkData.professionId);
        this.mainFrm.get('profession')?.setValidators([Validators.required]);
        this.mainFrm.controls['profession'].updateValueAndValidity();
      }
      // set form data
      this.mainFrm.controls.position.setValue(this.userWorkData.positionId);
      this.mainFrm.controls.occupation.setValue(this.userWorkData.occupationId);
      this.mainFrm.controls.contractType.setValue(this.userWorkData.contractType);
      const contractSalaryFormatted = this.userWorkData.contractSalary !== null ? (typeof this.userWorkData.contractSalary === 'string' ? this.userWorkData.contractSalary : this.utilitiesSv.convertStringToCurrency(this.userWorkData.contractSalary.toString())) : null;
      this.mainFrm.controls.contractSalary.setValue(contractSalaryFormatted);
      // get contract dates
      const contractStart = (this.origin === 'b2c' || this.origin === 'loan-add') ? this.userWorkData.contractStart : moment.utc(this.userWorkData.contractStart).format('YYYY-MM-DD');
      const contractEnd = this.userWorkData.contractEnd !== null ? ((this.origin === 'b2c' || this.origin === 'loan-add') ? this.userWorkData.contractEnd : moment.utc(this.userWorkData.contractEnd).format('YYYY-MM-DD')) : null;
      this.mainFrm.controls.contractStart.setValue(contractStart);
      this.mainFrm.controls.contractEnd.setValue(contractEnd);
      // check origin
      if (this.origin === 'b2c' || this.origin === 'loan-add') {
        this.mainFrm.controls.contractActive.setValue(true);
      } else {
        this.mainFrm.controls.contractActive.setValue(this.userWorkData.contractActive);
      }
      // clear companies
      this.companyId = null;
      this.otherCompanyId = null;
      // company data
      if (this.userWorkData.companyId) {
        this.companyId = this.userWorkData.companyId;
      }
      // otherCompany data
      if (this.userWorkData.otherCompanyId) {
        this.otherCompanyId = this.userWorkData.otherCompanyId;
      }
      // update salary
      this.salary = this.userWorkData.contractSalary
      // set savedData
      this.savedData = true;
    }
  }
  // companies
  async getCompanyData() {
    // get stored data company
    const storedDataCompany: any = localStorage.getItem('companyData');
    // check company data
    if (storedDataCompany != null) {
      // get data company
      const companyData: CompanyInterface = JSON.parse(storedDataCompany);
      // set company data
      this.companyId = companyData.id;
    }
  }
  async getOtherCompanyData() {
    // get stored data otherCompany
    const storedDataOtherCompany: any = localStorage.getItem('otherCompanyData');
    // check otherCompany data
    if (storedDataOtherCompany != null) {
      // get data otherCompany
      const otherCompanyData: OtherCompanyInterface = JSON.parse(storedDataOtherCompany);
      // set otherCompany data
      this.otherCompanyId = otherCompanyData.id;
    }
  }
  // professions
  async getProfessions() {
    // get stored data professions
    const storedDataProfessions: any = localStorage.getItem('professionsData');
    // set professionsData
    let professionsData: any;
    // check professionsData
    if (!storedDataProfessions) {
      // get data from api
      await this.apiSv.getPublicProfessions().then((response: any)=>{
        // get professions
        professionsData = response.data;
        // save professions on storage
        localStorage.setItem('professionsData', JSON.stringify(professionsData));
        // sort professions
        const professionsSorted = this.utilitiesSv.sortArrayByKey(professionsData, 'professionName');
        // update all professions
        this.professions = professionsSorted;
      }, error=>{
        console.log('error', error);
      });
    } else {
      // get professionsData
      professionsData = JSON.parse(storedDataProfessions);
      // sort professions
      const professionsSorted = this.utilitiesSv.sortArrayByKey(professionsData, 'professionName');
      // update all professions
      this.professions = professionsSorted;
    }
  }
  async getOtherProfessions() {
    // get stored data professions
    const storedDataProfessions: any = localStorage.getItem('otherProfessionsData');
    // check otherProfessionsData
    if (storedDataProfessions) {
      // set otherProfessionsData
      const otherProfessionsData = JSON.parse(storedDataProfessions);
      // loop otherProfessionsData
      await otherProfessionsData.map((profession: ProfessionInterface) => {
        // add other profession
        this.professions.push(profession);
      });
    }
  }
  async addOtherProfessionOption() {
    // set professionData
    const otherProfessionData = {
      id: 'new',
      professionName: this.translate.instant('HELPERS.other')
    }
    // add other profession
    this.professions.push(otherProfessionData);
  }
  async selectProfession() {
    // get profession
    const profession = this.mainFrm.controls.profession.value; 
    // check if profession is other
    if (profession === 'new') {
      // show alert to get new profession
      Swal.fire({
        icon: 'warning',
        title: this.translate.instant('USERWORKS.formLabels.profession'),
        text: this.translate.instant('USERWORKS.professionOther'),
        input: 'text',
        inputAttributes: {
          autocapitalize: 'on'
        },
        inputPlaceholder: this.translate.instant('USERWORKS.formPlaceHolders.professionOther'),
        confirmButtonText: this.translate.instant('HELPERS.accept'),
        showCancelButton: true,
        cancelButtonText: this.translate.instant('HELPERS.cancel'),
        customClass: {
          cancelButton: 'btn-swal-cancel'
        },
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          // get profession
          const profession: any = result.value;
          // add profession
          await this.addProfession(profession);
        }
      })
    }
  }
  async addProfession(profession: string) {
    // update name data
    profession = this.utilitiesSv.capitalizeString(profession);
    // set professionData
    const professionData: ProfessionInterface = {
      professionName: profession
    }
    // save address on API
    const auxProfession: any = await this.apiSv.addPublicProfession(professionData);
    // get professionId
    const professionId = auxProfession.data.id;
    // set professionSavingData
    const professionSavingData: ProfessionInterface  = {
      id: professionId,
      professionName: profession
    }
    // update profession
    this.professions.push(professionSavingData);
    // update other professions
    this.otherProfessions.push(professionSavingData);
    // save other professions data on storage
    localStorage.setItem('otherProfessionsData', JSON.stringify(this.otherProfessions));
    // select new profession
    this.mainFrm.controls.profession.setValue(professionId);
  }
  // occupations
  async getOccupations() {
    // get stored data occupations
    const storedDataOccupations: any = localStorage.getItem('occupationsData');
    // set occupationsData
    let occupationsData: any;
    // check occupationsData
    if (!storedDataOccupations) {
      // get data from api
      await this.apiSv.getPublicOccupations().then((response: any)=>{
        // get occupations
        occupationsData = response.data;
        // save occupations on storage
        localStorage.setItem('occupationsData', JSON.stringify(occupationsData));
        // sort occupations
        const occupationsSorted = this.utilitiesSv.sortArrayByKey(occupationsData, 'occupationName');
        // update all occupations
        this.occupations = occupationsSorted;
      }, error=>{
        console.log('error', error);
      });
    } else {
      // get occupationsData
      occupationsData = JSON.parse(storedDataOccupations);
      // sort occupations
      const occupationsSorted = this.utilitiesSv.sortArrayByKey(occupationsData, 'occupationName');
      // update all occupations
      this.occupations = occupationsSorted;
    }
  }
  async getOtherOccupations() {
    // get stored data occupations
    const storedDataOccupations: any = localStorage.getItem('otherOccupationsData');
    // check otherOccupationsData
    if (storedDataOccupations) {
      // set otherOccupationsData
      const otherOccupationsData = JSON.parse(storedDataOccupations);
      // loop otherOccupationsData
      await otherOccupationsData.map((occupation: OccupationInterface) => {
        // add other occupation
        this.occupations.push(occupation);
      });
    }
  }
  async addOtherOccupationOption() {
    // set occupationData
    const otherOccupationData = {
      id: 'new',
      occupationName: this.translate.instant('HELPERS.other')
    }
    // add other occupation
    this.occupations.push(otherOccupationData);
  }
  async selectOccupation() {
    // get occupation
    const occupation = this.mainFrm.controls.occupation.value; 
    // check if occupation is other
    if (occupation === 'new') {
      // show alert to get new occupation
      Swal.fire({
        icon: 'warning',
        title: this.translate.instant('USERWORKS.formLabels.occupation'),
        text: this.translate.instant('USERWORKS.occupationOther'),
        input: 'text',
        inputAttributes: {
          autocapitalize: 'on'
        },
        inputPlaceholder: this.translate.instant('USERWORKS.formPlaceHolders.occupationOther'),
        confirmButtonText: this.translate.instant('HELPERS.accept'),
        showCancelButton: true,
        cancelButtonText: this.translate.instant('HELPERS.cancel'),
        customClass: {
          cancelButton: 'btn-swal-cancel'
        },
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          // get occupation
          const occupation: any = result.value;
          // add occupation
          await this.addOccupation(occupation);
        }
      })
    }
  }
  async addOccupation(occupation: string) {
    // update name data
    occupation = this.utilitiesSv.capitalizeString(occupation);
    // set occupationData
    const occupationData: OccupationInterface = {
      occupationName: occupation
    }
    // save address on API
    const auxOccupation: any = await this.apiSv.addPublicOccupation(occupationData);
    // get occupationId
    const occupationId = auxOccupation.data.id;
    // set occupationSavingData
    const occupationSavingData: OccupationInterface  = {
      id: occupationId,
      occupationName: occupation
    }
    // update occupation
    this.occupations.push(occupationSavingData);
    // update other occupations
    this.otherOccupations.push(occupationSavingData);
    // save other occupations data on storage
    localStorage.setItem('otherOccupationsData', JSON.stringify(this.otherOccupations));
    // select new occupation
    this.mainFrm.controls.occupation.setValue(occupationId);
  }
  // positions
  async getPositions() {
    // get stored data positions
    const storedDataPositions: any = localStorage.getItem('positionsData');
    // set positionsData
    let positionsData: any;
    // check positionsData
    if (!storedDataPositions) {
      // get data from api
      await this.apiSv.getPublicPositions().then((response: any)=>{
        // get positions
        positionsData = response.data;
        // save positions on storage
        localStorage.setItem('positionsData', JSON.stringify(positionsData));
        // sort positions
        const positionsSorted = this.utilitiesSv.sortArrayByKey(positionsData, 'positionName');
        // update all positions
        this.positions = positionsSorted;
      }, error=>{
        console.log('error', error);
      });
    } else {
      // get positionsData
      positionsData = JSON.parse(storedDataPositions);
      // sort positions
      const positionsSorted = this.utilitiesSv.sortArrayByKey(positionsData, 'positionName');
      // update all positions
      this.positions = positionsSorted;
    }
  }
  async getOtherPositions() {
    // get stored data positions
    const storedDataPositions: any = localStorage.getItem('otherPositionsData');
    // check otherPositionsData
    if (storedDataPositions) {
      // set otherPositionsData
      const otherPositionsData = JSON.parse(storedDataPositions);
      // loop otherPositionsData
      await otherPositionsData.map((position: PositionInterface) => {
        // add other position
        this.positions.push(position);
      });
    }
  }
  async addOtherPositionOption() {
    // set positionData
    const otherPositionData = {
      id: 'new',
      positionName: this.translate.instant('HELPERS.other')
    }
    // add other position
    this.positions.push(otherPositionData);
  }
  async selectPosition() {
    // get position
    const position = this.mainFrm.controls.position.value; 
    // check if position is other
    if (position === 'new') {
      // show alert to get new position
      Swal.fire({
        icon: 'warning',
        title: this.translate.instant('USERWORKS.formLabels.position'),
        text: this.translate.instant('USERWORKS.positionOther'),
        input: 'text',
        inputAttributes: {
          autocapitalize: 'on'
        },
        inputPlaceholder: this.translate.instant('USERWORKS.formPlaceHolders.positionOther'),
        confirmButtonText: this.translate.instant('HELPERS.accept'),
        showCancelButton: true,
        cancelButtonText: this.translate.instant('HELPERS.cancel'),
        customClass: {
          cancelButton: 'btn-swal-cancel'
        },
        allowOutsideClick: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          // get position
          const position: any = result.value;
          // add position
          await this.addPosition(position);
        }
      })
    }
  }
  async addPosition(position: string) {
    // update name data
    position = this.utilitiesSv.capitalizeString(position);
    // set positionData
    const positionData: PositionInterface = {
      positionName: position
    }
    // save address on API
    const auxPosition: any = await this.apiSv.addPublicPosition(positionData);
    // get positionId
    const positionId = auxPosition.data.id;
    // set positionSavingData
    const positionSavingData: PositionInterface  = {
      id: positionId,
      positionName: position
    }
    // update position
    this.positions.push(positionSavingData);
    // update other positions
    this.otherPositions.push(positionSavingData);
    // save other positions data on storage
    localStorage.setItem('otherPositionsData', JSON.stringify(this.otherPositions));
    // select new position
    this.mainFrm.controls.position.setValue(positionId);
  }
  // form
  async help () {
    if (this.toggleHelp) {
      this.toggleHelp = false;
    } else {
      this.toggleHelp = true;
    }
  }
  async setFocus(elementId: any) {
    elementId.focus();
  }
  checkFormField(fieldName: string) {
    // get field
    const field = this.mainFrm.get(fieldName);
    // check field
    const check = field?.invalid && field?.touched;
    // return data
    return check;
  }
  dateValidator() {
    return (control: FormControl) => {
      const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
      const valid = dateRegex.test(control.value);
      return (control.value !== null && control.value.length !== 10 && !valid) ? { invalidDate: true } : null;
    };
  }
  checkSalaryMin() {
    return (control: FormControl) => {
      // check value
      if (control.value !== null) {
        // get currentSalary
        const currentSalary: number = typeof control.value === 'string' ? this.utilitiesSv.removeDotsFromCurrencyString(control.value) :control.value;
        // set newSalaryMin
        const newSalaryMin = this.salaryMin;
        // check salaryMin
        if (currentSalary < newSalaryMin) {
          return { minSalary: true }
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }
  checkSalaryMax() {
    return (control: FormControl) => {
      // check value
      if (control.value !== null) {
        // get currentSalary
        const currentSalary: number = typeof control.value === 'string' ? parseInt(this.utilitiesSv.removeDotsFromCurrencyString(control.value)) :control.value;
        // set newSalaryMax
        const newSalaryMax = this.salaryMax;
        // check salaryMax
        if (currentSalary > newSalaryMax) {
          return { maxSalary: true }
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }
  // navigation
  async goToPage(page: string, params?: any | null){
    // navigate to page
    this.utilitiesSv.goTo(page, true, params);
  }
  // actions
  async save() {
    // markAllAsTouched
    this.mainFrm.markAllAsTouched();
    // validate data
    if (!this.mainFrm.valid) {
      // send alert
      this.alertSv.showMessage(this.translate.instant('HELPERS.requiredFields'),'warning', this.translate.instant('HELPERS.warning'), true);
    } else {
      // check if user is been saved before
      if (this.savedData) {
        // update userWork
        await this.updateUserWork();
        // console.log('update');
      } else {
        // add userWork
        await this.addUserWork();
        // console.log('add');
      }
    }
  }
  async addUserWork() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // set userWorkData
      const userWorkData: UserWorkInterface = {
        ...((this.origin !== 'b2c') && {userId: this.userData.id}),
        companyId: this.companyId,
        otherCompanyId: this.otherCompanyId,
        workType: this.workActivity,
        positionId: this.mainFrm.controls.position.value,
        occupationId: this.mainFrm.controls.occupation.value,
        contractType: this.mainFrm.controls.contractType.value,
        contractSalary: (this.origin === 'b2c' || this.origin === 'loan-add') ? this.requestData.salary : this.mainFrm.controls.contractType.value,
        contractStart: this.mainFrm.controls.contractStart.value,
        contractEnd: this.mainFrm.controls.contractEnd.value,
        contractActive: this.mainFrm.controls.contractActive.value,
      }

      console.log('userWorkData', userWorkData);

      // init auxUserWork
      let auxUserWork: any = null;
      // init userWorkId
      let userWorkId: string = null;
      // check origin
      if (this.origin === 'b2c') {
        // save address on API
        auxUserWork = await this.apiSv.addPublicUserWork(userWorkData);
        // set userWorkId
        userWorkId = auxUserWork.data;
      } else {
        // save address on API
        auxUserWork = await this.apiSv.addUserWork(userWorkData);
        // set userWorkId
        userWorkId = auxUserWork.data.id;
      }
      // add userWorkId to userWork
      userWorkData.id = userWorkId;
      // set professionId
      const professionId = this.mainFrm.controls.profession.value;
      // add profession to userWorkData to save on storage
      userWorkData.professionId = professionId;
      // update profession on user
      const userData: UserInterface = {professionId}
      // check origin
      if (this.origin === 'b2c') {
        // save profession on API
        await this.apiSv.updatePublicUser(userData);
      } else {
        // save profession on API
        await this.apiSv.updateUser(this.userData.id, userData);
      }
      // set savedData
      this.savedData = true;
      // update userWorkData
      this.userWorkData = userWorkData;
      // hide loader
      this.loaderEvent.emit(null);
      // emit data
      this.saveEvent.emit(userWorkData);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async updateUserWork() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // init salary
      let salary: any = null;
      // check salary
      if (this.salary > 0) {
        salary = (this.origin === 'b2c' || this.origin === 'loan-add') ? this.salary : this.mainFrm.controls.contractSalary.value;
      }
      // set userWorkData
      const userWorkData: UserWorkInterface = {
        companyId: this.companyId,
        otherCompanyId: this.otherCompanyId,
        workType: this.workActivity,
        positionId: this.mainFrm.controls.position.value,
        occupationId: this.mainFrm.controls.occupation.value,
        contractType: this.mainFrm.controls.contractType.value,
        ...((salary && this.origin === 'b2c') && {contractSalary: salary}),
        // ...((salary && this.origin !== 'b2c') && {salary: salary.toString()}), // TODO: CHANGE FOR CONTRACTSALARY AND TYPE NUMBER
        contractStart: this.mainFrm.controls.contractStart.value,
        contractEnd: this.mainFrm.controls.contractEnd.value,
        contractActive: this.mainFrm.controls.contractActive.value,
      }

      console.log('userWorkData', userWorkData);

      // check origin
      if (this.origin === 'b2c') {
        // save address on API
        await this.apiSv.updatePublicUserWork(this.userWorkData.id, userWorkData);
      } else {
        // save address on API
        await this.apiSv.updateUserWork(this.userWorkData.id, userWorkData);
      }
      // update userWorkData id
      userWorkData.id = this.userWorkData.id;
      // set professionId
      const professionId = this.mainFrm.controls.profession.value;
      // check professionId
      if (professionId !== null) {
        // add profession to userWorkData to save on storage
        userWorkData.professionId = professionId;
        // update userWorkData
        this.userWorkData = userWorkData;
        // update profession on user
        const userData: UserInterface = { professionId };
        // check origin
        if (this.origin === 'b2c') {
          // save profession on API
          await this.apiSv.updatePublicUser(userData);
        } else {
          // save profession on API
          await this.apiSv.updateUser(this.userData.id, userData);
        }
      }
      // set savedData
      this.savedData = true;
      // hide loader
      this.loaderEvent.emit(null);
      // fix contractSalary
      userWorkData.contractSalary = salary;
      // emit data
      this.saveEvent.emit(userWorkData);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
}
