// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 25/05/2023
// import
import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
// translate
import { TranslateService } from '@ngx-translate/core';
// services
import { UtilitiesService } from '../../services/utilities.service';
// component
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
// class
export class BreadcrumbComponent implements OnInit {
  // variables
  @Input() title: string;
  @Input() items: any[];
  @Input() active_item: string;
  @Input() breadcrumbs: any[] = [];
  @Input() currentPageTitle: string = null;
  @Input() currentPageBaseUrl: string = null;
  // constructor
  constructor(
    public translate: TranslateService,
    private utilitiesSv: UtilitiesService,
    private _location: Location,
  ) {
  }
  // life cycle
  async ngOnInit() { }
  // navigation
  async goToPage(page: string){
    // navigate to page
    this.utilitiesSv.goTo(page, false, null);
  }
  async goBack() {
    this._location.back();
  }

}
