<div class="card social-profile">
  <div class="card-body">
    <!-- companyLogo -->
    <div class="social-img-wrap"> 
      <div class="social-img">
        <img [src]="companyLogo" [alt]="businessName" onError="src = 'assets/images/broken-image-slim.png'" *ngIf="companyLogo !== null">
        <img src="assets/images/broken-image-slim.png" alt="Logo" *ngIf="companyLogo === null">
      </div>
      <div class="edit-icon" *ngIf="editEnable && isFinancial" (click)="editCompany()">
        <app-feather-icons class="feather-icon" [icon]="'edit-3'"></app-feather-icons>
      </div>
    </div>
    <!-- company -->
    <div class="social-details">
      <!-- name -->
      <h5 class="mb-1">
        <b>{{ businessName }}</b>
      </h5>
      <!-- contact options -->
      <ul class="card-social">
        <li *ngIf="companyEmail">
          <a class="contact-option" [href]="'mailto:' + companyEmail">
            <app-feather-icons class="feather-icon" [icon]="'mail'"></app-feather-icons>
            <span class="social-btn-txt">{{ companyEmail }}</span>
          </a>
        </li>
        <li *ngIf="companyPhone">
          <a class="contact-option" [href]="'tel: +' + companyPhoneCountryCodeCod + companyPhone">
            <app-feather-icons class="feather-icon" [icon]="'phone-call'"></app-feather-icons>
            <span class="social-btn-txt">+{{ companyPhoneCountryCodeCod }}&nbsp;{{ companyPhone }}</span>
          </a>
        </li>
        <li *ngIf="companyCellphone">
          <a class="contact-option" [href]="'tel: +' + companyCellphoneCountryCodeCod + companyCellphone">
            <app-feather-icons class="feather-icon" [icon]="'smartphone'"></app-feather-icons>
            <span class="social-btn-txt">+{{ companyCellphoneCountryCodeCod }}&nbsp;{{ companyCellphone }}</span>
          </a>
        </li>
        <li *ngIf="companyWhatsapp">
          <a class="contact-option" [href]="'https://wa.me/' + companyWhatsappCountryCodeCod + companyWhatsapp + '?text=' + whatsappMsgIntro + businessName + '.%20' + whatsappMsgEnding" target="_blank" rel="norel noopener noreferrer">
            <app-feather-icons class="feather-icon" [icon]="'smartphone'"></app-feather-icons>
            <span class="social-btn-txt">+{{ companyWhatsappCountryCodeCod }}&nbsp;{{ companyWhatsapp }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>