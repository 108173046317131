// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 25/05/2023
// tslint:disable: max-line-length
// tslint:disable: no-unused-expression
// import
import { Pipe, PipeTransform } from '@angular/core';
// plugin
import * as moment from 'moment';
// pipe
@Pipe({
  name: 'momentPipe'
})
// class
export class MomentPipe implements PipeTransform {
  // variables
  mvalue: string;
  // transform
  transform(value: string, ...args: any[]): any {
    // check format
    switch (args[0]) {
      case 'fromNow':
        this.mvalue = moment(value).locale(args[1]).fromNow();
        break;
      default:
        this.mvalue = moment(value).locale(args[1]).format(args[2]);
        break;
    }
    // return data
    return this.mvalue;
  }

}
