<div class="center">
  <div class="file-content loan-files mt-4 mb-4" [ngClass]="{'text-left': alignItems === 'left', 'text-center': alignItems === 'center', 'text-right': alignItems === 'right'}">
    <div class="file-manager">
      <ul class="files">
        <!-- idFrontFile -->
        <li class="file-box" *ngIf="idFrontFileShow">
          <!-- media -->
          <div class="file-top">
            <!-- preview -->
            <app-loan-document-preview 
              *ngIf="!idFrontShowDropZone"
              [fileData]="idFrontLocalFileData"
              [editEnable]="idFrontFileEdit"
              (actionEvent)="manageFile(idFrontFileData, 'idFront', $event, imageModal)"
            ></app-loan-document-preview>
            <!-- drag and drop -->
            <app-loan-document-upload [fileData]="idFrontLocalFileData" (loaderEvent)="updateLoader($event)" (cancelEvent)="cancelFile($event)" (saveEvent)="saveFile($event)" *ngIf="idFrontShowDropZone"></app-loan-document-upload>
          </div>
          <!-- data -->
          <div class="file-bottom">
            <h6>{{ 'LOAN.loanDocumentUserIdFront' | translate }}</h6>
            <!-- <p class="mb-1"><span *ngIf="idFrontFileName === null">---<br>---</span><span *ngIf="idFrontFileName !== null">{{ idFrontFileSizeBytes }}<br>{{ idFrontFileDate }}</span></p> -->
          </div>
        </li>
        <!-- idBackFile -->
        <li class="file-box" *ngIf="idBackFileShow">
          <!-- media -->
          <div class="file-top">
            <!-- preview -->
            <app-loan-document-preview 
              *ngIf="!idBackShowDropZone"
              [fileData]="idBackLocalFileData"
              [editEnable]="idBackFileEdit"
              (actionEvent)="manageFile(idBackFileData, 'idBack', $event, imageModal)"
            ></app-loan-document-preview>
            <!-- drag and drop -->
            <app-loan-document-upload [fileData]="idBackLocalFileData" (loaderEvent)="updateLoader($event)" (cancelEvent)="cancelFile($event)" (saveEvent)="saveFile($event)" *ngIf="idBackShowDropZone"></app-loan-document-upload>
          </div>
          <!-- data -->
          <div class="file-bottom">
            <h6>{{ 'LOAN.loanDocumentUserIdBack' | translate }}</h6>
            <!-- <p class="mb-1"><span *ngIf="idBackFileName === null">---<br>---</span><span *ngIf="idBackFileName !== null">{{ idBackFileSizeBytes }}<br>{{ idBackFileDate }}</span></p> -->
          </div>
        </li>
        <!-- requestFile -->
        <li class="file-box" *ngIf="requestFileShow">
          <!-- media -->
          <div class="file-top">
            <!-- preview -->
            <app-loan-document-preview 
              *ngIf="!requestShowDropZone"
              [fileData]="requestLocalFileData"
              [editEnable]="requestFileEdit"
              (actionEvent)="manageFile(requestFileData, 'request', $event, imageModal)"
            ></app-loan-document-preview>
            <!-- drag and drop -->
            <app-loan-document-upload [fileData]="requestLocalFileData" (loaderEvent)="updateLoader($event)" (cancelEvent)="cancelFile($event)" (saveEvent)="saveFile($event)" *ngIf="requestShowDropZone"></app-loan-document-upload>
          </div>
          <!-- data -->
          <div class="file-bottom">
            <h6>{{ 'LOAN.loanDocumentRequest' | translate }}</h6>
            <!-- <p class="mb-1"><span *ngIf="requestFileName === null">---<br>---</span><span *ngIf="requestFileName !== null">{{ requestFileSizeBytes }}<br>{{ requestFileDate }}</span></p> -->
          </div>
        </li>
        <!-- bankFile -->
        <li class="file-box" *ngIf="bankFileShow">
          <!-- media -->
          <div class="file-top">
            <!-- preview -->
            <app-loan-document-preview 
              *ngIf="!bankShowDropZone"
              [fileData]="bankLocalFileData"
              [editEnable]="bankFileEdit"
              (actionEvent)="manageFile(bankFileData, 'bank', $event, imageModal)"
            ></app-loan-document-preview>
            <!-- drag and drop -->
            <app-loan-document-upload [fileData]="bankLocalFileData" (loaderEvent)="updateLoader($event)" (cancelEvent)="cancelFile($event)" (saveEvent)="saveFile($event)" *ngIf="bankShowDropZone"></app-loan-document-upload>
          </div>
          <!-- data -->
          <div class="file-bottom">
            <h6>{{ 'LOAN.loanDocumentBank' | translate }}</h6>
            <!-- <p class="mb-1"><span *ngIf="bankFileName === null">---<br>---</span><span *ngIf="bankFileName !== null">{{ bankFileSizeBytes }}<br>{{ bankFileDate }}</span></p> -->
          </div>
        </li>
        <!-- laborFile -->
        <li class="file-box" *ngIf="laborFileShow">
          <!-- media -->
          <div class="file-top">
            <!-- preview -->
            <app-loan-document-preview 
              *ngIf="!laborShowDropZone"
              [fileData]="laborLocalFileData"
              [editEnable]="laborFileEdit"
              (actionEvent)="manageFile(laborFileData, 'labor', $event, imageModal)"
            ></app-loan-document-preview>
            <!-- drag and drop -->
            <app-loan-document-upload [fileData]="laborLocalFileData" (loaderEvent)="updateLoader($event)" (cancelEvent)="cancelFile($event)" (saveEvent)="saveFile($event)" *ngIf="laborShowDropZone"></app-loan-document-upload>
          </div>
          <!-- data -->
          <div class="file-bottom">
            <h6>{{ 'LOAN.loanDocumentLabor' | translate }}</h6>
            <!-- <p class="mb-1"><span *ngIf="laborFileName === null">---<br>---</span><span *ngIf="laborFileName !== null">{{ laborFileSizeBytes }}<br>{{ laborFileDate }}</span></p> -->
          </div>
        </li>
        <!-- promissoryFile -->
        <li class="file-box" *ngIf="promissoryFileShow">
          <!-- media -->
          <div class="file-top">
            <!-- preview -->
            <app-loan-document-preview 
              *ngIf="!promissoryShowDropZone"
              [fileData]="promissoryLocalFileData"
              [editEnable]="promissoryFileEdit"
              (actionEvent)="manageFile(promissoryFileData, 'promissory', $event, imageModal)"
            ></app-loan-document-preview>
            <!-- drag and drop -->
            <app-loan-document-upload [fileData]="promissoryLocalFileData" (loaderEvent)="updateLoader($event)" (cancelEvent)="cancelFile($event)" (saveEvent)="saveFile($event)" *ngIf="promissoryShowDropZone"></app-loan-document-upload>
          </div>
          <!-- data -->
          <div class="file-bottom">
            <h6>{{ 'LOAN.loanDocumentPromissory' | translate }}</h6>
            <!-- <p class="mb-1"><span *ngIf="promissoryFileName === null">---<br>---</span><span *ngIf="promissoryFileName !== null">{{ promissoryFileSizeBytes }}<br>{{ promissoryFileDate }}</span></p> -->
          </div>
        </li>
        <!-- instructionsFile -->
        <li class="file-box" *ngIf="instructionsFileShow">
          <!-- media -->
          <div class="file-top">
            <!-- preview -->
            <app-loan-document-preview 
              *ngIf="!instructionsShowDropZone"
              [fileData]="instructionsLocalFileData"
              [editEnable]="instructionsFileEdit"
              (actionEvent)="manageFile(instructionsFileData, 'instructions', $event, imageModal)"
            ></app-loan-document-preview>
            <!-- drag and drop -->
            <app-loan-document-upload [fileData]="instructionsLocalFileData" (loaderEvent)="updateLoader($event)" (cancelEvent)="cancelFile($event)" (saveEvent)="saveFile($event)" *ngIf="instructionsShowDropZone"></app-loan-document-upload>
          </div>
          <!-- data -->
          <div class="file-bottom">
            <h6>{{ 'LOAN.loanDocumentInstructions' | translate }}</h6>
            <!-- <p class="mb-1"><span *ngIf="instructionsFileName === null">---<br>---</span><span *ngIf="instructionsFileName !== null">{{ instructionsFileSizeBytes }}<br>{{ instructionsFileDate }}</span></p> -->
          </div>
        </li>
        <!-- authorizationFile -->
        <li class="file-box" *ngIf="authorizationFileShow">
          <!-- media -->
          <div class="file-top">
            <!-- preview -->
            <app-loan-document-preview 
              *ngIf="!authorizationShowDropZone"
              [fileData]="authorizationLocalFileData"
              [editEnable]="authorizationFileEdit"
              (actionEvent)="manageFile(authorizationFileData, 'authorization', $event, imageModal)"
            ></app-loan-document-preview>
            <!-- drag and drop -->
            <app-loan-document-upload [fileData]="authorizationLocalFileData" (loaderEvent)="updateLoader($event)" (cancelEvent)="cancelFile($event)" (saveEvent)="saveFile($event)" *ngIf="authorizationShowDropZone"></app-loan-document-upload>
          </div>
          <!-- data -->
          <div class="file-bottom">
            <h6>{{ 'LOAN.loanDocumentAuthorization' | translate }}</h6>
            <!-- <p class="mb-1"><span *ngIf="authorizationFileName === null">---<br>---</span><span *ngIf="authorizationFileName !== null">{{ authorizationFileSizeBytes }}<br>{{ authorizationFileDate }}</span></p> -->
          </div>
        </li>
        <!-- disbursementFile -->
        <li class="file-box" *ngIf="disbursementFileShow">
          <!-- media -->
          <div class="file-top">
            <!-- preview -->
            <app-loan-document-preview 
              *ngIf="!disbursementShowDropZone"
              [fileData]="disbursementLocalFileData"
              [editEnable]="disbursementFileEdit"
              [viewEnable]="viewEnable"
              (actionEvent)="manageFile(disbursementFileData, 'disbursement', $event, imageModal)"
            ></app-loan-document-preview>
            <!-- drag and drop -->
            <app-loan-document-upload [fileData]="disbursementLocalFileData" (loaderEvent)="updateLoader($event)" (cancelEvent)="cancelFile($event)" (saveEvent)="saveFile($event)" *ngIf="disbursementShowDropZone"></app-loan-document-upload>
          </div>
          <!-- data -->
          <div class="file-bottom">
            <h6>{{ 'LOAN.loanDocumentDisbursement' | translate }}</h6>
            <!-- <p class="mb-1"><span *ngIf="disbursementFileName === null">---<br>---</span><span *ngIf="disbursementFileName !== null">{{ disbursementFileSizeBytes }}<br>{{ disbursementFileDate }}</span></p> -->
          </div>
        </li>
        <!-- loanPaymentFile -->
        <li class="file-box" *ngIf="loanPaymentFileShow">
          <!-- media -->
          <div class="file-top">
            <!-- preview -->
            <app-loan-document-preview 
              *ngIf="!loanPaymentShowDropZone"
              [fileData]="loanPaymentLocalFileData"
              [editEnable]="loanPaymentFileEdit"
              [viewEnable]="viewEnable"
              (actionEvent)="manageFile(loanPaymentFileData, 'loanPayment', $event, imageModal)"
            ></app-loan-document-preview>
            <!-- drag and drop -->
            <app-loan-document-upload [fileData]="loanPaymentLocalFileData" (loaderEvent)="updateLoader($event)" (cancelEvent)="cancelFile($event)" (saveEvent)="saveFile($event)" *ngIf="loanPaymentShowDropZone"></app-loan-document-upload>
          </div>
          <!-- data -->
          <div class="file-bottom">
            <h6>{{ 'LOANPAYMENTS.loanPaymentReceiptMonth' | translate }}</h6>
            <!-- <p class="mb-1"><span *ngIf="loanPaymentFileName === null">---<br>---</span><span *ngIf="loanPaymentFileName !== null">{{ loanPaymentFileSizeBytes }}<br>{{ loanPaymentFileDate }}</span></p> -->
          </div>
        </li>
        <!-- batchPaymentReceiptFile -->
        <li class="file-box" *ngIf="batchPaymentReceiptFileShow">
          <!-- media -->
          <div class="file-top">
            <!-- preview -->
            <app-loan-document-preview 
              *ngIf="!batchPaymentReceiptShowDropZone"
              [fileData]="batchPaymentReceiptLocalFileData"
              [editEnable]="batchPaymentReceiptFileEdit"
              [viewEnable]="viewEnable"
              (actionEvent)="manageFile(batchPaymentReceiptFileData, 'batchPaymentReceipt', $event, imageModal)"
            ></app-loan-document-preview>
            <!-- drag and drop -->
            <app-loan-document-upload [fileData]="batchPaymentReceiptLocalFileData" (loaderEvent)="updateLoader($event)" (cancelEvent)="cancelFile($event)" (saveEvent)="saveFile($event)" *ngIf="batchPaymentReceiptShowDropZone"></app-loan-document-upload>
          </div>
          <!-- data -->
          <div class="file-bottom">
            <h6>{{ 'LOAN.loanPaymentReceipt' | translate }}</h6>
            <!-- <p class="mb-1"><span *ngIf="batchPaymentReceiptFileName === null">---<br>---</span><span *ngIf="batchPaymentReceiptFileName !== null">{{ batchPaymentReceiptFileSizeBytes }}<br>{{ batchPaymentReceiptFileDate }}</span></p> -->
          </div>
        </li>
        <!-- acceptanceFile -->
        <li class="file-box" *ngIf="acceptanceFileShow">
          <!-- media -->
          <div class="file-top">
            <!-- preview -->
            <app-loan-document-preview 
              *ngIf="!acceptanceShowDropZone"
              [fileData]="acceptanceLocalFileData"
              [editEnable]="acceptanceFileEdit"
              (actionEvent)="manageFile(acceptanceFileData, 'acceptance', $event, imageModal)"
            ></app-loan-document-preview>
            <!-- drag and drop -->
            <app-loan-document-upload [fileData]="acceptanceLocalFileData" (loaderEvent)="updateLoader($event)" (cancelEvent)="cancelFile($event)" (saveEvent)="saveFile($event)" *ngIf="acceptanceShowDropZone"></app-loan-document-upload>
          </div>
          <!-- data -->
          <div class="file-bottom">
            <h6>{{ 'LOAN.loanDocumentAcceptance' | translate }}</h6>
            <!-- <p class="mb-1"><span *ngIf="acceptanceFileName === null">---<br>---</span><span *ngIf="acceptanceFileName !== null">{{ acceptanceFileSizeBytes }}<br>{{ acceptanceFileDate }}</span></p> -->
          </div>
        </li>
        <!-- companyPromissoryFile -->
        <li class="file-box" *ngIf="companyPromissoryFileShow">
          <!-- media -->
          <div class="file-top">
            <!-- preview -->
            <app-loan-document-preview 
              *ngIf="!companyPromissoryShowDropZone"
              [fileData]="companyPromissoryLocalFileData"
              [editEnable]="companyPromissoryFileEdit"
              (actionEvent)="manageFile(companyPromissoryFileData, 'companyPromissory', $event, imageModal)"
            ></app-loan-document-preview>
            <!-- drag and drop -->
            <app-loan-document-upload [fileData]="companyPromissoryLocalFileData" (loaderEvent)="updateLoader($event)" (cancelEvent)="cancelFile($event)" (saveEvent)="saveFile($event)" *ngIf="companyPromissoryShowDropZone"></app-loan-document-upload>
          </div>
          <!-- data -->
          <div class="file-bottom">
            <h6>{{ 'LOAN.loanDocumentCompanyPromissory' | translate }}</h6>
            <!-- <p class="mb-1"><span *ngIf="companyPromissoryFileName === null">---<br>---</span><span *ngIf="companyPromissoryFileName !== null">{{ companyPromissoryFileSizeBytes }}<br>{{ companyPromissoryFileDate }}</span></p> -->
          </div>
        </li>
        <!-- profileFile -->
        <li class="file-box" *ngIf="profileFileShow">
          <!-- media -->
          <div class="file-top">
            <!-- preview -->
            <app-loan-document-preview 
              *ngIf="!profileShowDropZone"
              [fileData]="profileLocalFileData"
              [editEnable]="profileFileEdit"
              (actionEvent)="manageFile(profileFileData, 'profile', $event, imageModal)"
            ></app-loan-document-preview>
            <!-- drag and drop -->
            <app-loan-document-upload [fileData]="profileLocalFileData" (loaderEvent)="updateLoader($event)" (cancelEvent)="cancelFile($event)" (saveEvent)="saveFile($event)" *ngIf="profileShowDropZone"></app-loan-document-upload>
          </div>
          <!-- data -->
          <div class="file-bottom">
            <h6>{{ 'LOAN.loanDocumentProfile' | translate }}</h6>
            <!-- <p class="mb-1"><span *ngIf="profileFileName === null">---<br>---</span><span *ngIf="profileFileName !== null">{{ profileFileSizeBytes }}<br>{{ profileFileDate }}</span></p> -->
          </div>
        </li>
        <!-- synthesisFile -->
        <li class="file-box" *ngIf="synthesisFileShow">
          <!-- media -->
          <div class="file-top">
            <!-- preview -->
            <app-loan-document-preview 
              *ngIf="!synthesisShowDropZone"
              [fileData]="synthesisLocalFileData"
              [editEnable]="synthesisFileEdit"
              (actionEvent)="manageFile(synthesisFileData, 'synthesis', $event, imageModal)"
            ></app-loan-document-preview>
            <!-- drag and drop -->
            <app-loan-document-upload [fileData]="synthesisLocalFileData" (loaderEvent)="updateLoader($event)" (cancelEvent)="cancelFile($event)" (saveEvent)="saveFile($event)" *ngIf="synthesisShowDropZone"></app-loan-document-upload>
          </div>
          <!-- data -->
          <div class="file-bottom">
            <h6>{{ 'LOAN.loanDocumentSynthesis' | translate }}</h6>
            <!-- <p class="mb-1"><span *ngIf="synthesisFileName === null">---<br>---</span><span *ngIf="synthesisFileName !== null">{{ synthesisFileSizeBytes }}<br>{{ synthesisFileDate }}</span></p> -->
          </div>
        </li>
        <!-- detailedFile -->
        <li class="file-box" *ngIf="detailedFileShow">
          <!-- media -->
          <div class="file-top">
            <!-- preview -->
            <app-loan-document-preview 
              *ngIf="!detailedShowDropZone"
              [fileData]="detailedLocalFileData"
              [editEnable]="detailedFileEdit"
              (actionEvent)="manageFile(detailedFileData, 'detailed', $event, imageModal)"
            ></app-loan-document-preview>
            <!-- drag and drop -->
            <app-loan-document-upload [fileData]="detailedLocalFileData" (loaderEvent)="updateLoader($event)" (cancelEvent)="cancelFile($event)" (saveEvent)="saveFile($event)" *ngIf="detailedShowDropZone"></app-loan-document-upload>
          </div>
          <!-- data -->
          <div class="file-bottom">
            <h6>{{ 'LOAN.loanDocumentDetailed' | translate }}</h6>
            <!-- <p class="mb-1"><span *ngIf="detailedFileName === null">---<br>---</span><span *ngIf="detailedFileName !== null">{{ detailedFileSizeBytes }}<br>{{ detailedFileDate }}</span></p> -->
          </div>
        </li>
        <!-- rutFile -->
        <li class="file-box" *ngIf="rutFileShow">
          <!-- media -->
          <div class="file-top">
            <!-- preview -->
            <app-loan-document-preview 
              *ngIf="!rutShowDropZone"
              [fileData]="rutLocalFileData"
              [editEnable]="rutFileEdit"
              (actionEvent)="manageFile(rutFileData, 'rut', $event, imageModal)"
            ></app-loan-document-preview>
            <!-- drag and drop -->
            <app-loan-document-upload [fileData]="rutLocalFileData" (loaderEvent)="updateLoader($event)" (cancelEvent)="cancelFile($event)" (saveEvent)="saveFile($event)" *ngIf="rutShowDropZone"></app-loan-document-upload>
          </div>
          <!-- data -->
          <div class="file-bottom">
            <h6>{{ 'LOAN.loanDocumentRut' | translate }}</h6>
            <!-- <p class="mb-1"><span *ngIf="rutFileName === null">---<br>---</span><span *ngIf="rutFileName !== null">{{ rutFileSizeBytes }}<br>{{ rutFileDate }}</span></p> -->
          </div>
        </li>
        <!-- ccioFile -->
        <li class="file-box" *ngIf="ccioFileShow">
          <!-- media -->
          <div class="file-top">
            <!-- preview -->
            <app-loan-document-preview 
              *ngIf="!ccioShowDropZone"
              [fileData]="ccioLocalFileData"
              [editEnable]="ccioFileEdit"
              (actionEvent)="manageFile(ccioFileData, 'ccio', $event, imageModal)"
            ></app-loan-document-preview>
            <!-- drag and drop -->
            <app-loan-document-upload [fileData]="ccioLocalFileData" (loaderEvent)="updateLoader($event)" (cancelEvent)="cancelFile($event)" (saveEvent)="saveFile($event)" *ngIf="ccioShowDropZone"></app-loan-document-upload>
          </div>
          <!-- data -->
          <div class="file-bottom">
            <h6>{{ 'LOAN.loanDocumentCCIO' | translate }}</h6>
            <!-- <p class="mb-1"><span *ngIf="ccioFileName === null">---<br>---</span><span *ngIf="ccioFileName !== null">{{ ccioFileSizeBytes }}<br>{{ ccioFileDate }}</span></p> -->
          </div>
        </li>
        <!-- repIdFile -->
        <li class="file-box" *ngIf="repIdFileShow">
          <!-- media -->
          <div class="file-top">
            <!-- preview -->
            <app-loan-document-preview 
              *ngIf="!repIdShowDropZone"
              [fileData]="repIdLocalFileData"
              [editEnable]="repIdFileEdit"
              (actionEvent)="manageFile(repIdFileData, 'repId', $event, imageModal)"
            ></app-loan-document-preview>
            <!-- drag and drop -->
            <app-loan-document-upload [fileData]="repIdLocalFileData" (loaderEvent)="updateLoader($event)" (cancelEvent)="cancelFile($event)" (saveEvent)="saveFile($event)" *ngIf="repIdShowDropZone"></app-loan-document-upload>
          </div>
          <!-- data -->
          <div class="file-bottom">
            <h6>{{ 'LOAN.loanDocumentRepId' | translate }}</h6>
            <!-- <p class="mb-1"><span *ngIf="repIdFileName === null">---<br>---</span><span *ngIf="repIdFileName !== null">{{ repIdFileSizeBytes }}<br>{{ repIdFileDate }}</span></p> -->
          </div>
        </li>
        <!-- pgFile -->
        <li class="file-box" *ngIf="pgFileShow">
          <!-- media -->
          <div class="file-top">
            <!-- preview -->
            <app-loan-document-preview 
              *ngIf="!pgShowDropZone"
              [fileData]="pgLocalFileData"
              [editEnable]="pgFileEdit"
              (actionEvent)="manageFile(pgFileData, 'pg', $event, imageModal)"
            ></app-loan-document-preview>
            <!-- drag and drop -->
            <app-loan-document-upload [fileData]="pgLocalFileData" (loaderEvent)="updateLoader($event)" (cancelEvent)="cancelFile($event)" (saveEvent)="saveFile($event)" *ngIf="pgShowDropZone"></app-loan-document-upload>
          </div>
          <!-- data -->
          <div class="file-bottom">
            <h6>{{ 'LOAN.loanDocumentPG' | translate }}</h6>
            <!-- <p class="mb-1"><span *ngIf="pgFileName === null">---<br>---</span><span *ngIf="pgFileName !== null">{{ pgFileSizeBytes }}<br>{{ pgFileDate }}</span></p> -->
          </div>
        </li>
        <!-- bankExtractsFile -->
        <li class="file-box" *ngIf="bankExtractsFileShow">
          <!-- media -->
          <div class="file-top">
            <!-- preview -->
            <app-loan-document-preview 
              *ngIf="!bankExtractsShowDropZone"
              [fileData]="bankExtractsLocalFileData"
              [editEnable]="bankExtractsFileEdit"
              (actionEvent)="manageFile(bankExtractsFileData, 'bankExtracts', $event, imageModal)"
            ></app-loan-document-preview>
            <!-- drag and drop -->
            <app-loan-document-upload [fileData]="bankExtractsLocalFileData" (loaderEvent)="updateLoader($event)" (cancelEvent)="cancelFile($event)" (saveEvent)="saveFile($event)" *ngIf="bankExtractsShowDropZone"></app-loan-document-upload>
          </div>
          <!-- data -->
          <div class="file-bottom">
            <h6>{{ 'LOAN.loanDocumentBankExtracts' | translate }}</h6>
            <!-- <p class="mb-1"><span *ngIf="bankExtractsFileName === null">---<br>---</span><span *ngIf="bankExtractsFileName !== null">{{ bankExtractsFileSizeBytes }}<br>{{ bankExtractsFileDate }}</span></p> -->
          </div>
        </li>
        <!-- pensionerFile -->
        <li class="file-box" *ngIf="pensionerFileShow">
          <!-- media -->
          <div class="file-top">
            <!-- preview -->
            <app-loan-document-preview 
              *ngIf="!pensionerShowDropZone"
              [fileData]="pensionerLocalFileData"
              [editEnable]="pensionerFileEdit"
              (actionEvent)="manageFile(pensionerFileData, 'pensioner', $event, imageModal)"
            ></app-loan-document-preview>
            <!-- drag and drop -->
            <app-loan-document-upload [fileData]="pensionerLocalFileData" (loaderEvent)="updateLoader($event)" (cancelEvent)="cancelFile($event)" (saveEvent)="saveFile($event)" *ngIf="pensionerShowDropZone"></app-loan-document-upload>
          </div>
          <!-- data -->
          <div class="file-bottom">
            <h6>{{ 'LOAN.loanDocumentPensioner' | translate }}</h6>
            <!-- <p class="mb-1"><span *ngIf="pensionerFileName === null">---<br>---</span><span *ngIf="pensionerFileName !== null">{{ pensionerFileSizeBytes }}<br>{{ pensionerFileDate }}</span></p> -->
          </div>
        </li>
        <!-- utilitiesFile -->
        <li class="file-box" *ngIf="utilitiesFileShow">
          <!-- media -->
          <div class="file-top">
            <!-- preview -->
            <app-loan-document-preview 
              *ngIf="!utilitiesShowDropZone"
              [fileData]="utilitiesLocalFileData"
              [editEnable]="utilitiesFileEdit"
              (actionEvent)="manageFile(utilitiesFileData, 'utilities', $event, imageModal)"
            ></app-loan-document-preview>
            <!-- drag and drop -->
            <app-loan-document-upload [fileData]="utilitiesLocalFileData" (loaderEvent)="updateLoader($event)" (cancelEvent)="cancelFile($event)" (saveEvent)="saveFile($event)" *ngIf="utilitiesShowDropZone"></app-loan-document-upload>
          </div>
          <!-- data -->
          <div class="file-bottom">
            <h6>{{ 'LOAN.loanDocumentUtilities' | translate }}</h6>
            <!-- <p class="mb-1"><span *ngIf="utilitiesFileName === null">---<br>---</span><span *ngIf="utilitiesFileName !== null">{{ utilitiesFileSizeBytes }}<br>{{ utilitiesFileDate }}</span></p> -->
          </div>
        </li>
        <!-- otherDocument1File -->
        <li class="file-box" *ngIf="otherDocument1FileShow">
          <!-- media -->
          <div class="file-top">
            <!-- preview -->
            <app-loan-document-preview 
              *ngIf="!otherDocument1ShowDropZone"
              [fileData]="otherDocument1LocalFileData"
              [editEnable]="otherDocument1FileEdit"
              (actionEvent)="manageFile(otherDocument1FileData, 'otherDocument1', $event, imageModal)"
            ></app-loan-document-preview>
            <!-- drag and drop -->
            <app-loan-document-upload [fileData]="otherDocument1LocalFileData" (loaderEvent)="updateLoader($event)" (cancelEvent)="cancelFile($event)" (saveEvent)="saveFile($event)" *ngIf="otherDocument1ShowDropZone"></app-loan-document-upload>
          </div>
          <!-- data -->
          <div class="file-bottom">
            <h6>{{ 'LOAN.loanDocumentOtherDocument' | translate }} 1</h6>
            <!-- <p class="mb-1"><span *ngIf="otherDocument1FileName === null">---<br>---</span><span *ngIf="otherDocument1FileName !== null">{{ otherDocument1FileSizeBytes }}<br>{{ otherDocument1FileDate }}</span></p> -->
          </div>
        </li>
        <!-- otherDocument2File -->
        <li class="file-box" *ngIf="otherDocument2FileShow">
          <!-- media -->
          <div class="file-top">
            <!-- preview -->
            <app-loan-document-preview 
              *ngIf="!otherDocument2ShowDropZone"
              [fileData]="otherDocument2LocalFileData"
              [editEnable]="otherDocument2FileEdit"
              (actionEvent)="manageFile(otherDocument2FileData, 'otherDocument2', $event, imageModal)"
            ></app-loan-document-preview>
            <!-- drag and drop -->
            <app-loan-document-upload [fileData]="otherDocument2LocalFileData" (loaderEvent)="updateLoader($event)" (cancelEvent)="cancelFile($event)" (saveEvent)="saveFile($event)" *ngIf="otherDocument2ShowDropZone"></app-loan-document-upload>
          </div>
          <!-- data -->
          <div class="file-bottom">
            <h6>{{ 'LOAN.loanDocumentOtherDocument' | translate }} 2</h6>
            <!-- <p class="mb-1"><span *ngIf="otherDocument2FileName === null">---<br>---</span><span *ngIf="otherDocument2FileName !== null">{{ otherDocument2FileSizeBytes }}<br>{{ otherDocument2FileDate }}</span></p> -->
          </div>
        </li>
        <!-- otherDocument3File -->
        <li class="file-box" *ngIf="otherDocument3FileShow">
          <!-- media -->
          <div class="file-top">
            <!-- preview -->
            <app-loan-document-preview 
              *ngIf="!otherDocument3ShowDropZone"
              [fileData]="otherDocument3LocalFileData"
              [editEnable]="otherDocument3FileEdit"
              (actionEvent)="manageFile(otherDocument3FileData, 'otherDocument3', $event, imageModal)"
            ></app-loan-document-preview>
            <!-- drag and drop -->
            <app-loan-document-upload [fileData]="otherDocument3LocalFileData" (loaderEvent)="updateLoader($event)" (cancelEvent)="cancelFile($event)" (saveEvent)="saveFile($event)" *ngIf="otherDocument3ShowDropZone"></app-loan-document-upload>
          </div>
          <!-- data -->
          <div class="file-bottom">
            <h6>{{ 'LOAN.loanDocumentOtherDocument' | translate }} 3</h6>
            <!-- <p class="mb-1"><span *ngIf="otherDocument3FileName === null">---<br>---</span><span *ngIf="otherDocument3FileName !== null">{{ otherDocument3FileSizeBytes }}<br>{{ otherDocument3FileDate }}</span></p> -->
          </div>
        </li>


        <!-- paymentReceiptFiles -->
        <li class="file-box" *ngFor="let loanPaymentReceipt of paymentReceiptLocalFiles; let i = index">
          <!-- media -->
          <div class="file-top">
            <!-- preview -->
            <app-loan-document-preview 
              *ngIf="!loanPaymentReceipt.showDropZone"
              [fileData]="loanPaymentReceipt"
              [editEnable]="paymentReceiptEdit"
              (actionEvent)="manageFile(loanPaymentReceipt, 'paymentReceipts', $event, imageModal)"
            ></app-loan-document-preview>
            <!-- drag and drop -->
            <app-loan-document-upload [fileData]="loanPaymentReceipt" class="sub-document" (loaderEvent)="updateLoader($event)" (cancelEvent)="cancelFile($event)" (saveEvent)="saveFile($event)" *ngIf="loanPaymentReceipt.showDropZone"></app-loan-document-upload>
          </div>
          <!-- data -->
          <div class="file-bottom">
            <h6>{{ 'LOAN.loanDocumentPaymentReceipt' | translate }}&nbsp;{{ i + 1 }}</h6>
            <!-- <p class="mb-1"><span *ngIf="loanPaymentReceipt.paymentReceiptFileName === null">---<br>---</span><span *ngIf="loanPaymentReceipt.paymentReceiptFileName !== null">{{ loanPaymentReceipt.paymentReceiptFileSizeBytes }}<br>{{ loanPaymentReceipt.paymentReceiptFileDate }}</span></p> -->
          </div>
        </li>
      </ul>
    </div>
  </div>          
</div>
<!-- image modal -->
<ng-template #imageModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ fileModalTitle }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="closePreview()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <img class="modal-image-preview" [src]="fileModalImage" onError="src = 'assets/images/broken-image-slim.png'">
  </div>
</ng-template>
