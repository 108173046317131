// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 03/06/2023
// import
import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
// translate
import { TranslateService } from '@ngx-translate/core';
// plugins
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// services
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
// interfaces
import { UserInterface } from 'src/app/shared/interfaces/user.interface';
// component
@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
// class
export class UserCardComponent implements OnInit, OnChanges  {
  // variables
  @Input() userData: UserInterface = null;
  @Input() fullData: boolean = false;
  @Input() showDocuments: boolean = false;
  @Input() idFrontFileData: any = null;
  @Input() idBackFileData: any = null;
  @Input() editEnable: boolean = false;
  @Input() fileEditEnable: boolean = false;
  @Input() showAddresses: boolean = false;
  @Input() userAddresses: any[] = [];
  @Output() loaderEvent = new EventEmitter();
  @Output() editEvent = new EventEmitter();
  @Output() fileActionEvent = new EventEmitter();
  // lang data
  currentLang: string = null;
  // user data
  userId: string = null;
  nationalityId: string = null;
  nationalityName: string = null;
  nationalityFlag: string = null;
  birthPlaceCountryName: string = null;
  birthPlaceStateName: string = null;
  birthPlaceCityName: string = null;
  birthPlaceId: string = null;
  documentExpeditionCountryName: string = null;
  documentExpeditionStateName: string = null;
  documentExpeditionCityName: string = null;
  documentExpeditionCityId: string = null;
  documentExpeditionDate: string = null;
  documentType: string = null;
  documentNumber: string = null;
  firstName: string = null;
  secondName: string = null;
  lastName: string = null;
  surname: string = null;
  email: string = null;
  cellphoneCountryId: string = null;
  cellphoneCountryName: string = null;
  cellphoneCountryCode: string = null;
  cellphoneCountryCodeCod: string = null;
  cellphone: string = null;
  phoneCountryId: string = null;
  phoneCountryName: string = null;
  phoneCountryCode: string = null;
  phoneCountryCodeCod: string = null;
  phone: string = null;
  avatar: string = null;
  gender: string = null;
  birthDate: string = null;
  civilState: string = null;
  userStatus: string = null;
  // document data
  idFrontFile: any = null;
  idBackFile: any = null;
  // current user data
  currentUserData: UserInterface = null;
  // role data
  isAdmin: boolean = false;
  isFinancial: boolean = false;
  isCompany: boolean = false;
  isClient: boolean = false;
  // constructor
  constructor(
    public translate: TranslateService,
    public apiSv: ApiService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    private utilitiesSv: UtilitiesService,
  ) {
    // get currentLang
    const currentLang = this.translate.getDefaultLang();
    // set currentLang
    this.currentLang = currentLang;
  }
  // life cycle
  async ngOnInit() {}
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.userData) {
      if (this.userData !== null) {
        await this.getCurrentUserData();
        await this.setData();
      }
    }
    if (changes.idFrontFileData) {
      if (this.idFrontFileData !== null) {
        await this.setIdFrontFileData();
      }
    }
    if (changes.idBackFileData) {
      if (this.idBackFileData !== null) {
        await this.setIdBackFileData();
      }
    }
  }
  // loader
  async updateLoader(loaderMsg: any) {
    this.loaderEvent.emit(loaderMsg);
  }
  // data
  async getCurrentUserData() {
    // get stored data user
    const storedDataUser: any = localStorage.getItem('userData');
    // get data user
    const userData: UserInterface = JSON.parse(storedDataUser);
    // check user data
    if (userData) {
      // set data user
      this.currentUserData = userData;
      // check roles
      const checkAdmin = await this.utilitiesSv.checkRoles(userData.role, ['support', 'administrator']);
      this.isAdmin = checkAdmin;
      const checkFinancial = await this.utilitiesSv.checkRoles(userData.role, ['support', 'administrator', 'board', 'treasure', 'businessAgent']);
      this.isFinancial = checkFinancial;
      const checkCompany = await this.utilitiesSv.checkRoles(userData.role, ['ceo', 'manager', 'auxiliar']);
      this.isCompany = checkCompany;
      const checkClient = await this.utilitiesSv.checkRoles(userData.role, ['client']);
      this.isClient = checkClient;
    }
  }
  async setData() {
    // set single data
    this.userId = this.userData.id;
    this.avatar = this.userData.avatar;
    this.firstName = this.userData.firstName;
    this.secondName = this.userData.secondName;
    this.lastName = this.userData.lastName;
    this.surname = this.userData.surname;
    this.email = this.userData.email;
    this.cellphone = this.userData.cellphone;
    this.phone = this.userData.phone;
    this.userStatus = this.userData.userStatus;
    // get cellphoneCountry data
    this.cellphoneCountryId = this.userData.cellphoneCountryCode;
    this.cellphoneCountryCode = this.userData.cellphoneCountryCode;
    this.cellphoneCountryName = this.userData.cellphoneCountryName;
    this.cellphoneCountryCodeCod = this.userData.cellphoneCountryCodeCod;
    // get phoneCountry data
    this.phoneCountryId = this.userData.phoneCountryCode;
    this.phoneCountryCode = this.userData.phoneCountryCode;
    this.phoneCountryName = this.userData.phoneCountryName;
    this.phoneCountryCodeCod = this.userData.phoneCountryCodeCod;
    // check for full data
    if (this.fullData) {
      this.documentExpeditionDate = this.userData.documentExpeditionDate;
      this.documentType = this.userData.documentType;
      this.documentNumber = this.userData.documentNumber;
      this.gender = this.userData.gender;
      this.birthDate = this.userData.birthDate;
      this.civilState = this.userData.civilState;
      // get nationality data
      this.nationalityId = this.userData.nationalityId;
      this.nationalityName = this.userData.nationalityName;
      this.nationalityFlag = this.userData.nationalityFlag;
      // get birthPlace data
      this.birthPlaceId = this.userData.birthPlaceId;
      this.birthPlaceCountryName = this.userData.birthPlaceCountryName;
      this.birthPlaceStateName = this.userData.birthPlaceStateName;
      this.birthPlaceCityName = this.userData.birthPlaceCityName;
      // get documentExpeditionPlace data
      this.documentExpeditionCityId = this.userData.documentExpeditionCityId;
      this.documentExpeditionCountryName = this.userData.documentExpeditionCountryName;
      this.documentExpeditionStateName = this.userData.documentExpeditionStateName;
      this.documentExpeditionCityName = this.userData.documentExpeditionCityName;
    }
  }
  async setIdFrontFileData() {
    this.idFrontFile = this.idFrontFileData;
  }
  async setIdBackFileData() {
    this.idBackFile = this.idBackFileData;
  }
  // actions
  async edit() {
    // emit data
    this.editEvent.emit(this.userData);
  }
  // files
  async fileAction(action: any) {
    this.fileActionEvent.emit(action);
  }
}
