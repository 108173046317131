// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 19/06/2023
// import
import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
// translate
import { TranslateService } from '@ngx-translate/core';
// services
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
import { MediaService } from 'src/app/shared/services/media.service';
// interfaces
import { UserInterface } from 'src/app/shared/interfaces/user.interface';
import { CountryInterface } from 'src/app/shared/interfaces/country.interface';
import { LoanInterface } from 'src/app/shared/interfaces/loan.interface';
import { LoanPaymentInterface } from 'src/app/shared/interfaces/loan-payment.interface';
// component
@Component({
  selector: 'app-dashboard-user',
  templateUrl: './dashboard-user.component.html',
  styleUrls: ['./dashboard-user.component.scss']
})
// class
export class DashboardUserComponent implements OnInit, OnChanges {
  // variables
  @Input() userData: UserInterface = null;
  @Output() loaderEvent = new EventEmitter();
  // user data
  user: UserInterface = null;
  userId: string = null;
  firstName: string = null;
  avatar: string = null;
  role: string = null;
  userRole: any = null;
  // role variables
  isSupport: boolean = false;
  isFinancial: boolean = false;
  isAdmin: boolean = false;
  isBoard: boolean = false;
  isTreasure: boolean = false;
  isBusinessAgent: boolean = false;
  isCEO: boolean = false;
  isManager: boolean = false;
  isCompany: boolean = false;
  isCompanyAdmin: boolean = false;
  isCompanyAuxiliar: boolean = false;
  isClient: boolean = false;
  // search data
  startDate: any = null;
  finishDate: any = null;
  minDate: any = null;
  maxDate: any = null;
  currentMonthYear: any = null;
  // home data
  homeError: boolean = false;
  // reports
  // loans data
  loans: any[] = [];
  // loan payments data
  loanPayments: any[] = [];
  // constructor
  constructor(
    public translate: TranslateService,
    public apiSv: ApiService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    private utilitiesSv: UtilitiesService,
    private mediaSv: MediaService,
  ) { }
  // life cycle
  async ngOnInit() {
    // data
    await this.setUserData();
    this.generalData();
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.userData) {
      await this.setUserData();
      this.generalData();
    }
  }
  async generalData() {
    this.getLoansData();
  }
  // loader
  async updateLoader(loaderMsg: any) {
    this.loaderEvent.emit(loaderMsg);
  }
  // navigation
  async goToPage(page: string, params?: any | null){
    // navigate to page
    this.utilitiesSv.goTo(page, true, params);
  }
  // actions
  async copyHash(hash: string) {
    navigator.clipboard.writeText(hash);
  }
  // data
  async setUserData() {
    // get data user
    const userData: UserInterface = this.userData;
    // check user data
    if (userData !== null) {
      // set data user
      this.user = userData;
      this.userId = userData.id;
      this.firstName = userData.firstName;
      this.avatar = userData.avatar;
      this.role = userData.role;
      this.userRole = userData.userRole;
      // check roles
      const checkSupport = await this.utilitiesSv.checkRoles(userData.role, ['support']);
      this.isSupport = checkSupport;
      const checkFinancial = await this.utilitiesSv.checkRoles(userData.role, ['support', 'administrator', 'board', 'treasure', 'businessAgent']);
      this.isFinancial = checkFinancial;
      const checkAdmin = await this.utilitiesSv.checkRoles(userData.role, ['administrator']);
      this.isAdmin = checkAdmin;
      const checkBoard = await this.utilitiesSv.checkRoles(userData.role, ['board']);
      this.isBoard = checkBoard;
      const checkTreasure = await this.utilitiesSv.checkRoles(userData.role, ['treasure']);
      this.isTreasure = checkTreasure;
      const checkBusinessAgent = await this.utilitiesSv.checkRoles(userData.role, ['businessAgent']);
      this.isBusinessAgent = checkBusinessAgent;
      const checkCompany = await this.utilitiesSv.checkRoles(userData.role, ['support', 'ceo', 'manager', 'auxiliar']);
      this.isCompany = checkCompany;
      const checkCompanyAdmin = await this.utilitiesSv.checkRoles(userData.role, ['ceo', 'manager']);
      this.isCompanyAdmin = checkCompanyAdmin;
      const checkCompanyAuxiliar = await this.utilitiesSv.checkRoles(userData.role, ['auxiliar']);
      this.isCompanyAuxiliar = checkCompanyAuxiliar;
      const checkCEO = await this.utilitiesSv.checkRoles(userData.role, ['ceo']);
      this.isCEO = checkCEO;
      const checkManager = await this.utilitiesSv.checkRoles(userData.role, ['manager']);
      this.isManager = checkManager;
      const checkClient = await this.utilitiesSv.checkRoles(userData.role, ['client']);
      this.isClient = checkClient;
    }
  }
  // actions
  openLoan(loan: LoanInterface) {
    // navigate to page
    this.goToPage('loans/detail/' + loan.loanNumber);
  }
  openUser(user: UserInterface) {
    // navigate to page
    this.goToPage('users/detail/' + user.id);
  }
  // report data
  async getLoansData() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.loadingTxt') + ' ' + this.translate.instant('LOAN.loans'));
      // init loans data
      let loansData: any =  {
        limit: 5, 
        page: 1, 
        userId: this.userId,
        companyId: null,
        productId: null,
        loanType: null,
        loanNumber: null,
        loanCode: null,
        loanAmount:  null,
        loanStatusLast: null,
      }
      // init data
      const data: any = await this.apiSv.getLoans(loansData);
      // get result
      const result = data.data.result;
      // check if loans has returned data
      if (result && result.length > 0) {
        // clear loans
        this.loans = [];
        // init loans
        const loans: any[] = [];
        // loop loans
        for (let index = 0; index < result.length; index++) {
          const item: any = result[index];
          // get user
          const user = item.userWork.user;
          // check cellphoneCountryCode
          if (user.cellphoneCountryCode) {
            const auxCellphoneCountry: any = await this.apiSv.getCountry(user.cellphoneCountryCode);
            const cellphoneCountry: CountryInterface = auxCellphoneCountry.data;
            user.cellphoneCountryName = cellphoneCountry.countryName;
            user.cellphoneCountryCodeCod = cellphoneCountry.numericCode;
          }
          // add user to item
          item.user = user;
          // get loanPaymentsData
          await this.getLoanPaymentsData(item.id, item.loanNumber);
          // push data
          loans.push(item);
        }
        // update loans
        this.loans = loans;
        // hide loader
        this.loaderEvent.emit(null);
      } else {
        // hide loader
        this.loaderEvent.emit(null);
      }
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // error data
    }
  }
  // loan payments
  async getLoanPaymentsData(loanId: string, loanNumber: string) {
    return new Promise(async (resolve, reject) => {
      try {
        // show loader
        this.loaderEvent.emit(this.translate.instant('HELPERS.loadingTxt') + ' ' + this.translate.instant('LOAN.loanPayments'));
        // set loanPaymentData
        const loanPaymentData = {
          companyId: null,
          batchId: null,
          loanNumber: loanNumber,
          userId: null,
          loanPaymentNumber: null,
          loanPaymentAmount: null,
          loanPaymentStatuses: null,
          loanPaymentMonth: null,
        }
        // get data from api
        await this.apiSv.getLoanPayments(loanPaymentData).then(async (loanPaymentResponse: any)=>{
          // get loanPayments
          const loanPaymentsData: any = loanPaymentResponse.data;
          // check loanPayments
          if (loanPaymentsData) {
            // clear loanPayments
            this.loanPayments = [];
            // init loanPayments
            const loanPayments: any[] = [];
            // loop loanPayments
            loanPaymentsData.map((loanPayment: LoanPaymentInterface) => {
              // init fileExtension
              let fileExtension: string = null;
              // init fileType
              let fileType: string = null;
              // init fileName
              let fileName: string = null;
              // init loanPaymentFileData
              let loanPaymentFileData: any = null;
              // check for payment Receipt
              if (loanPayment.loanPaymentReceipt) {
                // get fileExtension
                fileExtension = this.mediaSv.getFileExtension(loanPayment.loanPaymentReceipt);
                // get fileType
                fileType = this.mediaSv.getFileType(fileExtension);
                // get fileName
                fileName = this.mediaSv.getFileNameAndExtension(loanPayment.loanPaymentReceipt);
                // set loanPaymentFileData
                loanPaymentFileData = {
                  id: loanPayment.id,
                  parentId: loanId,
                  docType: 'loanPayment',
                  file: null,
                  fileId: loanPayment.id,
                  fileType: fileType,
                  fileName: fileName,
                  filePath: loanPayment.loanPaymentReceipt,
                  fileKey: loanPayment.loanPaymentReceipt,
                  fileDate: null,
                  title: this.translate.instant('LOAN.loanDocumentBank'),
                  image: 'assets/svg/icon-document-payment-receipt.svg',
                  showDropZone: false,
                }
              } else {
                loanPaymentFileData = {
                  id: loanPayment.id,
                  parentId: loanId,
                  docType: 'loanPayment',
                  file: null,
                  fileId: 'loanPayment',
                  fileType: null,
                  fileName: null,
                  filePath: null,
                  fileKey: null,
                  fileDate: null,
                  title: this.translate.instant('LOAN.loanDocumentBank'),
                  image: 'assets/svg/icon-document-payment-receipt.svg',
                  showDropZone: false,
                }
              }
              // update loanPaymentFileData
              loanPayment.loanPaymentFileData = loanPaymentFileData;
              // push loanPayment
              loanPayments.push(loanPayment);
            });
            // orderLoanPayments
            const orderedLoanPayments = this.utilitiesSv.sortArrayByKey(loanPayments, 'loanPaymentNumber');
            // loop orderedLoanPayments
            orderedLoanPayments.map((loanPayment: LoanPaymentInterface) => {
              // push loanPayment
              this.loanPayments.push(loanPayment);
            });
          }
          // resolve
          resolve(true);
        }, error=>{
          console.log('error', error);
          reject(error);
        });
      } catch (error) {
        console.log('error', error);
        // hide loader
        this.loaderEvent.emit(null);
        reject(error);
      }
    });
  }

}
