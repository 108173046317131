<!-- form -->
<form class="theme-form" [formGroup]="mainFrm">
  <!-- salary -->
  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
    <div class="form-group">
      <label for="salary">
        <span *ngIf="workActivity !== 'business'">{{ 'LOAN.salary' | translate }}&nbsp;</span>
        <span *ngIf="workActivity === 'business'">{{ 'LOAN.companyIncome' | translate }}&nbsp;</span>
        <span class="required-lbl">*</span></label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">$</span>
        </div>
        <input class="form-control" #salary id="salary" type="text" inputmode="numeric" [min]="salaryMin" [max]="salaryMax" formControlName="salary" placeholder="0">
      </div>
      <ng-container class="error-messages" *ngFor="let error of formErrorMessages.salary">
        <small class="text-danger error-msg" *ngIf="mainFrm.get('salary').hasError(error.type) && (mainFrm.get('salary').dirty || mainFrm.get('salary').touched)">
          {{ error.message }}.&nbsp;
        </small>
      </ng-container>
      <p class="help-messages" *ngIf="toggleHelp">
        <small>{{ 'LOAN.contractSalaryHelp' | translate }}</small>
      </p>
    </div>
  </div>
  <!-- simulator -->
  <div>
    <!-- amount -->
    <div class="row mt-4" *ngIf="!amountCustom">
      <!-- range help -->
      <div class="col-12">
        <h6>{{ 'HELPERS.help' | translate }}</h6>
        <p><small>{{ 'HELPERS.rangeHelp' | translate }}</small></p>
      </div>
      <!-- value -->
      <div class="col-lg-4 col-md-5 col-sm-6 col-xs-12">
        <label class="amount-lbl mt-3" for="amount">{{ 'LOAN.requestAmount' | translate }} <span class="required-lbl">*</span></label>
        <div class="percentage-resume">
          <span class="current-value amount-value">{{ amount | currency:'$' : 'symbol' : '1.0-0' }}</span>
        </div>
      </div>
      <!-- range -->
      <div class="col-lg-8 col-md-7 col-sm-6 col-xs-12">
        <div class="form-group range-field">
          <input *ngIf="!slideSelect" class="range-disabled" id="amount" formControlName="amount" type="range" step="50000" [min]="amountMin" [max]="amountMax" disabled>
          <input *ngIf="slideSelect" id="amount" formControlName="amount" type="range" step="50000" [min]="amountMin" [max]="amountMax">
          <ng-container class="error-messages error-range" *ngFor="let error of formErrorMessages.amount">
            <small class="text-danger" *ngIf="mainFrm.get('amount').hasError(error.type) && (mainFrm.get('amount').dirty || mainFrm.get('amount').touched)">
              {{ error.message }}.&nbsp;
            </small>
          </ng-container>
          <p class="help-messages helper-range" *ngIf="toggleHelp">
            <small>{{ 'LOAN.requestAmountHelp' | translate }}</small>
          </p>
        </div>
      </div>
      <!-- custom button -->
      <div class="col-12">
        <button class="btn btn-xs btn-dark custom-value-btn" (click)="toggleCustomAmount()" [disabled]="!slideSelect">{{ 'LOAN.requestAmountCustom' | translate }}</button>
      </div>
    </div>
    <!-- custom amount -->
    <div class="row mt-4" *ngIf="amountCustom">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="form-group mt-4">
          <label for="customAmount">{{ 'LOAN.requestAmount' | translate }} <span class="required-lbl">*</span></label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input class="form-control" #customAmount id="customAmount" type="text" inputmode="numeric" formControlName="amount" [min]="amountMin" [max]="amountMax">
          </div>
          <ng-container class="error-messages" *ngFor="let error of formErrorMessages.amount">
            <small class="text-danger error-msg" *ngIf="mainFrm.get('amount').hasError(error.type) && (mainFrm.get('amount').dirty || mainFrm.get('amount').touched)">
              {{ error.message }}.&nbsp;
            </small>
          </ng-container>
          <p class="help-messages" *ngIf="toggleHelp">
            <small>{{ 'LOAN.requestAmountHelp' | translate }}</small>
          </p>
        </div>
        <button class="btn btn-xs btn-dark custom-value-btn" (click)="toggleCustomAmount()">{{ 'LOAN.requestAmountSave' | translate }}</button>
      </div>
    </div>
    <!-- months -->
    <div class="row mt-4" *ngIf="!monthsCustom">
      <!-- value -->
      <div class="col-lg-2 col-md-3 col-sm-4 col-xs-12">
        <label class="monts-lbl" for="months">{{ 'LOAN.requestMonths' | translate }} <span class="required-lbl">*</span></label>
        <div class="percentage-resume">
          <span class="current-value">{{ mainFrm.controls.months.value }}</span>
        </div>
      </div>
      <!-- range -->
      <div class="col-lg-10 col-md-9 col-sm-8 col-xs-12">
        <div class="form-group range-field">
          <input *ngIf="!slideSelect" class="range-disabled" id="months" formControlName="months" type="range" [step]="monthSteps" [min]="monthMin" [max]="monthMax" disabled>
          <input *ngIf="slideSelect" id="months" formControlName="months" type="range" [step]="monthSteps" [min]="monthMin" [max]="monthMax">
          <ng-container class="error-messages error-range" *ngFor="let error of formErrorMessages.months">
            <small class="text-danger" *ngIf="mainFrm.get('months').hasError(error.type) && (mainFrm.get('months').dirty || mainFrm.get('months').touched)">
              {{ error.message }}.&nbsp;
            </small>
          </ng-container>
          <p class="help-messages helper-range" *ngIf="toggleHelp">
            <small>{{ 'LOAN.requestMonthsHelp' | translate }}</small>
          </p>
        </div>
      </div>
      <!-- custom button -->
      <div class="col-12">
        <button class="btn btn-xs btn-dark custom-value-btn" (click)="toggleCustomMonths()" [disabled]="!slideSelect">{{ 'LOAN.requestMonthsCustom' | translate }}</button>
      </div>
    </div>
    <!-- custom months -->
    <div class="row mt-4" *ngIf="monthsCustom">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="form-group mt-4">
          <label for="customMonths">{{ 'LOAN.requestMonths' | translate }} <span class="required-lbl">*</span></label>
          <input class="form-control" #customMonths id="customMonths" type="number" inputmode="numeric" formControlName="months" [min]="monthMin" [max]="monthMax">
          <ng-container class="error-messages" *ngFor="let error of formErrorMessages.months">
            <small class="text-danger error-msg" *ngIf="mainFrm.get('months').hasError(error.type) && (mainFrm.get('months').dirty || mainFrm.get('months').touched)">
              {{ error.message }}.&nbsp;
            </small>
          </ng-container>
          <p class="help-messages" *ngIf="toggleHelp">
            <small>{{ 'LOAN.requestMonthsHelp' | translate }}</small>
          </p>
        </div>
        <button class="btn btn-xs btn-dark custom-value-btn" (click)="toggleCustomMonths()">{{ 'LOAN.requestMonthsSave' | translate }}</button>
      </div>
    </div>
    <!-- insuranceIds -->
    <div class="row mt-4" *ngIf="showInsuranceIds">
      <div class="col-6">
        <h4 class="request-title">{{ 'LOAN.loanInsurances' | translate }}</h4>
        <p>{{ 'LOAN.loanInsuranceIdsHelp' | translate }}</p>
      </div>
      <div class="col-6">
        <div class="form-group mt-2">
          <label for="loanInsuranceIdsInput">{{ 'LOAN.loanInsuranceIds' | translate }}</label>
          <input class="form-control" #loanInsuranceIdsInput id="loanInsuranceIdsInput" type="text" inputmode="text" formControlName="loanInsuranceIds" [placeholder]="'LOAN.loanInsuranceIdsPlaceHolder' | translate">
          <ng-container class="error-messages" *ngFor="let error of formErrorMessages.loanInsuranceIds">
            <small class="text-danger error-msg" *ngIf="mainFrm.get('loanInsuranceIds').hasError(error.type) && (mainFrm.get('loanInsuranceIds').dirty || mainFrm.get('loanInsuranceIds').touched)">
              {{ error.message }}.&nbsp;
            </small>
          </ng-container>
          <p class="help-messages" *ngIf="toggleHelp">
            <small>{{ 'LOAN.loanInsuranceIdsHelp' | translate }}</small>
          </p>
        </div>
      </div>
    </div>
    <!-- terms and privacy -->
    <div class="row mt-4" *ngIf="origin === 'b2c'">
      <div class="col">
        <div class="form-group mt-4">
          <label class="d-block" for="terms">
            <input class="checkbox_animated" #terms id="terms" type="checkbox" formControlName="terms" >
            <span class="terms-txt">{{ 'LOAN.acceptance0' | translate }}
              <!-- <a [href]="mainUri + '/wp-content/uploads/documents/Términos%20y%20Condiciones.pdf'" target="_blank" rel="norel noopener noreferrer">{{ 'LOAN.terms' | translate }}</a> {{ 'LOAN.acceptance2' | translate }} 
              <a [href]="mainUri + '/wp-content/uploads/documents/Política%20de%20Privacidad.pdf'" target="_blank"  rel="norel noopener noreferrer">{{ 'LOAN.privacy' | translate }}</a> {{ 'LOAN.acceptance3' | translate }} -->
              <a routerLink="/legal/terminos-y-condiciones" target="_blank" rel="norel noopener noreferrer">{{ 'LOAN.terms' | translate }}</a> {{ 'LOAN.acceptance2' | translate }} <br>
              <a routerLink="/legal/politica-de-privacidad" target="_blank"  rel="norel noopener noreferrer">{{ 'LOAN.privacy' | translate }}</a> {{ 'LOAN.acceptance3' | translate }}
            </span>
          </label>
          <ng-container class="error-messages" *ngFor="let error of formErrorMessages.terms">
            <small class="text-danger" *ngIf="mainFrm.get('terms').hasError(error.type) && (mainFrm.get('terms').dirty || mainFrm.get('terms').touched)">
              {{ error.message }}.&nbsp;
            </small>
          </ng-container>
          <p class="help-messages" *ngIf="toggleHelp">
            <small>{{ 'LOAN.acceptanceHelp' | translate }}</small>
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- save -->
  <div class="row mt-4">
    <div class="col">
      <div class="request-form-btns-container">
        <button class="btn btn-light d-block btn-cancel" (click)="goBack()" type="button">
          <span>{{ 'HELPERS.back' | translate }}</span>
        </button>
        <button class="btn btn-dark d-block" (click)="save()" type="button">
          <span>{{ 'HELPERS.next' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</form>
          