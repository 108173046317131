// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 25/05/2023
// import
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
// injectable
@Injectable({
	providedIn: 'root'
})
// class
export class SecureInnerPagesGuard implements CanActivate {
	
	constructor(private authSv: AuthService,
		private router: Router) { }

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		if (this.authSv.isLoggedIn) {
			this.router.navigate(['/'])
		}
		return true;
	}
}