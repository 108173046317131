<!-- form -->
<form class="theme-form" [formGroup]="mainFrm">
  <!-- header -->
  <div class="row mt-4">
    <!-- title -->
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
      <h4><b>{{ 'USERBANKACCOUNTS.bankTitle' | translate }}</b></h4>
      <p class="mb-1">{{ 'USERBANKACCOUNTS.bankInfo' | translate }}</p>
    </div>
    <!-- help -->
    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 pull-right">
      <div class="btn-with-outside-txt-lbl help-btn" (click)="help()">
        <small class="btn-inner-txt">{{ 'HELPERS.help' | translate }}</small> 
        <a class="btn btn-light btn-xs btn-icon-only btn-icon-text-left">
          <app-feather-icons [icon]="'help-circle'"></app-feather-icons>
        </a>
      </div>
    </div>
  </div>
  <!-- countryBank - bank - userBankAccountType - userBankAccountNumber -->
  <div class="row mt-4">
    <!-- countryBank -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="bankCountryId === null">
      <div class="form-group">
        <label for="countryBankSelect" class="col-form-label">{{ 'USERBANKACCOUNTS.formLabels.countryBank' | translate }} <span class="required-lbl">*</span></label>
        <ng-select [items]="allCountries"
          [selectOnTab]="true"
          bindLabel="countryName"
          bindValue="id"
          labelForId="countryBankSelect"
          [placeholder]="'USERBANKACCOUNTS.formPlaceHolders.countryBank' | translate"
          #countryBankSelect id="countryBankSelect" name="countryBankSelect" formControlName="countryBank">
        </ng-select>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.countryBank">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('countryBank').hasError(error.type) && (mainFrm.get('countryBank').dirty || mainFrm.get('countryBank').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'USERBANKACCOUNTS.formHelp.countryBank' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- bank -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group select-fix">
        <label for="bankSelect" class="col-form-label">{{ 'USERBANKACCOUNTS.formLabels.bank' | translate }} <span class="required-lbl">*</span></label>
        <ng-select [items]="allBanks"
          [selectOnTab]="true"
          bindLabel="bankName"
          bindValue="id"
          labelForId="bankSelect"
          [placeholder]="'USERBANKACCOUNTS.formPlaceHolders.bank' | translate"
          (change)="selectBank()"
          #bankSelect id="bankSelect" name="bankSelect" formControlName="bank">
        </ng-select>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.bank">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('bank').hasError(error.type) && (mainFrm.get('bank').dirty || mainFrm.get('bank').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'USERBANKACCOUNTS.formHelp.bank' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- userBankAccountType -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group select-fix">
        <span class="caret-select"></span>
        <label for="userBankAccountTypeSelect" class="col-form-label">{{ 'USERBANKACCOUNTS.formLabels.userBankAccountType' | translate }} <span class="required-lbl">*</span></label>
        <select class="form-control" #userBankAccountTypeSelect id="userBankAccountTypeSelect" name="userBankAccountTypeSelect" formControlName="userBankAccountType" [title]="'USERBANKACCOUNTS.formPlaceHolders.userBankAccountType' | translate">
          <option value="null" selected disabled>{{ 'USERBANKACCOUNTS.formPlaceHolders.userBankAccountType' | translate }}</option>
          <option value="savings">{{ 'USERBANKACCOUNTS.formLabels.userBankAccountTypeSavings' | translate }}</option>
          <option value="regular">{{ 'USERBANKACCOUNTS.formLabels.userBankAccountTypeRegular' | translate }}</option>
        </select>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.userBankAccountType">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('userBankAccountType').hasError(error.type) && (mainFrm.get('userBankAccountType').dirty || mainFrm.get('userBankAccountType').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'USERBANKACCOUNTS.formHelp.userBankAccountType' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- userBankAccountNumber -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group">
        <label for="userBankAccountNumber">{{ 'USERBANKACCOUNTS.formLabels.userBankAccountNumber' | translate }} <span class="required-lbl">*</span></label>
        <input class="form-control" #userBankAccountNumber id="userBankAccountNumber" type="text" inputmode="numeric" [placeholder]="'USERBANKACCOUNTS.formPlaceHolders.userBankAccountNumber' | translate"  formControlName="userBankAccountNumber">
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.userBankAccountNumber">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('userBankAccountNumber').hasError(error.type) && (mainFrm.get('userBankAccountNumber').dirty || mainFrm.get('userBankAccountNumber').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'USERBANKACCOUNTS.formHelp.userBankAccountNumber' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- userBankAccountActive -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="banksCounter > 0">
      <div class="form-check form-switch userBankAccountActive-switch mt-4">
        <input #userBankAccountActive id="userBankAccountActive" type="checkbox" role="switch" formControlName="userBankAccountActive" class="form-check-input">
        <label class="form-check-label" for="userBankAccountActive">{{ 'USERBANKACCOUNTS.activeBank' | translate }}</label>
      </div>
    </div>
  </div>
  <!-- buttons -->
  <div class="row mt-4">
    <div class="col">
      <div class="request-form-btns-container">
        <button class="btn btn-light d-block btn-cancel" (click)="goBack()" type="button">
          <span *ngIf="(origin === 'b2c' || origin === 'loan-add')">{{ 'HELPERS.back' | translate }}</span>
          <span *ngIf="!(origin === 'b2c' || origin === 'loan-add')">{{ 'HELPERS.cancel' | translate }}</span>
        </button>
        <button class="btn btn-dark d-block" (click)="save()" type="button">
          <span *ngIf="(origin === 'b2c' || origin === 'loan-add')">{{ 'HELPERS.next' | translate }}</span>
          <span *ngIf="!(origin === 'b2c' || origin === 'loan-add')">{{ 'HELPERS.save' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</form>