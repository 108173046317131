// author: Alejandro Bermúdez Restrepo
// product: Think In
// date: 03/06/2023
// import
import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
// translate
import { TranslateService } from '@ngx-translate/core';
// services
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
// interfaces
import { CompanyActivityInterface } from 'src/app/shared/interfaces/company-activity.interface';
// component
@Component({
  selector: 'app-company-activity-card',
  templateUrl: './company-activity-card.component.html',
  styleUrls: ['./company-activity-card.component.scss']
})
// class
export class CompanyActivityCardComponent implements OnInit {
  // variables
  @Input() companyActivityData: CompanyActivityInterface = null;
  @Input() editEnable: boolean = false;
  @Output() loaderEvent = new EventEmitter();
  @Output() editEvent = new EventEmitter();
  // lang data
  currentLang: string = null;
  // product data
  companyActivityCode: string = null;
  companyActivity: string = null;
  companyActivityStatus: boolean = false;
  // constructor
  constructor(
    public translate: TranslateService,
    public apiSv: ApiService,
    public filterSv: FilterService,
    public alertSv: AlertService,
  ) {
    // get currentLang
    const currentLang = this.translate.getDefaultLang();
    // set currentLang
    this.currentLang = currentLang;
  }
  // life cycle
  async ngOnInit() {}
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.companyActivityData) {
      if (this.companyActivityData !== null) {
        await this.setData();
      }
    }
  }
  // loader
  async updateLoader(loaderMsg: any) {
    this.loaderEvent.emit(loaderMsg);
  }
  // data
  async setData() {
    // set single data
    this.companyActivityCode = this.companyActivityData.companyActivityCode;
    this.companyActivity = this.companyActivityData.companyActivity;
    this.companyActivityStatus = this.companyActivityData.companyActivityStatus;
  }
  // actions
  async edit() {
    // emit data
    this.editEvent.emit(this.companyActivityData);
  }
}