// author: Alejandro Bermúdez
// company: Think In
// date: 25/05/2023
/* eslint-disable max-len */
// import
import { Injectable } from '@angular/core';
// plugins
import jwt_decode from 'jwt-decode';
import * as CryptoJS from 'crypto-js';
// environment
import { environment } from 'src/environments/environment';
// injectable
@Injectable({
  providedIn: 'root'
})
// class
export class EncodingService {
  // variables
  secretKey = environment.secretToken;
  // constructor
  constructor() {}
  // functions
  async decodeToken(token: any) {
    const payload: any = await jwt_decode(token);
    return payload;
  }
  encodeCrypto(value: string): string {
    const encodedValue = CryptoJS.AES.encrypt(value, this.secretKey).toString();
    return encodedValue;
  }
  decodeCrypto(encodedValue: string): string {
    const bytes = CryptoJS.AES.decrypt(encodedValue, this.secretKey);
    const decodedValue = bytes.toString(CryptoJS.enc.Utf8);
    return decodedValue;
  }
  encodeBTOA(value: string): string {
    const encodedValue = btoa(value);
    return encodedValue;
  }
  decodeBTOA(encodedValue: string): string {
    const decodedValue = atob(encodedValue);
    return decodedValue;
  }
}
