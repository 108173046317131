<!-- edit -->
<button class="btn btn-primary btn-xs btn-icon-only edit-form-btn" (click)="edit()" *ngIf="editEnable">
  <app-feather-icons [icon]="'edit-3'"></app-feather-icons>
</button>
<!-- family -->
<div class="col-12" *ngIf="showFamilyInfo">
  <div class="card small-widget mb-3">
    <div class="card-body primary user-body-card">
      <span class="widget-title">{{ 'USERFINANTIALS.formLabels.family' | translate }}</span>
      <div class="user-body-content">
        <p class="content-txt"><small><b>{{ 'USERFINANTIALS.formLabels.dependentsShort' | translate }}:</b> {{ dependents }}</small></p>
        <p class="content-txt">
          <small>
            <b>{{ 'USERFINANTIALS.formLabels.housingType' | translate }}:</b>&nbsp;
            <span *ngIf="housingType === 'rent'">{{ 'USERFINANTIALS.formLabels.housingTypeRent' | translate }}</span>
            <span *ngIf="housingType === 'family'">{{ 'USERFINANTIALS.formLabels.housingTypeFamily' | translate }}</span>
            <span *ngIf="housingType === 'own'">{{ 'USERFINANTIALS.formLabels.housingTypeOwn' | translate }}</span>
          </small>
        </p>
      </div>
      <div class="bg-gradient">
        <app-feather-icons class="svg-feather" [icon]="'home'"></app-feather-icons>
      </div>
    </div>
  </div>
</div>
<!-- income -->
<div>
  <!-- sub-header income -->
  <div class="row mt-2 mb-2">
    <div class="col-md-4 col-sm-12">
      <h6 class="request-subtitle">{{ 'LOAN.income' | translate }}</h6>
    </div>
    <div class="col-md-8 col-sm-12" *ngIf="salary > 0">
      <h6 class="financial-totals pull-right">{{ totalIncome | currency:'$' : 'symbol' : '1.0-0' }}</h6>
    </div>
  </div>
  <!-- salary / professionalFees / otherIncome -->
  <div class="row income-resume">
    <!-- salary -->
    <div class="col-md-6 col-sm-12" *ngIf="salary > 0">
      <div class="card small-widget mb-3">
        <div class="card-body primary">
          <span class="widget-title">{{ 'LOAN.salary' | translate }}</span>
          <div class="d-block align-items-end gap-1">
            <h4>{{ salary | currency:'$' : 'symbol' : '1.0-0' }}</h4>
          </div>
          <div class="bg-gradient">
            <app-feather-icons class="svg-feather" [icon]="'briefcase'"></app-feather-icons>
          </div>
        </div>
      </div>
    </div>
    <!-- professionalFees -->
    <div [ngClass]="{'col-md-6 col-sm-12': salary > 0, 'col-12': salary === 0}">
      <div class="card small-widget mb-3">
        <div class="card-body primary">
          <span class="widget-title">{{ 'LOAN.professionalFees' | translate }}</span>
          <div class="d-block align-items-end gap-1">
            <h4>{{ professionalFees | currency:'$' : 'symbol' : '1.0-0' }}</h4>
          </div>
          <div class="bg-gradient">
            <app-feather-icons class="svg-feather" [icon]="'coffee'"></app-feather-icons>
          </div>
        </div>
      </div>
    </div>
    <!-- otherIncome -->
    <div class="col-12">
      <div class="card small-widget mb-3">
        <div class="card-body primary">
          <span class="widget-title">{{ 'LOAN.otherIncome' | translate }}</span>
          <div class="d-block align-items-end gap-1">
            <h4>{{ otherIncome | currency:'$' : 'symbol' : '1.0-0' }}</h4>
            <p><small>{{ otherIncomeDescription }}</small></p>
          </div>
          <div class="bg-gradient">
            <app-feather-icons class="svg-feather" [icon]="'dollar-sign'"></app-feather-icons>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- outcome -->
<div>
  <!-- sub-header outcome -->
  <div class="row mt-4 mb-2">
    <div class="col-md-4 col-sm-12">
      <h6 class="request-subtitle">{{ 'LOAN.outcome' | translate }}</h6>
    </div>
    <div class="col-md-8 col-sm-12" *ngIf="salary > 0">
      <h6 class="financial-totals pull-right">{{ totalOutcome | currency:'$' : 'symbol' : '1.0-0' }}</h6>
    </div>
  </div>
  <!-- rent / credits / familyExpenses / otherExpenses -->
  <div class="row outcome-resume">
    <!-- rent -->
    <div class="col-md-6 col-sm-12">
      <div class="card small-widget mb-3">
        <div class="card-body primary">
          <span class="widget-title">{{ 'LOAN.rent' | translate }}</span>
          <div class="d-block align-items-end gap-1">
            <h4>{{ rent | currency:'$' : 'symbol' : '1.0-0' }}</h4>
          </div>
          <div class="bg-gradient">
            <app-feather-icons class="svg-feather" [icon]="'home'"></app-feather-icons>
          </div>
        </div>
      </div>
    </div>
    <!-- credits -->
    <div class="col-md-6 col-sm-12">
      <div class="card small-widget mb-3">
        <div class="card-body primary">
          <span class="widget-title">{{ 'LOAN.credits' | translate }}</span>
          <div class="d-block align-items-end gap-1">
            <h4>{{ credits | currency:'$' : 'symbol' : '1.0-0' }}</h4>
          </div>
          <div class="bg-gradient">
            <app-feather-icons class="svg-feather" [icon]="'credit-card'"></app-feather-icons>
          </div>
        </div>
      </div>
    </div>
    <!-- familyExpenses -->
    <div class="col-md-6 col-sm-12">
      <div class="card small-widget mb-3">
        <div class="card-body primary">
          <span class="widget-title">{{ 'LOAN.familyExpenses' | translate }}</span>
          <div class="d-block align-items-end gap-1">
            <h4>{{ familyExpenses | currency:'$' : 'symbol' : '1.0-0' }}</h4>
          </div>
          <div class="bg-gradient">
            <app-feather-icons class="svg-feather" [icon]="'users'"></app-feather-icons>
          </div>
        </div>
      </div>
    </div>
    <!-- otherExpenses -->
    <div class="col-12">
      <div class="card small-widget mb-3">
        <div class="card-body primary">
          <span class="widget-title">{{ 'LOAN.otherExpenses' | translate }}</span>
          <div class="d-block align-items-end gap-1">
            <h4>{{ otherExpenses | currency:'$' : 'symbol' : '1.0-0' }}</h4>
            <p><small>{{ otherExpensesDescription }}</small></p>
          </div>
          <div class="bg-gradient">
            <app-feather-icons class="svg-feather" [icon]="'dollar-sign'"></app-feather-icons>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- assets / liabilities -->
<div>
  <!-- sub-header assets liabilities -->
  <div class="row mt-4 mb-2">
    <div class="col-12">
      <h6 class="request-subtitle">{{ 'LOAN.assetsLiabilities' | translate }}</h6>
    </div>
  </div>
  <!-- assets / liabilities -->
  <div class="row">
    <!-- otherAssets -->
    <div class="col-12">
      <div class="card small-widget mb-3">
        <div class="card-body primary">
          <span class="widget-title">{{ 'LOAN.otherAssets' | translate }}</span>
          <div class="d-block align-items-end gap-1">
            <h4>{{ otherAssets | currency:'$' : 'symbol' : '1.0-0' }}</h4>
            <p><small>{{ otherAssetsDescription }}</small></p>
          </div>
          <div class="bg-gradient">
            <app-feather-icons class="svg-feather" [icon]="'chevrons-right'"></app-feather-icons>
          </div>
        </div>
      </div>
    </div>
    <!-- otherLiabilities -->
    <div class="col-12">
      <div class="card small-widget mb-3">
        <div class="card-body primary">
          <span class="widget-title">{{ 'LOAN.otherLiabilities' | translate }}</span>
          <div class="d-block align-items-end gap-1">
            <h4>{{ otherLiabilities | currency:'$' : 'symbol' : '1.0-0' }}</h4>
            <p><small>{{ otherLiabilitiesDescription }}</small></p>
          </div>
          <div class="bg-gradient">
            <app-feather-icons class="svg-feather" [icon]="'chevrons-right'"></app-feather-icons>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- originFunds -->
<div *ngIf="originFunds !== null">
  <!-- sub-header originFunds -->
  <div class="row mt-4 mb-2">
    <div class="col-12">
      <h6 class="request-subtitle">{{ 'USERFINANTIALS.originFunds' | translate }}</h6>
    </div>
  </div>
  <!-- originFunds -->
  <div class="row">
    <div class="col-12">
      <div class="card small-widget mb-3">
        <div class="card-body primary">
          <div class="d-block align-items-end gap-1">
            <p>{{ originFunds }}</p>
          </div>
          <div class="bg-gradient">
            <app-feather-icons class="svg-feather" [icon]="'pocket'"></app-feather-icons>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- bank accounts -->
<app-user-bank-accounts [userData]="userData" [userBankAccounts]="userBankAccounts" [addEnable]="editEnable" [editEnable]="editEnable" [showFiles]="fileEditEnable" [fileEditEnable]="fileEditEnable" (loaderEvent)="updateLoader($event)" *ngIf="showBankInfo"></app-user-bank-accounts>