<ul class="horizontal-menu">
  <!-- <li class="level-menu" *ngIf="!isFinancial && !reportDownloadEnable">
    <a class="nav-link" routerLink="/loans/all" routerLinkActive="router-link-active">
      <app-feather-icons [icon]="'layers'"></app-feather-icons>
      <span>{{ 'LOAN.loans' | translate }}</span>
    </a>
  </li> -->
  <li class="level-menu" *ngIf="isFinancial">
    <a class="nav-link" routerLink="/loans/add" routerLinkActive="router-link-active">
      <app-feather-icons [icon]="'layers'"></app-feather-icons>
      <span>{{ 'LOAN.loanAdd' | translate }}</span>
    </a>
  </li>
  <li class="level-menu" *ngIf="reportDownloadEnable">
    <a class="nav-link" (click)="openFilterDatesExcelReport(filterDatesModal)">
      <app-feather-icons [icon]="'download'"></app-feather-icons>
      <span>{{ 'REPORT.reportDownload' | translate }}</span>
    </a>
  </li>
</ul>
<!-- filterDates modal -->
<ng-template #filterDatesModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'REPORT.report' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancelFilterDatesExcelReport()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- mainFrm -->
    <form class="theme-form" [formGroup]="mainFrm">
      <div class="row mt-4">
        <div class="col-12">
          <div class="dropdown dropdown-select-time-query">
            <button class="btn dropdown-toggle" type="button" (click)="toggleTimeQuery()">{{ 'DATEDATA.timeQueryOptions.' + selectedTimeQuery | translate }}</button>
            <div class="dropdown-menu dropdown-menu-end" [class.show]="showTimeQuery">
              <a class="dropdown-item" (click)="setTimeQuery(timeQueryOption)" *ngFor="let timeQueryOption of timeQueryOptions">{{ 'DATEDATA.timeQueryOptions.' + timeQueryOption | translate }}</a>
            </div>
          </div>
        </div>
        <!-- dateTimeStart -->
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div class="form-group">
            <label for="dateTimeStart">{{ 'REPORT.dateStartLabel' | translate }} <span class="required-lbl">*</span></label>
            <input class="form-control digits" #dateTimeStart id="dateTimeStart" type="date" inputmode="text" [value]="selectedDateStart" formControlName="dateTimeStart" placeholder="dd-mm-yyyy"/>
            <ng-container class="error-messages" *ngFor="let error of formErrorMessages.dateTimeStart">
              <small class="text-danger error-msg" *ngIf="mainFrm.get('dateTimeStart').hasError(error.type) && (mainFrm.get('dateTimeStart').dirty || mainFrm.get('dateTimeStart').touched)">
                {{ error.message }}.&nbsp;
              </small>
            </ng-container>
            <p class="help-messages" *ngIf="toggleHelp">
              <small>{{ 'REPORT.dateStartHelp' | translate }}</small>
            </p>
          </div>
        </div>
        <!-- dateTimeEnd -->
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div class="form-group">
            <label for="dateTimeEnd">{{ 'REPORT.dateEndLabel' | translate }} <span class="required-lbl" *ngIf="selectedTimeQuery !== 'today'">*</span></label>
            <input *ngIf="selectedTimeQuery !== 'today'" class="form-control digits" #dateTimeEnd id="dateTimeEnd" type="date" inputmode="text" [value]="selectedDateEnd" formControlName="dateTimeEnd" placeholder="dd-mm-yyyy"/>
            <input *ngIf="selectedTimeQuery === 'today'" class="form-control digits" #dateTimeEnd id="dateTimeEnd" type="date" inputmode="text" [value]="selectedDateEnd" formControlName="dateTimeEnd" placeholder="dd-mm-yyyy" disabled="true"/>
            <ng-container class="error-messages" *ngFor="let error of formErrorMessages.dateTimeEnd">
              <small class="text-danger error-msg" *ngIf="mainFrm.get('dateTimeEnd').hasError(error.type) && (mainFrm.get('dateTimeEnd').dirty || mainFrm.get('dateTimeEnd').touched)">
                {{ error.message }}.&nbsp;
              </small>
            </ng-container>
            <p class="help-messages" *ngIf="toggleHelp">
              <small>{{ 'REPORT.dateEndHelp' | translate }}</small>
            </p>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="btn btn-light d-block" (click)="cancelFilterDatesExcelReport()" type="button">
      <span>{{ 'HELPERS.cancel' | translate }}</span>
    </button>
    <button class="btn btn-dark d-block" (click)="checkExcelReportDates()" type="button" [disabled]="!mainFrm.valid">
      <span>{{ 'HELPERS.generate' | translate }}</span>
    </button>
  </div>
</ng-template>