// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 25/05/2023
// import
import { Component, EventEmitter, OnInit, OnChanges, Input, Output, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
// translate
import { TranslateService } from '@ngx-translate/core';
// environment
import { environment } from 'src/environments/environment';
// plugins
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageTransform, ImageCroppedEvent } from 'ngx-image-cropper';
// services
import { AuthService } from 'src/app/shared/services/auth.service';
import { EncodingService } from 'src/app/shared/services/encoding.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { LocationService } from 'src/app/shared/services/location.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
import { MediaService } from 'src/app/shared/services/media.service';
// interfaces
import { BankInterface } from 'src/app/shared/interfaces/bank.interface';
import { CountryInterface } from 'src/app/shared/interfaces/country.interface';
// component
@Component({
  selector: 'app-bank-form',
  templateUrl: './bank-form.component.html',
  styleUrls: ['./bank-form.component.scss']
})
// class
export class BankFormComponent implements OnInit, OnChanges {
  // variables
  @Input() bankData: BankInterface = null;
  @Output() loaderEvent = new EventEmitter();
  @Output() addEvent = new EventEmitter();
  @Output() updateEvent = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  // lang data
  currentLang: string = null;
  // form data
  public mainFrm: FormGroup;
  // messages data
  formErrorMessages: any = {};
  // help data
  toggleHelp = false;
  // saved data
  savedData: boolean = false;
  // bank data
  bankId: string = null;
  bankTmpId: number = 0;
  bankName: string = null;
  bankCountries: any[] = [];
  bankCountriesToLink: any[] = [];
  banksHasCountries: any[] = [];
  // bankLogo data
  bankLogoFile: any = null;
  bankLogoSource: any = null;
  bankLogoUpdate: boolean = false;
  // country data
  countries: any[] = [];
  allCountries: any[] = [];
  countryName: string = environment.countryName;
  countryFlag: string = environment.countryFlag;
  countryId: string = null;
  country: CountryInterface = null;
  // edit image data
  imageChangedEvent: any = null;
  canvasRotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  imageModal: any = null;
  // constructor
  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public authSv: AuthService,
    public encodingSv: EncodingService,
    public apiSv: ApiService,
    public locationSv: LocationService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    private utilitiesSv: UtilitiesService,
    private mediaSv: MediaService,
    private modalService: NgbModal,
  ) {
    // main form
    this.mainFrm = this.formBuilder.group({
      bankName: [null, [Validators.required]],
      bankCountries: [null, [Validators.required]],
      bankStatus: [false, [Validators.required]],
    });
  }
  // life cycle
  async ngOnInit() {
    await this.translateMsgs();
    this.getCountries();
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.bankData) {
      // check for bankData
      if (this.bankData !== null) {
        this.initData();
      }
    }
  }
  async initData() {
    // show loader
    this.loaderEvent.emit(this.translate.instant('HELPERS.loadingTxt'));
    // get data
    await this.setBankData();
    // hide loader
    this.loaderEvent.emit(null);
  }
  // translate
  async translateMsgs() {
    // update form error messages
    this.formErrorMessages = {
      bankLogo: [
        { type: 'required', message: this.translate.instant('BANK.formValidation.bankLogo.required') }
      ],
      bankName: [
        { type: 'required', message: this.translate.instant('BANK.formValidation.bankName.required') }
      ],
      bankCountries: [
        { type: 'required', message: this.translate.instant('BANK.formValidation.bankCountries.required') }
      ],
      bankStatus: [
        { type: 'required', message: this.translate.instant('BANK.formValidation.bankStatus.required') }
      ],
    };
  }
  // form
  async help() {
    if (this.toggleHelp) {
      this.toggleHelp = false;
    } else {
      this.toggleHelp = true;
    }
  }
  async setFocus(elementId: any) {
    elementId.focus();
  }
  checkFormField(fieldName: string) {
    // get field
    const field = this.mainFrm.get(fieldName);
    // check field
    const check = field?.invalid && field?.touched;
    // return data
    return check;
  }
  // location
  async getCountries() {
    try {
      // get stored data countries
      const storedDataCountries: any = localStorage.getItem('countriesData');
      // set allCountries
      const allCountries: any = [];
      // set countries
      let countries: any;
      // check countries data
      if (!storedDataCountries) {
        // get data from api
        await this.apiSv.getCountries().then((response: any)=>{
          // get countries
          countries = response.data;
          // save countries on storage
          localStorage.setItem('countriesData', JSON.stringify(countries));
          // loop countries
          countries.map((country: CountryInterface) => {
            // push countries
            this.countries.push(country);
            // set countryData
            const countryData = {
              id: country.id,
              countryName: country.countryName
            }
            // push country
            allCountries.push(countryData);
          });
          // sort countries
          const countriesSorted = this.utilitiesSv.sortArrayByKey(allCountries, 'countryName');
          // set countryNullData
          const countryNullData = {
            id: null,
            countryName: this.translate.instant('LOCATION.formPlaceHolders.countrySelect')
          }
          // unshift country
          countriesSorted.unshift(countryNullData);
          // update all countries
          this.allCountries = countriesSorted;
        }, error=>{
          console.log('error', error);
        });
      } else {
        // get countries
        countries = JSON.parse(storedDataCountries);
        // loop countries
        countries.map((country: CountryInterface) => {
          // push countries
          this.countries.push(country);
          // set countryData
          const countryData = {
            id: country.id,
            countryName: country.countryName
          }
          // push country
          allCountries.push(countryData);
        });
        // sort countries
        const countriesSorted = this.utilitiesSv.sortArrayByKey(allCountries, 'countryName');
        // set countryNullData
        const countryNullData = {
          id: null,
          countryName: this.translate.instant('LOCATION.formPlaceHolders.countrySelect')
        }
        // unshift country
        countriesSorted.unshift(countryNullData);
        // update all countries
        this.allCountries = countriesSorted;
      }
    } catch (error: any) {
      console.log('error', error);
    }
  }
  async selectCountry() {
    // get selectedBankCountries
    const selectedBankCountries = this.mainFrm.controls.bankCountries.value;
    // loop selectedBankCountries
    for (let index = 0; index < selectedBankCountries.length; index++) {
      // get selectedBankCountryId
      const selectedBankCountryId: string = selectedBankCountries[index];
      // get selectedBankCountryIndex
      const selectedBankCountryIndex = this.bankCountries.findIndex((bankCountrySearch: any) => bankCountrySearch.countryId === selectedBankCountryId);
      // check selectedBankCountryIndex
      if (selectedBankCountryIndex === -1) {
        // get countryIndex
        const countryIndex = this.countries.findIndex((countrySearch: any) => countrySearch.id === selectedBankCountryId);
        // get country
        const country = this.countries[countryIndex];
        // update bankTmpId
        this.bankTmpId = this.bankTmpId + 1;
        // set countryData
        const countryData = {
          bankCountryId: 'temp-' + this.bankTmpId,
          tempItem: true,
          countryId: country.id,
          countryName: country.countryName,
          countryFlag: 'assets/images/flags/' + country.countryFlag + '.png',
          updateStatus: 'link'
        }
        // push bankCountry
        this.bankCountries.push(countryData);
      } else {
        // check if is stored
        if (this.bankCountries[selectedBankCountryIndex].updateStatus !== 'stored') {
          this.bankCountries[selectedBankCountryIndex].updateStatus = 'link';
        }
      }
    }
    // loop bankCountries
    for (let index = 0; index < this.bankCountries.length; index++) {
      // get bankCountry
      const bankCountry: CountryInterface = this.bankCountries[index];
      // get checkBankCountryIndex
      const checkBankCountryIndex = selectedBankCountries.findIndex((selectedBankCountrySearch: any) => selectedBankCountrySearch === bankCountry.countryId);
      // checkBankCountryIndex
      if (checkBankCountryIndex === -1) {
        // check tempItem
        if (bankCountry.tempItem) {
          this.bankCountries[index].updateStatus = 'remove';
        } else {
          this.bankCountries[index].updateStatus = 'unlink';
          // unlink bankCountry on api
          await this.apiSv.unlinkBankCountry(this.bankId, bankCountry.countryId);
        }
      }
    }
  }

  // navigation
  async cancel() {
    // emit data
    this.cancelEvent.emit(); // Return dat
  }
  async setBankData() {
    // check bank data
    if (this.bankData !== null) {
      // set bank
      const bank: BankInterface = this.bankData;
      // set bankLogo
      this.bankLogoSource = bank.bankLogo;
      // set bank data
      this.bankId = bank.id;
      this.bankName = bank.bankName;
      // set form data
      this.mainFrm.controls.bankName.setValue(bank.bankName);
      this.mainFrm.controls.bankStatus.setValue(bank.bankStatus);
      // update banksHasCountries
      this.banksHasCountries = bank.banksHasCountries;
      // clear bankCountries
      this.bankCountries = [];
      // bankCountries
      const bankCountriesFrm: any[] = [];
      // loop bankCountries
      bank.bankCountries.map((bankCountry: CountryInterface) => {
        // push country to bankCountriesFrm
        bankCountriesFrm.push(bankCountry.countryId);
        // add updateStatus
        bankCountry.updateStatus = 'stored';
        bankCountry.tempItem = false;
        // push country to bankCountries
        this.bankCountries.push(bankCountry);
      });
      // update bankCountries form data
      this.mainFrm.controls.bankCountries.setValue(bankCountriesFrm);
      // set savedData
      this.savedData = true;
    }
  }
  // actions
  async save() {
    // markAllAsTouched
    this.mainFrm.markAllAsTouched();
    // validate data
    if (!this.mainFrm.valid) {
      // send alert
      this.alertSv.showMessage(this.translate.instant('HELPERS.requiredFields'),'warning', this.translate.instant('HELPERS.warning'), true);
    } else {
      // check if data is been saved before
      if (!this.savedData) {
        // add data
        await this.addBank();
      } else {
        // update data
        await this.updateBank();
      }
    }
  }
  async addBank() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // get name data
      const bankName = this.utilitiesSv.capitalizeString(this.mainFrm.controls.bankName.value);
      // set bankData
      const bankData: BankInterface = {
        ...((this.bankLogoUpdate) && {bankLogo: this.bankLogoSource}),
        bankName,
        bankStatus: this.mainFrm.controls.bankStatus.value,
      }
      // get auxBank
      const auxBank: any = await this.apiSv.addBank(bankData);
      // get data
      const data: any = auxBank.data;
      // get bankId
      const bankId: string = data.id;
      // update bankId
      this.bankId = bankId;
      // update BankCountries
      await this.updateBankCountries();
      // set savedData
      this.savedData = true;
      // set saveBankData
      const saveBankData: BankInterface = {
        id: bankId,
        bankLogo: this.bankLogoSource,
        bankName: this.mainFrm.controls.bankName.value,
        bankCountries: this.bankCountries,
        banksHasCountries: this.banksHasCountries,
        bankStatus: this.mainFrm.controls.bankStatus.value
      }
      // update bankData
      this.bankData = saveBankData;
      // hide loader
      this.loaderEvent.emit(null);
      // emit data
      this.addEvent.emit(saveBankData);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async updateBank() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // get name data
      const bankName = this.utilitiesSv.capitalizeString(this.mainFrm.controls.bankName.value);
      // set bankData
      const bankData: BankInterface = {
        ...((this.bankLogoUpdate) && {bankLogo: this.bankLogoSource}),
        bankName,
        bankStatus: this.mainFrm.controls.bankStatus.value
      }
      // update product
      await this.apiSv.updateBank(this.bankData.id, bankData);
      // update bankData
      this.bankData.bankName = bankName;
      this.bankData.bankLogo = this.bankLogoSource;
      this.bankData.bankStatus = this.mainFrm.controls.bankStatus.value;
      // update BankCountries
      await this.updateBankCountries();
      // hide loader
      this.loaderEvent.emit(null);
      // emit data
      this.updateEvent.emit(this.bankData);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async updateBankCountries() {
    try {
      // loop bankCountries
      this.bankCountries.map(async (bankCountry: any) => {
        // check updateStatus
        switch (bankCountry.updateStatus) {
          case 'link':
            // set linkBankCountryData
            const linkBankCountryData = {
              bankId: this.bankId,
              countryId: bankCountry.countryId
            }
            // link bankCountry on api
            const auxLinkBankCountry: any = await this.apiSv.linkBankCountry(linkBankCountryData);
            // get linkedBankCountry
            const linkedBankCountry = auxLinkBankCountry.data;
            // get linkedBankCountryId
            const linkedBankCountryId = linkedBankCountry.id;
            // get linkBankCountryIndex
            const linkBankCountryIndex = this.bankCountries.findIndex((bankCountrySearch: any) => bankCountrySearch.countryId === bankCountry.countryId);
            // update bankCountry updateStatus
            this.bankCountries[linkBankCountryIndex].updateStatus = null;
            // get countryIndex
            const countryIndex = this.countries.findIndex((countrySearch: any) => countrySearch.id === bankCountry.countryId);
            // update bankCountry updateStatus
            const country = this.countries[countryIndex];
            // set linkBanksHasCountryData
            const linkBanksHasCountryData = {
              id: linkedBankCountryId,
              countryId: country
            }
            // push banksHasCountries
            this.banksHasCountries.push(linkBanksHasCountryData);
            break;
          case 'unlink':
            // get unlinkBankCountryIndex
            const unlinkBankCountryIndex = this.bankCountries.findIndex((bankCountrySearch: any) => bankCountrySearch.countryId === bankCountry.countryId);
            // remove bankCountry from array
            this.bankCountries.splice(unlinkBankCountryIndex, 1);
            // get unlinkBankHasCountriesIndex
            const unlinkBankHasCountriesIndex = this.banksHasCountries.findIndex((bankCountrySearch: any) => bankCountrySearch.id === bankCountry.bankCountryId);
            // remove bankCountry from array
            this.banksHasCountries.splice(unlinkBankHasCountriesIndex, 1);
            break;
          case 'remove':
            // get removeBankCountryIndex
            const removeBankCountryIndex = this.bankCountries.findIndex((bankCountrySearch: any) => bankCountrySearch.countryId === bankCountry.countryId);
            // remove bankCountry from array
            this.bankCountries.splice(removeBankCountryIndex, 1);
            break;
        }
      });
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  // image
  async editImage() {
    // trigger hidden input file
    this.fileInput.nativeElement.click();
  }
  async onFileSelected(event: any, modal: any) {
    const file = event.target.files?.[0];
    if (file) {
      // check fileType
      if (file.type.startsWith('image/')) {
        // get uploaded file data
        const fileSize = file.size;
        // check file size
        if (fileSize >= environment.maxFileData) {
          // hide loader
          this.loaderEvent.emit(null);
          // show loader
          this.alertSv.showMessage(this.translate.instant('HELPERS.fileTooBig'),'error', this.translate.instant('HELPERS.error'), true);
        } else {
          // set image changed event
          this.imageChangedEvent = event;
          // showCropper
          this.showCropper = true;
          // open crop modal
          this.imageModal = this.modalService.open(modal);
        }
      } else {
        // hide loader
        this.loaderEvent.emit(null);
        // show loader
        this.alertSv.showMessage(this.translate.instant('HELPERS.fileNoImage'),'error', this.translate.instant('HELPERS.error'), true);
      }
    } else {
      // hide loader
      this.loaderEvent.emit(null);
    }
  }
  async uploadFile(file: any) {
    const imageData = new FormData()
    imageData.append('file', file);
    // send file to api
    const imageFile: any = await this.apiSv.uploadFile(imageData);
    // get file data
    const fileData: any = imageFile.data;
    const filePath = fileData.filePath;
    // save file
    this.updateFile(filePath);
  }
  async updateFile(filePath: string) {
    try {
      // mark bankLogoUpdate
      this.bankLogoUpdate = true;
      // update icon source
      this.bankLogoSource = filePath;
      // hide loader
      this.loaderEvent.emit(null);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  imageCroppingClose() {
    // clear icon
    this.bankLogoSource = null;
    // clear image event
    this.imageChangedEvent = null;
    // hide modal
    this.imageModal.close();
  }
  async imageCroppingFinish() {
    // hide modal
    this.imageModal.close();
    // show loader
    this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
    // init base 64 data
    let b64Data: string = null;
    // clear icon
    this.bankLogoFile = null;
    // get base 64 data
    b64Data = this.bankLogoSource;
    // get file data
    const bankName = this.mainFrm.controls.bankName.value;
    const fileName = bankName === null ? 'bank-logo.png' : await this.utilitiesSv.slugify(bankName) + '-bank-logo.png';
    var file = this.mediaSv.dataURLtoFile(b64Data, fileName);
    // upload file
    await this.uploadFile(file);
  }
  // cropping media
  imageCropped(event: ImageCroppedEvent) {
    // set icon
    this.bankLogoSource = event.base64;
  }
  imageLoadImageFailed() {
    // show message
    this.alertSv.showMessage(this.translate.instant('HELPERS.fileLoadImageError'),'error', this.translate.instant('HELPERS.error'), true);
  }
  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }
  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }
  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }
  flipHorizontal() {
    this.transform = {
        ...this.transform,
        flipH: !this.transform.flipH
    };
  }
  flipVertical() {
    this.transform = {
        ...this.transform,
        flipV: !this.transform.flipV
    };
  }
  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }
  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }
}
