// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 19/06/2023
// import
import { Component, OnInit, OnChanges, SimpleChanges, AfterViewInit, Input } from '@angular/core';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
// translate
import { TranslateService } from '@ngx-translate/core';
// services
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
// interfaces
import { UserInterface } from 'src/app/shared/interfaces/user.interface';
// variables
declare var $: any;
import * as moment from 'moment';
// component
@Component({
  selector: 'app-dashboard-welcome',
  templateUrl: './dashboard-welcome.component.html',
  styleUrls: ['./dashboard-welcome.component.scss']
})
export class DashboardWelcomeComponent implements OnInit, OnChanges, AfterViewInit {
  // variables
  @Input() userData: UserInterface = null;
  @Input() totalRequests: number = 0;
  // greeting data
  public greeting: string;
  // inspiration data
  inspiration: any[] = [];
  inspirationalPhrase: string = null;
  // user data
  user: UserInterface = null;
  userId: string = null;
  firstName: string = null;
  avatar: string = null;
  role: string = null;
  userRole: any = null;
  // role variables
  isSupport: boolean = false;
  isFinancial: boolean = false;
  isAdmin: boolean = false;
  isBoard: boolean = false;
  isTreasure: boolean = false;
  isBusinessAgent: boolean = false;
  isCEO: boolean = false;
  isManager: boolean = false;
  isCompany: boolean = false;
  isCompanyAdmin: boolean = false;
  isCompanyAuxiliar: boolean = false;
  isClient: boolean = false;
  // url data
  urlData: string = null;
  requestString: string = null;
  // search data
  startDate: any = null;
  finishDate: any = null;
  minDate: any = null;
  maxDate: any = null;
  currentMonthYear: any = null;
  // time data
  public time: any;
  public today = new Date();
  public currentHour = this.today.getHours();
  public m = this.today.getMinutes();
  public ampm = this.currentHour >= 12 ? 'PM' : 'AM';
  public date: { year: number, month: number };
  model: NgbDateStruct;
  disabled = true;
  // constructor
  constructor(
    calendar: NgbCalendar,
    public translate: TranslateService,
    public apiSv: ApiService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    private utilitiesSv: UtilitiesService,
  ) {
    this.model = calendar.getToday();
  }
  // life cycle
  async ngOnInit() {
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.userData) {
      await this.setUserData();
    }
  }
  async ngAfterViewInit() {
    // time
    await this.startTime();
    // data
    await this.getGreeting();
    await this.getInspiration();
  }
  // calendar
  async startTime() {
    this.currentHour = this.currentHour % 12;
    this.currentHour = this.currentHour ? this.currentHour : 12;
    this.m = this.checkTime(this.m);
    this.time = this.currentHour + ":" + this.m + ' ' + this.ampm;
    this.minDate = moment().subtract(1, 'M').locale(this.translate.getDefaultLang()).format('YYYY-MM-DD');
    this.maxDate = moment().locale(this.translate.getDefaultLang()).format('YYYY-MM-DD');
    this.currentMonthYear = moment().locale(this.translate.getDefaultLang()).format('MMMM YYYY');
  }
  checkTime(i) {
    if (i < 10) { i = "0" + i };  // add zero in front of numbers < 10
    return i;
  }
  // data
  async setUserData() {
    // get data user
    const userData: UserInterface = this.userData;
    // check user data
    if (userData !== null) {
      // set data user
      this.user = userData;
      this.userId = userData.id;
      this.firstName = userData.firstName;
      this.avatar = userData.avatar;
      this.role = userData.role;
      this.userRole = userData.userRole;
      // check roles
      const checkSupport = await this.utilitiesSv.checkRoles(userData.role, ['support']);
      this.isSupport = checkSupport;
      const checkFinancial = await this.utilitiesSv.checkRoles(userData.role, ['support', 'administrator', 'board', 'treasure', 'businessAgent']);
      this.isFinancial = checkFinancial;
      const checkAdmin = await this.utilitiesSv.checkRoles(userData.role, ['administrator']);
      this.isAdmin = checkAdmin;
      const checkBoard = await this.utilitiesSv.checkRoles(userData.role, ['board']);
      this.isBoard = checkBoard;
      const checkTreasure = await this.utilitiesSv.checkRoles(userData.role, ['treasure']);
      this.isTreasure = checkTreasure;
      const checkBusinessAgent = await this.utilitiesSv.checkRoles(userData.role, ['businessAgent']);
      this.isBusinessAgent = checkBusinessAgent;
      const checkCompany = await this.utilitiesSv.checkRoles(userData.role, ['support', 'ceo', 'manager', 'auxiliar']);
      this.isCompany = checkCompany;
      const checkCompanyAdmin = await this.utilitiesSv.checkRoles(userData.role, ['ceo', 'manager']);
      this.isCompanyAdmin = checkCompanyAdmin;
      const checkCompanyAuxiliar = await this.utilitiesSv.checkRoles(userData.role, ['auxiliar']);
      this.isCompanyAuxiliar = checkCompanyAuxiliar;
      const checkCEO = await this.utilitiesSv.checkRoles(userData.role, ['ceo']);
      this.isCEO = checkCEO;
      const checkManager = await this.utilitiesSv.checkRoles(userData.role, ['manager']);
      this.isManager = checkManager;
      const checkClient = await this.utilitiesSv.checkRoles(userData.role, ['client']);
      this.isClient = checkClient;
      // check role
      if (this.isSupport) {
        // set url
        this.urlData = '/loans/all';
        this.requestString = 'LOAN.loans';
      } else if (this.isAdmin) {
        // set url
        this.urlData = '/loans/all';
        this.requestString = 'LOAN.loans';
      } else if (this.isBoard) {
        // set url
        this.urlData = '/loans/list/validated';
        this.requestString = 'MENU.loansToApprove';
      } else if (this.isTreasure) {
        // set url
        this.urlData = '/loans/list/readyForDisbursement';
        this.requestString = 'LOAN.loanStatusesReadyForDisbursement';
      } else if (this.isBusinessAgent) {
        // set url
        this.urlData = '/loans/all';
        this.requestString = 'LOAN.loans';
      } else if (this.isCEO) {
        // set url
        this.urlData = '/loans/list/readyToApprove';
        this.requestString = 'LOAN.loanStatusesReadyToApprove';
      } else if (this.isManager) {
        // set url
        this.urlData = '/loans/list/readyToApprove';
        this.requestString = 'LOAN.loanStatusesReadyToApprove';
      } else if (this.isClient) {
        // set url
        this.urlData = '/loans/mine';
        this.requestString = 'MENU.myLoans';
      }
    }
  }
  async getGreeting() {
    // get time
    const currentTime = moment();
    const hour = currentTime.hours();
    // check timing
    if (hour >= 4 && hour < 12) {
      this.greeting = this.translate.instant('HELPERS.goodMorning');
    } else if (hour >= 12 && hour < 18) {
      this.greeting = this.translate.instant('HELPERS.goodAfternoon');
    } else if ((hour >= 18 && hour < 22) || (hour >= 0 && hour < 4)) {
      this.greeting = this.translate.instant('HELPERS.goodEvening');
    } else {
      this.greeting = this.translate.instant('HELPERS.goodNight');
    }
  }
  getInspiration() {
    // get inspiration
    const inspiration: any[] = [
      { phrase: this.translate.instant('PHRASES.phrase1') },
      { phrase: this.translate.instant('PHRASES.phrase2') },
      { phrase: this.translate.instant('PHRASES.phrase3') },
      { phrase: this.translate.instant('PHRASES.phrase4') },
      { phrase: this.translate.instant('PHRASES.phrase5') },
      { phrase: this.translate.instant('PHRASES.phrase6') },
      { phrase: this.translate.instant('PHRASES.phrase7') },
    ];
    // update inspirationalPhrase
    this.inspirationalPhrase = inspiration[Math.floor(Math.random() * inspiration.length)].phrase;
  }
}
