// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 25/05/2023
// import
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
// translate
import { TranslateService } from '@ngx-translate/core';
// environment
import { environment } from 'src/environments/environment';
// services
import { AuthService } from 'src/app/shared/services/auth.service';
import { EncodingService } from 'src/app/shared/services/encoding.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
// interfaces
import { ProductInterface } from 'src/app/shared/interfaces/product.interface';
// component
@Component({
  selector: 'app-loan-simulator-form',
  templateUrl: './loan-simulator-form.component.html',
  styleUrls: ['./loan-simulator-form.component.scss']
})
// class
export class LoanSimulatorFormComponent implements OnInit {
  // variables
  @Input() workActivity: string = null;
  @Input() productData: ProductInterface = null;
  @Input() origin: string = null;
  @Output() loaderEvent = new EventEmitter();
  @Output() saveEvent = new EventEmitter();
  @Output() backEvent = new EventEmitter();
  @ViewChild('customAmount') customAmount: ElementRef;
  @ViewChild('customMonths') customMonths: ElementRef;
  // uri data
  mainUri: string = null;
  // form data
  public mainFrm: FormGroup;
  // messages data
  formErrorMessages: any = {};
  // help data
  toggleHelp = false;
  // loan data
  salary: number = 0;
  salaryMin: number = 1000000;
  salaryMax: number = 9999999999;
  amount: number = 0;
  loanAmountMin: number = 100000;
  loanAmountMax: number = 100000000;
  loanAmountMaxLimit: boolean = false;
  slideSelect: boolean = false;
  amountMin: number = 100000;
  amountMax: number = 100000000;
  amountCustom: boolean = false;
  amountCustomSeted: boolean = false;
  monthMin: number = 6;
  monthMax: number = 48;
  monthsCustom: boolean = false;
  monthsCustomSeted: boolean = false;
  monthSteps: number = 3;
  minAmountMsg: string = null;
  maxAmountMsg: string = null;
  minlengthMsg: string = null;
  maxlengthMsg: string = null;
  // insurance data
  showInsuranceIds: boolean = false;
  // constructor
  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public authSv: AuthService,
    public encodingSv: EncodingService,
    public apiSv: ApiService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    public utilitiesSv: UtilitiesService,
  ) {
    // main form
    this.mainFrm = this.formBuilder.group({
      salary: [null, [Validators.required, this.checkSalaryMin(), this.checkSalaryMax()]],
      amount: [null, [Validators.required, this.checkAmountMin(), this.checkAmountCustom()]],
      months: [null, [Validators.required, this.checkMonthMin(), this.checkMonthCustom()]],
      terms: [false, Validators.requiredTrue],
      loanInsuranceIds: [null],
    });
    // check for form changes
    this.mainFrm.valueChanges.subscribe(async (val) => {
      // check salary
      if (typeof val.salary === 'string') {
        // mask value
        const maskedVal = this.utilitiesSv.convertStringToCurrency(val.salary);
        // set prevAmountMax
        const prevAmountMax = this.amountMax;
        // update min and max
        await this.setMinMax();
        await this.translateMsgs();
        // set newAmountMax
        const newAmountMax = this.amountMax;
        // check amountMax
        if (prevAmountMax > newAmountMax) {
          this.mainFrm.controls.amount.setValue(newAmountMax);
        }
        // check matched values
        if (val.salary !== maskedVal) {
          this.mainFrm.patchValue({salary: maskedVal});
        }
      }
      // check amount
      if (typeof val.amount === 'string') {
        // mask value
        const maskedVal = this.utilitiesSv.convertStringToCurrency(val.amount);
        // check matched values
        if (val.amount !== maskedVal) {
          // set form value
          this.mainFrm.patchValue({amount: maskedVal});
        }
      }
      // update salary
      this.salary = Math.ceil(typeof val.salary === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(val.salary)) : val.salary);
      // update amount
      this.amount = Math.ceil(typeof val.amount === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(val.amount)) : val.amount);
    });
    // set mainUri
    this.mainUri = environment.websiteURI;
  }
  // life cycle
  async ngOnInit() {
    await this.translateMsgs();
    await this.getWorkActivityData();
    await this.getProductData();
    await this.setProductData();
    await this.getRequestData();
    await this.setMinMax();
    await this.evalTerms();
  }
  // translate
  async translateMsgs () {
    this.formErrorMessages = {
      salary: [
        { type: 'required', message: this.translate.instant(this.workActivity === 'business' ? 'LOAN.contractIncomeError' : 'LOAN.contractSalaryError') },
        { type: 'minSalary', message: this.translate.instant(this.workActivity === 'business' ? 'LOAN.contractIncomeMin' : 'LOAN.contractSalaryMin') + ' $' + this.utilitiesSv.convertStringToCurrency(this.salaryMin.toString()) },
        { type: 'maxSalary', message: this.translate.instant(this.workActivity === 'business' ? 'LOAN.contractIncomeMax' : 'LOAN.contractSalaryMax') + ' $' + this.utilitiesSv.convertStringToCurrency(this.salaryMax.toString()) },
      ],
      amount: [
        { type: 'amountCustom', message: this.translate.instant('LOAN.requestAmountSavingRequired') },
        { type: 'required', message: this.translate.instant('LOAN.requestAmountError') },
        { type: 'minAmount', message: this.translate.instant('LOAN.requestAmountMin') + ' $' + this.utilitiesSv.convertStringToCurrency(this.amountMin.toString()) },
        { type: 'maxAmount', message: this.translate.instant('LOAN.requestAmountMax') + ' $' + this.utilitiesSv.convertStringToCurrency(this.amountMax.toString()) },
      ],
      months: [
        { type: 'monthsCustom', message: this.translate.instant('LOAN.requestMonthsSavingRequired') },
        { type: 'required', message: this.translate.instant('LOAN.requestMonthsError') },
        { type: 'minMonth', message: this.translate.instant('LOAN.requestMonthsMin') + ' ' + this.utilitiesSv.convertStringToCurrency(this.monthMin.toString()) + ' ' + this.translate.instant('HELPERS.months') },
        { type: 'maxMonth', message: this.translate.instant('LOAN.requestMonthsMax') + ' ' + this.utilitiesSv.convertStringToCurrency(this.monthMax.toString()) + ' ' + this.translate.instant('HELPERS.months') },
      ],
      terms: [
        { type: 'required', message: this.translate.instant('LOAN.acceptanceRequired') },
      ],
      loanInsuranceIds: [
        { type: 'required', message: this.translate.instant('LOAN.loanInsuranceIdsError') },
      ],
    };
  }
  // data
  async evalTerms() {
    // check origin
    if (this.origin === 'b2c') {
      this.mainFrm.get('terms')?.setValidators([Validators.requiredTrue]);
      this.mainFrm.controls['terms'].updateValueAndValidity();
    } else {
      this.mainFrm.get('terms')?.setValidators(null);
      this.mainFrm.controls['terms'].updateValueAndValidity();
      this.mainFrm.controls.terms.setValue(true);
    }
  }
  async getWorkActivityData() {
    // get workActivity data
    let workActivity: any = localStorage.getItem('workActivity');
    // check workActivity data
    if (workActivity != null) {
      // set workActivity data
      this.workActivity = workActivity;
    }
  }
  async getProductData() {
    // get stored data product
    const storedDataProduct: any = localStorage.getItem('productData');
    // check product data
    if (storedDataProduct != null) {
      // get data product
      const productData: ProductInterface = JSON.parse(storedDataProduct);
      // set product data
      this.productData = productData;
    }
  }
  async setProductData() {
    // check productData
    if (this.productData != null) {
      // update loan data
      this.loanAmountMin = this.productData.loanAmountMin;
      this.loanAmountMax = this.productData.loanAmountMax;
      this.loanAmountMaxLimit = this.productData.loanAmountMaxLimit;
      this.monthMin = this.productData.loanMonthMin;
      this.monthMax = this.productData.loanMonthMax;
      this.monthSteps = this.productData.loanMonthSteps;
      // update form values
      this.mainFrm.controls.amount.setValue(this.productData.loanAmountMin);
      this.mainFrm.controls.months.setValue(this.productData.loanMonthMin);
      // check productInsurance
      if (this.productData.productInsurance) {
        // update showInsuranceIds
        this.showInsuranceIds = true;
        // set otp validators
        this.mainFrm.get('loanInsuranceIds')?.setValidators([Validators.required]);
        this.mainFrm.controls['loanInsuranceIds'].updateValueAndValidity();
      } else {
        // update showInsuranceIds
        this.showInsuranceIds = false;
        // set otp validators
        this.mainFrm.get('loanInsuranceIds')?.setValidators(null);
        this.mainFrm.controls['loanInsuranceIds'].updateValueAndValidity();
      }
    } else {
      // update loan data
      this.loanAmountMin = 100000;
      this.loanAmountMax = 100000000;
      this.loanAmountMaxLimit = true;
      this.monthMin = 6;
      this.monthMax = 48;
      this.monthSteps = 3;
      // update form values
      this.mainFrm.controls.amount.setValue(100000);
      this.mainFrm.controls.months.setValue(6);
    }
  }
  async getRequestData() {
    // get request data
    let storedRequestData: any = localStorage.getItem('requestData');
    // check request data
    if (storedRequestData != null) {
      // get request
      const requestData = JSON.parse(storedRequestData);
      // update request
      this.mainFrm.controls.salary.setValue(requestData.salary.toString());
      this.mainFrm.controls.amount.setValue(requestData.amount);
      this.mainFrm.controls.months.setValue(requestData.months);
      this.mainFrm.controls.terms.setValue(requestData.terms);
      this.mainFrm.controls.loanInsuranceIds.setValue(requestData.loanInsuranceIds);
    }
  }
  async setMinMax() {
    // get salaryValue
    const salaryValue: string = this.mainFrm.controls.salary.value === null ? '0' : this.mainFrm.controls.salary.value;
    // get salary
    const salary: number = parseInt(this.utilitiesSv.removeDotsFromCurrencyString(salaryValue));
    // get min max amount
    const amountMin: number = this.loanAmountMin;
    const amountMax: number = this.loanAmountMax;
    const limit = this.loanAmountMaxLimit;
    // init new min max amount
    let newAmountMin: number = 0;
    let newAmountMax: number = 0;
    // check if amountCustomSeted
    if (!this.amountCustomSeted) {
      // check for limit
      if (limit) {
        // set new min max amount
        newAmountMin = amountMin;
        newAmountMax = amountMax;
      } else {
        // set new min max amount
        newAmountMin = amountMin;
        newAmountMax = salary * amountMax;
      }
      // check salary
      if (salary >= 1000000) {
        // update loan data
        this.amountMin = newAmountMin;
        this.amountMax = newAmountMax;
        this.slideSelect = true;
      } else {
        this.slideSelect = false;
      }
    }
  }
  // form
  async help () {
    if (this.toggleHelp) {
      this.toggleHelp = false;
    } else {
      this.toggleHelp = true;
    }
  }
  async setFocus(elementId: any) {
    elementId.focus();
  }
  checkFormField(fieldName: string) {
    // get field
    const field = this.mainFrm.get(fieldName);
    // check field
    const check = field?.invalid && field?.touched;
    // return data
    return check;
  }
  checkSalaryMin() {
    return (control: FormControl) => {
      // check value
      if (control.value !== null) {
        // get currentSalary
        const currentSalary: number = typeof control.value === 'string' ? parseInt(this.utilitiesSv.removeDotsFromCurrencyString(control.value)) : control.value;
        // set newSalaryMin
        const newSalaryMin = this.salaryMin;
        // check salaryMin
        if (currentSalary < newSalaryMin) {
          return { minSalary: true }
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }
  checkSalaryMax() {
    return (control: FormControl) => {
      // check value
      if (control.value !== null) {
        // get currentSalary
        const currentSalary: number = typeof control.value === 'string' ? parseInt(this.utilitiesSv.removeDotsFromCurrencyString(control.value)) :control.value;
        // set newSalaryMax
        const newSalaryMax = this.salaryMax;
        // check salaryMax
        if (currentSalary > newSalaryMax) {
          return { maxSalary: true }
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }
  checkAmountMin() {
    return (control: FormControl) => {
      // check value
      if (control.value !== null) {
        // get currentAmount
        const currentAmount: number = typeof control.value === 'string' ? this.utilitiesSv.removeDotsFromCurrencyString(control.value) :control.value;
        // set newAmountMin
        const newAmountMin = this.amountMin;
        // check amountMin
        if (currentAmount < newAmountMin) {
          return { minAmount: true }
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }
  checkAmountMax() {
    return (control: FormControl) => {
      // check value
      if (control.value !== null) {
        // get currentAmount
        const currentAmount: number = typeof control.value === 'string' ? parseInt(this.utilitiesSv.removeDotsFromCurrencyString(control.value)) :control.value;
        // set newAmountMax
        const newAmountMax = this.amountMax;
        // check amountMax
        if (currentAmount > newAmountMax) {
          return { maxAmount: true }
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }
  checkAmountCustom() {
    return (control: FormControl) => {
      // check value
      if (control.value !== null) {
        // check amountCustom
        if (this.amountCustom) {
          return { amountCustom: true }
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }
  checkMonthMin() {
    return (control: FormControl) => {
      // check value
      if (control.value !== null) {
        // get currentMonth
        const currentMonth: number = control.value;
        // set newMonthMin
        const newMonthMin = this.monthMin;
        // check monthMin
        if (currentMonth < newMonthMin) {
          return { minMonth: true }
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }
  checkMonthMax() {
    return (control: FormControl) => {
      // check value
      if (control.value !== null) {
        // get currentMonth
        const currentMonth: number = control.value;
        // set newMonthMax
        const newMonthMax = this.monthMax;
        // check monthMax
        if (currentMonth > newMonthMax) {
          return { maxMonth: true }
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }
  checkMonthCustom() {
    return (control: FormControl) => {
      // check value
      if (control.value !== null) {
        // check monthsCustom
        if (this.monthsCustom) {
          return { monthsCustom: true }
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }
  // navigation
  async goBack() {
    // emit data
    this.backEvent.emit(); // Return data
  }
  async toggleCustomAmount() {
    // get currentAmount
    const currentAmount: number = typeof this.mainFrm.controls.amount.value === 'string' ? parseInt(this.utilitiesSv.removeDotsFromCurrencyString(this.mainFrm.controls.amount.value)) : this.mainFrm.controls.amount.value;
    // set newAmountMin and newAmountMax
    const newAmountMin = this.amountMin;
    const newAmountMax = this.amountMax;
    // TODO: ON FINANCIALLY CHECK MIN AND MAX
    // check amountMax
    // if (currentAmount >= newAmountMin && currentAmount <= newAmountMax) {
    if (currentAmount < newAmountMin) {
      // send alert
      const alertMin = this.translate.instant('LOAN.requestAmountMin') + ' $' + this.utilitiesSv.convertStringToCurrency(this.amountMin.toString());
      const alertMax = this.translate.instant('LOAN.requestAmountMaxAnd') + ' $' + this.utilitiesSv.convertStringToCurrency(this.amountMax.toString());
      const alertMsg = alertMin + ', ' + alertMax;
      // this.alertSv.showMessage(alertMsg, 'warning', this.translate.instant('HELPERS.warning'), true);
      this.alertSv.showMessage(alertMin, 'warning', this.translate.instant('HELPERS.warning'), true);
    } else {
      // check amountCustom
      if (this.amountCustom === true) {
        const newAmount = this.mainFrm.controls.amount.value;
        const amountVal: number = parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(newAmount));
        // update amountCustomSeted
        this.amountCustomSeted = true;
        // update new amount max
        this.amountMax = amountVal;
        // update amount data
        this.amount = amountVal;
        this.mainFrm.controls.amount.setValue(amountVal); 
        // hide amountCustom
        this.amountCustom = false;
      } else {
        const amountInt = Math.ceil(this.amount);
        const maskedVal = this.utilitiesSv.convertStringToCurrency(amountInt.toString());
        this.mainFrm.patchValue({amount: maskedVal});
        // show amountCustom
        this.amountCustom = true;
        // wait a moment
        setTimeout(() => {
          // set focus on input
          this.customAmount.nativeElement.focus();
          // select input content
          this.customAmount.nativeElement.select();
        }, 100);
      }
    }
  }
  async toggleCustomMonths() {
    // get currentMonth
    const currentMonth: number = this.mainFrm.controls.months.value;
    // set newMonth data
    const newMonthMin = this.monthMin;
    const newMonthMax = this.monthMax;
    // TODO: ON FINANCIALLY CHECK MIN AND MAX
    // check monthMax
    // if (currentMonth >= newMonthMin && currentMonth <= newMonthMax) {
    if (currentMonth < newMonthMin) {
      // send alert
      const alertMin = this.translate.instant('LOAN.requestMonthsMin') + ' ' + newMonthMin.toString() + ' ' + this.translate.instant('HELPERS.months');
      const alertMax = this.translate.instant('LOAN.requestMonthsMax') + ' ' + newMonthMax.toString() + ' ' + this.translate.instant('HELPERS.months');
      const alertMsg = alertMin + ', ' + alertMax;
      // this.alertSv.showMessage(alertMsg, 'warning', this.translate.instant('HELPERS.warning'), true);
      this.alertSv.showMessage(alertMin, 'warning', this.translate.instant('HELPERS.warning'), true);
    } else {
      // check monthsCustom
      if (this.monthsCustom === true) {
        this.monthsCustom = false;
        // update new month max
        this.monthMax = currentMonth;
      } else {
        this.monthsCustom = true;
        // wait a moment
        setTimeout(() => {
          // set focus on input
          this.customMonths.nativeElement.focus();
          // select input content
          this.customMonths.nativeElement.select();
        }, 100);
      }
    }
  }
  // actions
  async save() {
    // markAllAsTouched
    this.mainFrm.markAllAsTouched();
    // validate data
    if (!this.mainFrm.valid) {
      // send alert
      this.alertSv.showMessage(this.translate.instant('HELPERS.requiredFields'),'warning', this.translate.instant('HELPERS.warning'), true);
    } else {
      // set amountChk
      let amountChk: number = typeof this.mainFrm.controls.amount.value === 'string' ? this.utilitiesSv.removeDotsFromCurrencyString(this.mainFrm.controls.amount.value) : this.mainFrm.controls.amount.value;
      // check amount range
      if (!(amountChk >= this.amountMin && amountChk <= this.amountMax)) {
        // set alertMsg
        const alertMsg = this.translate.instant('HELPERS.minAmountValue') + ' $' + this.utilitiesSv.convertStringToCurrency(this.amountMin.toString()) + ' ' + this.translate.instant('HELPERS.maxAmountValue') + ' $' + this.utilitiesSv.convertStringToCurrency(this.amountMax.toString());
        // send alert
        this.alertSv.showMessage(alertMsg,'warning', this.translate.instant('HELPERS.warning'), true);
      } else {
        // get monthsChk
        let monthsChk: any = this.mainFrm.controls.months.value;
        // check months range
        if (monthsChk >= this.monthMin && monthsChk <= this.monthMax) {
          // call save
          await this.saveRequest();
        } else {
          // set alertMsg
          const alertMsg = this.translate.instant('HELPERS.minMonthsValue') + ' ' + this.monthMin + ' ' + this.translate.instant('HELPERS.maxMonthsValue') + ' ' + this.monthMax;
          // send alert
          this.alertSv.showMessage(alertMsg,'warning', this.translate.instant('HELPERS.warning'), true);
        }
      }
    }
  }
  async saveRequest() {
    // show loader
    this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
    // get salary
    const salary: number = typeof this.mainFrm.controls.salary.value === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(this.mainFrm.controls.salary.value)) : this.mainFrm.controls.salary.value;
    const amount: number = typeof this.mainFrm.controls.amount.value === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(this.mainFrm.controls.amount.value)) : this.mainFrm.controls.amount.value;
    // set requestData
    const requestData: any = {
      salary: salary,
      amount: amount,
      months: this.mainFrm.controls.months.value,
      terms: this.mainFrm.controls.terms.value,
      loanInsuranceIds: this.mainFrm.controls.loanInsuranceIds.value,
    }
    // emit data
    this.saveEvent.emit(requestData); // Return data
    // hide loader
    this.loaderEvent.emit(null);
  }
}
