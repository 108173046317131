// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 25/05/2023
// import
import { EventEmitter, Injectable, NgZone, Output } from '@angular/core';
// plugins
import * as moment from 'moment';
import Swal from 'sweetalert2';
// translate
import { TranslateService } from '@ngx-translate/core';
// reducers
import { Action, Store } from '@ngrx/store';
// services
import { EncodingService } from './encoding.service';
import { ApiService } from './api.service';
import { FilterService } from './filter.service';
import { UtilitiesService } from './utilities.service';
// interfaces
import { UpdateDataInterface } from '../reducers/interfaces';
import { UserInterface } from '../../shared/interfaces/user.interface';
import { CompanyInterface } from '../interfaces/company.interface';
// injectable
@Injectable({
  providedIn: 'root',
})
// export
export class AuthService {
  // variables
  @Output() loaderEvent = new EventEmitter();
  public isLoggedIn: boolean = false;
  // user data
  userData: any; // Save logged in user data
  // constructor
  constructor(
    public translate: TranslateService,
    private encodingSv: EncodingService,
    private apiSv: ApiService,
    private filterSv: FilterService,
    private utilitiesSv: UtilitiesService,
    private updateAppData: Store<UpdateDataInterface>,
  ) {}
  // functions
  async isAuthenticated(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      let status = true;
      const token = localStorage.getItem('token');
      if (token == null || token === undefined) {
        status = false;
      }
      resolve(status);
    });
  }
  async checkStatus() {
    try {
      const response: any = await this.apiSv.checkStatus();
      return response;
    } catch (error) {
      console.log('error', error);
      await this.logout();
    }
  }
  async logout() {
    // clear local storage
    localStorage.clear();
    // set is logged in as false
    this.isLoggedIn = false;
    this.loaderEvent.emit(null);
    // go to login page
    this.goToPage('auth/login');
  }
  async login(loginData: any) {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.loadingTxt'));
      let actionAfter: string = null;
      // set email
      const email = loginData.email;
      // set ia data login
      const dataLogin = {
        email,
        password: loginData.password
      };
      // login
      const response: any = await this.apiSv.login(dataLogin);
      // check status
      if (!response.error) {
        // get token
        const token = response.data.accessToken;
        // log in user
        actionAfter = await this.setLogin(email, token); 
      } else {
        // hide loader
        this.loaderEvent.emit(null);
        // handle error
        this.filterSv.handleError(response.message);
      }
      // return action
      return actionAfter;
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async setLogin(email: string, token: string): Promise<string> {
    let actionAfter: string = null;
    // get user data
    const userData: any = await this.encodingSv.decodeToken(token);

    console.log('userData', userData);

    // check user status
    if (userData.userStatus === 'unactive') {
      // hide loader
      this.loaderEvent.emit(null);
      // show alert
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: this.translate.instant('LOGIN.unactive'),
        showConfirmButton:  true,
        confirmButtonText: this.translate.instant('HELPERS.accept'),
        allowOutsideClick: false,
        customClass: {
            popup: 'animated tada swal-wide'
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          // logout
          actionAfter = 'logout';
        }
      });
    } else {
      // save token on storage
      localStorage.setItem('token' , token);
      // get userRoles
      const userRoles: any = typeof userData.userRoles === 'string' ? JSON.parse(userData.userRoles) : userData.userRoles;
      // get currentRole
      const currentRole: any = this.utilitiesSv.getGreaterActiveRole(userRoles);
      // set userRole
      userData.role = currentRole.roleName;
      userData.userRole = currentRole;
      userData.userRoles = userRoles;
      // save user data on storage
      localStorage.setItem('userData' , JSON.stringify(userData));
      // mark as login
      this.isLoggedIn = true;
      // save loggedIn status on storage
      localStorage.setItem('isLoggedIn' , 'true');
      // save email on storage
      localStorage.setItem('email' , email);
      // hide loader
      this.loaderEvent.emit(null);
      // check user status
      if (userData.userStatus === 'pending') {
        // action confirm
        actionAfter = 'confirm';
      } else {
        // check if user is from a company
        if ((userData.role == 'ceo' || userData.role == 'manager' || userData.role == 'auxiliar' || userData.role == 'client')) {
          
          // TODO: CHECK USER SELECT COMPANIES
          
          // get company data
          const companyData: any = await this.apiSv.getUserCompanies(userData.id);
          // get companies
          const companies: any = companyData.data;

          console.log('companies', companies);

          // set company data on storage
          localStorage.setItem('dataCompanies', JSON.stringify(companies));
          // check total user companies
          if (companies.length > 1) {
            // init companies select
            let companiesSelect: any[] = [];
            // populate companies select
            companies.map((company: CompanyInterface) => {
              companiesSelect.push(company.companyName);
            });
            // show alert so user can select a company
            Swal.fire({
              title: this.translate.instant('BRAND.company'),
              text: this.translate.instant('BRAND.companySelectWork'),
              confirmButtonText: this.translate.instant('HELPERS.continue'),
              input: 'select',
              inputOptions: companiesSelect,
              inputPlaceholder: this.translate.instant('BRAND.companySelect'),
              allowOutsideClick: false,
            }).then(async (result: any) => {
              if (result.isConfirmed) {
                // set company data on storage
                localStorage.setItem('companyData', JSON.stringify(companies[result.value]));
                // set company id on storage
                localStorage.setItem('companyId', companies[result.value].id);
                // update company info
                const action: Action = {
                  type: 'UPDATECOMPANY',
                }
                this.updateAppData.dispatch(action);
                // login user
                actionAfter = 'login';
              }
            });
          } else if (companies.length === 1) {
            // set company data on storage
            localStorage.setItem('companyData', JSON.stringify(companies[0]));
            // set company id on storage
            localStorage.setItem('companyId', companies[0].id);
            // login user
            actionAfter = 'login';
          } else {
            // login user
            actionAfter = 'login';
          }
        } else {
          // login user
          actionAfter = 'login';
        }
      }
    }
    // return action
    return actionAfter;
  }
  async setUserLogInStatus(status: boolean) {
    // mark as login
    this.isLoggedIn = status;
  }
  // check session time
  async checkSession() {
    // set develop data
    const develop = false;
    // set session data
    let returnUri = null;
    let returnReplace = false;
    let menuStatus = false
    let alertMsg = null;
    let logout = false;
    // get token
    const token: any = localStorage.getItem('token');
    // check token
    if (token) {
      // get main url
      const mainUri: any = localStorage.getItem('mainUri');
      // get stored data user
      const storedDataUser: any = localStorage.getItem('userData');
      // get data user
      const userData: UserInterface = JSON.parse(storedDataUser);
      // get userId
      const userId = userData.id;
      // check develop
      if (!develop) {
        // check token
        if (token != null || token != undefined) {
          // mark as not login
          this.isLoggedIn = false;
          // get user status
          const userStatus: any = await this.checkStatus();  
          // check user status
          if (userStatus.data.userStatus === 'unactive') {
            logout = true;
            alertMsg = this.translate.instant('LOGIN.unactive');
          } else if (userData.userStatus === 'pendingApproval') {
            logout = true;
            alertMsg = this.translate.instant('LOGIN.pendingApproval');
          } else if (userData.userStatus === 'pendingChanges') {
            logout = true;
            alertMsg = this.translate.instant('LOGIN.pendingChanges');
          } else if (userStatus.data.userStatus === 'pending') {
            // go to page
            returnUri = 'auth/confirmation/' + userData.email + '/register';
          } else {
            // set main url
            returnUri = mainUri;
            // enable menu
            menuStatus = true;
          }
        } else {
          // mark as login
          this.isLoggedIn = true;
          // check main url
          if (mainUri == null || mainUri == undefined) {
            // go to page
            returnUri = 'auth/login';
          } else {
            // go to page
            returnUri = mainUri;
          }
        }
      } else {
        const devUri = '/';
        localStorage.setItem('mainUri', devUri);
        menuStatus = true;
        // go to page
        returnUri = devUri;
      }
    } else {
      localStorage.setItem('mainUri', '/');
      menuStatus = false;
      // go to page
      returnUri = '/';
    }
    // build check data
    const checkData = {
      returnUri,
      returnReplace,
      menuStatus,
      alertMsg,
      logout,
    }
    // return
    return checkData;
  }
  async checkSessionTime() {
    let diffMinutes = 0;
    // get current time
    const currentTime: any = moment();
    // get stored session time
    const storedTime: any = moment(localStorage.getItem('activeSessionTime'));
    // check stored time
    if (storedTime === null || storedTime === undefined) {
      const currentStoreTime: any = new Date();
      console.log('currentStoreTime', currentStoreTime);
      // set active session time
      localStorage.setItem('activeSessionTime', currentStoreTime);
    } else {
      // get time difference
      diffMinutes = currentTime.diff(storedTime, 'minutes');
    }
    return diffMinutes;
  }
  // navigation
  goToPage(uri: any) {
    setTimeout(() => {
      // navigate to page
      this.utilitiesSv.goTo(uri, true, null);
    }, 500);
  }
}