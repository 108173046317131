import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragulaModule } from 'ng2-dragula';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { ArchwizardModule } from 'angular-archwizard';
import { NgParticlesModule } from 'ng-particles';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgApexchartsModule } from "ng-apexcharts";
import { TabsModule } from 'ngx-tabset';
import { ImageCropperModule } from 'ngx-image-cropper';

// import { KnobModule } from "angular2-knob";
// Components
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CustomizerComponent } from './components/customizer/customizer.component';
import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderBaseComponent } from './components/header/header-base.component';
import { HeaderComponent } from './components/header/header.component';
import { ContentComponent } from './components/layout/content/content.component';
import { BaseComponent } from './components/layout/base/base.component';
import { LoaderComponent } from './components/loader/loader.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';
// Header Elements Components
import { SearchComponent } from './components/header/elements/search/search.component';
import { MegaMenuComponent } from './components/header/elements/mega-menu/mega-menu.component';
import { LanguagesComponent } from './components/header/elements/languages/languages.component';
import { NotificationComponent } from './components/header/elements/notification/notification.component';
import { BookmarkComponent } from './components/header/elements/bookmark/bookmark.component';
import { CartComponent } from './components/header/elements/cart/cart.component';
import { MessageBoxComponent } from './components/header/elements/message-box/message-box.component';
import { MyAccountComponent } from './components/header/elements/my-account/my-account.component';
// Auth Components
import { LoginFormComponent } from '../components/auth/login-form/login-form.component';
import { EmailFormComponent } from '../components/auth/email-form/email-form.component';
import { ChangePwdFormComponent } from '../components/auth/change-pwd-form/change-pwd-form.component';
import { OTPFormComponent } from '../components/auth/otp-form/otp-form.component';
import { PinCodeFormComponent } from '../components/auth/pin-code-form/pin-code-form.component';
// Own Components
import { ErrorDataComponent } from './components/error-data/error-data.component';
import { LoaderInnerComponent } from './components/loader-inner/loader-inner.component';
import { NoDataComponent } from './components/no-data/no-data.component';
import { ChartsComponent } from './components/charts/charts.component';
// Dashboard Components
import { DashboardTempComponent } from '../components/dashboard/dashboard-temp/dashboard-temp.component';
import { DashboardAdminComponent } from '../components/dashboard/dashboard-admin/dashboard-admin.component';
import { DashboardCompanyComponent } from '../components/dashboard/dashboard-company/dashboard-company.component';
import { DashboardUserComponent } from '../components/dashboard/dashboard-user/dashboard-user.component';
import { DashboardWelcomeComponent } from '../components/dashboard/dashboard-welcome/dashboard-welcome.component';
import { TotalPendingComponent } from '../components/dashboard/totals/total-pending/total-pending.component';
import { TotalNoPassedValidationComponent } from '../components/dashboard/totals/total-no-passed-validation/total-no-passed-validation.component';
import { TotalReadyToApproveComponent } from '../components/dashboard/totals/total-ready-to-approve/total-ready-to-approve.component';
import { TotalApprovedComponent } from '../components/dashboard/totals/total-approved/total-approved.component';
import { TotalRejectedComponent } from '../components/dashboard/totals/total-rejected/total-rejected.component';
import { TotalPayedComponent } from '../components/dashboard/totals/total-payed/total-payed.component';
import { TotalToCollectComponent } from '../components/dashboard/totals/total-to-collect/total-to-collect.component';
import { TotalDisbursedComponent } from '../components/dashboard/totals/total-disbursed/total-disbursed.component';
import { LoanProductsComponent } from '../components/dashboard/loan-products/loan-products.component';
import { LoanApprovedVsRequestedComponent } from '../components/dashboard/loan-approved-vs-requested/loan-approved-vs-requested.component';
import { LoanWorkActivitiesComponent } from '../components/dashboard/loan-work-activities/loan-work-activities.component';
import { LoanTop5Component } from '../components/dashboard/loan-top-5/loan-top-5.component';

import { LoansResumeComponent } from '../components/dashboard/loans-resume/loans-resume.component';
import { MonthlyProfitsComponent } from '../components/dashboard/monthly-profits/monthly-profits.component';
import { OrdersProfitComponent } from '../components/dashboard/orders-profit/orders-profit.component';
import { OrdersComponent } from '../components/dashboard/orders-profit/orders/orders.component';
import { ProfitComponent } from '../components/dashboard/orders-profit/profit/profit.component';
import { OrderOverviewComponent } from '../components/dashboard/order-overview/order-overview.component';
import { RightChartDataComponent } from '../components/dashboard/order-overview/right-chart-data/right-chart-data.component';

// Config Components
import { ConfigFormComponent } from '../components/config/config-form/config-form.component';
// Legal Components
import { TermsFormComponent } from '../components/legal/terms-form/terms-form.component';
import { PrivacyFormComponent } from '../components/legal/privacy-form/privacy-form.component';
// Bank Components
import { BanksCardComponent } from '../components/config/banks/banks-card/banks-card.component';
import { BankFormComponent } from '../components/config/banks/bank-form/bank-form.component';
// Occupation Components
import { OccupationsCardComponent } from '../components/config/occupations/occupations-card/occupations-card.component';
import { OccupationFormComponent } from '../components/config/occupations/occupation-form/occupation-form.component';
// Position Components
import { PositionsCardComponent } from '../components/config/positions/positions-card/positions-card.component';
import { PositionFormComponent } from '../components/config/positions/position-form/position-form.component';
// Profession Components
import { ProfessionsCardComponent } from '../components/config/professions/professions-card/professions-card.component';
import { ProfessionFormComponent } from '../components/config/professions/profession-form/profession-form.component';
// Company Components
import { CompanyFormComponent } from '../components/companies/company-form/company-form.component';
import { CompaniesCardComponent } from '../components/companies/companies-card/companies-card.component';
import { CompanyCardComponent } from '../components/companies/company-card/company-card.component';
import { CompanyLegalCardComponent } from '../components/companies/company-legal-card/company-legal-card.component';
import { CompanyClientListComponent } from '../components/companies/company-client-list/company-client-list.component';
import { CompanyLinkComponent } from '../components/companies/company-link/company-link.component';

// Company Activities Components
import { CompanyActivitiesCardComponent } from '../components/company-activities/company-activities-card/company-activities-card.component';
import { CompanyActivityCardComponent } from '../components/company-activities/company-activity-card/company-activity-card.component';
import { CompanyActivityFormComponent } from '../components/company-activities/company-activity-form/company-activity-form.component';
// Product Components
import { ProductCardComponent } from '../components/products/product-card/product-card.component';
import { ProductsCardComponent } from '../components/products/products-card/products-card.component';
import { ProductFormComponent } from '../components/products/product-form/product-form.component';
import { ProductClientCardComponent } from '../components/products/product-client-card/product-client-card.component';
// Product Categories Components
import { ProductCategoriesCardComponent } from '../components/product-categories/product-categories-card/product-categories-card.component';
import { ProductCategoryFormComponent } from '../components/product-categories/product-category-form/product-category-form.component';
// User Components
import { UserCardComponent } from '../components/users/user-card/user-card.component';
import { UserValidationComponent } from '../components/users/user-validation/user-validation.component';
import { UserFormComponent } from '../components/users/user-form/user-form.component';
import { UserCompanyCardComponent } from '../components/users/user-company/user-company-card/user-company-card.component';
import { UserCompanyFormComponent } from '../components/users/user-company/user-company-form/user-company-form.component';
import { UserAddressesListComponent } from '../components/users/user-address/user-addresses-list/user-addresses-list.component';
import { UserAddressFormComponent } from '../components/users/user-address/user-address-form/user-address-form.component';
import { UserAddressesComponent } from '../components/users/user-address/user-addresses/user-addresses.component';
import { UserBankAccountsComponent } from '../components/users/user-bank-account/user-bank-accounts/user-bank-accounts.component';
import { UserBankAccountFormComponent } from '../components/users/user-bank-account/user-bank-account-form/user-bank-account-form.component';
import { UserFinancialCardComponent } from '../components/users/user-financial/user-financial-card/user-financial-card.component';
import { UserFinancialFormComponent } from '../components/users/user-financial/user-financial-form/user-financial-form.component';
import { UserReferralCardComponent } from '../components/users/user-referral/user-referral-card/user-referral-card.component';
import { UserReferralFormComponent } from '../components/users/user-referral/user-referral-form/user-referral-form.component';
import { UserWorkCardComponent } from '../components/users/user-work/user-work-card/user-work-card.component';
import { UserWorkFormComponent } from '../components/users/user-work/user-work-form/user-work-form.component';
import { UsersCardComponent } from '../components/users/users-card/users-card.component';
// Loan Components
import { LoanListTableComponent } from '../components/loans/loan-list-table/loan-list-table.component';
import { LoanCardComponent } from '../components/loans/loan-card/loan-card.component';
import { LoanItemComponent } from '../components/loans/loan-item/loan-item.component';
import { LoanSimulatorFormComponent } from '../components/loans/loan-simulator-form/loan-simulator-form.component';
import { LoanSimulatorFormShortComponent } from '../components/loans/loan-simulator-form-short/loan-simulator-form-short.component';
import { LoanDocumentsComponent } from '../components/loans/loan-documents/loan-documents.component';
import { LoanDocumentPreviewComponent } from '../components/loans/loan-document-preview/loan-document-preview.component';
import { LoanDocumentUploadComponent } from '../components/loans/loan-document-upload/loan-document-upload.component';
import { LoanPaymentsComponent } from '../components/loans/loan-payments/loan-payments.component';
// Batch Components
import { BatchCardComponent } from '../components/batches/batch-card/batch-card.component';
import { BatchFormComponent } from '../components/batches/batch-form/batch-form.component';
// Directives
import { DisableKeyPressDirective } from './directives/disable-key-press.directive';
import { OnlyAlphabetsDirective } from './directives/only-alphabets.directive';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { ShowOptionsDirective } from './directives/show-options.directive';
import { NgbdSortableHeader } from './directives/NgbdSortableHeader';
// Pipes
import { DecimalPipe } from '@angular/common';
import { MomentPipe } from './pipes/moment-pipe.pipe';
import { TimeElapsedPipe } from './pipes/time-elapsed-pipe.pipe';
import { MainUriPipe } from './pipes/main-uri-pipe.pipe';
import { MaskSensitiveDataPipe } from './pipes/masked-sensitive-data-pipe.pipe';
import { CurrencyFormatPipe } from './pipes/udp-currency-mask.pipe';
import { bankAccountNumberPipe } from './pipes/bank-account-number-pipe.pipe';
import { LeadingZerosPipe } from './pipes/leading-zeros-pipe.pipe';
import { PhoneCodePipe } from './pipes/phone-code-pipe.pipe';
import { PhonePipe } from './pipes/phone-pipe.pipe';
import { PercentagePipe } from './pipes/percentage-pipe.pipe';
// Services
import { AlertService } from './services/alert.service';
import { ApiService } from './services/api.service';
import { BreadcrumbsService } from './services/breadcrumbs.service';
import { ChatService } from './services/chat.service';
import { FilterService } from './services/filter.service';
import { EncodingService } from './services/encoding.service';
import { LayoutService } from './services/layout.service';
import { LocationService } from './services/location.service';
import { ModalService } from './services/modal.service';
import { NavService } from './services/nav.service';
import { TableService } from './services/table.service';
import { TasksService } from './services/tasks.service';
import { TreeViewService } from './services/tree-view.service';
import { UtilitiesService } from './services/utilities.service';
import { MediaService } from './services/media.service';
@NgModule({
  declarations: [
    // components
    HeaderBaseComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ContentComponent,
    BreadcrumbComponent,
    CustomizerComponent,
    FeatherIconsComponent,
    BaseComponent,
    LoaderComponent,
    TapToTopComponent,
    SearchComponent,
    MegaMenuComponent,
    LanguagesComponent,
    NotificationComponent,
    BookmarkComponent,
    CartComponent,
    MessageBoxComponent,
    MyAccountComponent,
    NgbdSortableHeader,
    ErrorDataComponent,
    LoaderInnerComponent,
    NoDataComponent,
    ChartsComponent,
    // auth
    LoginFormComponent,
    EmailFormComponent,
    ChangePwdFormComponent,
    OTPFormComponent,
    PinCodeFormComponent,
    // dashboard
    DashboardTempComponent,
    DashboardAdminComponent,
    DashboardCompanyComponent,
    DashboardUserComponent,
    DashboardWelcomeComponent,
    TotalPendingComponent,
    TotalNoPassedValidationComponent,
    TotalReadyToApproveComponent,
    TotalApprovedComponent,
    TotalRejectedComponent,
    TotalPayedComponent,
    TotalToCollectComponent,
    TotalDisbursedComponent,
    LoanProductsComponent,
    LoanApprovedVsRequestedComponent,
    LoanWorkActivitiesComponent,
    LoanTop5Component,

    LoansResumeComponent,
    MonthlyProfitsComponent,
    OrdersProfitComponent,
    OrdersComponent,
    ProfitComponent,
    OrderOverviewComponent,
    RightChartDataComponent,
    // config
    ConfigFormComponent,
    // legal
    TermsFormComponent,
    PrivacyFormComponent,
    // bank
    BanksCardComponent,
    BankFormComponent,
    // occupation
    OccupationsCardComponent,
    OccupationFormComponent,
    // position
    PositionsCardComponent,
    PositionFormComponent,
    // profession
    ProfessionsCardComponent,
    ProfessionFormComponent,
    // companies
    CompanyFormComponent,
    CompaniesCardComponent,
    CompanyCardComponent,
    CompanyLegalCardComponent,
    CompanyClientListComponent,
    CompanyLinkComponent,
    // company activities
    CompanyActivitiesCardComponent,
    CompanyActivityCardComponent,
    CompanyActivityFormComponent,
    // products
    ProductCardComponent,
    ProductsCardComponent,
    ProductFormComponent,
    ProductClientCardComponent,
    // product categories
    ProductCategoriesCardComponent,
    ProductCategoryFormComponent,
    // users
    UserCardComponent,
    UserValidationComponent,
    UserFormComponent,
    UserCompanyCardComponent,
    UserCompanyFormComponent,
    UserAddressesListComponent,
    UserAddressFormComponent,
    UserAddressesComponent,
    UserBankAccountsComponent,
    UserBankAccountFormComponent,
    UserFinancialCardComponent,
    UserFinancialFormComponent,
    UserReferralCardComponent,
    UserReferralFormComponent,
    UserWorkCardComponent,
    UserWorkFormComponent,
    UsersCardComponent,
    // loans
    LoanListTableComponent,
    LoanCardComponent,
    LoanItemComponent,
    LoanSimulatorFormComponent,
    LoanSimulatorFormShortComponent,
    LoanDocumentsComponent,
    LoanDocumentPreviewComponent,
    LoanDocumentUploadComponent,
    LoanPaymentsComponent,
    // batches
    BatchCardComponent,
    BatchFormComponent,
    // directives
    ShowOptionsDirective,
    DisableKeyPressDirective,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    // pipes
    MomentPipe,
    TimeElapsedPipe,
    MainUriPipe,
    MaskSensitiveDataPipe,
    CurrencyFormatPipe,
    bankAccountNumberPipe,
    LeadingZerosPipe,
    PhoneCodePipe,
    PhonePipe,
    PercentagePipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    DragulaModule.forRoot(),
    TranslateModule.forChild({}),
    NgParticlesModule,
    NgSelectModule,
    ArchwizardModule,
    NgxFileDropModule,
    NgxSpinnerModule,
    AngularEditorModule,
    CKEditorModule,
    NgApexchartsModule,
    TabsModule,
    ImageCropperModule,
  ],
  providers: [
    // pipes
    DecimalPipe,
    CurrencyFormatPipe,
    bankAccountNumberPipe,
    // services
    AlertService,
    ApiService,
    BreadcrumbsService,
    ChatService,
    FilterService,
    EncodingService,
    LayoutService,
    LocationService,
    ModalService,
    NavService,
    TableService,
    TasksService,
    TreeViewService,
    UtilitiesService,
    MediaService,
  ],
  exports: [
    // modules
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgSelectModule,
    ArchwizardModule,
    NgxFileDropModule,
    NgxSpinnerModule,
    AngularEditorModule,
    CKEditorModule,
    NgApexchartsModule,
    // components
    HeaderBaseComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ContentComponent,
    BreadcrumbComponent,
    CustomizerComponent,
    FeatherIconsComponent,
    BaseComponent,
    LoaderComponent,
    TapToTopComponent,
    SearchComponent,
    MegaMenuComponent,
    LanguagesComponent,
    NotificationComponent,
    BookmarkComponent,
    CartComponent,
    MessageBoxComponent,
    MyAccountComponent,
    NgbdSortableHeader,
    ErrorDataComponent,
    LoaderInnerComponent,
    NoDataComponent,
    ChartsComponent,
    // auth
    LoginFormComponent,
    EmailFormComponent,
    ChangePwdFormComponent,
    OTPFormComponent,
    PinCodeFormComponent,
    // dashboard
    DashboardTempComponent,
    DashboardAdminComponent,
    DashboardCompanyComponent,
    DashboardUserComponent,
    DashboardWelcomeComponent,
    TotalPendingComponent,
    TotalNoPassedValidationComponent,
    TotalReadyToApproveComponent,
    TotalApprovedComponent,
    TotalRejectedComponent,
    TotalPayedComponent,
    TotalToCollectComponent,
    TotalDisbursedComponent,
    LoanProductsComponent,
    LoanApprovedVsRequestedComponent,
    LoanWorkActivitiesComponent,
    LoanTop5Component,

    LoansResumeComponent,
    MonthlyProfitsComponent,
    OrdersProfitComponent,
    OrdersComponent,
    ProfitComponent,
    OrderOverviewComponent,
    RightChartDataComponent,
    // config
    ConfigFormComponent,
    // legal
    TermsFormComponent,
    PrivacyFormComponent,
    // bank
    BanksCardComponent,
    BankFormComponent,
    // occupation
    OccupationsCardComponent,
    OccupationFormComponent,
    // position
    PositionsCardComponent,
    PositionFormComponent,
    // profession
    ProfessionsCardComponent,
    ProfessionFormComponent,
    // companies
    CompanyFormComponent,
    CompaniesCardComponent,
    CompanyCardComponent,
    CompanyLegalCardComponent,
    CompanyClientListComponent,
    CompanyLinkComponent,
    // company activities
    CompanyActivitiesCardComponent,
    CompanyActivityCardComponent,
    CompanyActivityFormComponent,
    // products
    ProductCardComponent,
    ProductsCardComponent,
    ProductFormComponent,
    ProductClientCardComponent,
    // product categories
    ProductCategoriesCardComponent,
    ProductCategoryFormComponent,
    // users
    UserCardComponent,
    UserValidationComponent,
    UserFormComponent,
    UserCompanyCardComponent,
    UserCompanyFormComponent,
    UserAddressesListComponent,
    UserAddressFormComponent,
    UserAddressesComponent,
    UserBankAccountsComponent,
    UserBankAccountFormComponent,
    UserFinancialCardComponent,
    UserFinancialFormComponent,
    UserReferralCardComponent,
    UserReferralFormComponent,
    UserWorkCardComponent,
    UserWorkFormComponent,
    UsersCardComponent,
    // loans
    LoanListTableComponent,
    LoanCardComponent,
    LoanItemComponent,
    LoanSimulatorFormComponent,
    LoanSimulatorFormShortComponent,
    LoanDocumentsComponent,
    LoanDocumentPreviewComponent,
    LoanDocumentUploadComponent,
    LoanPaymentsComponent,
    // batches
    BatchCardComponent,
    BatchFormComponent,
    // directives
    DisableKeyPressDirective,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    NgbdSortableHeader,
    NgParticlesModule,
    // pipes
    MomentPipe,
    TimeElapsedPipe,
    MainUriPipe,
    MaskSensitiveDataPipe,
    CurrencyFormatPipe,
    bankAccountNumberPipe,
    LeadingZerosPipe,
    PhoneCodePipe,
    PhonePipe,
    PercentagePipe,
  ],
})
export class SharedModule { }
