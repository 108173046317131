<!-- company-employees -->
<div class="table-responsive" *ngIf="listType === 'company-employees'">
  <table class="table table-hover" [ngClass]="{'table-user-support': isSupport, 'table-user-financial': (isAdmin || isFinancial), 'table-user-company': isCompany}">
    <!-- table header -->
    <thead>
      <tr>
        <th class="col-avatar text-center align-middle" scope="col"><p class="table-header-title">{{ 'USER.formLabels.avatar' | translate }}</p></th>
        <th class="col-document align-middle" scope="col"><p class="table-header-title">{{ 'USER.formLabels.document' | translate }}</p></th>
        <th class="col-firstName align-middle" scope="col"><p class="table-header-title">{{ 'USER.formLabels.firstName' | translate }}</p></th>
        <th class="col-lastName align-middle" scope="col"><p class="table-header-title">{{ 'USER.formLabels.lastName' | translate }}</p></th>
        <th class="col-email align-middle" scope="col"><p class="table-header-title">{{ 'USER.formLabels.email' | translate }}</p></th>
        <th class="col-cellphone align-middle" scope="col"><p class="table-header-title">{{ 'USER.formLabels.cellphone' | translate }}</p></th>
        <th class="col-role align-middle" scope="col"><p class="table-header-title">{{ 'USER.formLabels.role' | translate }}</p></th>
        <th class="col-status align-middle" scope="col"><p class="table-header-title">{{ 'USER.formLabels.statusShort' | translate }}</p></th>
      </tr>
    </thead>
    <!-- table body -->
    <tbody>
      <tr *ngFor="let user of users">
        <td class="col-avatar text-center align-middle table-user" scope="col">
          <div class="media small-media">
            <div class="media-left">
              <img class="media-object rounded-circle" [src]="user.avatar" [alt]="user.firstName" onError="src = 'assets/images/broken-avatar.png'">
            </div>
          </div>
        </td>
        <td class="col-document align-middle item-number" scope="col" (click)="openUser(user)">{{ user.documentNumber }}</td>
        <td class="col-firstName align-middle" scope="col">{{ user.firstName }}</td>
        <td class="col-lastName align-middle" scope="col">{{ user.lastName }}</td>
        <td class="col-email align-middle" scope="col">
          <a [href]="'mailto: ' + user.email" target="_blank" rel="noopener noreferrer">{{ user.email }}</a>
        </td>
        <td class="col-cellphone align-middle" scope="col">
          <a [href]="'tel:+' + user.cellphoneCountryCodeCod + user.cellphone" target="_blank" rel="noopener noreferrer">+{{ user.cellphoneCountryCodeCod }}&nbsp;{{ user.cellphone | phonePipe }}</a>
        </td>
        <td class="col-role align-middle text-center">
          <p>
            <span *ngIf="user.role === 'support'">{{ 'USER.formLabels.roleSupport' | translate }}</span>
            <span *ngIf="user.role === 'administrator'">{{ 'USER.formLabels.roleAdministrator' | translate }}</span>
            <span *ngIf="user.role === 'board'">{{ 'USER.formLabels.roleBoard' | translate }}</span>
            <span *ngIf="user.role === 'treasure'">{{ 'USER.formLabels.roleTreasure' | translate }}</span>
            <span *ngIf="user.role === 'businessAgent'">{{ 'USER.formLabels.roleBusinessAgent' | translate }}</span>
            <span *ngIf="user.role === 'ceo'">{{ 'USER.formLabels.roleCEO' | translate }}</span>
            <span *ngIf="user.role === 'manager'">{{ 'USER.formLabels.roleManager' | translate }}</span>
            <span *ngIf="user.role === 'auxiliar'">{{ 'USER.formLabels.roleAuxiliar' | translate }}</span>
            <span *ngIf="user.role === 'client' && !(isCompany || isClient)">{{ 'USER.formLabels.roleClient' | translate }}</span>
            <span *ngIf="user.role === 'client' && (isCompany || isClient)">{{ 'USER.formLabels.roleEmployee' | translate }}</span>
          </p>
        </td>
        <td class="col-status align-middle text-center">
          <p class="table-status-txt">
            <span class="status-started" *ngIf="user.userStatus === 'pending'">{{ 'USER.formLabels.statusPending' | translate }}</span>
            <span class="status-preApproved" *ngIf="user.userStatus === 'active'">{{ 'USER.formLabels.statusActive' | translate }}</span>
            <span class="status-rejected" *ngIf="user.userStatus === 'unactive'">{{ 'USER.formLabels.statusUnactive' | translate }}</span>
          </p>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<!-- table -->
<div class="table-responsive" *ngIf="listType === 'list'">
  <table class="table table-hover" [ngClass]="{'table-user-support': isSupport, 'table-user-financial': (isAdmin || isFinancial), 'table-user-company': isCompany}">
    <!-- table header -->
    <thead>
      <tr>
        <th class="col-company text-center align-middle" scope="col" *ngIf="userType === 'ceo' || userType === 'manager' || userType === 'auxiliar' || userType === 'client' || userType === 'employee'"><p class="table-header-title">{{ 'COMPANY.company' | translate }}</p></th>
        <th class="col-avatar text-center align-middle" scope="col"><p class="table-header-title">{{ 'USER.formLabels.avatar' | translate }}</p></th>
        <th class="col-document align-middle" scope="col"><p class="table-header-title">{{ 'USER.formLabels.document' | translate }}</p></th>
        <th class="col-firstName align-middle" scope="col"><p class="table-header-title">{{ 'USER.formLabels.firstName' | translate }}</p></th>
        <th class="col-lastName align-middle" scope="col"><p class="table-header-title">{{ 'USER.formLabels.lastName' | translate }}</p></th>
        <th class="col-email align-middle" scope="col"><p class="table-header-title">{{ 'USER.formLabels.email' | translate }}</p></th>
        <th class="col-cellphone align-middle" scope="col"><p class="table-header-title">{{ 'USER.formLabels.cellphone' | translate }}</p></th>
        <th class="col-status align-middle" scope="col"><p class="table-header-title">{{ 'USER.formLabels.statusShort' | translate }}</p></th>
        <th class="col-delete align-middle" scope="col" *ngIf="deleteEnable"></th>
      </tr>
    </thead>
    <!-- table body -->
    <tbody>
      <tr *ngFor="let user of users">
        <td class="col-company text-center align-middle table-user" scope="col" *ngIf="userType === 'ceo' || userType === 'manager' || userType === 'auxiliar' || userType === 'client' || userType === 'employee'">
          <div class="companies-container" style="--z-index-start: 1;">
            <div class="media small-media company-item" *ngFor="let company of user.userCompanies">
              <div class="media-left">
                <img class="media-object rounded-circle" [src]="company.companyLogo" [alt]="company.businessName" onError="src = 'assets/images/broken-avatar.png'">
              </div>
            </div>
          </div>
        </td>
        <td class="col-avatar text-center align-middle table-user" scope="col">
          <div class="media small-media">
            <div class="media-left">
              <img class="media-object rounded-circle" [src]="user.avatar" [alt]="user.firstName" onError="src = 'assets/images/broken-avatar.png'">
            </div>
          </div>
        </td>
        <td class="col-document align-middle item-number" scope="col" (click)="openUser(user)">{{ user.documentNumber }}</td>
        <td class="col-firstName align-middle" scope="col">{{ user.firstName }}</td>
        <td class="col-lastName align-middle" scope="col">{{ user.lastName }}</td>
        <td class="col-email align-middle" scope="col">
          <a [href]="'mailto: ' + user.email" target="_blank" rel="noopener noreferrer">{{ user.email }}</a>
        </td>
        <td class="col-cellphone align-middle" scope="col">
          <a [href]="'tel:+' + user.cellphoneCountryCodeCod + user.cellphone" target="_blank" rel="noopener noreferrer">+{{ user.cellphoneCountryCodeCod }}&nbsp;{{ user.cellphone | phonePipe }}</a>
        </td>
        <td class="col-status align-middle text-center">
          <p class="table-status-txt">
            <span class="status-started" *ngIf="user.userStatus === 'pending'">{{ 'USER.formLabels.statusPending' | translate }}</span>
            <span class="status-preApproved" *ngIf="user.userStatus === 'active'">{{ 'USER.formLabels.statusActive' | translate }}</span>
            <span class="status-rejected" *ngIf="user.userStatus === 'unactive'">{{ 'USER.formLabels.statusUnactive' | translate }}</span>
          </p>
        </td>
        <td *ngIf="deleteEnable" class="col-button align-middle">
          <a class="btn btn-danger btn-xs btn-icon-only" (click)="deleteUser(user)">
            <app-feather-icons [icon]="'trash'"></app-feather-icons>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>


