<!-- data -->
<div class="row">
  <div class="list-group address-selection" *ngIf="userAddresses.length > 0">
    <div class="list-group-item list-group-item-action" *ngFor="let userAddress of userAddresses">
      <!-- address data -->
      <p class="address-type">
        <b *ngIf="userAddress.addressType === 'work'">{{ 'LOCATION.formLabels.addressTypeWork' | translate }}</b>
        <b *ngIf="userAddress.addressType === 'home'">{{ 'LOCATION.formLabels.addressTypeHome' | translate }}</b>
        <b *ngIf="userAddress.addressType === 'other'">{{ 'LOCATION.formLabels.addressTypeOther' | translate }}</b>
      </p>
      <p class="address-detail">
        <span>{{ userAddress.address }}<br></span>
        <span *ngIf="userAddress.address2 !== null">{{ userAddress.address2 }}<br></span>
        <span>{{ userAddress.cityName }}</span> - <span>{{ userAddress.stateName }}</span> - <span>{{ userAddress.countryName }}</span>
      </p>
      <div class="mailing-address-container" *ngIf="userAddress.mailingAddress">
        <a class="btn btn-primary btn-xs btn-icon-only mailing-address">
          <app-feather-icons [icon]="'mail'"></app-feather-icons>
        </a>
      </div>
      <div class="list-group-btns" *ngIf="editEnable">
        <a class="btn btn-success btn-xs btn-icon-only btn-option" (click)="editAddress(userAddress)">
          <app-feather-icons [icon]="'edit-3'"></app-feather-icons>
        </a>
        <a class="btn btn-danger btn-xs btn-icon-only btn-option" (click)="deleteAddress(userAddress.id)">
          <app-feather-icons [icon]="'trash'"></app-feather-icons>
        </a>
      </div>
    </div>
  </div>
</div>
<!-- no data -->
<div class="row mt-4" *ngIf="userAddresses.length === 0">
  <div class="col table-no-data">
    <h5 class="table-no-data-title">{{ 'LOCATION.noAddresses' | translate }}</h5>
  </div>
</div>