// main
import { Injectable } from '@angular/core';
// plugin
import Swal from 'sweetalert2';
// translate
import { TranslateService } from '@ngx-translate/core';
// injectable
@Injectable({
  providedIn: 'root'
})
// export
export class AlertService {
  // constructor
  constructor(
    public translate: TranslateService,
  ) { }
  // methods
  showMessage(text: any, type: any, title?: any, showBtn?: any, btnTxt?: any, timer?: any) {
    // check button
    if (showBtn === null || showBtn === undefined) {
      showBtn = false;
    }
    // check if btn has to be shown
    if (showBtn) {
      // check btn txt
      if (btnTxt === undefined || btnTxt === null) {
        btnTxt = this.translate.instant('HELPERS.accept');
      }
      // check title
      if (title === null || title === undefined) {
        // switch type
        switch (type) {
          case 'info':
            Swal.fire({
              icon: 'info',
              text,
              showConfirmButton:  true,
              confirmButtonColor: '#1d2127',
              confirmButtonText: btnTxt,
              allowOutsideClick: false,
            });
            break;
          case 'warning':
            Swal.fire({
              icon: 'warning',
              text,
              showConfirmButton:  true,
              confirmButtonColor: '#1d2127',
              confirmButtonText: btnTxt,
              allowOutsideClick: false,
            });
            break;
          case 'success':
            Swal.fire({
              icon: 'success',
              text,
              showConfirmButton:  true,
              confirmButtonColor: '#1d2127',
              confirmButtonText: btnTxt,
              allowOutsideClick: false,
            });
            break;
          case 'error':
            Swal.fire({
              icon: 'error',
              text,
              showConfirmButton:  true,
              confirmButtonColor: '#1d2127',
              confirmButtonText: btnTxt,
              allowOutsideClick: false,
            });
            break;
          case 'question':
            Swal.fire({
              icon: 'question',
              text,
              showConfirmButton:  true,
              confirmButtonColor: '#1d2127',
              confirmButtonText: btnTxt,
              allowOutsideClick: false,
            });
            break;
        }
      } else {
        // switch type
        switch (type) {
          case 'info':
            Swal.fire({
              icon: 'info',
              title,
              text,
              showConfirmButton:  true,
              confirmButtonColor: '#1d2127',
              confirmButtonText: btnTxt,
              allowOutsideClick: false,
            });
            break;
          case 'warning':
            Swal.fire({
              icon: 'warning',
              title,
              text,
              showConfirmButton:  true,
              confirmButtonColor: '#1d2127',
              confirmButtonText: btnTxt,
              allowOutsideClick: false,
            });
            break;
          case 'success':
            Swal.fire({
              icon: 'success',
              title,
              text,
              showConfirmButton:  true,
              confirmButtonColor: '#1d2127',
              confirmButtonText: btnTxt,
              allowOutsideClick: false,
            });
            break;
          case 'error':
            Swal.fire({
              icon: 'error',
              title,
              text,
              showConfirmButton:  true,
              confirmButtonColor: '#1d2127',
              confirmButtonText: btnTxt,
              allowOutsideClick: false,
            });
            break;
          case 'question':
            Swal.fire({
              icon: 'question',
              title,
              text,
              showConfirmButton:  true,
              confirmButtonColor: '#1d2127',
              confirmButtonText: btnTxt,
              allowOutsideClick: false,
            });
            break;
        }
      }
    } else {
      if (timer === null || timer === undefined) {
        timer = 3000;
      }
      // check title
      if (title === null || title === undefined) {
        // switch type
        switch (type) {
          case 'info':
            Swal.fire({
              icon: 'info',
              text,
              timer,
              showConfirmButton:  false,
            });
            break;
          case 'warning':
            Swal.fire({
              icon: 'warning',
              text,
              timer,
              showConfirmButton:  false,
            });
            break;
          case 'success':
            Swal.fire({
              icon: 'success',
              text,
              timer,
              showConfirmButton:  false,
            });
            break;
          case 'error':
            Swal.fire({
              icon: 'error',
              text,
              timer,
              showConfirmButton:  false,
            });
            break;
          case 'question':
            Swal.fire({
              icon: 'question',
              text,
              timer,
              showConfirmButton:  false,
            });
            break;
        }
      } else {
        // switch type
        switch (type) {
          case 'info':
            Swal.fire({
              icon: 'info',
              title,
              text,
              timer,
              showConfirmButton:  false,
            });
            break;
          case 'warning':
            Swal.fire({
              icon: 'warning',
              title,
              text,
              timer,
              showConfirmButton:  false,
            });
            break;
          case 'success':
            Swal.fire({
              icon: 'success',
              title,
              text,
              timer,
              showConfirmButton:  false,
            });
            break;
          case 'error':
            Swal.fire({
              icon: 'error',
              title,
              text,
              timer,
              showConfirmButton:  false,
            });
            break;
          case 'question':
            Swal.fire({
              icon: 'question',
              title,
              text,
              timer,
              showConfirmButton:  false,
            });
            break;
        }
      }
    }
  }
  getMsgIcon(msgType: string) {
    // set icon type
    let iconType = 'info';
    // switch msg type
    switch (msgType) {
      case 'Information':
        iconType = 'info';
        break;
      case 'Warning':
        iconType = 'warning';
        break;
      case 'Success':
        iconType = 'success';
        break;
      case 'Error':
        iconType = 'error';
        break;
      case 'Exception':
        iconType = 'error';
        break;
      case 'Question':
        iconType = 'question';
        break;
    }
    // return data
    return iconType;
    // public const string Information = "Information";
    // public const string Error = "Error";
    // public const string Warning = "Warning";
    // public const string Exception = "Exception";
  }
}
