<!-- form -->
<form class="theme-form" [formGroup]="mainFrm">
  <!-- company select -->
  <div class="row mb-4" *ngIf="!needsCompanySearch">
    <div class="col-12">
      <div class="form-group select-fix col-12">
        <span class="caret-select"></span>
        <label for="companySelect" class="col-form-label">{{ 'COMPANY.company' | translate }} <span class="required-lbl">*</span></label>
        <select class="form-control" #companySelect id="companySelect" name="companySelect" formControlName="company" [title]="'COMPANY.company' | translate">
          <option value="null" selected disabled>{{ 'COMPANY.companySelectA' | translate }}</option>
          <option [value]="company.id" *ngFor="let company of companies"><span>{{ company.businessName }}</span></option>
        </select>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.company">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('company').hasError(error.type) && (mainFrm.get('company').dirty || mainFrm.get('company').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small *ngIf="origin === 'user'">{{ 'USER.userCompanyLinkHelp' | translate }}</small>
          <small *ngIf="origin === 'product'">{{ 'PRODUCT.productCompanyLinkHelp' | translate }}</small>
        </p>
      </div>
    </div>
  </div>
  <!-- company search -->
  <div class="row mb-4"  *ngIf="needsCompanySearch">
    <!-- input -->
    <div class="form-group col-10">
      <label class="col-form-label">{{ 'COMPANY.company' | translate }}</label>
      <input id="companiesSearch" #companiesSearch name="companiesSearch" type="text" inputmode="text" formControlName="company" [placeholder]="'COMPANY.company' | translate" class="form-control" (keyup)="findCompany($event)">
    </div>
    <!-- clear data -->
    <div class="col-2">
      <a class="btn btn-primary btn-icon-only btn-user-search-reset" (click)="resetCompanySearch()">
        <app-feather-icons [icon]="'refresh-cw'"></app-feather-icons>
      </a>
    </div>
    <!-- company messages -->
    <div class="col-12 m-0">
      <ng-container class="error-messages" *ngFor="let error of formErrorMessages.company">
        <small class="text-danger error-msg" *ngIf="mainFrm.get('company').hasError(error.type) && (mainFrm.get('company').dirty || mainFrm.get('company').touched)">
          {{ error.message }}.&nbsp;
        </small>
      </ng-container>
      <p class="help-messages" *ngIf="toggleHelp">
        <small>{{ 'USER.formHelp.company' | translate }}</small>
      </p>
    </div>
    <!-- search selection -->
    <div class="col-12">
      <div class="list-group search-selection user-selection">
        <!-- companies -->
        <a class="list-group-item list-group-item-action" (click)="selectCompany(company.id)" [ngClass]="{'active': company.id === companyId}" *ngFor="let company of companies">
          <!-- avatar -->
          <div class="social-img-wrap"> 
            <div class="social-img">
              <img [src]="company.companyLogo" [alt]="company.businessName" onError="src = 'assets/images/broken-image-slim.png'" *ngIf="company.companyLogo !== null">
              <img src="assets/images/broken-image-slim.png" alt="Avatar" *ngIf="company.companyLogo === null">
            </div>
          </div>
          <!-- user data -->
          <div class="user-data">
            <h6 class="user-name">{{ company.businessName }}</h6>
            <p class="user-document">
              <span>{{ company.companyIdentification }}</span>
            </p>
          </div>
        </a>
      </div>
    </div>
  </div>
  <!-- buttons -->
  <div class="row mt-4">
    <div class="col">
      <div class="request-form-btns-container">
        <button class="btn btn-light d-block btn-cancel" (click)="cancel()" type="button">
          <span>{{ 'HELPERS.cancel' | translate }}</span>
        </button>
        <button class="btn btn-dark d-block" (click)="save()" type="button">
          <span>{{ 'HELPERS.save' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</form>