<div *ngIf="userData" class="media profile-media" (click)="toggleProfile()">
  <img class="account-avatar" [src]="avatar" [alt]="firstName + ' ' + lastName" onError="src = 'https://credipremium.co/wp-content/uploads/logos/icon-svg-orange.svg'">
  <div class="user-data-container">
    <p class="user-firstName">{{ firstName }}</p>
    <p class="user-lastName" *ngIf="role === 'client'">{{ lastName }}</p>
    <p *ngIf="role === 'support'" class="user-role">{{ 'USER.formLabels.roleSupport' | translate }}</p>
    <p *ngIf="role === 'administrator'" class="user-role">{{ 'USER.formLabels.roleAdministrator' | translate }}</p>
    <p *ngIf="role === 'board'" class="user-role">{{ 'USER.formLabels.roleBoard' | translate }}</p>
    <p *ngIf="role === 'treasure'" class="user-role">{{ 'USER.formLabels.roleTreasure' | translate }}</p>
    <p *ngIf="role === 'businessAgent'" class="user-role">{{ 'USER.formLabels.roleBusinessAgent' | translate }}</p>
    <p *ngIf="role === 'ceo'" class="user-role">{{ 'USER.formLabels.roleCEO' | translate }}</p>
    <p *ngIf="role === 'manager'" class="user-role">{{ 'USER.formLabels.roleManager' | translate }}</p>
    <p *ngIf="role === 'auxiliar'" class="user-role">{{ 'USER.formLabels.roleAuxiliar' | translate }}</p>
  </div>
  <i class="middle fa fa-angle-down user-dropdown-icon"></i>
</div>
<ul class="profile-dropdown onhover-show-div" [class.active]="openProfile">
  <li><a routerLink="/users/detail/my-profile" routerLinkActive="router-link-active"><app-feather-icons [icon]="'user'"></app-feather-icons><span>{{ 'MENU.myProfile' | translate }}</span></a></li>
  <li *ngIf="companies.length > 1"><a (click)="selectCompany()"><app-feather-icons [icon]="'briefcase'"></app-feather-icons><span>{{ 'MENU.companySelect' | translate }}</span></a></li>
  <li><a (click)="logout()"><app-feather-icons [icon]="'log-out'"></app-feather-icons><span>{{ 'MENU.logout' | translate }}</span></a></li>
</ul>