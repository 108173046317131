// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 25/05/2023
// import
import { Component, ViewEncapsulation, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// environment
import { environment } from 'src/environments/environment';
// reducers
import { Store } from '@ngrx/store';
// services
import { Menu, NavService } from 'src/app/shared/services/nav.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { UtilitiesService } from '../../services/utilities.service';
// interfaces
import { UpdateDataInterface } from 'src/app/shared/reducers/interfaces';
import { UserInterface } from 'src/app/shared/interfaces/user.interface';
import { CompanyInterface } from 'src/app/shared/interfaces/company.interface';
// component
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
// class
export class SidebarComponent implements OnInit  {
  // uri data
  mainUri: string = null;
  // sidebar data
  public version: string = environment.version;
  public iconSidebar;
  public menuItems: Menu[];
  public allMenuItems: Menu[];
  public url: any;
  public fileUrl: any;
  // horizontal menu data
  public margin: any = 0;
  public width: any = window.innerWidth;
  public leftArrowNone: boolean = true;
  public rightArrowNone: boolean = false;
  // user data
  user: UserInterface = null;
  role: string = null;
  userRole: any = null;
  // role variables
  isAdmin: boolean = false;
  isFinancial: boolean = false;
  isCompany: boolean = false;
  isClient: boolean = false;
  // company data
  companyId: string = null;
  companyLogo: string = null;
  companyName: string = null;
  // loans data
  loanAll: number = 0;
  loanInitial: number = 0;
  loanStarted: number = 0;
  loanSended: number = 0;
  loanAccepted: number = 0;
  loanNoPassedValidation: number = 0;
  loanValidated: number = 0;
  loanRejected: number = 0;
  loanPreApproved: number = 0;
  loanReadyToApprove: number = 0;
  loanApproved: number = 0;
  loanReadyForDisbursement: number = 0;
  loanDisbursementOnProgress: number = 0;
  loanDisbursed: number = 0;
  loanPayed: number = 0;
  loanLate: number = 0;
  loanPreLegal: number = 0;
  loanLegal: number = 0;
  loanNegotiation: number = 0;
  loanExpired: number = 0;
  loanCanceled: number = 0;
  // batches data
  batchDisbursement: number = 0;
  batchPayment: number = 0;
  // constructor
  constructor(
    private router: Router, 
    public navServices: NavService,
    public layout: LayoutService,
    private apiSv: ApiService,
    private utilitiesSv: UtilitiesService,
    private dataState: Store<UpdateDataInterface>,
  ) {
    // update data
    this.dataState.subscribe(async(state) =>{
      // loans
      if (state.loansCountReducer) {
        // check user data
        if (this.user !== null) {
          await this.getLoansData();
          await this.getMenuItems();
        }
      }
      // batches
      if (state.batchesCountReducer) {
        // check user data
        if (this.user !== null) {
          await this.getBatchesData();
          await this.getMenuItems();
        }
      }
      // profile 
      if (state.profileReducer) {
        await this.getUserData();
      }
      // company
      if (state.companyReducer) {
        // check user data
        if (this.user !== null) {
          await this.getAllData();
        }
      }
    });
    // update nav items
    this.navServices.items.subscribe(async (menuItems) => {
      // set menu items
      this.allMenuItems = menuItems;
      // get menu data
      await this.getAllData();
    });
    // set mainUri
    this.mainUri = environment.websiteURI;
  }
  // life cycle
  async ngOnInit() {
    await this.getUserData();
    await this.getCompanyData();
    await this.getAllData();
  }
  // navigation
  async goToPage(page: string, params?: any | null) {
    // navigate to page
    this.utilitiesSv.goTo(page, true, params);
  }
  // data
  async getAllData() {
    // check user data
    if (this.user !== null) {
      await this.getLoansData();  
      await this.getBatchesData();
      await this.getMenuItems();
    }
  }
  async getUserData() {
    // get stored data user
    const storedDataUser: any = localStorage.getItem('userData');
    // get data user
    const userData: UserInterface = JSON.parse(storedDataUser);
    // check user data
    if (userData) {
      // set data user
      this.user = userData;
      this.role = userData.role;
      this.userRole = typeof userData.userRole === 'string' ? JSON.parse(userData.userRole) : userData.userRole;
      // check roles
      const checkAdmin = await this.utilitiesSv.checkRoles(userData.role, ['support', 'administrator']);
      this.isAdmin = checkAdmin;
      const checkFinancial = await this.utilitiesSv.checkRoles(userData.role, ['support', 'administrator', 'board', 'treasure', 'businessAgent']);
      this.isFinancial = checkFinancial;
      const checkCompany = await this.utilitiesSv.checkRoles(userData.role, ['ceo', 'manager', 'auxiliar']);
      this.isCompany = checkCompany;
      const checkClient = await this.utilitiesSv.checkRoles(userData.role, ['client']);
      this.isClient = checkClient;
    }
  }
  async getCompanyData() {
    // get stored companyData
    const storedCompanyData: string = localStorage.getItem('companyData');
    // check companyData
    if (storedCompanyData) {
      // get data company
      const companyData: CompanyInterface = JSON.parse(storedCompanyData);
      // set data company
      this.companyId = companyData.id;
      this.companyLogo = companyData.companyLogo;
      this.companyName = companyData.companyName;
    }
  }
  async getLoansData() {
    try {
      // get data from api
      await this.apiSv.getLoanTotals(this.companyId).then((response: any)=>{
        // get badgesCounts
        const badgesCounts: any = response.data;
        // update totals
        this.loanInitial = badgesCounts.initial;
        this.loanAll = badgesCounts.all;
        this.loanStarted = badgesCounts.started;
        this.loanSended = badgesCounts.sended;
        this.loanAccepted = badgesCounts.accepted;
        this.loanNoPassedValidation = badgesCounts.noPassedValidation;
        this.loanValidated = badgesCounts.validated;
        this.loanRejected = badgesCounts.rejected;
        this.loanPreApproved = badgesCounts.preApproved;
        this.loanReadyToApprove = badgesCounts.readyToApprove;
        this.loanApproved = badgesCounts.approved;
        this.loanReadyForDisbursement = badgesCounts.readyForDisbursement;
        this.loanDisbursementOnProgress = badgesCounts.disbursementOnProgress;
        this.loanDisbursed = badgesCounts.disbursed;
        this.loanPayed = badgesCounts.payed;
        this.loanLate = badgesCounts.late;
        this.loanPreLegal = badgesCounts.preLegal;
        this.loanLegal = badgesCounts.legal;
        this.loanNegotiation = badgesCounts.negotiation;
        this.loanExpired = badgesCounts.expired;
        this.loanCanceled = badgesCounts.canceled;
      }, error=>{
        console.log('error', error);
      });
    } catch (error) {
      console.log('error', error);
    }
  }
  async getBatchesData() {
    try {
      // get data from api
      await this.apiSv.getBatchTotals(this.companyId).then((response: any)=>{
        // get badgesCounts
        const badgesCounts: any = response.data;
        // update totals
        this.batchDisbursement = badgesCounts.disbursement;
        this.batchPayment = badgesCounts.payment;
      }, error=>{
        console.log('error', error);
      });
    } catch (error) {
      console.log('error', error);
    }
  }
  async getMenuItems() {
    // get currentPath
    const currentPath = window.location.pathname;
    // init items
    const items: Menu[] = [];
    // loop menu items
    this.allMenuItems.map((menuItem: Menu) => {
      // check role match
      if (menuItem.roles.includes(this.role)) {
        // check if item has sub items
        if (menuItem.type == 'sub') {
          // set children
          const children: any[] = [];
          // loop children
          menuItem.children.map((subMenuItem: Menu) => {
            // check role match on children
            if (subMenuItem.roles.includes(this.role)) {
              // check badgesCount
              if (subMenuItem.badgesCount) {
                // check loanType
                switch (subMenuItem.badgeId) {
                  case 'loanAll':
                    subMenuItem.badgeValue = this.loanAll.toString();
                    break;
                  case 'loanInitial':
                    subMenuItem.badgeValue = this.loanInitial.toString();
                    break;
                  case 'loanStarted':
                    subMenuItem.badgeValue = this.loanStarted.toString();
                    break;
                  case 'loanSended':
                    subMenuItem.badgeValue = this.loanSended.toString();
                    break;
                  case 'loanAccepted':
                    subMenuItem.badgeValue = this.loanAccepted.toString();
                    break;
                  case 'loanNoPassedValidation':
                    subMenuItem.badgeValue = this.loanNoPassedValidation.toString();
                    break;
                  case 'loanValidated':
                    subMenuItem.badgeValue = this.loanValidated.toString();
                    break;
                  case 'loanRejected':
                    subMenuItem.badgeValue = this.loanRejected.toString();
                    break;
                  case 'loanPreApproved':
                    subMenuItem.badgeValue = this.loanPreApproved.toString();
                    break;
                  case 'loanReadyToApprove':
                    subMenuItem.badgeValue = this.loanReadyToApprove.toString();
                    break;
                  case 'loanApproved':
                    subMenuItem.badgeValue = this.loanApproved.toString();
                    break;
                  case 'loanReadyForDisbursement':
                    subMenuItem.badgeValue = this.loanReadyForDisbursement.toString();
                    break;
                  case 'loanDisbursementOnProgress':
                    subMenuItem.badgeValue = this.loanDisbursementOnProgress.toString();
                    break;
                  case 'loanDisbursed':
                    subMenuItem.badgeValue = this.loanDisbursed.toString();
                    break;
                  case 'loanPayed':
                    subMenuItem.badgeValue = this.loanPayed.toString();
                    break;
                  case 'loanLate':
                    subMenuItem.badgeValue = this.loanLate.toString();
                    break;
                  case 'loanPreLegal':
                    subMenuItem.badgeValue = this.loanPreLegal.toString();
                    break;
                  case 'loanLegal':
                    subMenuItem.badgeValue = this.loanLegal.toString();
                    break;
                  case 'loanNegotiation':
                    subMenuItem.badgeValue = this.loanNegotiation.toString();
                    break;
                  case 'loanExpired':
                    subMenuItem.badgeValue = this.loanExpired.toString();
                    break;
                  case 'loanCanceled':
                    subMenuItem.badgeValue = this.loanCanceled.toString();
                    break;
                }
              }
              // set active item
              subMenuItem.active = subMenuItem.path === currentPath ? true : false;
              // add subitem to children
              children.push(subMenuItem);
            }
          });
          // update children
          menuItem.children = children;
        }
        // check badgesCount
        if (menuItem.badgesCount) {
          // check loanType
          switch (menuItem.badgeId) {
            case 'loanAll':
              menuItem.badgeValue = this.loanAll.toString();
              break;
            case 'loanInitial':
              menuItem.badgeValue = this.loanInitial.toString();
              break;
            case 'loanStarted':
              menuItem.badgeValue = this.loanStarted.toString();
              break;
            case 'loanSended':
              menuItem.badgeValue = this.loanSended.toString();
              break;
            case 'loanAccepted':
              menuItem.badgeValue = this.loanAccepted.toString();
              break;
            case 'loanNoPassedValidation':
              menuItem.badgeValue = this.loanNoPassedValidation.toString();
              break;
            case 'loanValidated':
              menuItem.badgeValue = this.loanValidated.toString();
              break;
            case 'loanRejected':
              menuItem.badgeValue = this.loanRejected.toString();
              break;
            case 'loanPreApproved':
              menuItem.badgeValue = this.loanPreApproved.toString();
              break;
            case 'loanReadyToApprove':
              menuItem.badgeValue = this.loanReadyToApprove.toString();
              break;
            case 'loanApproved':
              menuItem.badgeValue = this.loanApproved.toString();
              break;
            case 'loanReadyForDisbursement':
              menuItem.badgeValue = this.loanReadyForDisbursement.toString();
              break;
            case 'loanDisbursementOnProgress':
              menuItem.badgeValue = this.loanDisbursementOnProgress.toString();
              break;
            case 'loanDisbursed':
              menuItem.badgeValue = this.loanDisbursed.toString();
              break;
            case 'loanPayed':
              menuItem.badgeValue = this.loanPayed.toString();
              break;
            case 'loanLate':
              menuItem.badgeValue = this.loanLate.toString();
              break;
            case 'loanPreLegal':
              menuItem.badgeValue = this.loanPreLegal.toString();
              break;
            case 'loanLegal':
              menuItem.badgeValue = this.loanLegal.toString();
              break;
            case 'loanNegotiation':
              menuItem.badgeValue = this.loanNegotiation.toString();
              break;
            case 'loanExpired':
              menuItem.badgeValue = this.loanExpired.toString();
              break;
            case 'loanCanceled':
              menuItem.badgeValue = this.loanCanceled.toString();
              break;
            case 'batchDisbursement':
              menuItem.badgeValue = this.batchDisbursement.toString();
              break;
            case 'batchPayment':
              menuItem.badgeValue = this.batchPayment.toString();
              break;
          }
        }
        // set active item
        menuItem.active = menuItem.path === currentPath ? true : false;
        // add item to list
        items.push(menuItem);
      }
    });
    // update user items
    this.menuItems = items;
  }
  // actions
  clearIds() {
    // check if user is an owner
    if ((this.role == 'superadmin' || this.role == 'admin')) {
      localStorage.removeItem('companyId');
    }
    // clear stored ids
    localStorage.removeItem('dataBreadcrumbs');
  }
  // listeners
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = event.target.innerWidth - 500;
  }
  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }
  // toggle menu
  toggleNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item)) {
          a.active = false;
        }
        if (!a.children) { return false; }
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }
  // horizontal menu
  scrollToLeft() {
    if (this.margin >= -this.width) {
      this.margin = 0;
      this.leftArrowNone = true;
      this.rightArrowNone = false;
    } else {
      this.margin += this.width;
      this.rightArrowNone = false;
    }
  }
  scrollToRight() {
    if (this.margin <= -3051) {
      this.margin = -3464;
      this.leftArrowNone = false;
      this.rightArrowNone = true;
    } else {
      this.margin += -this.width;
      this.leftArrowNone = false;
    }
  }
}
