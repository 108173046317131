<div class="container-fluid">
  <div class="row widget-grid">
    <!-- welcome -->
    <div class="col-xxl-6 col-xl-6 col-md-5 col-sm-12">
      <app-dashboard-welcome 
        [userData]="user" 
        [totalRequests]="totalRequests">
      </app-dashboard-welcome>
    </div>
    <!-- totals -->
    <div class="col-xxl-6 col-xl-6 col-md-7 col-sm-12">
      <div class="row">
        <div class="col-xxl-6 col-xl-6 col-sm-12">
          <app-total-pending
            [totalPending]="totalPending">
          </app-total-pending>
        </div>
        <div class="col-xxl-6 col-xl-6 col-sm-12">
          <app-total-no-passed-validation
            [totalNoPassedValidation]="totalNoPassedValidation">
          </app-total-no-passed-validation>
        </div>
        <div class="col-xxl-6 col-xl-6 col-sm-12">
          <app-total-ready-to-approve
            [totalReadyToApprove]="totalReadyToApprove">
          </app-total-ready-to-approve>
        </div>
        <div class="col-xxl-6 col-xl-6 col-sm-12">
          <app-total-approved
            [totalApproved]="totalApproved">
          </app-total-approved>
        </div>
        <div class="col-xxl-6 col-xl-6 col-sm-12">
          <app-total-rejected
            [totalRejected]="totalRejected">
          </app-total-rejected>
        </div>
        <div class="col-xxl-6 col-xl-6 col-sm-12">
          <app-total-to-collect
            [totalToCollect]="totalToCollect">
          </app-total-to-collect>
        </div>
        <div class="col-xxl-6 col-xl-6 col-sm-12">
          <app-total-disbursed
            [totalDisbursed]="totalDisbursed">
          </app-total-disbursed>
        </div>
        <div class="col-xxl-6 col-xl-6 col-sm-12">
          <app-total-payed
            [totalPayed]="totalPayed">
          </app-total-payed>
        </div>
      </div>
    </div>
    <!-- loan last 5 -->
    <div class="col-xxl-12 col-xl-12 col-md-12 col-sm-12">
      <!-- card -->
      <div class="card">
        <!-- header -->
        <div class="card-header card-no-border mt-4">
          <div class="header-top">
            <div class="row">
              <div class="col-12">
                <h5 class="test-title-css loan-work-activities-title">{{ 'REPORT.loanTop5' | translate }}</h5>
              </div>
            </div>
            <div class="card-header-right-icon" *ngIf="loans.length > 0">
              <a class="btn btn-report" type="button" routerLink="/loans/all" routerLinkActive="router-link-active">{{ 'REPORT.loanViewAll' | translate }}</a>
            </div>
          </div>
        </div>
        <!-- body -->
        <div class="card-body">
          <!-- data -->
          <div class="row" *ngIf="loans.length > 0">
            <div class="col-12">
              <app-loan-list-table
                [loansData]="loans"
                [isClient]="isClient"
                [isAdmin]="isAdmin"
                [isBoard]="isBoard"
                [isBusinessAgent]="isBusinessAgent"
                [isTreasure]="isTreasure"
                [isCompanyAdmin]="isCompanyAdmin"
                [isCompanyAuxiliar]="isCompanyAuxiliar"
                (openLoanEvent)="openLoan($event)"
                (openUserEvent)="openUser($event)"
                (loaderEvent)="updateLoader($event)">
              </app-loan-list-table>
            </div>
          </div>
          <!-- no data -->
          <div class="row" *ngIf="loans.length === 0">
            <div class="no-data-container inner-container">
              <app-no-data [msg]="'REPORT.loansEmpty' | translate"></app-no-data>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
