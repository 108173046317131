<!-- Page Header Start-->
<div class="page-header">
  <div class="header-wrapper row m-0">
    <!-- logo -->
    <div class="col-lg-4 col-sm-4 logo-col">
      <div class="logo">
        <img class="img-fluid for-light" [src]="mainUri + '/wp-content/uploads/logos/logo-transparent.png'" alt="CrediPremium">
        <img class="img-fluid for-dark" [src]="mainUri + '/wp-content/uploads/logos/logo-transparent-white-orange.png'" alt="CrediPremium">
      </div>
    </div>
    <!-- contact -->
    <div class="col-lg-3 col-sm-4 contact-col">
      <div class="header-contact-container">
        <div class="header-contact-info-container">
          <div class="header-contact-info">
            <p class="header-contact-lbl">{{ 'CONTACT.questions' | translate }}</p>
            <a class="header-contact-link" href="tel:+573234815179" target="_blank" rel="norel noopener noreferrer">+57 (323) 481 5179</a>
          </div>
        </div>
        <div class="header-contact-icon-container">
          <app-feather-icons class="header-contact-icon" [icon]="'phone-call'"></app-feather-icons>
        </div>
      </div>
    </div>
    <!-- visit -->
    <div class="col-lg-3 d-block d-md-block d-sm-none visit-col">
      <div class="header-contact-container">
        <div class="header-contact-info-container">
          <div class="header-contact-info">
            <p class="header-contact-lbl">{{ 'CONTACT.visitUs' | translate }}</p>
            <a class="header-contact-link" href="https://goo.gl/maps/7FTpkCsYf1trLhNc9" target="_blank" rel="norel noopener noreferrer">{{ 'CONTACT.address' | translate }}</a>
          </div>
        </div>
        <div class="header-contact-icon-container">
          <app-feather-icons class="header-contact-icon" [icon]="'map-pin'"></app-feather-icons>
        </div>
      </div>
    </div>
    <!-- go back -->
    <div class="col-lg-2 col-sm-4 link-col">
      <div class="btn-header">
        <a class="btn btn-primary text-center" [href]="mainUri" target="_blank" rel="norel noopener noreferrer">
          <span>{{ 'CONTACT.goToMainPage' | translate }}</span>
        </a>
      </div>
    </div>
  </div>
</div>