// imports
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnyAaaaRecord } from 'dns';
// routes
import {
  route_media,
  route_files,
  route_terms,
  route_privacy,
  route_config,
  route_notifications,
  route_professions,
  route_occupations,
  route_positions,
  route_location,
  route_banks,
  route_products,
  route_product_categories,
  route_companies,
  route_other_companies,
  route_company_activities,
  route_auth,
  route_users,
  route_loans,
  route_loan_statuses,
  route_batches,
  route_transactions,
  route_batch_has_loans,
  route_batch_has_loan_payments,
  route_batch_status,
  route_companies_users,
  route_companies_products,
  route_venues,
  route_user_attachment,
  route_user_address,
  route_reports
} from '../routes/api.routes';
import { firstValueFrom } from 'rxjs';
// interfaces

// injectable
@Injectable()
// class
export class ApiService {
  // constructor
  constructor(
    private http: HttpClient,
  ) {}

  // files
  // ---------------------------------------------------------------------
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async uploadFile(file: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        enctype: 'multipart/form-data; boundary=----WebKitFormBoundaryuL67FWkv1CA'
      })
    };
    const url: any = route_files + '/upload';
    return await firstValueFrom(this.http.post(url, file, httpOptions));
  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async deleteFile(fileKey: any) {
    const url: any = route_files + '/delete/' + fileKey;
    return await firstValueFrom(this.http.delete(url, {}));
  }

  // notifications
  // -----------------------------------------------------------------------------------
  // NOT INCLUDED
  public async getNotifications(data: any) {
    const url = route_notifications + '/' + data.userId;
    return await firstValueFrom(this.http.get(url, data));
  }
  // NOT INCLUDED
  public async checkNotification(userId: string, notificationId: string) {
    const url = route_notifications + '?userId=' + userId + '&notificationId=' + notificationId;
    return await firstValueFrom(this.http.get(url));
  }
  // NOT INCLUDED
  public async addNotification(userId: string, data: any) {
    const url = route_notifications + '/' + userId;
    return await firstValueFrom(this.http.patch(url, data));
  }
  // NOT INCLUDED
  public async deleteNotification(id: string) {
    const url = route_users + '/' + id;
    return await firstValueFrom(this.http.delete(url));
  }

  // auth
  // ---------------------------------------------------------------------
  // OK: JUAN / SPRINT 2
  public async checkEmail(email: string) {
    const url = route_users + '/check-email/' + email;
    return await firstValueFrom(this.http.get(url));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      email: 'email'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        statusEmail: true // IF EMAIL EXISTS RETURNS TRUE IF NOT FALSE
      }
    }

  }
  // OK: JUAN / SPRINT 2
  public async checkStatus() {
    const url = route_users + '/check-status/';
    return await firstValueFrom(this.http.get(url));

    // ALL LOGGED IN ROLES

    // NO DATA SENDED. IDENTIFY USER WITH TOKEN

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        userStatus: 'userStatus' // RETURN USER STATUS
      }
    }

  }
  // OK: JUAN / SPRINT 2
  public async login(data: any) {
    const url = route_auth + '/login/';
    return await firstValueFrom(this.http.post(url, data));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON BODY

    // DATA SENDED
    const sendedBodyData: any = {
      email: 'email',
      password: 'password'
    };

    // EXPECTED RESPONSE IF CREDENTIALS MATCH
    const response: any = {
      data: {
        accessToken: 'accessToken'
      }
    }

  }
  // OK: JUAN / SPRINT 2
  public async resetPassword(data: any) {
    const url = route_auth + '/reset-password';
    return await firstValueFrom(this.http.post(url, data));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON BODY

    // DATA SENDED
    const sendedBodyData: any = {
      email: 'email',
      code: 'code',
      password: 'password'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        accessToken: 'accessToken'
      }
    }

  }
  // OK: JUAN - REASIGNED TO ANDRES / SPRINT 2
  public async changePassword(data: any) {
    const url = route_auth + '/change-password';
    return await firstValueFrom(this.http.post(url, data));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON BODY. IDENTIFY USER WITH TOKEN

    // DATA SENDED. 
    const sendedBodyData: any = {
      password: 'password'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        accessToken: 'accessToken'
      }
    }

  }
  // OK: JUAN - REASIGNED TO ANDRES / SPRINT 2
  public async updatePassword(data: any) {
    const url = route_auth + '/reset-password-by-userId';
    return await firstValueFrom(this.http.post(url, data));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON BODY. IDENTIFY USER WITH TOKEN

    // DATA SENDED. 
    const sendedBodyData: any = {
      userId: 'userId',
      password: 'password'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        accessToken: 'accessToken'
      }
    }

  }
  // OK: JUAN / SPRINT 2
  public async validateCodeRegister(email: string, code: string) {
    const url = route_auth + '/validate-code-register/' + email + '/' + code;
    return await firstValueFrom(this.http.post(url, {}));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      email: 'email',
      code: 'code'
    };

    // IF THE EMAIL CORRESPOND TO USER AND THE CODE MATCHES THEN SET USER STATUS TO "active" AND RETURN RESPONSE

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        accessToken: 'accessToken'
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: JUAN / SPRINT 2
  public async validateCodeForgot(email: string, code: string, ) {
    const url = route_auth + '/validate-code-forgot/' + email + '/' + code;
    return await firstValueFrom(this.http.post(url, {}));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      email: 'email',
      code: 'code'
    };

    // IF THE EMAIL CORRESPOND TO USER AND THE CODE MATCHES THEN SET USER STATUS TO "active" AND RETURN RESPONSE

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        accessToken: 'accessToken'
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }

  // config
  // ---------------------------------------------------------------------
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async getTerms() {
    const url = route_terms;
    return await firstValueFrom(this.http.get(url));

    // ALL LOGGED IN ROLES

    // NO DATA IS SENDED

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        terms: 'terms' // GET DATA FROM CONFIG TABLE
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async getPrivacy() {
    const url = route_privacy;
    return await firstValueFrom(this.http.get(url));

    // ALL LOGGED IN ROLES

    // NO DATA IS SENDED

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        privacy: 'privacy' // GET DATA FROM CONFIG TABLE
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // NEW: ASSIGNED TO ANDRES / SPRINT 5
  public async getPublicConfig() {
    const url = route_config + '/public';
    return await firstValueFrom(this.http.get(url));

    // NO ROLE CHECKING

    // NO DATA IS SENDED

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        companyName: 'companyName',
        documentNumber: 'documentNumber',
        uriWebsite: 'uriWebsite',
        uriWebsiteTxt: 'uriWebsiteTxt',
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async getConfig() {
    const url = route_config;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator'];

    // NO DATA IS SENDED

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        config: 'config' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async updateConfig(id: string, data: any) {
    const url = route_config + '/' + id;
    return await firstValueFrom(this.http.put(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator'];

    // DATA IS SENDED ON URI AND BODY

   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };

    // DATA SENDED
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      companyName: 'companyName',
      logo: 'logo',
      terms: 'terms',
      privacy: 'privacy',
      overduedDays: 'overduedDays',
      uriGooglePlay: 'uriGooglePlay',
      uriAppStore: 'uriAppStore',
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        config: 'config' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async version() {
    const url = route_config + '/version-dashboard';
    return await firstValueFrom(this.http.get(url));

    // NO ROLE CHECKING

    // NO DATA IS SENDED ON URI AND BODY

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        versionDashboard: '2.1.6' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }

  // locations
  // ---------------------------------------------------------------------
  // OK: JUAN / SPRINT 2
  public async getCountries() {
    const url = route_location + '/all-countries';
    return await firstValueFrom(this.http.get(url));

    // ALL LOGGED IN ROLES

    // NO DATA IS SENDED

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        result: [
          {
            country: 'country' // OBJECT
          }
        ]
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: JUAN / SPRINT 2
  public async getCountry(countryId: string) {
    const url = route_location + '/country/' + countryId;
    return await firstValueFrom(this.http.get(url));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      countryId: 'countryId'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        country: 'country' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: JUAN / SPRINT 2
  public async getStates(countryId: string) {
    const url = route_location + '/states/' + countryId;
    return await firstValueFrom(this.http.get(url));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      countryId: 'countryId'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        result: [
          {
            state: 'state' // OBJECT
          }
        ]
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: JUAN / SPRINT 3
  public async getState(stateId: string) {
    const url = route_location + '/state/' + stateId;
    return await firstValueFrom(this.http.get(url));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      stateId: 'stateId'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        state: 'state' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: JUAN / SPRINT 2
  public async getCities(stateId: string) {
    const url = route_location + '/cities/' + stateId;
    return await firstValueFrom(this.http.get(url));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      stateId: 'stateId'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        result: [
          {
            city: 'city' // OBJECT
          }
        ]
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: JUAN / SPRINT 3
  public async getCity(cityId: string) {
    const url = route_location + '/city/' + cityId;
    return await firstValueFrom(this.http.get(url));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      cityId: 'cityId'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        city: 'city' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }

  // banks
  // ---------------------------------------------------------------------
  // OK: JUAN / SPRINT 2
  public async getPublicBanks(countryId: string) {
    const url = route_banks + '/all-banks/' + countryId;
    return await firstValueFrom(this.http.get(url));
  
    // ALL LOGGED IN ROLES
  
    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      countryId: 'countryId'
    };
  
    // EXPECTED RESPONSE - RETURN ONLY STATUS 'active' OR true if boolean attribute
    const response: any = {
      data: {
        result: [
          {
            bank: 'bank' // OBJECT
          }
        ]
      }
    }
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES
  
  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async addPublicBank(data: any) {
    const url = route_banks + '/create-bank';
    return await firstValueFrom(this.http.post(url, data));
  
    // ALL LOGGED IN ROLES
  
    // DATA IS SENDED ON BODY
  
    // DATA SENDED
    const sendedBodyData: any = {
      bankName: 'bankName',
    };

    // SET STATUS TO 'pendingForReview'
  
    // EXPECTED RESPONSE
    const response: any = {
      data: 'id'
    }
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES
  
  }
  // OK: JUAN / SPRINT 3
  public async getBanks(data: any) {
    const url = route_banks + '?limit=' + data.limit + '&page=' + data.page + '&bankName=' + data.bankName + '&bankStatus=' + data.bankStatus + '&countryId=' + data.countryId;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar', 'client'];

    // DATA IS SENDED ON URI

    // DATA SENDED
    // SOME ATTRIBUTES CAN BE null, IN THAT CASE SHOULD NOT BE TAKEN ON THE WHERE AT THE QUERY
    const sendedURIData: any = {
      limit: 10,
      page: 1,
      bankName: null,
      bankStatus: false,
      countryId: 'd27a2ec8-e942-4044-b00c-382caf3a2673',
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        result: [
          {
            bank: 'bank' // OBJECT
          }
        ],
        pages: 1
      }
    }

    // RESPONSE RECEIVED
    const responseReceived = {
      "statusCode": 500,
      "error": true,
      "path": "/banks?limit=10&page=1&bankName=null&bankStatus=false&countryId=d27a2ec8-e942-4044-b00c-382caf3a2673",
      "timestamp": "2023-06-13T22:10:54.936Z",
      "data": "Property \"bankStatus\" was not found in \"BanksHasCountries\". Make sure your query is correct."
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: JUAN / SPRINT 3
  public async getBank(id: string) {
    const url = route_banks + '/' + id;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar', 'client'];

    // DATA IS SENDED ON URI

   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        bank: 'bank' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: JUAN / SPRINT 3
  public async addBank(data: any) {
    const url = route_banks;
    return await firstValueFrom(this.http.post(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent'];

    // DATA IS SENDED ON BODY

    // DATA SENDED
    const sendedBodyData: any = {
      bankLogo: 'bankLogo',
      bankName: 'bankName',
      bankStatus: 'bankStatus', // ENUM('pendingForReview', 'active', 'unactive')
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        bank: 'bank' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async updateBank(id: string, data: any) {
    const url = route_banks + '/' + id;
    return await firstValueFrom(this.http.put(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent'];
  
    // DATA IS SENDED ON URI AND BODY

    // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // DATA SENDED ON BODY
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      bankLogo: 'bankLogo',
      bankName: 'bankName',
      bankStatus: 'bankStatus', // ENUM('pendingForReview', 'active', 'unactive')
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        bank: 'bank' // OBJECT
      }
    }
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: CORRECTION: JUAN - ON SWAGER DOES NOT SEEM TO BE RECEIVING WHICH BANK IT HAS TO DELETE, CHECK BELLOW ON RESPONSE RECEIVED FOR FURTHER DETAILS / SPRINT 3
  public async removeBank(id: string) {
    const url = route_banks + '/' + id;
    return await firstValueFrom(this.http.delete(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent'];

    // DATA IS SENDED ON URI

    // DATA SENDED ON URI
    const sendedURIData: any = {
    id: 'id'
    };

    // NO EXPECTED RESPONSE

    // RESPONSE RECEIVED
    const responseReceived = {
    "statusCode": 404,
    "error": true,
    "path": "/banks/dbbe9909-f355-40ca-aba4-c0cdfc9a81d7",
    "timestamp": "2023-06-13T22:13:54.249Z",
    "data": "Cannot DELETE /banks/dbbe9909-f355-40ca-aba4-c0cdfc9a81d7"
    }

    // ON DELETE, REPLACE ON CASCADE FOR NULL 

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async linkBankCountry(data: any) {
    const url = route_banks + '/link-country/';
    return await firstValueFrom(this.http.post(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent'];

    // DATA IS SENDED ON BODY

    // DATA SENDED
    const sendedBodyData: any = {
      bankId: 'bankId',
      countryId: 'countryId',
    };

    // NO EXPECTED RESPONSE

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async unlinkBankCountry(bankId: string, countryId: string) {
    const url = route_banks + '/unlink-country/' + bankId + '/' + countryId;
    return await firstValueFrom(this.http.delete(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent'];

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      bankId: 'bankId',
      countryId: 'countryId',
    };

    // NO EXPECTED RESPONSE

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }

  // professions
  // ---------------------------------------------------------------------
  // OK: JUAN / SPRINT 2
  public async getPublicProfessions() {
    const url = route_professions + '/all-professions';
    return await firstValueFrom(this.http.get(url));

    // ALL LOGGED IN ROLES

    // NO DATA IS SENDED

    // EXPECTED RESPONSE - RETURN ONLY STATUS 'active' OR true if boolean attribute
    const response: any = {
      data: {
        result: [
          {
            profession: 'profession' // OBJECT
          }
        ]
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: JUAN / SPRINT 2
  public async addPublicProfession(data: any) {
    const url = route_professions + '/create-profession';
    return await firstValueFrom(this.http.post(url, data));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON BODY

    // DATA SENDED
    const sendedBodyData: any = {
      professionName: 'professionName',
    };

    // SET STATUS TO 'pendingForReview'

    // EXPECTED RESPONSE
    const response: any = {
      data: 'id'
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ANDRES / SPRINT 3
  public async getProfessions(data: any) {
    const url = route_professions + '?limit=' + data.limit + '&page=' + data.page + '&professionName=' + data.professionName + '&professionStatus=' + data.professionStatus;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator'];

    // DATA IS SENDED ON URI

    // DATA SENDED
    // SOME ATTRIBUTES CAN BE null, IN THAT CASE SHOULD NOT BE TAKEN ON THE WHERE AT THE QUERY
    const sendedURIData: any = {
      limit: 10,
      page: 1,
      professionName: null,
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        result: [
          {
            profession: 'profession' // OBJECT
          }
        ],
        pages: 1
      }
    }

    // RESPONSE RECEIVED
    const responseReceived = {
      "statusCode": 200,
      "error": false,
      "data": {
          "result": [],
          "count": 0,
          "pages": 0
      }
    }
    const responseReceivedWithExtraParameter = {
      "statusCode": 400,
      "error": true,
      "path": "/professions?limit=10&page=1&professionName=null&professionStatus=null",
      "timestamp": "2023-06-20T22:45:42.976Z",
      "data": [
          "property professionStatus should not exist"
      ]
    }

    // FORGOT TO ADD PROFESSION STATUS - MY BAD :(
    // BUT ALSO WITHOUT THIS PARAMETER IS NOT RETURNING ANY DATA, BUT IT EXISTS ON THE DATABASE

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async getProfession(id: string) {
    const url = route_professions + '/' + id;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator'];

    // DATA IS SENDED ON URI

   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        profession: 'profession' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async addProfession(data: any) {
    const url = route_professions;
    return await firstValueFrom(this.http.post(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator'];

    // DATA IS SENDED ON BODY

    // DATA SENDED
    const sendedBodyData: any = {
      professionName: 'professionName',
      professionStatus: 'professionStatus', // ENUM('pendingForReview', 'active', 'unactive')
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        profession: 'profession' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async updateProfession(id: string, data: any) {
    const url = route_professions + '/' + id;
    return await firstValueFrom(this.http.put(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator'];

    // DATA IS SENDED ON URI AND BODY

    // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };

    // DATA SENDED
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      professionName: 'professionName',
      professionStatus: 'professionStatus', // ENUM('pendingForReview', 'active', 'unactive')
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        profession: 'profession' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async removeProfession(id: string) {
    const url = route_professions + id;
    return await firstValueFrom(this.http.delete(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator'];

    // DATA IS SENDED ON URI

   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };

    // NO EXPECTED RESPONSE

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }

  // occupations
  // ---------------------------------------------------------------------
  // OK: JUAN / SPRINT 2
  public async getPublicOccupations() {
    const url = route_occupations + '/all-occupations/list';
    return await firstValueFrom(this.http.get(url));

    // ALL LOGGED IN ROLES

    // NO DATA IS SENDED

    // EXPECTED RESPONSE - RETURN ONLY STATUS 'active' OR true if boolean attribute
    const response: any = {
      data: {
        result: [
          {
            occupation: 'occupation' // OBJECT
          }
        ]
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: JUAN / SPRINT 2
  public async addPublicOccupation(data: any) {
    const url = route_occupations + '/create-occupation';
    return await firstValueFrom(this.http.post(url, data));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON BODY

    // DATA SENDED
    const sendedBodyData: any = {
      occupationName: 'occupationName',
    };

    // SET STATUS TO 'pendingForReview'

    // EXPECTED RESPONSE
    const response: any = {
      data: 'id'
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async getOccupations(data: any) {
    const url = route_occupations + '?limit=' + data.limit + '&page=' + data.page + '&occupationName=' + data.occupationName;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator'];

    // DATA IS SENDED ON URI

    // DATA SENDED
    // SOME ATTRIBUTES CAN BE null, IN THAT CASE SHOULD NOT BE TAKEN ON THE WHERE AT THE QUERY
    const sendedURIData: any = {
      limit: 'limit',
      page: 'page',
      occupationName: 'occupationName',
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        result: [
          {
            occupation: 'occupation' // OBJECT
          }
        ],
        pages: 1
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async getOccupation(id: string) {
    const url = route_occupations + '/' + id;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator'];

    // DATA IS SENDED ON URI

   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        occupation: 'occupation' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async addOccupation(data: any) {
    const url = route_occupations;
    return await firstValueFrom(this.http.post(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator'];

    // DATA IS SENDED ON BODY

    // DATA SENDED

    const sendedBodyData: any = {
      occupationName: 'occupationName',
      occupationStatus: 'occupationStatus', // ENUM('pendingForReview', 'active', 'unactive')
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        occupation: 'occupation' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async updateOccupation(id: string, data: any) {
    const url = route_occupations + '/' + id;
    return await firstValueFrom(this.http.put(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator'];

    // DATA IS SENDED ON URI AND BODY

    // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };

    // DATA SENDED
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      occupationName: 'occupationName',
      occupationStatus: 'occupationStatus', // ENUM('pendingForReview', 'active', 'unactive')
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        occupation: 'occupation' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async removeOccupation(id: string) {
    const url = route_occupations + id;
    return await firstValueFrom(this.http.delete(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator'];

    // DATA IS SENDED ON URI

   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };

    // NO EXPECTED RESPONSE

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }

  // positions
  // ---------------------------------------------------------------------
  // OK: JUAN / SPRINT 2
  public async getPublicPositions() {
    const url = route_positions + '/all-positions';
    return await firstValueFrom(this.http.get(url));
  
    // ALL LOGGED IN ROLES
  
    // NO DATA IS SENDED
  
    // EXPECTED RESPONSE - RETURN ONLY STATUS 'active' OR true if boolean attribute
    const response: any = {
      data: {
        result: [
          {
            position: 'position' // OBJECT
          }
        ]
      }
    }
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES
  
  }
  // OK: JUAN / SPRINT 2
  public async addPublicPosition(data: any) {
    const url = route_positions + '/create-position';
    return await firstValueFrom(this.http.post(url, data));
  
    // ALL LOGGED IN ROLES
  
    // DATA IS SENDED ON BODY
  
    // DATA SENDED
    const sendedBodyData: any = {
      positionName: 'positionName',
    };

    // SET STATUS TO 'pendingForReview'
  
    // EXPECTED RESPONSE
    const response: any = {
      data: 'id'
    }
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES
  
  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async getPositions(data: any) {
    const url = route_positions + '?limit=' + data.limit + '&page=' + data.page + '&positionName=' + data.positionName;
    return await firstValueFrom(this.http.get(url));
  
    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator'];
  
    // DATA IS SENDED ON URI
  
    // DATA SENDED
    // SOME ATTRIBUTES CAN BE null, IN THAT CASE SHOULD NOT BE TAKEN ON THE WHERE AT THE QUERY
    const sendedURIData: any = {
      limit: 'limit',
      page: 'page',
      positionName: 'positionName',
    };
  
    // EXPECTED RESPONSE
    const response: any = {
      data: {
        result: [
          {
            position: 'position' // OBJECT
          }
        ],
        pages: 1
      }
    }
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES
  
  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async getPosition(id: string) {
    const url = route_positions + '/' + id;
    return await firstValueFrom(this.http.get(url));
  
    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator'];
  
    // DATA IS SENDED ON URI
  
   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // EXPECTED RESPONSE
    const response: any = {
      data: {
        position: 'position' // OBJECT
      }
    }
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES
  
  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async addPosition(data: any) {
    const url = route_positions;
    return await firstValueFrom(this.http.post(url, data));
  
    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator'];
  
    // DATA IS SENDED ON BODY
  
    // DATA SENDED
    const sendedBodyData: any = {
      positionName: 'positionName',
      positionStatus: 'positionStatus', // ENUM('pendingForReview', 'active', 'unactive')
    };
  
    // EXPECTED RESPONSE
    const response: any = {
      data: {
        position: 'position' // OBJECT
      }
    }
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES
  
  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async updatePosition(id: string, data: any) {
    const url = route_positions + '/' + id;
    return await firstValueFrom(this.http.put(url, data));
  
    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator'];
  
    // DATA IS SENDED ON URI AND BODY

    // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // DATA SENDED
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      positionName: 'positionName',
      positionStatus: 'positionStatus', // ENUM('pendingForReview', 'active', 'unactive')
    };
  
    // EXPECTED RESPONSE
    const response: any = {
      data: {
        position: 'position' // OBJECT
      }
    }
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES
  
  }
  // ASSIGNED TO ANDRES / SPRINT 3
  public async removePosition(id: string) {
    const url = route_positions + id;
    return await firstValueFrom(this.http.delete(url));
  
    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator'];
  
    // DATA IS SENDED ON URI
  
   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // NO EXPECTED RESPONSE
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES
  
  }

  // companies
  // ---------------------------------------------------------------------
  // OK: JUAN / SPRINT 2
  public async getPublicCompanies(data: any) {
    const url = route_companies + '/all-companies/list' + '?limit=' + data.limit + '&page=' + data.page;
    return await firstValueFrom(this.http.get(url));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      limit: 'limit',
      page: 'page'
    };

    // EXPECTED RESPONSE - RETURN ONLY STATUS 'active' OR true if boolean attribute
    const response: any = {
      data: {
        result: [
          {
            id: 'id',
            businessName: 'businessName',
            companyLogo: 'companyLogo',
          }
        ],
        pages: 1
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // CORRECTION: JUAN - FOR BEEN PUBLIC, IT DOES NOT RETURN ALL OBJECT, CHECK BELLOW TO SEE WHAT ITEMS SHOULD RETURN / SPRINT 3
  public async getPublicCompany(id: string) {
    const url = route_companies + '/company/' + id;
    return await firstValueFrom(this.http.get(url));

    // NO ROLE CHECKING

    // DATA IS SENDED ON URI

   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        companyActivityId: '66a1bf0b-8797-44bb-a7ed-50bae1d952fa',
        companyName: 'Ismocol S.A.S.',
        businessName: 'Ismocol',
        companyIdentification: '123.456.789-0',
        companyLogo: 'assets/images/brands/logo - ismocol.png',
        companyPortrait: 'portrait.png',
        companyAbout: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        companyEmail: 'info@ismocol.com',
        companyCellphoneCountryCode: null,
        companyCellphone: null,
        companyPhoneCountryCode: null,
        companyPhone: null,
        companyWhatsappCountryCode: null,
        companyWhatsapp: null,
        companyUriFacebook: null,
        companyUserInstagram: null,
        companyUserTwitter: null,
        companyUriTiktok: null,
        companyUriYoutube: null,
        companyUriLinkedin: null,
        companyWebProtocol: 'https://',
        companyWeb: 'www.ismocol.com',
      }
    }
    // return response;

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES
 
  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async getCompanies(data: any) {
    const url = route_companies + '?limit=' + data.limit + '&page=' + data.page + '&companyName=' + data.companyName + '&companyIdentification=' + data.companyIdentification + '&companyStatus=' + data.companyStatus;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent'];

    // DATA IS SENDED ON URI

    // DATA SENDED
    // SOME ATTRIBUTES CAN BE null, IN THAT CASE SHOULD NOT BE TAKEN ON THE WHERE AT THE QUERY
    const sendedURIData: any = {
      limit: 'limit',
      page: 'page',
      companyName: 'companyName',
      companyIdentification: 'companyIdentification',
      companyStatus: 'companyStatus'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        result: [
          {
            id: 'id',
            companyName: 'companyName',
            businessName: 'businessName',
            companyIdentification: 'companyIdentification',
            companyLogo: 'companyLogo',
            companyEmail: 'companyEmail',
            companyStatus: 'companyStatus', // ENUM('pendingForReview', 'active', 'unactive')
          }
        ],
        pages: 1
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: JUAN - CORRECTION BY ANDRES / SPRINT 3
  public async getCompany(id: string) {
    const url = route_companies + '/' + id;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar', 'client'];

    // DATA IS SENDED ON URI

   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        company: 'company' // OBJECT
      }
    }

    // RECEIVED RESPONSE
    const responseReceived = {
      "statusCode": 401,
      "error": true,
      "path": "/companies/7d913f68-e17a-479d-8d5b-db792631db8e",
      "timestamp": "2023-06-21T20:24:41.543Z",
      "data": "Invalid role"
    }

    const simulatedResponse = {
      data: {
        id: "7d913f68-e17a-479d-8d5b-db792631db8e",
        companyConstitutionDate: "2023-05-30 21:34:17.859725+00",
        companyName: "Ismocol S.A.S.",
        businessName: "Ismocol",
        companyIdentification: "123.456.789-0",
        companyLogo: "assets/images/brands/logo - ismocol.png",
        companyPortrait: "portrait.png",
        companyAbout: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        companyEmail: "info@ismocol.com",
        companyPhoneCountryCode: null,
        companyPhone: null,
        companyCellphoneCountryCode: null,
        companyCellphone: null,
        companyWhatsappCountryCode: null,
        companyWhatsapp: null,
        companyUriFacebook: null,
        companyUserInstagram: null,
        companyUserTwitter: null,
        companyUriTiktok: null,
        companyUriYoutube: null,
        companyUriLinkedin: null,
        companyWebProtocol: "https://",
        companyWeb: "www.ismocol.com",
        termsConditions: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.",
        privacyPolicy: "Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur.",
        companyLoanInstructions: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        companyApprovalRequired: true,
        companyApprovalTxt: "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur",
        companyStatus: true,
        companyActivityId: "66a1bf0b-8797-44bb-a7ed-50bae1d952fa",
        loanTermsConditions: "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.",
        loanPrivacyPolicy: "Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat."
      }
    }
    // return simulatedResponse;

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async addCompany(data: any) {
    const url = route_companies + '/';
    return await firstValueFrom(this.http.post(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'businessAgent'];

    // DATA IS SENDED ON BODY

    // DATA SENDED
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES ADDED
    const sendedBodyData: any = {
      companyActivityId: 'companyActivityId',
      companyName: 'companyName',
      businessName: 'businessName',
      companyIdentification: 'companyIdentification',
      companyConstitutionDate: 'companyConstitutionDate',
      companyLogo: 'companyLogo',
      companyPortrait: 'companyPortrait',
      companyAbout: 'companyAbout',
      companyEmail: 'companyEmail',
      companyCellphoneCountryCode: 'companyCellphoneCountryId',
      companyCellphone: 'companyCellphone',
      companyPhoneCountryCode: 'companyPhoneCountryId',
      companyPhone: 'companyPhone',
      companyWhatsappCountryCode: 'companyWhatsappCountryId',
      companyWhatsapp: 'companyWhatsapp',
      companyUriFacebook: 'companyUriFacebook',
      companyUserInstagram: 'companyUserInstagram',
      companyUserTwitter: 'companyUserTwitter',
      companyUriTiktok: 'companyUriTiktok',
      companyUriYoutube: 'companyUriYoutube',
      companyUriLinkedin: 'companyUriLinkedin',
      companyWebProtocol: 'companyWebProtocol',
      companyWeb: 'companyWeb',
      termsConditions: 'termsConditions',
      privacyPolicy: 'privacyPolicy',
      loanTermsConditions: 'loanTermsConditions',
      loanPrivacyPolicy: 'loanPrivacyPolicy',
      companyLoanInstructions: 'companyLoanInstructions',
      companyApprovalRequired: false, // false / true
      companyApprovalTxt: 'companyApprovalTxt',
      companyStatus: false, // false / true
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        company: 'company' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async updateCompany(id: string, data: any) {
    const url = route_companies + '/' + id;
    return await firstValueFrom(this.http.put(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'businessAgent'];
  
    // DATA IS SENDED ON URI AND BODY

    // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // DATA SENDED ON BODY
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      companyActivityId: 'companyActivityId',
      companyName: 'companyName',
      businessName: 'businessName',
      companyIdentification: 'companyIdentification',
      companyConstitutionDate: 'companyConstitutionDate',
      companyLogo: 'companyLogo',
      companyPortrait: 'companyPortrait',
      companyAbout: 'companyAbout',
      companyEmail: 'companyEmail',
      companyCellphoneCountryCode: 'companyCellphoneCountryId',
      companyCellphone: 'companyCellphone',
      companyPhoneCountryCode: 'companyPhoneCountryId',
      companyPhone: 'companyPhone',
      companyWhatsappCountryCode: 'companyWhatsappCountryId',
      companyWhatsapp: 'companyWhatsapp',
      companyUriFacebook: 'companyUriFacebook',
      companyUserInstagram: 'companyUserInstagram',
      companyUserTwitter: 'companyUserTwitter',
      companyUriTiktok: 'companyUriTiktok',
      companyUriYoutube: 'companyUriYoutube',
      companyUriLinkedin: 'companyUriLinkedin',
      companyWebProtocol: 'companyWebProtocol',
      companyWeb: 'companyWeb',
      termsConditions: 'termsConditions',
      privacyPolicy: 'privacyPolicy',
      loanTermsConditions: 'loanTermsConditions',
      loanPrivacyPolicy: 'loanPrivacyPolicy',
      companyLoanInstructions: 'companyLoanInstructions',
      companyApprovalRequired: false, // false / true
      companyApprovalTxt: 'companyApprovalTxt',
      companyStatus: false, // false / true
    };
  
    // EXPECTED RESPONSE
    const response: any = {
      data: {
        company: 'company' // OBJECT
      }
    }
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async removeCompany(id: string) {
    const url = route_companies + '/' + id;
    return await firstValueFrom(this.http.delete(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator'];
  
    // DATA IS SENDED ON URI
  
   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // NO EXPECTED RESPONSE

    // ON DELETE, REPLACE ON CASCADE FOR NULL
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }

  // other companies
  // ---------------------------------------------------------------------
  // OK: JUAN / SPRINT 2
  public async getPublicOtherCompanies() {
    const url = route_other_companies + '/all-other-companies/';
    return await firstValueFrom(this.http.get(url));

    // NO ROLES

    // NO DATA IS SENDED

    // EXPECTED RESPONSE - RETURN ONLY STATUS 'active' OR true if boolean attribute
    const response: any = {
      data: {
        result: [
          {
            id: 'id',
            companyName: 'companyName'
          }
        ]
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: JUAN / SPRINT 2
  public async addPublicOtherCompany(data: any) {
    const url = route_other_companies + '/create-other-company';
    return await firstValueFrom(this.http.post(url, data));

    // ALL

    // DATA IS SENDED ON BODY

    // DATA SENDED
    const sendedBodyData: any = {
      companyName: 'companyName',
    };

    // SET STATUS TO 'pendingForReview'

    // EXPECTED RESPONSE
    const response: any = {
      data: 'id'
    }

    // RESPONSE RECEIVED
    const responseReceived = {
      "statusCode": 404,
      "error": true,
      "path": "/other-companies/create-other-company",
      "timestamp": "2023-06-14T02:20:27.075Z",
      "data": "Cannot POST /other-companies/create-other-company"
  }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: JUAN / SPRINT 3
  public async updatePublicOtherCompany(id: string, data: any) {
    const url = route_other_companies  + '/loan-company/' + id;
    return await firstValueFrom(this.http.post(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'businessAgent'];
  
    // DATA IS SENDED ON URI AND BODY

    // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // DATA SENDED ON BODY
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      companyName: 'companyName'
    };
  
    // EXPECTED RESPONSE
    const response: any = {
      data: {
        otherCompany: 'otherCompany' // OBJECT
      }
    }
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES
  
  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async getOtherCompanies(data: any) {
    const url = route_other_companies + '?limit=' + data.limit + '&page=' + data.page + '&companyName=' + data.companyName + '&companyIdentification=' + data.companyIdentification + '&companyStatus=' + data.companyStatus;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent'];

    // DATA IS SENDED ON URI

    // DATA SENDED
    // SOME ATTRIBUTES CAN BE null, IN THAT CASE SHOULD NOT BE TAKEN ON THE WHERE AT THE QUERY
    const sendedURIData: any = {
      limit: 'limit',
      page: 'page',
      companyName: 'companyName',
      companyIdentification: 'companyIdentification',
      companyStatus: 'companyStatus'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        result: [
          {
            otherCompany: 'otherCompany' // OBJECT
          }
        ],
        pages: 1
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: JUAN / SPRINT 3
  public async getOtherCompany(id: string) {
    const url = route_other_companies + '/' + id;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent'];

    // DATA IS SENDED ON URI

   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        otherCompany: 'otherCompany' // OBJECT
      }
    }
    // return response;

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async addOtherCompany(data: any) {
    const url = route_other_companies + '/';
    return await firstValueFrom(this.http.post(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'businessAgent'];

    // DATA IS SENDED ON BODY

    // DATA SENDED
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      companyActivityId: 'companyActivityId',
      companyName: 'companyName',
      companyIdentification: 'companyIdentification',
      companyStatus: 'companyStatus', // ENUM('pendingForReview', 'active', 'unactive') // true / false
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        otherCompany: 'otherCompany' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES
 
  }
  // OK CORRECTION: JUAN - NOT DONE - ASSIGN TO ANDRES / SPRINT 3
  public async updateOtherCompany(id: string, data: any) {
    const url = route_other_companies + '/' + id;
    return await firstValueFrom(this.http.put(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'businessAgent'];
  
    // DATA IS SENDED ON URI AND BODY

    // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // DATA SENDED ON BODY
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      companyActivityId: 'companyActivityId',
      companyName: 'companyName',
      companyIdentification: 'companyIdentification',
      companyStatus: 'companyStatus', // ENUM('pendingForReview', 'active', 'unactive') // true / false
    };
  
    // EXPECTED RESPONSE
    const response: any = {
      data: {
        otherCompany: 'otherCompany' // OBJECT
      }
    }

    // RESPONSE RECEIVED
    const responseReceived = {
      "statusCode": 404,
      "error": true,
      "path": "/other-companies/438f0ba0-c26e-493e-a345-1a072b67c129",
      "timestamp": "2023-06-14T02:46:56.862Z",
      "data": "Cannot POST /other-companies/438f0ba0-c26e-493e-a345-1a072b67c129"
  }

    // THIS IS DIFFERENT THAN THE PUBLIC ENDPOINT... ON THE PUBLIC IT ONLY CAN UPDATE THE COMPANY NAME, BUT HERE YOU CAN UPDATE ALL DATA
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES
  
  }
  // OK ASSIGNED TO ANDRES / SPRINT 3
  public async removeOtherCompany(id: string) {
    const url = route_other_companies + '/' + id;
    return await firstValueFrom(this.http.delete(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator'];
  
    // DATA IS SENDED ON URI
  
   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // NO EXPECTED RESPONSE

    // ON DELETE, REPLACE ON CASCADE FOR NULL
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES
  
  }

  // company activities
  // ---------------------------------------------------------------------
  // OOPS: ASSIGNED TO ANDRES / SPRINT OOPS
  public async getPublicCompanyActivities() {
    const url = route_company_activities + '/all-company-activities/list';
    return await firstValueFrom(this.http.get(url));

    // NO ROLES
  
    // DATA IS SENDED ON URI
  
    // DATA SENDED
    // SOME ATTRIBUTES CAN BE null, IN THAT CASE SHOULD NOT BE TAKEN ON THE WHERE AT THE QUERY
    const sendedURIData: any = {
      limit: 'limit',
      page: 'page',
      companyActivity: 'companyActivity',
      companyActivityStatus: 'companyActivityStatus',
    };
  
    // EXPECTED RESPONSE
    const response: any = {
      data: {
        result: [
          {
            companyActivity: 'companyActivity' // OBJECT
          }
        ],
        pages: 1
      }
    }
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES
  
  }
  // OOPS: ASSIGNED TO ANDRES / SPRINT OOPS
  public async getCompanyActivities(data: any) {
    const url = route_company_activities + '?limit=' + data.limit + '&page=' + data.page + '&companyActivity=' + data.companyActivity + '&companyActivityStatus=' + data.companyActivityStatus;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];
  
    // DATA IS SENDED ON URI
  
    // DATA SENDED
    // SOME ATTRIBUTES CAN BE null, IN THAT CASE SHOULD NOT BE TAKEN ON THE WHERE AT THE QUERY
    const sendedURIData: any = {
      limit: 'limit',
      page: 'page',
      companyActivity: 'companyActivity',
      companyActivityStatus: 'companyActivityStatus',
    };
  
    // EXPECTED RESPONSE
    const response: any = {
      data: {
        result: [
          {
            companyActivity: 'companyActivity' // OBJECT
          }
        ],
        pages: 1
      }
    }
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES
  
  }
  // OOPS: ASSIGNED TO ANDRES / SPRINT OOPS
  public async getCompanyActivity(id: string) {
    const url = route_company_activities + '/' + id;
    return await firstValueFrom(this.http.get(url));
  
    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator'];
  
    // DATA IS SENDED ON URI
  
   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // EXPECTED RESPONSE
    const response: any = {
      data: {
        companyActivity: 'companyActivity' // OBJECT
      }
    }
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES
  
  }
  // OOPS: ASSIGNED TO ANDRES / SPRINT OOPS
  public async addCompanyActivity(data: any) {
    const url = route_company_activities;
    return await firstValueFrom(this.http.post(url, data));
  
    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator'];
  
    // DATA IS SENDED ON BODY
  
    // DATA SENDED
    const sendedBodyData: any = {
      companyActivityCode: 'companyActivityCode',
      companyActivity: 'companyActivity',
      companyActivityStatus: 'companyActivityStatus', // boolean
    };
  
    // EXPECTED RESPONSE
    const response: any = {
      data: {
        companyActivity: 'companyActivity' // OBJECT
      }
    }
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES
  
  }
  // OOPS: ASSIGNED TO ANDRES / SPRINT OOPS
  public async updateCompanyActivity(id: string, data: any) {
    const url = route_company_activities + '/' + id;
    return await firstValueFrom(this.http.put(url, data));
  
    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator'];
  
    // DATA IS SENDED ON URI AND BODY

    // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // DATA SENDED
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      companyActivityCode: 'companyActivityCode',
      companyActivity: 'companyActivity',
      companyActivityStatus: 'companyActivityStatus', // booleand
    };
  
    // EXPECTED RESPONSE
    const response: any = {
      data: {
        companyActivity: 'companyActivity' // OBJECT
      }
    }
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES
  
  }
  // OOPS: ASSIGNED TO ANDRES / SPRINT OOPS
  public async removeCompanyActivity(id: string) {
    const url = route_company_activities + id;
    return await firstValueFrom(this.http.delete(url));
  
    // ROLE CHECKING
    const roleAccess: any = ['support'];
  
    // DATA IS SENDED ON URI
  
   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // NO EXPECTED RESPONSE
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES
  
  }

  // company users
  // ---------------------------------------------------------------------
  // OK: ANDRES / SPRINT 3.2
  public async linkPublicCompanyUser(data: any) {
    const url = route_companies_users ;
    return await firstValueFrom(this.http.post(url, data));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON BODY

    // DATA SENDED
    const sendedBodyData: any = {
      companyId: 'companyId',
    };

    // NO EXPECTED RESPONSE

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async getCompanyUsers(companyId: string) {
    const url = route_companies_users + '/company/' + companyId;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      companyId: 'companyId'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        result: [
          {
            id: 'id',
            firstName: 'firstName',
            lastName: 'lastName',
            email: 'email',
            cellphoneCountryCode: 'cellphoneCountryCode',
            cellphone: 'cellphone',
            avatar: 'avatar',
            userStatus: 'userStatus', // ENUM('pendingForReview', 'active', 'unactive')
          }
        ]
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async getUserCompanies(userId: string) {
    const url = route_companies_users + '/users/' + userId;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      userId: 'userId'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        result: [
          {
            id: 'id',
            companyName: 'companyName',
            businessName: 'businessName',
            companyIdentification: 'companyIdentification',
            companyLogo: 'companyLogo',
            companyEmail: 'companyEmail',
            companyStatus: 'companyStatus', // ENUM('pendingForReview', 'active', 'unactive')
          }
        ]
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async getMyUserCompanies() {
    const url = route_companies_users + '/user/';
    return await firstValueFrom(this.http.get(url));

    // NO ROLE CHECKING

    // NO DATA IS SENDED. IDENTIFY USER WITH TOKEN

    // EXPECTED RESPONSE - RETURN ONLY STATUS 'active' OR true if boolean attribute
    const response: any = {
      data: {
        result: [
          {
            id: 'id',
            companyName: 'companyName',
            businessName: 'businessName',
            companyIdentification: 'companyIdentification',
            companyLogo: 'companyLogo',
            companyEmail: 'companyEmail',
          }
        ]
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES
    
  }
  // OK: ANDRES / SPRINT 3.2
  public async linkCompanyUser(data: any) {
    const url = route_companies_users + '/link-user/';
    return await firstValueFrom(this.http.post(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON BODY

    // DATA SENDED
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      userId: 'userId',
      companyId: 'companyId',
    };

    // NO EXPECTED RESPONSE

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async unlinkCompanyUser(data: any) {
    const url = route_companies_users + '/' + data.companyId + '/' + data.userId;
    return await firstValueFrom(this.http.delete(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON URI

    // DATA SENDED
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedURIData: any = {
      userId: 'userId',
      companyId: 'companyId',
    };

    // NO EXPECTED RESPONSE

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }

  // company products
  // ---------------------------------------------------------------------
  // OK: JUAN / SPRINT 3
  public async getPublicCompanyProducts(companyId: string) {
    const url = route_products + '/all-products' + '?companyId=' + companyId
    return await firstValueFrom(this.http.get(url));

    // ALL ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      companyId: 'companyId'
    };

    // EXPECTED RESPONSE - RETURN ONLY STATUS 'active' OR true if boolean attribute
    const response: any = {
      data: {
        result: [
          {
            id: 'id',
            productName: 'productName',
            productDescription: 'productDescription',
            loanMonthMin: 'loanMonthMin',
            loanMonthMax: 'loanMonthMax',
            loanMonthSteps: 'loanMonthSteps',
            loanAmountMin: 'loanAmountMin',
            loanAmountMax: 'loanAmountMax',
            loanAmountMaxLimit: 'loanAmountMaxLimit',
            loanMonthlyRate: 'loanMonthlyRate',
            loanAnualRate: 'loanAnualRate',
            loanReferralMinimum: 'loanReferralMinimum',
            loanPaymentReceipts: 'loanPaymentReceipts',
            borrowingCapacityPercentage: 'borrowingCapacityPercentage',
          }
        ]
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async getCompanyProducts(companyId: string) {
    const url = route_companies_products + '/products/' + companyId;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      companyId: 'companyId'
    };

    // EXPECTED RESPONSE - RETURN ONLY STATUS 'active' OR true if boolean attribute
    const response: any = {
      data: {
      result: [
          {
            product: 'product' // OBJECT
          }
        ]
      }
    }
 
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // ONEMORE: ASSIGNED TO ANDRES / ONEMORE
  public async getProductCompanies(productId: string) {
    const url = route_companies_products + '/companies/' + productId;
    return await firstValueFrom(this.http.get(url));

    // CHECK: ADD ENDPOINT

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      productId: 'productId'
    };

    // EXPECTED RESPONSE - RETURN ONLY STATUS 'active' OR true if boolean attribute
    const response: any = {
      data: {
      result: [
          {
            company: 'company' // OBJECT
          }
        ]
      }
    }
 
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async linkCompanyProduct(data: any) {
    const url = route_companies_products;
    return await firstValueFrom(this.http.post(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'businessAgent'];

    // DATA IS SENDED ON BODY

    // DATA SENDED
    const sendedBodyData: any = {
      companyId: 'companyId',
      productId: 'productId',
    };

    // NO EXPECTED RESPONSE

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async unlinkCompanyProduct(companyId: string, productId: string) {
    const url = route_companies_products + '/' + companyId + '/' + productId;
    return await firstValueFrom(this.http.delete(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'businessAgent'];

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      companyId: 'companyId',
      productId: 'productId',
    };

    // NO EXPECTED RESPONSE

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }

  // venues
  // ---------------------------------------------------------------------
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async getVenues(data: any) {
    const url = route_venues + '?limit=' + data.limit + '&page=' + data.page + '&companyId=' + data.companyId + '&venueName=' + data.venueName + '&status=' + data.status;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON URI

    // DATA SENDED
    // SOME ATTRIBUTES CAN BE null, IN THAT CASE SHOULD NOT BE TAKEN ON THE WHERE AT THE QUERY
    const sendedURIData: any = {
      limit: 'limit',
      page: 'page',
      companyId: 'companyId',
      venueName: 'venueName',
      status: 'status'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        result: [
          {
            venue: 'venue', // OBJECT
          }
        ],
        page: 1
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async getVenue(id: string) {
    const url = route_venues + '/' + id;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON URI

   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        venue: 'venue' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES
  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async addVenue(data: any) {
    const url = route_venues;
    return await firstValueFrom(this.http.post(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar', 'client'];

    // DATA IS SENDED ON BODY

    // DATA SENDED
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      venueName: 'venueName',
      cityId: 'cityId',
      address: 'address',
      address2: 'address2',
      latitude: 'latitude',
      longitude: 'longitude',
      status: false, // false / true
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        venue: 'venue' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async updateVenue(id: string, data: any) {
    const url = route_companies  + '/' + id;
    return await firstValueFrom(this.http.put(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar', 'client'];
  
    // DATA IS SENDED ON BODY
  
    // DATA SENDED
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      venueName: 'venueName',
      cityId: 'cityId',
      address: 'address',
      address2: 'address2',
      latitude: 'latitude',
      longitude: 'longitude',
      status: false, // false / true
    };
  
    // EXPECTED RESPONSE
    const response: any = {
      data: {
        venue: 'venue' // OBJECT
      }
    }
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async removeVenue(id: string) {
    const url = route_companies + '/venues/' + id;
    return await firstValueFrom(this.http.delete(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar', 'client'];
  
    // DATA IS SENDED ON URI
  
   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // NO EXPECTED RESPONSE

    // ON DELETE, REPLACE ON CASCADE FOR NULL
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }

  // users
  // ---------------------------------------------------------------------
  // OK: JUAN / SPRINT 2
  public async addPublicUser(data: any) {
    const url = route_users + '/user';
    return await firstValueFrom(this.http.post(url, data));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON BODY

    // DATA SENDED
    const sendedBodyData: any = {
      email: 'email',
      cellphoneCountryCode: 'countryId',
      cellphone: 'cellphone',
      firstName: 'firstName',
      secondName: 'secondName',
      lastName: 'lastName',
      surname: 'surname',
      documentType: 'documentType', // ENUM('id', 'foreign', 'passport', 'workPermission')
      documentNumber: 'documentNumber',
      documentExpeditionCityId: 'cityId',
      documentExpeditionDate: 'documentExpeditionDate',
      nationalityId: 'countryId',
      birthDate: 'birthDate',
      birthPlaceId: 'cityId',
      gender: 'gender', // ENUM('male', 'female')
      civilState: 'civilState', // ENUM('single', 'married', 'divorced', 'widow', 'freeUnion')
      housingType: 'housingType', // ENUM('rent', 'family', 'own')
      dependents: 'dependents',
      registerFrom: 'registerFrom' // ENUM('form', 'company')
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        accessToken: 'token'
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: JUAN / SPRINT 3
  public async updatePublicUser(data: any) {
    // set public api
    localStorage.setItem('publicApi', 'true');
    // set url
    const url = route_users + '/user';
    // call api
    const api = await firstValueFrom(this.http.patch(url, data));
    // check for api
    if (api) {
      // remove public api option
      localStorage.removeItem('publicApi');
    }
    // return data
    return api;

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON BODY

    // DATA SENDED
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      email: 'email',
      cellphoneCountryCode: 'cellphoneCountryCode',
      cellphone: 'cellphone',
      firstName: 'firstName',
      secondName: 'secondName',
      lastName: 'lastName',
      surname: 'surname',
      documentType: 'documentType',
      documentNumber: 'documentNumber',
      documentExpeditionCityId: 'documentExpeditionCityId',
      documentExpeditionDate: 'documentExpeditionDate',
      nationalityId: 'nationalityId',
      birthDate: 'birthDate',
      birthPlaceId: 'birthPlaceId',
      gender: 'gender',
      civilState: 'civilState',
    };

    // NO EXPECTED RESPONSE

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ANDRES / SPRINT 3
  public async getUsers(data: any) {
    const url = route_users + '?limit=' + data.limit + '&page=' + data.page + '&companyId=' + data.companyId + '&email=' + data.email + '&cellphone=' + data.cellphone + '&firstName=' + data.firstName + '&lastName=' + data.lastName + '&documentNumber=' + data.documentNumber + '&userStatus=' + data.userStatus + '&userRole=' + data.userRole;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON URI

    // DATA SENDED
    // SOME ATTRIBUTES CAN BE null, IN THAT CASE SHOULD NOT BE TAKEN ON THE WHERE AT THE QUERY
    const sendedURIData: any = {
      limit: 'limit',
      page: 'page',
      companyId: 'companyId',
      email: 'email',
      cellphone: 'cellphone',
      firstName: 'firstName',
      lastName: 'lastName',
      documentNumber: 'documentNumber',
      userStatus: 'userStatus', // ENUM('pendingForReview', 'active', 'unactive')
      userRole: 'userRole',
    }

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        result: [
          {
            id: '4dcec7eb-fc8e-4ad0-9877-fc23c6fc2c97',
            professionId: 'e4bb9c36-5484-4189-a2ad-cb60e332d998',
            nationalityId: 'd27a2ec8-e942-4044-b00c-382caf3a2673',
            birthPlaceId: '68f5138e-287c-46f4-a24c-62abba23dfa6',
            documentExpeditionCityId: '5412f55c-1a12-4d05-bfac-aad85667c0c5',
            documentExpeditionDate: '2002-01-02 00:00:00+00',
            documentType: 'id',
            documentNumber: '901093402-4',
            firstName: 'Soporte',
            secondName: null,
            lastName: 'Think In',
            surname: null,
            email: 'soporte@think-in.co',
            cellphoneCountryCode: 'd27a2ec8-e942-4044-b00c-382caf3a2673',
            cellphone: '3024504130',
            phoneCountryCode: null,
            phone: null,
            avatar: null,
            gender: null,
            birthDate: null,
            civilState: null,
            dependents: null,
            housingType: null,
            treatmentCheck: '',
            userRoles: '[{"roleName":"support","roleActive":true,"dateAssigned":"2023-06-06T05:43:34.844Z"}]',
            userStatus: 'active',
            registerFrom: 'company'
          },
          {
            id: 'd2b90165-09d8-4e63-a023-db21492e4179',
            professionId: 'e4bb9c36-5484-4189-a2ad-cb60e332d998',
            nationalityId: 'd27a2ec8-e942-4044-b00c-382caf3a2673',
            birthPlaceId: '68f5138e-287c-46f4-a24c-62abba23dfa6',
            documentExpeditionCityId: '5412f55c-1a12-4d05-bfac-aad85667c0c5',
            documentExpeditionDate: '2002-01-02 00:00:00+00',
            documentType: 'id',
            documentNumber: '80740810',
            firstName: 'Alejandro',
            secondName: '',
            lastName: 'Bermúdez',
            surname: 'Restrepo',
            email: 'idemshall@gmail.com',
            cellphoneCountryCode: 'd27a2ec8-e942-4044-b00c-382caf3a2673',
            cellphone: '3024504130',
            phoneCountryCode: null,
            phone: null,
            avatar: null,
            gender: 'male',
            birthDate: '1983-12-23 00:00:00+00',
            civilState: 'married',
            dependents: 2,
            housingType: 'rent',
            treatmentCheck: '',
            userRoles: '[{"roleName":"support","roleActive":true,"dateAssigned":"2023-06-06T05:43:34.844Z"}]',
            userStatus: 'active',
            registerFrom: 'company'
          }
        ],
        pages: 1
      }
    }
    // return response;

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ANDRES / SPRINT 3.5
  public async getUsersByCompanyAndRole(data: any) {
    const url = route_loans + '/team-information/' + data.companyId + '/' + data.userRole;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON URI

    // DATA SENDED
    // SOME ATTRIBUTES CAN BE null, IN THAT CASE SHOULD NOT BE TAKEN ON THE WHERE AT THE QUERY
    const sendedURIData: any = {
      companyId: 'companyId',
      userRole: 'userRole',
    }

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        result: [
          {
            id: '4dcec7eb-fc8e-4ad0-9877-fc23c6fc2c97',
            professionId: 'e4bb9c36-5484-4189-a2ad-cb60e332d998',
            nationalityId: 'd27a2ec8-e942-4044-b00c-382caf3a2673',
            birthPlaceId: '68f5138e-287c-46f4-a24c-62abba23dfa6',
            documentExpeditionCityId: '5412f55c-1a12-4d05-bfac-aad85667c0c5',
            documentExpeditionDate: '2002-01-02 00:00:00+00',
            documentType: 'id',
            documentNumber: '901093402-4',
            firstName: 'Soporte',
            secondName: null,
            lastName: 'Think In',
            surname: null,
            email: 'soporte@think-in.co',
            cellphoneCountryCode: 'd27a2ec8-e942-4044-b00c-382caf3a2673',
            cellphone: '3024504130',
            phoneCountryCode: null,
            phone: null,
            avatar: null,
            gender: null,
            birthDate: null,
            civilState: null,
            dependents: null,
            housingType: null,
            treatmentCheck: '',
            userRoles: '[{"roleName":"support","roleActive":true,"dateAssigned":"2023-06-06T05:43:34.844Z"}]',
            userStatus: 'active',
            registerFrom: 'company'
          },
          {
            id: 'd2b90165-09d8-4e63-a023-db21492e4179',
            professionId: 'e4bb9c36-5484-4189-a2ad-cb60e332d998',
            nationalityId: 'd27a2ec8-e942-4044-b00c-382caf3a2673',
            birthPlaceId: '68f5138e-287c-46f4-a24c-62abba23dfa6',
            documentExpeditionCityId: '5412f55c-1a12-4d05-bfac-aad85667c0c5',
            documentExpeditionDate: '2002-01-02 00:00:00+00',
            documentType: 'id',
            documentNumber: '80740810',
            firstName: 'Alejandro',
            secondName: '',
            lastName: 'Bermúdez',
            surname: 'Restrepo',
            email: 'idemshall@gmail.com',
            cellphoneCountryCode: 'd27a2ec8-e942-4044-b00c-382caf3a2673',
            cellphone: '3024504130',
            phoneCountryCode: null,
            phone: null,
            avatar: null,
            gender: 'male',
            birthDate: '1983-12-23 00:00:00+00',
            civilState: 'married',
            dependents: 2,
            housingType: 'rent',
            treatmentCheck: '',
            userRoles: '[{"roleName":"support","roleActive":true,"dateAssigned":"2023-06-06T05:43:34.844Z"}]',
            userStatus: 'active',
            registerFrom: 'company'
          }
        ],
        pages: 1
      }
    }
    // return response;

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: JUAN / SPRINT 3
  public async getUser(id: string) {
    const url = route_users + '/' + id;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON URI

   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        id: 'd27a2ec8-e942-4044-b00c-382caf3a2673',
        professionId: 'e4bb9c36-5484-4189-a2ad-cb60e332d998',
        nationalityId: 'd27a2ec8-e942-4044-b00c-382caf3a2673',
        birthPlaceId: '68f5138e-287c-46f4-a24c-62abba23dfa6',
        documentExpeditionCityId: '5412f55c-1a12-4d05-bfac-aad85667c0c5',
        documentExpeditionDate: '2002-01-02 00:00:00+00',
        documentType: 'id',
        documentNumber: '80740810',
        firstName: 'Alejandro',
        secondName: null,
        lastName: 'Bermúdez',
        surname: 'Restrepo',
        email: 'idemshall@gmail.com',
        cellphoneCountryCode: 'd27a2ec8-e942-4044-b00c-382caf3a2673',
        cellphone: '3024504130',
        phoneCountryCode: null,
        phone: null,
        avatar: null,
        gender: 'male',
        birthDate: '1983-12-23 00:00:00+00',
        civilState: 'married',
        dependents: 2,
        housingType: 'rent',
        treatmentCheck: null,
        userRoles: '[{"roleName":"support","roleActive":true,"dateAssigned":"2023-06-06T05:43:34.844Z"}]',
        userStatus: 'active'
      }
    }
    // return response;

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async getMyUser() {
    const url = route_users+'/user-info';
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['client'];

    // NO DATA SENDED. IDENTIFY USER WITH TOKEN

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        professionId: 'e4bb9c36-5484-4189-a2ad-cb60e332d998',
        nationalityId: 'd27a2ec8-e942-4044-b00c-382caf3a2673',
        birthPlaceId: '68f5138e-287c-46f4-a24c-62abba23dfa6',
        documentExpeditionCityId: '5412f55c-1a12-4d05-bfac-aad85667c0c5',
        documentExpeditionDate: '2002-01-02 00:00:00+00',
        documentType: 'id',
        documentNumber: '80740810',
        firstName: 'Alejandro',
        secondName: null,
        lastName: 'Bermúdez',
        surname: 'Restrepo',
        email: 'idemshall@gmail.com',
        cellphoneCountryCode: 'd27a2ec8-e942-4044-b00c-382caf3a2673',
        cellphone: '3024504130',
        phoneCountryCode: null,
        phone: null,
        avatar: null,
        gender: 'male',
        birthDate: '1983-12-23 00:00:00+00',
        civilState: 'married',
        dependents: 2,
        housingType: 'rent',
      }
    }
    return response;

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async addUser(data: any) {
    const url = route_users;
    return await firstValueFrom(this.http.post(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON BODY

    // DATA SENDED
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      professionId: 'professionId',
      nationalityId: 'countryId',
      birthPlaceId: 'cityId',
      documentExpeditionCityId: 'cityId',
      documentExpeditionDate: 'documentExpeditionDate',
      documentType: 'documentType', // ENUM('id', 'foreign', 'passport', 'workPermission')
      documentNumber: 'documentNumber',
      firstName: 'firstName',
      secondName: 'secondName',
      lastName: 'lastName',
      surname: 'surname',
      email: 'email',
      cellphoneCountryCode: 'countryId',
      cellphone: 'cellphone',
      phoneCountryCode: 'countryId',
      phone: 'phone',
      avatar: 'avatar',
      gender: 'gender', // ENUM('male', 'female')
      birthDate: 'birthDate',
      civilState: 'civilState', // ENUM('single', 'married', 'divorced', 'widow', 'freeUnion')
      dependents: 2,
      housingType: 'housingType', // ENUM('rent', 'family', 'own')
      treatmentCheck: 'treatmentCheck',
      userRoles: 'userRoles', // JSON WITH THIS POSIBLE ROLES ENUM('support', 'administrator', 'board', 'businessAgent', 'treasure', 'ceo', 'manager', 'auxiliar', 'client')
      userStatus: 'userStatus', // ENUM('pendingForReview', 'active', 'unactive') // ENUM('pending', 'active', 'unactive')
      registerFrom: 'registerFrom' // ENUM('form', 'company')
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        user: 'user' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: CORRECTION: JUAN - CHECK BELLOW ON RESPONSE RECEIVED FOR FURTHER DETAILS / SPRINT 3
  public async updateUser(id: string, data: any) {
    const url = route_users + '/' + id;
    return await firstValueFrom(this.http.put(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON URI AND BODY

    // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };

    // DATA SENDED ON BODY
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      professionId: 'professionId',
      nationalityId: 'countryId',
      birthPlaceId: 'cityId',
      documentExpeditionCityId: 'cityId',
      documentExpeditionDate: 'documentExpeditionDate',
      documentType: 'documentType', // ENUM('id', 'foreign', 'passport', 'workPermission')
      documentNumber: 'documentNumber',
      firstName: 'firstName',
      secondName: 'secondName',
      lastName: 'lastName',
      surname: 'surname',
      email: 'email',
      cellphoneCountryCode: 'countryId',
      cellphone: 'cellphone',
      phoneCountryCode: 'countryId',
      phone: 'phone',
      avatar: 'avatar',
      gender: 'gender', // ENUM('male', 'female')
      birthDate: 'birthDate',
      civilState: 'civilState', // ENUM('single', 'married', 'divorced', 'widow', 'freeUnion')
      dependents: 2,
      housingType: 'housingType', // ENUM('rent', 'family', 'own')
      treatmentCheck: 'treatmentCheck',
      userRoles: 'userRoles', // JSON WITH THIS POSIBLE ROLES ENUM('support', 'administrator', 'board', 'businessAgent', 'treasure', 'ceo', 'manager', 'auxiliar', 'client')
      userStatus: 'userStatus', // ENUM('pendingForReview', 'active', 'unactive') // ENUM('pending', 'active', 'unactive')
      registerFrom: 'registerFrom' // ENUM('form', 'company')
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        user: 'user' // OBJECT
      }
    }

    // RESPONSE RECEIVED
    const responseReceived = {
      "statusCode": 500,
      "error": true,
      "path": "/professions?limit=10&page=1&professionName=null",
      "timestamp": "2023-06-13T22:23:18.111Z",
      "data": "Property \"bankId\" was not found in \"Professions\". Make sure your query is correct."
    }

    // WAS UPDATING OTHER USER BEEN SUPPORT USER, CHECK THE ROLE ACCESS RELATED ABOVE "roleAccess" 
    // CHECK URL

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: JUAN / SPRINT 3
  public async removeUser(id: string) {
    const url = route_users + '/' + id;
    return await firstValueFrom(this.http.delete(url));

    // ROLE CHECKING
    const roleAccess: any = ['support'];

    // DATA IS SENDED ON URI
  
    // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // NO EXPECTED RESPONSE

    // DELETE THE FILE
    // ON DELETE, REPLACE ON CASCADE FOR NULL 
    
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // TODO: ASSIGNED TO ANDRES / SPRINT 5
  public async downloadExcelTemplate() {
    const url = route_users + '/csv-sample';
    return await firstValueFrom(this.http.post(url, null));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // NO DATA SENDED

    // EXPECTED RESPONSE
    const response: any = {
      data: 'filePath'
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // TODO: ASSIGNED TO ANDRES / SPRINT 5
  public async importUsers(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        enctype: 'multipart/form-data; boundary=----WebKitFormBoundaryuL67FWkv1CA'
      })
    };
    const url = route_users + '/import';
    return await firstValueFrom(this.http.post(url, data, httpOptions));

     // ROLE CHECKING
     const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

     // DATA IS SENDED ON BODY
 
     // FILE IS SENDED
 
     // NO EXPECTED RESPONSE
 
     // IF ERROR CATCH WILL HANDLE IT
     // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }

  // user attachment
  // ---------------------------------------------------------------------
  // OK: JUAN / SPRINT 2
  public async getUserAttachments(userId: string) {
    const url = route_users + '/attachments/' + userId;
    return await firstValueFrom(this.http.get(url));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      userId: 'userId',
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        result: [
          {
            userAttachment: 'userAttachment' // OBJECT
          }
        ]
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async getUserAttachment(id: string) {
    const url = route_user_attachment + '/' + id;
    return await firstValueFrom(this.http.get(url));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      id: 'id',
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        userAttachment: 'userAttachment' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: JUAN / SPRINT 2
  public async addUserAttachment(userId: string, data: any) {
    const url = route_users + '/attachments/' + userId;
    return await firstValueFrom(this.http.post(url, data));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI AND BODY

    // DATA SENDED ON URI
    const sendedURIData: any = {
      userId: 'userId'
    };

    // DATA SENDED ON BODY
    const sendedBodyData: any = {
      userAttachmentType: 'userAttachmentType', // ENUM('idFront', 'idBack', 'selfie')
      userAttachment: 'userAttachment',
      fileKey: 'fileKey'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        userAttachment: 'userAttachment' // OBJECT
      }
    }

    // RESPONSE RECEIVED
    const responseReceived = {
      "statusCode": 404,
      "error": true,
      "path": "/users/attachment/d27a2ec8-e942-4044-b00c-382caf3a2673",
      "timestamp": "2023-06-13T22:33:45.308Z",
      "data": "Cannot POST /users/attachment/d27a2ec8-e942-4044-b00c-382caf3a2673"
    }

    // MUST RECEIVE USER ID AS PARAMETER ON URL DUE THAT OTHER USERS LIKE BUSINESS AGENT NEED TO ADD ATTACHMENTS OF A DIFFERENT USER THAT IT'S SEL
    // CHECK URL TOO
    // IS NOT RECEIVING THE USER ID

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: JUAN / SPRINT 3
  public async updateUserAttachment(id: string, data: any) {
    const url = route_users  + '/attachment/' + id;
    return await firstValueFrom(this.http.patch(url, data));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI AND BODY

    // DATA SENDED
    const sendedURIData: any = {
      id: 'id',
    };

    // DATA SENDED ON BODY
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      userAttachmentType: 'userAttachmentType', // ENUM('idFront', 'idBack', 'selfie')
      userAttachment: 'userAttachment',
    };

    // FIND THE PREVIOUSLY SAVED FILE NAME ON THE RECORD
    // IF PREVIOUS FILE NAME IS DIFFERENT THAN THE NEW NAME, FIND PREVIOUS FILE AND DELETE THE FILE
    // THEN REPLACE THE FILENAME ON THE RECORD WITH THE NEW FILE NAME

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        userAttachment: 'userAttachment' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: JUAN / SPRINT 3
  public async removeUserAttachment(id: string) {
    const url = route_users + '/attachment/' + id;
    return await firstValueFrom(this.http.delete(url));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI
  
   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // NO EXPECTED RESPONSE

    // DELETE THE FILE
    // ON DELETE, REPLACE ON CASCADE FOR NULL 
    
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }

  // user addresses
  // ---------------------------------------------------------------------
  // OK: JUAN / SPRINT 2
  public async addPublicUserAddress(data: any) {
    // set public api
    localStorage.setItem('publicApi', 'true');
    // set url
    const url = route_users + '/user-addresses/';
    // call api
    const api = await firstValueFrom(this.http.post(url, data));
    // check for api
    if (api) {
      // remove public api option
      localStorage.removeItem('publicApi');
    }
    // return data
    return api;

    // ALL LOGGED IN ROLES

    // DATA SENDED ON BODY. IDENTIFY USER WITH TOKEN

    // DATA SENDED
    const sendedBodyData: any = {
      userAddressType: 'userAddressType', // ENUM('work', 'home', 'other')
      cityId: 'cityId',
      address: 'address',
      address2: 'address2',
      mailingAddress: 'mailingAddress'
    };

    // EXPECTED RESPONSE 
    const response: any = {
      data: 'id'
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: JUAN / SPRINT 3
  public async updatePublicUserAddress(id: string, data: any) {
    // set public api
    localStorage.setItem('publicApi', 'true');
    // set url
    const url = route_users + '/user-address/' + id;
    // call api
    const api = await firstValueFrom(this.http.put(url, data)); // TODO: CHECK IF IS PUT OR PATCH 
    // check for api
    if (api) {
      // remove public api option
      localStorage.removeItem('publicApi');
    }
    // return data
    return api;

    // const url = route_users + '/user-address/' + id;
    // return await firstValueFrom(this.http.patch(url, data));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI AND BODY

    // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };

    // DATA SENDED ON BODY
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      userAddressType: 'userAddressType', // ENUM('work', 'home', 'other')
      cityId: 'cityId',
      address: 'address',
      address2: 'address2',
      mailingAddress: 'mailingAddress'
    };

    // EXPECTED RESPONSE 
    const response: any = {
      data: {
        userAddress: 'userAddress' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES
    
  }
  // OK: JUAN / SPRINT 2
  public async getUserAddresses(userId: string) {
    const url = route_users + '/addresses/' + userId;
    return await firstValueFrom(this.http.get(url));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED ON URI
    const sendedURIData: any = {
      userId: 'userId'
    };

    // EXPECTED RESPONSE 
    const response: any = {
      data: {
        result: [
          {
            userAddress: 'userAddress' // OBJECT
          }
        ]
      }
    }

    // RESPONSE RECEIVED
    const responseReceived = {
      "statusCode": 404,
      "error": true,
      "path": "/users/user-addresses/d27a2ec8-e942-4044-b00c-382caf3a2673",
      "timestamp": "2023-06-13T22:44:18.606Z",
      "data": "Cannot GET /users/user-addresses/d27a2ec8-e942-4044-b00c-382caf3a2673"
  }

    // MUST RECEIVE USER ID AS PARAMETER ON URL DUE THAT OTHER USERS LIKE BUSINESS AGENT NEED TO GET THE ADDRESSES OF A DIFFERENT USER THAT IT'S SELF

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async getUserAddress(id: string) {
    const url = route_user_address + '/' + id;
    return await firstValueFrom(this.http.get(url));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      id: 'id',
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        userAddress: 'userAddress' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: JUAN / SPRINT 3
  public async addUserAddress(data: any) {
    const url = route_users + '/address/';
    return await firstValueFrom(this.http.post(url, data));

    // ALL LOGGED IN ROLES

    // DATA SENDED ON BODY

    // DATA SENDED
    const sendedBodyData: any = {
      userId: 'userId',
      userAddressType: 'userAddressType', // ENUM('work', 'home', 'other')
      cityId: 'cityId',
      address: 'address',
      address2: 'address2',
      mailingAddress: 'mailingAddress'
    };

    // EXPECTED RESPONSE 
    const response: any = {
      data: {
        userAddress: 'userAddress' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async updateUserAddress(id: string, data: any) {
    const url = route_user_address  + '/' + id;
    return await firstValueFrom(this.http.put(url, data));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI AND BODY

    // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };

    // DATA SENDED ON BODY
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      userAddressType: 'userAddressType', // ENUM('work', 'home', 'other')
      cityId: 'cityId',
      address: 'address',
      address2: 'address2',
      mailingAddress: 'mailingAddress'
    };

    // EXPECTED RESPONSE 
    const response: any = {
      data: {
        userAddress: 'userAddress' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async removeUserAddress(id: string) {
    const url = route_user_address + '/' + id;
    return await firstValueFrom(this.http.delete(url));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI
  
   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // NO EXPECTED RESPONSE

    // ON DELETE, REPLACE ON CASCADE FOR NULL 
    
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }

  // user bank account
  // ---------------------------------------------------------------------
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async addPublicUserBankAccount(data: any) {
    // set public api
    localStorage.setItem('publicApi', 'true');
    // set url
    const url = route_users + '/add-bank/';
    // call api
    const api = await firstValueFrom(this.http.post(url, data));
    // check for api
    if (api) {
      // remove public api option
      localStorage.removeItem('publicApi');
    }
    // return data
    return api;

    // ALL LOGGED IN ROLES

    // DATA SENDED ON BODY. IDENTIFY USER WITH TOKEN

    // DATA SENDED
    const sendedBodyData: any = {
      bankId: 'bankId',
      userBankAccountType: 'userBankAccountType', // ENUM('savings', 'regular')
      userBankAccountNumber: 'userBankAccountNumber'
    };

    // EXPECTED RESPONSE 
    const response: any = {
      data: 'id'
    }

    const simulatedResponse: any = {
      data: 'id'
    }
    return simulatedResponse;

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: JUAN / SPRINT 2
  public async getUserBankAccounts(userId: string) {
    const url = route_users + '/bank-accounts/' + userId;
    return await firstValueFrom(this.http.get(url));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED ON URI
    const sendedURIData: any = {
      userId: 'userId'
    };

    // EXPECTED RESPONSE 
    const response: any = {
      data: {
        result: [
          {
            userBankAccount: 'userBankAccount' // OBJECT
          }
        ]
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async getUserBankAccount(id: string) {
    const url = route_users + '/bank-account/' + id;
    return await firstValueFrom(this.http.get(url));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      id: 'id',
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        userBankAccount: 'userBankAccount' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async addUserBankAccount(data: any) {
    const url = route_users + '/bank-account/';
    return await firstValueFrom(this.http.post(url, data));

    // ALL LOGGED IN ROLES

    // DATA SENDED ON BODY

    // DATA SENDED
    const sendedBodyData: any = {
      userId: 'userId',
      bankId: 'bankId',
      userBankAccountType: 'userBankAccountType', // ENUM('savings', 'regular')
      userBankAccountNumber: 'userBankAccountNumber'
    };

    // EXPECTED RESPONSE 
    const response: any = {
      data: {
        userBankAccount: 'userBankAccount' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async updateUserBankAccount(id: string, data: any) {
    const url = route_users  + '/bank-account/' + id;
    return await firstValueFrom(this.http.put(url, data));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI AND BODY

    // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };

    // DATA SENDED ON BODY
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      bankId: 'bankId',
      userBankAccountType: 'userBankAccountType', // ENUM('savings', 'regular')
      userBankAccountNumber: 'userBankAccountNumber'
    };

    // EXPECTED RESPONSE 
    const response: any = {
      data: {
        userBankAccount: 'userBankAccount' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async removeUserBankAccount(id: string) {
    const url = route_users + '/bank-account/' + id;
    return await firstValueFrom(this.http.delete(url));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI
  
   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // NO EXPECTED RESPONSE

    // ON DELETE, REPLACE ON CASCADE FOR NULL 
    
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }

  // user works
  // ---------------------------------------------------------------------
  // OK: JUAN / SPRINT 2
  public async addPublicUserWork(data: any) {
    // set public api
    localStorage.setItem('publicApi', 'true');
    // set url
    const url = route_users + '/user-works/';
    // call api
    const api = await firstValueFrom(this.http.post(url, data));
    // check for api
    if (api) {
      // remove public api option
      localStorage.removeItem('publicApi');
    }
    // return data
    return api;

    // ALL LOGGED IN ROLES

    // DATA SENDED ON BODY. IDENTIFY USER WITH TOKEN

    // DATA SENDED
    const sendedBodyData: any = {
      companyId: 'companyId',
      otherCompanyId: 'otherCompanyId',
      workType: 'workType', // ENUM('employee', 'entrepreneur', 'student', 'home', 'pensioner')
      positionId: 'positionId',
      occupationId: 'occupationId',
      contractType: 'contractType', // ENUM('fixed', 'undefined', 'work')
      salary: 'salary',
      contractStart: 'contractStart',
      contractEnd: 'contractEnd',
      contractActive: 'contractActive' // true / false
    };

    // EXPECTED RESPONSE 
    const response: any = {
      data: 'id'
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: JUAN / SPRINT 3
  public async updatePublicUserWork(id: string, data: any) {
    // set public api
    localStorage.setItem('publicApi', 'true');
    // set url
    const url = route_users + '/user-works/' + id;
    // call api
    const api = await firstValueFrom(this.http.patch(url, data)); // TODO: CHECK IF IS PUT OR PATCH
    // check for api
    if (api) {
      // remove public api option
      localStorage.removeItem('publicApi');
    }
    // return data
    return api;

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI AND BODY

    // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };

    // DATA SENDED ON BODY
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      companyId: 'companyId',
      otherCompanyId: 'otherCompanyId',
      workType: 'workType', // ENUM('employee', 'entrepreneur', 'student', 'home', 'pensioner')
      positionId: 'positionId',
      occupationId: 'occupationId',
      contractType: 'contractType', // ENUM('fixed', 'undefined', 'work')
      salary: 'salary',
      contractStart: 'contractStart',
      contractEnd: 'contractEnd',
      contractActive: 'contractActive' // true / false
    };

    // NO EXPECTED RESPONSE 

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: JUAN / SPRINT 2
  public async getUserWorks(userId: string) {
    const url = route_users + '/user-works/' + userId;
    return await firstValueFrom(this.http.get(url));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED ON URI
    const sendedURIData: any = {
      userId: 'userId'
    };

    // EXPECTED RESPONSE    
    const response = {
      data: {
        result: [
          {
            userId: 'd2b90165-09d8-4e63-a023-db21492e4179',
            workType: 'employee',
            companyId: '7d913f68-e17a-479d-8d5b-db792631db8e',
            otherCompanyId: '',
            positionId: 'fa330ac6-a82b-4c94-9674-c61897b5df98',
            occupationId: '61501903-cb97-4eb8-b180-43ad2cfb61fb',
            contractType: 'undefined',
            salary: 12000000,
            contractStart: '2017-06-17 00:00:00+00',
            contractEnd: null,
            contractActive: false
          }
        ],
        pages: 1
      }
    }
    // return response;

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // TODO: CREATE THIS NEW ENDPOINT
  public async getUserWorksByCompany(companyId: string) {
    const url = route_users + '/user-works/company/' + companyId;
    return await firstValueFrom(this.http.get(url));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED ON URI
    const sendedURIData: any = {
      companyId: 'companyId'
    };

    // EXPECTED RESPONSE    
    const response = {
      data: {
        result: [
          {
            userId: 'd2b90165-09d8-4e63-a023-db21492e4179',
            workType: 'employee',
            companyId: '7d913f68-e17a-479d-8d5b-db792631db8e',
            otherCompanyId: '',
            positionId: 'fa330ac6-a82b-4c94-9674-c61897b5df98',
            occupationId: '61501903-cb97-4eb8-b180-43ad2cfb61fb',
            contractType: 'undefined',
            salary: 12000000,
            contractStart: '2017-06-17 00:00:00+00',
            contractEnd: null,
            contractActive: false
          }
        ],
        pages: 1
      }
    }
    // return response;

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: JUAN - CORRECTION BY ANDRES / SPRINT 3.2
  public async getUserWork(id: string) {
    const url = route_users + '/user-work/' + id;
    return await firstValueFrom(this.http.get(url));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      id: 'id',
    };

    // EXPECTED RESPONSE
    // const response = {
    //   data: {
    //     userWork: 'userWork', // OBJECT
    //   }
    // }
    
    const response = {
      data: {
        userId: 'd27a2ec8-e942-4044-b00c-382caf3a2673',
        workType: 'employee',
        companyId: '7d913f68-e17a-479d-8d5b-db792631db8e',
        otherCompanyId: '',
        positionId: 'fa330ac6-a82b-4c94-9674-c61897b5df98',
        occupationId: '61501903-cb97-4eb8-b180-43ad2cfb61fb',
        contractType: 'undefined',
        salary: 12000000,
        contractStart: '2017-06-17 00:00:00+00',
        contractEnd: null,
        contractActive: false
      }
    }
    // return response;

    // NOT GETTING companyId / otherCompanyId

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async addUserWork(data: any) {
    const url = route_users + '/work/';
    return await firstValueFrom(this.http.post(url, data));

    // ALL LOGGED IN ROLES

    // DATA SENDED ON BODY

    // DATA SENDED
    const sendedBodyData: any = {
      userId: 'userId',
      companyId: 'companyId',
      otherCompanyId: 'otherCompanyId',
      workType: 'workType', // ENUM('employee', 'entrepreneur', 'student', 'home', 'pensioner')
      positionId: 'positionId',
      occupationId: 'occupationId',
      contractType: 'contractType', // ENUM('fixed', 'undefined', 'work')
      contractSalary: 'salary',
      contractStart: 'contractStart',
      contractEnd: 'contractEnd',
      contractActive: 'contractActive' // true / false
    };

    // EXPECTED RESPONSE 
    const response: any = {
      data: {
        userWork: 'userWork' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async updateUserWork(id: string, data: any) {
    const url = route_users  + '/work/' + id;
    return await firstValueFrom(this.http.put(url, data));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI AND BODY

    // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };

    // DATA SENDED ON BODY
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      companyId: 'companyId',
      otherCompanyId: 'otherCompanyId',
      workType: 'workType', // ENUM('employee', 'entrepreneur', 'student', 'home', 'pensioner')
      positionId: 'positionId',
      occupationId: 'occupationId',
      contractType: 'contractType', // ENUM('fixed', 'undefined', 'work')
      salary: 'salary',
      contractStart: 'contractStart',
      contractEnd: 'contractEnd',
      contractActive: 'contractActive' // true / false
    };

    // EXPECTED RESPONSE 
    const response: any = {
      data: {
        userWork: 'userWork' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async removeUserWork(id: string) {
    const url = route_users + '/work/' + id;
    return await firstValueFrom(this.http.delete(url));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI
  
   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // NO EXPECTED RESPONSE

    // ON DELETE, REPLACE ON CASCADE FOR NULL 
    
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }

  // user referrals
  // ---------------------------------------------------------------------
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async getUserReferrals(userId: string) {
    const url = route_users + '/referral/' + userId;
    return await firstValueFrom(this.http.get(url));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED ON URI
    const sendedURIData: any = {
      userId: 'userId'
    };

    // EXPECTED RESPONSE 
    const response: any = {
      data: {
        result: [
          {
            userReferral: 'userReferral' // OBJECT
          }
        ]
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // ASSIGNED TO ANDRES / SPRINT 3.2
  public async getUserReferral(id: string) {
    const url = route_users + '/referral/' + id;
    return await firstValueFrom(this.http.get(url));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      id: 'id',
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        userReferral: 'userReferral' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async addUserReferral(data: any) {
    const url = route_users + '/referral/';
    return await firstValueFrom(this.http.post(url, data));

    // ALL LOGGED IN ROLES

    // DATA SENDED ON BODY

    // DATA SENDED
    const sendedBodyData: any = {
      userId: 'userId',
      userReferralType: 'userReferralType', // ENUM('personal', 'work', 'commercial')
      firstName: 'firstName',
      lastName: 'lastName',
      cellphoneCountryCode: 'countryId',
      cellphone: 'cellphone',
      address: 'address',
      address2: 'address2',
      latitude: 'latitude',
      longitude: 'longitude'
    };

    // EXPECTED RESPONSE 
    const response: any = {
      data: {
        userReferral: 'userReferral' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK:ASSIGNED TO ANDRES / SPRINT 3
  public async updateUserReferral(id: string, data: any) {
    const url = route_users  + '/referral/' + id;
    return await firstValueFrom(this.http.put(url, data));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI AND BODY

    // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };

    // DATA SENDED ON BODY
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      userReferralType: 'userReferralType', // ENUM('personal', 'work', 'commercial')
      firstName: 'firstName',
      lastName: 'lastName',
      cellphoneCountryCode: 'countryId',
      cellphone: 'cellphone',
      address: 'address',
      address2: 'address2',
      latitude: 'latitude',
      longitude: 'longitude'
    };

    // EXPECTED RESPONSE 
    const response: any = {
      data: {
        userReferral: 'userReferral' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async removeUserReferral(id: string) {
    const url = route_users + '/referral/' + id;
    return await firstValueFrom(this.http.delete(url));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI
  
   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // NO EXPECTED RESPONSE

    // ON DELETE, REPLACE ON CASCADE FOR NULL 
    
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }

  // user financials
  // ---------------------------------------------------------------------
  // OK: JUAN / SPRINT 2
  public async addPublicUserFinancial(data: any) {
    // set public api
    localStorage.setItem('publicApi', 'true');
    // set url
    const url = route_users + '/financial/';
    // call api
    const api = await firstValueFrom(this.http.post(url, data));
    // check for api
    if (api) {
      // remove public api option
      localStorage.removeItem('publicApi');
    }
    // return data
    return api;

    // ALL LOGGED IN ROLES

    // DATA SENDED ON BODY. IDENTIFY USER WITH TOKEN

    // DATA SENDED
    const sendedBodyData: any = {
      professionalFees: 'professionalFees',
      otherIncome: 'otherIncome',
      otherIncomeDescription: 'otherIncomeDescription',
      rent: 'rent',
      familyExpenses: 'familyExpenses',
      credits: 'credits',
      otherExpenses: 'otherExpenses',
      publicResourcesHandling: 'publicResourcesHandling', // true / false
      otherAssets: 'otherAssets',
      otherAssetsDescription: 'otherAssetsDescription',
      otherLiabilities: 'otherLiabilities',
      otherLiabilitiesDescription: 'otherLiabilitiesDescription',
      originFunds: 'originFunds',
      userFinancialActive: 'userFinancialActive' // true / false
    };

    // EXPECTED RESPONSE 
    const response: any = {
      data: 'id'
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // CORRECTION: JUAN - CHECK BELLOW ON RESPONSE RECEIVED FOR FURTHER DETAILS / SPRINT 3
  public async updatePublicUserFinancial(userFinancialId: string, data: any) {
    // set public api
    localStorage.setItem('publicApi', 'true');
    // set url
    const url = route_users + '/financial/' + userFinancialId;
    // call api
    const api = await firstValueFrom(this.http.put(url, data));
    // check for api
    if (api) {
      // remove public api option
      localStorage.removeItem('publicApi');
    }
    // return data
    return api;

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI AND BODY

    // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };

    // DATA SENDED ON BODY
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      professionalFees: 'professionalFees',
      otherIncome: 'otherIncome',
      otherIncomeDescription: 'otherIncomeDescription',
      rent: 'rent',
      familyExpenses: 'familyExpenses',
      credits: 'credits',
      otherExpenses: 'otherExpenses',
      publicResourcesHandling: 'publicResourcesHandling', // true / false
      otherAssets: 'otherAssets',
      otherAssetsDescription: 'otherAssetsDescription',
      otherLiabilities: 'otherLiabilities',
      otherLiabilitiesDescription: 'otherLiabilitiesDescription',
      originFunds: 'originFunds',
      userFinancialActive: 'userFinancialActive' // true / false
    };

    // EXPECTED RESPONSE 
    const response: any = {
      data: {
        userFinancial: 'userFinancial' // OBJECT
      }
    }

    // RESPONSE RECEIVED
    const responseReceived = {"statusCode":200,"error":false,"data":"5cd3731e-b58f-41aa-a084-dfe721f3d0bc"}

    // TRIED TO UPDATE publicResourcesHandling ATTRIBUTE OF THE USER FINANCIAL ID '5cd3731e-b58f-41aa-a084-dfe721f3d0bc'. 
    // IT RETURNED 200 BUT WHEN CHECKED ON THE DATABASE , THE ATTRIBUTE WAS NOT CHANGED
    // NOW IS GIVING ME AN INVALID ROLE MSG

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: JUAN - REASIGNED TO ANDRES / SPRINT 2
  public async getUserFinancials(userId: string) {
    const url = route_users + '/user-financial/' + userId;
    return await firstValueFrom(this.http.get(url));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED ON URI
    const sendedURIData: any = {
      userId: 'userId'
    };

    // EXPECTED RESPONSE    
    const response = {
      data: {
        result: [
          {
            userFinancial: 'userFinancial', // OBJECT
          }
        ],
        pages: 1
      }
    }
    // return response;

    // RESPONSE RECEIVED
    const responseReceived = {
      "statusCode": 404,
      "error": true,
      "path": "/users/financials/d27a2ec8-e942-4044-b00c-382caf3a2673",
      "timestamp": "2023-06-14T02:22:34.123Z",
      "data": "Cannot GET /users/financials/d27a2ec8-e942-4044-b00c-382caf3a2673"
    }

    // MUST RECEIVE USER ID AS PARAMETER ON URL DUE THAT OTHER USERS LIKE BUSINESS AGENT NEED TO GET THE FINANCIALS OF A DIFFERENT USER THAT IT'S SELF

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async getUserFinancial(id: string) {
    const url = route_users + '/financial/' + id;
    return await firstValueFrom(this.http.get(url));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      id: 'id',
    };

    // EXPECTED RESPONSE
    // const response: any = {
    //   data: {
    //     userFinancial: 'userFinancial' // OBJECT
    //   }
    // }

    const response: any = {
      data: {
        professionalFees: 1600000,
        otherIncome: 1300000,
        otherIncomeDescription: 'Venta de Productos',
        rent: 1200000,
        familyExpenses: 1300000,
        credits: 950000,
        otherExpenses: 1300000,
        otherExpensesDescription: 'Vacaciones',
        publicResourcesHandling: false,
        otherAssets: 88000000,
        otherAssetsDescription: 'Vehículo',
        otherLiabilities: 10000000,
        otherLiabilitiesDescription: 'Equipos de Oficina',
        originFunds: 'Ventas y Servicios Profesionales',
        userFinancialActive: true
      }
    };
    // return response;

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async addUserFinancial(data: any) {
    const url = route_users + '/user-financial/';
    return await firstValueFrom(this.http.post(url, data));

    // ALL LOGGED IN ROLES BUT CLIENTS CAN ONLY UPDATE IS OWN

    // DATA SENDED ON BODY

    // DATA SENDED
    const sendedBodyData: any = {
      professionalFees: 'professionalFees',
      otherIncome: 'otherIncome',
      otherIncomeDescription: 'otherIncomeDescription',
      rent: 'rent',
      familyExpenses: 'familyExpenses',
      credits: 'credits',
      otherExpenses: 'otherExpenses',
      publicResourcesHandling: 'publicResourcesHandling', // true / false
      otherAssets: 'otherAssets',
      otherAssetsDescription: 'otherAssetsDescription',
      otherLiabilities: 'otherLiabilities',
      otherLiabilitiesDescription: 'otherLiabilitiesDescription',
      originFunds: 'originFunds',
      userFinancialActive: 'userFinancialActive' // true / false
    };

    // EXPECTED RESPONSE 
    const response: any = {
      data: {
        userFinancial: 'userFinancial' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async updateUserFinancial(id: string, data: any) {
    const url = route_users  + '/user-financial/' + id;
    return await firstValueFrom(this.http.put(url, data));

    // ALL LOGGED IN ROLES BUT CLIENTS CAN ONLY UPDATE IS OWN

    // DATA IS SENDED ON URI AND BODY

    // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };

    // DATA SENDED ON BODY
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      professionalFees: 'professionalFees',
      otherIncome: 'otherIncome',
      otherIncomeDescription: 'otherIncomeDescription',
      rent: 'rent',
      familyExpenses: 'familyExpenses',
      credits: 'credits',
      otherExpenses: 'otherExpenses',
      publicResourcesHandling: 'publicResourcesHandling', // true / false
      otherAssets: 'otherAssets',
      otherAssetsDescription: 'otherAssetsDescription',
      otherLiabilities: 'otherLiabilities',
      otherLiabilitiesDescription: 'otherLiabilitiesDescription',
      originFunds: 'originFunds',
      userFinancialActive: 'userFinancialActive' // true / false
    };

    // EXPECTED RESPONSE 
    const response: any = {
      data: {
        userFinancial: 'userFinancial' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async removeUserFinancial(id: string) {
    const url = route_users + '/financial/' + id;
    return await firstValueFrom(this.http.delete(url));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI
  
   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // NO EXPECTED RESPONSE

    // ON DELETE, REPLACE ON CASCADE FOR NULL 
    
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }

  // products
  // ---------------------------------------------------------------------
  // TEST: ASSIGNED TO ANDRES / SPRINT 3.2
  public async getPublicProducts() {
    const url = route_products + '/active-products/list';
    return await firstValueFrom(this.http.get(url));

    // TODO: AÚN SE MUESTRAN LOS PRODUCTOS QUE TIENEN COMPAÑÍAS

    // TODO: TEST ADD ENDPOINT

    // ALL ROLES

    // DATA IS SENDED ON URI

    // NO DATA IS SENDED ON URI

    // EXPECTED RESPONSE ALL ACTIVE PRODUCTS WITH NO COMPANY
    const response = {
      data: {
        result: [
          {
            product: 'product', // OBJECT
          }
        ],
        pages: 1
      }
    }
    return response;

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // TEST: ASSIGNED TO ANDRES / SPRINT 3.2
  public async getProducts(data: any) {
    const url = route_products + '?limit=' + data.limit + '&page=' + data.page + '&companyId=' + data.companyId + '&productCategoryId=' + data.productCategoryId + '&productName=' + data.productName + '&productStatus=' + data.productStatus;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent'];

    // DATA IS SENDED ON URI

    // DATA SENDED ON URI
    const sendedURIData: any = {
      limit: 'limit',
      page: 'page',
      companyId: 'companyId',
      productCategoryId: 'productCategoryId',
      productName: 'productName',
      productStatus: 'productStatus'
    };

    // EXPECTED RESPONSE    
    const response = {
      data: {
        result: [
          {
            product: 'product', // OBJECT
          }
        ],
        pages: 1
      }
    }
    return response;

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: CORRECTION: JUAN - CORRECTION BY ANDRES CHECK BELLOW ON RESPONSE RECEIVED FOR FURTHER DETAILS / SPRINT 3
  public async getProduct(id: string) {
    const url = route_products + '/' + id;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent'];

    // DATA IS SENDED ON URI

   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };

    // EXPECTED RESPONSE
    // const response: any = {
    //   data: {
    //     product: 'product' // OBJECT
    //   }
    // }

    // RESPONSE RECEIVED
    const responseReceived: any = {
      "statusCode": 401,
      "error": true,
      "path": "/products/ad938ad1-5a59-4ea5-88a7-025e759c6adf",
      "timestamp": "2023-06-26T18:22:34.201Z",
      "data": "Invalid role"
    }

    const response: any = {
      data: {
        // product: 'product' // OBJECT
        productCategoryId: '8584e1bb-9f45-4694-9300-5230fdf7f37b',
        productName: 'Libre Inversión Empleados Ismocol',
        productDescription: 'Crédito de Libre Inversión para empleados de Ismocol',
        productPortrait: 'portrait.png',
        productIcon: 'icon.png',
        loanMonthMin: 6,
        loanMonthMax: 24,
        loanMonthSteps: 2,
        loanAmountMin: 500000,
        loanAmountMax: 12,
        loanAmountMaxLimit: false,
        loanInterest: 1.5,
        loanMonthlyRate: 0,
        loanAnualRate: 0,
        loanReferralMinimum: 3,
        loanPaymentReceipts: 3,
        borrowingCapacityPercentage: 30,
      }
    }
    // return response;

    // No debería traer loans, ni productInsurances, ni companyProducts… esos tendrían su endpoind aparte.

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async addProduct(data: any) {
    const url = route_products + '/';
    return await firstValueFrom(this.http.post(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board'];

    // DATA IS SENDED ON BODY

    // DATA SENDED
    const sendedBodyData: any = {
      productCategoryId: 'productCategoryId',
      productName: 'productName',
      productDescription: 'productDescription',
      productPortrait: 'productPortrait',
      productIcon: 'productIcon',
      loanMonthMin: 'loanMonthMin',
      loanMonthMax: 'loanMonthMax',
      loanMonthSteps: 'loanMonthSteps',
      loanAmountMin: 'loanAmountMin',
      loanAmountMax: 'loanAmountMax',
      loanAmountMaxLimit: 'loanAmountMaxLimit', // true / false
      loanMonthlyRate: 'loanMonthlyRate',
      loanAnualRate: 'loanAnualRate',
      loanReferralMinimum: 'loanReferralMinimum',
      loanPaymentReceipts: 'loanPaymentReceipts',
      borrowingCapacityPercentage: 'borrowingCapacityPercentage',
      borrowingCapacity: 'borrowingCapacity',
      productStatus: 'productStatus' // true / false
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        product: 'product' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async updateProduct(id: string, data: any) {
    const url = route_products + '/' + id;
    return await firstValueFrom(this.http.put(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board'];
  
    // DATA IS SENDED ON URI AND BODY

    // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // DATA SENDED ON BODY
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      productCategoryId: 'productCategoryId',
      productName: 'productName',
      productDescription: 'productDescription',
      productPortrait: 'productPortrait',
      productIcon: 'productIcon',
      loanMonthMin: 'loanMonthMin',
      loanMonthMax: 'loanMonthMax',
      loanMonthSteps: 'loanMonthSteps',
      loanAmountMin: 'loanAmountMin',
      loanAmountMax: 'loanAmountMax',
      loanAmountMaxLimit: 'loanAmountMaxLimit', // true / false
      loanMonthlyRate: 'loanMonthlyRate',
      loanAnualRate: 'loanAnualRate',
      loanReferralMinimum: 'loanReferralMinimum',
      loanPaymentReceipts: 'loanPaymentReceipts',
      borrowingCapacityPercentage: 'borrowingCapacityPercentage',
      borrowingCapacity: 'borrowingCapacity',
      productStatus: 'productStatus' // true / false
    };
  
    // EXPECTED RESPONSE
    const response: any = {
      data: {
        product: 'product' // OBJECT
      }
    }
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async removeProduct(id: string) {
    const url = route_products + '/' + id;
    return await firstValueFrom(this.http.delete(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board'];
  
    // DATA IS SENDED ON URI
  
   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // NO EXPECTED RESPONSE

    // ON DELETE, REPLACE ON CASCADE FOR NULL
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }

  // productCategories
  // ---------------------------------------------------------------------
  // OOPS2: ASSIGNED TO ANDRES / SPRINT OOPS I DID IT AGAIN                                                                    FIX: ASSIGNED TO ANDRES / SPRINT OOPS I DID IT AGAIN
  public async getProductCategories(data: any) {
    const url = route_product_categories + '/list?limit=' + data.limit + '&page=' + data.page + '&productId=' + data.productId + '&productCategory=' + data.productCategory + '&productCategoryStatus=' + data.productCategoryStatus;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent'];

    // DATA IS SENDED ON URI

    // DATA SENDED ON URI
    const sendedURIData: any = {
      limit: 'limit',
      page: 'page',
      productId: 'productId',
      productCategory: 'productCategory',
      productCategoryStatus: 'productCategoryStatus',
    };

    // EXPECTED RESPONSE    
    const response = {
      data: {
        result: [
          {
            productCategory: 'productCategory', // OBJECT
          }
        ],
        pages: 1
      }
    }
    return response;

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OOPS2: ASSIGNED TO ANDRES / SPRINT OOPS I DID IT AGAIN
  public async getProductCategory(id: string) {
    const url = route_product_categories + '/detail/' + id;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent'];

    // DATA IS SENDED ON URI

   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };

    // EXPECTED RESPONSE
    // const response: any = {
    //   data: {
    //     productCategory: 'productCategory' // OBJECT
    //   }
    // }

    // No debería traer loans, ni productInsurances, ni companyProductCategories… esos tendrían su endpoind aparte.

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OOPS2: ASSIGNED TO ANDRES / SPRINT OOPS I DID IT AGAIN
  public async addProductCategory(data: any) {
    const url = route_product_categories + '/';
    return await firstValueFrom(this.http.post(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board'];

    // DATA IS SENDED ON BODY

    // DATA SENDED
    const sendedBodyData: any = {
      productCategory: 'productCategory',
      productCategoryIcon: 'productCategoryIcon',
      productCategoryStatus: 'productCategoryStatus' // true / false
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        productCategory: 'productCategory' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OOPS2: ASSIGNED TO ANDRES / SPRINT OOPS I DID IT AGAIN
  public async updateProductCategory(id: string, data: any) {
    const url = route_product_categories + '/' + id;
    return await firstValueFrom(this.http.put(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board'];
  
    // DATA IS SENDED ON URI AND BODY

    // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // DATA SENDED ON BODY
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      productCategory: 'productCategory',
      productCategoryIcon: 'productCategoryIcon',
      productCategoryStatus: 'productCategoryStatus' // true / false
    };
  
    // EXPECTED RESPONSE
    const response: any = {
      data: {
        productCategory: 'productCategory' // OBJECT
      }
    }
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OOPS2: ASSIGNED TO ANDRES / SPRINT OOPS I DID IT AGAIN
  public async removeProductCategory(id: string) {
    const url = route_product_categories + '/' + id;
    return await firstValueFrom(this.http.delete(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator'];
  
    // DATA IS SENDED ON URI
  
   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // NO EXPECTED RESPONSE

    // ON DELETE, REPLACE ON CASCADE FOR NULL
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }

  // product insurances
  // ---------------------------------------------------------------------
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async getProductInsurances(data: any) {
    const url = route_products + '/product-insurance/list' + '?limit=' + data.limit + '&page=' + data.page + '&productId=' + data.productId + '&loanId=' + data.loanId + '&productInsuranceName=' + data.productInsuranceName + '&productInsuranceStatus=' + data.productInsuranceStatus;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar', 'client'];

    // DATA IS SENDED ON URI

    // DATA SENDED
    // SOME ATTRIBUTES CAN BE null, IN THAT CASE SHOULD NOT BE TAKEN ON THE WHERE AT THE QUERY
    const sendedURIData: any = {
      limit: 'limit',
      page: 'page',
      productId: 'productId',
      productInsuranceName: 'productInsuranceName',
      productInsuranceStatus: 'productInsuranceStatus'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        result: [
          {
            id: 'id',
            productInsuranceName: 'productInsuranceName',
            productInsuranceValue: 'productInsuranceValue',
            productInsuranceStatus: 'productInsuranceStatus'
          }
        ],
        pages: 1
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async getProductInsurance(id: string) {
    const url = route_products + '/product-insurance/detail/' + id;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar', 'client'];

    // DATA IS SENDED ON URI

   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        productInsurance: 'productInsurance' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async addProductInsurance(data: any) {
    const url = route_products + '/product-insurance/';
    return await firstValueFrom(this.http.post(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board'];

    // DATA IS SENDED ON BODY

    // DATA SENDED
    const sendedBodyData: any = {
      productInsuranceName: 'productInsuranceName',
      productInsuranceDescription: 'productInsuranceDescription',
      productInsuranceValue: 'productInsuranceValue',
      productInsuranceStatus: 'productInsuranceStatus' // true / false
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        productInsurance: 'productInsurance' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async updateProductInsurance(id: string, data: any) {
    const url = route_products  + '/product-insurance/' + id;
    return await firstValueFrom(this.http.put(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board'];
  
    // DATA IS SENDED ON URI AND BODY

    // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // DATA SENDED ON BODY
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      productInsuranceName: 'productInsuranceName',
      productInsuranceDescription: 'productInsuranceDescription',
      productInsuranceValue: 'productInsuranceValue',
      productInsuranceStatus: 'productInsuranceStatus' // true / false
    };
  
    // EXPECTED RESPONSE
    const response: any = {
      data: {
        productInsurance: 'productInsurance' // OBJECT
      }
    }
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async removeProductInsurance(id: string) {
    const url = route_products + '/product-insurance/' + id;
    return await firstValueFrom(this.http.delete(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board'];
  
    // DATA IS SENDED ON URI
  
   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // NO EXPECTED RESPONSE

    // ON DELETE, REPLACE ON CASCADE FOR NULL
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.6
  public async linkLoanProductInsurance(data: any) {
    const url = route_loans + '/product-insurance-link/';
    return await firstValueFrom(this.http.post(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent'];

    // DATA IS SENDED ON BODY

    // DATA SENDED
    const sendedBodyData: any = {
      loanId: 'loanId',
      productInsuranceId: 'productInsuranceId',
    };

    // NO EXPECTED RESPONSE

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.6
  public async unlinkLoanProductInsurance(loanId: string, productInsuranceId: string) {
    const url = route_loans + '/product-insurance-unlink/' + loanId + '/' + productInsuranceId;
    return await firstValueFrom(this.http.delete(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent'];

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      companyId: 'companyId',
      productId: 'productId',
    };

    // NO EXPECTED RESPONSE

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }

  // loans
  // ---------------------------------------------------------------------
  // OK: JUAN / SPRINT 3
  public async getPublicLoan(number: string) {
    // set public api
    localStorage.setItem('publicApi', 'true');
    // set url
    const url = route_loans + '/loan/' + number;
    // call api
    const api = await firstValueFrom(this.http.get(url));
    // check for api
    if (api) {
      // remove public api option
      localStorage.removeItem('publicApi');
    }
    // return data
    return api;

    // NO ROLE CHECKING

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      number: 'number'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        loanType: 'fixedInterest',
        loanNumber: 'VMO4K',
        loanCode: '$2b$10$SbfYVBg78eM4MqzpSsqK9e7uhHBzZoeb7KCY9bgncO4Q7kMek.Kw.',
        loanAmount: 9200000,
        loanPaymentMonths: 12,
        loanStatusLast: 'started',
        loanStatusDateLast: '2023-06-07 03:16:06.583055+00',
        productId: 'ad938ad1-5a59-4ea5-88a7-025e759c6adf',
        userWorkId: '4679bc36-2a89-4b0e-a102-c70c302b28dc',
      }
    }
    // return response;

    // RESPONSE RECEIVED
    const responseReceived = {"statusCode":500,"message":"Internal server error"}
    
    // ON THE PUBLIC ENDPOINT IT SHOULDN'T RECEIVE THE LOAN ID BUT THE NUMBER
    // TRIED WITH ID 'd3247dc7-0731-454a-9aad-234c54ad527c' AND WITH NUMBER 'M380W' AND IT RETURNED 500 ERROR

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES
 
  }
  // OK: JUAN / SPRINT 3
  public async addPublicLoan(data: any) {
    // set public api
    localStorage.setItem('publicApi', 'true');
    // set url
    const url = route_loans + '/user-loan/';
    // call api
    const api = await firstValueFrom(this.http.post(url, data));
    // check for api
    if (api) {
      // remove public api option
      localStorage.removeItem('publicApi');
    }
    // return data
    return api;

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON BODY

    // DATA SENDED
    const sendedBodyData: any = {
      userWorkId: 'userWorkId',
      productId: 'productId',
      loanType: 'loanType', // ENUM('fixedInterest', 'variableInterest')
      loanAmount: 'loanAmount',
      loanPaymentMonths: 'loanPaymentMonths',
      loanAmountApproved: 'loanAmountApproved',
      loanPaymentMonthsApproved: 'loanPaymentMonthsApproved',
      loanInterest: 'loanInterest',
      loanAdditionalInfoConsulted: 'loanAdditionalInfoConsulted',
      loanMonthMin: 'loanMonthMin',
      loanMonthMax: 'loanMonthMax',
      loanMonthSteps: 'loanMonthSteps',
      loanAmountMin: 'loanAmountMin',
      loanAmountMax: 'loanAmountMax',
      loanAmountMaxLimit: 'loanAmountMaxLimit', // true / false
      loanMonthlyRate: 'loanMonthlyRate',
      loanAnualRate: 'loanAnualRate',
      loanReferralMinimum: 'loanReferralMinimum',
      loanPaymentReceipts: 'loanPaymentReceipts',
      borrowingCapacityPercentage: 'borrowingCapacityPercentage',
      borrowingCapacity: 'borrowingCapacity',
      loanRequestStep: 'loanRequestStep'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: 'id'
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: JUAN / SPRINT 3
  public async getLoans(data: any) {
    const url = route_loans + '?limit=' + data.limit + '&page=' + data.page + '&companyId=' + data.companyId + '&productId=' + data.productId + '&userId=' + data.userId + '&loanType=' + data.loanType + '&loanNumber=' + data.loanNumber + '&loanCode=' + data.loanCode + '&loanAmount=' + data.loanAmount  + '&loanStatusLast=' + data.loanStatusLast;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar', 'client'];

    // DATA IS SENDED ON URI

    // DATA SENDED
    // SOME ATTRIBUTES CAN BE null, IN THAT CASE SHOULD NOT BE TAKEN ON THE WHERE AT THE QUERY
    const sendedURIData: any = {
      limit: 'limit',
      page: 'page',
      userId: 'userId',
      companyId: 'companyId',
      productId: 'productId',
      loanType: 'loanType',
      loanNumber: 'loanNumber',
      loanCode: 'loanCode',
      loanAmount: 'loanAmount',
      loanStatusLast: 'loanStatusLast'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        result: [
          {
            id: "d3247dc7-0731-454a-9aad-234c54ad527c",
            loanType: "fixedInterest",
            loanNumber: "M380W",
            loanCode: "$2b$10$J7J7SWlg2gWQjllEjSRCueRzJ4G8O05.QjxIDr5ADhNNGk7T7EMfW",
            loanAmount: 10000000,
            loanPaymentMonths: 12,
            loanAmountApproved: 7600000,
            loanPaymentMonthsApproved: 6,
            loanAmountPreApproved: 10000000,
            loanPaymentMonthsPreApproved: 24,
            loanAmountApprovedCompany: 7600000,
            loanPaymentMonthsApprovedCompany: 6,
            loanSinglePayment: 560364, // 1,380,703
            loanInterest: 2.52,
            userWork: {
              id: "32bf67c0-aa0b-4573-83e7-76b2c4bbe990",
              user: {
                id: '',
                avatar: '',
                firstName: '',
                lastName: '',
                email: '',
                userCountryCellphoneCode: '',
                cellphone: ''
              },
              company: {
                id: '',
                companyName: '',
                businessName: '',
                companyLogo: '',
                companyApprovalRequired: true,
              },
              otherCompany: {
                id: '',
                companyName: '',
              }
            },
            productId: "ad938ad1-5a59-4ea5-88a7-025e759c6adf",
            loanValidationScore: 734,
            loanPayedPercentage: 0,
            loanDebtDays: 0,
            loanStatusDateLast: "2023-06-14 02:11:04.424071+00",
            loanStatusLast: "started",
          },
        ],
        pages: 1
      }
    }
    return response;

    // RESPONSE RECEIVED
    const responseReceived = {
      "statusCode": 401,
      "error": true,
      "path": "/loans?limit=10&page=1&userId=null&loanType=null&loanNumber=null&loanCode=null&loanAmount=null&loanStatusLast=null",
      "timestamp": "2023-06-14T02:32:16.601Z",
      "data": "Invalid role"
    }

    // ON SWAGER CAN'T SEE THE OTHER PARAMETERS BEEN RECEIVED ON THE URL
    // BY THE ERROR MSG IT SEEMS SOMETHING RELATED WITH ROLES, CHECK roleAccess ABOVE
    
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: JUAN / SPRINT 3
  public async getLoan(id: string) {
    const url = route_loans + '/loan/' + id;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar', 'client'];

    // DATA IS SENDED ON URI

   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        id: "d3247dc7-0731-454a-9aad-234c54ad527c",
        loanType: "fixedInterest",
        loanNumber: "M380W",
        loanCode: "$2b$10$J7J7SWlg2gWQjllEjSRCueRzJ4G8O05.QjxIDr5ADhNNGk7T7EMfW",
        loanAmount: 10000000,
        loanPaymentMonths: 12,
        loanAmountApproved: 7600000,
        loanPaymentMonthsApproved: 6,
        loanAmountPreApproved: 10000000,
        loanPaymentMonthsPreApproved: 24,
        loanAmountApprovedCompany: 7600000,
        loanPaymentMonthsApprovedCompany: 6,
        loanSinglePayment: 560364, // 1,380,703
        loanInterest: 2.52,
        loanAdditionalInfoConsulted: "False",
        loanMonthMin: 6,
        loanMonthMax: 24,
        loanAmountMin: 500000,
        loanAmountMax: 12,
        loanMonthSteps: 2,
        loanAmountMaxLimit: "False",
        loanMonthlyRate: 0,
        loanAnualRate: 0,
        loanReferralMinimum: 3,
        loanPaymentReceipts: 3,
        borrowingCapacityPercentage: 30,
        borrowingCapacity: 1200000,
        loanRequestStep: 1,
        loanProfileValidation: "False",
        loanSynthesisValidation: "False",
        loanDetailedValidation: "False",
        loanPromissoryLoaded: "False",
        loanManagerSignature: "False",
        userWorkId: "32bf67c0-aa0b-4573-83e7-76b2c4bbe990",
        productId: "ad938ad1-5a59-4ea5-88a7-025e759c6adf",
        loanValidationScore: 734,
        loanPayedPercentage: 0,
        loanDebtDays: 0,
        loanStatusDateLast: "2023-06-14 02:11:04.424071+00",
        loanStatusLast: "started"
      }
    }
    // return response;

    // RESPONSE RECEIVED
    const responseReceived = {"statusCode":500,"message":"Internal server error"}

    // ON THE INTERNAL ENDPOINT IT SHOULDN'T RECEIVE THE LOAN ID BUT IS GIVING A 500 ERROR

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: JUAN / SPRINT 3
  public async getLoanNavigation(id: string) {
    const url = route_loans + '/search-prev-and-next-loan-by-id/search/' + id;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar', 'client'];

    // DATA IS SENDED ON URI

    // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        current: "id",
        previous: "loanNumber",
        next: "loanNumber",
      }
    }
    // return response;

    // ON THE INTERNAL ENDPOINT IT SHOULDN'T RECEIVE THE LOAN ID BUT IS GIVING A 500 ERROR

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async addLoan(data: any) {
    const url = route_loans + '/';
    return await firstValueFrom(this.http.post(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'businessAgent', 'auxiliar', 'client'];

    // DATA IS SENDED ON BODY

    // DATA SENDED
    const sendedBodyData: any = {
      userWorkId: 'userWorkId',
      productId: 'productId',
      loanType: 'loanType', // ENUM('fixedInterest', 'variableInterest')
      loanAmount: 'loanAmount',
      loanPaymentMonths: 'loanPaymentMonths',
      loanAmountApproved: 'loanAmountApproved',
      loanPaymentMonthsApproved: 'loanPaymentMonthsApproved',
      loanInterest: 'loanInterest',
      loanAdditionalInfoConsulted: 'loanAdditionalInfoConsulted',
      loanMonthMin: 'loanMonthMin',
      loanMonthMax: 'loanMonthMax',
      loanMonthSteps: 'loanMonthSteps',
      loanAmountMin: 'loanAmountMin',
      loanAmountMax: 'loanAmountMax',
      loanAmountMaxLimit: 'loanAmountMaxLimit', // true / false
      loanMonthlyRate: 'loanMonthlyRate',
      loanAnualRate: 'loanAnualRate',
      loanReferralMinimum: 'loanReferralMinimum',
      loanPaymentReceipts: 'loanPaymentReceipts',
      borrowingCapacityPercentage: 'borrowingCapacityPercentage',
      borrowingCapacity: 'borrowingCapacity',
      loanRequestStep: 'loanRequestStep'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        loan: 'loan' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: JUAN / SPRINT 3
  public async updateLoan(id: string, data: any) {
    const url = route_loans + '/' + id;
    return await firstValueFrom(this.http.patch(url, data)); // TODO: CHECK IF IS PATCH OR PUT

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON URI AND BODY

    // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // DATA SENDED ON BODY
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      userWorkId: 'userWorkId',
      productId: 'productId',
      loanType: 'loanType', // ENUM('fixedInterest', 'variableInterest')
      loanAmount: 'loanAmount',
      loanPaymentMonths: 'loanPaymentMonths',
      loanAmountApproved: 'loanAmountApproved',
      loanPaymentMonthsApproved: 'loanPaymentMonthsApproved',
      loanSinglePayment: 'loanSinglePayment',
      loanInterest: 'loanInterest',
      loanAdditionalInfoConsulted: 'loanAdditionalInfoConsulted',
      loanMonthMin: 'loanMonthMin',
      loanMonthMax: 'loanMonthMax',
      loanMonthSteps: 'loanMonthSteps',
      loanAmountMin: 'loanAmountMin',
      loanAmountMax: 'loanAmountMax',
      loanAmountMaxLimit: 'loanAmountMaxLimit', // true / false
      loanMonthlyRate: 'loanMonthlyRate',
      loanAnualRate: 'loanAnualRate',
      loanReferralMinimum: 'loanReferralMinimum',
      loanPaymentReceipts: 'loanPaymentReceipts',
      borrowingCapacityPercentage: 'borrowingCapacityPercentage',
      borrowingCapacity: 'borrowingCapacity',
      loanRequestStep: 'loanRequestStep',
      loanStatusLast: 'loanStatusLast',
      loanStatusDateLast: 'loanStatusDateLast',
      loanProfileValidation: 'loanProfileValidation', // true / false
      loanSynthesisValidation: 'loanSynthesisValidation', // true / false
      loanDetailedValidation: 'loanDetailedValidation', // true / false
      loanPromissoryLoaded: 'loanPromissoryLoaded', // true / false
      loanManagerSignature: 'loanManagerSignature', // true / false
      loanPayedPercentage: 'loanPayedPercentage',
    };
  
    // EXPECTED RESPONSE
    const response: any = {
      data: {
        loan: 'loan' // OBJECT
      }
    }

    // EXPECTED RESPONSE
    const simulatedResponse: any = {
      data: {
        id: "d3247dc7-0731-454a-9aad-234c54ad527c",
        loanType: "fixedInterest",
        loanNumber: "M380W",
        loanCode: "$2b$10$J7J7SWlg2gWQjllEjSRCueRzJ4G8O05.QjxIDr5ADhNNGk7T7EMfW",
        loanAmount: 10000000,
        loanPaymentMonths: 12,
        loanAmountApproved: 7600000,
        loanPaymentMonthsApproved: 6,
        loanAmountPreApproved: 10000000,
        loanPaymentMonthsPreApproved: 24,
        loanAmountApprovedCompany: 7600000,
        loanPaymentMonthsApprovedCompany: 6,
        loanSinglePayment: 560364, // 1,380,703
        loanInterest: 2.52,
        loanAdditionalInfoConsulted: "False",
        loanMonthMin: 6,
        loanMonthMax: 24,
        loanAmountMin: 500000,
        loanAmountMax: 12,
        loanMonthSteps: 2,
        loanAmountMaxLimit: "False",
        loanMonthlyRate: 0,
        loanAnualRate: 0,
        loanReferralMinimum: 3,
        loanPaymentReceipts: 3,
        borrowingCapacityPercentage: 30,
        borrowingCapacity: 1200000,
        loanRequestStep: 1,
        loanProfileValidation: "False",
        loanSynthesisValidation: "False",
        loanDetailedValidation: "False",
        loanPromissoryLoaded: "False",
        loanManagerSignature: "False",
        userWorkId: "32bf67c0-aa0b-4573-83e7-76b2c4bbe990",
        productId: "ad938ad1-5a59-4ea5-88a7-025e759c6adf",
        loanValidationScore: 734,
        loanPayedPercentage: 0,
        loanDebtDays: 0,
        loanStatusDateLast: "2023-06-14 02:11:04.424071+00",
        loanStatusLast: "started"
      }
    }
    // return simulatedResponse;
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES
  
  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3
  public async removeLoan(id: string) {
    const url = route_loans + '/' + id;
    return await firstValueFrom(this.http.delete(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board'];
  
    // DATA IS SENDED ON URI
  
   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // NO EXPECTED RESPONSE

    // ON DELETE, REPLACE ON CASCADE FOR NULL
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES
  
  }

  // OK: ANDRES / SPRINT 5
  public async getLoanStatuses(loanId: string) {
    const url = route_loan_statuses + '/' + loanId;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar', 'client'];

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      loanId: 'loanId'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: [
        {
          loanId: 'loanId',
          loanStatus: 'loanStatus',
          user: {
            id: 'id',
            avatar: 'avatar', // TOCHECK: AGREGAR ESTE ATRIBUTO. PERDÓN, SE ME PASÓ AGREGAR ESTE ATRIBUTO
            firstName: 'firstName',
            lastName: 'lastName',
            email: 'email',
            cellphoneCountryCode: 'cellphoneCountryCode',
            cellphoneCountryCodeCod: 'country.numericCode', // TOCHECK: FALTÓ ESTE ATRIBUTO
            cellphone: 'cellphone',
          },
          loanNoPassedValidationRejectOption: 'loanNoPassedValidationRejectOption',
          loanStatusObservations: 'loanStatusObservations',
          createdAt: 'createdAt',
          updatedAt: 'updatedAt'
        },
      ],
    }
    // return response;

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ANDRES / SPRINT 5
  public async addLoanStatus(data: any) {
    const url = route_loan_statuses + '/';
    return await firstValueFrom(this.http.post(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'businessAgent', 'auxiliar', 'client'];

    // DATA IS SENDED ON BODY

    // DATA SENDED
    const sendedBodyData: any = {
      loanId: 'loanId',
      loanStatus: 'loanStatus', // ENUM('started', 'sended', 'accepted', 'validated', 'noPassedValidation', 'preApproved', 'approved', 'rejected', 'readyToApprove', 'readyForDisbursement', 'disbursmentOnProgress', 'disbursed', 'payed', 'preLegal', 'legal', 'negotiation', 'expired')
      user: {
        id: 'id',
        firstName: 'firstName',
        lastName: 'lastName',
        email: 'email',
        cellphoneCountryCode: 'cellphoneCountryCode',
        cellphoneCountryCodeCod: 'country.numericCode',
        cellphone: 'cellphone',
      },
      loanNoPassedValidationRejectOption: 'loanNoPassedValidationRejectOption',
      loanStatusObservations: 'loanStatusObservations',
      createdAt: 'createdAt',
      updatedAt: 'updatedAt'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        loanStatus: 'loanStatus' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ANDRES / SPRINT 5
  public async updateLoanStatus(id: string, data: any) {
    const url = route_loans + '/' + id;
    return await firstValueFrom(this.http.patch(url, data)); // TODO: CHECK IF IS PATCH OR PUT

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON URI AND BODY

    // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // DATA SENDED ON BODY
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      loanId: 'loanId',
      loanStatus: 'loanStatus', // ENUM('started', 'sended', 'accepted', 'validated', 'noPassedValidation', 'preApproved', 'approved', 'rejected', 'readyToApprove', 'readyForDisbursement', 'disbursmentOnProgress', 'disbursed', 'payed', 'preLegal', 'legal', 'negotiation', 'expired')
      user: {
        id: 'id',
        firstName: 'firstName',
        lastName: 'lastName',
        email: 'email',
        cellphoneCountryCode: 'cellphoneCountryCode',
        cellphoneCountryCodeCod: 'country.numericCode',
        cellphone: 'cellphone',
      },
      loanNoPassedValidationRejectOption: 'loanNoPassedValidationRejectOption',
      loanStatusObservations: 'loanStatusObservations',
      createdAt: 'createdAt',
      updatedAt: 'updatedAt'
    };
  
    // EXPECTED RESPONSE
    const response: any = {
      data: {
        loan: 'loan' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES
  
  }
  // OK: ANDRES / SPRINT 5
  public async removeLoanStatus(id: string) {
    const url = route_loan_statuses + '/' + id;
    return await firstValueFrom(this.http.delete(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board'];
  
    // DATA IS SENDED ON URI
  
   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // NO EXPECTED RESPONSE

    // ON DELETE, REPLACE ON CASCADE FOR NULL
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES
  
  }

  // emails
  // ---------------------------------------------------------------------
  // route_auth + '/send-code/' + email + '/' + typeSend; // EMAILS: code-confirmation / code-forgot
  // route_loans + '/send-first-steps/' + loanId; // EMAILS: new-request-user / new-request-company
  // route_loans + '/send-no-pass-validation-msg'; // EMAILS: loan-no-passed-validation
  // route_loans + '/send-rejection-msg'; // EMAILS: loan-rejected
  // route_loans + '/send-company-promissory-and-authorization'; // EMAILS: loan-company-pre-approved-user
  // route_loans + '/send-confirmation'; // EMAILS: loan-confirmation-ceo / loan-confirmation-manager
  // route_loans + '/send-company-approved'; // EMAILS: loan-company-approved-user / loan-company-approved-ceo-manager
  // route_loans + '/send-promissory'; // EMAILS: loan-approved-user-no-company
  // route_loans + '/send-company-disbursed'; // EMAILS: loan-disbursed-user / loan-disbursed-ceo-manager
  // route_loans + '/send-disbursed'; // EMAILS: loan-disbursed-user

  // TODO: IF MANAGER IS NULL. NOT SEND EMAIL

  public async sendCode(email: string, typeSend: string) {

    // EMAILS: code-confirmation / code-forgot

    const url = route_auth + '/send-code/' + email + '/' + typeSend;
    return await firstValueFrom(this.http.post(url, {}));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      email: 'email',
      typeSend: 'typeSend' // typeSend options: "forgot", "register"
    };

    // IF THE TYPESEND IS REGISTER THEN SEND "code-confirmation" EMAIL TEMPLATE 
    // AND IF TYPESEND IS FORGOT THEN SEND "code-forgot" EMAIL TEMPLATE

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        accessToken: 'accessToken'
      }
    }

    // ON FORGOT TYPE ON THE EMAIL THERE IS AN ERROR ON THE CODE
    // Código de Recuperación
    // {code}

    // ON REGISTER TYPE ON THE EMAIL THERE IS AN ERROR ON THE CODE
    // Confirmación de Cuenta
    // Bienvenido Alejandro
    // Hemos recibido una solicitud para crear tu cuenta.

    // Para ello deberás ingresar el código que aparece abajo para confirmar tu correo electrónico.

    // null

  }
  public async sendFirstSteps(data: any) {

    // EMAILS: new-request-user / new-request-company

    const url = route_loans + '/send-first-steps/';
    return await firstValueFrom(this.http.post(url, data));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      loanId: 'loanId'
    };

    // URL: https://api.credipremium.co/loans/send-first-steps/01bea3e3-b561-4d18-b477-2f548646179b

    // RESPONSE
    // {
    //   "statusCode": 401,
    //   "error": true,
    //   "path": "/loans/send-first-steps/01bea3e3-b561-4d18-b477-2f548646179b",
    //   "timestamp": "2023-08-03T19:04:41.617Z",
    //   "data": "Invalid role"
    // }


    // NO EXPECTED RESPONSE
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  public async sendNoPassValidationMsg(data: any) {

    // EMAILS: loan-no-passed-validation

    const url = route_loans + '/send-no-pass-validation-msg';
    return await firstValueFrom(this.http.post(url, data));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      loanId: 'loanId'
    };

    // SENDS THE PROMISSORY AND LOAN INSTRUCTIONS PDF ON THE EMAIL

    // NO EXPECTED RESPONSE
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  public async sendRejectionMsg(data: any) {

    // EMAILS: loan-rejected
    
    const url = route_loans + '/send-rejection-msg';
    return await firstValueFrom(this.http.post(url, data));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      loanId: 'loanId'
    };

    // SENDS THE PROMISSORY AND LOAN INSTRUCTIONS PDF ON THE EMAIL

    // NO EXPECTED RESPONSE
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  public async sendCompanyPromissoryAndAuthorization(data: any) {

    // EMAIL loan-company-pre-approved-user

    // set url
    const url = route_loans + '/send-company-promissory-and-authorization';
    return await firstValueFrom(this.http.post(url, data));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      loanId: 'loanId'
    };

    // SENDS THE PROMISSORY AND LOAN INSTRUCTIONS PDF ON THE EMAIL

    // NO EXPECTED RESPONSE
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  public async sendConfirmation(data: any) {

    // EMAIL loan-confirmation-ceo / loan-confirmation-manager

    const url = route_loans + '/send-confirmation';
    return await firstValueFrom(this.http.post(url, data));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      loanId: 'loanId'
    };

    // SENDS THE PROMISSORY AND LOAN INSTRUCTIONS PDF ON THE EMAIL

    // NO EXPECTED RESPONSE
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  public async sendCompanyApproved(data: any) {

    // EMAIL loan-company-approved-user / loan-company-approved-ceo-manager
    
    const url = route_loans + '/send-company-approved';
    return await firstValueFrom(this.http.post(url, data));

    // return true;

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      loanId: 'loanId'
    };

    // SENDS THE PROMISSORY AND LOAN INSTRUCTIONS PDF ON THE EMAIL

    // NO EXPECTED RESPONSE
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  public async sendPromissory(data: any) {

    // EMAIL loan-approved-user-no-company

    // set url
    const url = route_loans + '/send-promissory';
    return await firstValueFrom(this.http.post(url, data));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      loanId: 'loanId'
    };

    // SENDS THE PROMISSORY AND LOAN INSTRUCTIONS PDF ON THE EMAIL

    // NO EXPECTED RESPONSE
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  public async sendCompanyDisbursed(data: any) {

    // EMAIL loan-disbursed-user / loan-disbursed-ceo-manager
    
    const url = route_loans + '/send-company-disbursed';
    return await firstValueFrom(this.http.post(url, data));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      loanId: 'loanId'
    };

    // SENDS THE PROMISSORY AND LOAN INSTRUCTIONS PDF ON THE EMAIL

    // NO EXPECTED RESPONSE
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  public async sendDisbursed(data: any) {

    // EMAIL loan-disbursed-user
    
    const url = route_loans + '/send-disbursed';
    return await firstValueFrom(this.http.post(url, data));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      loanId: 'loanId'
    };

    // SENDS THE PROMISSORY AND LOAN INSTRUCTIONS PDF ON THE EMAIL

    // NO EXPECTED RESPONSE
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }

  // NEW: ANDRES / SPRINT 3.4
  public async generateUserPromissory(data: any) {
    const url = route_loans + '/generate-user-promissory';
    return await firstValueFrom(this.http.post(url, data));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      loanId: 'loanId'
    };

    // CREATES THE PROMISSORY FOR THE LOAN ON PDF

    // NO EXPECTED RESPONSE
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // NEW: ANDRES / SPRINT 3.4
  public async generateUserAuthorization(data: any) {
    const url = route_loans + '/generate-user-authorization';
    return await firstValueFrom(this.http.post(url, data));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      loanId: 'loanId'
    };

    // CREATES THE INSTRUCTIONS FOR THE LOAN ON PDF

    // NO EXPECTED RESPONSE
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // NEW: ANDRES / SPRINT 3.4
  public async generateUserInstructions(data: any) {
    const url = route_loans + '/generate-user-instructions';
    return await firstValueFrom(this.http.post(url, data));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      loanId: 'loanId'
    };

    // CREATES THE INSTRUCTIONS FOR THE LOAN ON PDF

    // NO EXPECTED RESPONSE
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // NEW: ANDRES / SPRINT 3.4
  public async generateCompanyPromissory(data: any) {
    const url = route_loans + '/generate-company-promissory';
    return await firstValueFrom(this.http.post(url, data));

    // ALL LOGGED IN ROLES

    // DATA IS SENDED ON URI

    // DATA SENDED
    const sendedURIData: any = {
      loanId: 'loanId'
    };

    // CREATES THE COMPANY PROMISSORY FOR THE LOAN ON PDF

    // NO EXPECTED RESPONSE
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }

  // loan user financials
  // ---------------------------------------------------------------------
  // OK: JUAN - CORRECTED BY ANDRES / SPRINT 3.2
  public async getLoanUserFinancials(loanId: string) {
    const url = route_loans + '/user-financials/' + loanId;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar', 'client'];

    // DATA IS SENDED ON URI

    // DATA SENDED ON URI
    const sendedURIData: any = {
      loanId: 'loanId'
    };

    // EXPECTED RESPONSE    
    // const response = {
    //   data: {
    //     result: [
    //       {
    //         userFinancial: 'userFinancial', // OBJECT
    //       }
    //     ],
    //     pages: 1
    //   }
    // }

    // EXPECTED RESPONSE    
    const response = {
      data: {
        result: [
          {
            id: '5cd3731e-b58f-41aa-a084-dfe721f3d0bc',
            professionalFees: 1600000,
            otherIncome: 1300000,
            otherIncomeDescription: 'Venta de Productos',
            rent: 1200000,
            familyExpenses: 1300000,
            credits: 950000,
            otherExpenses: 1300000,
            otherExpensesDescription: 'Vacaciones',
            publicResourcesHandling: false,
            otherAssets: 88000000,
            otherAssetsDescription: 'Vehículo',
            otherLiabilities: 10000000,
            otherLiabilitiesDescription: 'Equipos de Oficina',
            originFunds: 'Ventas y Servicios Profesionales',
            userFinancialActive: true
          }
        ],
        pages: 1
      }
    }
    // return response;

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ANDRES / SPRINT 3.2
  public async linkLoanUserFinancial(data: any) {
    const url = route_loans + '/link-financial/';
    return await firstValueFrom(this.http.post(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar', 'client'];

    // DATA IS SENDED ON BODY

    // DATA SENDED
    const sendedBodyData: any = {
      loanId: 'loanId',
      userFinancialId: 'userFinancialId',
    };

    // NO EXPECTED RESPONSE

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async unlinkLoanUserFinancial(loanId: string, userFinancialId: string) {
    const url = route_loans + '/unlink-financial/delete/' + loanId + '/' + userFinancialId;
    return await firstValueFrom(this.http.delete(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent'];

    // DATA IS SENDED ON BODY

    // DATA SENDED
    const sendedBodyData: any = {
      loanId: 'loanId',
      userFinancialId: 'userFinancialId',
    };

    // NO EXPECTED RESPONSE

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }

  // loan user referrals
  // ---------------------------------------------------------------------
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async getLoanUserReferrals(loanId: string) {
    const url = route_loans + '/user-referrals/' + loanId;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar', 'client'];

    // DATA IS SENDED ON URI

    // DATA SENDED ON URI
    const sendedURIData: any = {
      loanId: 'loanId'
    };

    // EXPECTED RESPONSE    
    const response = {
      data: {
        result: [
          {
            userReferral: 'userReferral', // OBJECT
          }
        ],
        pages: 1
      }
    }
    return response;

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async linkLoanUserReferral(data: any) {
    const url = route_loans + '/link-referral/';
    return await firstValueFrom(this.http.post(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar', 'client'];

    // DATA IS SENDED ON BODY

    // DATA SENDED
    const sendedBodyData: any = {
      loanId: 'loanId',
      userReferralId: 'userReferralId',
    };

    // NO EXPECTED RESPONSE

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async unlinkLoanUserReferral(loanId: string, userReferralId: string) {
    const url = route_loans + '/unlink-financial/delete/' + loanId + '/' + userReferralId;
    return await firstValueFrom(this.http.delete(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent'];

    // DATA IS SENDED ON BODY

    // DATA SENDED
    const sendedBodyData: any = {
      loanId: 'loanId',
      userReferralId: 'userReferralId',
    };

    // NO EXPECTED RESPONSE

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }

  // loan documents
  // ---------------------------------------------------------------------
  // OK: ANDRES - JUAN FIXED / SPRINT 3.2
  public async getLoanDocuments(data: any) {
    const url = route_loans + '/documents/' + '?limit=' + data.limit + '&page=' + data.page + '&loanId=' + data.loanId + '&loanDocumentType=' + data.loanDocumentType;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar', 'client'];

    // DATA IS SENDED ON URI

    // DATA SENDED
    // SOME ATTRIBUTES CAN BE null, IN THAT CASE SHOULD NOT BE TAKEN ON THE WHERE AT THE QUERY
    const sendedURIData: any = {
      limit: 'limit',
      page: 'page',
      loanId: 'loanId',
      loanDocumentType: 'loanDocumentType'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        result: [
          {
            loanDocument: 'loanDocument' // OBJECT
          }
        ],
        pages: 1
      }
    }

    // SIMULATED RESPONSE
    const simulatedResponse: any = {
      data: {
        result: [
          {
            id: '01ce3817-66b8-4dcc-92d6-8f274d484e46',
            loanId: '33f8aa82-237b-480b-bfa4-edbaa825f11a',
            loanDocumentType: 'bank',
            loanDocument: 'https://credipremium.s3.us-east-1.amazonaws.com/2a116848-a427-43d2-80ba-0f239396f3f4.pdf',
            fileKey: '2a116848-a427-43d2-80ba-0f239396f3f4.pdf',
          }
        ],
        pages: 1
      }
    }
    // return simulatedResponse;

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async getLoanDocument(id: string) {
    const url = route_loans + '/documents/' + id;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar', 'client'];

    // DATA IS SENDED ON URI

   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        loanDocument: 'loanDocument' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ANDRES - REASIGNATED TO JUAN / SPRINT 3.2
  public async addLoanDocument(data: any) {
    const url = route_loans + '/documents/';
    return await firstValueFrom(this.http.post(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar', 'client'];

    // DATA IS SENDED ON BODY

    // DATA SENDED
    const sendedBodyData: any = {
      loanId: 'loanId',
      loanDocumentType: 'loanDocumentType', // ENUM('request', 'bank', 'labor', 'paymentReceipts', 'promissory', 'acceptance', 'profile', 'synthesis', 'detailed')
      loanDocument: 'loanDocument',
      fileKey: 'fileKey'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        loanDocument: 'loanDocument' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: JUAN / SPRINT 3.2
  public async updateLoanDocument(id: string, data: any) {
    const url = route_loans  + '/documents/' + id;
    return await firstValueFrom(this.http.patch(url, data)); // TODO: CHECK IF IS PATCH OR PUT

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar', 'client'];
  
    // DATA IS SENDED ON URI AND BODY

    // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // DATA SENDED ON BODY
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      loanDocumentType: 'loanDocumentType', // ENUM('request', 'bank', 'labor', 'paymentReceipts', 'promissory', 'acceptance', 'profile', 'synthesis', 'detailed')
      loanDocument: 'loanDocument'
    };

    // FIND THE PREVIOUSLY SAVED FILE NAME ON THE RECORD
    // IF PREVIOUS FILE NAME IS DIFFERENT THAN THE NEW NAME, FIND PREVIOUS FILE AND DELETE THE FILE
    // THEN REPLACE THE FILENAME ON THE RECORD WITH THE NEW FILE NAME
  
    // EXPECTED RESPONSE
    const response: any = {
      data: {
        loanDocument: 'loanDocument' // OBJECT
      }
    }
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: JUAN / SPRINT 3.2
  public async removeLoanDocument(id: string) {
    const url = route_loans + '/documents/' + id;
    return await firstValueFrom(this.http.delete(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON URI
  
   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // NO EXPECTED RESPONSE

    // DELETE THE FILE
    // ON DELETE, REPLACE ON CASCADE FOR NULL 
    
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }

  // loan payments
  // ---------------------------------------------------------------------
  // OK: ANDRES / SPRINT 3.2
  public async getLoanPayments(data: any) {
    const url = route_loans + '/payments' + '?companyId=' + data.companyId + '&batchId=' + data.batchId + '&loanNumber=' + data.loanNumber + '&userId=' + data.userId + '&loanPaymentNumber=' + data.loanPaymentNumber + '&loanPaymentAmount=' + data.loanPaymentAmount + '&loanPaymentStatuses=' + data.loanPaymentStatuses + '&loanPaymentMonth=' + data.loanPaymentMonth;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar', 'client'];

    // DATA IS SENDED ON URI

    // DATA SENDED
    // SOME ATTRIBUTES CAN BE null, IN THAT CASE SHOULD NOT BE TAKEN ON THE WHERE AT THE QUERY
    const sendedURIData: any = {
      companyId: 'companyId',
      batchId: 'batchId',
      loanNumber: 'loanNumber',
      userId: 'userId',
      loanPaymentNumber: 'loanPaymentNumber',
      loanPaymentAmount: 'loanPaymentAmount',
      loanPaymentStatuses: 'loanPaymentStatuses',
      loanPaymentMonth: '02/08/2023',
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        result: [
          {
            id: 'id',
            loanId: 'loanId',
            loanNumber: 'loanNumber',
            loanPaymentAmount: 'loanPaymentAmount',
            loanPaymentNumber: 'loanPaymentNumber',
            loanPaymentStart: 'loanPaymentStart',
            loanPaymentEnd: 'loanPaymentEnd',
            loanPaymentInterest: 'loanPaymentInterest',
            loanPaymentDebt: 'loanPaymentDebt',
            loanPaymentOverduedDays: 'loanPaymentOverduedDays',
            loanPaymentDueDate: 'loanPaymentDueDate',
            loanPaymentStatusLast: 'loanPaymentStatusLast',
            loanPaymentStatusDateLast: 'loanPaymentStatusDateLast',
            user: {
              id: 'id',
              avatar: 'avatar',
              firstName: 'firstName',
              lastName: 'lastName',
              email: 'email',
              documentNumber: 'documentNumber',
              userCountryPhoneCode: 'userCountryPhoneCode',
              cellphone: 'cellphone',
            }
          }
        ],
        pages: 1
      }
    }


    // SIMULATED RESPONSE
    const simulatedResponse: any = {
      data: {
        result: [
          {
              "loanId": "33f8aa82-237b-480b-bfa4-edbaa825f11a",
              "loanPaymentNumber": 1,
              "loanPaymentStart": 8000000,
              "loanPaymentAmount": 780841.7448828904,
              "loanPaymentInterest": 201600,
              "loanPaymentDebt": 579241.7448828904,
              "loanPaymentEnd": 7420758.25511711,
              "loanPaymentStatusLast": "pending",
              "loanPaymentStatusDateLast": "2023-06-28T19:21:48.855Z",
              "loanPaymentOverduedDays": 0,
              "id": " loanPaymentId-0"
          },
          {
              "loanId": "33f8aa82-237b-480b-bfa4-edbaa825f11a",
              "loanPaymentNumber": 2,
              "loanPaymentStart": 7420758.25511711,
              "loanPaymentAmount": 780841.7448828904,
              "loanPaymentInterest": 187003.10802895116,
              "loanPaymentDebt": 593838.6368539392,
              "loanPaymentEnd": 6826919.618263171,
              "loanPaymentStatusLast": "pending",
              "loanPaymentStatusDateLast": "2023-06-28T19:21:48.855Z",
              "loanPaymentOverduedDays": 0,
              "id": " loanPaymentId-1"
          },
          {
              "loanId": "33f8aa82-237b-480b-bfa4-edbaa825f11a",
              "loanPaymentNumber": 3,
              "loanPaymentStart": 6826919.618263171,
              "loanPaymentAmount": 780841.7448828904,
              "loanPaymentInterest": 172038.37438023192,
              "loanPaymentDebt": 608803.3705026584,
              "loanPaymentEnd": 6218116.247760513,
              "loanPaymentStatusLast": "pending",
              "loanPaymentStatusDateLast": "2023-06-28T19:21:48.855Z",
              "loanPaymentOverduedDays": 0,
              "id": " loanPaymentId-2"
          },
          {
              "loanId": "33f8aa82-237b-480b-bfa4-edbaa825f11a",
              "loanPaymentNumber": 4,
              "loanPaymentStart": 6218116.247760513,
              "loanPaymentAmount": 780841.7448828904,
              "loanPaymentInterest": 156696.5294435649,
              "loanPaymentDebt": 624145.2154393254,
              "loanPaymentEnd": 5593971.032321188,
              "loanPaymentStatusLast": "pending",
              "loanPaymentStatusDateLast": "2023-06-28T19:21:48.855Z",
              "loanPaymentOverduedDays": 0,
              "id": " loanPaymentId-3"
          },
          {
              "loanId": "33f8aa82-237b-480b-bfa4-edbaa825f11a",
              "loanPaymentNumber": 5,
              "loanPaymentStart": 5593971.032321188,
              "loanPaymentAmount": 780841.7448828904,
              "loanPaymentInterest": 140968.07001449392,
              "loanPaymentDebt": 639873.6748683965,
              "loanPaymentEnd": 4954097.357452791,
              "loanPaymentStatusLast": "pending",
              "loanPaymentStatusDateLast": "2023-06-28T19:21:48.855Z",
              "loanPaymentOverduedDays": 0,
              "id": " loanPaymentId-4"
          },
          {
              "loanId": "33f8aa82-237b-480b-bfa4-edbaa825f11a",
              "loanPaymentNumber": 6,
              "loanPaymentStart": 4954097.357452791,
              "loanPaymentAmount": 780841.7448828904,
              "loanPaymentInterest": 124843.25340781033,
              "loanPaymentDebt": 655998.49147508,
              "loanPaymentEnd": 4298098.865977711,
              "loanPaymentStatusLast": "pending",
              "loanPaymentStatusDateLast": "2023-06-28T19:21:48.855Z",
              "loanPaymentOverduedDays": 0,
              "id": " loanPaymentId-5"
          },
          {
              "loanId": "33f8aa82-237b-480b-bfa4-edbaa825f11a",
              "loanPaymentNumber": 7,
              "loanPaymentStart": 4298098.865977711,
              "loanPaymentAmount": 780841.7448828904,
              "loanPaymentInterest": 108312.09142263832,
              "loanPaymentDebt": 672529.6534602521,
              "loanPaymentEnd": 3625569.212517459,
              "loanPaymentStatusLast": "pending",
              "loanPaymentStatusDateLast": "2023-06-28T19:21:48.855Z",
              "loanPaymentOverduedDays": 0,
              "id": " loanPaymentId-6"
          },
          {
              "loanId": "33f8aa82-237b-480b-bfa4-edbaa825f11a",
              "loanPaymentNumber": 8,
              "loanPaymentStart": 3625569.212517459,
              "loanPaymentAmount": 780841.7448828904,
              "loanPaymentInterest": 91364.34415543996,
              "loanPaymentDebt": 689477.4007274504,
              "loanPaymentEnd": 2936091.8117900086,
              "loanPaymentStatusLast": "pending",
              "loanPaymentStatusDateLast": "2023-06-28T19:21:48.855Z",
              "loanPaymentOverduedDays": 0,
              "id": " loanPaymentId-7"
          },
          {
              "loanId": "33f8aa82-237b-480b-bfa4-edbaa825f11a",
              "loanPaymentNumber": 9,
              "loanPaymentStart": 2936091.8117900086,
              "loanPaymentAmount": 780841.7448828904,
              "loanPaymentInterest": 73989.51365710821,
              "loanPaymentDebt": 706852.2312257822,
              "loanPaymentEnd": 2229239.5805642265,
              "loanPaymentStatusLast": "pending",
              "loanPaymentStatusDateLast": "2023-06-28T19:21:48.855Z",
              "loanPaymentOverduedDays": 0,
              "id": " loanPaymentId-8"
          },
          {
              "loanId": "33f8aa82-237b-480b-bfa4-edbaa825f11a",
              "loanPaymentNumber": 10,
              "loanPaymentStart": 2229239.5805642265,
              "loanPaymentAmount": 780841.7448828904,
              "loanPaymentInterest": 56176.83743021851,
              "loanPaymentDebt": 724664.9074526719,
              "loanPaymentEnd": 1504574.6731115547,
              "loanPaymentStatusLast": "pending",
              "loanPaymentStatusDateLast": "2023-06-28T19:21:48.855Z",
              "loanPaymentOverduedDays": 0,
              "id": " loanPaymentId-9"
          },
          {
              "loanId": "33f8aa82-237b-480b-bfa4-edbaa825f11a",
              "loanPaymentNumber": 11,
              "loanPaymentStart": 1504574.6731115547,
              "loanPaymentAmount": 780841.7448828904,
              "loanPaymentInterest": 37915.28176241118,
              "loanPaymentDebt": 742926.4631204791,
              "loanPaymentEnd": 761648.2099910756,
              "loanPaymentStatusLast": "pending",
              "loanPaymentStatusDateLast": "2023-06-28T19:21:48.855Z",
              "loanPaymentOverduedDays": 0,
              "id": " loanPaymentId-10"
          },
          {
              "loanId": "33f8aa82-237b-480b-bfa4-edbaa825f11a",
              "loanPaymentNumber": 12,
              "loanPaymentStart": 761648.2099910756,
              "loanPaymentAmount": 780841.7448828904,
              "loanPaymentInterest": 19193.534891775103,
              "loanPaymentDebt": 761648.2099911153,
              "loanPaymentEnd": -3.969762474298477e-8,
              "loanPaymentStatusLast": "pending",
              "loanPaymentStatusDateLast": "2023-06-28T19:21:48.855Z",
              "loanPaymentOverduedDays": 0,
              "id": " loanPaymentId-11"
          }
        ],
        pages: 1
      }
    }
    // return simulatedResponse;

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // ASSIGNED TO ANDRES / SPRINT 3.2
  public async getLoanPayment(id: string) {
    const url = route_loans + '/payments/' + id;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar', 'client'];

    // DATA IS SENDED ON URI

   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        loanPayment: 'loanPayment' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ANDRES / SPRINT 3.2
  public async addLoanPayment(data: any) {
    const url = route_loans + '/payments/';
    return await firstValueFrom(this.http.post(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar', 'client'];

    // DATA IS SENDED ON BODY

    // DATA SENDED
    const sendedBodyData: any = {
      loanId: 'loanId',
      loanPaymentNumber: 'loanPaymentNumber',
      loanPaymentAmount: 'loanPaymentAmount',
      loanPaymentInterest: 'loanPaymentInterest',
      loanPaymentDebt: 'loanPaymentDebt',
      loanPaymentOverduedDays: 'loanPaymentOverduedDays',
      loanPaymentStatusLast: 'loanPaymentStatusLast',
      loanPaymentStatusDateLast: 'loanPaymentStatusDateLast',
      loanPaymentStart: 'loanPaymentStart',
      loanPaymentEnd: 'loanPaymentEnd',
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        loanPayment: 'loanPayment' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ANDRES / SPRINT 3.2
  public async updateLoanPayment(id: string, data: any) {
    const url = route_loans  + '/payments/' + id;
    return await firstValueFrom(this.http.put(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar', 'client'];
  
    // DATA IS SENDED ON URI AND BODY

    // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // DATA SENDED ON BODY
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      loanPaymentNumber: 'loanPaymentNumber',
      loanPaymentStart: 'loanPaymentStart',
      loanPaymentEnd: 'loanPaymentEnd',
      loanPaymentAmount: 'loanPaymentAmount',
      loanPaymentInterest: 'loanPaymentInterest',
      loanPaymentDebt: 'loanPaymentDebt',
      loanPaymentOverduedDays: 'loanPaymentOverduedDays',
      loanPaymentDueDate: 'loanPaymentDueDate',
      loanPaymentStatusLast: 'loanPaymentStatusLast',
      loanPaymentStatusDateLast: 'loanPaymentStatusDateLast'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        loanPayment: 'loanPayment' // OBJECT
      }
    }
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async removeLoanPayment(id: string) {
    const url = route_loans + '/payments/' + id;
    return await firstValueFrom(this.http.delete(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar', 'client'];

    // DATA IS SENDED ON URI
  
   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // NO EXPECTED RESPONSE

    // ON DELETE, REPLACE ON CASCADE FOR NULL 
    
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }

  // loan payment statuses
  // ---------------------------------------------------------------------
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async getLoanPaymentStatuses(data: any) {
    const url = route_loans + '/payment-status/' + '?limit=' + data.limit + '&page=' + data.page + '&loanPaymentId=' + data.loanPaymentId;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar', 'client'];

    // DATA IS SENDED ON URI

    // DATA SENDED
    // SOME ATTRIBUTES CAN BE null, IN THAT CASE SHOULD NOT BE TAKEN ON THE WHERE AT THE QUERY
    const sendedURIData: any = {
      limit: 'limit',
      page: 'page',
      loanPaymentId: 'loanPaymentId'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        result: [
          {
            loanPaymentStatus: 'loanPaymentStatus' // OBJECT
          }
        ],
        pages: 1
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async getLoanPaymentStatus(id: string) {
    const url = route_loans + '/payment-status/' + id;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar', 'client'];

    // DATA IS SENDED ON URI

   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        loanPaymentStatus: 'loanPaymentStatus' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async addLoanPaymentStatus(data: any) {
    const url = route_loans + '/payment-status/';
    return await firstValueFrom(this.http.post(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar', 'client'];

    // DATA IS SENDED ON BODY

    // DATA SENDED
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      loanPaymentId: 'loanPaymentId',
      loanPaymentStatus: 'loanPaymentStatus', // ENUM('pending', 'payed', 'overdued')
      loanPaymentStatusObservation: 'loanPaymentStatusObservation'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        loanPaymentStatus: 'loanPaymentStatus' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.2
  public async updateLoanPaymentStatus(id: string, data: any) {
    const url = route_loans  + '/payment-status/' + id;
    return await firstValueFrom(this.http.put(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar', 'client'];

    // DATA IS SENDED ON URI AND BODY

    // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // DATA SENDED ON BODY
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      loanPaymentStatus: 'loanPaymentStatus', // ENUM('pending', 'payed', 'overdued')
      loanPaymentStatusObservation: 'loanPaymentStatusObservation'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        loanPaymentStatus: 'loanPaymentStatus' // OBJECT
      }
    }
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK:  ASSIGNED TO ANDRES / SPRINT 3.2
  public async removeLoanPaymentStatus(id: string) {
    const url = route_loans + '/payment-status/' + id;
    return await firstValueFrom(this.http.delete(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar', 'client'];

    // DATA IS SENDED ON URI
  
   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // NO EXPECTED RESPONSE

    // ON DELETE, REPLACE ON CASCADE FOR NULL 
    
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }

  // batches
  // ---------------------------------------------------------------------
  // OK: ASSIGNED TO ANDRES / SPRINT 4
  public async getBatches(data: any) {
    // const url = route_batches + '?limit=' + data.limit + '&page=' + data.page + '&batchType=' + data.batchType + '&batchNumber=' + data.batchNumber + '&batchCode=' + data.batchCode + '&batchStatusLast=' + data.batchStatus;
    const url = route_batches + '?limit=' + data.limit + '&page=' + data.page + '&batchType=' + data.batchType + '&batchNumber=' + data.batchNumber + '&batchCode=' + data.batchCode + '&batchStatusLast=' + data.batchStatus + '&loanId=' + data.loanId;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON URI

    // DATA SENDED
    // SOME ATTRIBUTES CAN BE null, IN THAT CASE SHOULD NOT BE TAKEN ON THE WHERE AT THE QUERY
    const sendedURIData: any = {
      limit: 'limit',
      page: 'page',
      batchType: 'batchType',
      batchNumber: 'batchNumber',
      batchCode: 'batchCode',
      batchStatus: 'batchStatus',
      loanId: 'loanId', // TOCHECK: CHECK THIS SEARCH ATTRIBUTE 
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        result: [
          {
            id: 'id',
            companyId: 'companyId',
            batchType: 'batchType',

            batchNumber: 'batchNumber',
            batchCode: 'batchCode',
            batchValue: 'batchValue',
            batchStatusLast: 'batchStatusLast',
            batchStatusDateLast: 'batchStatusDateLast',
            batchObservations: 'batchObservations',
            batchCount: 'IF BATCH TYPE === "disbursement" THEN COUNT ALL LOANS ASSOCIATED TO THIS BATCH OR IF BATCH TYPE === "payment" THEN COUNT ALL LOAN PAYMENTS ASSOCIATED TO THIS BATCH',
          }
        ],
        pages: 1
      }
    }

    // SIMULATED RESPONSE
    const simulatedResponse: any = {
      data: {
        result: [
          {
            id: 'b905046a-bf1d-4609-9787-23efba774d7d',
            companyId: '7d913f68-e17a-479d-8d5b-db792631db8e',
            batchType: 'disbursement',
            batchNumber: 'A3ED5R97T',
            batchCode: 'b905046a-bf1d-4609-9787-23efba774d7d',
            batchValue: 10000000,
            batchStatusLast: 'created',
            batchStatusDateLast: '2023-07-15 20:19:29.822895+00',
            batchObservations: null,
            batchLoansCount: 1,
            batchLoanPaymentsCount: 0,
          }
        ],
        pages: 1
      }
    }
    // return simulatedResponse;

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 3.5
  public async getBatchLoanPayments(data: any) {
    const url = route_batches + '/payments-ready/' + '?limit=' + data.limit + '&page=' + data.page + '&loanId=' + data.loanId + '&loanPaymentNumber=' + data.loanPaymentNumber + '&loanPaymentAmount=' + data.loanPaymentAmount + '&loanPaymentStatusLast=' + data.loanPaymentStatusLast;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar', 'client'];

    // DATA IS SENDED ON URI

    // DATA SENDED
    // SOME ATTRIBUTES CAN BE null, IN THAT CASE SHOULD NOT BE TAKEN ON THE WHERE AT THE QUERY
    const sendedURIData: any = {
      limit: 'limit',
      page: 'page',
      batchId: 'batchId',
      batchNumber: 'batchNumber',
      batchValue: 'batchValue',
      batchStatusLast: 'batchStatusLast'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        result: [
          {
            loanPayment: 'loanPayment' // OBJECT WITH USERID AND COMPANYID
          }
        ],
        pages: 1
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 4
  public async getBatch(id: string) {
    const url = route_batches + '/' + id;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON URI

    // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        batch: 'batch', // OBJECT
      }
    }
    return response;

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ANDRES / SPRINT 3.5
  public async getBatchLoans(data: any) {
    const url = route_loans + '/batch-loans?limit=' + data.limit + '&page=' + data.page + '&userId=' + data.userId + '&companyId=' + data.companyId + '&batchId=' + data.batchId + '&loanType=' + data.loanType + '&loanNumber=' + data.loanNumber + '&loanCode=' + data.loanCode + '&loanAmount=' + data.loanAmount  + '&loanStatusLast=' + data.loanStatusLast;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent'];

    // DATA IS SENDED ON URI

    // DATA SENDED
    // SOME ATTRIBUTES CAN BE null, IN THAT CASE SHOULD NOT BE TAKEN ON THE WHERE AT THE QUERY
    const sendedURIData: any = {
      limit: 'limit',
      page: 'page',
      userId: 'userId',
      companyId: 'companyId',
      batchId: 'batchId',
      loanType: 'loanType',
      loanNumber: 'loanNumber',
      loanCode: 'loanCode',
      loanAmount: 'loanAmount',
      loanStatusLast: 'loanStatusLast'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        result: [
          {
            id: "d3247dc7-0731-454a-9aad-234c54ad527c",
            loanType: "fixedInterest",
            loanNumber: "M380W",
            loanCode: "$2b$10$J7J7SWlg2gWQjllEjSRCueRzJ4G8O05.QjxIDr5ADhNNGk7T7EMfW",
            loanAmountApproved: 7600000,
            loanPaymentMonthsApproved: 6,
            loanSinglePayment: 560364,
            loanInterest: 2.52,
            userWork: {
              id: "32bf67c0-aa0b-4573-83e7-76b2c4bbe990",
              user: {
                id: '',
                avatar: '',
                firstName: '',
                lastName: '',
                email: '',
                cellphoneCountryCode: '',
                cellphoneCountryCodeCod: 'country.numericCode',
                cellphone: '',
                userBankAccounts: [
                  {
                    bankId: 'bank.id',
                    bankName: 'bank.bankName',
                    bankLogo: 'bank.bankLogo',
                    bankAccountType: 'userBankAccount.userBankAccountType',
                    bankAccountNumber: 'userBankAccount.userBankAccountNumber',
                  }
                ]
              },
              company: {
                id: '',
                companyName: '',
                businessName: '',
                companyLogo: '',
                companyCellphoneCountryCode: '',
                companyCellphoneCountryCodeCod: 'country.numericCode',
                companyCellphone: ''
              },
              otherCompany: {
                id: '',
                companyName: '',
                businessName: 'companyName',
              }
            },
            productId: "ad938ad1-5a59-4ea5-88a7-025e759c6adf",
            loanStatuses: [
              {
                loanStatus:"started",
                loanStatusObservations: null,
                loanStatusDate:"2023-07-17T15:07:35.235Z"
              }
            ],
            loanStatusDateLast: "2023-06-14 02:11:04.424071+00",
            loanStatusLast: "started",
          },
        ],
        pages: 1
      }
    }
    return response;

    // RESPONSE RECEIVED
    const responseReceived = {
      "statusCode": 401,
      "error": true,
      "path": "/loans?limit=10&page=1&userId=null&loanType=null&loanNumber=null&loanCode=null&loanAmount=null&loanStatusLast=null",
      "timestamp": "2023-06-14T02:32:16.601Z",
      "data": "Invalid role"
    }

    // ON SWAGER CAN'T SEE THE OTHER PARAMETERS BEEN RECEIVED ON THE URL
    // BY THE ERROR MSG IT SEEMS SOMETHING RELATED WITH ROLES, CHECK roleAccess ABOVE
    
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 4
  public async addBatch(data: any) {
    const url = route_batches + '/';
    return await firstValueFrom(this.http.post(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON BODY

    // DATA SENDED
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES ADDED
    const sendedBodyData: any = {
      batchType: 'batchType', // ENUM('payment', 'disbursement')
      batchNumber: 'batchNumber',
      batchCode: 'batchCode',
      batchObservations: 'batchObservations'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        batch: 'batch' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 4
  public async updateBatch(id: string, data: any) {
    const url = route_batches + '/' + id;
    return await firstValueFrom(this.http.put(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON URI AND BODY

    // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // DATA SENDED ON BODY
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      batchType: 'batchType', // ENUM('payment', 'disbursement')
      batchNumber: 'batchNumber',
      batchCode: 'batchCode',
      batchObservations: 'batchObservations',
      batchPaymentReceiptFile: 'batchPaymentReceiptFile',
      batchPaymentReceiptFileKey: 'batchPaymentReceiptFileKey',
    };
  
    // EXPECTED RESPONSE
    const response: any = {
      data: {
        batch: 'batch' // OBJECT
      }
    }
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 4
  public async removeBatch(id: string) {
    const url = route_batches + '/' + id;
    return await firstValueFrom(this.http.delete(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'ceo', 'manager'];
  
    // DATA IS SENDED ON URI
  
   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // NO EXPECTED RESPONSE

    // ON DELETE, REPLACE ON CASCADE FOR NULL
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }

  // batch loans
  // ---------------------------------------------------------------------
  // OK: ASSIGNED TO ANDRES / SPRINT 4
  public async linkBatchLoan(data: any) {
    const url = route_batch_has_loans;
    return await firstValueFrom(this.http.post(url, data));
  
    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];
  
    // DATA IS SENDED ON BODY
  
    // DATA SENDED
    const sendedBodyData: any = {
      batchId: 'batchId',
      loanId: 'loanId',
    };
  
    // NO EXPECTED RESPONSE
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES
  
  }
  // OK: ASSIGNED TO ANDRES / SPRINT 4
  public async unlinkBatchLoan(batchId: string, loanId: string) {
    const url = route_batch_has_loans + '/' + batchId + '/' + loanId;
    return await firstValueFrom(this.http.delete(url));
  
    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent'];
  
    // DATA IS SENDED ON BODY
  
    // DATA SENDED
    const sendedBodyData: any = {
      batchId: 'batchId',
      loanId: 'loanId',
    };
  
    // NO EXPECTED RESPONSE
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES
  
  }

  // batch loan payments
  // ---------------------------------------------------------------------
  // OK: ASSIGNED TO ANDRES / SPRINT 4
  public async linkBatchLoanPayment(data: any) {
    const url = route_batch_has_loan_payments;
    return await firstValueFrom(this.http.post(url, data));
  
    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];
  
    // DATA IS SENDED ON BODY
  
    // DATA SENDED
    const sendedBodyData: any = {
      batchId: 'batchId',
      loanPaymentId: 'loanPaymentId',
    };
  
    // NO EXPECTED RESPONSE
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES
  
  }
  // OK: ASSIGNED TO ANDRES / SPRINT 4
  public async unlinkBatchLoanPayment(batchId: string, loanPaymentId: string) {
    const url = route_batch_has_loan_payments + '/' + batchId + '/' + loanPaymentId;
    return await firstValueFrom(this.http.delete(url));
  
    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent'];
  
    // DATA IS SENDED ON BODY
  
    // DATA SENDED
    const sendedBodyData: any = {
      batchId: 'batchId',
      loanPaymentId: 'loanPaymentId',
    };
  
    // NO EXPECTED RESPONSE
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES
  
  }

  // batch statuses
  // ---------------------------------------------------------------------
  // OK: ASSIGNED TO ANDRES / SPRINT 4
  public async getBatchStatuses(data: any) {
    // const url = route_batch_status + '?limit=' + data.limit + '&page=' + data.page + '&batchId=' + data.batchId;
    const url = route_batch_status + '?limit=' + data.limit + '&page=' + data.page + '&batchStatus=' + data.batchStatus + '&batchId=' + data.batchId;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON URI

    // DATA SENDED
    // SOME ATTRIBUTES CAN BE null, IN THAT CASE SHOULD NOT BE TAKEN ON THE WHERE AT THE QUERY
    const sendedURIData: any = {
      limit: 'limit',
      page: 'page',
      batchStatus: 'batchStatus',
      batchId: 'batchId',
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        result: [
          {
            batchStatus: 'batchStatus' // OBJECT
          }
        ],
        pages: 1
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 4
  public async getBatchStatus(id: string) {
    const url = route_batch_status + '/' + id;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON URI

   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        batchStatus: 'batchStatus' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 4
  public async addBatchStatus(data: any) {
    const url = route_batch_status;
    return await firstValueFrom(this.http.post(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON BODY

    // DATA SENDED
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      batchId: 'batchId',
      userId: 'userId',
      batchStatus: 'batchStatus' // ENUM('created', 'downloadedCSV', 'finished')
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        batchStatus: 'batchStatus' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 4
  public async updateBatchStatus(id: string, data: any) {
    const url = route_batch_status  + '/' + id;
    return await firstValueFrom(this.http.put(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON URI AND BODY

    // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // DATA SENDED ON BODY
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      batchStatus: 'batchStatus' // ENUM('created', 'downloadedCSV', 'finished')
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        batchStatus: 'batchStatus' // OBJECT
      }
    }
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 4
  public async removeBatchStatus(id: string) {
    const url = route_batch_status + '/' + id;
    return await firstValueFrom(this.http.delete(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure'];

    // DATA IS SENDED ON URI
  
   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // NO EXPECTED RESPONSE

    // ON DELETE, REPLACE ON CASCADE FOR NULL 
    
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }


  // reports
  // ---------------------------------------------------------------------
  // MODIFY: ASSIGNED TO ANDRES / SPRINT 3.5
  public async getLoanTotals(companyId: string) {
    // TODO: FILTER DATA BY COMPANY
    const url = route_loans + '/totals?companyId=' + companyId;
    // const url = route_loans + '/totals';
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON URI
  
    // DATA SENDED ON URI
    const sendedURIData: any = {
      companyId: 'companyId' // IF COMPANYID IS NULL THEN RETURN ALL CORRESPONDING DATA, IF IS NOT NULL THEN FILTER DATA OF THAT COMPANY
    };

    // ENUM('started', 'sended', 'accepted', 'validated', 'noPassedValidation', 'preApproved', 'approved', 'rejected', 'readyToApprove', 'readyForDisbursement', 'disbursementOnProgress', 'disbursed', 'payed', 'preLegal', 'legal', 'negotiation', 'expired')

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        all: 0, // todos
        initial: 0, // 'started', 'sended', 'accepted', 'validated', 'noPassedValidation', 'preApproved', 'approved', 'rejected', 'readyToApprove', 'readyForDisbursement', 'disbursementOnProgress',
        late: 0, // 'preLegal', 'legal', 'negotiation', 'expired'
        started: 0,
        sended: 0,
        accepted: 0,
        validated: 0,
        noPassedValidation: 0,
        preApproved: 0,
        approved: 0,
        rejected: 0,
        readyToApprove: 0,
        readyForDisbursement: 0,
        disbursementOnProgress: 0,
        disbursed: 0,
        payed: 0,
        preLegal: 0,
        legal: 0,
        negotiation: 0,
        expired: 0,
        canceled: 0
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // MODIFY: ASSIGNED TO ANDRES / SPRINT 3.5
  public async getBatchTotals(companyId: string) {
    // TODO: FILTER DATA BY COMPANY
    const url = route_batches + '/totals/counter?companyId=' + companyId;
    // const url = route_batches + '/totals/counter';
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON URI
  
    // DATA SENDED ON URI
    const sendedURIData: any = {
      companyId: 'companyId' // IF COMPANYID IS NULL THEN RETURN ALL CORRESPONDING DATA, IF IS NOT NULL THEN FILTER DATA OF THAT COMPANY
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        disbursement: 4,
        payment: 2,
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // NEW: ASSIGNED TO ANDRES / SPRINT 5
  public async getPendingTotals(companyId: string) {
    const url = route_reports + '/loans/pending?companyId=' + companyId;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent'];

    // DATA IS SENDED ON URI
  
    // DATA SENDED ON URI
    const sendedURIData: any = {
      companyId: 'companyId' // IF COMPANYID IS NULL THEN RETURN ALL CORRESPONDING DATA, IF IS NOT NULL THEN FILTER DATA OF THAT COMPANY
    };

    // HERE IT SHOULD ZUM ALL LOANAMOUNT FROM LOANS WITH THE FOLLOWING STATUSES
    // 'started', 'sended', 'accepted', 'validated'
    
    // EXPECTED RESPONSE
    const response: any = {
      data: 75000000
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // NEW: ASSIGNED TO ANDRES / SPRINT 5
  public async getNoPassedValidationTotals(companyId: string) {
    const url = route_reports + '/loans/no-passed-validation?companyId=' + companyId;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent'];

    // DATA IS SENDED ON URI
  
    // DATA SENDED ON URI
    const sendedURIData: any = {
      companyId: 'companyId' // IF COMPANYID IS NULL THEN RETURN ALL CORRESPONDING DATA, IF IS NOT NULL THEN FILTER DATA OF THAT COMPANY
    };

    // HERE IT SHOULD ZUM ALL LOANAAMOUNT FROM LOANS WITH THE FOLLOWING STATUSES
    // 'noPassedValidation'
    
    // EXPECTED RESPONSE
    const response: any = {
      data: 3000000
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // NEW: ASSIGNED TO ANDRES / SPRINT 5
  public async getRejectedTotals(companyId: string) {
    const url = route_reports + '/loans/rejected?companyId=' + companyId;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent'];

    // DATA IS SENDED ON URI
  
    // DATA SENDED ON URI
    const sendedURIData: any = {
      companyId: 'companyId' // IF COMPANYID IS NULL THEN RETURN ALL CORRESPONDING DATA, IF IS NOT NULL THEN FILTER DATA OF THAT COMPANY
    };

    // HERE IT SHOULD ZUM ALL LOANAAMOUNT FROM LOANS WITH THE FOLLOWING STATUSES
    // 'rejected'
    
    // EXPECTED RESPONSE
    const response: any = {
      data: 15000000
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // NEW: ASSIGNED TO ANDRES / SPRINT 5
  public async getCanceledTotals(companyId: string) {
    const url = route_reports + '/loans/canceled?companyId=' + companyId;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent'];

    // DATA IS SENDED ON URI
  
    // DATA SENDED ON URI
    const sendedURIData: any = {
      companyId: 'companyId' // IF COMPANYID IS NULL THEN RETURN ALL CORRESPONDING DATA, IF IS NOT NULL THEN FILTER DATA OF THAT COMPANY
    };

    // HERE IT SHOULD ZUM ALL LOANAAMOUNT FROM LOANS WITH THE FOLLOWING STATUSES
    // 'rejected'
    
    // EXPECTED RESPONSE
    const response: any = {
      data: 15000000
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // NEW: ASSIGNED TO ANDRES / SPRINT 5
  public async getReadyToApproveTotals(companyId: string) {
    const url = route_reports + '/loans/readyToApprove?companyId=' + companyId;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent'];

    // DATA IS SENDED ON URI
  
    // DATA SENDED ON URI
    const sendedURIData: any = {
      companyId: 'companyId' // IF COMPANYID IS NULL THEN RETURN ALL CORRESPONDING DATA, IF IS NOT NULL THEN FILTER DATA OF THAT COMPANY
    };

    // HERE IT SHOULD ZUM ALL LOANAMOUNT FROM LOANS WITH THE FOLLOWING STATUSES
    // 'started', 'sended', 'accepted', 'validated', 'noPassedValidation', 'preApproved', 'approved', 'rejected', 'readyToApprove', 'readyForDisbursement', 'disbursementOnProgress',
    
    // EXPECTED RESPONSE
    const response: any = {
      data: 90000000
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // NEW: ASSIGNED TO ANDRES / SPRINT 5
  public async getApprovedTotals(companyId: string) {
    const url = route_reports + '/loans/approved?companyId=' + companyId;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent'];

    // DATA IS SENDED ON URI
  
    // DATA SENDED ON URI
    const sendedURIData: any = {
      companyId: 'companyId' // IF COMPANYID IS NULL THEN RETURN ALL CORRESPONDING DATA, IF IS NOT NULL THEN FILTER DATA OF THAT COMPANY
    };

    // HERE IT SHOULD ZUM ALL APPROVED AND NOT DISBURSEDLOANS WITH THE FOLLOWING STATUSES
    
    // EXPECTED RESPONSE
    const response: any = {
      data: 25000000
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // NEW: ASSIGNED TO ANDRES / SPRINT 5
  public async getDisbursedTotals(companyId: string) {
    const url = route_reports + '/loans/disbursements?companyId=' + companyId;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON URI
  
    // DATA SENDED ON URI
    const sendedURIData: any = {
      companyId: 'companyId' // IF COMPANYID IS NULL THEN RETURN ALL CORRESPONDING DATA, IF IS NOT NULL THEN FILTER DATA OF THAT COMPANY
    };

    // ENUM('started', 'sended', 'accepted', 'validated', 'noPassedValidation', 'preApproved', 'approved', 'rejected', 'readyToApprove', 'readyForDisbursement', 'disbursementOnProgress', 'disbursed', 'payed', 'preLegal', 'legal', 'negotiation', 'expired')

    // EXPECTED RESPONSE
    const response: any = {
      data: 125000000
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // NEW: ASSIGNED TO ANDRES / SPRINT 5
  public async getPayedTotals(companyId: string) {
    const url = route_reports + '/payed?companyId=' + companyId;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON URI
  
    // DATA SENDED ON URI
    const sendedURIData: any = {
      companyId: 'companyId'
    };

    // HERE IT SHOULD ZUM ALL LOANPAYMENTAMOUNT FROM LOANPAYMENTS WITH THE STATUS PAYED

    // EXPECTED RESPONSE
    const response: any = {
      data: 85000000
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // NEW: ASSIGNED TO ANDRES / SPRINT 5
  public async getToCollectTotals(companyId: string) {
    const url = route_reports + '/to-collect?companyId=' + companyId;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON URI
  
    // DATA SENDED ON URI
    const sendedURIData: any = {
      companyId: 'companyId'
    };

    // HERE IT SHOULD ZUM ALL LOANPAYMENTAMOUNT FROM LOANPAYMENTS WITH THE STATUS DIFERENT THAN PAYED
    // AND LOANSTATUS

    // EXPECTED RESPONSE
    const response: any = {
      data: 85000000
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }


  // NEW: ASSIGNED TO ANDRES / SPRINT 5
  public async getLoanProducts(data: any) {
    const url = route_reports + '/loans-by-products/' +  data.timeQueryOption  + '/' +  data.selectedDateStart + '/' +  data.selectedTimeStart  + '/' +  data.selectedDateEnd  + '/' +  data.selectedTimeEnd + '?companyId=' + data.companyId;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON URI
  
    // DATA SENDED ON URI
    const sendedURIData: any = {
      companyId: 'companyId',
      timeQueryOption: 'timeQueryOption',
      selectedDateStart: 'selectedDateStart',
      selectedTimeStart: 'selectedTimeStart',
      selectedDateEnd: 'selectedDateEnd',
      selectedTimeEnd: 'selectedTimeEnd'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        products: [
          {
            productId: 'pr1',
            productName: 'productName',
            productData: ['approvedAmount1', 'approvedAmount2', '...etc'] 
          }
        ],
        totalDisbursed: 'zumOfDisbursedOnSelectedPeriod',
        totalPayed: 'zumOfPayedOnSelectedPeriod'
      }
    }

    // TO BUILD productData IT SHOULD BE CONSIDER THE FOLLOWING.

    // -- timeQueryOption === 'today', approvedAmount SHOULD BE ZUM OF EVERY HOUR FROM selectedTimeStart TO selectedTimeEnd from approvedDate on loanStatuses
    // -- timeQueryOption === 'week', approvedAmount SHOULD BE ZUM OF EVERY DAY OF THE WEEK FROM selectedTimeStart TO selectedTimeEnd from approvedDate on loanStatuses
    // -- timeQueryOption === 'month', approvedAmount SHOULD BE ZUM OF EVERY DAY OF THE MONTH FROM selectedTimeStart TO selectedTimeEnd from approvedDate on loanStatuses
    // -- timeQueryOption === 'trimester', approvedAmount SHOULD BE ZUM OF EVERY MONTH OF TRIMESTER FROM selectedTimeStart TO selectedTimeEnd from approvedDate on loanStatuses
    // -- timeQueryOption === 'semester', approvedAmount SHOULD BE ZUM OF EVERY MONTH OF SEMESTER FROM selectedTimeStart TO selectedTimeEnd from approvedDate on loanStatuses
    // -- timeQueryOption === 'year', approvedAmount SHOULD BE ZUM OF EVERY HOUR MONTH OF THE YEAR selectedTimeStart TO selectedTimeEnd from approvedDate on loanStatuses
    // -- timeQueryOption === 'other', approvedAmount SHOULD BE ZUM OF EVERY DAY FROM selectedTimeStart TO selectedTimeEnd from approvedDate on loanStatuses

    const simulatedResponse: any = {
      data: {
        products: [
          {
            productId: 'f61dcec5-5387-4b58-b95d-b10b0f6a075e',
            productName: 'Libre Inversión',
            productData: [17500000, 23450000, 12565000, 3450000, 45735000, 72568350, 1231112] 
          },
          {
            productId: 'ad938ad1-5a59-4ea5-88a7-025e759c6adf',
            productName: 'Libre Inversión Empleados Ismocol',
            productData: [45735000, 12565000, 23450000, 72568350, 17500000, 26549350, 874293] 
          },
          {
            productId: '6313206f-422f-4131-9e00-58b6c173c3ff',
            productName: 'Financiacion Polizas',
            productData: [23450000, 72568350, 17500000, 12565000, 26549350, 45735000, 4437281] 
          }
        ],
        totalDisbursed: 53251920,
        totalPayed: 173429
      } 
    }
    // return simulatedResponse;

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // NEW: ASSIGNED TO ANDRES / SPRINT 5
  public async getLoanWorkActivities(data: any) {
    const url = route_reports + '/loans-by-work-activities/' +  data.selectedDateStart + '/' +  data.selectedTimeStart  + '/' +  data.selectedDateEnd  + '/' +  data.selectedTimeEnd + '?companyId=' + data.companyId;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON URI
  
    // DATA SENDED ON URI
    const sendedURIData: any = {
      companyId: 'companyId',
      timeQueryOption: 'timeQueryOption',
      selectedDateStart: 'selectedDateStart',
      selectedTimeStart: 'selectedTimeStart',
      selectedDateEnd: 'selectedDateEnd',
      selectedTimeEnd: 'selectedTimeEnd'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        workActivities: [
          {
            workActivity: 'workActivityName',
            workActivityAmount: 'approvedAmount' 
          }
        ],
        totals: 'zumOfApprovedAmountOnSelectedPeriod'
      }
    }

    // ENUM('business', 'employee', 'entrepreneur', 'student', 'home', 'pensioner')

    // TO BUILD productData IT SHOULD BE CONSIDER THE FOLLOWING.

    // timeQueryOption === 'today', approvedAmount SHOULD BE ZUM OF EVERY HOUR FROM selectedTimeStart TO selectedTimeEnd from approvedDate on loanStatuses
    // timeQueryOption === 'week', approvedAmount SHOULD BE ZUM OF EVERY DAY OF THE WEEK FROM selectedTimeStart TO selectedTimeEnd from approvedDate on loanStatuses
    // timeQueryOption === 'month', approvedAmount SHOULD BE ZUM OF EVERY DAY OF THE MONTH FROM selectedTimeStart TO selectedTimeEnd from approvedDate on loanStatuses
    // timeQueryOption === 'trimester', approvedAmount SHOULD BE ZUM OF EVERY MONTH OF TRIMESTER FROM selectedTimeStart TO selectedTimeEnd from approvedDate on loanStatuses
    // timeQueryOption === 'semester', approvedAmount SHOULD BE ZUM OF EVERY MONTH OF SEMESTER FROM selectedTimeStart TO selectedTimeEnd from approvedDate on loanStatuses
    // timeQueryOption === 'year', approvedAmount SHOULD BE ZUM OF EVERY HOUR MONTH OF THE YEAR selectedTimeStart TO selectedTimeEnd from approvedDate on loanStatuses
    // timeQueryOption === 'other', approvedAmount SHOULD BE ZUM OF EVERY HOUR FROM selectedTimeStart TO selectedTimeEnd from approvedDate on loanStatuses

    // DEBIDO A QUE YO TE MANDO LAS FECHAS. ESTE ES EL ÚNICO QUERY QUE NECESITARIAMOS PARA ARMAR EL JSON

    // WITH workTypes AS (
    //   SELECT unnest(ARRAY['business', 'employee', 'entrepreneur', 'student', 'home', 'pensioner']::user_works_work_type_enum[]) AS work_type
    // )
    
    // SELECT 
    //     workTypes.work_type,
    //     COALESCE(SUM(loans.loan_amount_approved), 0) AS total_approved_per_work_type
    // FROM workTypes
    // LEFT JOIN user_works ON user_works.work_type = workTypes.work_type
    // LEFT JOIN loans ON user_works.id = loans.user_works_id
    // LEFT JOIN loan_statuses ON loan_statuses.loan_id = loans.id
    // LEFT JOIN companies ON companies.id = user_works.company_id
    // WHERE (loan_statuses.loan_status IN ('approved') OR loan_statuses.loan_status IS NULL)
    //     AND (loan_statuses.created_at BETWEEN '2023-10-13 00:00' AND '2023-10-13 23:59' OR loan_statuses.created_at IS NULL)
    // GROUP BY workTypes.work_type
    // ORDER BY workTypes.work_type;
    

    // TOTALDISBURSED AND TOTAL PAYED WOULD HAVE TO BE ON THE SAME timeQueryOption RANGE

    const simulatedResponse: any = {
      data: {
        workActivities: [
          {
            workActivity: 'business',
            workActivityAmount: 17500000
          },
          {
            workActivity: 'employee',
            workActivityAmount: 23450000
          },
          {
            workActivity: 'entrepreneur',
            workActivityAmount: 12565000
          },
          {
            workActivity: 'student',
            workActivityAmount: 3450000
          },
          {
            workActivity: 'home',
            workActivityAmount: 45735000
          },
          {
            workActivity: 'pensioner',
            workActivityAmount: 72568350
          }
        ],
        totals: 3251920
      } 
    }
    // return simulatedResponse;

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // NEW: ASSIGNED TO ANDRES / SPRINT 5
  public async getLoanRequestedVsApproved(data: any) {
    const url = route_reports + '/loans-requested-vs-approved/' +  data.timeQueryOption  + '/' +  data.selectedDateStart + '/' +  data.selectedTimeStart  + '/' +  data.selectedDateEnd  + '/' +  data.selectedTimeEnd + '?companyId=' + data.companyId;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON URI
  
    // DATA SENDED ON URI
    const sendedURIData: any = {
      companyId: 'companyId',
      timeQueryOption: 'timeQueryOption',
      selectedDateStart: 'selectedDateStart',
      selectedTimeStart: 'selectedTimeStart',
      selectedDateEnd: 'selectedDateEnd',
      selectedTimeEnd: 'selectedTimeEnd'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: [
        {
          requested: ['amount1', 'amount2', '...etc'],
          approved: ['approvedAmount1', 'approvedAmount2', '...etc'] 
        }
      ]
    }

    // TO BUILD productData IT SHOULD BE CONSIDER THE FOLLOWING.

    // timeQueryOption === 'today', approvedAmount SHOULD BE ZUM OF EVERY HOUR FROM selectedTimeStart TO selectedTimeEnd from approvedDate on loanStatuses
    // timeQueryOption === 'week', approvedAmount SHOULD BE ZUM OF EVERY DAY OF THE WEEK FROM selectedTimeStart TO selectedTimeEnd from approvedDate on loanStatuses
    // timeQueryOption === 'month', approvedAmount SHOULD BE ZUM OF EVERY DAY OF THE MONTH FROM selectedTimeStart TO selectedTimeEnd from approvedDate on loanStatuses
    // timeQueryOption === 'trimester', approvedAmount SHOULD BE ZUM OF EVERY MONTH OF TRIMESTER FROM selectedTimeStart TO selectedTimeEnd from approvedDate on loanStatuses
    // timeQueryOption === 'semester', approvedAmount SHOULD BE ZUM OF EVERY MONTH OF SEMESTER FROM selectedTimeStart TO selectedTimeEnd from approvedDate on loanStatuses
    // timeQueryOption === 'year', approvedAmount SHOULD BE ZUM OF EVERY HOUR MONTH OF THE YEAR selectedTimeStart TO selectedTimeEnd from approvedDate on loanStatuses
    // timeQueryOption === 'other', approvedAmount SHOULD BE ZUM OF EVERY HOUR FROM selectedTimeStart TO selectedTimeEnd from approvedDate on loanStatuses

    // TOTALDISBURSED AND TOTAL PAYED WOULD HAVE TO BE ON THE SAME timeQueryOption RANGE

    const simulatedResponse: any = {
      data: {
        requested: [45735000, 12565000, 23450000, 72568350, 17500000, 26549350, 23450000],
        approved: [17500000, 23450000, 12565000, 3450000, 45735000, 72568350, 12565000] 
      } 
    }
    // return simulatedResponse;

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // NEW: ASSIGNED TO ANDRES / SPRINT 5
  public async getLoanTop5(data: any) {
    const url = route_reports + '/loans-top-5?companyId=' + data.companyId;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON URI
  
    // DATA SENDED ON URI
    const sendedURIData: any = {
      companyId: 'companyId',
      timeQueryOption: 'timeQueryOption',
      selectedDateStart: 'selectedDateStart',
      selectedTimeStart: 'selectedTimeStart',
      selectedDateEnd: 'selectedDateEnd',
      selectedTimeEnd: 'selectedTimeEnd'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: [
        {
          user: {
            id: 'id',
            avatar: 'avatar',
            firstName: 'firstName',
            lastName: 'lastName',
            email: 'email',
            cellphoneCountryCode: 'cellphoneCountryCode',
            cellphone: 'cellphone',
          },
          id: 'id',
          loanNumber: 'loanNumber',
          amount: 'amount',
          approvedAmount: 'approvedAmount',
        }
      ]
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // NEW: ASSIGNED TO ANDRES / SPRINT 5
  public async getExcelReport(data: any) {
    // TODO: REPLACE URL TO INCLUDE DATES ON FILTER
    const url = route_reports + '/generate-report-excel/' + data.dateStart + '/' + data.timeStart + '/' + data.dateEnd + '/' + data.timeEnd;
    // const url = route_reports + '/generate-report-excel';
    return await firstValueFrom(this.http.post(url, {}));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON URI
  
    // DATA SENDED ON URI
    const sendedURIData: any = {
      companyId: 'companyId',
      dateStart: 'dateStart',
      timeStart: 'timeStart',
      dateEnd: 'dateEnd',
      timeEnd: 'timeEnd'
    };


    // EXPECTED RESPONSE
    const response: any = {
      data: {
        message: 'message',
        file: 'file',
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }


  // transactions
  // ---------------------------------------------------------------------
  // OK: ASSIGNED TO ANDRES / SPRINT 4
  public async getTransactions(data: any) {
    const url = route_transactions + '?limit=' + data.limit + '&page=' + data.page + '&batchId=' + data.batchId + '&userId=' + data.userId + '&loanId=' + data.loanId + '&loanPaymentId=' + data.loanPaymentId + '&transactionType=' + data.transactionType + '&tokenId=' + data.tokenId + '&transactionCode=' + data.transactionCode;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar', 'client'];

    // DATA IS SENDED ON URI

    // DATA SENDED
    // SOME ATTRIBUTES CAN BE null, IN THAT CASE SHOULD NOT BE TAKEN ON THE WHERE AT THE QUERY
    const sendedURIData: any = {
      limit: 'limit',
      page: 'page',
      batchId: 'batchId',
      userId: 'userId',
      loanId: 'loanId',
      loanPaymentId: 'loanPaymentId',
      transactionType: 'transactionType',
      tokenId: 'tokenId',
      transactionCode: 'transactionCode',
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        result: [
          {
            id: 'id',
            userId: 'userId',
            loanId: 'loanId',
            loanPaymentId: 'loanPaymentId',
            transactionType: 'transactionType',
            transactionCode: 'transactionCode',
            transactionStatusLast: 'transactionStatusLast',
            transactionStatusDateLast: 'transactionStatusDateLast'
          }
        ],
        pages: 1
      }
    }
    return response;
    
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 4
  public async getTransaction(id: string) {
    const url = route_transactions + '/' + id;
    return await firstValueFrom(this.http.get(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar', 'client'];

    // DATA IS SENDED ON URI

    // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        transaction: 'transaction', // OBJECT
      }
    }
    return response;

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 4
  public async addTransaction(data: any) {
    const url = route_transactions + '/';
    return await firstValueFrom(this.http.post(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON BODY

    // DATA SENDED + '/' + id;
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES ADDED
    const sendedBodyData: any = {
      userId: 'userId',
      batchId: 'batchId',
      loanId: 'loanId',
      loanPaymentId: 'loanPaymentId',
      transactionType: 'transactionType',
      transactionNote: 'transactionNote',
    };

    // EXPECTED RESPONSE
    const response: any = {
      data: {
        transaction: 'transaction' // OBJECT
      }
    }

    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 4
  public async updateTransaction(id: string, data: any) {
    const url = route_transactions + '/' + id;
    return await firstValueFrom(this.http.put(url, data));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'];

    // DATA IS SENDED ON URI AND BODY

    // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };

    // DATA SENDED ON BODY
    // NOT ALL ATTRIBUTES WILL BE SEND ALWAYS
    // ONLY SENDED ATTRIBUTES ARE GOING TO BE THE ONES UPDATED
    const sendedBodyData: any = {
      userId: 'userId',
      batchId: 'batchId',
      loanId: 'loanId',
      loanPaymentId: 'loanPaymentId',
      transactionType: 'transactionType',
      transactionNote: 'transactionNote',
    };
  
    // EXPECTED RESPONSE
    const response: any = {
      data: {
        transaction: 'transaction' // OBJECT
      }
    }
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
  // OK: ASSIGNED TO ANDRES / SPRINT 4
  public async removeTransaction(id: string) {
    const url = route_transactions + '/' + id;
    return await firstValueFrom(this.http.delete(url));

    // ROLE CHECKING
    const roleAccess: any = ['support', 'administrator'];
  
    // DATA IS SENDED ON URI
  
   // DATA SENDED ON URI
    const sendedURIData: any = {
      id: 'id'
    };
  
    // NO EXPECTED RESPONSE

    // ON DELETE, REPLACE ON CASCADE FOR NULL
  
    // IF ERROR CATCH WILL HANDLE IT
    // DEFINE WHAT WILL BE THE ERROR MESSAGE TO FILTER IT ON FILTER ERROR MESSAGES

  }
}


// TODO: CRONJOBS

// [{"loanStatus":"started","loanStatusObservations":null,"loanStatusDate":"2023-09-14T05:12:54.584Z","userId":"7bc7c00e-2edf-4eb4-a8db-7516806b28dd","firstName":"B2c","lastName":"Ismocol","email":"idemshall@gmail.com","cellphoneCountryCode":"d27a2ec8-e942-4044-b00c-382caf3a2673","cellphone":"3005020590"},{"loanStatus":"sended","loanStatusObservations":null,"loanStatusDate":"2023-09-14T05:12:57.548Z","userId":"7bc7c00e-2edf-4eb4-a8db-7516806b28dd","firstName":"B2c","lastName":"Ismocol","email":"idemshall@gmail.com","cellphoneCountryCode":"d27a2ec8-e942-4044-b00c-382caf3a2673","cellphone":"3005020590"},{"loanStatus":"accepted","loanStatusObservations":null,"loanStatusDate":"2023-09-14T14:05:23.464Z","userId":"55722a7d-d272-4c30-9a33-1e09401ca73d","firstName":"Gerencia","lastName":"CrediPremium","email":"gerencia@credipremium.co"},{"loanStatus":"validated","loanStatusObservations":null,"loanStatusDate":"2023-09-14T14:05:49.014Z","userId":"55722a7d-d272-4c30-9a33-1e09401ca73d","firstName":"Gerencia","lastName":"CrediPremium","email":"gerencia@credipremium.co"}]  

// SELECT loans.* 
// FROM public.loans
// WHERE loans.loan_number = 'MED-2810031-2024'
// ORDER BY loans.loan_number ASC 

// SELECT loan_statuses.* 
// FROM public.loans
// LEFT JOIN loan_statuses ON loan_statuses.loan_id = loans.id
// WHERE loans.loan_number = 'MED-2810031-2024'

// SELECT loan_payments.* 
// FROM public.loans
// LEFT JOIN loan_payments ON loan_payments.loan_id = loans.id
// WHERE loans.loan_number = 'MED-2810031-2024'
// ORDER BY loan_payments.loan_payment_number ASC 

// SELECT loan_payment_statuses.* 
// FROM public.loans
// LEFT JOIN loan_payments ON loan_payments.loan_id = loans.id
// LEFT JOIN loan_payment_statuses ON loan_payment_statuses.loan_payment_id = loan_payments.id
// WHERE loans.loan_number = 'MMED-2810031-2024'
// ORDER BY loan_payments.loan_payment_number ASC 


// SELECT loans.*
// FROM public.loans
// WHERE loans.loan_status_last = 'readyForDisbursment'
// ORDER BY loans.loan_number ASC 

// SELECT loans.loan_number, loan_statuses.* 
// FROM public.loans
// LEFT JOIN loan_statuses ON loan_statuses.loan_id = loans.id
// WHERE loans.loan_status_last = 'readyForDisbursment'


// SELECT batches.* 
// FROM public.batches
// LEFT JOIN batch_has_loan_payments ON batch_has_loan_payments.batch_id = batches.id
// WHERE batch_has_loan_payments.loan_payments_id = '755f09a0-7803-4a8e-825f-430ba568fac3'

// SELECT batches.* 
// FROM public.batches
// WHERE batches.batch_number = 'Z4O8V'

// SELECT batch_status.* 
// FROM public.batches
// LEFT JOIN batch_status ON batch_status.batch_id = batches.id
// WHERE batches.batch_number = 'Z4O8V'

// SELECT batch_has_loan_payments.* 
// FROM public.batches
// LEFT JOIN batch_has_loan_payments ON batch_has_loan_payments.batch_id = batches.id
// WHERE batches.batch_number = 'Z4O8V'

// SELECT batch_has_loans.* 
// FROM public.batches
// LEFT JOIN batch_has_loans ON batch_has_loans.batch_id = batches.id
// WHERE batches.batch_number = 'Z4O8V'


// SELECT user_works.* 
// FROM public.loans
// INNER JOIN user_works ON user_works.id = loans.user_works_id
// WHERE loans.loan_number = 'MED-2810031-2024'
// ORDER BY loans.loan_number ASC 

// SELECT user_works.* 
// FROM public.user_works
// WHERE user_works.id = '8b1243c5-6276-427e-84f0-92039b264440'


// SELECT loans.loan_number, loans.loan_status_last, users.id AS user_id, users.first_name, users.last_name, user_works.id AS user_work_id, user_works.work_type, user_works.contract_type, user_bank_accounts.user_bank_account_type, user_bank_accounts.user_bank_account_number, banks.id AS bank_id, banks.bank_name
// FROM public.loans
// LEFT JOIN user_works ON user_works.id = loans.user_works_id
// LEFT JOIN users ON users.id = user_works.user_id
// LEFT JOIN user_bank_accounts ON users.id = user_bank_accounts.user_id
// LEFT JOIN banks ON banks.id = user_bank_accounts.bank_id
// ORDER BY loans.loan_number ASC 



// SELECT user_works.* FROM public.loans
// INNER JOIN user_works ON user_works.id = loans.user_works_id
// WHERE loans.loan_number = 'GL3D7'
// ORDER BY loans.id ASC 

// SELECT * FROM public.user_bank_accounts
// WHERE user_id = '086dc120-b958-4017-8897-c393e5282bb6'
// ORDER BY id ASC 

// SELECT * FROM public.users
// ORDER BY id ASC 

// SELECT * FROM public.users
// WHERE id = '086dc120-b958-4017-8897-c393e5282bb6'
// ORDER BY id ASC 

// SELECT * FROM public.company_users
// WHERE user_id = '086dc120-b958-4017-8897-c393e5282bb6'
// ORDER BY id ASC 

// SELECT * FROM public.user_financial
// WHERE user_id = '086dc120-b958-4017-8897-c393e5282bb6'
// ORDER BY id ASC 

// SELECT * FROM public.user_bank_accounts
// WHERE user_id = '086dc120-b958-4017-8897-c393e5282bb6'
// ORDER BY id ASC 

// SELECT * FROM public.user_addresses
// WHERE user_id = '086dc120-b958-4017-8897-c393e5282bb6'
// ORDER BY id ASC 

// SELECT * FROM public.user_attachments
// WHERE user_id = '086dc120-b958-4017-8897-c393e5282bb6'
// ORDER BY id ASC 

// SELECT * FROM public.user_works
// WHERE user_id = '086dc120-b958-4017-8897-c393e5282bb6'
// ORDER BY id ASC 

// SELECT * FROM public.loans
// ORDER BY id ASC 

// SELECT * FROM public.loan_has_users_financial
// ORDER BY id ASC 

// SELECT * FROM public.loans
// WHERE user_works_id = '35c4004f-0cd1-473a-8aa5-9df481172216'
// ORDER BY id ASC 

// SELECT * FROM public.loan_has_users_financial
// WHERE loan_id = '3fa25b55-12c3-4144-8875-e719106fb9cb'
// ORDER BY id ASC 

// SELECT * FROM public.loan_documents
// WHERE loan_id = '3fa25b55-12c3-4144-8875-e719106fb9cb'
// ORDER BY id ASC 

// SELECT id, loan_number, loan_amount, loan_payment_months, loan_interest, loan_monthly_rate, loan_anual_rate, loan_amount_approved, loan_payment_months_approved, loan_amount_pre_approved, loan_payment_months_pre_approved, loan_single_payment, loan_amount_approved_company, loan_payment_month_approved_company loan_status_last FROM public.loans
// ORDER BY id ASC 

// SELECT * FROM public.loan_payments
// WHERE loan_id = '41fa6dc6-42aa-4711-95b9-87a0fee780ef'
// ORDER BY loan_payment_number ASC 

// SELECT * FROM public.batches
// WHERE id = '4d1dc5e0-e441-4b93-923d-a4a0ff31bd9b'
// ORDER BY id ASC 

// SELECT * FROM public.batch_status
// WHERE batch_id = '4d1dc5e0-e441-4b93-923d-a4a0ff31bd9b'
// ORDER BY id ASC 

// SELECT * FROM public.batch_has_loans
// WHERE batch_id = '4d1dc5e0-e441-4b93-923d-a4a0ff31bd9b'
// ORDER BY id ASC 

// SELECT * FROM public.batch_has_loan_payments
// WHERE batch_id = '4d1dc5e0-e441-4b93-923d-a4a0ff31bd9b'
// ORDER BY id ASC 

// DELETE FROM batch_has_loan_payments;
// DELETE FROM batch_has_loans;
// DELETE FROM batch_status;
// DELETE FROM batches;
// DELETE FROM loan_documents;
// DELETE FROM loan_has_product_insurances;
// DELETE FROM loan_has_users_financial;
// DELETE FROM loan_has_users_referrals;
// DELETE FROM loan_payment_statuses;
// DELETE FROM loan_payments;
// DELETE FROM user_financial;
// DELETE FROM loans;
// DELETE FROM user_addresses;
// DELETE FROM user_referrals;
// DELETE FROM user_attachments;
// DELETE FROM user_bank_accounts;
// DELETE FROM user_financial;
// DELETE FROM user_referrals;
// DELETE FROM user_works;
// DELETE FROM company_users;

// -- ceo ismocol
// INSERT INTO public.company_users (user_id, company_id)
// VALUES ('5459528d-e797-47f4-b97f-23b41e2166e0', '7d913f68-e17a-479d-8d5b-db792631db8e');
// -- manager ismocol
// INSERT INTO public.company_users (user_id, company_id)
// VALUES ('544393ca-4e43-475a-9062-faca2540f644', '7d913f68-e17a-479d-8d5b-db792631db8e');
// -- ceo optymus
// INSERT INTO public.company_users (user_id, company_id)
// VALUES ('b7e128a0-a802-4308-808d-0aab4190975a', '9d6bc5f9-fd9b-4c38-aab5-1c21b1b97718');



// ALTER SEQUENCE loans_loan_increment_number_seq RESTART WITH 1;