// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 29/05/2023
// import
import { Component, EventEmitter, ViewChild, OnInit, OnChanges, Input, Output, ElementRef, SimpleChanges} from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
// translate
import { TranslateService } from '@ngx-translate/core';
// services
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
// interfaces
import { UserInterface } from 'src/app/shared/interfaces/user.interface';
import { CompanyInterface } from 'src/app/shared/interfaces/company.interface';
import { OtherCompanyInterface } from 'src/app/shared/interfaces/other-company.interface';
// component
@Component({
  selector: 'app-company-link',
  templateUrl: './company-link.component.html',
  styleUrls: ['./company-link.component.scss']
})
// class
export class CompanyLinkComponent implements OnInit, OnChanges {
  // variables
  @Input() origin: string = null;
  @Input() userData: UserInterface = null;
  @Input() companyData: CompanyInterface = null;
  @Output() loaderEvent = new EventEmitter();
  @Output() saveEvent = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();
  // form data
  public mainFrm: FormGroup;
  savedData: boolean = false;
  // messages data
  formErrorMessages: any = {};
  // help data
  toggleHelp = false;
  // loader data
  loadingMsg: string = null;
  loaderStatus: boolean = false;
  // search data
  typingTimer: any = null;
  // company data
  businessName: string = null;
  companyApproval: boolean = null;
  companyAmountEdit: boolean = null;
  companies: any[] = [];
  company: CompanyInterface = null;
  companyId: string = null;
  companySelected: boolean = false;
  needsCompanySearch: boolean = false;
  needsCompanySelect: boolean = false;
  editCompanyData: boolean = false;
  // company pagination data
  companyLimit: number = 12;
  companyPage: number = 1;
  companyPagesLimit: number = 5;
  companyTotalPages: number = 1;
  companyShowLeft: boolean = false;
  companyShowRight: boolean = false;
  companyArrayPages: any[] = [];
  companyInitPage: number = 1;
  companyFinishPage: number = this.companyPagesLimit;
  // constructor
  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public apiSv: ApiService,
    public filterSv: FilterService,
    public alertSv: AlertService,
  ) {
    // main form
    this.mainFrm = this.formBuilder.group({
      company: [null, [Validators.required]],
    });
  }
  // life cycle
  async ngOnInit() {
    await this.translateMsgs();
    // get data
    await this.getCompanies();
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.companyData) {
      // get data
      await this.getCompanies();
      // check for companyData
      if (this.companyData !== null) {
        // set data
        this.selectCompany(this.companyData.id);
        // set savedData
        this.savedData = true;
      }
    }
  }
  // translate
  async translateMsgs () {
    this.formErrorMessages = {
      company: [
        { type: 'required', message: this.translate.instant(this.origin === 'user' ? 'USER.userCompanyLinkError' : 'PRODUCT.productCompanyLinkError') },
      ],
    };
  }
  // loader
  async updateLoader(loaderMsg: any) {
    this.loaderEvent.emit(loaderMsg);
  }
  // form
  async help () {
    if (this.toggleHelp) {
      this.toggleHelp = false;
    } else {
      this.toggleHelp = true;
    }
  }
  async setFocus(elementId: any) {
    elementId.focus();
  }
  checkFormField(fieldName: string) {
    // get field
    const field = this.mainFrm.get(fieldName);
    // check field
    const check = field?.invalid && field?.touched;
    // return data
    return check;
  }
  // navigation
  async cancel() {
    // emit data
    this.cancelEvent.emit(); // Return dat
  }
  // data
  async getCompanies() {
    return new Promise(async (resolve, reject) => {
      try {
        // show loader
        this.updateLoader(this.translate.instant('HELPERS.loadingTxt') + ' ' + this.translate.instant('COMPANY.companies'));
        // set companiesData
        const companiesData = {
          limit: this.companyLimit, 
          page: this.companyPage,
          companyName: null,
          companyIdentification: null,
          companyStatus: null,
        }
        // get data from api
        await this.apiSv.getCompanies(companiesData).then(async (response: any)=>{
          // get companies
          const companies: any = response.data.result;
          // check if companies has returned data
          if (companies && companies.length > 0) {
            // check more than 15 companies
            if (companies.length > 15) {
              // set needsCompanySearch
              this.needsCompanySearch = true;
            } else {
              // set needsCompanySearch
              this.needsCompanySearch = false;
            }
            // get pages
            const pages = response.data.pages;
            // set total pages
            this.companyTotalPages = pages;
            // clear companies
            this.companies = [];
            // paginator
            this.companyPaginator();
            // loop companies
            await companies.map((company: CompanyInterface) => {
              // push companies
              this.companies.push(company);
            });
          }
          // hide loader
          this.updateLoader(null);
          // resolve
          resolve(true);
        }, error=>{
          console.log('error', error);
          reject(error);
        });
      } catch (error) {
        console.log('error', error);
        reject(error);
      }
    });
  }
  companyPaginator() {
    // check for first page
    if (this.companyPage > 1 && (this.companyTotalPages > this.companyPagesLimit)) {
      this.companyShowLeft = true;
    } else {
      this.companyShowLeft = false;
    }
    // check finish page
    if ((this.companyInitPage + this.companyPagesLimit) > this.companyTotalPages) {
      this.companyShowRight = false;
    } else {
      this.companyShowRight = true;
    }
    // check if pages have to be shown
    if (this.companyTotalPages > 1) {
      // clear array pages
      this.companyArrayPages = [];
      // get init page
      let initPage: number = this.companyInitPage;
      // set limit array
      let arrayLimit = this.companyPagesLimit;
      // check total pages
      if (this.companyTotalPages < this.companyPagesLimit) {
        arrayLimit = this.companyTotalPages;
      }
      // create pages buttons array
      for (let i = 0; i < arrayLimit; i++) {
        // push array
        this.companyArrayPages.push({ page: initPage ++, active: false });
      }
    }
  }
  async companyJumpToPage(page: number) {
    this.companyPage = page;
    // get new data
    await this.getCompanies();
  }
  async companyNavigatePage(direction: string) {
    // get init page
    const initPage = this.companyInitPage;
    // check direction
    if (direction == 'back') {
      this.companyInitPage = initPage - this.companyPagesLimit;
      // check finish page
      if ((this.companyInitPage - this.companyPagesLimit) < this.companyPagesLimit) {
        this.companyFinishPage = this.companyPagesLimit;
      } else {
        this.companyFinishPage = this.companyInitPage - this.companyPagesLimit;
      }
      this.companyPage = this.companyInitPage;
    } else {
      this.companyInitPage = initPage + this.companyPagesLimit;
      // check finish page
      if ((this.companyInitPage + this.companyPagesLimit) > this.companyTotalPages) {
        this.companyFinishPage = this.companyPagesLimit;
      } else {
        this.companyFinishPage = this.companyInitPage + this.companyPagesLimit;
      }
      this.companyPage = this.companyInitPage;
    }
    // get new data
    await this.getCompanies();
  }
  async findCompany(event: any) {
    // reset timeout every time user types
    clearTimeout(this.typingTimer);
    // get company input
    const companyInput = event.target.value;
    // check company input length
    if (companyInput.length >= 3) {
      // wait for company input
      this.typingTimer = setTimeout(() => {
        // check if company is been selected
        if (!this.companySelected) {
          // filter companies
          this.filterCompany(companyInput);
        }
      }, 500);
    }
  }
  async filterCompany(input: string) {
    try {
      // clear companies
      this.companies = [];
      // init company data
      let companyData: any = {
        limit: 100000, 
        page: 1, 
        companyName: input,
        companyIdentification: null,
        companyStatus: null,
      }
      // init data
      const data: any = await this.apiSv.getCompanies(companyData);
      // get result
      const result = data.data.result;
      // check if loans has returned data
      if (result && result.length > 0) {
        // get loans
        result.forEach(async (company: CompanyInterface) => {
          // push data
          this.companies.push(company);
        });
      }
      // init companyOther data
      let companyOtherData: any = {
        limit: 100000, 
        page: 1, 
        companyName: input,
        companyIdentification: null,
        companyStatus: null,
      }
      // init data
      const dataOther: any = await this.apiSv.getOtherCompanies(companyOtherData);
      // get resultOther
      const resultOther = dataOther.data.result;
      // check if loans has returned data
      if (resultOther && resultOther.length > 0) {
        // get loans
        resultOther.forEach(async (company: OtherCompanyInterface) => {
          // add businessName to company
          company.businessName = company.companyName;
          // push data
          this.companies.push(company);
        });
      }
    } catch (error) {
      console.log('error', error);
    }
  }
  async selectCompany(companyId: string) {
    // get companyIndex
    const companyIndex = this.companies.findIndex((company: CompanyInterface) => company.id === companyId);
    // get company
    const company = this.companies[companyIndex];
    // update data
    this.companySelected = true;
    this.company = company;
    this.companyId = company.id;
    this.mainFrm.controls.company.setValue(company.id);
  }
  async resetCompanySearch() {
    this.companySelected = false;
    this.companyId = null;
    this.mainFrm.controls.company.setValue(null);
    this.companies = [];
  }
  // company
  async save() {
    // markAllAsTouched
    this.mainFrm.markAllAsTouched();
    // validate data
    if (!this.mainFrm.valid) {
      // send alert
      this.alertSv.showMessage(this.translate.instant('HELPERS.requiredFields'),'warning', this.translate.instant('HELPERS.warning'), true);
    } else {
      // get companyId
      const companyId = this.mainFrm.controls.company.value;
      // select company
      await this.selectCompany(companyId);
      // emit data
      this.saveEvent.emit(this.company);
    }
  }
}
