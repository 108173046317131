<!-- form header -->
<div class="row mb-4">
  <!-- language -->
  <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
    <ul class="lang-select" *ngIf="false">
      <li class="language-nav" (click)="languageToggle()">
        <app-languages [origin]="'select'"></app-languages>
      </li>
    </ul>
  </div>
  <!-- help -->
  <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 pull-right">
    <div class="btn-with-outside-txt-lbl help-btn" (click)="help()">
      <small class="btn-inner-txt">{{ 'HELPERS.help' | translate }}</small> 
      <a class="btn btn-light btn-xs btn-icon-only btn-icon-text-left">
        <app-feather-icons [icon]="'help-circle'"></app-feather-icons>
      </a>
    </div>
  </div>
</div>
<!-- header -->
<div #otpMsg id="otpMsg" class="row">
  <div *ngIf="otpType === 'forgot'">
    <div class="col-12">
      <h4>{{ 'FORGOT.title' | translate }}</h4>
      <p>{{ 'FORGOT.code' | translate }}</p>
    </div>
  </div>
  <div *ngIf="otpType === 'registerB2C'">
    <div *ngIf="firstName !== null" class="col-12">
      <h4>{{ firstName }}&nbsp;{{ 'OTP.otpMsg1' | translate }}</h4>
      <p>{{ 'OTP.otpMsg2' | translate }}&nbsp;{{ 'OTP.otpMsg3' | translate }}&nbsp;{{ 'OTP.otpMsg4' | translate }}</p>
    </div>
    <div *ngIf="firstName === null" class="col-12">
      <h4>{{ 'OTP.confirmation' | translate }}</h4>
      <p>{{ 'OTP.otpMsg5' | translate }}&nbsp;{{ 'OTP.otpMsg3' | translate }}&nbsp;{{ 'OTP.otpMsg4' | translate }}</p>
    </div>
  </div>
  <div *ngIf="otpType === 'register'">
    <div *ngIf="firstName !== null" class="col-12">
      <h4>{{ firstName }}&nbsp;{{ 'OTP.otpMsg6' | translate }}</h4>
      <p class="mb-0">{{ 'OTP.otpMsg7' | translate }}<br>{{ 'OTP.otpMsg8' | translate }}</p>
    </div>
    <div *ngIf="firstName === null" class="col-12">
      <h4>{{ 'OTP.confirmation' | translate }}</h4>
      <p class="mb-0">{{ 'OTP.otpMsg7' | translate }}<br>{{ 'OTP.otpMsg8' | translate }}</p>
    </div>
  </div>
</div>
<!-- form -->
<form class="mt-4" #otpFormEl id="otpFormEl" [formGroup]="otpForm">
  <!-- code -->
  <div class="row">
    <div class="col">
      <input class="form-control text-center opt-text" type="text" inputmode="text" placeholder="######" maxlength="6" #code id="code" formControlName="code">
      <ng-container class="error-messages" *ngFor="let error of formErrorMessages.code">
        <small class="text-danger error-msg" *ngIf="otpForm.get('code').hasError(error.type) && (otpForm.get('code').dirty || otpForm.get('code').touched)">
          {{ error.message }}.&nbsp;
        </small>
      </ng-container>
      <p class="help-messages" *ngIf="toggleHelp">
        <small>{{ 'OTP.otpHelp' | translate }}</small>
      </p>
    </div>
  </div>
</form>
<!-- buttons -->
<div class="row">
  <div class="col mt-4 text-center">
    <button type="button" (click)="sendOTP()" class="btn btn-primary btn-block">{{ 'HELPERS.send' | translate }}</button>
  </div>
</div>
<!-- resend code -->
<p class="mt-3 mb-0 text-center form-link">
  {{ 'OTP.otpNotRecieve1' | translate }}
  <a (click)="resendCode()" class="btn-link">{{ 'OTP.otpNotRecieve2' | translate }}</a>
</p>
<p class="mt-3 mb-0 text-center form-link">
  {{ 'OTP.otpCheckEmail1' | translate }}&nbsp;{{ email }}&nbsp;{{ 'OTP.otpCheckEmail2' | translate }}
  <a (click)="editEmail()" class="btn-link">{{ 'HELPERS.no' | translate }}</a>
</p>
