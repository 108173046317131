// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 29/05/2023
// import
import { Component, EventEmitter, ViewChild, OnInit, OnChanges, Input, Output, ElementRef, SimpleChanges} from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
// translate
import { TranslateService } from '@ngx-translate/core';
// environment
import { environment } from 'src/environments/environment';
// services
import { AuthService } from 'src/app/shared/services/auth.service';
import { EncodingService } from 'src/app/shared/services/encoding.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { LocationService } from 'src/app/shared/services/location.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
// interfaces
import { UserAddressInterface } from 'src/app/shared/interfaces/user-address.interface';
import { CountryInterface } from 'src/app/shared/interfaces/country.interface';
import { StateInterface } from 'src/app/shared/interfaces/state.interface';
import { CityInterface } from 'src/app/shared/interfaces/city.interface';
// variables
declare var $: any;
import * as moment from 'moment';
import mapboxgl from 'mapbox-gl';
// component
@Component({
  selector: 'app-user-address-form',
  templateUrl: './user-address-form.component.html',
  styleUrls: ['./user-address-form.component.scss']
})
// class
export class UserAddressFormComponent implements OnInit, OnChanges {
  // variables
  @Input() formType: string = null;
  @Input() userAddress: UserAddressInterface = null;
  @Input() mailingUserAddressSelected: boolean = false;
  @Output() loaderEvent = new EventEmitter();
  @Output() saveEvent = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();
  // form data
  public mainFrm: FormGroup;
  // messages data
  formErrorMessages: any = {};
  // help data
  toggleHelp = false;
  // country data
  countries: any[] = [];
  allCountries: any[] = [];
  countryName: string = null;
  countryFlag: string = null;
  countryCode: string = null;
  countryCodeId: string = null;
  countryId: string = null;
  country: CountryInterface = null;
  // state data
  states: any[] = [];
  allStates: any[] = [];
  stateId: string = null;
  stateName: string = null;
  state: StateInterface = null;
  // city data
  cities: any[] = [];
  allCities: any[] = [];
  cityId: string = null;
  cityName: string = null;
  city: CityInterface = null;
  // location data
  loadingPlace: boolean = false;
  addressType: string = null;
  addressTypeSelected: boolean = false;
  loadingLocation: boolean = false;
  @ViewChild('userMap') divMap!: ElementRef;
  @ViewChild('userEditMap') divEditMap!: ElementRef;
  map!: mapboxgl.Map;
  mapEdit!: mapboxgl.Map;
  marker: any = null;
  markerEdit: any = null;
  lng: number= environment.lng;
  lat: number= environment.lat;
  center: [number, number] = [environment.lng, environment.lat];
  gotCords: boolean = false;
  showMap: boolean = false;
  // constructor
  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public authSv: AuthService,
    public encodingSv: EncodingService,
    public apiSv: ApiService,
    public locationSv: LocationService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    private utilitiesSv: UtilitiesService,
  ) {
    // main form
    this.mainFrm = this.formBuilder.group({
      country: [null, [Validators.required]],
      state: [null, [Validators.required]],
      city: [null, [Validators.required]],
      address: [null, [Validators.required]],
      address2: [null],
      mailingAddress: [false, [Validators.required]],
    });
  }
  // life cycle
  async ngOnInit() {
    await this.translateMsgs();
    // get data
    await this.initData();
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.userAddress) {
      // check for userAddress
      if (this.userAddress !== null) {
        // get data
        await this.initData();
        // set data
        await this.setUserAddress();
      }
    }
  }
  async initData() {
    // get data
    await this.getCountries();
    // check formType
    if (this.formType === 'add') {
      // set initial mailingAddress
      const mailingAddress = this.mailingUserAddressSelected ? false : true;
      this.mainFrm.controls.mailingAddress.setValue(mailingAddress);
    }
  }
  // translate
  async translateMsgs () {
    this.formErrorMessages = {
      city: [
        { type: 'required', message: this.translate.instant('LOCATION.formValidation.location.required') },
      ],
      address: [
        { type: 'required', message: this.translate.instant('LOCATION.formValidation.address.required') },
      ],
      mailingAddress: [
        { type: 'required', message: this.translate.instant('LOCATION.formValidation.mailingAddress.required') },
      ],
    };
  }
  // form
  async help () {
    if (this.toggleHelp) {
      this.toggleHelp = false;
    } else {
      this.toggleHelp = true;
    }
  }
  async setFocus(elementId: any) {
    elementId.focus();
  }
  checkFormField(fieldName: string) {
    // get field
    const field = this.mainFrm.get(fieldName);
    // check field
    const check = field?.invalid && field?.touched;
    // return data
    return check;
  }
  // navigation
  async cancel() {
    // emit data
    this.cancelEvent.emit(); // Return dat
  }
  // data
  async setUserAddress() {
    // check data
    if (this.userAddress != null) {
      this.mainFrm.controls.address.setValue(this.userAddress.address);
      this.mainFrm.controls.address2.setValue(this.userAddress.address2);
      this.mainFrm.controls.mailingAddress.setValue(this.userAddress.mailingAddress);
      // update
      this.addressType = this.userAddress.addressType;
      // set addressTypeSelected as true
      this.addressTypeSelected = true;
      // set selects
      this.mainFrm.controls.country.setValue(this.userAddress.countryId);
      await this.selectCountry();
      this.mainFrm.controls.state.setValue(this.userAddress.stateId);
      await this.selectState();
      this.mainFrm.controls.city.setValue(this.userAddress.cityId);
      await this.selectCity();
    }
  }
  // location
  async getCountries() {
    try {
      // get stored data countries
      const storedDataCountries: any = localStorage.getItem('countriesData');
      // set allCountries
      const allCountries: any = [];
      // set countries
      let countries: any;
      // check countries data
      if (!storedDataCountries) {
        // get data from api
        await this.apiSv.getCountries().then((response: any)=>{
          // get countries
          countries = response.data;
          // save countries on storage
          localStorage.setItem('countriesData', JSON.stringify(countries));
          // loop countries
          countries.map((country: CountryInterface) => {
            // push countries
            this.countries.push(country);
            // set countryData
            const countryData = {
              id: country.id,
              countryName: country.countryName
            }
            // push country
            allCountries.push(countryData);
          });
          // sort countries
          const countriesSorted = this.utilitiesSv.sortArrayByKey(allCountries, 'countryName');
          // set countryNullData
          const countryNullData = {
            id: null,
            countryName: this.translate.instant('LOCATION.formPlaceHolders.countrySelect')
          }
          // unshift country
          countriesSorted.unshift(countryNullData);
          // update all countries
          this.allCountries = countriesSorted;
        }, error=>{
          console.log('error', error);
        });
      } else {
        // get countries
        countries = JSON.parse(storedDataCountries);
        // loop countries
        countries.map((country: CountryInterface) => {
          // push countries
          this.countries.push(country);
          // set countryData
          const countryData = {
            id: country.id,
            countryName: country.countryName
          }
          // push country
          allCountries.push(countryData);
        });
        // sort countries
        const countriesSorted = this.utilitiesSv.sortArrayByKey(allCountries, 'countryName');
        // set countryNullData
        const countryNullData = {
          id: null,
          countryName: this.translate.instant('LOCATION.formPlaceHolders.countrySelect')
        }
        // unshift country
        countriesSorted.unshift(countryNullData);
        // update all countries
        this.allCountries = countriesSorted;
      }
    } catch (error: any) {
      console.log('error', error);
    }
  }
  async selectCountry() {
    // get data
    const countryId: any = this.mainFrm.controls.country.value;
    const countryIndex: any = this.countries.findIndex((country: any) => country.id === countryId); 
    const country: CountryInterface = this.countries[countryIndex];
    // set birthDate country
    this.countryId = country.id;
    this.countryName = country.countryName;
    this.country = country;
    // clear birthDate states
    this.stateId = null;
    this.stateName = null;
    this.state = null;
    // clear birthDate cities
    this.cityId = null;
    this.cityName = null;
    this.city = null;
    // load states
    await this.getStates(country.id);
  }
  async getStates(countryId: string) {
    try {
      // show location loader
      this.loadingLocation = true;
      // get data
      const states: any = await this.apiSv.getStates(countryId);
      // set allStates
      const allStates: any = [];
      // loop states
      states.data.forEach((state: StateInterface) => {
        // push states
        this.states.push(state);
        // set stateData
        const stateData = {
          id: state.id,
          stateName: state.name
        }
        // push state
        allStates.push(stateData);
      });
      // sort states
      const birthPlaceStatesSorted = this.utilitiesSv.sortArrayByKey(allStates, 'stateName');
      // update all states
      this.allStates = birthPlaceStatesSorted;
      // hide location loader
      this.loadingLocation = false;
    } catch (error: any) {
      console.log('error', error);
      // hide location loader
      this.loadingLocation = false;
    }
  }
  async selectState() {
    // get data
    const stateId: any = this.mainFrm.controls.state.value;
    const stateIndex: any = this.states.findIndex((state: any) => state.id === stateId); 
    const state: any = this.states[stateIndex];
    // set birthDate state
    this.stateId = state.id;
    this.stateName = state.name;
    this.state = state;
    // clear birthDate cities
    this.cityId = null;
    this.cityName = null;
    this.city = null;
    // load states
    await this.getCities(state.id);
  }
  async getCities(stateId: string) {
    try {
      // show location loader
      this.loadingLocation = true;
      // get data
      const cities: any = await this.apiSv.getCities(stateId);
      // set allCities
      const allCities: any = [];
      // loop cities
      cities.data.forEach((city: CityInterface) => {
        // push cities
        this.cities.push(city);
        // set birthPlaceCityData
        const birthPlaceCityData = {
          id: city.id,
          cityName: city.name
        }
        // push city
        allCities.push(birthPlaceCityData);
      });
      // sort cities
      const citiesSorted = this.utilitiesSv.sortArrayByKey(allCities, 'cityName');
      // update all cities
      this.allCities = citiesSorted;
      // hide location loader
      this.loadingLocation = false;
    } catch (error: any) {
      console.log('error', error);
      // hide location loader
      this.loadingLocation = false;
    }
  }
  async selectCity() {
    // get data
    const cityId: any = this.mainFrm.controls.city.value;
    const cityIndex: any = this.cities.findIndex((state: any) => state.id === cityId); 
    const city: any = this.cities[cityIndex];
    // set birthDate state
    this.cityId = city.id;
    this.cityName = city.name;
    this.city = city;
  }
  async resetLocationSelect() {
    // set country
    this.countryId = null;
    this.country = null;
    this.countryName = null;
    // clear states
    this.stateId = null;
    this.state = null;
    this.stateName = null;
    // clear cities
    this.cityId = null;
    this.city = null;
    this.cityName = null;
    // clear form data
    this.mainFrm.controls.country.setValue(null);
    this.mainFrm.controls.state.setValue(null);
    this.mainFrm.controls.city.setValue(null);
  }
  // actions
  setAddressType(addressType: string) {
    // update
    this.addressType = addressType;
    // set addressTypeSelected as true
    this.addressTypeSelected = true;
  }
  async save() {
    // markAllAsTouched
    this.mainFrm.markAllAsTouched();
    // validate data
    if (!this.mainFrm.valid) {
      // send alert
      this.alertSv.showMessage(this.translate.instant('HELPERS.requiredFields'),'warning', this.translate.instant('HELPERS.warning'), true);
    } else {
      // check addressType
      if (this.addressType === null) {
        // send alert
        this.alertSv.showMessage(this.translate.instant('LOCATION.formValidation.addressType.required'),'error', this.translate.instant('HELPERS.error'), true);
      } else {
        // check formType
        if (this.formType === 'add') {
          // add userAddress
          await this.addUserAddress();
        } else {
          // update userAddress
          await this.updateUserAddress();
        }
      }
    }
  }
  async addUserAddress() {
    try {
      // set userAddressData
      const userAddressData: UserAddressInterface = {
        addressType: this.addressType,
        cityId: this.city.id,
        cityName: this.city.name,
        stateId: this.state.id,
        stateName: this.state.name,
        countryId: this.country.id,
        countryName: this.country.countryName,
        address: this.mainFrm.controls.address.value,
        address2: this.mainFrm.controls.address2.value,
        mailingAddress: this.mainFrm.controls.mailingAddress.value
      }
      // emit data
      this.saveEvent.emit(userAddressData);
    } catch (error) {
      console.log('error', error);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async updateUserAddress() {
    try {
      // set userAddressData
      const userAddressData: UserAddressInterface = {
        id: this.userAddress.id,
        addressType: this.addressType,
        cityId: this.city.id,
        cityName: this.city.name,
        stateId: this.state.id,
        stateName: this.state.name,
        countryId: this.country.id,
        countryName: this.country.countryName,
        address: this.mainFrm.controls.address.value,
        address2: this.mainFrm.controls.address2.value,
        mailingAddress: this.mainFrm.controls.mailingAddress.value
      }
      // emit data
      this.saveEvent.emit(userAddressData);
    } catch (error) {
      console.log('error', error);
      // handle error
      this.filterSv.handleError(error);
    }
  }
}
