<div class="container-fluid">
  <div class="page-title">
    <div class="row">
      <!-- title -->
      <div class="col">
        <h3>{{ title | translate }}</h3>
      </div>
      <!-- breadcrumbs -->
      <div class="col">
        <ol class="breadcrumb">
          <!-- breadcrumbs -->
          <li class="breadcrumb-item" *ngFor="let breadcrumb of breadcrumbs"  [ngClass]="{'hide-breadcrumb': breadcrumb.baseUrl == currentPageBaseUrl}">
            <!-- pages -->
            <a *ngIf="breadcrumb.baseUrl != currentPageBaseUrl" class="btn-link" (click)="goToPage(breadcrumb.url)">
              <!-- other page -->
              <span *ngIf="breadcrumb.baseUrl != '/'">{{ breadcrumb.title | translate }}</span>
              <!-- home page -->
              <app-feather-icons *ngIf="breadcrumb.baseUrl == '/'" class="btn-icon" [icon]="'home'"></app-feather-icons>
            </a>
            <!-- current page -->
            <span *ngIf="breadcrumb.baseUrl == currentPageBaseUrl && breadcrumb.baseUrl != '/'">
              <span *ngIf="breadcrumb.baseUrl != '/'">{{ currentPageTitle | translate }}</span>
            </span>
          </li>
        </ol>
      </div>
    </div>
  </div>
</div>