<div class="container-fluid">
  <div class="row widget-grid">
    <div class="col-xxl-4 col-xl-5 col-md-6 col-sm-12">
      <app-dashboard-welcome 
        [userData]="user" 
        [totalRequests]="totalRequests">
      </app-dashboard-welcome>
    </div>
    <div class="col-xxl-8 col-xl-7 col-md-6 col-sm-12">
      <!-- card -->
      <div class="card">
        <!-- header -->
        <div class="card-header card-no-border mt-4">
          <div class="header-top">
            <div class="row">
              <div class="col-12">
                <h5 class="test-title-css loan-work-activities-title">{{ 'REPORT.loanTop5' | translate }}</h5>
              </div>
            </div>
            <div class="card-header-right-icon" *ngIf="loans.length > 0">
              <div class="dropdown">
                <button class="btn dropdown-toggle" type="button">{{ 'REPORT.loanViewAll' | translate }}</button>
              </div>
            </div>
          </div>
        </div>
        <!-- body -->
        <div class="card-body">
          <!-- data -->
          <div class="row" *ngIf="loans.length > 0">
            <div class="col-12">
              <app-loan-list-table
                [loansData]="loans"
                [isClient]="isClient"
                [isAdmin]="isAdmin"
                [isBoard]="isBoard"
                [isBusinessAgent]="isBusinessAgent"
                [isTreasure]="isTreasure"
                [isCompanyAdmin]="isCompanyAdmin"
                [isCompanyAuxiliar]="isCompanyAuxiliar"
                (openLoanEvent)="openLoan($event)"
                (openUserEvent)="openUser($event)"
                (loaderEvent)="updateLoader($event)">
              </app-loan-list-table>
            </div>
          </div>
          <!-- no data -->
          <div class="row" *ngIf="loans.length === 0">
            <div class="no-data-container inner-container">
              <app-no-data [msg]="'REPORT.loansEmpty' | translate"></app-no-data>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


