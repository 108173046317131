import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
// injectable
@Injectable({
  providedIn: 'root'
})
// class
export class LocationService {
  // variables
  private _countries = 'assets/locations/countries.json';
  private _states = 'assets/locations/states.json';
  private _cities = 'assets/locations/cities.json';
  // constructor
  constructor(private http: HttpClient) { }
  // life page cycle
  ngOnInit() {}
  public getCountries() {
    return firstValueFrom(this.http.get(this._countries));
  }
  public getStates() {
    return firstValueFrom(this.http.get(this._states));
  }
  public getCities() {
    return firstValueFrom(this.http.get(this._cities));
  }
  public getLocation(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp=> {
        resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
      });
    });
  }
  public handleGPSPermission() {
    return new Promise((resolve, reject) => {
      try {
        if ( navigator.permissions && navigator.permissions.query) {
          //try permissions APIs first
          navigator.permissions.query({ name: 'geolocation' }).then((result: any) => {
            resolve(result.state);
          });
        } else if (navigator.geolocation) {
          resolve('granted');
        }
      } catch (error) {
        reject(error);
      }
    });
  }
}
