// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 25/05/2023
// tslint:disable: max-line-length
// tslint:disable: no-unused-expression
// import
import { Pipe, PipeTransform } from '@angular/core';
// pipe
@Pipe({
  name: 'bankAccountNumberPipe'
})
// class
export class bankAccountNumberPipe implements PipeTransform {
  // transform
  transform(value: string): any {
    if (value) {
      return value.replace(/(\d{3})(\d{6})(\d{2})/, '$1-$2-$3');
    } else {
      return null;
    }
  }
}
