<div class="file-top-inner loan-file" [id]="docType + '-' + fileId">
  <img class="loan-document-type not-loaded" *ngIf="fileName === null" [src]="image" onError="src = 'assets/images/broken-image-slim.png'">
  <img class="loan-document-type loaded" *ngIf="fileName !== null && fileType === 'image'" [src]="filePath" onError="src = 'assets/images/broken-image-slim.png'">
  <img class="loan-document-type loaded-pdf" *ngIf="fileName !== null && fileType === 'pdf'" src="assets/images/documents/icon-document-pdf.png" onError="src = 'assets/images/broken-image-slim.png'">
  <div class="file-controls">
    <button class="btn btn-icon-only btn-add btn-file-control" (click)="sendAction('add')" *ngIf="fileName === null && editEnable">
      <app-feather-icons [icon]="'upload-cloud'"></app-feather-icons>
    </button>
    <button class="btn btn-icon-only btn-edit btn-file-control" (click)="sendAction('edit')" *ngIf="fileName !== null && editEnable">
      <app-feather-icons [icon]="'edit-2'"></app-feather-icons>
    </button>
    <button class="btn btn-icon-only btn-zoom btn-file-control" (click)="sendAction('zoom')" *ngIf="fileName !== null && fileType === 'image' && viewEnable">
      <app-feather-icons [icon]="'zoom-in'"></app-feather-icons>
    </button>
    <button class="btn btn-icon-only btn-zoom btn-file-control" (click)="sendAction('open')" *ngIf="fileName !== null && fileType === 'pdf'">
      <app-feather-icons [icon]="'external-link'"></app-feather-icons>
    </button>
    <button class="btn btn-icon-only btn-delete btn-file-control" (click)="sendAction('delete')" *ngIf="fileName !== null && editEnable">
      <app-feather-icons [icon]="'trash'"></app-feather-icons>
    </button>
  </div>
</div>
