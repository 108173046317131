// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 03/10/2023
// import
import { OnInit, OnChanges, SimpleChanges, Component, Input } from "@angular/core";
// translate
import { TranslateService } from '@ngx-translate/core';
// services
import { ApiService } from 'src/app/shared/services/api.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
// interfaces
import { CompanyInterface } from "src/app/shared/interfaces/company.interface";
export interface Balance {
  icon: string;
  title: string;
  price: string;
  growth: string;
  colorClass: string;
  show?: boolean;
}
@Component({
  selector: "app-loan-products",
  templateUrl: "./loan-products.component.html",
  styleUrls: ["./loan-products.component.scss"],
})
export class LoanProductsComponent implements OnInit, OnChanges {
  // variables
  @Input() companyData: CompanyInterface = null;
  // loader
  showLoader: boolean = false;
  loaderMsg: string = null;
  // time query
  public showTimeQuery: boolean = false;
  timeQueryOptions: any[] = ['today', 'week', 'month', 'trimester', 'semester', 'year']
  selectedTimeQuery: string = 'week';
  selectedCustomTimeQuery: boolean = false;
  selectedDateStart: string = null;
  selectedDateEnd: string = null;
  selectedTimeStart: string = null;
  selectedTimeEnd: string = null;
  // loans data
  loanProductsChartData: any = null;
  loanTotalsData: any = null;
  dataLoaded: boolean = false;
  // constructor
  constructor(
    public translate: TranslateService,
    public apiSv: ApiService,
    private utilitiesSv: UtilitiesService,
  ) {}
  // life cycle
  async ngOnInit() {
    await this.setTimeQuery('week');
    await this.getLoanProductsData();
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.companyData) {
      // check company data
      if (this.companyData !== changes.companyData.currentValue) {

        console.log('++++ this.companyData', this.companyData);

        if (!this.selectedCustomTimeQuery) {
          await this.setTimeQuery('week');
          await this.getLoanProductsData();
        }
      }
    }
  }
  // actions
  toggleTimeQuery() {
    this.showTimeQuery = !this.showTimeQuery;
  }
  async setCustomTimeQuery(timeQueryOption: string) {
    this.selectedCustomTimeQuery = true;
    await this.setTimeQuery(timeQueryOption);
  }
  async setTimeQuery(timeQueryOption: string) {
    // update selectedTimeQuery
    this.selectedTimeQuery = timeQueryOption;
    // get selected time
    const selectedTime = this.utilitiesSv.getTimeQuery(timeQueryOption);
    // update times
    this.selectedDateStart = selectedTime.dateStart;
    this.selectedDateEnd = selectedTime.dateEnd;
    this.selectedTimeStart = selectedTime.timeStart;
    this.selectedTimeEnd = selectedTime.timeEnd;
    // check timeQueryOption
    if (timeQueryOption !== this.selectedTimeQuery) {
      // toggle time query
      this.toggleTimeQuery();
    }
  }
  // data
  async getLoanProductsData() {
    return new Promise(async (resolve, reject) => {
      try {
        // show loader
        this.showLoader = true;
        this.loaderMsg = this.translate.instant('HELPERS.loadingTxt') + ' ' + this.translate.instant('REPORT.loanProducts');
        // get loanProductsData
        const loanProductsData = {
          companyId: this.companyData !== null ? this.companyData.id : null,
          timeQueryOption: this.selectedTimeQuery,
          selectedDateStart: this.selectedDateStart,
          selectedDateEnd: this.selectedDateEnd,
          selectedTimeStart: this.selectedTimeStart,
          selectedTimeEnd: this.selectedTimeEnd,
        }

        console.log('>>>> this.companyData', this.companyData);
        console.log('loanProductsData', loanProductsData);

        // get data from api
        await this.apiSv.getLoanProducts(loanProductsData).then(async (loanProductsResponse: any)=>{
          
          console.log('loanProductsResponse', loanProductsResponse);
          
          // check data
          if (loanProductsResponse.data) {
            // get loanProductsData
            const loanProductsData = loanProductsResponse.data.products;
            // init loanProducts data
            const loanProductSeries: any[] = [];
            const loanProductStrokes: any[] = [];
            const loanProductColors: any[] = this.utilitiesSv.getColorsByRepetitions(loanProductsData.length);
            const loanProductLabels: any[] = this.utilitiesSv.getTimeLabels(this.selectedTimeQuery);
            const loanProductMarkers: any[] = [];
            // loop loanProducts
            for (let index = 0; index < loanProductsData.length; index++) {
              // get loanProduct
              const loanProduct: any = loanProductsData[index];
              // get productData
              const productData: any[] = [];
              // loop productData
              for (let indexProduct = 0; indexProduct < loanProduct.productData.length; indexProduct++) {
                // get productItem
                const productItem = loanProduct.productData[indexProduct];
                // push productData
                productData.push(productItem.total_approved);
              }
              // get loanProductSerial
              const loanProductSerial = {
                name: loanProduct.productName,
                type: 'area',
                data: productData,
              }
              // push serial to series
              loanProductSeries.push(loanProductSerial);
              // push strokes
              loanProductStrokes.push(2);
              // loop productData
              for (let productIndex = 0; productIndex < productData.length; productIndex++) {
                // get loan product marker
                const loanProductMarker = {
                  seriesIndex: index,
                  dataPointIndex: productIndex,
                  fillColor: loanProductColors[index],
                  strokeColor: "#FFFFFF",
                  size: 5,
                  sizeOffset: 3,
                };
                // push loan product marker
                loanProductMarkers.push(loanProductMarker);
              }
            };
            // get loanProductsChartData
            const loanProductsChartData: any = {
              series: loanProductSeries,
              chart: {
                height: 300,
                type: "line",
                stacked: false,
                toolbar: {
                  show: false,
                },
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  blur: 4,
                  color: "#0c0a11",
                  opacity: 0.08,
                },
              },
              stroke: {
                width: loanProductStrokes,
                curve: "smooth",
              },
              grid: {
                show: true,
                borderColor: "#d9d9eb",
                strokeDashArray: 0,
                position: "back",
                xaxis: {
                  lines: {
                    show: true,
                  },
                },
                padding: {
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                },
              },
              plotOptions: {
                bar: {
                  columnWidth: "50%",
                },
              },
              colors: loanProductColors,
              fill: {
                type: "gradient",
                gradient: {
                  shade: "light",
                  type: "vertical",
                  opacityFrom: 0.4,
                  opacityTo: 0,
                  stops: [0, 100],
                },
              },
              labels: loanProductLabels,
              markers: {
                discrete: loanProductMarkers,
                hover: {
                  size: 5,
                  sizeOffset: 0,
                },
              },
              xaxis: {
                type: "category",
                tickAmount: 4,
                tickPlacement: "between",
                tooltip: {
                  enabled: false,
                },
                axisBorder: {
                  color: "#d9d9eb",
                },
                axisTicks: {
                  show: false,
                },
              },
              legend: {
                show: false,
              },
              yaxis: {
                min: 0,
                tickAmount: 6,
                tickPlacement: "between",
                offsetX: 4,
                labels: {
                  formatter: (value: any) => {
                    return '$' + this.utilitiesSv.convertStringToCurrency(value.toString());
                  }
                }
              },
              tooltip: {
                shared: false,
                intersect: false,
              },
              responsive: [
                {
                  breakpoint: 1200,
                  options: {
                    chart: {
                      height: 250,
                    },
                  },
                },
              ],
            };
            // update loanProductsChartData
            this.loanProductsChartData = loanProductsChartData;
            // get totals
            const loanTotalDisbursedData: number = loanProductsResponse.data.totalDisbursed;
            const loanTotalPayedData: number = loanProductsResponse.data.totalPayed;
            const loanTotalDifference: number = loanTotalPayedData - loanTotalDisbursedData;
            const loanTotals: Balance[] = [
              {
                icon: "expense",
                title: this.translate.instant('REPORT.totalDisbursed'),
                price: '$' + this.utilitiesSv.convertStringToCurrency(loanTotalDisbursedData.toString()),
                growth: "",
                colorClass: "success",
              },
              {
                icon: "income",
                title: this.translate.instant('REPORT.totalPayed'),
                price: '$' + this.utilitiesSv.convertStringToCurrency(loanTotalPayedData.toString()),
                growth: "",
                colorClass: "success",
              },
              {
                icon: "doller-return",
                title: this.translate.instant('REPORT.totalDifference'),
                price: '$' + this.utilitiesSv.convertStringToCurrency(loanTotalDifference.toString()),
                growth: (loanTotalDifference < 0 ? '-' : '+') + ((this.utilitiesSv.roundNumber(((loanTotalPayedData / loanTotalDisbursedData) * 100), 'up', 2)) + '%').toString(),
                colorClass: (loanTotalDifference < 0 ? 'danger' : 'success'),
              },
            ];
            // update loanTotals
            this.loanTotalsData = loanTotals;
            // update dataLoaded
            this.dataLoaded = true;
          }
          // resolve
          resolve(true);
        }, error=>{
          console.log('error', error);
          reject(error);
        });
      } catch (error) {
        console.log('error', error);
        // hide loader
        this.showLoader = false;
        this.loaderMsg = null;
        reject(error);
      }
    });
  }
}
