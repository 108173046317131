// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 03/06/2023
// import
import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
// translate
import { TranslateService } from '@ngx-translate/core';
// plugins
import Swal from 'sweetalert2';
// services
import { BreadcrumbsService } from 'src/app/shared/services/breadcrumbs.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
// interfaces
import { ProductInterface } from 'src/app/shared/interfaces/product.interface';
// component
@Component({
  selector: 'app-products-card',
  templateUrl: './products-card.component.html',
  styleUrls: ['./products-card.component.scss']
})
// class
export class ProductsCardComponent implements OnInit, OnChanges {
  // variables
  @Input() productsData: any = null;
  @Input() deleteEnable: boolean = false;
  @Output() loaderEvent = new EventEmitter();
  @Output() openEvent = new EventEmitter();
  @Output() editEvent = new EventEmitter();
  @Output() deleteEvent = new EventEmitter();
  // products data
  products: any[] = [];
  // constructor
  constructor(
    public router: Router,
    public translate: TranslateService,
    public breadcrumbSv: BreadcrumbsService,
    public apiSv: ApiService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    private utilitiesSv: UtilitiesService,
  ) {
  }
  // life cycle
  async ngOnInit() {
    await this.setProducts();
  }
  async ngOnChanges(changes: SimpleChanges) {
    try {
      if (changes.productsData) {
        await this.setProducts();
      }
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  // loader
  async updateLoader(loaderMsg: any) {
    this.utilitiesSv.updateLoader(loaderMsg);
  }
  // data
  async setProducts() {
    if (this.productsData !== null) {
      // update products
      this.products = this.productsData;
    }
  }
  openProduct(product: ProductInterface) {
    // emit data
    this.openEvent.emit(product);
  }
  deleteProduct(product: ProductInterface) {
    // show alert
    Swal.fire({
      icon: 'error',
      title: this.translate.instant('PRODUCT.productDelete'),
      text: this.translate.instant('PRODUCT.productDeleteAsk'),
      confirmButtonText: this.translate.instant('HELPERS.yes'),
      showCancelButton: true,
      cancelButtonText: this.translate.instant('HELPERS.no'),
      allowOutsideClick: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        // emit data
        this.deleteEvent.emit(product);
      }
    });
  }
}
