// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 03/10/2023
// import
import { Component, Input } from "@angular/core";
// translate
import { TranslateService } from '@ngx-translate/core';
// plugins
import { ApexAnnotations, ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexGrid, ApexLegend, ApexMarkers, ApexNonAxisChartSeries, ApexPlotOptions, ApexResponsive, ApexStroke, ApexTheme, ApexTitleSubtitle, ApexXAxis, ApexYAxis } from "ng-apexcharts";
// services
import { ApiService } from 'src/app/shared/services/api.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
// interfaces
import { CompanyInterface } from "src/app/shared/interfaces/company.interface";
export type ChartOptions = {
  series?: ApexAxisChartSeries;
  chart?: ApexChart;
  xaxis?: ApexXAxis;
  stroke?: ApexStroke;
  tooltip?: any;
  dataLabels?: ApexDataLabels;
  yaxis?: ApexYAxis;
  legend?: ApexLegend;
  labels?: string[];
  plotOptions?: ApexPlotOptions;
  fill?: ApexFill;
  responsive?: ApexResponsive[];
  pieseries?: ApexNonAxisChartSeries;
  title?: ApexTitleSubtitle;
  theme?: ApexTheme;
  colors?: string[];
  markers?: ApexMarkers;
  annotations?: ApexAnnotations;
  grid?: ApexGrid;
};
@Component({
  selector: 'app-loan-approved-vs-requested',
  templateUrl: './loan-approved-vs-requested.component.html',
  styleUrls: ['./loan-approved-vs-requested.component.scss']
})
export class LoanApprovedVsRequestedComponent {
  // variables
  @Input() companyData: CompanyInterface = null;
  // loader
  showLoader: boolean = false;
  loaderMsg: string = null;
  // time query
  public showTimeQuery: boolean = false;
  timeQueryOptions: any[] = ['today', 'week', 'month', 'trimester', 'semester', 'year']
  selectedTimeQuery: string = 'week';
  selectedDateStart: string = null;
  selectedDateEnd: string = null;
  selectedTimeStart: string = null;
  selectedTimeEnd: string = null;
  // loans data
  loanRequestVsApprovedData: any = null;
  dataLoaded: boolean = false;
  // constructor
  constructor(
    public translate: TranslateService,
    public apiSv: ApiService,
    private utilitiesSv: UtilitiesService,
  ) {}
  // life cycle
  async ngOnInit() {
    await this.setTimeQuery('week');
  }
  // actions
  toggleTimeQuery() {
    this.showTimeQuery = !this.showTimeQuery;
  }
  async setTimeQuery(timeQueryOption: string) {
    // update selectedTimeQuery
    this.selectedTimeQuery = timeQueryOption;
    // get selected time
    const selectedTime = this.utilitiesSv.getTimeQuery(timeQueryOption);
    // update times
    this.selectedDateStart = selectedTime.dateStart;
    this.selectedDateEnd = selectedTime.dateEnd;
    this.selectedTimeStart = selectedTime.timeStart;
    this.selectedTimeEnd = selectedTime.timeEnd;
    await this.getLoanRequestedVsApprovedData();
    // check timeQueryOption
    if (timeQueryOption !== this.selectedTimeQuery) {
      // toggle time query
      this.toggleTimeQuery();
    }
  }
  // data
  async getLoanRequestedVsApprovedData() {
    return new Promise(async (resolve, reject) => {
      try {
        // show loader
        this.showLoader = true;
        this.loaderMsg = this.translate.instant('HELPERS.loadingTxt') + ' ' + this.translate.instant('REPORT.loanApprovedVsRequested');
        // get loanRequestVsApprovedData
        const loanRequestVsApprovedData = {
          companyId: this.companyData !== null ? this.companyData.id : null,
          timeQueryOption: this.selectedTimeQuery,
          selectedDateStart: this.selectedDateStart,
          selectedDateEnd: this.selectedDateEnd,
          selectedTimeStart: this.selectedTimeStart,
          selectedTimeEnd: this.selectedTimeEnd,
        }
        // get data from api
        await this.apiSv.getLoanRequestedVsApproved(loanRequestVsApprovedData).then(async (loanRequestVsApprovedResponse: any)=>{
          
          console.log('loanRequestVsApprovedResponse', loanRequestVsApprovedResponse);

          // check data
          if (loanRequestVsApprovedResponse.data) {
            // get loanRequestVsApprovedData
            const loanRequestVsApprovedData: any = loanRequestVsApprovedResponse.data;
            const loanRequested: any[] = loanRequestVsApprovedData.requested;
            const loanApproved: any[] = loanRequestVsApprovedData.approved;
            const loanLabels: any[] = this.utilitiesSv.getTimeLabels(this.selectedTimeQuery);
            // get loanRequestVsApproved
            let loanRequestVsApproved: ChartOptions | any = {
              series: [
                {
                  name: this.translate.instant('REPORT.loanRequest'),
                  data: loanRequested,
                },
                {
                  name: this.translate.instant('REPORT.loanApproved'),
                  data: loanApproved,
                },
              ],
              chart: {
                type: "bar",
                height: 300,
                stacked: true,
                toolbar: {
                  show: false,
                },
                dropShadow: {
                  enabled: true,
                  top: 8,
                  left: 0,
                  blur: 10,
                  color: "#FFCC11",
                  opacity: 0.1,
                },
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: "10px",
                  borderRadius: 0,
                },
              },
              grid: {
                show: true,
                borderColor: "#d9d9eb",
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                width: 2,
                dashArray: 0,
                lineCap: "butt",
                colors: "#fff",
              },
              fill: {
                opacity: 1,
              },
              legend: {
                show: false,
              },
              states: {
                hover: {
                  filter: {
                    type: "darken",
                    value: 1,
                  },
                },
              },
              colors: ['#FFCC11', '#d9d9eb'],
              yaxis: {
                tickAmount: 3,
                labels: {
                  show: true,
                  style: {
                    fontFamily: "Montserrat, sans-serif",
                  },
                },
                axisBorder: {
                  show: false,
                },
                axisTicks: {
                  show: false,
                },
              },
              xaxis: {
                categories: loanLabels,
                labels: {
                  style: {
                    fontFamily: "Montserrat, sans-serif",
                  },
                },
                axisBorder: {
                  show: false,
                },
                axisTicks: {
                  show: false,
                },
              },
              responsive: [
                {
                  breakpoint: 1661,
                  options: {
                    chart: {
                      height: 290,
                    },
                  },
                },
                {
                  breakpoint: 767,
                  options: {
                    plotOptions: {
                      bar: {
                        columnWidth: "35px",
                      },
                    },
                    yaxis: {
                      labels: {
                        show: false,
                      },
                    },
                  },
                },
                {
                  breakpoint: 481,
                  options: {
                    chart: {
                      height: 200,
                    },
                  },
                },
                {
                  breakpoint: 420,
                  options: {
                    chart: {
                      height: 170,
                    },
                    plotOptions: {
                      bar: {
                        columnWidth: "40px",
                      },
                    },
                  },
                },
              ],
            };
            // update loanRequestVsApproved
            this.loanRequestVsApprovedData = loanRequestVsApproved;
            // update dataLoaded
            this.dataLoaded = true;
          }
          // resolve
          resolve(true);
        }, error=>{
          console.log('error', error);
          reject(error);
        });
      } catch (error) {
        console.log('error', error);
        // hide loader
        this.showLoader = false;
        this.loaderMsg = null;
        reject(error);
      }
    });
  }
}