<!-- header -->
<div #otpMsg id="pinCodeMsg" class="row">
  <!-- intro -->
  <div class="col-12 mb-2">
    <h4 class="register-title text-center mb-2">{{ 'UNLOCK.lock' | translate }}</h4>
    <p>{{ firstName }},&nbsp;{{ 'UNLOCK.unlockMsg2' | translate }}&nbsp;{{ unlockTimeMsg }}.</p>
  </div>
</div>
<!-- code -->
<form #unlockFrmEl id="unlockFrmEl" [formGroup]="unlockFrm">
  <div class="row">
    <div class="col-12">
      <div class="form-group code-input">
        <input class="form-control text-center" #pincode id="pincode" [type]="showCode ? 'text' : 'password'" inputmode="numeric" formControlName="pincode" placeholder="******"  (keyup.enter)="saveCode()">
        <div class="show-hide" (click)="togleCode()">
          <app-feather-icons *ngIf="!showCode" [icon]="'eye'"></app-feather-icons>
          <app-feather-icons *ngIf="showCode"  [icon]="'eye-off'"></app-feather-icons>
        </div>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.pincode">
          <small class="text-danger" *ngIf="unlockFrm.get('pincode').hasError(error.type) && (unlockFrm.get('pincode').dirty || unlockFrm.get('pincode').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
      </div>
    </div>
  </div>
</form>
<!-- buttons -->
<div class="row">
  <div class="col mt-2 text-center">
    <button type="button" (click)="saveCode()" class="btn btn-primary btn-block">{{ 'HELPERS.save' | translate }}</button>
    <button *ngIf="origin === 'profile'" type="button" (click)="cancelCode()" class="btn btn-tertiary btn-block mt-3">{{ 'HELPERS.cancel' | translate }}</button>
  </div>
</div>
