// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 25/05/2023
// tslint:disable: max-line-length
// tslint:disable: no-unused-expression
// import
import { AngularEditorConfig } from '@kolkov/angular-editor';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// editor global config en
export const editorGlobalConfigEn: AngularEditorConfig = {
	editable: true,
	spellcheck: true,
	height: 'auto',
	minHeight: '200px',
	maxHeight: 'auto',
	width: 'auto',
	minWidth: '100%',
	translate: 'yes',
	enableToolbar: true,
	showToolbar: true,
	placeholder: 'Description',
	defaultParagraphSeparator: '',
	defaultFontSize: '2',
	fonts: [
		{ class: 'lato-font', name: 'Normal' },
		{ class: 'lato-light-font', name: 'Light' },
	],
	customClasses: [
		{
			name: 'Title',
			class: 'lead-txt',
		},
		{
			name: 'Subtitle',
			class: 'sub-txt'
		},
		{
			name: 'Paragraph',
			class: 'paragraph-txt'
		},
	],
	sanitize: true,
	toolbarPosition: 'top',
	toolbarHiddenButtons: [
		[
			'insertImage',
			'insertVideo',
		]
	]
};
// editor global config es
export const editorGlobalConfigEs: AngularEditorConfig = {
	editable: true,
	spellcheck: true,
	height: 'auto',
	minHeight: '200px',
	maxHeight: 'auto',
	width: 'auto',
	minWidth: '100%',
	translate: 'yes',
	enableToolbar: true,
	showToolbar: true,
	placeholder: 'Descripción',
	defaultParagraphSeparator: '',
	defaultFontSize: '2',
	fonts: [
		{ class: 'lato-font', name: 'Normal' },
		{ class: 'lato-light-font', name: 'Ligera' },
	],
	customClasses: [
		{
			name: 'Título',
			class: 'lead-txt',
		},
		{
			name: 'Subtítulo',
			class: 'sub-txt'
		},
		{
			name: 'Párrafo',
			class: 'paragraph-txt'
		},
	],
	sanitize: true,
	toolbarPosition: 'top',
	toolbarHiddenButtons: []
};
// editor global config en
export const editorObservationGlobalConfigEn: AngularEditorConfig = {
	editable: true,
	spellcheck: true,
	height: 'auto',
	minHeight: '100px',
	maxHeight: 'auto',
	width: 'auto',
	minWidth: '100%',
	translate: 'yes',
	enableToolbar: true,
	showToolbar: true,
	placeholder: 'Add your observation here',
	defaultParagraphSeparator: '',
	defaultFontSize: '2',
	fonts: [
		{ class: 'lato-font', name: 'Normal' },
		{ class: 'lato-light-font', name: 'Light' },
	],
	customClasses: [
		{
			name: 'Title',
			class: 'lead-txt',
		},
		{
			name: 'Subtitle',
			class: 'sub-txt'
		},
		{
			name: 'Paragraph',
			class: 'paragraph-txt'
		},
	],
	sanitize: true,
	toolbarPosition: 'top',
	toolbarHiddenButtons: [
		[
			'fontSize',
			'customClasses',
			'insertImage',
    	'insertVideo',
		]
	]
};
// editor global config es
export const editorObservationGlobalConfigEs: AngularEditorConfig = {
	editable: true,
	spellcheck: true,
	height: 'auto',
	minHeight: '100px',
	maxHeight: 'auto',
	width: 'auto',
	minWidth: '100%',
	translate: 'yes',
	enableToolbar: true,
	showToolbar: true,
	placeholder: 'Agrega tu observación aquí',
	defaultParagraphSeparator: '',
	defaultFontSize: '2',
	fonts: [
		{ class: 'lato-font', name: 'Normal' },
		{ class: 'lato-light-font', name: 'Ligera' },
	],
	customClasses: [
		{
			name: 'Título',
			class: 'lead-txt',
		},
		{
			name: 'Subtítulo',
			class: 'sub-txt'
		},
		{
			name: 'Párrafo',
			class: 'paragraph-txt'
		},
	],
	sanitize: true,
	toolbarPosition: 'top',
	toolbarHiddenButtons: [
		[
			'fontSize',
			'customClasses',
			'insertImage',
    	'insertVideo',
		]
	]
};
// editor global config en
export const editorCompanyAboutGlobalConfigEn: AngularEditorConfig = {
	editable: true,
	spellcheck: true,
	height: 'auto',
	minHeight: '100px',
	maxHeight: 'auto',
	width: 'auto',
	minWidth: '100%',
	translate: 'yes',
	enableToolbar: true,
	showToolbar: true,
	placeholder: 'Add something about your company here',
	defaultParagraphSeparator: '',
	defaultFontSize: '2',
	fonts: [
		{ class: 'lato-font', name: 'Normal' },
		{ class: 'lato-light-font', name: 'Light' },
	],
	customClasses: [
		{
			name: 'Title',
			class: 'lead-txt',
		},
		{
			name: 'Subtitle',
			class: 'sub-txt'
		},
		{
			name: 'Paragraph',
			class: 'paragraph-txt'
		},
	],
	sanitize: true,
	toolbarPosition: 'top',
	toolbarHiddenButtons: [
		[
			'fontSize',
			'customClasses',
			'insertImage',
    	'insertVideo',
		]
	]
};
// editor global config es
export const editorCompanyAboutGlobalConfigEs: AngularEditorConfig = {
	editable: true,
	spellcheck: true,
	height: 'auto',
	minHeight: '100px',
	maxHeight: 'auto',
	width: 'auto',
	minWidth: '100%',
	translate: 'yes',
	enableToolbar: true,
	showToolbar: true,
	placeholder: 'Agrega algo acerca de tu empresa aquí',
	defaultParagraphSeparator: '',
	defaultFontSize: '2',
	fonts: [
		{ class: 'lato-font', name: 'Normal' },
		{ class: 'lato-light-font', name: 'Ligera' },
	],
	customClasses: [
		{
			name: 'Título',
			class: 'lead-txt',
		},
		{
			name: 'Subtítulo',
			class: 'sub-txt'
		},
		{
			name: 'Párrafo',
			class: 'paragraph-txt'
		},
	],
	sanitize: true,
	toolbarPosition: 'top',
	toolbarHiddenButtons: [
		[
			'fontSize',
			'customClasses',
			'insertImage',
    	'insertVideo',
		]
	]
};
// classic editor global config es
export const classicEditorEs = ClassicEditor.defaultConfig = {
	toolbar: {
		items: [
			'heading',
			'|',
			'bold',
			'italic',
			'|',
			'bulletedList',
			'numberedList',
			'|',
			'insertTable',
			'|',
			'imageUpload',
			'|',
			'undo',
			'redo'
		]
	},
	table: {
		contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
	},
	language: 'es'
};
// classic editor global config en
export const classicEditorEn = ClassicEditor.defaultConfig = {
	toolbar: {
		items: [
			'heading',
			'|',
			'bold',
			'italic',
			'|',
			'bulletedList',
			'numberedList',
			'|',
			'insertTable',
			'|',
			'imageUpload',
			'|',
			'undo',
			'redo'
		]
	},
	table: {
		contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
	},
	language: 'en'
};
