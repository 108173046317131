<div class="card">
   <div class="card-header card-no-border">
    <h5 class="test-title-css loan-work-activities-title">{{ 'REPORT.loanWorkActivities' | translate }}</h5>
    <div class="dropdown">
      <button class="btn dropdown-toggle" type="button" (click)="toggleTimeQuery()">{{ 'DATEDATA.timeQueryOptions.' + selectedTimeQuery | translate }}</button>
      <div class="dropdown-menu dropdown-menu-end" [class.show]="showTimeQuery">
        <a class="dropdown-item" (click)="setTimeQuery(timeQueryOption)" *ngFor="let timeQueryOption of timeQueryOptions">{{ 'DATEDATA.timeQueryOptions.' + timeQueryOption | translate }}</a>
      </div>
    </div>
   </div>
   <div class="card-body pt-0">
     <div class="monthly-profit">
       <div id="profitmonthly">
        <apx-chart
          *ngIf="dataLoaded"
          [labels]="workActivitiesData.labels"
          [series]="workActivitiesData.series"
          [chart]="workActivitiesData.chart"
          [dataLabels]="workActivitiesData.dataLabels"
          [legend]="workActivitiesData.legend"
          [stroke]="workActivitiesData.stroke"
          [plotOptions]="workActivitiesData.plotOptions"
          [states]="workActivitiesData.states"
          [colors]="workActivitiesData.colors"
          [responsive]="workActivitiesData.responsive"
        ></apx-chart>
       </div>
     </div>
   </div>
 </div>