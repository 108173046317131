// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 25/05/2023
// import
import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
// translate
import { TranslateService } from '@ngx-translate/core';
// reducers
import { Action, Store } from '@ngrx/store';
// environment
import { environment } from 'src/environments/environment';
// plugins
declare var $: any;
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageTransform, ImageCroppedEvent, base64ToFile, Dimensions, LoadedImage } from 'ngx-image-cropper';
// services
import { AuthService } from 'src/app/shared/services/auth.service';
import { EncodingService } from 'src/app/shared/services/encoding.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { LocationService } from 'src/app/shared/services/location.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
import { MediaService } from 'src/app/shared/services/media.service';
// interfaces
import { CountryInterface } from 'src/app/shared/interfaces/country.interface';
import { StateInterface } from 'src/app/shared/interfaces/state.interface';
import { CityInterface } from 'src/app/shared/interfaces/city.interface';
import { UserInterface } from 'src/app/shared/interfaces/user.interface';
import { UserRoleInterface } from 'src/app/shared/interfaces/user-role.interface';
import { UpdateDataInterface } from 'src/app/shared/reducers/interfaces';
// component
@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
// class
export class UserFormComponent implements OnInit, OnChanges {
  // variables
  @Input() userData: UserInterface = null;
  @Input() fullData: boolean = false;
  @Input() origin: string = null;
  @Input() role: string = null;
  @Input() showDocuments: boolean = false;
  @Output() loaderEvent = new EventEmitter();
  @Output() addEvent = new EventEmitter();
  @Output() updateEvent = new EventEmitter();
  @Output() backEvent = new EventEmitter();
  @Output() saveSimulatorEvent = new EventEmitter();
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  // lang data
  currentLang: string = null;
  // form data
  public mainFrm: FormGroup;
  public pwdFrm: FormGroup;
  emailStatus: string = null;
  showPwd: boolean = false;
  showConfirmPwd: boolean = false;
  passwordMismatch: boolean = false;
  // messages data
  formErrorMessages: any = {};
  // help data
  toggleHelp = false;
  // saved data
  savedData: boolean = false;
  // token data
  token: string = null;
  // avatar data
  avatarFile: any = null;
  avatarSource: any = null;
  avatarChangedEvent: any = null;
  canvasRotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  // current user data
  currentUserData: UserInterface = null;
  userShowPwdEdit: boolean = false;
  userEditRoleSupport: boolean = false;
  userEditRole: boolean = false;
  userEditFinancial: boolean = false;
  userEditCompany: boolean = false;
  userEditStatus: boolean = false;
  allRoles: any[] = [];
  // user data
  userSearched: boolean = false;
  userFound: boolean = false;
  userAddedOnSimulator: boolean = false;
  // other data
  minAge: number = 0;
  maxAge: number = 0;
  minYear: string = null;
  maxYear: string = null;
  maxBirthDate: string = null;
  noPassBirthAgeValidation: boolean = null;
  currentDate: any = null;
  // location data
  loadingBirthDatePlace: boolean = false;
  loadingDocumentExpedition: boolean = false;
  // country data
  countries: any[] = [];
  allCountries: any[] = [];
  countryName: string = environment.countryName;
  countryFlag: string = environment.countryFlag;
  countryCode: string = environment.countryCode;
  countryCodeId: string = environment.countryCodeId;
  olderAge: number = 18;
  nationalityCountryId: string = null;
  nationalityCountry: CountryInterface = null;
  birthPlaceCountryId: string = null;
  birthPlaceCountryName: string = null;
  birthPlaceCountry: CountryInterface = null;
  documentExpeditionCountryId: string = null;
  documentExpeditionCountryName: string = null;
  documentExpeditionCountry: CountryInterface = null;
  // cellphone country code data
  selectCellphoneCode: boolean = false;
  cellphoneCountry: CountryInterface = null;
  cellphoneCountryId: string = environment.countryCodeId;
  cellphoneCountryName: string = environment.countryName;
  cellphoneCountryFlag: string = environment.countryFlag;
  cellphoneCountryCode: string = environment.countryCode;
  cellphoneCountryCodeId: string = environment.countryCodeId;
  // phone country code data
  selectPhoneCode: boolean = false;
  phoneCountry: CountryInterface = null;
  phoneCountryId: string = environment.countryCodeId;
  phoneCountryName: string = environment.countryName;
  phoneCountryFlag: string = environment.countryFlag;
  phoneCountryCode: string = environment.countryCode;
  phoneCountryCodeId: string = environment.countryCodeId;
  // birth data
  birthPlaceStates: any[] = [];
  allBirthPlaceStates: any[] = [];
  documentExpeditionStates: any[] = [];
  allDocumentExpeditionStates: any[] = [];
  birthPlaceStateId: string = null;
  birthPlaceStateName: string = null;
  birthPlaceState: StateInterface = null;
  // document expedition data
  documentExpeditionStateId: string = null;
  documentExpeditionStateName: string = null;
  documentExpeditionState: StateInterface = null;
  // city data
  birthDateCities: any[] = [];
  allBirthPlaceCities: any[] = [];
  documentExpeditionCities: any[] = [];
  allDocumentExpeditionCities: any[] = [];
  birthPlaceCityId: string = null;
  birthPlaceCityName: string = null;
  birthPlaceCity: CityInterface = null;
  documentExpeditionCityId: string = null;
  documentExpeditionCityName: string = null;
  documentExpeditionCity: CityInterface = null;
  // constructor
  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public authSv: AuthService,
    public encodingSv: EncodingService,
    public apiSv: ApiService,
    public locationSv: LocationService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    private utilitiesSv: UtilitiesService,
    private mediaSv: MediaService,
    private modalService: NgbModal,
    private updateAppData: Store<UpdateDataInterface>,
  ) {
    // get min and max year
    this.minYear = '01-01-' + moment().year();
    this.maxYear = '01-01-' + moment().year();
    this.maxBirthDate = moment().locale(this.translate.getDefaultLang()).format('DD-MM-YYYY');
    this.currentDate = moment().locale(this.translate.getDefaultLang()).format('YYYY-MM-DD');
    // main form
    this.mainFrm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      cellphoneCountryCode: [null, Validators.required],
      cellphone: [null, [Validators.required, Validators.minLength(4), Validators.maxLength(13), Validators.pattern('^[0-9]+$')]],
      phoneCountryCode: [null],
      phone: [null, [Validators.minLength(4), Validators.maxLength(13), Validators.pattern('^[0-9]+$')]],
      firstName: [null, Validators.required],
      secondName: [null],
      lastName: [null, Validators.required],
      surname: [null],
      documentType: [null, Validators.required],
      documentNumber: [null, Validators.required],
      documentExpeditionCountry: [null, Validators.required],
      documentExpeditionState: [null, Validators.required],
      documentExpeditionCity: [null, Validators.required],
      documentExpeditionDate: [null, [Validators.required, this.dateValidator()]],
      nationality: [null, Validators.required],
      birthDate: [null, [Validators.required, this.dateValidator(), this.checkBirthDate()]],
      birthPlaceCountry: [null, Validators.required],
      birthPlaceState: [null, Validators.required],
      birthPlaceCity: [null, Validators.required],
      gender: [null, Validators.required],
      civilState: [null, Validators.required],
      roles: [null],
      status: [null],
    });
    // forms
    this.pwdFrm = this.formBuilder.group({
      password: [null, [Validators.required, Validators.minLength(6), Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,30}$')]],
      confirmPwd: [null, Validators.required],
    }, {
      validators: this.password.bind(this)
    });
  }

  test() {
    console.log('roles', this.mainFrm.controls.roles.value);
  }

  // life cycle
  async ngOnInit() {
    await this.translateMsgs();
    await this.checkOrigin();
    this.generalData();
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.userData) {
      // check for userData
      if (this.userData !== null) {
        this.initData();
      }
    }
  }
  async generalData() {
    // get data
    await this.getCountries();
    await this.setInitialCountries();
    await this.getToken();
    await this.getUserSearched();
    await this.getCurrentUserData();
    await this.checkRoles();
    // set initial country code
    this.mainFrm.controls.cellphoneCountryCode.setValue(this.countryCodeId);
    this.mainFrm.controls.phoneCountryCode.setValue(this.countryCodeId);
    // check role
    if (this.role !== 'all') {
      // userRoles
      const userRolesFrm: any[] = [this.role];
      // update userRoles form data
      this.mainFrm.controls.roles.setValue(userRolesFrm);
    }
    // check origin
    if (this.origin === 'simulator') {
      // set document type
      this.mainFrm.controls.documentType.setValue('id');
      // get documentExpeditionDate
      const documentExpeditionDate = moment.utc().format('YYYY-MM-DD');
      // set documentExpeditionDate
      this.mainFrm.controls.documentExpeditionDate.setValue(documentExpeditionDate);
    }
  }
  async initData() {
    // show loader
    this.loaderEvent.emit(this.translate.instant('HELPERS.loadingTxt'));
    // get data
    await this.generalData();
    await this.setUserData();
    // check origin
    if (this.origin === 'userB2B') {
      // set status
      this.mainFrm.controls.status.setValue('active');
    }
    // hide loader
    this.loaderEvent.emit(null);
  }
  async checkOrigin() {
    // check origin
    if (this.origin === 'simulator') {
      this.mainFrm.get('documentType')?.setValidators(null);
      this.mainFrm.controls['documentType'].updateValueAndValidity();
      this.mainFrm.get('documentNumber')?.setValidators(null);
      this.mainFrm.controls['documentNumber'].updateValueAndValidity();
      this.mainFrm.get('documentExpeditionCountry')?.setValidators(null);
      this.mainFrm.controls['documentExpeditionCountry'].updateValueAndValidity();
      this.mainFrm.get('documentExpeditionState')?.setValidators(null);
      this.mainFrm.controls['documentExpeditionState'].updateValueAndValidity();
      this.mainFrm.get('documentExpeditionCity')?.setValidators(null);
      this.mainFrm.controls['documentExpeditionCity'].updateValueAndValidity();
      this.mainFrm.get('documentExpeditionDate')?.setValidators(null);
      this.mainFrm.controls['documentExpeditionDate'].updateValueAndValidity();
      this.mainFrm.get('nationality')?.setValidators(null);
      this.mainFrm.controls['nationality'].updateValueAndValidity();
      this.mainFrm.get('birthDate')?.setValidators(null);
      this.mainFrm.controls['birthDate'].updateValueAndValidity();
      this.mainFrm.get('birthPlaceCountry')?.setValidators(null);
      this.mainFrm.controls['birthPlaceCountry'].updateValueAndValidity();
      this.mainFrm.get('birthPlaceState')?.setValidators(null);
      this.mainFrm.controls['birthPlaceState'].updateValueAndValidity();
      this.mainFrm.get('birthPlaceCity')?.setValidators(null);
      this.mainFrm.controls['birthPlaceCity'].updateValueAndValidity();
      this.mainFrm.get('gender')?.setValidators(null);
      this.mainFrm.controls['gender'].updateValueAndValidity();
      this.mainFrm.get('civilState')?.setValidators(null);
      this.mainFrm.controls['civilState'].updateValueAndValidity();
    } else {
      this.mainFrm.get('documentType')?.setValidators([Validators.required]);
      this.mainFrm.controls['documentType'].updateValueAndValidity();
      this.mainFrm.get('documentNumber')?.setValidators([Validators.required]);
      this.mainFrm.controls['documentNumber'].updateValueAndValidity();
      this.mainFrm.get('documentExpeditionCountry')?.setValidators([Validators.required]);
      this.mainFrm.controls['documentExpeditionCountry'].updateValueAndValidity();
      this.mainFrm.get('documentExpeditionState')?.setValidators([Validators.required]);
      this.mainFrm.controls['documentExpeditionState'].updateValueAndValidity();
      this.mainFrm.get('documentExpeditionCity')?.setValidators([Validators.required]);
      this.mainFrm.controls['documentExpeditionCity'].updateValueAndValidity();
      this.mainFrm.get('documentExpeditionDate')?.setValidators([Validators.required]);
      this.mainFrm.controls['documentExpeditionDate'].updateValueAndValidity();
      this.mainFrm.get('nationality')?.setValidators([Validators.required]);
      this.mainFrm.controls['nationality'].updateValueAndValidity();
      this.mainFrm.get('birthDate')?.setValidators([Validators.required]);
      this.mainFrm.controls['birthDate'].updateValueAndValidity();
      this.mainFrm.get('birthPlaceCountry')?.setValidators([Validators.required]);
      this.mainFrm.controls['birthPlaceCountry'].updateValueAndValidity();
      this.mainFrm.get('birthPlaceState')?.setValidators([Validators.required]);
      this.mainFrm.controls['birthPlaceState'].updateValueAndValidity();
      this.mainFrm.get('birthPlaceCity')?.setValidators([Validators.required]);
      this.mainFrm.controls['birthPlaceCity'].updateValueAndValidity();
      this.mainFrm.get('gender')?.setValidators([Validators.required]);
      this.mainFrm.controls['gender'].updateValueAndValidity();
      this.mainFrm.get('civilState')?.setValidators([Validators.required]);
      this.mainFrm.controls['civilState'].updateValueAndValidity();
    }
  }
  // translate
  async translateMsgs() {
    this.formErrorMessages = {
      email: [
        { type: 'required', message: this.translate.instant('USER.formValidation.email.required') },
        { type: 'email', message: this.translate.instant('USER.formValidation.email.email') },
      ],
      cellphone: [
        { type: 'required', message: this.translate.instant('USER.formValidation.cellphone.required') },
        { type: 'minlength', message: this.translate.instant('USER.formValidation.cellphone.minLength') },
        { type: 'maxlength', message: this.translate.instant('USER.formValidation.cellphone.maxLength') },
        { type: 'pattern', message: this.translate.instant('USER.formValidation.cellphone.pattern') },
      ],
      cellphoneCountry: [
        { type: 'required', message: this.translate.instant('USER.formValidation.countryPhoneCode.required') }
      ],
      phone: [
        { type: 'minlength', message: this.translate.instant('USER.formValidation.phone.minLength') },
        { type: 'maxlength', message: this.translate.instant('USER.formValidation.phone.maxLength') },
        { type: 'pattern', message: this.translate.instant('USER.formValidation.phone.pattern') },
      ],
      phoneCountry: [
        { type: 'required', message: this.translate.instant('USER.formValidation.countryPhoneCode.required') }
      ],
      firstName: [
        { type: 'required', message: this.translate.instant('USER.formValidation.firstName.required') }
      ],
      lastName: [
        { type: 'required', message: this.translate.instant('USER.formValidation.lastName.required') }
      ],
      documentType: [
        { type: 'required', message: this.translate.instant('USER.formValidation.documentType.required') }
      ],
      documentNumber: [
        { type: 'required', message: this.translate.instant('USER.formValidation.documentNumber.required') }
      ],
      documentExpeditionDate: [
        { type: 'required', message: this.translate.instant('USER.formValidation.documentExpeditionDate.required') },
        { type: 'invalidDate', message: this.translate.instant('HELPERS.invalidDate') },
      ],
      documentExpeditionCity: [
        { type: 'required', message: this.translate.instant('USER.formValidation.documentExpeditionPlace.required') }
      ],
      nationality: [
        { type: 'required', message: this.translate.instant('USER.formValidation.nationality.required') }
      ],
      birthDate: [
        { type: 'required', message: this.translate.instant('USER.formValidation.birthDate.required') },
        { type: 'invalidDate', message: this.translate.instant('HELPERS.invalidDate') },
        { type: 'olderAge', message: this.translate.instant('USER.formValidation.birthDate.olderAge') }
      ],
      birthPlaceCity: [
        { type: 'required', message: this.translate.instant('USER.formValidation.birthPlace.required') }
      ],
      gender: [
        { type: 'required', message: this.translate.instant('USER.formValidation.gender.required') }
      ],
      civilState: [
        { type: 'required', message: this.translate.instant('USER.formValidation.civilState.required') }
      ],
      roles: [
        { type: 'required', message: this.translate.instant('USER.formValidation.roles.required') }
      ],
      status: [
        { type: 'required', message: this.translate.instant('USER.formValidation.status.required') }
      ],
      password: [
        { type: 'required', message: this.translate.instant('USER.formValidation.password.required') },
        { type: 'minLength', message: this.translate.instant('USER.formValidation.password.minLength') },
        { type: 'pattern', message: this.translate.instant('USER.formValidation.password.pattern') },
        { type: 'match', message: this.translate.instant('USER.formValidation.confirmPwd.match') },
      ],
      confirmPwd: [
        { type: 'required', message: this.translate.instant('USER.formValidation.confirmPwd.required') },
      ],
    };
  }
  // form
  async help() {
    if (this.toggleHelp) {
      this.toggleHelp = false;
    } else {
      this.toggleHelp = true;
    }
  }
  async setFocus(elementId: any) {
    elementId.focus();
  }
  checkFormField(fieldName: string) {
    // get field
    const field = this.mainFrm.get(fieldName);
    // check field
    const check = field?.invalid && field?.touched;
    // return data
    return check;
  }
  async checkEmail() {
    // go to server
    try {
      // get email data
      const email = this.mainFrm.controls.email.value;
      const emailStored = this.userData !== null ? this.userData.email : null;
      // init emailStatus
      let emailStatus: any = null;
      // init checkEmail
      let checkEmail: any = null;
      // check userData
      if (this.userData !== null) {
        // compareEmails
        if (email !== emailStored) {
          // check email
          checkEmail = await this.apiSv.checkEmail(email);
          // set emailStatus
          emailStatus = checkEmail.data.statusEmail;
          // setEmail
          await this.setEmail(email, emailStored, emailStatus);
        }
      } else {
        // check email
        checkEmail = await this.apiSv.checkEmail(email);
        // set emailStatus
        emailStatus = checkEmail.data.statusEmail;
        // setEmail
        await this.setEmail(email, emailStored, emailStatus);
      }
    } catch (error) {
      console.log('error', error);
    }
  }
  async setEmail(email: string, emailStored:string, emailStatus: any) {
    // set emailStatus
    this.emailStatus = emailStatus;
    // check origin
    if (this.origin === 'userB2C' || this.origin === 'userB2B' || this.origin === 'simulator') {
      // check userSearched
      if (!this.userSearched) {
        // update userSearched
        this.userSearched = true;
        // save userSearched on local storage
        localStorage.setItem('userSearched', 'true');
        // check emailStatus
        if (emailStatus !== 'not_found') {
          // clear userData
          await this.clearUserData();
          // get token
          const token = emailStatus;
          // check origin
          if (this.origin === 'userB2C' || this.origin === 'simulator') {
            // save token on storage
            localStorage.setItem('token', token);
            // check oriin simulator
            if (this.origin === 'simulator') {
              // mark userAddedOnSimulator
              this.userAddedOnSimulator = true;
            }
          }
          // update userFound
          this.userFound = true;
          // save userFound on storage
          localStorage.setItem('userFound', 'true');
          // get user data
          const userData: any = await this.encodingSv.decodeToken(token);
          // get user data from api
          await this.getUserData(userData.id);
          // check origin
          if (this.origin === 'simulator') {
            // update user
            await this.updateUser();
          }
        }
      } else {
        // check emailStatus
        if (emailStatus !== 'not_found') {
          // show alert
          Swal.fire({
            icon: 'warning',
            title: this.translate.instant('USER.userFound'),
            text: this.translate.instant('USER.userFoundTxt1') + ' ' + email + ' ' + this.translate.instant('USER.userFoundTxt2'),
            confirmButtonText: this.translate.instant('HELPERS.yes'),
            showCancelButton: true,
            cancelButtonText: this.translate.instant('HELPERS.no'),
            customClass: {
              cancelButton: 'btn-swal-cancel'
            },
            allowOutsideClick: false,
          }).then(async (result) => {
            if (result.isConfirmed) {
              // clear userData
              await this.clearUserData();
              // get token
              const token = emailStatus;
              // check origin
              if (this.origin === 'userB2C' || this.origin === 'simulator') {
                // save token on storage
                localStorage.setItem('token', token);
              }
              // update userFound
              this.userFound = true;
              // save userFound on storage
              localStorage.setItem('userFound', 'true');
              // get user data
              const userData: any = await this.encodingSv.decodeToken(token);
              // get user data from api
              await this.getUserData(userData.id);
            } else {
              // rollback email
              this.mainFrm.controls.email.setValue(emailStored);
            }
          });
        }
      }
    }
  }
  async checkPhoneNumber(checkFrom: string) {
    // checkFrom
    switch (checkFrom) {
      case 'cellphone':
        const cellphoneCode = this.cellphoneCountryCode;
        const cellphoneNumber = this.mainFrm.controls.cellphone.value;
        if (cellphoneNumber && cellphoneNumber !== null) {
          const cellphoneCheckPlus = cellphoneNumber.includes('+' + cellphoneCode);
          // check cellphone
          if (cellphoneCheckPlus) {
            this.mainFrm.controls.cellphone.setValue(cellphoneNumber.replace('+' + cellphoneCode,''));
          }
        }
        break;
      case 'phone':
        const phoneCode = this.phoneCountryCode;
        const phoneNumber = this.mainFrm.controls.phone.value;
        if (phoneNumber && phoneNumber !== null) {
          const phoneCheckPlus = phoneNumber.includes('+' + phoneCode);
          // check phone
          if (phoneCheckPlus) {
            this.mainFrm.controls.phone.setValue(phoneNumber.replace('+' + phoneCode,''));
          }
        }
        break;
    }
  }
  dateValidator() {
    return (control: FormControl) => {
      const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
      const valid = dateRegex.test(control.value);
      return (control.value !== null && control.value.length !== 10 && !valid) ? { invalidDate: true } : null;
    };
  }
  checkBirthDate() {
    return (control: FormControl) => {
      // verify age
      const verifyAge = this.utilitiesSv.verifyDateRange(control.value, this.olderAge, 'up', 'years');
      return (control.value !== null && control.value.length === 10 && !verifyAge) ? { olderAge: true } : null;
    };
  }
  async checkRoles() {
    // set rolesSupport
    const rolesSupport: any = [
      {
        id:"support",
        roleName: this.translate.instant('USER.formLabels.roleSupport')
      },
      {
        id:"administrator",
        roleName: this.translate.instant('USER.formLabels.roleAdministrator')
      },
      {
        id:"board",
        roleName: this.translate.instant('USER.formLabels.roleBoard')
      },
      {
        id:"businessAgent",
        roleName: this.translate.instant('USER.formLabels.roleBusinessAgent')
      },
      {
        id:"treasure",
        roleName: this.translate.instant('USER.formLabels.roleTreasure')
      },
      {
        id:"ceo",
        roleName: this.translate.instant('USER.formLabels.roleCEO')
      },
      {
        id:"manager",
        roleName: this.translate.instant('USER.formLabels.roleManager')
      },
      {
        id:"auxiliar",
        roleName: this.translate.instant('USER.formLabels.roleAuxiliar')
      },
      {
        id:"client",
        roleName: this.translate.instant('USER.formLabels.roleClient')
      },
    ];
    // set rolesFinancial
    const rolesFinancial: any = [
      {
        id:"administrator",
        roleName: this.translate.instant('USER.formLabels.roleAdministrator')
      },
      {
        id:"board",
        roleName: this.translate.instant('USER.formLabels.roleBoard')
      },
      {
        id:"businessAgent",
        roleName: this.translate.instant('USER.formLabels.roleBusinessAgent')
      },
      {
        id:"treasure",
        roleName: this.translate.instant('USER.formLabels.roleTreasure')
      },
      {
        id:"ceo",
        roleName: this.translate.instant('USER.formLabels.roleCEO')
      },
      {
        id:"manager",
        roleName: this.translate.instant('USER.formLabels.roleManager')
      },
      {
        id:"auxiliar",
        roleName: this.translate.instant('USER.formLabels.roleAuxiliar')
      },
      {
        id:"client",
        roleName: this.translate.instant('USER.formLabels.roleClient')
      },
    ];
    // set rolesCompany
    const rolesCompany: any = [
      {
        id:"ceo",
        roleName: this.translate.instant('USER.formLabels.roleCEO')
      },
      {
        id:"manager",
        roleName: this.translate.instant('USER.formLabels.roleManager')
      },
      {
        id:"auxiliar",
        roleName: this.translate.instant('USER.formLabels.roleAuxiliar')
      },
      {
        id:"client",
        roleName: this.translate.instant('USER.formLabels.roleEmployee')
      },
    ];
    // check permissions
    if (this.userEditRoleSupport) {
      // update all roles
      this.allRoles = rolesSupport;
    } else if (this.userEditFinancial) {
      // update all roles
      this.allRoles = rolesFinancial;
    } else if (this.userEditCompany) {
      // update all roles
      this.allRoles = rolesCompany;
    }
    // check register from
    if (this.fullData) {
      if (this.userEditRole) {
        // set form validators
        this.mainFrm.get('roles')?.setValidators([Validators.required]);
        this.mainFrm.controls['roles'].updateValueAndValidity();
      } else {
        // set form validators
        this.mainFrm.get('roles')?.setValidators(null);
        this.mainFrm.controls['roles'].updateValueAndValidity();
      }
      if (this.userEditStatus) {
        // set form validators
        this.mainFrm.get('status')?.setValidators([Validators.required]);
        this.mainFrm.controls['status'].updateValueAndValidity();
      } else {
        // set form validators
        this.mainFrm.get('status')?.setValidators(null);
        this.mainFrm.controls['status'].updateValueAndValidity();
      }
    }
  }
  password(formGroup: FormGroup) {
    // get data
    const password = formGroup.get('password').value;
    const passwordDirty = formGroup.get('password').dirty;
    const passwordTouched = formGroup.get('password').touched;
    const confirmPwd = formGroup.get('confirmPwd').value;
    const confirmPwdDirty = formGroup.get('confirmPwd').dirty;
    const confirmPwdTouched = formGroup.get('confirmPwd').touched;
    // check if password and confirmPwd have been touched or dirty
    if ((passwordDirty || passwordTouched) && (confirmPwdDirty || confirmPwdTouched)) {
      // check if password and confirmPwd are the same
      if (password === confirmPwd) {
        // set passwordMismatch
        this.passwordMismatch = false;
        // return data
        return null;
      } else {
        // set passwordMismatch
        this.passwordMismatch = true;
        // return data
        return { passwordNotMatch: true };
      }
    }
  }
  async togglePwd() {
    this.showPwd = !this.showPwd;
  }
  async toggleConfirmPwd() {
    this.showConfirmPwd = !this.showConfirmPwd;
  }
  // navigation
  async goBack() {
    // emit data
    this.backEvent.emit(); // Return dat
  }
  // location
  async getCountries() {
    try {
      // get stored data countries
      const storedDataCountries: any = localStorage.getItem('countriesData');
      // set allCountries
      const allCountries: any = [];
      // set countries
      let countries: any;
      // check countries data
      if (!storedDataCountries) {
        // get data from api
        await this.apiSv.getCountries().then((response: any)=>{
          // get countries
          countries = response.data;
          // save countries on storage
          localStorage.setItem('countriesData', JSON.stringify(countries));
          // loop countries
          countries.map((country: CountryInterface) => {
            // push countries
            this.countries.push(country);
            // set countryData
            const countryData = {
              id: country.id,
              countryName: country.countryName
            }
            // push country
            allCountries.push(countryData);
          });
          // sort countries
          const countriesSorted = this.utilitiesSv.sortArrayByKey(allCountries, 'countryName');
          // set countryNullData
          const countryNullData = {
            id: null,
            countryName: this.translate.instant('LOCATION.formPlaceHolders.countrySelect')
          }
          // unshift country
          countriesSorted.unshift(countryNullData);
          // update all countries
          this.allCountries = countriesSorted;
        }, error=>{
          console.log('error', error);
        });
      } else {
        // get countries
        countries = JSON.parse(storedDataCountries);
        // loop countries
        countries.map((country: CountryInterface) => {
          // push countries
          this.countries.push(country);
          // set countryData
          const countryData = {
            id: country.id,
            countryName: country.countryName
          }
          // push country
          allCountries.push(countryData);
        });
        // sort countries
        const countriesSorted = this.utilitiesSv.sortArrayByKey(allCountries, 'countryName');
        // set countryNullData
        const countryNullData = {
          id: null,
          countryName: this.translate.instant('LOCATION.formPlaceHolders.countrySelect')
        }
        // unshift country
        countriesSorted.unshift(countryNullData);
        // update all countries
        this.allCountries = countriesSorted;
      }
    } catch (error: any) {
      console.log('error', error);
    }
  }
  async setInitialCountries() {
    this.mainFrm.controls.cellphoneCountryCode.setValue(this.cellphoneCountryId);
    await this.selectCountry('cellphone');
    this.mainFrm.controls.phoneCountryCode.setValue(this.phoneCountryId);
    await this.selectCountry('phone');
  }
  async selectCountry(selectFrom: string) {
    // check selectFrom
    switch (selectFrom) {
      case 'birthPlace':
        // get data
        const birthPlaceCountryId: any = this.mainFrm.controls.birthPlaceCountry.value;
        const birthPlaceCountryIndex: any = this.countries.findIndex((country: any) => country.id === birthPlaceCountryId); 
        const birthPlaceCountry: CountryInterface = this.countries[birthPlaceCountryIndex];
        // set birthDate country
        this.birthPlaceCountryId = birthPlaceCountry.id;
        this.birthPlaceCountryName = birthPlaceCountry.countryName;
        this.birthPlaceCountry = birthPlaceCountry;
        // clear birthDate states
        this.birthPlaceStateId = null;
        this.birthPlaceStateName = null;
        this.birthPlaceState = null;
        // clear birthDate cities
        this.birthPlaceCityId = null;
        this.birthPlaceCityName = null;
        this.birthPlaceCity = null;
        // load states
        await this.getStates(selectFrom, birthPlaceCountry.id);
        break;
      case 'documentExpedition':
        // get data
        const documentExpeditionCountryId: any = this.mainFrm.controls.documentExpeditionCountry.value;
        const documentExpeditionCountryIndex: any = this.countries.findIndex((country: any) => country.id === documentExpeditionCountryId); 
        const documentExpeditionCountry: CountryInterface = this.countries[documentExpeditionCountryIndex];
        // set documentExpedition country
        this.documentExpeditionCountryId = documentExpeditionCountry.id;
        this.documentExpeditionCountryName = documentExpeditionCountry.countryName;
        this.documentExpeditionCountry = documentExpeditionCountry;
        // clear documentExpedition states
        this.documentExpeditionStateId = null;
        this.documentExpeditionStateName = null;
        this.documentExpeditionState = null;
        // clear documentExpedition cities
        this.documentExpeditionCityId = null;
        this.documentExpeditionCityName = null;
        this.documentExpeditionCity = null;
        // load states
        await this.getStates(selectFrom, documentExpeditionCountry.id);
        break;
      case 'nationality':
        // get data
        const nationalityCountryId: any = this.mainFrm.controls.nationality.value;
        const nationalityCountryIndex: any = this.countries.findIndex((country: any) => country.id === nationalityCountryId); 
        const nationalityCountry: CountryInterface = this.countries[nationalityCountryIndex];
        // set nationality country
        this.nationalityCountryId = nationalityCountry.id;
        this.nationalityCountry = nationalityCountry;
        break;
      case 'cellphone':
        // get data
        const cellphoneCountryId: any = this.mainFrm.controls.cellphoneCountryCode.value;
        const cellphoneCountryIndex: any = this.countries.findIndex((country: any) => country.id === cellphoneCountryId); 
        const cellphoneCountry: CountryInterface = this.countries[cellphoneCountryIndex];
        // set cellphone country
        this.cellphoneCountryId = cellphoneCountry.id;
        this.cellphoneCountry = cellphoneCountry;
        this.cellphoneCountryName = cellphoneCountry.countryName;
        this.cellphoneCountryFlag = 'assets/images/flags/' + cellphoneCountry.countryFlag + '.png';
        this.cellphoneCountryCode = cellphoneCountry.numericCode;
        this.cellphoneCountryCodeId = cellphoneCountry.id;
        // check phone number
        await this.checkPhoneNumber('cellphone');
        // close select phone code
        this.selectCellphoneCode = false;
        break;
      case 'phone':
        // get data
        const phoneCountryId: any = this.mainFrm.controls.phoneCountryCode.value;
        const phoneCountryIndex: any = this.countries.findIndex((country: any) => country.id === phoneCountryId); 
        const phoneCountry: CountryInterface = this.countries[phoneCountryIndex];
        // set phone country
        this.phoneCountryId = phoneCountry.id;
        this.phoneCountry = phoneCountry;
        this.phoneCountryName = phoneCountry.countryName;
        this.phoneCountryFlag = 'assets/images/flags/' + phoneCountry.countryFlag + '.png';
        this.phoneCountryCode = phoneCountry.numericCode;
        this.phoneCountryCodeId = phoneCountry.id;
        // check phone number
        await this.checkPhoneNumber('phone');
        // close select phone code
        this.selectPhoneCode = false;
        break;
    }
  }
  openSelectPhoneCountry(selectFrom: string) {
    switch (selectFrom) {
      case 'cellphone':
        this.selectCellphoneCode = true;
        break;
        case 'phone':
        this.selectPhoneCode = true;
        break;
    }
  }
  async getStates(selectFrom: string, countryId: string) {
    try {
      // check selectFrom
      switch (selectFrom) {
        case 'birthPlace':
          // show location loader
          this.loadingBirthDatePlace = true;
          // get data
          const birthPlaceStates: any = await this.apiSv.getStates(countryId);
          // set allBirthPlaceStates
          const allBirthPlaceStates: any = [];
          // loop birthPlaceStates
          birthPlaceStates.data.forEach((state: StateInterface) => {
            // push birthPlaceStates
            this.birthPlaceStates.push(state);
            // set birthPlaceStateData
            const birthPlaceStateData = {
              id: state.id,
              stateName: state.name
            }
            // push state
            allBirthPlaceStates.push(birthPlaceStateData);
          });
          // sort states
          const birthPlaceStatesSorted = this.utilitiesSv.sortArrayByKey(allBirthPlaceStates, 'stateName');
          // update all states
          this.allBirthPlaceStates = birthPlaceStatesSorted;
          // hide location loader
          this.loadingBirthDatePlace = false;
          break;
        case 'documentExpedition':
          // show loader
          this.loadingDocumentExpedition = true;
          // get data
          const documentExpeditions: any = await this.apiSv.getStates(countryId);
          // set allDocumentExpeditionStates
          const allDocumentExpeditionStates: any = [];
          // loop documentExpeditions
          documentExpeditions.data.forEach((state: StateInterface) => {
            // push documentExpeditionStates
            this.documentExpeditionStates.push(state);
            // set documentExpeditionStateData
            const documentExpeditionStateData = {
              id: state.id,
              stateName: state.name
            }
            // push state
            allDocumentExpeditionStates.push(documentExpeditionStateData);
          });
          // sort states
          const documentExpeditionStatesSorted = this.utilitiesSv.sortArrayByKey(allDocumentExpeditionStates, 'stateName');
          // update all states
          this.allDocumentExpeditionStates = documentExpeditionStatesSorted;
          // hide location loader
          this.loadingBirthDatePlace = false;
          break;
      }
    } catch (error: any) {
      console.log('error', error);
      // hide location loader
      this.loadingBirthDatePlace = false;
      this.loadingDocumentExpedition = false;
    }
  }
  async selectState(selectFrom: string) {
    // check selectFrom
    switch (selectFrom) {
      case 'birthPlace':
        // get data
        const birthPlaceStateId: any = this.mainFrm.controls.birthPlaceState.value;
        const birthPlaceStateIndex: any = this.birthPlaceStates.findIndex((state: any) => state.id === birthPlaceStateId); 
        const birthPlaceState: any = this.birthPlaceStates[birthPlaceStateIndex];
        // set birthDate state
        this.birthPlaceStateId = birthPlaceState.id;
        this.birthPlaceStateName = birthPlaceState.name;
        this.birthPlaceState = birthPlaceState;
        // clear birthDate cities
        this.birthPlaceCityId = null;
        this.birthPlaceCityName = null;
        this.birthPlaceCity = null;
        // load states
        await this.getCities(selectFrom, birthPlaceState.id);
        break;
      case 'documentExpedition':
        // get data
        const documentExpeditionStateId: any = this.mainFrm.controls.documentExpeditionState.value;
        const documentExpeditionStateIndex: any = this.documentExpeditionStates.findIndex((state: any) => state.id === documentExpeditionStateId); 
        const documentExpeditionState: any = this.documentExpeditionStates[documentExpeditionStateIndex];
        // set documentExpedition state
        this.documentExpeditionStateId = documentExpeditionState.id;
        this.documentExpeditionStateName = documentExpeditionState.name;
        this.documentExpeditionState = documentExpeditionState;
        // clear documentExpedition cities
        this.documentExpeditionCityId = null;
        this.documentExpeditionCityName = null;
        this.documentExpeditionCity = null;
        // load states
        await this.getCities(selectFrom, documentExpeditionState.id);
        break;
    }
  }
  async getCities(selectFrom: string, stateId: string) {
    try {
      // check selectFrom
      switch (selectFrom) {
        case 'birthPlace':
          // show location loader
          this.loadingBirthDatePlace = true;
          // get data
          const birthDateCities: any = await this.apiSv.getCities(stateId);
          // set allBirthPlaceCities
          const allBirthPlaceCities: any = [];
          // loop birthDateCities
          birthDateCities.data.forEach((city: CityInterface) => {
            // push birthDateCities
            this.birthDateCities.push(city);
            // set birthPlaceCityData
            const birthPlaceCityData = {
              id: city.id,
              cityName: city.name
            }
            // push city
            allBirthPlaceCities.push(birthPlaceCityData);
          });
          // sort cities
          const birthDateCitiesSorted = this.utilitiesSv.sortArrayByKey(allBirthPlaceCities, 'cityName');
          // update all cities
          this.allBirthPlaceCities = birthDateCitiesSorted;
          // hide location loader
          this.loadingBirthDatePlace = false;
          break;
        case 'documentExpedition':
          // show loader
          this.loadingDocumentExpedition = true;
          // get data
          const documentExpeditionCities: any = await this.apiSv.getCities(stateId);
          // set allDocumentExpeditionCities
          const allDocumentExpeditionCities: any = [];
          // loop documentExpeditionCities
          documentExpeditionCities.data.forEach((city: CityInterface) => {
            // push documentExpeditionCities
            this.documentExpeditionCities.push(city);
            // set documentExpeditionCityData
            const documentExpeditionCityData = {
              id: city.id,
              cityName: city.name
            }
            // push city
            allDocumentExpeditionCities.push(documentExpeditionCityData);
          });
          // sort cities
          const documentExpeditionCitiesSorted = this.utilitiesSv.sortArrayByKey(allDocumentExpeditionCities, 'cityName');
          // update all cities
          this.allDocumentExpeditionCities = documentExpeditionCitiesSorted;
          // hide location loader
          this.loadingBirthDatePlace = false;
          break;
      }
    } catch (error: any) {
      console.log('error', error);
      // hide location loader
      this.loadingBirthDatePlace = false;
      this.loadingDocumentExpedition = false;
    }
  }
  async selectCity(selectFrom: string) {
    // check selectFrom
    switch (selectFrom) {
      case 'birthPlace':
        // get data
        const birthPlaceCityId: any = this.mainFrm.controls.birthPlaceCity.value;
        const birthPlaceCityIndex: any = this.birthDateCities.findIndex((state: any) => state.id === birthPlaceCityId); 
        const birthPlaceCity: any = this.birthDateCities[birthPlaceCityIndex];
        // set birthDate state
        this.birthPlaceCityId = birthPlaceCity.id;
        this.birthPlaceCityName = birthPlaceCity.name;
        this.birthPlaceCity = birthPlaceCity;
        break;
      case 'documentExpedition':
        // get data
        const documentExpeditionCityId: any = this.mainFrm.controls.documentExpeditionCity.value;
        const documentExpeditionCityIndex: any = this.documentExpeditionCities.findIndex((state: any) => state.id === documentExpeditionCityId); 
        const documentExpeditionCity: any = this.documentExpeditionCities[documentExpeditionCityIndex];
        // set documentExpedition state
        this.documentExpeditionCityId = documentExpeditionCity.id;
        this.documentExpeditionCityName = documentExpeditionCity.name;
        this.documentExpeditionCity = documentExpeditionCity;
        break;
    }
  }
  async resetLocationSelect(selectFrom: string) {
    // check selectFrom
    switch (selectFrom) {
      case 'birthPlace':
        // set birthDate country
        this.birthPlaceCountryId = null;
        this.birthPlaceCountryName = null;
        this.birthPlaceCountry = null;
        // clear birthDate states
        this.birthPlaceStateId = null;
        this.birthPlaceStateName = null;
        this.birthPlaceState = null;
        // clear birthDate cities
        this.birthPlaceCityId = null;
        this.birthPlaceCityName = null;
        this.birthPlaceCity = null;
        // clear form data
        this.mainFrm.controls.birthPlaceCountry.setValue(null);
        this.mainFrm.controls.birthPlaceState.setValue(null);
        this.mainFrm.controls.birthPlaceCity.setValue(null);
        break;
      case 'documentExpedition':
        // set documentExpedition country
        this.documentExpeditionCountryId = null;
        this.documentExpeditionCountryName = null;
        this.documentExpeditionCountry = null;
        // clear documentExpedition states
        this.documentExpeditionStateId = null;
        this.documentExpeditionStateName = null;
        this.documentExpeditionState = null;
        // clear documentExpedition cities
        this.documentExpeditionCityId = null;
        this.documentExpeditionCityName = null;
        this.documentExpeditionCity = null;
        // clear form data
        this.mainFrm.controls.documentExpeditionCountry.setValue(null);
        this.mainFrm.controls.documentExpeditionState.setValue(null);
        this.mainFrm.controls.documentExpeditionCity.setValue(null);
        break;
    }
  }
  // data
  async getToken() {
    // get stored data token
    const storedDataToken: any = localStorage.getItem('token');
    // check token data
    if (storedDataToken != null) {
      // get data token
      this.token = storedDataToken;
      // check origin
      if (this.origin === 'userB2C') {
        // update saved data
        this.savedData = true;
      }
    }
  }
  async getUserSearched() {
    // get stored data userSearched
    const storedUserSearched: any = localStorage.getItem('userSearched');
    // check userSearched data
    if (storedUserSearched != null) {
      // get data userSearched
      this.userSearched = storedUserSearched === 'true' ? true : false;
    }
  }
  async getCurrentUserData() {
    // get stored data user
    const storedDataUser: any = localStorage.getItem('userData');
    // check user data
    if (storedDataUser != null) {
      // get data user
      const userData: UserInterface = JSON.parse(storedDataUser);
      // update data user
      this.currentUserData = userData;
      // check permissions
      const checkEditRole = await this.utilitiesSv.checkRoles(userData.role, ['support', 'administrator', 'board', 'treasure', 'ceo', 'manager', 'auxiliar']);
      this.userEditRole = checkEditRole;
      const checkEditRoleSupport = await this.utilitiesSv.checkRoles(userData.role, ['support']);
      this.userEditRoleSupport = checkEditRoleSupport;
      const checkEditStatus = await this.utilitiesSv.checkRoles(userData.role, ['support', 'administrator', 'board', 'treasure', 'ceo', 'manager', 'auxiliar']);
      this.userEditStatus = checkEditStatus;
      const checkUserEditFinancial = await this.utilitiesSv.checkRoles(userData.role, ['support', 'administrator', 'board', 'treasure']);
      this.userEditFinancial = checkUserEditFinancial;
      const checkUserEditCompany = await this.utilitiesSv.checkRoles(userData.role, ['ceo', 'manager', 'auxiliar']);
      this.userEditCompany = checkUserEditCompany;
    }
  }
  async getUserData(userId: string) {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.loadingTxt'));
      // check userId
      if (userId === 'my-profile') {
        // update userId
        userId = this.currentUserData.id;
      }
      // get data from api
      await this.apiSv.getUser(userId).then(async (userResponse: any)=>{
        // get user
        const user: UserInterface = userResponse.data;
        // set user
        this.userData = user;
        // set user data
        await this.setUserData();
        // hide loader
        this.loaderEvent.emit(null);
      }, error=>{
        console.log('error', error);
        // hide loader
        this.loaderEvent.emit(null);
      });
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
    }
  }
  async setUserData() {
    // check user data
    if (this.userData !== null) {
      // check currentUserData
      if (this.currentUserData) {
        // check pwdEdit
        const checkShowPwdEdit = await this.utilitiesSv.checkRoles(this.currentUserData.role, ['support', 'administrator']);
        this.userShowPwdEdit = (checkShowPwdEdit && (this.currentUserData.id !== this.userData.id)) || (this.currentUserData.id === this.userData.id) ? true : false;
      }
      // set user
      const user = this.userData;
      // set avatar
      this.avatarSource = this.userData.avatar;
      // set form data
      this.mainFrm.controls.email.setValue(user.email);
      this.mainFrm.controls.cellphoneCountryCode.setValue(user.cellphoneCountryCode);
      this.mainFrm.controls.cellphone.setValue(user.cellphone);
      this.mainFrm.controls.phoneCountryCode.setValue(user.phoneCountryCode);
      this.mainFrm.controls.phone.setValue(user.phone);
      this.mainFrm.controls.firstName.setValue(user.firstName);
      this.mainFrm.controls.secondName.setValue(user.secondName);
      this.mainFrm.controls.lastName.setValue(user.lastName);
      this.mainFrm.controls.surname.setValue(user.surname);
      this.mainFrm.controls.documentType.setValue(user.documentType);
      this.mainFrm.controls.documentNumber.setValue(user.documentNumber);
      this.mainFrm.controls.gender.setValue(user.gender);
      this.mainFrm.controls.civilState.setValue(user.civilState);
      // check userRoles type
      if (typeof user.userRoles === 'string') {
        // transform userRoles to json
        user.userRoles = JSON.parse(user.userRoles);
      }
      // userRoles
      const userRolesFrm: any[] = [];
      user.userRoles.map((userRole: UserRoleInterface) => {
        userRolesFrm.push(userRole.roleName);
      });

      if (this.userEditFinancial || this.userEditCompany) {
        setTimeout(() => {
          // update userRoles form data
          this.mainFrm.controls.roles.setValue(userRolesFrm);
        }, 500);
      }

      // get currentRole
      const currentRole = this.utilitiesSv.getGreaterActiveRole(user.userRoles);
      // update userRole
      user.userRole = currentRole;
      // userStatus
      this.mainFrm.controls.status.setValue(user.userStatus);


      // set selects
      // check cellphone
      if (user.cellphone && user.cellphone !== null) {
        await this.selectCountry('cellphone');
        this.checkPhoneNumber('cellphone');
      }
      // check phone
      if (user.phone && user.phone !== null) {
        await this.selectCountry('phone');
        this.checkPhoneNumber('phone');
      }
      // check nationality
      if (user.nationalityId && user.nationalityId !== null) {
        this.mainFrm.controls.nationality.setValue(user.nationalityId);
        await this.selectCountry('nationality');
      }
      // check origin
      if (this.origin === 'userB2C' || this.origin === 'userB2B') {
        // set birth data
        const birthDate = moment.utc(user.birthDate).format('YYYY-MM-DD');
        this.mainFrm.controls.birthDate.setValue(birthDate);
        // check birthPlace
        if (user.birthPlaceId && user.birthPlaceId !== null) {
          const auxBirthPlace: any = await this.apiSv.getCity(user.birthPlaceId);
          const birthPlace: CityInterface = auxBirthPlace.data;
          user.birthPlaceCountryId = birthPlace.countryId;
          user.birthPlaceCountryName = birthPlace.countryName;
          user.birthPlaceStateId = birthPlace.stateId;
          user.birthPlaceStateName = birthPlace.stateName;
          user.birthPlaceCityId = birthPlace.id;
          user.birthPlaceCityName = birthPlace.name;
          // set birthPlace form data
          this.mainFrm.controls.birthPlaceCountry.setValue(birthPlace.countryId);
          this.mainFrm.controls.birthPlaceState.setValue(birthPlace.stateId);
          this.mainFrm.controls.birthPlaceCity.setValue(birthPlace.id);
          await this.selectCountry('birthPlace');
          await this.selectState('birthPlace');
          await this.selectCity('birthPlace');
        }
        // set document expedition data
        const documentExpeditionDate = moment.utc(user.documentExpeditionDate).format('YYYY-MM-DD');
        this.mainFrm.controls.documentExpeditionDate.setValue(documentExpeditionDate);
        // check documentExpeditionCity
        if (user.documentExpeditionCityId && user.documentExpeditionCityId !== null) {
          const auxDocumentExpeditionPlace: any = await this.apiSv.getCity(user.documentExpeditionCityId);
          const documentExpeditionPlace: CityInterface = auxDocumentExpeditionPlace.data;
          user.documentExpeditionCountryId = documentExpeditionPlace.countryId;
          user.documentExpeditionCountryName = documentExpeditionPlace.countryName;
          user.documentExpeditionStateId = documentExpeditionPlace.stateId;
          user.documentExpeditionStateName = documentExpeditionPlace.stateName;
          user.documentExpeditionCityId = documentExpeditionPlace.id;
          user.documentExpeditionCityName = documentExpeditionPlace.name;
          // set documentExpeditionCity form data
          this.mainFrm.controls.documentExpeditionCountry.setValue(documentExpeditionPlace.countryId);
          this.mainFrm.controls.documentExpeditionState.setValue(documentExpeditionPlace.stateId);
          this.mainFrm.controls.documentExpeditionCity.setValue(documentExpeditionPlace.id);
          await this.selectCountry('documentExpedition');
          await this.selectState('documentExpedition');
          await this.selectCity('documentExpedition');
        }
      } else {
        // get birthDate
        const birthDate = moment.utc(user.birthDate).format('YYYY-MM-DD');
        // set birthDate
        this.mainFrm.controls.birthDate.setValue(birthDate);
        // check birthPlaceId
        if (user.birthPlaceId && user.birthPlaceId !== null) {
          // get birth place data
          const birthPlaceCityData: any = await this.apiSv.getCity(user.birthPlaceId);
          // get birthPlaceCity
          const birthPlaceCity: CityInterface = birthPlaceCityData.data;
          // get state data
          const birthPlaceCountryId = birthPlaceCity.countryId;
          // get state data
          const birthPlaceStateId = birthPlaceCity.stateId;
          // set birth data
          this.mainFrm.controls.birthPlaceCountry.setValue(birthPlaceCountryId);
          this.mainFrm.controls.birthPlaceState.setValue(birthPlaceStateId);
          this.mainFrm.controls.birthPlaceCity.setValue(user.birthPlaceId);
          // select locations
          await this.selectCountry('birthPlace');
          await this.selectState('birthPlace');
          await this.selectCity('birthPlace');
        }
        // check documentExpeditionCity
        if (user.documentExpeditionCityId && user.documentExpeditionCityId !== null) {
          // get birth place data
          const documentExpeditionCityData: any = await this.apiSv.getCity(user.documentExpeditionCityId);
          // get documentExpeditionCity
          const documentExpeditionCity: CityInterface = documentExpeditionCityData.data;
          // get state data
          const documentExpeditionCountryId = documentExpeditionCity.countryId;
          // get state data
          const documentExpeditionStateId = documentExpeditionCity.stateId;
          // set document expedition data
          this.mainFrm.controls.documentExpeditionCountry.setValue(documentExpeditionCountryId);
          this.mainFrm.controls.documentExpeditionState.setValue(documentExpeditionStateId);
          this.mainFrm.controls.documentExpeditionCity.setValue(user.documentExpeditionCityId);
          // select locations
          await this.selectCountry('documentExpedition');
          await this.selectState('documentExpedition');
          await this.selectCity('documentExpedition');
        }
        // get documentExpeditionDate
        const documentExpeditionDate = moment.utc(user.documentExpeditionDate).format('YYYY-MM-DD');
        // set documentExpeditionDate
        this.mainFrm.controls.documentExpeditionDate.setValue(documentExpeditionDate);
      }
      // update userData
      this.userData = user;
      // check origin
      if (this.origin === 'userB2C' || this.origin === 'simulator') {
        // save data user on local storage
        localStorage.setItem('userData', JSON.stringify(user));
      } else if (this.origin === 'userB2B') {
        // save data user on local storage
        localStorage.setItem('loanUserData', JSON.stringify(user));
      }
      // set savedData
      this.savedData = true;
    }
  }
  async clearUserData() {
    // set avatar
    this.avatarSource = null;
    // set form data
    this.mainFrm.controls.email.setValue(null);
    this.mainFrm.controls.cellphoneCountryCode.setValue(null);
    this.mainFrm.controls.cellphone.setValue(null);
    this.mainFrm.controls.phoneCountryCode.setValue(null);
    this.mainFrm.controls.phone.setValue(null);
    this.mainFrm.controls.firstName.setValue(null);
    this.mainFrm.controls.secondName.setValue(null);
    this.mainFrm.controls.lastName.setValue(null);
    this.mainFrm.controls.surname.setValue(null);
    this.mainFrm.controls.documentType.setValue(null);
    this.mainFrm.controls.documentNumber.setValue(null);
    this.mainFrm.controls.gender.setValue(null);
    this.mainFrm.controls.civilState.setValue(null);
    this.mainFrm.controls.roles.setValue(null);
    this.mainFrm.controls.status.setValue(null);
    this.mainFrm.controls.nationality.setValue(null);
    this.mainFrm.controls.birthDate.setValue(null);
    this.mainFrm.controls.birthPlaceCountry.setValue(null);
    this.mainFrm.controls.birthPlaceState.setValue(null);
    this.mainFrm.controls.birthPlaceCity.setValue(null);
    this.mainFrm.controls.documentExpeditionDate.setValue(null);
    this.mainFrm.controls.documentExpeditionCountry.setValue(null);
    this.mainFrm.controls.documentExpeditionState.setValue(null);
    this.mainFrm.controls.documentExpeditionCity.setValue(null);
    // update userData
    this.userData = null;
    // check origin
    if (this.origin === 'userB2C') {
      // remove data user on local storage
      localStorage.removeItem('userData');
      localStorage.removeItem('token');
    } else if (this.origin === 'userB2B') {
      // remove data user on local storage
      localStorage.removeItem('loanUserData');
    }
    localStorage.removeItem('userFound');
    // set savedData
    this.savedData = false;
  }
  // actions
  async save() {
    // clear validations
    this.noPassBirthAgeValidation = false;
    this.emailStatus = null;
    // revalidate
    await this.checkEmail();
    await this.checkPhoneNumber('cellphone');
    await this.checkPhoneNumber('phone');
    // markAllAsTouched
    this.mainFrm.markAllAsTouched();
    // validate data
    if (!this.mainFrm.valid) {
      // send alert
      this.alertSv.showMessage(this.translate.instant('HELPERS.requiredFields'),'warning', this.translate.instant('HELPERS.warning'), true);
    } else {
      // check if user is been saved before
      if (!this.savedData) {
        // check email
        if (this.emailStatus !== 'not_found') {
          // send alert
          this.alertSv.showMessage(this.translate.instant('ERRORS.email_exists'),'error', this.translate.instant('HELPERS.error'), true);
        } else {
          // add user
          await this.addUser();
        }
      } else {
        // update user
        await this.updateUser();
      }
    }
  }
  async addUser() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // get currentDateTime
      const currentDateTime = moment().toISOString();
      // get user name data
      const firstName = this.mainFrm.controls.firstName.value;
      const secondName = this.mainFrm.controls.secondName.value;
      const lastName = this.mainFrm.controls.lastName.value;
      const surname = this.mainFrm.controls.surname.value;
      // set userRoles
      const userRoles: any[] = [];
      const userRolesFrm: any[] = [];
      // init currentRole
      let currentRole: any = null;
      // check origin
      if (this.origin === 'userB2C' || this.origin === 'userB2B' || this.origin === 'simulator') {
        // set currentRole
        currentRole = {
          roleName: 'client',
          roleActive: true,
          dateAssigned: currentDateTime
        }
        userRoles.push(currentRole);
        userRolesFrm.push(currentRole.roleName);
        // update form userRoles
        this.mainFrm.controls.roles.setValue(userRoles);
        if (this.origin === 'simulator') {
          // get birthDate
          const birthDate = moment.utc('1980-01-01').format('YYYY-MM-DD');
          // set birthDate
          this.mainFrm.controls.birthDate.setValue(birthDate);
        }
      } else if (this.origin === 'addUser') {
        // init roles
        let roles: any = this.mainFrm.controls.roles.value;
        // check roles type
        if (typeof roles === 'string') {
          // transform roles to json
          roles = JSON.parse(roles);
        }
        // loop roles
        roles.map((role: string) => {
          // set roleData
          const roleData = {
            roleName: role,
            roleActive: true,
            dateAssigned: currentDateTime
          };
          // push userRole
          userRoles.push(roleData);
          userRolesFrm.push(role);
        });
        // get currentRole
        currentRole = this.utilitiesSv.getGreaterActiveRole(userRoles);
        // update form userRoles
        this.mainFrm.controls.roles.setValue(userRolesFrm);
      }
      // init userStatus
      let userStatus: string = null;
      // check userStatus
      if (this.origin === 'userB2B') {
        userStatus = 'active';
      } else {
        // check userStatus
        if (this.origin !== 'userB2C') {
          userStatus = this.mainFrm.controls.status.value;
        } else {
          userStatus = 'active';
        }
      }
      // set userData
      const userData: UserInterface = {
        email: this.mainFrm.controls.email.value,
        cellphoneCountryCode: this.mainFrm.controls.cellphoneCountryCode.value,
        cellphone: this.mainFrm.controls.cellphone.value,
        ...((this.origin !== 'userB2C' && this.mainFrm.controls.phone.value !== null) && {userStatus: this.mainFrm.controls.phoneCountryCode.value}),
        ...((this.origin !== 'userB2C' && this.mainFrm.controls.phone.value !== null) && {userStatus: this.mainFrm.controls.phone.value}),
        firstName: firstName !== null ? this.utilitiesSv.capitalizeString(firstName) : null,
        secondName: secondName !== null ? this.utilitiesSv.capitalizeString(secondName) : null,
        lastName: lastName !== null ? this.utilitiesSv.capitalizeString(lastName) : null,
        surname: surname !== null ? this.utilitiesSv.capitalizeString(surname) : null,
        documentType: this.mainFrm.controls.documentType.value,
        documentNumber: this.mainFrm.controls.documentNumber.value,
        documentExpeditionCityId: this.mainFrm.controls.documentExpeditionCity.value,
        documentExpeditionDate: this.mainFrm.controls.documentExpeditionDate.value,
        nationalityId: this.mainFrm.controls.nationality.value,
        birthDate: this.mainFrm.controls.birthDate.value,
        birthPlaceId: this.mainFrm.controls.birthPlaceCity.value,
        gender: this.mainFrm.controls.gender.value,
        civilState: this.mainFrm.controls.civilState.value,
        registerFrom: this.origin === 'userB2C' || this.origin === 'userB2B' || this.origin === 'simulator' ? 'form' : 'company',
        ...((userRoles.length > 0 && !(this.origin === 'userB2C' || this.origin === 'simulator')) && {userRoles: JSON.stringify(userRoles)}),
        ...((userStatus !== null) && {userStatus: userStatus}),
      }
      // init auxUser
      let auxUser: any = null;
      // init data
      let data: any = null;
      // init userId
      let userId: string = null;
      // check origin
      if (this.origin === 'userB2C') {
        // add auxUser
        auxUser = await this.apiSv.addPublicUser(userData);
        // set token
        const token: any = auxUser.data;
        // decode user data
        const userDecodedData: any = await this.encodingSv.decodeToken(token);
        // set userId
        userId = userDecodedData.id;
        // save token on storage
        localStorage.setItem('token', token);
      } else if (this.origin === 'simulator') {
        // add auxUser
        auxUser = await this.apiSv.addPublicUser(userData);
        // set token
        const token: any = auxUser.data;
        // decode user data
        const userDecodedData: any = await this.encodingSv.decodeToken(token);
        // set userId
        userId = userDecodedData.id;
        // save token on storage
        localStorage.setItem('token', token);
      } else {
        // add auxUser
        auxUser = await this.apiSv.addUser(userData);
        // set data
        data = auxUser.data; 
        // set userId
        userId = data.id;
      }
      // set savedData
      this.savedData = true;
      // set saveUserData
      const saveUserData: UserInterface = {
        id: userId,
        avatar: this.avatarSource,
        email: this.mainFrm.controls.email.value,
        cellphoneCountryCode: this.mainFrm.controls.cellphoneCountryCode.value,
        cellphone: this.mainFrm.controls.cellphone.value,
        firstName: firstName !== null ? this.utilitiesSv.capitalizeString(firstName) : null,
        secondName: secondName !== null ? this.utilitiesSv.capitalizeString(secondName) : null,
        lastName: lastName !== null ? this.utilitiesSv.capitalizeString(lastName) : null,
        surname: surname !== null ? this.utilitiesSv.capitalizeString(surname) : null,
        documentType: this.mainFrm.controls.documentType.value,
        documentNumber: this.mainFrm.controls.documentNumber.value,
        documentExpeditionCityId: this.mainFrm.controls.documentExpeditionCity.value,
        documentExpeditionDate: this.mainFrm.controls.documentExpeditionDate.value,
        nationalityId: this.mainFrm.controls.nationality.value,
        birthDate: this.mainFrm.controls.birthDate.value,
        birthPlaceId: this.mainFrm.controls.birthPlaceCity.value,
        gender: this.mainFrm.controls.gender.value,
        civilState: this.mainFrm.controls.civilState.value,
        role: currentRole.roleName,
        userRole: currentRole,
        userRoles: userRoles,
        userFound: this.userFound,
        ...((this.origin === 'simulator') && {userAddedOnSimulator: this.userAddedOnSimulator}),
      }
      // update userData
      this.userData = saveUserData;
      // check origin
      if (this.origin === 'userB2C' || this.origin === 'simulator' || this.origin === 'editProfile') {
        // save data user on local storage
        localStorage.setItem('userData', JSON.stringify(this.userData));
      } else if (this.origin === 'userB2B') {
        // save data user on local storage
        localStorage.setItem('loanUserData', JSON.stringify(this.userData));
      } else if (this.origin === 'addUser') {
        // check email
        const checkEmail: any = await this.apiSv.checkEmail(this.mainFrm.controls.email.value);
        // set emailStatus
        const emailStatus = checkEmail.data.statusEmail;
        // check emailStatus
        if (emailStatus !== 'not_found') {
          // clear userData
          await this.clearUserData();
          // get token
          const token = emailStatus;
          // save token on storage
          localStorage.setItem('token', token);
        }
      }
      // hide loader
      this.loaderEvent.emit(null);
      // check origin
      if (this.origin === 'simulator') {
        // emit data
        this.saveSimulatorEvent.emit(this.userData);
      } else {
        // emit data
        this.addEvent.emit(saveUserData);
      }
      
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async updateUser() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // get currentDateTime
      const currentDateTime = moment().toISOString();
      // get user name data
      const firstName = this.mainFrm.controls.firstName.value;
      const secondName = this.mainFrm.controls.secondName.value;
      const lastName = this.mainFrm.controls.lastName.value;
      const surname = this.mainFrm.controls.surname.value;
      // set userRoles
      const userRoles: any[] = [];
      const userRolesFrm: any[] = [];
      // init currentRole
      let currentRole: any = null;
      // check origin
      if (this.origin === 'userB2C' || this.origin === 'userB2B' || this.origin === 'simulator') {
        // update form userRoles
        this.mainFrm.controls.roles.setValue(this.userData.userRoles);
        // loop roles
        this.userData.userRoles.map((role: any) => {
          // push userRole
          userRoles.push(role);
          userRolesFrm.push(role.roleName);
        });
        // get currentRole
        currentRole = this.utilitiesSv.getGreaterActiveRole(userRoles);
      } else if (this.origin === 'editProfile' || this.origin === 'editUser' || this.origin === 'editUserLoan') {
        // init roles
        let roles: any = this.mainFrm.controls.roles.value;
        // check roles type
        if (typeof roles === 'string') {
          // transform roles to json
          roles = JSON.parse(roles);
        }
        // loop roles
        roles.map((role: string) => {
          // set roleData
          const roleData = {
            roleName: role,
            roleActive: true,
            dateAssigned: currentDateTime
          };
          // push userRole
          userRoles.push(roleData);
          userRolesFrm.push(role);
        });
        // get currentRole
        currentRole = this.utilitiesSv.getGreaterActiveRole(userRoles);
        // update form userRoles
        this.mainFrm.controls.roles.setValue(userRolesFrm);
      }
      // init userStatus
      let userStatus: string = null;
      // check userStatus
      if (this.origin === 'userB2B') {
        userStatus = 'pending';
      } else {
        // check userStatus
        if (this.origin !== 'userB2C') {
          userStatus = this.mainFrm.controls.status.value;
        }
      }
      // set userData
      const userData: UserInterface = {
        email: this.mainFrm.controls.email.value,
        cellphoneCountryCode: this.mainFrm.controls.cellphoneCountryCode.value,
        cellphone: this.mainFrm.controls.cellphone.value,
        ...((this.mainFrm.controls.phone.value !== null) && {userStatus: this.mainFrm.controls.phoneCountryCode.value}),
        ...((this.mainFrm.controls.phone.value !== null) && {userStatus: this.mainFrm.controls.phone.value}),
        firstName: firstName !== null ? this.utilitiesSv.capitalizeString(firstName) : null,
        secondName: secondName !== null ? this.utilitiesSv.capitalizeString(secondName) : null,
        lastName: lastName !== null ? this.utilitiesSv.capitalizeString(lastName) : null,
        surname: surname !== null ? this.utilitiesSv.capitalizeString(surname) : null,
        ...((this.mainFrm.controls.documentType.value !== null) && {documentType:this.mainFrm.controls.documentType.value}),
        ...((this.mainFrm.controls.documentNumber.value !== null) && {documentNumber:this.mainFrm.controls.documentNumber.value}),
        ...((this.mainFrm.controls.documentExpeditionCity.value !== null) && {documentExpeditionCityId:this.mainFrm.controls.documentExpeditionCity.value}),
        ...((this.mainFrm.controls.documentExpeditionDate.value !== null) && {documentExpeditionDate:this.mainFrm.controls.documentExpeditionDate.value}),
        ...((this.mainFrm.controls.nationality.value !== null) && {nationalityId:this.mainFrm.controls.nationality.value}),
        ...((this.mainFrm.controls.birthDate.value !== null && this.mainFrm.controls.birthDate.value !== 'Invalid date') && {birthDate:this.mainFrm.controls.birthDate.value}),
        ...((this.mainFrm.controls.birthPlaceCity.value !== null) && {birthPlaceId:this.mainFrm.controls.birthPlaceCity.value}),
        ...((this.mainFrm.controls.gender.value !== null) && {gender:this.mainFrm.controls.gender.value}),
        ...((this.mainFrm.controls.civilState.value !== null) && {civilState:this.mainFrm.controls.civilState.value}),
        ...((userRoles.length > 0 && !(this.origin === 'userB2C' || this.origin === 'simulator')) && {userRoles: JSON.stringify(userRoles)}),
        ...((userStatus !== null) && {userStatus: userStatus}),
      }
      // init auxUser
      let auxUser: any = null;
      // check origin
      if (this.origin === 'userB2C') {
        // update auxUser
        auxUser = await this.apiSv.updatePublicUser(userData);
        // get stored data user
        const storedDataUser: any = localStorage.getItem('userData');
        // get data user
        const userStoredData: UserInterface = JSON.parse(storedDataUser);
        // get userData
        this.userData = userStoredData;
      } else if (this.origin === 'simulator') {
        // get stored data user
        const storedDataUser: any = localStorage.getItem('userData');
        // get data user
        const userStoredData: UserInterface = JSON.parse(storedDataUser);
        // get userData
        this.userData = userStoredData;
      } else {
        // update auxUser
        auxUser = await this.apiSv.updateUser(this.userData.id, userData);
      }
      // update userData
      this.userData.avatar = this.avatarSource;
      this.userData.email = this.mainFrm.controls.email.value;
      this.userData.phoneCountryCode = this.mainFrm.controls.phoneCountryCode.value;
      this.userData.phone = this.mainFrm.controls.phone.value;
      this.userData.cellphoneCountryCode = this.mainFrm.controls.cellphoneCountryCode.value;
      this.userData.cellphone = this.mainFrm.controls.cellphone.value;
      this.userData.firstName = firstName !== null ? this.utilitiesSv.capitalizeString(firstName) : null;
      this.userData.secondName = secondName !== null ? this.utilitiesSv.capitalizeString(secondName) : null;
      this.userData.lastName = lastName !== null ? this.utilitiesSv.capitalizeString(lastName) : null;
      this.userData.surname = surname !== null ? this.utilitiesSv.capitalizeString(surname) : null;
      this.userData.documentType = this.mainFrm.controls.documentType.value;
      this.userData.documentNumber = this.mainFrm.controls.documentNumber.value;
      this.userData.documentExpeditionCountry = this.mainFrm.controls.documentExpeditionCountry.value;
      this.userData.documentExpeditionState = this.mainFrm.controls.documentExpeditionState.value;
      this.userData.documentExpeditionCity = this.mainFrm.controls.documentExpeditionCity.value;
      this.userData.documentExpeditionCityId = this.mainFrm.controls.documentExpeditionCity.value;
      this.userData.documentExpeditionDate = this.mainFrm.controls.documentExpeditionDate.value;
      this.userData.nationalityId = this.mainFrm.controls.nationality.value;
      this.userData.birthDate = this.mainFrm.controls.birthDate.value;
      this.userData.birthPlaceCountry = this.mainFrm.controls.birthPlaceCountry.value;
      this.userData.birthPlaceState = this.mainFrm.controls.birthPlaceState.value;
      this.userData.birthPlaceCity = this.mainFrm.controls.birthPlaceCity.value;
      this.userData.birthPlaceId = this.mainFrm.controls.birthPlaceCity.value;
      this.userData.gender = this.mainFrm.controls.gender.value;
      this.userData.civilState = this.mainFrm.controls.civilState.value;
      this.userData.role = currentRole.roleName;
      this.userData.userRole = currentRole;
      this.userData.userRoles = userRoles;
      this.userData.userStatus = this.mainFrm.controls.status.value;
      this.userData.userFound = this.userFound;
      this.userData.userAddedOnSimulator = this.userAddedOnSimulator;
      // check origin
      if (this.origin === 'userB2C' || this.origin === 'simulator') {
        // save data user on local storage
        localStorage.setItem('userData', JSON.stringify(this.userData));
      } else if (this.origin === 'editProfile') {
        // save data user on local storage
        localStorage.setItem('userData', JSON.stringify(this.userData));
      } else if (this.origin === 'userB2C') {
        // save data user on local storage
        localStorage.setItem('loanUserData', JSON.stringify(this.userData));
      }
      // check origin
      if (this.origin === 'editProfile') {

        // TODO: CHECK EDIT PROFILE
        // // check email
        // const checkEmail: any = await this.apiSv.checkEmail(this.mainFrm.controls.email.value);
        // // set emailStatus
        // const emailStatus = checkEmail.data.statusEmail;
        // // check emailStatus
        // if (emailStatus !== 'not_found') {
        //   // clear userData
        //   await this.clearUserData();
        //   // get token
        //   const token = emailStatus;
        //   // save token on storage
        //   localStorage.setItem('token', token);
        // }

      }

      // get passwords
      const password = this.pwdFrm.controls.password.value;
      // get check role
      const checkRole = await this.utilitiesSv.checkRoles(this.currentUserData.role, ['support', 'administrator']);

      console.log('checkRole', checkRole);

      // check role
      if (checkRole) {
        // validate pwd data
        if (!this.pwdFrm.valid) {
          // send alert
          this.alertSv.showMessage(this.translate.instant('SER.formValidation.password.check'),'warning', this.translate.instant('HELPERS.warning'), true);
        } else {
          // check if is my own user
          if (this.currentUserData.id === this.userData.id) {
            // set data
            const changeMyPwdData: any = {
              password
            };
            // update pwd
            const auxChange: any = await this.apiSv.changePassword(changeMyPwdData);
            // check if aux was success
            if (auxChange.data.accessToken) {
              // save data user on local storage
              localStorage.setItem('token', auxChange.data.accessToken);
            }
          } else {
            // set data
            const changeOtherUserPwdData: any = {
              userId: this.userData.id,
              password
            };
            // update pwd
            await this.apiSv.updatePassword(changeOtherUserPwdData);
          }
        }
      } else {
        // check if is my own user
        if (this.currentUserData.id === this.userData.id) {
          // validate pwd data
          if (!this.pwdFrm.valid) {
            // send alert
            this.alertSv.showMessage(this.translate.instant('SER.formValidation.password.check'),'warning', this.translate.instant('HELPERS.warning'), true);
          } else {
            // set data
            const changeMyPwdData: any = {
              password
            };
            // update pwd
            const auxChange: any = await this.apiSv.changePassword(changeMyPwdData);
            // check if aux was success
            if (auxChange.data.accessToken) {
              // save data user on local storage
              localStorage.setItem('token', auxChange.data.accessToken);
            }
          }
        }
      }
      // check if is my own user
      if (this.currentUserData.id === this.userData.id) {
        // update profile info
        const action: Action = {
          type: 'UPDATEPROFILE',
        }
        this.updateAppData.dispatch(action);
      }
      // hide loader
      this.loaderEvent.emit(null);
      // check origin
      if (this.origin === 'simulator') {
        // emit data
        this.saveSimulatorEvent.emit(this.userData);
      } else {
        // emit data
        this.updateEvent.emit(this.userData);
      }
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  // avatar
  async editAvatar() {
    // trigger hidden input file
    this.fileInput.nativeElement.click();
  }
  async onFileSelected(event: any, modal: any) {
    const file = event.target.files?.[0];
    if (file) {
      // check fileType
      if (file.type.startsWith('image/')) {
        // get uploaded file data
        const fileSize = file.size;
        // check file size
        if (fileSize >= environment.maxFileData) {
          // hide loader
          this.loaderEvent.emit(null);
          // show loader
          this.alertSv.showMessage(this.translate.instant('HELPERS.fileTooBig'),'error', this.translate.instant('HELPERS.error'), true);
        } else {
          // set avatar changed event
          this.avatarChangedEvent = event;

          this.showCropper = true;
          
          // open crop modal
          this.modalService.open(modal);
        }
      } else {
        // hide loader
        this.loaderEvent.emit(null);
        // show loader
        this.alertSv.showMessage(this.translate.instant('HELPERS.fileNoImage'),'error', this.translate.instant('HELPERS.error'), true);
      }
    } else {
      // hide loader
      this.loaderEvent.emit(null);
    }
  }
  async uploadFile(file: any) {
    const avatarData = new FormData()
    avatarData.append('file', file);
    // send file to api
    const avatarFile: any = await this.apiSv.uploadFile(avatarData);
    // get file data
    const fileData: any = avatarFile.data;
    const filePath = fileData.filePath;
    // save file
    this.updateAvatar(filePath);
  }
  async updateAvatar(filePath: string) {
    try {
      // set userData
      const userData: UserInterface = {
        avatar: filePath,
      }
      // update avatar
      await this.apiSv.updateUser(this.userData.id, userData);
      // update avatar source
      this.avatarSource = filePath;
      // hide loader
      this.loaderEvent.emit(null);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  avatarCroppingClose() {
    // clear data
    this.avatarFile = null;
    this.avatarChangedEvent = null;
    // hide modal
    this.modalService.dismissAll();
  }
  async avatarCroppingFinish() {
    // hide modal
    this.modalService.dismissAll();
    // show loader
    this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
    // clear data
    this.avatarChangedEvent = null;
    // get base 64 data
    const b64Data = this.avatarSource;
    // get file data
    const firstName = this.mainFrm.controls.firstName.value;
    const lastName = this.mainFrm.controls.lastName.value;
    const fileName = (firstName !== null ? await this.utilitiesSv.slugify(firstName) : null) + (lastName !== null ? '-' + await this.utilitiesSv.slugify(lastName) + '-' : null) + 'avatar.png';
    var file = this.mediaSv.dataURLtoFile(b64Data, fileName);
    // upload file
    await this.uploadFile(file);
  }
  // cropping media
  imageCropped(event: ImageCroppedEvent) {
    this.avatarSource = event.base64;
  }
  imageLoadImageFailed() {
    // show message
    this.alertSv.showMessage(this.translate.instant('HELPERS.fileLoadImageError'),'error', this.translate.instant('HELPERS.error'), true);
  }
  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }
  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }
  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }
  flipHorizontal() {
    this.transform = {
        ...this.transform,
        flipH: !this.transform.flipH
    };
  }
  flipVertical() {
    this.transform = {
        ...this.transform,
        flipV: !this.transform.flipV
    };
  }
  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }
  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }
}
