import { Component } from '@angular/core';
import * as chartData from '../../../shared/data/dashboard/default'

@Component({
  selector: 'app-loans-resume',
  templateUrl: './loans-resume.component.html',
  styleUrls: ['./loans-resume.component.scss']
})
export class LoansResumeComponent {

  public recentOrders = chartData.recentOrders;
  public show : boolean = false
  constructor() { }

  toggle(){
    this.show = !this.show
  }
}
