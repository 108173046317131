import { Action } from '@ngrx/store';
export function companyReducer(state: boolean = false, action: Action) {
  switch (action.type) {
    case 'UPDATECOMPANY': {
      return true;
    }
    case 'NOUPDATECOMPANY': {
      return false;
    }
    default: {
      return state;
    }
  }
}
