// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 25/05/2023
// import
import { Component, EventEmitter, OnInit, OnChanges, Input, Output, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
// translate
import { TranslateService } from '@ngx-translate/core';
// services
import { AuthService } from 'src/app/shared/services/auth.service';
import { EncodingService } from 'src/app/shared/services/encoding.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { LocationService } from 'src/app/shared/services/location.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
// interfaces
import { ProfessionInterface } from 'src/app/shared/interfaces/profession.interface';
// component
@Component({
  selector: 'app-profession-form',
  templateUrl: './profession-form.component.html',
  styleUrls: ['./profession-form.component.scss']
})
// class
export class ProfessionFormComponent implements OnInit, OnChanges {
  // variables
  @Input() professionData: ProfessionInterface = null;
  @Output() loaderEvent = new EventEmitter();
  @Output() addEvent = new EventEmitter();
  @Output() updateEvent = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();
  // lang data
  currentLang: string = null;
  // form data
  public mainFrm: FormGroup;
  // messages data
  formErrorMessages: any = {};
  // help data
  toggleHelp = false;
  // saved data
  savedData: boolean = false;
  // profession data
  professionId: string = null;
  professionName: string = null;
  // constructor
  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public authSv: AuthService,
    public encodingSv: EncodingService,
    public apiSv: ApiService,
    public locationSv: LocationService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    private utilitiesSv: UtilitiesService,
  ) {
    // main form
    this.mainFrm = this.formBuilder.group({
      professionName: [null, [Validators.required]],
      professionStatus: [null, [Validators.required]],
    });
  }
  // life cycle
  async ngOnInit() {
    await this.translateMsgs();
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.professionData) {
      // check for professionData
      if (this.professionData !== null) {
        this.initData();
      }
    }
  }
  async initData() {
    // show loader
    this.loaderEvent.emit(this.translate.instant('HELPERS.loadingTxt'));
    // get data
    await this.setProfessionData();
    // hide loader
    this.loaderEvent.emit(null);
  }
  // translate
  async translateMsgs() {
    // update form error messages
    this.formErrorMessages = {
      professionName: [
        { type: 'required', message: this.translate.instant('PROFESSION.formValidation.professionName.required') }
      ],
      professionStatus: [
        { type: 'required', message: this.translate.instant('PROFESSION.formValidation.professionStatus.required') }
      ],
    };
  }
  // form
  async help() {
    if (this.toggleHelp) {
      this.toggleHelp = false;
    } else {
      this.toggleHelp = true;
    }
  }
  async setFocus(elementId: any) {
    elementId.focus();
  }
  checkFormField(fieldName: string) {
    // get field
    const field = this.mainFrm.get(fieldName);
    // check field
    const check = field?.invalid && field?.touched;
    // return data
    return check;
  }
  // navigation
  async cancel() {
    // emit data
    this.cancelEvent.emit(); // Return dat
  }
  async setProfessionData() {
    // check profession data
    if (this.professionData !== null) {
      // set profession
      const profession: ProfessionInterface = this.professionData;
      // set profession data
      this.professionId = profession.id;
      this.professionName = profession.professionName;
      // set form data
      this.mainFrm.controls.professionName.setValue(profession.professionName);
      this.mainFrm.controls.professionStatus.setValue(profession.professionStatus);
      // set savedData
      this.savedData = true;
    }
  }
  // actions
  async save() {
    // markAllAsTouched
    this.mainFrm.markAllAsTouched();
    // validate data
    if (!this.mainFrm.valid) {
      // send alert
      this.alertSv.showMessage(this.translate.instant('HELPERS.requiredFields'),'warning', this.translate.instant('HELPERS.warning'), true);
    } else {
      // check if data is been saved before
      if (!this.savedData) {
        // add data
        await this.addProfession();
      } else {
        // update data
        await this.updateProfession();
      }
    }
  }
  async addProfession() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // get name data
      const professionName = this.utilitiesSv.capitalizeString(this.mainFrm.controls.professionName.value);
      // set professionData
      const professionData: ProfessionInterface = {
        professionName,
        professionStatus: this.mainFrm.controls.professionStatus.value,
      }
      // get auxProfession
      const auxProfession: any = await this.apiSv.addProfession(professionData);
      // get data
      const data: any = auxProfession.data;
      // get professionId
      const professionId: string = data.id;
      // update professionId
      this.professionId = professionId;
      // set savedData
      this.savedData = true;
      // set saveProfessionData
      const saveProfessionData: ProfessionInterface = {
        id: professionId,
        professionName: this.mainFrm.controls.professionName.value,
        professionStatus: this.mainFrm.controls.professionStatus.value
      }
      // update professionData
      this.professionData = saveProfessionData;
      // hide loader
      this.loaderEvent.emit(null);
      // emit data
      this.addEvent.emit(saveProfessionData);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async updateProfession() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // get name data
      const professionName = this.utilitiesSv.capitalizeString(this.mainFrm.controls.professionName.value);
      // set professionData
      const professionData: ProfessionInterface = {
        professionName,
        professionStatus: this.mainFrm.controls.professionStatus.value
      }
      // update profession
      await this.apiSv.updateProfession(this.professionData.id, professionData);
      // update professionData
      this.professionData.professionName = professionName;
      this.professionData.professionStatus = this.mainFrm.controls.professionStatus.value;
      // hide loader
      this.loaderEvent.emit(null);
      // emit data
      this.updateEvent.emit(this.professionData);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
}
