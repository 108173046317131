// author: Alejandro Bermúdez
// company: Think In
// date: 25/05/2023
// tslint:disable: max-line-length
// tslint:disable: no-unused-expression
// import
import { Injectable } from '@angular/core';
import { Router, RouterEvent, NavigationExtras } from '@angular/router';
// translate
import { TranslateService } from '@ngx-translate/core';
// environment
import { environment } from 'src/environments/environment';
// reducers
import { Action, Store } from '@ngrx/store';
// interfaces
import { LoaderInterface } from 'src/app/shared/reducers/interfaces';
// variables
declare var $: any;
import * as moment from 'moment';
// injectable
@Injectable({
  providedIn: 'root'
})
// class
export class UtilitiesService {
  // variables
  audio: any = new Audio();
  // loader data
  loaderStatus: boolean = false;
  // constructor
  constructor(
    private router: Router,
    public translate: TranslateService,
    private loaderState: Store<LoaderInterface>,
  ) { }
  
  // loader
  // -----------------------------------------------------------------------------------------------------------------------------
  updateLoader(loaderMsg: any) {
    // show loader
    const actionShowStatus: Action = {type: loaderMsg};
    this.loaderState.dispatch(actionShowStatus);
  }
  // role check
  // -----------------------------------------------------------------------------------------------------------------------------
  async checkRoles(currentRole: string, checkRoles: any) {
    let roleMatch = false;
    for (const checkRole of checkRoles) {
      // check for role
      if (checkRole == 'all' || checkRole == currentRole) {
        roleMatch = true;
      }
    }
    return roleMatch;
  }
  getGreaterActiveRole(userRoles: any[]): any | null {
    // set hierarchy
    const hierarchy = [
      'support',
      'administrator',
      'board',
      'businessAgent',
      'treasure',
      'ceo',
      'manager',
      'auxiliar',
      'client'
    ];
    // set finalRole
    let finalRole = null;
    // loop hierarchy
    for (let i = 0; i < hierarchy.length; i++) {
      // set activeRole
      const activeRole = hierarchy[i];
      // set foundRole
      const foundRole = userRoles.find(role => role.roleName === activeRole && role.roleActive === true);
      // check if role was found
      if (foundRole) {
        // set finalRole
        finalRole = foundRole;
        break;
      }
    }
    return finalRole;
  }
  // format
  // -----------------------------------------------------------------------------------------------------------------------------
  padLeadingZeros(num: number, size: number) {
    var s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
  }
  titleCase(str: string) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    // Directly return the joined string
    return splitStr.join(' '); 
  }
  formatBytes(bytes: number, decimals: number = 2) {
    if (!+bytes) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }
  separateStringBySubstring(mainString: string, substring: string): string[] {
    const resultString: string[] = [];
    let startIndex = 0;
    let endIndex = mainString.indexOf(substring);
    while (endIndex !== -1) {
      resultString.push(mainString.substring(startIndex, endIndex));
      startIndex = endIndex + substring.length;
      endIndex = mainString.indexOf(substring, startIndex);
    }
    resultString.push(mainString.substring(startIndex));
    return resultString;
  }
  replaceStringOnString(mainString: string, searchString: string, replaceString: string): string {
    return mainString.replace(new RegExp(searchString, 'g'), replaceString);
  }
  async removeAccent(txt: string) {
    const a = 'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;';
    const b = 'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------';
    const p = new RegExp(a.split('').join('|'), 'g');
    return txt.toString().toLowerCase()
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text
  }
  async removerCharacters(str: string, cant: number, start: boolean) {
    if (start) {
      return str.substring(cant);
    } else {
      return str.substring(0, str.length - cant);
    }
  }
  capitalizeString(str: string): string {
    const exceptions = ['S.A.S.', 'SAS', 'L.L.C.', 'LLC'];
    const words = str.split(' ');
    const capitalizedWords = words.map((word) => {
      // Check if the word is an exception, and if so, leave it in uppercase
      if (exceptions.includes(word.toUpperCase())) {
        return word.toUpperCase();
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
    });
    return capitalizedWords.join(' ');
  }
  convertStringToArray(stringArray: string): any[] {
    try {
      return JSON.parse(stringArray);
    } catch (error) {
      console.error('error', error);
      return [];
    }
  }
  convertArrayToString(array: any) {
    return array.map((item: any) => `"${item}"`).join(', ');
  }
  convertStringToCurrency(stringVal: string): string {
    return stringVal.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
  removeDotsFromCurrencyString(stringVal: string): string {
    return (stringVal !== null) ? stringVal.replace(/\./g, '') : '';
  }
  formatBankAccountString(input: string): string {
    const formatted = input.replace(/(\d{3})(\d{6})(\d{2})/, '$1-$2-$3');
    return formatted;
  }
  formatPhoneNumber(phone: string): string {
    let phoneValue: any = null;
    // check value null or undefined
    if (!(phone == null || phone == undefined)) {
      // check phone length
      if (phone.length == 10) {
        let phoneNumber: any = phone.match(/(\d{3})(\d{3})(\d{4})/);
        phoneNumber = "(" + phoneNumber[1] + ") " + phoneNumber[2] + " " + phoneNumber[3];
        phoneValue = phoneNumber;
      } else {
        phoneValue = phone;
      }
    } else {
      phoneValue = phone;
    }
    // return data
    return phoneValue;
  }
  roundNumber(value: number, roundingMode: 'up' | 'down' | 'none', decimalPlaces: number): number {
    let roundedValue: number;
    if (roundingMode === 'up') {
      roundedValue = Math.ceil(value * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
    } else if (roundingMode === 'down') {
      roundedValue = Math.floor(value * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
    } else {
      roundedValue = Math.round(value * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
    }
    return roundedValue;
  }
  numberToWords(num: number, lang: string): string {
    // spanish version
    const unitsEs: string[] = ['cero', 'un', 'dos', 'tres', 'cuatro', 'cinco', 'seis', 'siete', 'ocho', 'nueve', 'diez', 'once', 'doce', 'trece', 'catorce', 'quince', 'dieciséis', 'diecisiete', 'dieciocho', 'diecinueve'];
    const tensEs: string[] = ['', '', 'veinte', 'treinta', 'cuarenta', 'cincuenta', 'sesenta', 'setenta', 'ochenta', 'noventa'];
    const hundredsEs: string[] = ['', 'ciento', 'doscientos', 'trescientos', 'cuatrocientos', 'quinientos', 'seiscientos', 'setecientos', 'ochocientos', 'novecientos'];
    const chunkNamesEs: string[] = ['', 'mil', 'millón'];
    // english version
    const unitsEn: string[] = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten ', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
    const tensEn: string[] = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
    const hundredsEn: string[] = ['', 'hundred', 'two hundred', 'three hundred', 'four hundred', 'five hundred', 'six hundred', 'seven hundred', 'eight hundred', 'nine hundred'];
    const chunkNamesEn: string[] = ['', 'thousand', 'million'];
    // variables
    const units: string[] = lang === 'es' ? unitsEs : unitsEn;
    const tens: string[] = lang === 'es' ? tensEs : tensEn;
    const hundreds: string[] = lang === 'es' ? hundredsEs : hundredsEn;
    const chunkNames: string[] = lang === 'es' ? chunkNamesEs : chunkNamesEn;
    // function
    function convertChunk(chunk: number): string {
      if (chunk < 20) {
        return units[chunk];
      } else if (chunk >= 20 && chunk < 100) {
        const unit = chunk % 10;
        return tens[Math.floor(chunk / 10)] + (unit > 0 ? (lang === 'es' ? ' y ' : ' and ') + units[unit] : '');
      } else if (chunk >= 100 && chunk < 1000) {
        const tensChunk = chunk % 100;
        const hundred = Math.floor(chunk / 100);
        return hundreds[hundred] + (tensChunk > 0 ? ' ' + convertChunk(tensChunk) : '');
      }
      return '';
    }
    // check num
    if (num === 0) {
      return units[num];
    }
    // set chunks
    const chunks: string[] = [];
    let chunkIndex = 0;
    // eval num
    while (num) {
      const chunk = num % 1000;
      if (chunk !== 0) {
        const chunkWord = convertChunk(chunk);
        const chunkName = chunkNames[chunkIndex];
        chunks.push((chunkIndex === 1 && chunk === 1 ? 'un' : chunkWord) + (chunkIndex === 2 && chunk > 1 ? (lang === 'es' ? ' millones ' : ' millions ') : ' ' + chunkName));
      }
      num = Math.floor(num / 1000);
      chunkIndex++;
    }
    // return
    return chunks.reverse().join(' ');
  }
  getColorsByRepetitions(repetitions: number): string[] {
    // init resultColors
    let resultColors: any[] = [];
    // loop repetitions
    for (let i = 0; i < repetitions; i++) {
      // We use the operator module to obtain the remainder of the division between i and the length of the color array
      const colorIndex = i % environment.colors.chartColors.length;
      resultColors.push(environment.colors.chartColors[colorIndex]);
    }
    // return data
    return resultColors;
  }
  // calcs
  // -----------------------------------------------------------------------------------------------------------------------------
  calculateLoanPayment(paymentInterestPercentage: number, totalPeriods: number, loanAmount: number): number {
    // get monthly interest rate
    const monthlyInterestRate = paymentInterestPercentage / 100;
    // calcs monthly payment using compound interest
    const numerator = monthlyInterestRate * Math.pow(1 + monthlyInterestRate, totalPeriods);
    const denominator = Math.pow(1 + monthlyInterestRate, totalPeriods) - 1;
    const monthlyPayment = -loanAmount * (numerator / denominator);
    // returns data
    return monthlyPayment;
  }
  // date - time
  // -----------------------------------------------------------------------------------------------------------------------------
  compareTwoDates(dateStart: string, dateEnd: string) {
    if (new Date(dateEnd) < new Date(dateStart)) {
      return true;
    } else {
      return false;
    }
  }
  verifyDateRange(date: Date, range: number, direction: string, unit: moment.unitOfTime.Diff): boolean {
    // unit: years, months, weeks, days, hours, minutes, seconds
    const currentDate = moment();
    const dateCompare = moment(date);
    const dateDifference = currentDate.diff(dateCompare, unit);
    // init chk
    let chk: any = null;
    // check direction
    switch (direction) {
      case 'up':
        // check range
        if (dateDifference >= range) {
          chk = true;
        } else {
          chk = false;
        }
        break;
      case 'down':
        // check range
        if (dateDifference <= range) {
          chk = true;
        } else {
          chk = false;
        }
        break;
      }
      // return data
      return chk;
  }
  getObjectYears(yearInit: any) {
    const years = [];
    const dt = new Date();
    const diff = dt.getFullYear() + (dt.getFullYear() - yearInit);
    for (let i = yearInit; i <= diff; i++) {
      years.push({
        value: i,
        label: i,
      });
    }
    return years;
  }
  getLastDayOfMonth(dateStr: string): string {
    const lastDay = moment(dateStr, 'YYYY-MM-DD').endOf('month');
    return lastDay.format('YYYY-MM-DD');
  }
  addToDate(dateStr: string, amount: number, unit: 'minutes' | 'hours' | 'days' | 'weeks' | 'months' | 'years'): string {
    const date = moment(dateStr, 'YYYY-MM-DD');
    const newDate = date.add(amount, unit as moment.DurationInputArg2);
    return newDate.format('YYYY-MM-DD');
  }
  async addTimeToDate(date: string, amount: number, unit: moment.unitOfTime.DurationConstructor, format: string) {
    const newDate = moment(date).add(amount, unit);
    return newDate.format(format);
  }
  async getAge(birthDate: Date) {
    const now = moment();
    const birthMoment = moment(birthDate, 'YYYY-MM-DD');
    const years = now.diff(birthMoment, 'years');
    return years;
  }
  timeElapsed(date: string): string {
    const nowTime = moment();
    const dateMoment = moment(date);
    const diff = nowTime.diff(dateMoment);
    // get tiem date
    const minutes = moment.duration(diff).asMinutes();
    const hours = moment.duration(diff).asHours();
    const days = moment.duration(diff).asDays();
    const months = moment.duration(diff).asMonths();
    const years = moment.duration(diff).asYears();
    // check time
    if (minutes === 1) {
      return this.translate.instant('DATEDATA.ago') + ' ' + Math.round(minutes) + ' ' + this.translate.instant('DATEDATA.minutes.single');
    } else if (minutes > 1  && minutes < 60) {
      return this.translate.instant('DATEDATA.ago') + ' ' + Math.round(minutes) + ' ' + this.translate.instant('DATEDATA.minutes.multiple');
    } else if (hours === 1) {
      return this.translate.instant('DATEDATA.ago') + ' ' + Math.round(hours) + ' ' + this.translate.instant('DATEDATA.hours.single');
    } else if (hours > 1 && hours < 24) {
      return this.translate.instant('DATEDATA.ago') + ' ' + Math.round(hours) + ' ' + this.translate.instant('DATEDATA.hours.multiple');
    } else if (days === 1) {
      return this.translate.instant('DATEDATA.ago') + ' ' + Math.round(days) + ' ' + this.translate.instant('DATEDATA.days.single');
    } else if (days > 1 && days < 30) {
      return this.translate.instant('DATEDATA.ago') + ' ' + Math.round(days) + ' ' + this.translate.instant('DATEDATA.days.multiple');
    } else if (months === 1) {
      return this.translate.instant('DATEDATA.ago') + ' ' + Math.round(months) + ' ' + this.translate.instant('DATEDATA.months.single');
    } else if (months > 1 && months < 12) {
      return this.translate.instant('DATEDATA.ago') + ' ' + Math.round(months) + ' ' + this.translate.instant('DATEDATA.months.multiple');
    } else if (years === 1) {
      return this.translate.instant('DATEDATA.ago') + ' ' + Math.round(years) + ' ' + this.translate.instant('DATEDATA.years.single');
    } else {
      return this.translate.instant('DATEDATA.ago') + ' ' + Math.round(years) + ' ' + this.translate.instant('DATEDATA.years.multiple');
    }
  }
  getTimeQuery(timeQueryOption: string): any {
    // get localLang
    const localLang = this.translate.getDefaultLang();
    // get current date time
    const currentDate = moment().locale(localLang);
    const currentDateTime = moment().locale(localLang);
    // init selected times
    let timeStart: any;
    let timeEnd: any;
    let dateStart: any;
    let dateEnd: any;
    // switch timeQueryOption
    switch (timeQueryOption) {
      case 'today':
        dateStart = currentDate.clone().startOf('day');
        dateEnd = currentDate.clone().endOf('day');
        timeStart = currentDateTime.clone().startOf('day');
        timeEnd = currentDateTime.clone().endOf('day');
        break;
      case 'week':
        dateStart = currentDate.clone().startOf('week');
        dateEnd = currentDate.clone().endOf('week');
        timeStart = currentDateTime.clone().startOf('week');
        timeEnd = currentDateTime.clone().endOf('week');
        break;
      case 'month':
        dateStart = currentDate.clone().startOf('month');
        dateEnd = currentDate.clone().endOf('month');
        timeStart = currentDateTime.clone().startOf('month');
        timeEnd = currentDateTime.clone().endOf('month');
        break;
      case 'trimester':
        dateStart = currentDate.clone().startOf('quarter');
        dateEnd = currentDate.clone().endOf('quarter');
        timeStart = currentDateTime.clone().startOf('quarter');
        timeEnd = currentDateTime.clone().endOf('quarter');
        break;
      case 'semester':
        if (currentDateTime.quarter() <= 2) {
          timeStart = currentDateTime.clone().startOf('year');
          timeEnd = moment(currentDateTime).quarter(2).clone().endOf('quarter');
        } else {
          timeStart = moment(currentDateTime).quarter(3).clone().startOf('quarter');
          timeEnd = currentDateTime.clone().endOf('year');
        }
        if (currentDate.quarter() <= 2) {
          dateStart = currentDate.clone().startOf('year');
          dateEnd = moment(currentDate).quarter(2).clone().endOf('quarter');
        } else {
          dateStart = moment(currentDate).quarter(3).clone().startOf('quarter');
          dateEnd = currentDate.clone().endOf('year');
        }
        break;
      case 'year':
        dateStart = currentDate.clone().startOf('year');
        dateEnd = currentDate.clone().endOf('year');
        timeStart = currentDateTime.clone().startOf('year');
        timeEnd = currentDateTime.clone().endOf('year');
        break;
    }
    return {
      dateStart: dateStart.format('YYYY-MM-DD'),
      dateEnd: dateEnd.format('YYYY-MM-DD'),
      timeStart: timeStart.format('HH:mm'),
      timeEnd: timeEnd.format('HH:mm'),
      dateTimeStart: timeStart.format('YYYY-MM-DD HH:mm'),
      dateTimeEnd: timeEnd.format('YYYY-MM-DD HH:mm')
    };
  }
  getPeriodOfTheYear(periodType: string): number {
    // get current quarter
    const currentQuarter = moment().quarter();
    // init data
    let trimester: number = 0;
    let semester: number = 0;
    // switch current quarter
    switch (currentQuarter) {
      case 1:
        trimester = 1;
        semester = 1;
        break;
      case 2:
        trimester = 2;
        semester = 1;
        break;
      case 3:
        trimester = 3;
        semester = 2;
        break;
      case 4:
        trimester = 4;
        semester = 2;
        break;
    }
    // check periot type
    if (periodType === 'trimester') {
      return trimester;
    } else if (periodType === 'semester') {
      return semester;
    } else {
      return 0;
    }
  }
  getDaysOfCurrentMonth(): any {
    const currentDate = moment();
    const daysInMonth = currentDate.daysInMonth();
    const daysArray = [];
    // loop days
    for (let i = 1; i <= daysInMonth; i++) {
        daysArray.push(i);
    }
    // return data
    return daysArray;
  }
  getTimeLabels(selectedTimeQuery: string): any {
    // init timeLabels
    let timeLabels: any = null;
    // switch selectedTimeQuery
    switch (selectedTimeQuery) {
      case 'today':
        timeLabels = [
          '12:00 am', 
          '1:00 am', 
          '2:00 am', 
          '3:00 am', 
          '4:00 am', 
          '5:00 am', 
          '6:00 am', 
          '7:00 am', 
          '8:00 am', 
          '9:00 am', 
          '10:00 am', 
          '11:00 am', 
          '12:00 pm', 
          '1:00 pm', 
          '2:00 pm', 
          '3:00 pm', 
          '4:00 pm', 
          '5:00 pm', 
          '6:00 pm', 
          '7:00 pm', 
          '8:00 pm', 
          '9:00 pm', 
          '10:00 pm', 
          '11:00 pm'
        ];
        break;
      case 'week':
        timeLabels = [
          this.translate.instant('DATEDATA.days.monday.dayNameShort'), 
          this.translate.instant('DATEDATA.days.tuesday.dayNameShort'), 
          this.translate.instant('DATEDATA.days.wednesday.dayNameShort'), 
          this.translate.instant('DATEDATA.days.thursday.dayNameShort'), 
          this.translate.instant('DATEDATA.days.friday.dayNameShort'), 
          this.translate.instant('DATEDATA.days.saturday.dayNameShort'), 
          this.translate.instant('DATEDATA.days.sunday.dayNameShort')
        ];
        break;
      case 'month':
        timeLabels = this.getDaysOfCurrentMonth();
        break;
      case 'trimester':
        // get 
        const currentTrimester = this.getPeriodOfTheYear('trimester');
        // switch currentTrimester
        switch (currentTrimester) {
          case 1:
            timeLabels = [
              this.translate.instant('DATEDATA.months.january.monthName'), 
              this.translate.instant('DATEDATA.months.february.monthName'), 
              this.translate.instant('DATEDATA.months.march.monthName')
            ];
            break;
          case 2:
            timeLabels = [
              this.translate.instant('DATEDATA.months.april.monthName'), 
              this.translate.instant('DATEDATA.months.may.monthName'), 
              this.translate.instant('DATEDATA.months.jun.monthName')
            ];
            break;
          case 3:
            timeLabels = [
              this.translate.instant('DATEDATA.months.july.monthName'), 
              this.translate.instant('DATEDATA.months.august.monthName'), 
              this.translate.instant('DATEDATA.months.september.monthName')
            ];
            break;
          case 4:
            timeLabels = [
              this.translate.instant('DATEDATA.months.october.monthName'), 
              this.translate.instant('DATEDATA.months.november.monthName'), 
              this.translate.instant('DATEDATA.months.december.monthName')
            ];
            break;
        }
        break;
      case 'semester':
        // get 
        const currentSemester = this.getPeriodOfTheYear('trimester');
        // switch currentSemester
        switch (currentSemester) {
          case 1:
            timeLabels = [
              this.translate.instant('DATEDATA.months.january.monthName'), 
              this.translate.instant('DATEDATA.months.february.monthName'), 
              this.translate.instant('DATEDATA.months.march.monthName'), 
              this.translate.instant('DATEDATA.months.april.monthName'), 
              this.translate.instant('DATEDATA.months.may.monthName'), 
              this.translate.instant('DATEDATA.months.jun.monthName')
            ];
            break;
          case 2:
            timeLabels = [
              this.translate.instant('DATEDATA.months.july.monthName'), 
              this.translate.instant('DATEDATA.months.august.monthName'), 
              this.translate.instant('DATEDATA.months.september.monthName'), 
              this.translate.instant('DATEDATA.months.october.monthName'), 
              this.translate.instant('DATEDATA.months.november.monthName'), 
              this.translate.instant('DATEDATA.months.december.monthName')
            ];
            break;
        }
        break;
      case 'year':
        timeLabels = [
          this.translate.instant('DATEDATA.months.january.monthName'), 
          this.translate.instant('DATEDATA.months.february.monthName'), 
          this.translate.instant('DATEDATA.months.march.monthName'), 
          this.translate.instant('DATEDATA.months.april.monthName'), 
          this.translate.instant('DATEDATA.months.may.monthName'), 
          this.translate.instant('DATEDATA.months.jun.monthName'), 
          this.translate.instant('DATEDATA.months.july.monthName'), 
          this.translate.instant('DATEDATA.months.august.monthName'), 
          this.translate.instant('DATEDATA.months.september.monthName'), 
          this.translate.instant('DATEDATA.months.october.monthName'), 
          this.translate.instant('DATEDATA.months.november.monthName'), 
          this.translate.instant('DATEDATA.months.december.monthName')
        ];
        break;
    }
    return timeLabels;
  }
  // data
  // -----------------------------------------------------------------------------------------------------------------------------
  async shuffleArray(a: any) {
    let j;
    let x;
    let i;
    for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
    }
    return a;
  }
  async randomArrayShuffle(array: any) {
    let currentIndex: any = array.length;
    let randomIndex: any = null;
    let newIndex: any[] = [];
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      newIndex = array[randomIndex];
    }
    return newIndex;
  }
  async dynamicSort(property: any) {
    var sortOrder = 1;
    if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a: any, b: any) {
      /* next line works with strings and numbers, 
      * and you may want to customize it to your needs
      */
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }
  sortArrayByKey(array: any[], key: string): any[] {
    return array.sort((a, b) => {
      if (a[key] < b[key]) {
        return -1;
      }
      if (a[key] > b[key]) {
        return 1;
      }
      return 0;
    });
  }
  sortArrayByKeys(arr: any[], keys: string[], order: "asc" | "desc" = "asc"): any[] {
    return arr.sort((a, b) => {
      let result = 0;
      for (const key of keys) {
        if (a[key] < b[key]) {
          result = -1;
          break;
        } else if (a[key] > b[key]) {
          result = 1;
          break;
        }
      }
      return order === "desc" ? -result : result;
    });
  }
  async generateRandomNumber() {
    const randomNumber = Array(8)
      .fill(null)
      .map(() => Math.round(Math.random() * 8).toString(8))
      .join('');
    return randomNumber
  }
  // actions
  // -----------------------------------------------------------------------------------------------------------------------------
  call(phone: string) {
    console.log('phone: ', phone);
    // this.callNumber.callNumber('+' + phone, true);
  }
  email(email: string) {
    console.log('email: ', email);
    window.open('mailto:' + email);
  }
  openPDF(uri: string) {
    window.open(uri, '_blank');
  }
  externalLink(uri: string) {
    window.open(uri, '_system', 'location=yes');
  }
  externalLinkSameTab(uri: string) {
    window.open(uri, '_self');
  }
  sendWhatsApp(whatsapp: string) {
    const whatsNum = whatsapp.replace(/\s/g, '').substring(1);
    window.open('https://wa.me/' + whatsNum, '_system', 'location=yes');
  }
  sendToZoom(zoom: string) {
    window.open(zoom);
  }
  socialShare(pictureUrl: any, msg: any) {
    // share(message, subject, file, url)
    // this.socialSharing.share(msg, msg, pictureUrl, null);
  }
  async playSound(soundPath: any) {
    // set audio
    this.audio.src = soundPath;
    this.audio.load();
    this.audio.play();
  }
  async pauseSound(soundPath: any) {
    // set audio
    this.audio.pause();
  }
  // url
  // -----------------------------------------------------------------------------------------------------------------------------
  async slugify(txt: string) {
    const a = 'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;';
    const b = 'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------';
    const p = new RegExp(a.split('').join('|'), 'g');
    return txt.toString().toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text
  }
  // animations
  // -----------------------------------------------------------------------------------------------------------------------------
  animate(element: string, animation: string, delay: number = 0) {
    return new Promise(async (resolve, reject) => {
      const prefix: string = 'animate__';
      const animationName = `${prefix}${animation}`;
      // animate element
      $(element).addClass('animated ' + animationName + ' animate__delay-' + delay + 's').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', () => {
        // $(this).removeClass('animated ' + animationName);
        resolve(true);
      });
      // const node = document.querySelector(element);
      // node.classList.add(`animated`, animationName);
      // // When the animation ends, we clean the classes and resolve the Promise
      // function handleAnimationEnd(event) {
      //   event.stopPropagation();
      //   node.classList.remove(`${prefix}animated`, animationName);
      //   resolve('Animation ended');
      // }
      // node.addEventListener('animationend', handleAnimationEnd, {once: true});
    });
  }
  // navigation
  // -----------------------------------------------------------------------------------------------------------------------------
  async goTo(uri: string, replaceUrl: boolean, params: any) {
    // check if there are params
    if (!(params == null || params == undefined)) {
      // save params on storage
      localStorage.setItem('params', JSON.stringify(params));
    }
    // check replace url
    if (replaceUrl) {
      // save url on storage 
      localStorage.setItem('mainUri', uri);
      // navigate to url as main page
      this.router.navigate([uri], { replaceUrl });
    } else {
      // navigate to url
      this.router.navigate([uri]);
    }
  }
}
