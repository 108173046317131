// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 03/06/2023
// import
import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
// translate
import { TranslateService } from '@ngx-translate/core';
// services
import { AuthService } from 'src/app/shared/services/auth.service';
import { EncodingService } from 'src/app/shared/services/encoding.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
// interfaces
import { LoanPaymentInterface } from 'src/app/shared/interfaces/loan-payment.interface';
// component
@Component({
  selector: 'app-loan-payments',
  templateUrl: './loan-payments.component.html',
  styleUrls: ['./loan-payments.component.scss']
})
// class
export class LoanPaymentsComponent implements OnInit, OnChanges {
  // variables
  @Input() dataType: string = null;
  @Input() loanPaymentsData: any[] = [];
  @Input() enablePayments: boolean = false;
  @Output() loaderEvent = new EventEmitter();
  @Output() updateEvent = new EventEmitter();
  @Output() openDocumentEvent = new EventEmitter();
  @Output() paymentEvent = new EventEmitter();
  // loan payments data
  loanPayments: any[] = [];
  loanPaymentId: string = null;
  allSelected: boolean = false;
  // lang data
  currentLang: string = null;
  // constructor
  constructor(
    public translate: TranslateService,
    public authSv: AuthService,
    public encodingSv: EncodingService,
    public apiSv: ApiService,
    public filterSv: FilterService,
    public alertSv: AlertService,
  ) {
    // get currentLang
    const currentLang = this.translate.getDefaultLang();
    // set currentLang
    this.currentLang = currentLang;
  }
  // life cycle
  async ngOnInit() {}
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.loanPaymentsData) {
      await this.setData();
    }
  }
  // loader
  async updateLoader(loaderMsg: any) {
    this.loaderEvent.emit(loaderMsg);
  }
  // data
  async setData() {
    // loop payments data
    this.loanPaymentsData.map(async (loanPayment: LoanPaymentInterface) => {
      this.loanPayments.push(loanPayment);
    });
  }
  // actions
  async selectAll() {
    // loop loanPayments
    await this.loanPayments.map((loanPayment: any, index: number) => {
      // update selected
      this.loanPayments[index].selected = this.allSelected === true ? (loanPayment.selected === false ? true : true) : false;
    });
    // update allSelected
    this.updateEvent.emit(this.loanPayments);
  }
  async selectItem() {
    // update allSelected
    this.updateEvent.emit(this.loanPayments);
  }
  async initPayment(loanPayment: LoanPaymentInterface) {
    // update allSelected
    this.paymentEvent.emit(loanPayment);
  }
  async openDocument(modal: any, loanPayment: LoanPaymentInterface) {
    // update allSelected
    this.openDocumentEvent.emit(loanPayment);
  }
}
