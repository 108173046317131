<!-- data -->
<div class="row">
  <div class="col-12">
    <div class="table-responsive">
      <table class="table table-hover">
        <!-- table header -->
        <thead>
          <tr>
            <th class="col align-middle" scope="col"><p class="table-header-title">{{ 'PRODUCT.formLabels.productCategory' | translate }}</p></th>
            <th class="col align-middle" scope="col"><p class="table-header-title">{{ 'PRODUCT.formLabels.productName' | translate }}</p></th>
            <th class="col align-middle text-center" scope="col"><p class="table-header-title">{{ 'STATUSES.status' | translate }}</p></th>
            <th class="col-delete align-middle" scope="col" *ngIf="deleteEnable"></th>
          </tr>
        </thead>
        <!-- table body -->
        <tbody>
          <tr *ngFor="let product of products">
            <td class="col align-middle" scope="col">{{ product.productCategoryName }}</td>
            <td class="col align-middle item-number" scope="col" (click)="openProduct(product)">{{ product.productName }}</td>
            <td class="col align-middle text-center">
              <p class="table-status-txt">
                <span class="status-approved" *ngIf="product.productStatus">{{ 'STATUSES.active' | translate }}</span>
                <span class="status-rejected" *ngIf="!product.productStatus">{{ 'STATUSES.unactive' | translate }}</span>
              </p>
            </td>
            <td *ngIf="deleteEnable" class="col-button align-middle">
              <a class="btn btn-danger btn-xs btn-icon-only" (click)="deleteProduct(product)">
                <app-feather-icons [icon]="'trash'"></app-feather-icons>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>