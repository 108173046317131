<!-- form -->
<form class="theme-form" [formGroup]="mainFrm">
  <!-- header -->
  <div class="row mt-4">
    <!-- title -->
    <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
      <h4>{{ 'COMPANYACTIVITIES.companyActivity' | translate }}</h4>
      <p>{{ 'COMPANYACTIVITIES.companyActivityInfo' | translate }}</p>
    </div>
    <!-- help -->
    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 pull-right">
      <div class="btn-with-outside-txt-lbl help-btn" (click)="help()">
        <small class="btn-inner-txt">{{ 'HELPERS.help' | translate }}</small> 
        <a class="btn btn-light btn-xs btn-icon-only btn-icon-text-left">
          <app-feather-icons [icon]="'help-circle'"></app-feather-icons>
        </a>
      </div>
    </div>
  </div>
  <!-- companyActivityCode / companyActivity / companyActivityStatus -->
  <div class="row">
    <!-- companyActivityCode -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group">
        <label for="companyActivityCode">{{ 'COMPANYACTIVITIES.formLabels.companyActivityCode' | translate }} <span class="required-lbl">*</span></label>
        <input class="form-control" #companyActivityCode id="companyActivityCode" type="text" inputmode="text" [placeholder]="'COMPANYACTIVITIES.formPlaceHolders.companyActivityCode' | translate" formControlName="companyActivityCode">
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.companyActivityCode">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('companyActivityCode').hasError(error.type) && (mainFrm.get('companyActivityCode').dirty || mainFrm.get('companyActivityCode').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'COMPANYACTIVITIES.formHelp.companyActivityCode' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- companyActivity -->
    <div class="col-12">
      <div class="form-group">
        <label for="companyActivityInput">{{ 'COMPANYACTIVITIES.formLabels.companyActivity' | translate }} <span class="required-lbl">*</span></label>
        <textarea #companyActivityInput id="companyActivityInput" type="text" inputmode="text" class="form-control origin-funds-text-area" rows="3" [placeholder]="'COMPANYACTIVITIES.formPlaceHolders.companyActivityDescription' | translate" formControlName="companyActivity"></textarea>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.companyActivity">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('companyActivity').hasError(error.type) && (mainFrm.get('companyActivity').dirty || mainFrm.get('companyActivity').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'COMPANYACTIVITIES.formHelp.companyActivity' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- companyActivityStatus -->
    <div class="col-12">
      <div class="form-check form-switch companyActivityStatus-switch">
        <input #companyActivityStatus id="companyActivityStatus" type="checkbox" role="switch" formControlName="companyActivityStatus" class="form-check-input">
        <label class="form-check-label" for="companyActivityStatus">{{ 'COMPANYACTIVITIES.formHelp.companyActivityStatus' | translate }}</label>
      </div>
    </div>
  </div>
  <!-- buttons -->
  <div class="row mt-4">
    <div class="col">
      <div class="request-form-btns-container">
        <button class="btn btn-light d-block btn-cancel" (click)="cancel()" type="button">
          <span>{{ 'HELPERS.cancel' | translate }}</span>
        </button>
        <button class="btn btn-dark d-block" (click)="save()" type="button">
          <span>{{ 'HELPERS.save' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</form>