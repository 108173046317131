import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  
  constructor(
    public authSv: AuthService,
    public router: Router
  ) { }
  // FUNCTIONS
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // get token
    const token = localStorage.getItem('token');
    // check token
    if (token === null) {
      this.router.navigate(['/auth/login']);
      return true
    } else {
      // get lock status
      const unlock = localStorage.getItem('lockAccount');
      // check lock status
      if (unlock === 'true') {
        this.router.navigate(['/auth/unlock']);
        return true
      }
    }
    return true
  }
  
}
