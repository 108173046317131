// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 25/05/2023
// import
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
// translate
import { TranslateService } from '@ngx-translate/core';
// environment
import { environment } from 'src/environments/environment';
// services
import { AuthService } from 'src/app/shared/services/auth.service';
import { EncodingService } from 'src/app/shared/services/encoding.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
// interfaces
import { ProductInterface } from 'src/app/shared/interfaces/product.interface';
// component
@Component({
  selector: 'app-loan-simulator-form-short',
  templateUrl: './loan-simulator-form-short.component.html',
  styleUrls: ['./loan-simulator-form-short.component.scss']
})
// class
export class LoanSimulatorFormShortComponent implements OnInit {
  // variables
  @Input() workActivity: string = null;
  @Input() productData: ProductInterface = null;
  @Output() loaderEvent = new EventEmitter();
  @Output() saveEvent = new EventEmitter();
  @Output() backEvent = new EventEmitter();
  @ViewChild('customAmount') customAmount: ElementRef;
  @ViewChild('customMonths') customMonths: ElementRef;
  // uri data
  mainUri: string = null;
  // form data
  public mainFrm: FormGroup;
  // messages data
  formErrorMessages: any = {};
  // help data
  toggleHelp = false;
  // loan data
  singlePayment: number = 0;
  salary: number = 0;
  salaryMin: number = 1300000;
  salaryMax: number = 9999999999;
  amount: number = 0;
  loanAmountMin: number = 200000;
  loanAmountMax: number = 50000000;
  loanAmountMaxLimit: boolean = false;
  amountMin: number = 200000;
  amountMax: number = 50000000;
  monthMin: number = 3;
  monthMax: number = 48;
  monthSteps: number = 1;
  minAmountMsg: string = null;
  maxAmountMsg: string = null;
  minlengthMsg: string = null;
  maxlengthMsg: string = null;
  probability: number = 0;
  // constructor
  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public authSv: AuthService,
    public encodingSv: EncodingService,
    public apiSv: ApiService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    public utilitiesSv: UtilitiesService,
  ) {
    // main form
    this.mainFrm = this.formBuilder.group({
      amount: [null, [Validators.required, this.checkAmountMin()]],
      months: [null, [Validators.required, this.checkMonthMin()]],
      salary: [null, [Validators.required, this.checkSalaryMin(), this.checkSalaryMax()]],
    });
    // check for form changes
    this.mainFrm.valueChanges.subscribe(async (val) => {
      // check amount
      if (typeof val.amount === 'string') {
        // mask value
        const maskedVal = this.utilitiesSv.convertStringToCurrency(val.amount);
        // check matched values
        if (val.amount !== maskedVal) {
          // set form value
          this.mainFrm.patchValue({amount: maskedVal});
        }
        await this.calcInterest();
        this.calculateLoanProbabilities();
      }
      // check months
      if (typeof val.months === 'string') {
        // mask value
        const maskedVal = this.utilitiesSv.convertStringToCurrency(val.months);
        // check matched values
        if (val.months !== maskedVal) {
          // set form value
          this.mainFrm.patchValue({months: maskedVal});
        }
        await this.calcInterest();
        this.calculateLoanProbabilities();
      }
      // check salary
      if (typeof val.salary === 'string') {
        // mask value
        const maskedVal = this.utilitiesSv.convertStringToCurrency(val.salary);
        // check matched values
        if (val.salary !== maskedVal) {
          // set form value
          this.mainFrm.patchValue({salary: maskedVal});
        }
        await this.calcInterest();
        this.calculateLoanProbabilities();
      }
      // update amount
      this.amount = Math.ceil(typeof val.amount === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(val.amount)) : val.amount);
      // update salary
      this.salary = Math.ceil(typeof val.salary === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(val.salary)) : val.salary);
    });
    // set mainUri
    this.mainUri = environment.websiteURI;
  }
  // life cycle
  async ngOnInit() {
    await this.translateMsgs();
    await this.getWorkActivityData();
    await this.getProductData();
    await this.setProductData();
    await this.getRequestData();
    await this.setMinMax();
    await this.calcInterest();
  }
  // translate
  async translateMsgs () {
    this.formErrorMessages = {
      amount: [
        { type: 'amountCustom', message: this.translate.instant('LOAN.requestAmountSavingRequired') },
        { type: 'required', message: this.translate.instant('LOAN.requestAmountError') },
        { type: 'minAmount', message: this.translate.instant('LOAN.requestAmountMin') + ' $' + this.utilitiesSv.convertStringToCurrency(this.amountMin.toString()) },
        { type: 'maxAmount', message: this.translate.instant('LOAN.requestAmountMax') + ' $' + this.utilitiesSv.convertStringToCurrency(this.amountMax.toString()) },
      ],
      months: [
        { type: 'monthsCustom', message: this.translate.instant('LOAN.requestMonthsSavingRequired') },
        { type: 'required', message: this.translate.instant('LOAN.requestMonthsError') },
        { type: 'minMonth', message: this.translate.instant('LOAN.requestMonthsMin') + ' ' + this.utilitiesSv.convertStringToCurrency(this.monthMin.toString()) + ' ' + this.translate.instant('HELPERS.months') },
        { type: 'maxMonth', message: this.translate.instant('LOAN.requestMonthsMax') + ' ' + this.utilitiesSv.convertStringToCurrency(this.monthMax.toString()) + ' ' + this.translate.instant('HELPERS.months') },
      ],
      salary: [
        { type: 'required', message: this.translate.instant(this.workActivity === 'business' ? 'LOAN.contractIncomeError' : 'LOAN.contractSalaryError') },
        { type: 'minSalary', message: this.translate.instant(this.workActivity === 'business' ? 'LOAN.contractIncomeMin' : 'LOAN.contractSalaryMin') + ' $' + this.utilitiesSv.convertStringToCurrency(this.salaryMin.toString()) },
        { type: 'maxSalary', message: this.translate.instant(this.workActivity === 'business' ? 'LOAN.contractIncomeMax' : 'LOAN.contractSalaryMax') + ' $' + this.utilitiesSv.convertStringToCurrency(this.salaryMax.toString()) },
      ],
    };
  }
  // data
  async getWorkActivityData() {
    // get workActivity data
    let workActivity: any = localStorage.getItem('workActivity');
    // check workActivity data
    if (workActivity != null) {
      // set workActivity data
      this.workActivity = workActivity;
    }
  }
  async getProductData() {
    // get stored data product
    const storedDataProduct: any = localStorage.getItem('productData');
    // check product data
    if (storedDataProduct != null) {
      // get data product
      const productData: ProductInterface = JSON.parse(storedDataProduct);
      // set product data
      this.productData = productData;
    }
  }
  async setProductData() {
    // check productData
    if (this.productData != null) {
      // update loan data
      this.loanAmountMin = this.productData.loanAmountMin;
      this.loanAmountMax = this.productData.loanAmountMax;
      this.loanAmountMaxLimit = this.productData.loanAmountMaxLimit;
      this.monthMin = this.productData.loanMonthMin;
      this.monthMax = this.productData.loanMonthMax;
      this.monthSteps = this.productData.loanMonthSteps;
      // middleAmount
      let middleAmount = this.productData.loanAmountMax / 1.5;
      middleAmount = this.utilitiesSv.roundNumber(middleAmount, 'none', 0);
      middleAmount = 8000000;
      // middleMonths
      let middleMonths = this.productData.loanMonthMax / 2;
      middleMonths = this.utilitiesSv.roundNumber(middleMonths, 'none', 0);
      // update form values
      this.mainFrm.controls.amount.setValue(middleAmount);
      this.mainFrm.controls.months.setValue(middleMonths);
    } else {
      // update loan data
      this.loanAmountMin = 200000;
      this.loanAmountMax = 50000000;
      this.loanAmountMaxLimit = true;
      this.monthMin = 3;
      this.monthMax = 48;
      this.monthSteps = 1;
      // update form values
      this.mainFrm.controls.amount.setValue(200000);
      this.mainFrm.controls.months.setValue(3);
    }
  }
  async getRequestData() {
    // get request data
    let storedRequestData: any = localStorage.getItem('requestData');
    // check request data
    if (storedRequestData != null) {
      // get request
      const requestData = JSON.parse(storedRequestData);
      // update request
      this.mainFrm.controls.amount.setValue(requestData.amount);
      this.mainFrm.controls.months.setValue(requestData.months);
      this.mainFrm.controls.salary.setValue(requestData.salary.toString());
    }
  }
  async setMinMax() {
    // get min max amount
    const amountMin: number = this.loanAmountMin;
    const amountMax: number = this.loanAmountMax;
    // update loan data
    this.amountMin = amountMin;
    this.amountMax = amountMax;
    // get salaryMin
    const salaryMin = this.utilitiesSv.convertStringToCurrency(this.salaryMin.toString());
    // set salaryMin
    this.mainFrm.controls.salary.setValue(salaryMin);
  }
  // form
  async help () {
    if (this.toggleHelp) {
      this.toggleHelp = false;
    } else {
      this.toggleHelp = true;
    }
  }
  async setFocus(elementId: any) {
    elementId.focus();
  }
  checkFormField(fieldName: string) {
    // get field
    const field = this.mainFrm.get(fieldName);
    // check field
    const check = field?.invalid && field?.touched;
    // return data
    return check;
  }
  checkSalaryMin() {
    return (control: FormControl) => {
      // check value
      if (control.value !== null) {
        // get currentSalary
        const currentSalary: number = typeof control.value === 'string' ? parseInt(this.utilitiesSv.removeDotsFromCurrencyString(control.value)) : control.value;
        // set newSalaryMin
        const newSalaryMin = this.salaryMin;
        // check salaryMin
        if (currentSalary < newSalaryMin) {
          return { minSalary: true }
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }
  checkSalaryMax() {
    return (control: FormControl) => {
      // check value
      if (control.value !== null) {
        // get currentSalary
        const currentSalary: number = typeof control.value === 'string' ? parseInt(this.utilitiesSv.removeDotsFromCurrencyString(control.value)) :control.value;
        // set newSalaryMax
        const newSalaryMax = this.salaryMax;
        // check salaryMax
        if (currentSalary > newSalaryMax) {
          return { maxSalary: true }
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }
  checkAmountMin() {
    return (control: FormControl) => {
      // check value
      if (control.value !== null) {
        // get currentAmount
        const currentAmount: number = typeof control.value === 'string' ? this.utilitiesSv.removeDotsFromCurrencyString(control.value) :control.value;
        // set newAmountMin
        const newAmountMin = this.amountMin;
        // check amountMin
        if (currentAmount < newAmountMin) {
          return { minAmount: true }
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }
  checkMonthMin() {
    return (control: FormControl) => {
      // check value
      if (control.value !== null) {
        // get currentMonth
        const currentMonth: number = control.value;
        // set newMonthMin
        const newMonthMin = this.monthMin;
        // check monthMin
        if (currentMonth < newMonthMin) {
          return { minMonth: true }
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }
  // navigation
  async goBack() {
    // emit data
    this.backEvent.emit(); // Return data
  }
  // actions
  async calcInterest() {
    // get loanInterest
    const loanInterest = this.productData.loanInterest;
    // get amount
    const amount = this.mainFrm.controls.amount.value;
    // get months
    const months = this.mainFrm.controls.months.value;
    // check data to calculate
    if (loanInterest > 0 && amount > 0 && months > 0) {
      // init calc
      const numerator = amount * Math.pow((1 + (loanInterest) / 100), months) * (loanInterest) / 100;
      const denominator = Math.pow((1 + (loanInterest) / 100), months) - 1;
      const singlePayment = numerator / denominator;
      // update singlePayment
      this.singlePayment = singlePayment;
    }
  }
  getProgressBarClass() {
    if (this.probability <= 33) {
      return 'progress-bar poor';
    } else if (this.probability <= 66) {
      return 'progress-bar fair';
    } else {
      return 'progress-bar good';
    }
  }
  getProgressLabel() {
    if (this.probability <= 33) {
      return 'Pocas Probabilidades';
    } else if (this.probability <= 66) {
      return 'Buenas Probabilidades';
    } else {
      return 'Excelentes Probabilidades';
    }
  }
  calculateLoanProbabilities() {
    // get data
    const amount: number = typeof this.mainFrm.controls.amount.value === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(this.mainFrm.controls.amount.value)) : this.mainFrm.controls.amount.value;
    const salary: number = typeof this.mainFrm.controls.salary.value === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(this.mainFrm.controls.salary.value)) : this.mainFrm.controls.salary.value;
    const months: number = this.mainFrm.controls.months.value;
    // set calculation data
    const monthlyPayment = amount / months;
    const salaryPercentage = (monthlyPayment / salary) * 100;
    // init probability
    let probability: number = 0;
    // check salaryPercentage
    if (salaryPercentage <= 30) {
      probability = 100;
    } else if (salaryPercentage > 30 && salaryPercentage <= 40) {
      probability = 60; // O el valor que consideres adecuado
    } else {
      probability = 20; // O el valor que consideres adecuado
    }
    // update probability
    this.probability = probability;
  }
  async save() {
    // markAllAsTouched
    this.mainFrm.markAllAsTouched();
    // validate data
    if (!this.mainFrm.valid) {
      // send alert
      this.alertSv.showMessage(this.translate.instant('HELPERS.requiredFields'),'warning', this.translate.instant('HELPERS.warning'), true);
    } else {
      // set amountChk
      let amountChk: number = typeof this.mainFrm.controls.amount.value === 'string' ? this.utilitiesSv.removeDotsFromCurrencyString(this.mainFrm.controls.amount.value) : this.mainFrm.controls.amount.value;
      // check amount range
      if (!(amountChk >= this.amountMin && amountChk <= this.amountMax)) {
        // set alertMsg
        const alertMsg = this.translate.instant('HELPERS.minAmountValue') + ' $' + this.utilitiesSv.convertStringToCurrency(this.amountMin.toString()) + ' ' + this.translate.instant('HELPERS.maxAmountValue') + ' $' + this.utilitiesSv.convertStringToCurrency(this.amountMax.toString());
        // send alert
        this.alertSv.showMessage(alertMsg,'warning', this.translate.instant('HELPERS.warning'), true);
      } else {
        // get monthsChk
        let monthsChk: any = this.mainFrm.controls.months.value;
        // check months range
        if (monthsChk >= this.monthMin && monthsChk <= this.monthMax) {
          // call save
          await this.saveRequest();
        } else {
          // set alertMsg
          const alertMsg = this.translate.instant('HELPERS.minMonthsValue') + ' ' + this.monthMin + ' ' + this.translate.instant('HELPERS.maxMonthsValue') + ' ' + this.monthMax;
          // send alert
          this.alertSv.showMessage(alertMsg,'warning', this.translate.instant('HELPERS.warning'), true);
        }
      }
    }
  }
  async saveRequest() {
    // show loader
    this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
    // get salary
    const salary: number = typeof this.mainFrm.controls.salary.value === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(this.mainFrm.controls.salary.value)) : this.mainFrm.controls.salary.value;
    const amount: number = typeof this.mainFrm.controls.amount.value === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(this.mainFrm.controls.amount.value)) : this.mainFrm.controls.amount.value;
    // set requestData
    const requestData: any = {
      salary: salary,
      amount: amount,
      months: this.mainFrm.controls.months.value,
    }
    // emit data
    this.saveEvent.emit(requestData); // Return data
    // hide loader
    this.loaderEvent.emit(null);
  }
}
