// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 03/06/2023
// import
import { Component, EventEmitter, OnInit, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
// translate
import { TranslateService } from '@ngx-translate/core';
// plugins
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { editorObservationGlobalConfigEn, editorObservationGlobalConfigEs } from 'src/app/shared/services/angular-editor-config';
// services
import { AuthService } from 'src/app/shared/services/auth.service';
import { EncodingService } from 'src/app/shared/services/encoding.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { LocationService } from 'src/app/shared/services/location.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
// interfaces
import { UserInterface } from 'src/app/shared/interfaces/user.interface';
import { ConfigInterface } from 'src/app/shared/interfaces/config.interface';
// component
@Component({
  selector: 'app-privacy-form',
  templateUrl: './privacy-form.component.html',
  styleUrls: ['./privacy-form.component.scss']
})
// class
export class PrivacyFormComponent implements OnInit {
  // variables
  @Input() configId: string = null;
  @Input() privacyPolicy: string = null;
  @Output() loaderEvent = new EventEmitter();
  @Output() addEvent = new EventEmitter();
  @Output() updateEvent = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();
  // lang data
  currentLang: string = null;
  // editor configuration
  editorConfig: AngularEditorConfig = null;
  // form data
  public mainFrm: FormGroup;
  // messages data
  formErrorMessages: any = {};
  // help data
  toggleHelp = false;
  // saved data
  savedData: boolean = false;
  // current user data
  currentUserData: UserInterface = null;
  // roles data
  isSupport: boolean = false;
  isAdmin: boolean = false;
  // constructor
  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public authSv: AuthService,
    public encodingSv: EncodingService,
    public apiSv: ApiService,
    public locationSv: LocationService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    private utilitiesSv: UtilitiesService,
  ) {
    // get currentLang
    const currentLang = this.translate.getDefaultLang();
    // set currentLang
    this.currentLang = currentLang;
    // main form
    this.mainFrm = this.formBuilder.group({
      privacyPolicy: [null, [Validators.required]]
    });
  }
  // life cycle
  async ngOnInit() {
    await this.translateMsgs();
    this.initData();
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.privacyPolicy) {
      // check for privacyPolicy
      if (this.privacyPolicy !== null) {
        this.initData();
      }
    }
  }
  async initData() {
    // show loader
    this.loaderEvent.emit(this.translate.instant('HELPERS.loadingTxt'));
    // get data
    await this.getCurrentUserData();
    await this.setConfigData();
    // hide loader
    this.loaderEvent.emit(null);
  }
  // translate
  async translateMsgs() {
    // update angular editor
    if (this.currentLang === 'es') {
      this.editorConfig = editorObservationGlobalConfigEs;
    } else {
      this.editorConfig = editorObservationGlobalConfigEn;
    }
    // update form error messages
    this.formErrorMessages = {
      privacyPolicy: [
        { type: 'required', message: this.translate.instant('CONFIG.formValidation.privacyPolicy.required') }
      ],
    };
  }
  // form
  async help() {
    if (this.toggleHelp) {
      this.toggleHelp = false;
    } else {
      this.toggleHelp = true;
    }
  }
  async setFocus(elementId: any) {
    elementId.focus();
  }
  checkFormField(fieldName: string) {
    // get field
    const field = this.mainFrm.get(fieldName);
    // check field
    const check = field?.invalid && field?.touched;
    // return data
    return check;
  }
  // navigation
  async cancel() {
    // emit data
    this.cancelEvent.emit(); // Return dat
  }
  // data
  async getCurrentUserData() {
    // get stored data user
    const storedDataUser: any = localStorage.getItem('userData');
    // check user data
    if (storedDataUser != null) {
      // get data user
      const userData: UserInterface = JSON.parse(storedDataUser);
      // update data user
      this.currentUserData = userData;
      // check roles
      const checkSupport = await this.utilitiesSv.checkRoles(userData.role, ['support']);
      this.isSupport = checkSupport;
      const checkAdmin = await this.utilitiesSv.checkRoles(userData.role, ['support', 'administrator']);
      this.isAdmin = checkAdmin;
    }
  }
  async setConfigData() {
    // check config data
    if (this.privacyPolicy !== null) {
      // set privacyPolicy
      const privacyPolicy: string = this.privacyPolicy;
      // set form data
      this.mainFrm.controls.privacyPolicy.setValue(privacyPolicy);
    }
  }
  // actions
  async save() {
    // markAllAsTouched
    this.mainFrm.markAllAsTouched();
    // validate data
    if (!this.mainFrm.valid) {
      // send alert
      this.alertSv.showMessage(this.translate.instant('HELPERS.requiredFields'),'warning', this.translate.instant('HELPERS.warning'), true);
    } else {
      // update data
      await this.updateConfig();
    }
  }
  async updateConfig() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // init privacyPolicy
      let privacyPolicy: string = this.mainFrm.controls.privacyPolicy.value;
      // check if observation is not null
      if (privacyPolicy !== null) {
        // fix privacyPolicy fontsize
        privacyPolicy = this.utilitiesSv.replaceStringOnString(privacyPolicy, '<font size="3">', '<font size="2">');
        // check if there is not font size parameter
        if (!privacyPolicy.includes('<font size="2">')) {
          privacyPolicy = '<font size="2">' + privacyPolicy + '</font>';
        }
      }
      // set privacyPolicy
      const privacyPolicyData: ConfigInterface = {
        privacyPolicy
      }
      // update config
      await this.apiSv.updateConfig(this.configId, privacyPolicyData);
      // update privacyPolicy
      this.privacyPolicy = this.mainFrm.controls.privacyPolicy.value;
      // hide loader
      this.loaderEvent.emit(null);
      // set configData
      const configData = {
        id: this.configId,
        privacyPolicy: this.privacyPolicy
      }
      // emit data
      this.updateEvent.emit(configData);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
}
