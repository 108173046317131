<!-- form -->
<form class="theme-form" [formGroup]="mainFrm">
  <!-- images -->
  <div class="row">
    <!-- portrait -->
    <div class="col-12 portrait-container" id="aniimated-thumbnials" itemscope="">
      <!-- image -->
      <div class="img-portrait">
        <img [src]="productPortraitSource" [alt]="productName" onError="src = 'assets/images/broken-image-slim.png'" *ngIf="productPortraitSource !== null">
        <img src="assets/images/broken-image-slim.png" [alt]="productName" *ngIf="productPortraitSource === null">
      </div>
      <!-- button -->
      <div class="add-icon" (click)="editImage('portrait')" *ngIf="!savedData">
        <app-feather-icons class="feather-icon" [icon]="'camera'"></app-feather-icons>
      </div>
      <div class="edit-icon" (click)="editImage('portrait')" *ngIf="savedData">
        <app-feather-icons class="feather-icon" [icon]="'edit-3'" ></app-feather-icons>
      </div>
    </div>
    <!-- icon -->
    <div class="col-12">
      <div class="logo-container">
        <div class="social-img-wrap">
          <!-- image -->
          <div class="social-img">
            <img [src]="productIconSource" [alt]="productName" onError="src = 'assets/images/broken-image-slim.png'" *ngIf="productIconSource !== null">
            <img src="assets/images/broken-image-slim.png" [alt]="productName" *ngIf="productIconSource === null">
          </div>
          <!-- button -->
          <div class="add-icon" (click)="editImage('icon')" *ngIf="!savedData">
            <app-feather-icons class="feather-icon" [icon]="'camera'"></app-feather-icons>
          </div>
          <div class="edit-icon" (click)="editImage('icon')" *ngIf="savedData">
            <app-feather-icons class="feather-icon" [icon]="'edit-3'" ></app-feather-icons>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- file input -->
  <input type="file" #fileInput style="display: none" (change)="onFileSelected($event, imageCropModal)" />
  <!-- header -->
  <div class="row mt-4">
    <!-- title -->
    <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
      <h4>{{ 'PRODUCT.product' | translate }}</h4>
      <p>{{ 'PRODUCT.productInfo' | translate }}</p>
    </div>
    <!-- help -->
    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 pull-right">
      <div class="btn-with-outside-txt-lbl help-btn" (click)="help()">
        <small class="btn-inner-txt">{{ 'HELPERS.help' | translate }}</small> 
        <a class="btn btn-light btn-xs btn-icon-only btn-icon-text-left">
          <app-feather-icons [icon]="'help-circle'"></app-feather-icons>
        </a>
      </div>
    </div>
  </div>
  <!-- productCategory / productName -->
  <div class="row">
    <!-- productCategory -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group select-fix">
        <span class="caret-select"></span>
        <label for="productCategorySelect" class="col-form-label">{{ 'PRODUCT.formLabels.productCategory' | translate }} <span class="required-lbl">*</span></label>
        <ng-select [items]="allProductCategories"
          [selectOnTab]="true"
          bindLabel="productCategory"
          bindValue="id"
          labelForId="productCategorySelect"
          [placeholder]="'PRODUCT.formLabels.productCategory' | translate"
          #productCategorySelect id="productCategorySelect" name="productCategorySelect" formControlName="productCategory">
        </ng-select>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.productCategory">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('productCategory').hasError(error.type) && (mainFrm.get('productCategory').dirty || mainFrm.get('productCategory').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'PRODUCT.formHelp.productCategory' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- productName -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group">
        <label for="productName">{{ 'PRODUCT.formLabels.productName' | translate }} <span class="required-lbl">*</span></label>
        <input class="form-control" #productName id="productName" type="text" inputmode="text" [placeholder]="'PRODUCT.formPlaceHolders.productName' | translate" formControlName="productName">
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.productName">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('productName').hasError(error.type) && (mainFrm.get('productName').dirty || mainFrm.get('productName').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'PRODUCT.formHelp.productName' | translate }}</small>
        </p>
      </div>
    </div>
  </div>
  <!-- productDescription -->
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label for="productDescriptionInput">{{ 'PRODUCT.formLabels.productDescription' | translate }}</label>
        <angular-editor id="productDescriptionInput" #productDescriptionInput name="productDescriptionInput" formControlName="productDescription" [config]="editorConfig"></angular-editor>
      </div>
    </div>
  </div>
  <!-- divider -->
  <hr>
  <!-- loanMonthMin / loanMonthMax / loanMonthSteps -->
  <div class="row">
    <!-- loanMonthMin -->
    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
      <div class="form-group">
        <label class="col-form-label">{{ 'PRODUCT.formLabels.loanMonthMin' | translate }} <span class="required-lbl">*</span></label>
        <input class="form-control" #loanMonthMin id="loanMonthMin" type="number" inputmode="numeric" min="0" placeholder="0" formControlName="loanMonthMin">
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.loanMonthMin">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('loanMonthMin').hasError(error.type) && (mainFrm.get('loanMonthMin').dirty || mainFrm.get('loanMonthMin').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'PRODUCT.formHelp.loanMonthMin' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- loanMonthMax -->
    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
      <div class="form-group">
        <label class="col-form-label">{{ 'PRODUCT.formLabels.loanMonthMax' | translate }} <span class="required-lbl">*</span></label>
        <input class="form-control" #loanMonthMax id="loanMonthMax" type="number" inputmode="numeric" min="0" placeholder="0" formControlName="loanMonthMax">
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.loanMonthMax">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('loanMonthMax').hasError(error.type) && (mainFrm.get('loanMonthMax').dirty || mainFrm.get('loanMonthMax').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'PRODUCT.formHelp.loanMonthMax' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- loanMonthSteps -->
    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
      <div class="form-group">
        <label class="col-form-label">{{ 'PRODUCT.formLabels.loanMonthSteps' | translate }} <span class="required-lbl">*</span></label>
        <input class="form-control" #loanMonthSteps id="loanMonthSteps" type="number" inputmode="numeric" min="1" max="100" placeholder="0" formControlName="loanMonthSteps">
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.loanMonthSteps">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('loanMonthSteps').hasError(error.type) && (mainFrm.get('loanMonthSteps').dirty || mainFrm.get('loanMonthSteps').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'PRODUCT.formHelp.loanMonthSteps' | translate }}</small>
        </p>
      </div>
    </div>
  </div>
  <!-- divider -->
  <hr>
  <!-- loanAmountMaxLimit / loanAmountMin / loanMonthMax  -->
  <div class="row">
    <!-- loanAmountMaxLimit -->
    <div class="col-12">
      <div class="form-check form-switch loanAmountMaxLimit-switch">
        <input #loanAmountMaxLimit id="loanAmountMaxLimit" type="checkbox" role="switch" formControlName="loanAmountMaxLimit" class="form-check-input">
        <label class="form-check-label" for="loanAmountMaxLimit"><small>{{ 'PRODUCT.formHelp.loanAmountMaxLimit' | translate }}</small></label>
      </div>
      <ng-container class="error-messages" *ngFor="let error of formErrorMessages.loanAmountMaxLimit">
        <small class="text-danger error-msg" *ngIf="mainFrm.get('loanAmountMaxLimit').hasError(error.type) && (mainFrm.get('loanAmountMaxLimit').dirty || mainFrm.get('loanAmountMaxLimit').touched)">
          {{ error.message }}.&nbsp;
        </small>
      </ng-container>
      <p class="help-messages" *ngIf="toggleHelp">
        <small>{{ 'PRODUCT.formHelp.loanAmountMaxLimit' | translate }}</small>
      </p>
    </div>
    <!-- loanAmountMin -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group">
        <label class="col-form-label">{{ 'PRODUCT.formLabels.loanAmountMin' | translate }} <span class="required-lbl">*</span></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input class="form-control" #loanAmountMin id="loanAmountMin" type="text" inputmode="numeric" min="0" placeholder="0" formControlName="loanAmountMin">
        </div>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.loanAmountMin">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('loanAmountMin').hasError(error.type) && (mainFrm.get('loanAmountMin').dirty || mainFrm.get('loanAmountMin').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'PRODUCT.formHelp.loanAmountMin' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- loanAmountMax -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group">
        <label class="col-form-label">{{ 'PRODUCT.formLabels.loanAmountMax' | translate }} <span class="required-lbl">*</span></label>
        <div class="input-group" *ngIf="mainFrm.controls.loanAmountMaxLimit.value">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input class="form-control" #loanAmountMax id="loanAmountMax" type="text" inputmode="numeric" min="0" placeholder="0" formControlName="loanAmountMax">
        </div>
        <div class="input-group" *ngIf="!mainFrm.controls.loanAmountMaxLimit.value">
          <input class="form-control" #loanAmountMax id="loanAmountMax" type="text" inputmode="numeric" min="0" placeholder="0" formControlName="loanAmountMax">
          <div class="input-group-append">
            <span class="input-group-text">{{ 'PRODUCT.formLabels.loanAmountMaxTimes' | translate }}</span>
          </div>
        </div>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.loanAmountMax">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('loanAmountMax').hasError(error.typeTimes) && (mainFrm.get('loanAmountMax').dirty || mainFrm.get('loanAmountMax').touched) && !mainFrm.controls.loanAmountMaxLimit.value">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.loanAmountMax">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('loanAmountMax').hasError(error.typeLimit) && (mainFrm.get('loanAmountMax').dirty || mainFrm.get('loanAmountMax').touched) && mainFrm.controls.loanAmountMaxLimit.value">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'PRODUCT.formHelp.loanAmountMax' | translate }}</small>
        </p>
      </div>
    </div>
  </div>
  <!-- divider -->
  <hr>
  <!-- borrowingCapacityPercentage / loanInterest -->
  <div class="row">
    <!-- borrowingCapacityPercentage -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group">
        <label class="col-form-label">{{ 'PRODUCT.formLabels.borrowingCapacityPercentage' | translate }} <span class="required-lbl">*</span></label>
        <div class="input-group">
          <input class="form-control" #borrowingCapacityPercentage id="borrowingCapacityPercentage" type="number" inputmode="numeric" min="0" placeholder="0" formControlName="borrowingCapacityPercentage">
          <div class="input-group-append">
            <span class="input-group-text">%</span>
          </div>
        </div>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.borrowingCapacityPercentage">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('borrowingCapacityPercentage').hasError(error.type) && (mainFrm.get('borrowingCapacityPercentage').dirty || mainFrm.get('borrowingCapacityPercentage').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'PRODUCT.formHelp.borrowingCapacityPercentage' | translate }} <span class="required-lbl">*</span></small>
        </p>
      </div>
    </div>
    <!-- loanInterest -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group">
        <label class="col-form-label">{{ 'PRODUCT.formLabels.loanInterest' | translate }} <span class="required-lbl">*</span></label>
        <div class="input-group">
          <input class="form-control" #loanInterest id="loanInterest" type="number" inputmode="numeric" min="0" placeholder="0" formControlName="loanInterest">
          <div class="input-group-append">
            <span class="input-group-text">%</span>
          </div>
        </div>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.loanInterest">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('loanInterest').hasError(error.type) && (mainFrm.get('loanInterest').dirty || mainFrm.get('loanInterest').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'PRODUCT.formHelp.loanInterest' | translate }}</small>
        </p>
      </div>
    </div>
  </div>
  <!-- loanPaymentReceipts / productInsurance / productStatus -->
  <div class="row">
    <!-- loanPaymentReceipts -->
    <div class="col-12">
      <div class="form-group">
        <label class="col-form-label">{{ 'PRODUCT.formLabels.loanPaymentReceipts' | translate }} <span class="required-lbl">*</span></label>
        <input class="form-control" #loanPaymentReceipts id="loanPaymentReceipts" type="number" inputmode="numeric" min="0" placeholder="0" formControlName="loanPaymentReceipts">
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.loanPaymentReceipts">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('loanPaymentReceipts').hasError(error.type) && (mainFrm.get('loanPaymentReceipts').dirty || mainFrm.get('loanPaymentReceipts').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'PRODUCT.formHelp.loanPaymentReceipts' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- productInsurance -->
    <div class="col-12">
      <div class="form-check form-switch productInsurance-switch">
        <input #productInsurance id="productInsurance" type="checkbox" role="switch" formControlName="productInsurance" class="form-check-input">
        <label class="form-check-label" for="productInsurance">{{ 'PRODUCT.formHelp.productInsurance' | translate }} <span class="required-lbl">*</span></label>
      </div>
      <ng-container class="error-messages" *ngFor="let error of formErrorMessages.productInsurance">
        <small class="text-danger error-msg" *ngIf="mainFrm.get('productInsurance').hasError(error.type) && (mainFrm.get('productInsurance').dirty || mainFrm.get('productInsurance').touched)">
          {{ error.message }}.&nbsp;
        </small>
      </ng-container>
      <p class="help-messages" *ngIf="toggleHelp">
        <small>{{ 'PRODUCT.formHelp.productInsurance' | translate }}</small>
      </p>
    </div>
    <!-- productStatus -->
    <div class="col-12">
      <div class="form-check form-switch productStatus-switch">
        <input #productStatus id="productStatus" type="checkbox" role="switch" formControlName="productStatus" class="form-check-input">
        <label class="form-check-label" for="productStatus">{{ 'PRODUCT.formHelp.productStatus' | translate }} <span class="required-lbl">*</span></label>
      </div>
      <ng-container class="error-messages" *ngFor="let error of formErrorMessages.productStatus">
        <small class="text-danger error-msg" *ngIf="mainFrm.get('productStatus').hasError(error.type) && (mainFrm.get('productStatus').dirty || mainFrm.get('productStatus').touched)">
          {{ error.message }}.&nbsp;
        </small>
      </ng-container>
      <p class="help-messages" *ngIf="toggleHelp">
        <small>{{ 'PRODUCT.formHelp.productStatus' | translate }}</small>
      </p>
    </div>
  </div>
  <!-- buttons -->
  <div class="row mt-4">
    <div class="col">
      <div class="request-form-btns-container">
        <button class="btn btn-light d-block btn-cancel" (click)="cancel()" type="button">
          <span>{{ 'HELPERS.cancel' | translate }}</span>
        </button>
        <button class="btn btn-dark d-block" (click)="save()" type="button">
          <span>{{ 'HELPERS.save' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</form>
<!-- image crop modal -->
<ng-template #imageCropModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Avatar</h4>
    <button type="button" class="close" aria-label="Close" (click)="imageCroppingClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <image-cropper 
      [imageChangedEvent]="imageChangedEvent" 
      [maintainAspectRatio]="true"
      [containWithinAspectRatio]="containWithinAspectRatio" 
      [aspectRatio]="editImageSelection === 'icon' ? 1 / 1 : 4 / 3" 
      [resizeToWidth]="320"
      [cropperMinWidth]="128" 
      [onlyScaleDown]="true" 
      [roundCropper]="editImageSelection === 'icon' ? true : false"
      [canvasRotation]="canvasRotation" 
      [transform]="transform" 
      [alignImage]="'center'"
      format="png" 
      (imageCropped)="imageCropped($event)"
      (loadImageFailed)="imageLoadImageFailed()">
    </image-cropper>
    <div class="btn-showcase">
      <button class="btn btn-xs btn-primary btn-icon-only" (click)="rotateLeft()"><app-feather-icons [icon]="'rotate-ccw'"></app-feather-icons></button>
      <button class="btn btn-xs btn-primary btn-icon-only" (click)="rotateRight()"><app-feather-icons [icon]="'rotate-cw'"></app-feather-icons></button>
      <!-- <button class="btn btn-xs btn-primary btn-icon-only" (click)="flipHorizontal()"><app-feather-icons [icon]="'more-horizontal'"></app-feather-icons></button>
      <button class="btn btn-xs btn-primary btn-icon-only" (click)="flipVertical()"><app-feather-icons [icon]="'more-vertical'"></app-feather-icons></button> -->
      <button class="btn btn-xs btn-primary btn-icon-only" (click)="zoomOut()"><app-feather-icons [icon]="'zoom-out'"></app-feather-icons></button>
      <button class="btn btn-xs btn-primary btn-icon-only" (click)="zoomIn()"><app-feather-icons [icon]="'zoom-in'"></app-feather-icons></button>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-light d-block" (click)="imageCroppingClose()" type="button">
      <span>{{ 'HELPERS.cancel' | translate }}</span>
    </button>
    <button class="btn btn-dark d-block" (click)="imageCroppingFinish()" type="button">
      <span>{{ 'HELPERS.save' | translate }}</span>
    </button>
  </div>
</ng-template>