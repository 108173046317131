<!-- form -->
<form class="theme-form" [formGroup]="mainFrm">
  <!-- header -->
  <div class="row">
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
      <h4>{{ 'LOAN.financialTitle' | translate }}</h4>
      <p>{{ 'LOAN.financialTxt' | translate }}</p>
    </div>
    <!-- help -->
    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 pull-right">
      <div class="btn-with-outside-txt-lbl help-btn" (click)="help()">
        <small class="btn-inner-txt">{{ 'HELPERS.help' | translate }}</small> 
        <a class="btn btn-light btn-xs btn-icon-only btn-icon-text-left">
          <app-feather-icons [icon]="'help-circle'"></app-feather-icons>
        </a>
      </div>
    </div>
  </div>
  <!-- business -->
  <div class="row mt-4" *ngIf="workActivity === 'business'">
    <!-- income -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <h5 class="txt-primary"><b>{{ 'USERFINANTIALS.monthlyIncome' | translate }}</b></h5>
      <p class="mb-1" *ngIf="salary > 0">{{ salary | currency:'$' : 'symbol' : '1.0-0' }}</p>
    </div>
    <!-- expenses -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group">
        <label for="expenses">{{ 'USERFINANTIALS.outcome' | translate }}</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input class="form-control" #expenses id="expenses" type="text" inputmode="numeric" formControlName="otherExpenses" placeholder="0">
        </div>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.expenses">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('otherExpenses').hasError(error.type) && (mainFrm.get('otherExpenses').dirty || mainFrm.get('otherExpenses').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'USERFINANTIALS.formHelp.expenses' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- otherAssets -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group">
        <label for="otherAssets">{{ 'USERFINANTIALS.formLabels.otherAssets' | translate }}</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input class="form-control" #otherAssets id="otherAssets" type="text" inputmode="numeric" formControlName="otherAssets" placeholder="0" (change)="updateOtherAssets()">
        </div>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.otherAssets">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('otherAssets').hasError(error.type) && (mainFrm.get('otherAssets').dirty || mainFrm.get('otherAssets').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'USERFINANTIALS.formHelp.otherAssets' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- otherLiabilities -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group">
        <label for="otherLiabilities">{{ 'USERFINANTIALS.formLabels.otherLiabilities' | translate }}</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input class="form-control" #otherLiabilities id="otherLiabilities" type="text" inputmode="numeric" formControlName="otherLiabilities" placeholder="0" (change)="updateOtherLiabilities()">
        </div>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.otherLiabilities">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('otherLiabilities').hasError(error.type) && (mainFrm.get('otherLiabilities').dirty || mainFrm.get('otherLiabilities').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'USERFINANTIALS.formHelp.otherLiabilities' | translate }}</small>
        </p>
      </div>
    </div>
  </div>
  <!-- person -->
  <div *ngIf="workActivity !== 'business'">
    <!-- dependents / housingType -->
    <div class="row mt-4">
      <!-- header -->
      <div class="row mt-4">
        <div class="col">
          <h5 class="txt-primary"><b>{{ 'USERFINANTIALS.family' | translate }}</b></h5>
        </div>
      </div>
      <!-- dependents -->
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="form-group">
          <label for="dependents">{{ 'USERFINANTIALS.formLabels.dependents' | translate }} <span class="required-lbl">*</span></label>
          <input class="form-control" #dependents id="dependents" type="number" inputmode="numeric" min="0" [placeholder]="'USERFINANTIALS.formLabels.dependents' | translate" formControlName="dependents">
          <ng-container class="error-messages" *ngFor="let error of formErrorMessages.dependents">
            <small class="text-danger error-msg" *ngIf="mainFrm.get('dependents').hasError(error.type) && (mainFrm.get('dependents').dirty || mainFrm.get('dependents').touched)">
              {{ error.message }}.&nbsp;
            </small>
          </ng-container>
          <p class="help-messages" *ngIf="toggleHelp">
            <small>{{ 'USERFINANTIALS.formHelp.dependents' | translate }}</small>
          </p>
        </div>
      </div>
      <!-- housingType -->
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="form-group select-fix">
          <span class="caret-select"></span>
          <label for="housingTypeSelect" class="col-form-label">{{ 'USERFINANTIALS.formLabels.housingType' | translate }} <span class="required-lbl">*</span></label>
          <select class="form-control" #housingTypeSelect id="housingTypeSelect" name="housingTypeSelect" formControlName="housingType" [title]="'USERFINANTIALS.formLabels.housingType' | translate">
            <option value="null" selected disabled>{{ 'HELPERS.selectType' | translate }}</option>
            <option value="rent">{{ 'USERFINANTIALS.formLabels.housingTypeRent' | translate }}</option>
            <option value="family">{{ 'USERFINANTIALS.formLabels.housingTypeFamily' | translate }}</option>
            <option value="own">{{ 'USERFINANTIALS.formLabels.housingTypeOwn' | translate }}</option>
          </select>
          <ng-container class="error-messages" *ngFor="let error of formErrorMessages.housingType">
            <small class="text-danger error-msg" *ngIf="mainFrm.get('housingType').hasError(error.type) && (mainFrm.get('housingType').dirty || mainFrm.get('housingType').touched)">
              {{ error.message }}.&nbsp;
            </small>
          </ng-container>
          <p class="help-messages" *ngIf="toggleHelp">
            <small>{{ 'USERFINANTIALS.formHelp.housingType' | translate }}</small>
          </p>
        </div>
      </div>
    </div>
    <!-- income -->
    <div>
      <!-- header -->
      <div class="row mt-4">
        <div class="col">
          <h5 class="txt-primary"><b>{{ 'USERFINANTIALS.monthlyIncome' | translate }}</b></h5>
          <p class="mb-1" *ngIf="salary > 0">{{ 'LOAN.salary' | translate }}: {{ salary | currency:'$' : 'symbol' : '1.0-0' }}</p>
        </div>
      </div>
      <!-- professionalFees / otherIncome -->
      <div class="row mt-4">
        <!-- professionalFees -->
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div class="form-group">
            <label for="professionalFees">{{ 'USERFINANTIALS.formLabels.professionalFees' | translate }}</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input class="form-control" #professionalFees id="professionalFees" type="text" inputmode="numeric" formControlName="professionalFees" placeholder="0">
            </div>
            <ng-container class="error-messages" *ngFor="let error of formErrorMessages.professionalFees">
              <small class="text-danger error-msg" *ngIf="mainFrm.get('professionalFees').hasError(error.type) && (mainFrm.get('professionalFees').dirty || mainFrm.get('professionalFees').touched)">
                {{ error.message }}.&nbsp;
              </small>
            </ng-container>
            <p class="help-messages" *ngIf="toggleHelp">
              <small>{{ 'USERFINANTIALS.formHelp.professionalFees' | translate }}</small>
            </p>
          </div>
        </div>
        <!-- otherIncome -->
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div class="form-group">
            <label for="otherIncome">{{ 'USERFINANTIALS.formLabels.otherIncome' | translate }}</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input class="form-control" #otherIncome id="otherIncome" type="text" inputmode="numeric" formControlName="otherIncome" placeholder="0" (change)="updateOtherIncome()">
            </div>
            <ng-container class="error-messages" *ngFor="let error of formErrorMessages.otherIncome">
              <small class="text-danger error-msg" *ngIf="mainFrm.get('otherIncome').hasError(error.type) && (mainFrm.get('otherIncome').dirty || mainFrm.get('otherIncome').touched)">
                {{ error.message }}.&nbsp;
              </small>
            </ng-container>
            <p class="help-messages" *ngIf="toggleHelp">
              <small>{{ 'USERFINANTIALS.formHelp.otherIncome' | translate }}</small>
            </p>
          </div>
        </div>
        <!-- otherIncomeDescription -->
        <div class="col-12" *ngIf="!(mainFrm.controls.otherIncome.value === '0' || mainFrm.controls.otherIncome.value === '' || mainFrm.controls.otherIncome.value === null)">
          <div class="form-group">
            <label for="otherIncomeDescription">{{ 'USERFINANTIALS.formLabels.otherIncomeDescription' | translate }} <span class="required-lbl">*</span></label>
            <textarea #otherIncomeDescription id="otherIncomeDescription" type="text" inputmode="text" class="form-control" rows="3" [placeholder]="'HELPERS.description' | translate" formControlName="otherIncomeDescription"></textarea>
            <ng-container class="error-messages" *ngFor="let error of formErrorMessages.otherIncomeDescription">
              <small class="text-danger error-msg" *ngIf="mainFrm.get('otherIncomeDescription').hasError(error.type) && (mainFrm.get('otherIncomeDescription').dirty || mainFrm.get('otherIncomeDescription').touched)">
                {{ error.message }}.&nbsp;
              </small>
            </ng-container>
            <p class="help-messages" *ngIf="toggleHelp">
              <small>{{ 'USERFINANTIALS.formHelp.otherIncomeDescription' | translate }}</small>
            </p>
          </div>
        </div>
      </div>
      <!-- total -->
      <div class="row">
        <div class="col">
          <p><b>{{ 'USERFINANTIALS.totalIncome' | translate }}&nbsp;{{ totalIncome | currency:'$' : 'symbol' : '1.0-0' }}</b></p>
        </div>
      </div>
    </div>
    <!-- outcome -->
    <div class="mt-4">
      <!-- header -->
      <div class="row mt-4">
        <div class="col">
          <h5 class="txt-primary"><b>{{ 'USERFINANTIALS.monthlyOutcome' | translate }}</b></h5>
        </div>
      </div>
      <!-- rent / familyExpenses / credits / otherExpenses -->
      <div class="row mt-4">
        <!-- rent -->
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="mainFrm.controls.housingType.value === 'rent' || mainFrm.controls.housingType.value === null">
          <div class="form-group">
            <label for="rent">{{ 'USERFINANTIALS.formLabels.rent' | translate }}</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input class="form-control" #rent id="rent" type="text" inputmode="numeric" formControlName="rent" placeholder="0">
            </div>
            <ng-container class="error-messages" *ngFor="let error of formErrorMessages.rent">
              <small class="text-danger error-msg" *ngIf="mainFrm.get('rent').hasError(error.type) && (mainFrm.get('rent').dirty || mainFrm.get('rent').touched)">
                {{ error.message }}.&nbsp;
              </small>
            </ng-container>
            <p class="help-messages" *ngIf="toggleHelp">
              <small>{{ 'USERFINANTIALS.formHelp.rent' | translate }}</small>
            </p>
          </div>
        </div>
        <!-- familyExpenses -->
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div class="form-group">
            <label for="familyExpenses">{{ 'USERFINANTIALS.formLabels.familyExpenses' | translate }}</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input class="form-control" #familyExpenses id="familyExpenses" type="text" inputmode="numeric" formControlName="familyExpenses" placeholder="0">
            </div>
            <ng-container class="error-messages" *ngFor="let error of formErrorMessages.familyExpenses">
              <small class="text-danger error-msg" *ngIf="mainFrm.get('familyExpenses').hasError(error.type) && (mainFrm.get('familyExpenses').dirty || mainFrm.get('familyExpenses').touched)">
                {{ error.message }}.&nbsp;
              </small>
            </ng-container>
            <p class="help-messages" *ngIf="toggleHelp">
              <small>{{ 'USERFINANTIALS.formHelp.familyExpenses' | translate }}</small>
            </p>
          </div>
        </div>
        <!-- credits -->
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div class="form-group">
            <label for="credits">{{ 'USERFINANTIALS.formLabels.credits' | translate }}</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input class="form-control" #credits id="credits" type="text" inputmode="numeric" formControlName="credits" placeholder="0">
            </div>
            <ng-container class="error-messages" *ngFor="let error of formErrorMessages.credits">
              <small class="text-danger error-msg" *ngIf="mainFrm.get('credits').hasError(error.type) && (mainFrm.get('credits').dirty || mainFrm.get('credits').touched)">
                {{ error.message }}.&nbsp;
              </small>
            </ng-container>
            <p class="help-messages" *ngIf="toggleHelp">
              <small>{{ 'USERFINANTIALS.formHelp.credits' | translate }}</small>
            </p>
          </div>
        </div>
        <!-- otherExpenses -->
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div class="form-group">
            <label for="otherExpenses">{{ 'USERFINANTIALS.formLabels.otherExpenses' | translate }}</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input class="form-control" #otherExpenses id="otherExpenses" type="text" inputmode="numeric" formControlName="otherExpenses" placeholder="0" (change)="updateOtherExpenses()">
            </div>
            <ng-container class="error-messages" *ngFor="let error of formErrorMessages.otherExpenses">
              <small class="text-danger error-msg" *ngIf="mainFrm.get('otherExpenses').hasError(error.type) && (mainFrm.get('otherExpenses').dirty || mainFrm.get('otherExpenses').touched)">
                {{ error.message }}.&nbsp;
              </small>
            </ng-container>
            <p class="help-messages" *ngIf="toggleHelp">
              <small>{{ 'USERFINANTIALS.formHelp.otherExpenses' | translate }}</small>
            </p>
          </div>
        </div>
        <!-- otherExpensesDescription -->
        <div class="col-12" *ngIf="!(mainFrm.controls.otherExpenses.value === '0' || mainFrm.controls.otherExpenses.value === '' || mainFrm.controls.otherExpenses.value === null)">
          <div class="form-group">
            <label for="otherExpensesDescription">{{ 'USERFINANTIALS.formLabels.otherExpensesDescription' | translate }} <span class="required-lbl">*</span></label>
            <textarea #otherExpensesDescription id="otherExpensesDescription" type="text" inputmode="text" class="form-control" rows="3" [placeholder]="'HELPERS.description' | translate" formControlName="otherExpensesDescription"></textarea>
            <ng-container class="error-messages" *ngFor="let error of formErrorMessages.otherExpensesDescription">
              <small class="text-danger error-msg" *ngIf="mainFrm.get('otherExpensesDescription').hasError(error.type) && (mainFrm.get('otherExpensesDescription').dirty || mainFrm.get('otherExpensesDescription').touched)">
                {{ error.message }}.&nbsp;
              </small>
            </ng-container>
            <p class="help-messages" *ngIf="toggleHelp">
              <small>{{ 'USERFINANTIALS.formHelp.otherExpensesDescription' | translate }}</small>
            </p>
          </div>
        </div>
      </div>
      <!-- total -->
      <div class="row">
        <div class="col">
          <p><b>{{ 'USERFINANTIALS.totalOutcome' | translate }}&nbsp;{{ totalOutcome | currency:'$' : 'symbol' : '1.0-0' }}</b></p>
        </div>
      </div>
    </div>
    <!-- otherAssetsAndLiabilities -->
    <div class="mt-4 mb-4">
      <!-- header -->
      <div class="row mt-4">
        <div class="col">
          <h5 class="txt-primary"><b>{{ 'USERFINANTIALS.otherAssetsAndLiabilities' | translate }}</b></h5>
        </div>
      </div>
      <!-- otherAssets -->
      <div class="row mt-4">
        <!-- otherAssets -->
        <div class="col-12">
          <div class="form-group">
            <label for="otherAssets">{{ 'USERFINANTIALS.formLabels.otherAssets' | translate }}</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input class="form-control" #otherAssets id="otherAssets" type="text" inputmode="numeric" formControlName="otherAssets" placeholder="0" (change)="updateOtherAssets()">
            </div>
            <ng-container class="error-messages" *ngFor="let error of formErrorMessages.otherAssets">
              <small class="text-danger error-msg" *ngIf="mainFrm.get('otherAssets').hasError(error.type) && (mainFrm.get('otherAssets').dirty || mainFrm.get('otherAssets').touched)">
                {{ error.message }}.&nbsp;
              </small>
            </ng-container>
            <p class="help-messages" *ngIf="toggleHelp">
              <small>{{ 'USERFINANTIALS.formHelp.otherAssets' | translate }}</small>
            </p>
          </div>
        </div>
        <!-- otherAssetsDescription -->
        <div class="col-12" *ngIf="!(mainFrm.controls.otherAssets.value === '0' || mainFrm.controls.otherAssets.value === '' || mainFrm.controls.otherAssets.value === null)">
          <div class="form-group">
            <label for="otherAssetsDescription">{{ 'USERFINANTIALS.formLabels.otherAssetsDescription' | translate }} <span class="required-lbl">*</span></label>
            <textarea #otherAssetsDescription id="otherAssetsDescription" type="text" inputmode="text" class="form-control" rows="3" [placeholder]="'HELPERS.description' | translate" formControlName="otherAssetsDescription"></textarea>
            <ng-container class="error-messages" *ngFor="let error of formErrorMessages.otherAssetsDescription">
              <small class="text-danger error-msg" *ngIf="mainFrm.get('otherAssetsDescription').hasError(error.type) && (mainFrm.get('otherAssetsDescription').dirty || mainFrm.get('otherAssetsDescription').touched)">
                {{ error.message }}.&nbsp;
              </small>
            </ng-container>
            <p class="help-messages" *ngIf="toggleHelp">
              <small>{{ 'USERFINANTIALS.formHelp.otherAssetsDescription' | translate }}</small>
            </p>
          </div>
        </div>
      </div>
      <!-- otherLiabilities -->
      <div class="row">
        <!-- otherLiabilities -->
        <div class="col-12">
          <div class="form-group">
            <label for="otherLiabilities">{{ 'USERFINANTIALS.formLabels.otherLiabilities' | translate }}</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input class="form-control" #otherLiabilities id="otherLiabilities" type="text" inputmode="numeric" formControlName="otherLiabilities" placeholder="0" (change)="updateOtherLiabilities()">
            </div>
            <ng-container class="error-messages" *ngFor="let error of formErrorMessages.otherLiabilities">
              <small class="text-danger error-msg" *ngIf="mainFrm.get('otherLiabilities').hasError(error.type) && (mainFrm.get('otherLiabilities').dirty || mainFrm.get('otherLiabilities').touched)">
                {{ error.message }}.&nbsp;
              </small>
            </ng-container>
            <p class="help-messages" *ngIf="toggleHelp">
              <small>{{ 'USERFINANTIALS.formHelp.otherLiabilities' | translate }}</small>
            </p>
          </div>
        </div>
        <!-- otherLiabilitiesDescription -->
        <div class="col-12" *ngIf="!(mainFrm.controls.otherLiabilities.value === '0' || mainFrm.controls.otherLiabilities.value === '' || mainFrm.controls.otherLiabilities.value === null)">
          <div class="form-group">
            <label for="otherLiabilitiesDescription">{{ 'USERFINANTIALS.formLabels.otherLiabilitiesDescription' | translate }} <span class="required-lbl">*</span></label>
            <textarea #otherLiabilitiesDescription id="otherLiabilitiesDescription" type="text" inputmode="text" class="form-control" rows="3" [placeholder]="'HELPERS.description' | translate" formControlName="otherLiabilitiesDescription"></textarea>
            <ng-container class="error-messages" *ngFor="let error of formErrorMessages.otherLiabilitiesDescription">
              <small class="text-danger error-msg" *ngIf="mainFrm.get('otherLiabilitiesDescription').hasError(error.type) && (mainFrm.get('otherLiabilitiesDescription').dirty || mainFrm.get('otherLiabilitiesDescription').touched)">
                {{ error.message }}.&nbsp;
              </small>
            </ng-container>
            <p class="help-messages" *ngIf="toggleHelp">
              <small>{{ 'USERFINANTIALS.formHelp.otherLiabilitiesDescription' | translate }}</small>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- originFunds -->
    <div class="row mt-4">
      <div class="col-12">
        <h5 class="txt-primary"><b>{{ 'USERFINANTIALS.originFundsDeclaration' | translate }}</b></h5>
        <div class="form-group">
          <p class="origin-funds-initial">{{ 'USERFINANTIALS.originFundsDeclarationDescription' | translate }}</p>
          <label for="originFunds">{{ 'USERFINANTIALS.originFunds' | translate }} <span class="required-lbl">*</span></label>
          <textarea #originFunds id="originFunds" type="text" inputmode="text" class="form-control origin-funds-text-area" rows="3" [placeholder]="'HELPERS.description' | translate" formControlName="originFunds"></textarea>
          <p class="origin-funds-ending">{{ 'USERFINANTIALS.originFundsDeclarationDescriptionEnding' | translate }}</p>
          <ng-container class="error-messages" *ngFor="let error of formErrorMessages.originFunds">
            <small class="text-danger error-msg" *ngIf="mainFrm.get('originFunds').hasError(error.type) && (mainFrm.get('originFunds').dirty || mainFrm.get('originFunds').touched)">
              {{ error.message }}.&nbsp;
            </small>
          </ng-container>
          <p class="help-messages" *ngIf="toggleHelp">
            <small>{{ 'USERFINANTIALS.formHelp.originFunds' | translate }}</small>
          </p>
        </div>
      </div>
    </div>
    <!-- publicResourcesHandling -->
    <div class="row mt-4">
      <div class="col-12">
        <h5 class="txt-primary"><b>{{ 'USERFINANTIALS.publicResources' | translate }}</b></h5>
        <p>{{ 'USERFINANTIALS.formLabels.publicResourcesHandlingAsk' | translate }}</p>
        <label class="radio-container radio-yes">
          {{ 'HELPERS.yes' | translate }}
          <input #publicResourcesHandlingYes id="publicResourcesHandlingYes" type="radio" name="publicResourcesHandling" formControlName="publicResourcesHandling" value="true">
          <span class="checkmark"></span>
        </label>
        <label class="radio-container radio-no">
          {{ 'HELPERS.no' | translate }}
          <input #publicResourcesHandlingNo id="publicResourcesHandlingNo" type="radio" name="publicResourcesHandling" formControlName="publicResourcesHandling" value="false">
          <span class="checkmark"></span>
        </label>
        <!-- <div class="form-check form-switch publicResourcesHandling-switch">
          <input #publicResourcesHandling id="publicResourcesHandling" type="checkbox" role="switch" formControlName="publicResourcesHandling" class="form-check-input">
          <label class="form-check-label" for="publicResourcesHandling">{{ 'USERFINANTIALS.formLabels.publicResourcesHandlingAsk' | translate }}</label>
        </div> -->
      </div>
    </div>
  </div>
  <!-- buttons -->
  <div class="row mt-4">
    <div class="col">
      <div class="request-form-btns-container">
        <button class="btn btn-light d-block btn-cancel" (click)="goBack()" type="button">
          <span *ngIf="(origin === 'b2c' || origin === 'loan-add')">{{ 'HELPERS.back' | translate }}</span>
          <span *ngIf="!(origin === 'b2c' || origin === 'loan-add')">{{ 'HELPERS.cancel' | translate }}</span>
        </button>
        <button class="btn btn-dark d-block" (click)="save()" type="button">
          <span *ngIf="(origin === 'b2c' || origin === 'loan-add')">{{ 'HELPERS.next' | translate }}</span>
          <span *ngIf="!(origin === 'b2c' || origin === 'loan-add')">{{ 'HELPERS.save' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</form>