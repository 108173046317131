// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 03/06/2023
// import
import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges  } from '@angular/core';
// translate
import { TranslateService } from '@ngx-translate/core';
// component
@Component({
  selector: 'app-loan-document-preview',
  templateUrl: './loan-document-preview.component.html',
  styleUrls: ['./loan-document-preview.component.scss']
})
// class
export class LoanDocumentPreviewComponent implements OnInit, OnChanges {
  // inputs
  @Input() fileData: any = null;
  @Input() editEnable: boolean = false;
  @Input() viewEnable: boolean = true;
  image: any = null;
  file: any = null;
  docType: string = null;
  fileId: string = null;
  fileType: string = null;
  fileName: string = null;
  filePath: string = null;
  // outputs
  @Output() actionEvent = new EventEmitter();
  // constructor
  constructor(
    public translate: TranslateService,
  ) {
  }
  // life cycle
  async ngOnInit() {}
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.fileData) {
      await this.setData();
    }
  }
  // data
  async setData() {
    // check for fileData
    if (this.fileData !== null) {
      this.image = this.fileData.image;
      this.file = this.fileData.file;
      this.docType = this.fileData.docType;
      this.fileId = this.fileData.fileId;
      this.fileType = this.fileData.fileType;
      this.fileName = this.fileData.fileName;
      this.filePath = this.fileData.filePath;
    }
  }
  // actions
  sendAction(action: string) {
    // emit data
    this.actionEvent.emit(action);
  }
}
