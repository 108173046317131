// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 29/09/2023
// import
import { Component, OnInit, Input } from '@angular/core';
// component
@Component({
  selector: 'app-total-pending',
  templateUrl: './total-pending.component.html',
  styleUrls: ['./total-pending.component.scss']
})
export class TotalPendingComponent {
  // variables
  @Input() totalPending: number = 0;
}
