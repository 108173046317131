// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 25/05/2023
// tslint:disable: max-line-length
// tslint:disable: no-unused-expression
// import
import { Pipe, PipeTransform } from '@angular/core';
// pipe
@Pipe({
  name: 'currencyFormat'
})
export class CurrencyFormatPipe implements PipeTransform {
  transform(value: string, decimalDigits: number): string {
    const parsedValue = parseFloat(value);
    
    if (isNaN(parsedValue)) {
      return value; // Devuelve el valor original si no se puede convertir a un número válido
    }

    const formattedValue = parsedValue.toFixed(decimalDigits).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return formattedValue;

  }
}