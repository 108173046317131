// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 25/05/2023
// import
import { Component, EventEmitter, OnInit, OnChanges, Input, Output, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
// translate
import { TranslateService } from '@ngx-translate/core';
// environment
import { environment } from 'src/environments/environment';
// plugins
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageTransform, ImageCroppedEvent } from 'ngx-image-cropper';
// services
import { AuthService } from 'src/app/shared/services/auth.service';
import { EncodingService } from 'src/app/shared/services/encoding.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { LocationService } from 'src/app/shared/services/location.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
import { MediaService } from 'src/app/shared/services/media.service';
// interfaces
import { ProductCategoryInterface } from 'src/app/shared/interfaces/product-category.interface';
// component
@Component({
  selector: 'app-product-category-form',
  templateUrl: './product-category-form.component.html',
  styleUrls: ['./product-category-form.component.scss']
})
// class
export class ProductCategoryFormComponent implements OnInit, OnChanges {
  // variables
  @Input() productCategoryData: ProductCategoryInterface = null;
  @Output() loaderEvent = new EventEmitter();
  @Output() addEvent = new EventEmitter();
  @Output() updateEvent = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  // lang data
  currentLang: string = null;
  // form data
  public mainFrm: FormGroup;
  // messages data
  formErrorMessages: any = {};
  // help data
  toggleHelp = false;
  // saved data
  savedData: boolean = false;
  // product category data
  productCategory: string = null;
  // productCategoryIcon data
  productCategoryIconFile: any = null;
  productCategoryIconSource: any = null;
  productCategoryIconUpdate: boolean = false
  // edit image data
  imageChangedEvent: any = null;
  canvasRotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  imageModal: any = null;
  // constructor
  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public authSv: AuthService,
    public encodingSv: EncodingService,
    public apiSv: ApiService,
    public locationSv: LocationService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    private utilitiesSv: UtilitiesService,
    private mediaSv: MediaService,
    private modalService: NgbModal,
  ) {
    // main form
    this.mainFrm = this.formBuilder.group({
      productCategory: [null, [Validators.required]],
      productCategoryStatus: [null, [Validators.required]],
    });
  }
  // life cycle
  async ngOnInit() {
    await this.translateMsgs();
    this.initData();
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.productCategoryData) {
      // check for productCategoryData
      if (this.productCategoryData !== null) {
        this.initData();
      }
    }
  }
  async initData() {
    // show loader
    this.loaderEvent.emit(this.translate.instant('HELPERS.loadingTxt'));
    // get data
    await this.setProductCategoryData();
    // hide loader
    this.loaderEvent.emit(null);
  }
  // translate
  async translateMsgs() {
    // update form error messages
    this.formErrorMessages = {
      productCategory: [
        { type: 'required', message: this.translate.instant('PRODUCTCATEGORIES.formValidation.productCategory.required') }
      ],
      productCategoryStatus: [
        { type: 'required', message: this.translate.instant('PRODUCTCATEGORIES.formValidation.productCategoryStatus.required') }
      ],
    };
  }
  // form
  async help() {
    if (this.toggleHelp) {
      this.toggleHelp = false;
    } else {
      this.toggleHelp = true;
    }
  }
  async setFocus(elementId: any) {
    elementId.focus();
  }
  checkFormField(fieldName: string) {
    // get field
    const field = this.mainFrm.get(fieldName);
    // check field
    const check = field?.invalid && field?.touched;
    // return data
    return check;
  }
  // navigation
  async cancel() {
    // emit data
    this.cancelEvent.emit(); // Return dat
  }
  async setProductCategoryData() {
    // check productCategory data
    if (this.productCategoryData !== null) {
      // set productCategory
      const productCategory: ProductCategoryInterface = this.productCategoryData;
      // set productCategoryIcon
      this.productCategoryIconSource = productCategory.productCategoryIcon;
      // set productCategory data
      this.productCategory = productCategory.productCategory;
      // set form data
      this.mainFrm.controls.productCategory.setValue(productCategory.productCategory);
      this.mainFrm.controls.productCategoryStatus.setValue(productCategory.productCategoryStatus);
      // set savedData
      this.savedData = true;
    }
  }
  // actions
  async save() {
    // markAllAsTouched
    this.mainFrm.markAllAsTouched();
    // validate data
    if (!this.mainFrm.valid) {
      // send alert
      this.alertSv.showMessage(this.translate.instant('HELPERS.requiredFields'),'warning', this.translate.instant('HELPERS.warning'), true);
    } else {
      // check if data is been saved before
      if (!this.savedData) {
        // add data
        await this.addProductCategory();
      } else {
        // update data
        await this.updateProductCategory();
      }
    }
  }
  async addProductCategory() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // get name data
      const productCategory = this.utilitiesSv.capitalizeString(this.mainFrm.controls.productCategory.value);
      // set productCategoryData
      const productCategoryData: ProductCategoryInterface = {
        productCategory,
        ...((this.productCategoryIconUpdate) && {productCategoryIcon: this.productCategoryIconSource}),
        productCategoryStatus: this.mainFrm.controls.productCategoryStatus.value,
      }
      // get auxProductCategory
      const auxProductCategory: any = await this.apiSv.addProductCategory(productCategoryData);
      // get data
      const data: any = auxProductCategory.data;
      // get productCategoryId
      const productCategoryId: string = data.id;
      // set savedData
      this.savedData = true;
      // set saveProductCategoryData
      const saveProductCategoryData: ProductCategoryInterface = {
        id: productCategoryId,
        productCategory,
        productCategoryIcon: this.productCategoryIconSource,
        productCategoryStatus: this.mainFrm.controls.productCategoryStatus.value
      }
      // update productCategoryData
      this.productCategoryData = saveProductCategoryData;
      // hide loader
      this.loaderEvent.emit(null);
      // emit data
      this.addEvent.emit(saveProductCategoryData);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async updateProductCategory() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // get name data
      const productCategory = this.utilitiesSv.capitalizeString(this.mainFrm.controls.productCategory.value);
      // set productCategoryData
      const productCategoryData: ProductCategoryInterface = {
        productCategory,
        ...((this.productCategoryIconUpdate) && {productCategoryIcon: this.productCategoryIconSource}),
        productCategoryStatus: this.mainFrm.controls.productCategoryStatus.value
      }
      // update product
      await this.apiSv.updateProductCategory(this.productCategoryData.id, productCategoryData);
      // update productCategoryData
      this.productCategoryData.productCategory = productCategory;
      this.productCategoryData.productCategoryIcon = this.productCategoryIconSource;
      this.productCategoryData.productCategoryStatus = this.mainFrm.controls.productCategoryStatus.value;
      // hide loader
      this.loaderEvent.emit(null);
      // emit data
      this.updateEvent.emit(this.productCategoryData);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  // image
  async editImage() {
    // trigger hidden input file
    this.fileInput.nativeElement.click();
  }
  async onFileSelected(event: any, modal: any) {
    const file = event.target.files?.[0];
    if (file) {
      // check fileType
      if (file.type.startsWith('image/')) {
        // get uploaded file data
        const fileSize = file.size;
        // check file size
        if (fileSize >= environment.maxFileData) {
          // hide loader
          this.loaderEvent.emit(null);
          // show loader
          this.alertSv.showMessage(this.translate.instant('HELPERS.fileTooBig'),'error', this.translate.instant('HELPERS.error'), true);
        } else {
          // set image changed event
          this.imageChangedEvent = event;
          // showCropper
          this.showCropper = true;
          // open crop modal
          this.imageModal = this.modalService.open(modal);
        }
      } else {
        // hide loader
        this.loaderEvent.emit(null);
        // show loader
        this.alertSv.showMessage(this.translate.instant('HELPERS.fileNoImage'),'error', this.translate.instant('HELPERS.error'), true);
      }
    } else {
      // hide loader
      this.loaderEvent.emit(null);
    }
  }
  async uploadFile(file: any) {
    const imageData = new FormData()
    imageData.append('file', file);
    // send file to api
    const imageFile: any = await this.apiSv.uploadFile(imageData);
    // get file data
    const fileData: any = imageFile.data;
    const filePath = fileData.filePath;
    // save file
    this.updateFile(filePath);
  }
  async updateFile(filePath: string) {
    try {
      // mark productCategoryIconUpdate
      this.productCategoryIconUpdate = true;
      // update icon source
      this.productCategoryIconSource = filePath;
      // hide loader
      this.loaderEvent.emit(null);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  imageCroppingClose() {
    // clear icon
    this.productCategoryIconSource = null;
    // clear image event
    this.imageChangedEvent = null;
    // hide modal
    this.imageModal.close();
  }
  async imageCroppingFinish() {
    // hide modal
    this.imageModal.close();
    // show loader
    this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
    // init base 64 data
    let b64Data: string = null;
    // clear icon
    this.productCategoryIconFile = null;
    // get base 64 data
    b64Data = this.productCategoryIconSource;
    // get file data
    const productCategory = this.mainFrm.controls.productCategory.value;
    const fileName = productCategory === null ? 'product-category-icon.png' : await this.utilitiesSv.slugify(productCategory) + '-product-category-icon.png';
    var file = this.mediaSv.dataURLtoFile(b64Data, fileName);
    // upload file
    await this.uploadFile(file);
  }
  // cropping media
  imageCropped(event: ImageCroppedEvent) {
    // set icon
    this.productCategoryIconSource = event.base64;
  }
  imageLoadImageFailed() {
    // show message
    this.alertSv.showMessage(this.translate.instant('HELPERS.fileLoadImageError'),'error', this.translate.instant('HELPERS.error'), true);
  }
  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }
  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }
  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }
  flipHorizontal() {
    this.transform = {
        ...this.transform,
        flipH: !this.transform.flipH
    };
  }
  flipVertical() {
    this.transform = {
        ...this.transform,
        flipV: !this.transform.flipV
    };
  }
  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }
  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }
}
