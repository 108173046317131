// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 19/06/2023
// import
import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
// translate
import { TranslateService } from '@ngx-translate/core';
// services
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
// interfaces
import { UserInterface } from 'src/app/shared/interfaces/user.interface';
import { CompanyInterface } from 'src/app/shared/interfaces/company.interface';
import { CountryInterface } from 'src/app/shared/interfaces/country.interface';
import { LoanInterface } from 'src/app/shared/interfaces/loan.interface';
// component
@Component({
  selector: 'app-dashboard-temp',
  templateUrl: './dashboard-temp.component.html',
  styleUrls: ['./dashboard-temp.component.scss']
})
// class
export class DashboardTempComponent implements OnInit {
  // variables
  @Input() userData: UserInterface = null;
  @Input() companyData: UserInterface = null;
  @Output() loaderEvent = new EventEmitter();
  // user data
  user: UserInterface = null;
  userId: string = null;
  firstName: string = null;
  avatar: string = null;
  role: string = null;
  userRole: any = null;
  // role variables
  isSupport: boolean = false;
  isFinancial: boolean = false;
  isAdmin: boolean = false;
  isBoard: boolean = false;
  isTreasure: boolean = false;
  isBusinessAgent: boolean = false;
  isCEO: boolean = false;
  isManager: boolean = false;
  isCompany: boolean = false;
  isCompanyAdmin: boolean = false;
  isCompanyAuxiliar: boolean = false;
  isClient: boolean = false;
  // company data
  company: CompanyInterface = null;
  companyId: string = null;
  // search data
  startDate: any = null;
  finishDate: any = null;
  minDate: any = null;
  maxDate: any = null;
  currentMonthYear: any = null;
  // home data
  homeError: boolean = false;
  // reports
  // loans data
  loans: any[] = [];
  loanAll: number = 0;
  loanInitial: number = 0;
  loanStarted: number = 0;
  loanSended: number = 0;
  loanAccepted: number = 0;
  loanNoPassedValidation: number = 0;
  loanValidated: number = 0;
  loanRejected: number = 0;
  loanPreApproved: number = 0;
  loanReadyToApprove: number = 0;
  loanApproved: number = 0;
  loanReadyForDisbursement: number = 0;
  loanDisbursementOnProgress: number = 0;
  loanDisbursed: number = 0;
  loanPayed: number = 0;
  loanLate: number = 0;
  loanPreLegal: number = 0;
  loanLegal: number = 0;
  loanNegotiation: number = 0;
  loanExpired: number = 0;
  // batches data
  batchDisbursement: number = 0;
  batchPayment: number = 0;
  // totals data
  totalRequests: number = 0;
  totalPending: number = 0;
  totalNoPassedValidation: number = 0;
  totalRejected: number = 0;
  totalReadyToApprove: number = 0;
  totalApproved: number = 0;
  totalDisbursed: number = 0;
  totalPayed: number = 0;
  totalToCollect: number = 0;
  // constructor
  constructor(
    public translate: TranslateService,
    public apiSv: ApiService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    private utilitiesSv: UtilitiesService,
  ) { }
  // life cycle
  async ngOnInit() {
    // data
    await this.setUserData();
    await this.setCompanyData();
    this.generalData();
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.userData) {
      await this.setUserData();
      // check user data
      if (this.user !== changes.userData.currentValue) {
        this.generalData();
      }
    }
    if (changes.companyData) {
      await this.setCompanyData();
      // check company data
      if (this.company !== changes.companyData.currentValue) {
        this.generalData();
      }
    }
  }
  async generalData() {
    // check user data
    if (this.user !== null) {
      this.getLoanTotals();
      this.getLoansData();
    }
  }
  // loader
  async updateLoader(loaderMsg: any) {
    this.loaderEvent.emit(loaderMsg);
  }
  // navigation
  async goToPage(page: string, params?: any | null){
    // navigate to page
    this.utilitiesSv.goTo(page, true, params);
  }
  // actions
  async copyHash(hash: string) {
    navigator.clipboard.writeText(hash);
  }
  // data
  async setUserData() {
    // get data user
    const userData: UserInterface = this.userData;
    // check user data
    if (userData !== null) {
      // set data user
      this.user = userData;
      this.userId = userData.id;
      this.firstName = userData.firstName;
      this.avatar = userData.avatar;
      this.role = userData.role;
      this.userRole = userData.userRole;
      // check roles
      const checkSupport = await this.utilitiesSv.checkRoles(userData.role, ['support']);
      this.isSupport = checkSupport;
      const checkFinancial = await this.utilitiesSv.checkRoles(userData.role, ['support', 'administrator', 'board', 'treasure', 'businessAgent']);
      this.isFinancial = checkFinancial;
      const checkAdmin = await this.utilitiesSv.checkRoles(userData.role, ['administrator']);
      this.isAdmin = checkAdmin;
      const checkBoard = await this.utilitiesSv.checkRoles(userData.role, ['board']);
      this.isBoard = checkBoard;
      const checkTreasure = await this.utilitiesSv.checkRoles(userData.role, ['treasure']);
      this.isTreasure = checkTreasure;
      const checkBusinessAgent = await this.utilitiesSv.checkRoles(userData.role, ['businessAgent']);
      this.isBusinessAgent = checkBusinessAgent;
      const checkCompany = await this.utilitiesSv.checkRoles(userData.role, ['support', 'ceo', 'manager', 'auxiliar']);
      this.isCompany = checkCompany;
      const checkCompanyAdmin = await this.utilitiesSv.checkRoles(userData.role, ['ceo', 'manager']);
      this.isCompanyAdmin = checkCompanyAdmin;
      const checkCompanyAuxiliar = await this.utilitiesSv.checkRoles(userData.role, ['auxiliar']);
      this.isCompanyAuxiliar = checkCompanyAuxiliar;
      const checkCEO = await this.utilitiesSv.checkRoles(userData.role, ['ceo']);
      this.isCEO = checkCEO;
      const checkManager = await this.utilitiesSv.checkRoles(userData.role, ['manager']);
      this.isManager = checkManager;
      const checkClient = await this.utilitiesSv.checkRoles(userData.role, ['client']);
      this.isClient = checkClient;
    }
  }
  async setCompanyData() {
    // get data company
    const companyData: CompanyInterface = this.companyData;
    // check company data
    if (companyData) {
      this.company = companyData;
      this.companyId = companyData.id;
    }
  }
  // actions
  openLoan(loan: LoanInterface) {
    // navigate to page
    this.goToPage('loans/detail/' + loan.loanNumber);
  }
  openUser(user: UserInterface) {
    // navigate to page
    this.goToPage('users/detail/' + user.id);
  }
  // report data
  async getLoansData() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.loadingTxt') + ' ' + this.translate.instant('LOAN.loans'));
      // init loans data
      let loansData: any = null;
      // check role
      if (this.isClient) {
        loansData = {
          limit: 5, 
          page: 1, 
          userId: this.userId,
          companyId: null,
          productId: null,
          loanType: null,
          loanNumber: null,
          loanCode: null,
          loanAmount:  null,
          loanStatusLast: null,
        }
      } else if (this.isCompany) {
        loansData = {
          limit: 5, 
          page: 1, 
          userId: null,
          companyId: this.companyId,
          productId: null,
          loanType: null,
          loanNumber: null,
          loanCode: null,
          loanAmount:  null,
          loanStatusLast: null,
        }
      } else {
        loansData = {
          limit: 5, 
          page: 1, 
          userId: null,
          companyId: null,
          productId: null,
          loanType: null,
          loanNumber: null,
          loanCode: null,
          loanAmount:  null,
          loanStatusLast: null,
        }
      }
      // init data
      const data: any = await this.apiSv.getLoans(loansData);
      // get result
      const result = data.data.result;
      // get pages
      const pages = data.data.pages;
      // check if loans has returned data
      if (result && result.length > 0) {
        // clear loans
        this.loans = [];
        // init loans
        const loans: any[] = [];
        // loop loans
        for (let index = 0; index < result.length; index++) {
          const item: any = result[index];
          // get user
          const user = item.userWork.user;
          // check cellphoneCountryCode
          if (user.cellphoneCountryCode) {
            const auxCellphoneCountry: any = await this.apiSv.getCountry(user.cellphoneCountryCode);
            const cellphoneCountry: CountryInterface = auxCellphoneCountry.data;
            user.cellphoneCountryName = cellphoneCountry.countryName;
            user.cellphoneCountryCodeCod = cellphoneCountry.numericCode;
          }
          // add user to item
          item.user = user;
          // push data
          loans.push(item);
        }
        // update loans
        this.loans = loans;
        // hide loader
        this.loaderEvent.emit(null);
      } else {
        // hide loader
        this.loaderEvent.emit(null);
      }
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // error data
    }
  }
  async getLoanTotals() {
    return new Promise(async (resolve, reject) => {
      try {
        // show loader
        this.loaderEvent.emit(this.translate.instant('HELPERS.loadingTxt') + ' ' + this.translate.instant('REPORT.loanQuantity'));
        // get data from api
        await this.apiSv.getLoanTotals(this.companyId ? this.companyId : null).then(async (response: any)=>{
          // get badgesCounts
          const badgesCounts: any = response.data;
          // update totals
          this.loanInitial = badgesCounts.initial;
          this.totalRequests = badgesCounts.all;
          this.loanAll = badgesCounts.all;
          this.loanStarted = badgesCounts.started;
          this.loanSended = badgesCounts.sended;
          this.loanAccepted = badgesCounts.accepted;
          this.loanNoPassedValidation = badgesCounts.noPassedValidation;
          this.loanValidated = badgesCounts.validated;
          this.loanRejected = badgesCounts.rejected;
          this.loanPreApproved = badgesCounts.preApproved;
          this.loanReadyToApprove = badgesCounts.readyToApprove;
          this.loanApproved = badgesCounts.approved;
          this.loanReadyForDisbursement = badgesCounts.readyForDisbursement;
          this.loanDisbursementOnProgress = badgesCounts.disbursementOnProgress;
          this.loanDisbursed = badgesCounts.disbursed;
          this.loanPayed = badgesCounts.payed;
          this.loanLate = badgesCounts.late;
          this.loanPreLegal = badgesCounts.preLegal;
          this.loanLegal = badgesCounts.legal;
          this.loanNegotiation = badgesCounts.negotiation;
          this.loanExpired = badgesCounts.expired;
          // resolve
          resolve(true);
        }, error=>{
          console.log('error', error);
          reject(error);
        });
      } catch (error) {
        console.log('error', error);
        // hide loader
        this.loaderEvent.emit(null);
        reject(error);
      }
    });
  }
}
