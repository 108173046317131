// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 25/05/2023
// tslint:disable: max-line-length
// tslint:disable: no-unused-expression
// import
import { Component, OnInit } from '@angular/core';
// translate
import { TranslateService } from '@ngx-translate/core';
// environment
import { environment } from 'src/environments/environment';
// plugins
declare var $: any;
import Swal from 'sweetalert2';
// services
import { AuthService } from 'src/app/shared/services/auth.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
// interfaces
import { UserInterface } from 'src/app/shared/interfaces/user.interface';
import { UpdateDataInterface } from 'src/app/shared/reducers/interfaces';
import { CompanyInterface } from 'src/app/shared/interfaces/company.interface';
// reducers
import { Store } from '@ngrx/store';
// component
@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  // variables
  public openProfile: boolean = false;
  // user data
  public userData: boolean = false;
  public firstName: string = null;
  public lastName: string = null;
  public role: string = null;
  public userRole: any = null;
  public avatar: string = environment.websiteURI + '/wp-content/uploads/logos/profile-ring.png';
  // company data
  public companyData: CompanyInterface = null;
  public companies: any[] = [];
  // constructor
  constructor(
    public translate: TranslateService,
    public authSv: AuthService,
    public apiSv: ApiService,
    private utilitiesSv: UtilitiesService,
    private dataState: Store<UpdateDataInterface>,
  ) { 
    // update user data
    this.dataState.subscribe(async(state: any) =>{
      // loans
      if (state.profileReducer) {
        await this.getUserData();
      }
      if (state.companyReducer) {
        await this.getCompanyData();
      }
    });
  }
  // life cycle
  async ngOnInit() {
    await this.getUserData();
    await this.getCompanyData();
  }
  // navigation
  async goToPage(page: string, params?: any | null){
    // navigate to page
    this.utilitiesSv.goTo(page, true, params);
  }
  // data
  async getUserData() {
    // get stored data user
    const storedDataUser: any = localStorage.getItem('userData');
    // get data user
    const userData: UserInterface = JSON.parse(storedDataUser);
    // check user data
    if (userData) {
      this.userData = true;
      this.firstName = userData.firstName;
      this.lastName = userData.lastName;
      this.role = userData.role;
      this.userRole = typeof userData.userRole === 'string' ? JSON.parse(userData.userRole) : userData.userRole;
      this.avatar = userData.avatar;

      // get company data
      const companyData: any = await this.apiSv.getUserCompanies(userData.id);
      // get companies
      const companies: any = companyData.data;
      // update companies
      this.companies = companies;
    }
  }
  async getCompanyData() {
    // get stored data company
    const storedDataUser: any = localStorage.getItem('companyData');
    // get data company
    const companyData: UserInterface = JSON.parse(storedDataUser);
    // check company data
    if (companyData) {
      // set data company
      this.companyData = companyData;
    }
  }
  // actions
  toggleProfile() {
    this.openProfile = !this.openProfile;
  }
  async logout() {
    try {
      await this.authSv.logout();
    } catch (error: any) {
      console.log('error: ', error);
    }
  }
  async openSelectCompany() {
    // get companyList
    const companyList = this.companies.reduce((acc, company) => {
      acc[company.id] = company.companyName;
      return acc;
    }, {});
    // show alert
    Swal.fire({
      icon: 'info',
      title: this.translate.instant('COMPANY.companies'),
      text: this.translate.instant('COMPANY.selectCompanyMsg'),
      input: 'select',
      inputOptions: companyList,
      inputPlaceholder: this.translate.instant('COMPANY.selectCompany'),
      confirmButtonText: this.translate.instant('HELPERS.accept'),
      showCancelButton: true,
      cancelButtonText: this.translate.instant('HELPERS.cancel'),
      allowOutsideClick: false,
      customClass: {
        cancelButton: 'btn-swal-cancel'
      },
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value) {
            resolve(null);
          } else {
            resolve(this.translate.instant('ERRORS.mustSelectCompany')); // Mensaje de error cuando no se selecciona una empresa
          }
        });
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        // get companyId
        const companyId: any = result.value;
        // set company
        await this.selectCompany(companyId);
      }
    });
  }
  async selectCompany(companyId: string) {
    // get companyIndex
    const companyIndex = this.companies.findIndex((companySearch: CompanyInterface) => companySearch.id === companyId);
    // get company 
    const company = this.companies[companyIndex];
    // update companyData
    this.companyData = company;
    // update companyData on local storage
    localStorage.setItem('companyData', JSON.stringify(company));
  }
}
