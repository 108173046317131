// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 25/05/2023
// import
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// translate
import { TranslateService } from '@ngx-translate/core';
// component
@Component({
  selector: 'app-company-client-list',
  templateUrl: './company-client-list.component.html',
  styleUrls: ['./company-client-list.component.scss']
})
// class
export class CompanyClientListComponent implements OnInit {
  // variables
  @Input() companies: [] = [];
  @Input() companySelected: string = null;
  @Output() companySelectEvent = new EventEmitter();
  // constructor
  constructor(
    public translate: TranslateService,
  ) { }
  // life cycle
  async ngOnInit() {}
  // actions
  async selectCompany(company: any) {
    // emit data
    this.companySelectEvent.emit(company); // Return data
  }
}
