// author: Alejandro Bermúdez
// company: Think In
// date: 25/05/2023
// tslint:disable: max-line-length
// tslint:disable: no-unused-expression
// import
import { Injectable } from '@angular/core';
// interfaces
import { BreadcrumbInterface } from '../interfaces/breadcrumb.interface';
// variables
declare var $: any;
// injectable
@Injectable({
  providedIn: 'root'
})
// class
export class BreadcrumbsService {
  // variables
  // constructor
  constructor(
  ) { }
  // functions
  async getBreadcrumbs(data: any) {
    // set breadcrumbs
    const breadcrumbs: any[] = [];
    // get stored data company
    const storedDataBreadcrumb: any = localStorage.getItem('dataBreadcrumbs');
    // get data company
    const dataBreadcrumbs: any = JSON.parse(storedDataBreadcrumb);
    // check breadcrumbs
    if (dataBreadcrumbs != null) {
      // get current base url index from breadcrumb
      const homeBreadcrumbIndex: any = dataBreadcrumbs.findIndex((breadcrumb: BreadcrumbInterface) => breadcrumb.baseUrl === '/');
      // check if home breadcrumb is found
      if (homeBreadcrumbIndex == -1) {
        // set breadcrumb
        const breadcrumb: BreadcrumbInterface = {
          title: 'HOME.home',
          baseUrl: '/',
          id: null,
          url: '/',
          current: false
        }
        // push breadcrumb
        breadcrumbs.push(breadcrumb);
      }
      // clear current breadcrumbs
      dataBreadcrumbs.map((breadcrumb: BreadcrumbInterface, index: number) => {
        dataBreadcrumbs[index].current = false;
        breadcrumbs.push(breadcrumb);
      });
      // get current base url index from breadcrumb
      const breadcrumbIndex: any = dataBreadcrumbs.findIndex((breadcrumb: BreadcrumbInterface) => breadcrumb.baseUrl === data.baseUrl);
      // check if breadcrumb base url was found
      if (breadcrumbIndex != -1) {
        // update breadcrumb data
        breadcrumbs[breadcrumbIndex].current = true;
        // delete breadcrumbs ahead current page
        breadcrumbs.length = breadcrumbIndex + 1;
      } else {
        // set breadcrumb
        const breadcrumb: BreadcrumbInterface = {
          title: data.title,
          baseUrl: data.baseUrl,
          id: data.id,
          url: data.url,
          current: true
        }
        // push breadcrumb
        breadcrumbs.push(breadcrumb);
      }
    } else {
      // set breadcrumbHome
      const breadcrumbHome: BreadcrumbInterface = {
        title: 'HOME.home',
        baseUrl: '/',
        id: null,
        url: '/',
        current: true
      }
      // push breadcrumbHome
      breadcrumbs.push(breadcrumbHome);
      // set breadcrumb
      const breadcrumb: BreadcrumbInterface = {
        title: data.title,
        baseUrl: data.baseUrl,
        id: data.id,
        url: data.url,
        current: true
      }
      // push breadcrumb
      breadcrumbs.push(breadcrumb);
    }
    // update breadcrumbs on storage
    localStorage.setItem('dataBreadcrumbs', JSON.stringify(breadcrumbs));
    // return breadcubms
    return breadcrumbs;
  }
}
