// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 25/05/2023
// tslint:disable: max-line-length
// tslint:disable: no-unused-expression
// import
import { Pipe, PipeTransform } from '@angular/core';
// pipe
@Pipe({
  name: 'percentagePipe'
})
// class
export class PercentagePipe implements PipeTransform {
  // transform
  transform(value: number): string {
    return `${Math.round(value * 100)}%`;
  }

}
