// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 25/05/2023
// import
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
// translate
import { TranslateService } from '@ngx-translate/core';
// component
@Component({
  selector: 'app-error-data',
  templateUrl: './error-data.component.html',
  styleUrls: ['./error-data.component.scss']
})
export class ErrorDataComponent implements OnInit {

  // variables
  @Input() msg: any = null;
  @Output() retryEvent: EventEmitter<any>;
  // constructor
  constructor(
    public translate: TranslateService,
  ) { }
  // life cycle
  async ngOnInit() {
  }
  // actions
  retry() {
    this.retryEvent.emit(null);
  }
}
