// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 25/05/2023
// tslint:disable: max-line-length
// tslint:disable: no-unused-expression
// import
import { Component, Input, OnInit } from '@angular/core';
// reducers
import { Store } from '@ngrx/store';
// plugins
import { NgxSpinnerService } from 'ngx-spinner';
// interfaces
import { LoaderInterface } from '../../reducers/interfaces';
// services
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
// component
@Component({
  selector: 'app-loader-inner',
  templateUrl: './loader-inner.component.html',
  styleUrls: ['./loader-inner.component.scss']
})
export class LoaderInnerComponent implements OnInit {
  // variables
  @Input() loadingMsg: string = null;
  // constructor
  constructor(
    private spinner: NgxSpinnerService,
    private loaderState: Store<LoaderInterface>,
    private utilitiesSv: UtilitiesService,
  ) { 
    // update beneficiaries data
    this.loaderState.subscribe((state: any) =>{
      // get loaderData
      const loaderData = state.loaderReducer;
      // check for loader reducer
      if (!(loaderData === 'UPDATELOANSCOUNT' || loaderData === 'NOUPDATELOANSCOUNT' || loaderData === 'UPDATEBATCHESCOUNT' || loaderData === 'NOUPDATEBATCHESCOUNT' || loaderData === 'UPDATEPROFILE' || loaderData === 'NOUPDATEPROFILE') && loaderData !== '@ngrx/store/init') {
        // update loadingMsg
        this.loadingMsg = loaderData;
        // check loaderData
        if (loaderData !== null) {
          this.showSpinner();
        } else {
          this.hideSpinner();
        }
      }
    });
  }
  // life cycle
  async ngOnInit() {}
  // actions
  showSpinner() {
    this.spinner.show();
  }
  hideSpinner() {
    this.spinner.hide();
  }
}
