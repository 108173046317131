// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 03/06/2023
// import
import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges  } from '@angular/core';
// translate
import { TranslateService } from '@ngx-translate/core';
// environment
import { environment } from 'src/environments/environment';
// plugins
import * as moment from 'moment';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
// services
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
// component
@Component({
  selector: 'app-loan-document-upload',
  templateUrl: './loan-document-upload.component.html',
  styleUrls: ['./loan-document-upload.component.scss']
})
// class
export class LoanDocumentUploadComponent implements OnInit, OnChanges {
  // variables
  @Input() fileData: any = null;
  @Output() loaderEvent = new EventEmitter();
  @Output() saveEvent = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();
  // file data
  file: any = null;
  id: string = null;
  parentId: string = null;
  docType: string = null;
  fileId: string = null;
  fileType: string = null;
  fileName: string = null;
  filePath: string = null;
  fileKey: string = null;
  fileDate: string = null;
  title: string = null;
  image: any = null;
  showDropZone: boolean = false;
  // variables
  public files: NgxFileDropEntry[] = [];
  // constructor
  constructor(
    public translate: TranslateService,
    public alertSv: AlertService,
    public apiSv: ApiService,
    public filterSv: FilterService,
    private utilitiesSv: UtilitiesService,
  ) {
  }
  // life cycle
  async ngOnInit() {}
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.fileData) {
      await this.setData();
    }
  }
  // navigation
  async cancel() {
    // emit data
    this.cancelEvent.emit(null);
  }
  // data
  async setData() {
    if (this.fileData !== null) {
      this.image = this.fileData.image;
      this.file = this.fileData.file;
      this.docType = this.fileData.docType;
      this.fileId = this.fileData.fileId;
    }
  }
  // drag and drop
  async droppedFile(files: NgxFileDropEntry[]) {
    // show loader
    this.loaderEvent.emit(this.translate.instant('HELPERS.uploading'));
    // set files
    this.files = files;
    // loop files
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file(async (file: File) => {
          // init fileType
          let fileType = null;
          // check fileType
          if (file.type === 'application/pdf') {
            // set fileType
            fileType = 'pdf';
          } else if (file.type.startsWith('image/')) {
            // set fileType
            fileType = 'image';
          }
          // get uploaded file data
          const fileName = droppedFile.relativePath;
          const fileSize = file.size;
          const fileSizeBytes = this.utilitiesSv.formatBytes(file.size, 0);
          // check file size
          if (fileSize >= environment.maxFileData) {
            // hide loader
            this.loaderEvent.emit(null);
            // show loader
            this.alertSv.showMessage(this.translate.instant('HELPERS.fileTooBig'),'error', this.translate.instant('HELPERS.error'), true);
          } else {
            // check file type
            if (fileType === null) {
              // hide loader
              this.loaderEvent.emit(null);
              // show loader
              this.alertSv.showMessage(this.translate.instant('HELPERS.fileUnsupportedImageOrPDF'),'error', this.translate.instant('HELPERS.error'), true);
            } else {
              // You could upload it like this:
              const documentData = new FormData()
              documentData.append('file', file);
              // send file to api
              const documentFile: any = await this.apiSv.uploadFile(documentData);
              // get file data
              const fileData: any = documentFile.data;
              const fileKey = fileData.fileKey;
              const filePath = fileData.filePath;
              const fileDate =  moment().locale(this.translate.getDefaultLang()).format('DD MMM YYYY hh:mm A');
              // update data
              this.file = file;
              this.fileType = fileType;
              this.fileName = fileName;
              this.filePath = filePath;
              this.fileKey = fileKey;
              this.fileDate = fileDate;
              // set fileSaveData
              const fileSaveData = {
                docType: this.fileData.docType,
                file: this.file,
                fileId: this.fileId,
                fileType: this.fileType,
                fileName: this.fileName,
                filePath: this.filePath,
                fileKey: this.fileKey,
                fileDate: this.fileDate,
                image: this.fileData.image
              }
              // hide loader
              this.loaderEvent.emit(null);
              // emit data
              this.saveEvent.emit(fileSaveData);
            }
          }
        });
      } else {
        // hide loader
        this.loaderEvent.emit(null);
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }
}
