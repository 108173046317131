// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 19/12/2022
// import
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
// translate
import { TranslateService } from '@ngx-translate/core';
// services
import { AuthService } from 'src/app/shared/services/auth.service';
import { EncodingService } from 'src/app/shared/services/encoding.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
// interfaces
import { UserInterface } from 'src/app/shared/interfaces/user.interface';
// environment
import { environment } from 'src/environments/environment';
// component
@Component({
  selector: 'app-pin-code-form',
  templateUrl: './pin-code-form.component.html',
  styleUrls: ['./pin-code-form.component.scss']
})
// class
export class PinCodeFormComponent implements OnInit {
  // variables
  @Input() origin: string = null;
  @Input() userId: string = null;
  @Input() firstName: string = null;
  @Input() email: string = null;
  @Output() loaderEvent = new EventEmitter();
  @Output() continueEvent = new EventEmitter<void>();
  // code data
  unlockTimeNumber: number = 0;
  unlockTimeMessure: string = null;
  unlockTimeMsg: string = null;
  showCode: boolean = false;
  // form data
  unlockFrm: FormGroup;
  // messages data
  formErrorMessages: any = {};
  // help data
  toggleHelp = false;
  // constructor
  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public authSv: AuthService,
    public encodingSv: EncodingService,
    public apiSv: ApiService,
    public filterSv: FilterService,
    public alertSv: AlertService,
  ) { 
    // code form
    this.unlockFrm = this.formBuilder.group({
      pincode: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
    });
    // unlock data
    this.unlockTimeNumber = environment.unlockTimeNumber;
    this.unlockTimeMessure = environment.unlockTimeMessure;
    this.unlockTimeMsg = this.unlockTimeNumber + ' ' + this.translate.instant('HELPERS.' + environment.unlockTimeMessure);
  }
  // life cycle
  async ngOnInit() {}
   // translate
   async translateMsgs () {
    this.formErrorMessages = {
      pincode: [
        { type: 'required', message: this.translate.instant('UNLOCK.required') },
        { type: 'minLength', message: this.translate.instant('UNLOCK.minLength') },
        { type: 'maxLength', message: this.translate.instant('UNLOCK.maxLength') },
      ],
    };
  }
  // form
  async togleCode() {
    this.showCode = !this.showCode;
  }
  async setFocus(elementId: any) {
    elementId.focus();
  }
  // actions
  async saveCode() {
    try {
      // validate data
      if (!this.unlockFrm.valid) {
        // send alert
        this.alertSv.showMessage(this.translate.instant('UNLOCK.required'),'error', this.translate.instant('HELPERS.error'), true);
      } else {
        // show loader
        this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
        // get userData
        const userData: UserInterface = {
          pincode: this.unlockFrm.controls.pincode.value,
        };
        // update user
        await this.apiSv.updateUser(this.userId, userData);
        // hide loader
        this.loaderEvent.emit(null);
        // set pinCodeData
        const pinCodeData: any = {
          codeStatus: true,
        }
        // emit data
        this.continueEvent.emit(pinCodeData);
      }
    } catch (error) {
      console.log('error', error);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async cancelCode() {
    // set pinCodeData
    const pinCodeData: any = {
      codeStatus: false,
    }
    // emit data
    this.continueEvent.emit(pinCodeData);
  }
}
