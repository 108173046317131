// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 25/05/2023
// import
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// translate
import { TranslateService } from '@ngx-translate/core';
// interfaces
import { ProductInterface } from 'src/app/shared/interfaces/product.interface';
// component
@Component({
  selector: 'app-product-client-card',
  templateUrl: './product-client-card.component.html',
  styleUrls: ['./product-client-card.component.scss']
})
// class
export class ProductClientCardComponent implements OnInit {
  // variables
  @Input() products: [] = [];
  @Input() productSelected: string = null;
  @Output() productSelectEvent = new EventEmitter();
  // constructor
  constructor(
    public translate: TranslateService,
  ) {}
  // life cycle
  async ngOnInit() {
  }
  // actions
  async selectProduct(product: any) {
    // emit data
    this.productSelectEvent.emit(product); // Return data
  }
}
