// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 03/06/2023
// import
import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges  } from '@angular/core';
// translate
import { TranslateService } from '@ngx-translate/core';
// services
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
// interfaces
import { UserWorkInterface } from 'src/app/shared/interfaces/user-work.interface';
// component
@Component({
  selector: 'app-user-work-card',
  templateUrl: './user-work-card.component.html',
  styleUrls: ['./user-work-card.component.scss']
})
// class
export class UserWorkCardComponent implements OnInit, OnChanges  {
  // variables
  @Input() userWorkData: UserWorkInterface = null;
  @Input() loanId: string = null;
  @Input() editEnable: boolean = false;
  @Input() fullData: boolean = false;
  @Input() fileEditEnable: boolean = false;
  @Input() laborFileData: any = null;
  @Input() paymentReceiptFilesData: any[] = [];
  @Input() rutFileData: any = null;
  @Input() ccioFileData: any = null;
  @Input() bankExtractsFileData: any = null;
  @Input() pgFileData: any = null;
  @Input() utilitiesFileData: any = null;
  @Input() pensionerFileData: any = null;
  @Output() loaderEvent = new EventEmitter();
  @Output() editEvent = new EventEmitter();
  @Output() fileActionEvent = new EventEmitter();
  // lang data
  currentLang: string = null;
  // user data
  userId: string = null;
  professionName: string = null;
  // user work data
  workType: string = null;
  positionName: string = null;
  occupationName: string = null;
  contractType: string = null;
  contractSalary: number = 0;
  contractStart: string = null;
  contractEnd: string = null;
  // file data
  laborFile: any = null;
  paymentReceiptFiles: any[] = [];
  rutFile: any = null;
  ccioFile: any = null;
  bankExtractsFile: any = null;
  pgFile: any = null;
  utilitiesFile: any = null;
  pensionerFile: any = null;
  // constructor
  constructor(
    public translate: TranslateService,
    public apiSv: ApiService,
    public filterSv: FilterService,
    public alertSv: AlertService,
  ) {
    // get currentLang
    const currentLang = this.translate.getDefaultLang();
    // set currentLang
    this.currentLang = currentLang;
  }
  // life cycle
  async ngOnInit() {
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.userWorkData) {
      await this.setData();
    }
    if (changes.laborFileData) {
      console.log('this.laborFileData', this.laborFileData);
      await this.setLaborFileData();
    }
    if (changes.paymentReceiptFilesData) {
      console.log('this.paymentReceiptFilesData', this.paymentReceiptFilesData);
      await this.setPaymentReceiptsFileData();
    }
    if (changes.rutFileData) {
      console.log('this.rutFileData', this.rutFileData);
      await this.setRutFileData();
    }
    if (changes.ccioFileData) {
      console.log('this.ccioFileData', this.ccioFileData);
      await this.setCCIOFileData();
    }
    if (changes.bankExtractsFileData) {
      console.log('this.bankExtractsFileData', this.bankExtractsFileData);
      await this.setBankExtractsFileData();
    }
    if (changes.pgFileData) {
      console.log('this.pgFileData', this.pgFileData);
      await this.setPGFileData();
    }
    if (changes.utilitiesFileData) {
      console.log('this.utilitiesFileData', this.utilitiesFileData);
      await this.setUtilitiesFileData();
    }
    if (changes.pensionerFileData) {
      console.log('this.pensionerFileData', this.pensionerFileData);
      await this.setPensionerFileData();
    }
  }
  // loader
  async updateLoader(loaderMsg: any) {
    this.loaderEvent.emit(loaderMsg);
  }
  // data
  async setData() {
    try {

      console.log('this.userWorkData', this.userWorkData);

      // check userWorkData
      if (this.userWorkData) {
        // user data
        this.userId = this.userWorkData.userId;
        if (this.userWorkData.professionName) {
          this.professionName = this.userWorkData.professionName;
        }
        // user work data
        this.workType = this.userWorkData.workType;
        this.positionName = this.userWorkData.positionName;
        this.occupationName = this.userWorkData.occupationName;
        this.contractType = this.userWorkData.contractType;
        this.contractSalary = this.userWorkData.contractSalary;
        this.contractStart = this.userWorkData.contractStart;
        this.contractEnd = this.userWorkData.contractEnd;
      }
    } catch (error) {
      console.log('error', error);
    }
  }
  async setLaborFileData() {
    this.laborFile = this.laborFileData;
  }
  async setPaymentReceiptsFileData() {
    this.paymentReceiptFiles = this.paymentReceiptFilesData;
  }
  async setRutFileData() {
    this.rutFile = this.rutFileData;
  }
  async setCCIOFileData() {
    this.ccioFile = this.ccioFileData;
  }
  async setBankExtractsFileData() {
    this.bankExtractsFile = this.bankExtractsFileData;
  }
  async setPGFileData() {
    this.pgFile = this.pgFileData;
  }
  async setUtilitiesFileData() {
    this.utilitiesFile = this.utilitiesFileData;
  }
  async setPensionerFileData() {
    this.pensionerFile = this.pensionerFileData;
  }
  // actions
  async edit() {
    // emit data
    this.editEvent.emit(this.userWorkData);
  }
  // files
  async fileAction(action: any) {
    this.fileActionEvent.emit(action);
  }

}
