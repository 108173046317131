// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 03/06/2023
// import
import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
// translate
import { TranslateService } from '@ngx-translate/core';
// plugins
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// services
import { AuthService } from 'src/app/shared/services/auth.service';
import { EncodingService } from 'src/app/shared/services/encoding.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
// interfaces
import { UserBankAccountInterface } from 'src/app/shared/interfaces/user-bank-account.interface';
import { UserInterface } from 'src/app/shared/interfaces/user.interface';
// component
@Component({
  selector: 'app-user-bank-accounts',
  templateUrl: './user-bank-accounts.component.html',
  styleUrls: ['./user-bank-accounts.component.scss']
})
// class
export class UserBankAccountsComponent implements OnInit, OnChanges {
  // variables
  @Input() userData: UserInterface = null;
  @Input() userBankAccounts: any[] = [];
  @Input() addEnable: boolean = false;
  @Input() editEnable: boolean = false;
  @Input() showFiles: boolean = false;
  @Input() fileEditEnable: boolean = false;
  @Input() origin: string = null;
  @Output() loaderEvent = new EventEmitter();
  @Output() updateEvent = new EventEmitter();
  @Output() fileActionEvent = new EventEmitter();
  // variables
  userBankAccountSelected: UserBankAccountInterface = null;
  banksCounter: number = 0;
  userBankAccountMainSelected: boolean = false;
  userBankAccountMainId: string = null;
  userBankTempId: number = 0;
  formType: string = null;
  // constructor
  constructor(
    public translate: TranslateService,
    public authSv: AuthService,
    public encodingSv: EncodingService,
    public apiSv: ApiService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    private cdRef: ChangeDetectorRef,
    private modalService: NgbModal,
  ) {
  }
  // life cycle
  async ngOnInit() {}
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.userData) {
      await this.setData();
    }
    if (changes.userBankAccounts) {
      await this.setData();
    }
  }
  // loader
  async updateLoader(loaderMsg: any) {
    this.loaderEvent.emit(loaderMsg);
  }
  // data
  async setData() {
    // loop userBankAccounts
    this.userBankAccounts.map((userBankAccount: UserBankAccountInterface, index) => {
      // get user bank account number
      const userBankAccountNumber = userBankAccount.userBankAccountNumber;
      const userBankAccountNumberEncoded = userBankAccountNumber;
      const userBankAccountNumberDecoded = this.encodingSv.decodeCrypto(userBankAccount.userBankAccountNumber);
      // update user bank account number
      this.userBankAccounts[index].userBankAccountNumberEncoded = userBankAccountNumberEncoded;
      this.userBankAccounts[index].userBankAccountNumberDecoded = userBankAccountNumberDecoded;
      // check for userBankAccountActive
      if (userBankAccount.userBankAccountActive) {
        // update userBankAccountMain data
        this.userBankAccountMainSelected = true;
        this.userBankAccountMainId = userBankAccount.id;
      }
    });
  }
  // actions
  async addUserBankAccountForm(modal: any) {
    // set counter
    this.banksCounter =  this.userBankAccounts.length;
    // open modal to select reject option
    this.modalService.open(modal);
    // formType
    this.formType = 'add';
  }
  async editUserBankAccount(modal: any, userBankAccount: UserBankAccountInterface) {
    // set counter
    this.banksCounter =  this.userBankAccounts.length;
    // set userBankAccountSelected
    this.userBankAccountSelected = userBankAccount;
    // open modal to select reject option
    this.modalService.open(modal);
    // formType
    this.formType = 'edit';
  }
  async closeUserBankAccountForm() {
    // clear userBankAccountSelected
    this.userBankAccountSelected = null;
    // hide modal
    this.modalService.dismissAll();
  }
  async checkUserBankAccountActive(userBankAccount: UserBankAccountInterface) {
    // init foundSelectedUserBankAccount
    let foundSelectedUserBankAccount: boolean = false;
    // check mailingAddress is been seted before
    if (this.userBankAccountMainId != null && userBankAccount.userBankAccountActive && this.userBankAccountMainId !== userBankAccount.id) {
      foundSelectedUserBankAccount = true;
    } else {
      foundSelectedUserBankAccount = false;
    }
    // return
    return foundSelectedUserBankAccount;
  }
  async saveAddUserBankAccount(userBankAccount: UserBankAccountInterface) {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // set userBankTempId
      this.userBankTempId = this.userBankTempId + 1;
      // set addressId 
      userBankAccount.id = 'bank-' + (this.userBankTempId + 1).toString();
      // get userBankAccountActive
      const userBankAccountMain = await this.checkUserBankAccountActive(userBankAccount);
      // check userBankAccountMain
      if (userBankAccountMain) {
        // hide loader
        this.loaderEvent.emit(null);
        // show alert to get new profession
        Swal.fire({
          icon: 'warning',
          title: this.translate.instant('USERBANKACCOUNTS.activeBank'),
          text: this.translate.instant('USERBANKACCOUNTS.activeBankFound'),
          confirmButtonText: this.translate.instant('HELPERS.yes'),
          showCancelButton: true,
          cancelButtonText: this.translate.instant('HELPERS.no'), 
          customClass: {
            cancelButton: 'btn-swal-cancel'
          },
          allowOutsideClick: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
            // show loader
            this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
            // update previous user bank active account
            await this.updateUserBankAccountStatus(this.userBankAccountMainId, false);
            // add user bank account data
            await this.addUserBankAccountData(userBankAccount);
            // hide loader
            this.loaderEvent.emit(null);
            // hide modal
            this.modalService.dismissAll();
            // emit data
            this.updateEvent.emit(this.userBankAccounts);
          } else {
            // show loader
            this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
            // set userBankAccountActive to false
            userBankAccount.userBankAccountActive = false;
            // add user bank account data
            await this.addUserBankAccountData(userBankAccount);
            // hide loader
            this.loaderEvent.emit(null);
            // hide modal
            this.modalService.dismissAll();
            // emit data
            this.updateEvent.emit(this.userBankAccounts);
          }
        });
      } else {
        // add user bank account data
        await this.addUserBankAccountData(userBankAccount);
        // hide loader
        this.loaderEvent.emit(null);
        // hide modal
        this.modalService.dismissAll();
        // emit data
        this.updateEvent.emit(this.userBankAccounts);
      }
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async addUserBankAccountData(userBankAccount: UserBankAccountInterface) {
    try {
      // set userBankAccountData
      const userBankAccountData: UserBankAccountInterface = {
        userId: this.userData.id,
        bankId: userBankAccount.bankId,
        userBankAccountType: userBankAccount.userBankAccountType,
        userBankAccountNumber: userBankAccount.userBankAccountNumberEncoded,
        userBankAccountActive: userBankAccount.userBankAccountActive,
      }
      // save bank account on API
      const auxUserBankAccount: any = await this.apiSv.addUserBankAccount(userBankAccountData);
      // get userBankAccountId
      const userBankAccountId = auxUserBankAccount.data.id;
      // add userBankAccountId
      userBankAccountData.id = userBankAccountId;
      // check userBankAccountActive
      if (userBankAccount.userBankAccountActive) {
        // update userBankAccountMain data
        this.userBankAccountMainSelected = true;
        this.userBankAccountMainId = userBankAccountId;
      }
      // update userBankAccounts
      this.userBankAccounts.push(userBankAccount);
      // clear userBankAccountSelected
      this.userBankAccountSelected = null;
      // detectChanges
      this.cdRef.detectChanges();
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async saveUpdateUserBankAccount(userBankAccount: UserBankAccountInterface) {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // get userBankAccountActive
      const userBankAccountMain = await this.checkUserBankAccountActive(userBankAccount);
      // check userBankAccountMain
      if (userBankAccountMain) {
        // hide loader
        this.loaderEvent.emit(null);
        // show alert to get new profession
        Swal.fire({
          icon: 'warning',
          title: this.translate.instant('USERBANKACCOUNTS.activeBank'),
          text: this.translate.instant('USERBANKACCOUNTS.activeBankFound'),
          confirmButtonText: this.translate.instant('HELPERS.yes'),
          showCancelButton: true,
          cancelButtonText: this.translate.instant('HELPERS.no'), 
          customClass: {
            cancelButton: 'btn-swal-cancel'
          },
          allowOutsideClick: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
            // show loader
            this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
            // update previous user bank active account
            await this.updateUserBankAccountStatus(this.userBankAccountMainId, false);
            // update user bank account data
            await this.updateUserBankAccountData(userBankAccount);
            // hide loader
            this.loaderEvent.emit(null);
            // hide modal
            this.modalService.dismissAll();
            // detectChanges
            this.cdRef.detectChanges();
            // emit data
            this.updateEvent.emit(this.userBankAccounts);
          } else {
            // show loader
            this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
            // set userBankAccountActive to false
            userBankAccount.userBankAccountActive = false;
            // update user bank account data
            await this.updateUserBankAccountData(userBankAccount);
            // hide loader
            this.loaderEvent.emit(null);
            // hide modal
            this.modalService.dismissAll();
            // detectChanges
            this.cdRef.detectChanges();
            // emit data
            this.updateEvent.emit(this.userBankAccounts);
          }
        });
      } else {
        // show loader
        this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
        // update user bank account data
        await this.updateUserBankAccountData(userBankAccount);
        // hide loader
        this.loaderEvent.emit(null);
        // hide modal
        this.modalService.dismissAll();
        // detectChanges
        this.cdRef.detectChanges();
        // emit data
        this.updateEvent.emit(this.userBankAccounts);
      }
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async updateUserBankAccountData(userBankAccount: UserBankAccountInterface) {
    try {
      // set userBankAccountData
      const userBankAccountData: UserBankAccountInterface = {
        userId: userBankAccount.userId,
        bankId: userBankAccount.bankId,
        userBankAccountType: userBankAccount.userBankAccountType,
        userBankAccountNumber: userBankAccount.userBankAccountNumberEncoded,
        userBankAccountActive: userBankAccount.userBankAccountActive,
      }
      // save bank account on API
      await this.apiSv.updateUserBankAccount(userBankAccount.id, userBankAccountData);
      // get userBankAccountIndex
      const userBankAccountIndex = this.userBankAccounts.findIndex((userBankAccountSearch: UserBankAccountInterface) => userBankAccountSearch.id === userBankAccount.id);
      // update userBankAccounts
      this.userBankAccounts[userBankAccountIndex] = userBankAccount;
      // check userBankAccountActive
      if (userBankAccount.userBankAccountActive) {
        // update userBankAccountMain data
        this.userBankAccountMainSelected = true;
        this.userBankAccountMainId = userBankAccount.id;
      }
      // clear userBankAccountSelected
      this.userBankAccountSelected = null;
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async updateUserBankAccountStatus(userBankAccountId: string, userBankAccountActive: boolean) {
    try {
      // set userBankAccountData
      const userBankAccountData: UserBankAccountInterface = {
        userBankAccountActive: userBankAccountActive,
      }
      // save bank account on API
      await this.apiSv.updateUserBankAccount(userBankAccountId, userBankAccountData);
      // get userBankAccountIndex
      const userBankAccountIndex = this.userBankAccounts.findIndex((userBankAccount: UserBankAccountInterface) => userBankAccount.id === userBankAccountId);
      // update userBankAccount
      this.userBankAccounts[userBankAccountIndex].userBankAccountActive = userBankAccountActive;
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async fileAction(fileAction: any) {
    // get data
    const data = fileAction.data;
    // get userBankAccountIndex
    const userBankAccountIndex = this.userBankAccounts.findIndex((userBankAccount: UserBankAccountInterface) => userBankAccount.id === data.id);
    // update userBankAccount
    this.userBankAccounts[userBankAccountIndex].certificateFileData = data;
    // emit file
    this.fileActionEvent.emit(fileAction);
  }
}
