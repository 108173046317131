<!-- form -->
<form class="theme-form" [formGroup]="mainFrm">
  <!-- header -->
  <div class="row">
    <div class="col">
      <h4>{{ 'LOGIN.signIn' | translate }}</h4>
      <p>{{ 'LOGIN.enterData' | translate }}</p>
    </div>
  </div>
  <!-- data -->
  <div class="row">
    <div class="col">
      <!-- email -->
      <div class="form-group">
        <label class="col-form-label">{{ 'LOGIN.email.placeHolder' | translate }}</label>
        <input class="form-control" #email id="email" type="email" inputmode="email"  [placeholder]="'USER.formPlaceHolders.email' | translate" formControlName="email" (change)="checkEmail()" (keyup.enter)="setFocus(password)">
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.email">
          <small class="text-danger" *ngIf="mainFrm.get('email').hasError(error.type) && (mainFrm.get('email').dirty || mainFrm.get('email').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <ng-container class="error-messages" *ngIf="emailExists === 'not_found'">
          {{ 'ERRORS.email_not_exists' | translate }}
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'USER.formHelp.email' | translate }}</small>
        </p>
      </div>
      <!-- password -->
      <div class="form-group">
        <label class="col-form-label">{{ 'LOGIN.password.placeHolder' | translate }}</label>
        <input class="form-control" #password id="password" [type]="showPwd ? 'text' : 'password'" formControlName="password"  placeholder="*********"  (keyup.enter)="save()">
        <div class="show-hide" (click)="togglePwd()">
          <app-feather-icons *ngIf="!showPwd" [icon]="'eye'"></app-feather-icons>
          <app-feather-icons *ngIf="showPwd"  [icon]="'eye-off'"></app-feather-icons>
        </div>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.password">
          <small class="text-danger" *ngIf="mainFrm.get('password').hasError(error.type) && (mainFrm.get('password').dirty || mainFrm.get('password').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          {{ 'USER.formHelp.passwordLogin' | translate }}
        </p>
      </div>
    </div>
  </div>
  <!-- remember -->
  <div class="row">
    <div class="col">
      <div class="form-group mt-2 mb-4">
        <label class="d-block" for="remember">
          <input class="checkbox_animated" #remember id="remember" type="checkbox" formControlName="remember" >
          <span class="checkbox-txt">{{ 'LOGIN.rememberAccount' | translate }}</span>
        </label>
      </div>
    </div>
  </div>
  <!-- login -->
  <div class="row">
    <div class="col">
      <button type="button" [disabled]="showLoader" (click)="save()" class="btn btn-primary d-block btn-login w-100" [class.loader--text]="showLoader" [ngClass]="{'en': currentLang === 'en', 'es': currentLang === 'es'}">
        <span>{{ showLoader ? '' : 'LOGIN.login' | translate }}</span>
      </button>
    </div>
  </div>
  <!-- links -->
  <div class="row">
    <div class="col">
      <!-- forgot -->
      <p class="mt-4 mb-0 text-center form-link">
        <a routerLink="/auth/forgot-password" routerLinkActive="router-link-active" class="btn-link text-center">{{ 'LOGIN.forgot' | translate }}</a>
      </p>
      <!-- sign up -->
      <p *ngIf="false" class="mt-3 mb-0 text-center form-link">
        {{ 'LOGIN.dontHaveAccount' | translate }}
        <a routerLink="/auth/register" routerLinkActive="router-link-active" class="ms-1">{{ 'LOGIN.register' | translate }}</a>
      </p>
    </div>
  </div>
</form>
          