<!-- header -->
<div class="row">
  <div class="col-12">
    <h4>{{ 'CHANGEPASS.changePwd' | translate }}</h4>
  </div>
</div>
<form [formGroup]="mainFrm">
  <div class="row">
    <!-- password -->
    <div class="col-12">
      <div class="form-group">
        <label for="password">{{ 'USER.formLabels.passwordNew' | translate }}</label>
        <input class="form-control" #password id="password" [type]="showPwd ? 'text' : 'password'" inputmode="text" placeholder="*********" formControlName="password" (keyup.enter)="setFocus(confirmPwd)">
        <div class="show-hide" (click)="togglePwd()">
          <app-feather-icons *ngIf="!showPwd" [icon]="'eye'"></app-feather-icons>
          <app-feather-icons *ngIf="showPwd"  [icon]="'eye-off'"></app-feather-icons>
        </div>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.password">
          <small class="text-danger" *ngIf="mainFrm.get('password').hasError(error.type) && (mainFrm.get('password').dirty || mainFrm.get('password').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'USER.formHelp.passwordLogin' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- confirmPwd -->
    <div class="col-12">
      <div class="form-group">
        <label for="confirmPwd">{{ 'USER.formLabels.confirmPwd' | translate }}</label>
        <input class="form-control" #confirmPwd id="confirmPwd" [type]="showConfirmPwd ? 'text' : 'password'" inputmode="text" placeholder="*********" formControlName="confirmPwd">
        <div class="show-hide" (click)="toggleConfirmPwd()">
          <app-feather-icons *ngIf="!showConfirmPwd" [icon]="'eye'"></app-feather-icons>
          <app-feather-icons *ngIf="showConfirmPwd"  [icon]="'eye-off'"></app-feather-icons>
        </div>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.confirmPwd">
          <small class="text-danger" *ngIf="mainFrm.get('confirmPwd').hasError(error.type) && (mainFrm.get('confirmPwd').dirty || mainFrm.get('confirmPwd').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'USER.formHelp.confirmPwd' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- password mismatch -->
    <div class="col-12 password-mismatch" *ngIf="passwordMismatch">
      <ng-container class="error-messages">
        <small class="text-danger">
          {{ 'USER.formValidation.password.match' | translate }}
        </small>
      </ng-container>
    </div>
  </div>
</form>
<!-- buttons -->
<div class="row">
  <div class="col mt-4 text-center">
    <button *ngIf="origin !== 'profile'"  type="button" (click)="save()" class="btn btn-primary btn-block">{{ 'HELPERS.save' | translate }}</button>
    <button *ngIf="origin === 'profile'" type="button" (click)="save()" class="btn btn-primary btn-block">{{ 'CHANGEPASS.change' | translate }}</button>
    <button *ngIf="origin === 'profile'" type="button" (click)="cancelCode()" class="btn btn-tertiary btn-block mt-3">{{ 'HELPERS.cancel' | translate }}</button>
  </div>
</div>
