<div class="card mb-4">
  <div class="card-body">
    <!-- legal -->
    <div class="row mt-4" *ngIf="showLegal">
      <!-- content -->
      <div class="col-12" *ngIf="!enableEditLegal">
        <!-- header -->
        <div class="row mb-4">
          <!-- title -->
          <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
            <h4>{{ 'COMPANY.companyLegalInfo' | translate }}</h4>
          </div>
          <!-- edit -->
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 pull-right">
            <button class="btn btn-primary btn-xs btn-icon-only edit-form-btn" (click)="editLegalData()">
              <app-feather-icons [icon]="'edit-3'"></app-feather-icons>
            </button>
          </div>
        </div>
        <!-- termsConditions -->
        <div class="card small-widget mb-3">
          <!-- body -->
          <div class="card-body legal primary">
            <span class="widget-title">{{ 'COMPANY.formLabels.termsConditions' | translate }}</span>
            <div class="d-block align-items-end gap-1">
              <p [innerHTML]="termsConditions"></p>
            </div>
            <div class="bg-gradient">
              <app-feather-icons class="svg-feather" [icon]="'feather'"></app-feather-icons>
            </div>
          </div>
        </div>
        <!-- privacyPolicy -->
        <div class="card small-widget mb-3">
          <div class="card-body legal primary">
            <span class="widget-title">{{ 'COMPANY.formLabels.privacyPolicy' | translate }}</span>
            <div class="d-block align-items-end gap-1">
              <p [innerHTML]="privacyPolicy"></p>
            </div>
            <div class="bg-gradient">
              <app-feather-icons class="svg-feather" [icon]="'shield'"></app-feather-icons>
            </div>
          </div>
        </div>
      </div>
      <!-- edit -->
      <div class="col-12" *ngIf="enableEditLegal">
        <div class="card small-widget mb-3">
          <div class="card-body ">
            <!-- form -->
            <form class="theme-form" [formGroup]="legalFrm">
              <!-- header -->
              <div class="row">
                <!-- title -->
                <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                  <h4>{{ 'HELPERS.edit' | translate }}&nbsp;{{ 'COMPANY.companyLegal' | translate }}</h4>
                </div>
                <!-- help -->
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 pull-right">
                  <div class="btn-with-outside-txt-lbl help-btn" (click)="help()">
                    <small class="btn-inner-txt">{{ 'HELPERS.help' | translate }}</small> 
                    <a class="btn btn-light btn-xs btn-icon-only btn-icon-text-left">
                      <app-feather-icons [icon]="'help-circle'"></app-feather-icons>
                    </a>
                  </div>
                </div>
              </div>
              <!-- data -->
              <div class="row mt-4">
                <!-- termsConditions -->
                <div class="col-12">
                  <div class="form-group">
                    <label for="termsConditionsInput">{{ 'COMPANY.formLabels.termsConditions' | translate }} <span class="required-lbl">*</span></label>
                    <angular-editor id="termsConditionsInput" #termsConditionsInput name="termsConditionsInput" formControlName="termsConditions" [config]="editorConfig"></angular-editor>
                    <ng-container class="error-messages" *ngFor="let error of formErrorMessages.termsConditions">
                      <small class="text-danger error-msg" *ngIf="legalFrm.get('termsConditions').hasError(error.type) && (legalFrm.get('termsConditions').dirty || legalFrm.get('termsConditions').touched)">
                        {{ error.message }}.&nbsp;
                      </small>
                    </ng-container>
                    <p class="help-messages" *ngIf="toggleHelp">
                      <small>{{ 'COMPANY.formHelp.termsConditions' | translate }}</small>
                    </p>
                  </div>
                </div>
                <!-- privacyPolicy -->
                <div class="col-12">
                  <div class="form-group">
                    <label for="privacyPolicyInput">{{ 'COMPANY.formLabels.privacyPolicy' | translate }} <span class="required-lbl">*</span></label>
                    <angular-editor id="privacyPolicyInput" #privacyPolicyInput name="privacyPolicyInput" formControlName="privacyPolicy" [config]="editorConfig"></angular-editor>
                    <ng-container class="error-messages" *ngFor="let error of formErrorMessages.privacyPolicy">
                      <small class="text-danger error-msg" *ngIf="legalFrm.get('privacyPolicy').hasError(error.type) && (legalFrm.get('privacyPolicy').dirty || legalFrm.get('privacyPolicy').touched)">
                        {{ error.message }}.&nbsp;
                      </small>
                    </ng-container>
                    <p class="help-messages" *ngIf="toggleHelp">
                      <small>{{ 'COMPANY.formHelp.privacyPolicy' | translate }}</small>
                    </p>
                  </div>
                </div>
              </div>
              <!-- buttons -->
              <div class="row mt-4">
                <div class="col">
                  <div class="request-form-btns-container">
                    <button class="btn btn-light d-block btn-cancel" (click)="cancelEditLegalData()" type="button">
                      <span>{{ 'HELPERS.cancel' | translate }}</span>
                    </button>
                    <button class="btn btn-dark d-block" (click)="checkEditLegalData()" type="button">
                      <span>{{ 'HELPERS.save' | translate }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- loan legal -->
    <div class="row mt-4" *ngIf="showLoanLegal">
      <!-- content -->
      <div class="col-12" *ngIf="!enableEditLoanLegal">
        <!-- header -->
        <div class="row mb-4">
          <!-- title -->
          <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12">
            <h4>{{ 'COMPANY.companyLoanLegalInfo' | translate }}</h4>
          </div>
          <!-- edit -->
          <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 pull-right">
            <button class="btn btn-primary btn-xs btn-icon-only edit-form-btn" (click)="editLegalData()">
              <app-feather-icons [icon]="'edit-3'"></app-feather-icons>
            </button>
          </div>
        </div>
        <!-- loanTermsConditions -->
        <div class="card small-widget mb-3">
          <div class="card-body legal primary">
            <span class="widget-title">{{ 'COMPANY.formLabels.loanTermsConditions' | translate }}</span>
            <div class="d-block align-items-end gap-1">
              <p [innerHTML]="loanTermsConditions"></p>
            </div>
            <div class="bg-gradient">
              <app-feather-icons class="svg-feather" [icon]="'feather'"></app-feather-icons>
            </div>
          </div>
        </div>
        <!-- loanPrivacyPolicy -->
        <div class="card small-widget mb-3">
          <div class="card-body legal primary">
            <span class="widget-title">{{ 'COMPANY.formLabels.loanPrivacyPolicy' | translate }}</span>
            <div class="d-block align-items-end gap-1">
              <p [innerHTML]="loanPrivacyPolicy"></p>
            </div>
            <div class="bg-gradient">
              <app-feather-icons class="svg-feather" [icon]="'shield'"></app-feather-icons>
            </div>
          </div>
        </div>
      </div>
      <!-- edit -->
      <div class="col-12" *ngIf="enableEditLoanLegal">
        <div class="card small-widget mb-3">
          <div class="card-body ">
            <!-- form -->
            <form class="theme-form" [formGroup]="loanLegalFrm">
              <!-- header -->
              <div class="row">
                <!-- title -->
                <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                  <h4>{{ 'HELPERS.edit' | translate }}&nbsp;{{ 'COMPANY.companyLoanLegal' | translate }}</h4>
                </div>
                <!-- help -->
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 pull-right">
                  <div class="btn-with-outside-txt-lbl help-btn" (click)="help()">
                    <small class="btn-inner-txt">{{ 'HELPERS.help' | translate }}</small> 
                    <a class="btn btn-light btn-xs btn-icon-only btn-icon-text-left">
                      <app-feather-icons [icon]="'help-circle'"></app-feather-icons>
                    </a>
                  </div>
                </div>
              </div>
              <!-- data -->
              <div class="row mt-4">
                <!-- loanTermsConditions -->
                <div class="col-12">
                  <div class="form-group">
                    <label for="loanTermsConditionsInput">{{ 'COMPANY.formLabels.loanTermsConditions' | translate }} <span class="required-lbl">*</span></label>
                    <angular-editor id="loanTermsConditionsInput" #loanTermsConditionsInput name="loanTermsConditionsInput" formControlName="loanTermsConditions" [config]="editorConfig"></angular-editor>
                    <ng-container class="error-messages" *ngFor="let error of formErrorMessages.loanTermsConditions">
                      <small class="text-danger error-msg" *ngIf="loanLegalFrm.get('loanTermsConditions').hasError(error.type) && (loanLegalFrm.get('loanTermsConditions').dirty || loanLegalFrm.get('loanTermsConditions').touched)">
                        {{ error.message }}.&nbsp;
                      </small>
                    </ng-container>
                    <p class="help-messages" *ngIf="toggleHelp">
                      <small>{{ 'COMPANY.formHelp.loanTermsConditions' | translate }}</small>
                    </p>
                  </div>
                </div>
                <!-- loanPrivacyPolicy -->
                <div class="col-12">
                  <div class="form-group">
                    <label for="loanPrivacyPolicyInput">{{ 'COMPANY.formLabels.loanPrivacyPolicy' | translate }} <span class="required-lbl">*</span></label>
                    <angular-editor id="loanPrivacyPolicyInput" #loanPrivacyPolicyInput name="loanPrivacyPolicyInput" formControlName="loanPrivacyPolicy" [config]="editorConfig"></angular-editor>
                    <ng-container class="error-messages" *ngFor="let error of formErrorMessages.loanPrivacyPolicy">
                      <small class="text-danger error-msg" *ngIf="loanLegalFrm.get('loanPrivacyPolicy').hasError(error.type) && (loanLegalFrm.get('loanPrivacyPolicy').dirty || loanLegalFrm.get('loanPrivacyPolicy').touched)">
                        {{ error.message }}.&nbsp;
                      </small>
                    </ng-container>
                    <p class="help-messages" *ngIf="toggleHelp">
                      <small>{{ 'COMPANY.formHelp.loanPrivacyPolicy' | translate }}</small>
                    </p>
                  </div>
                </div>
              </div>
              <!-- buttons -->
              <div class="row mt-4">
                <div class="col">
                  <div class="request-form-btns-container">
                    <button class="btn btn-light d-block btn-cancel" (click)="cancelEditLoanLegalData()" type="button">
                      <span>{{ 'HELPERS.cancel' | translate }}</span>
                    </button>
                    <button class="btn btn-dark d-block" (click)="checkEditLoanLegalData()" type="button">
                      <span>{{ 'HELPERS.save' | translate }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- loan instructions -->
    <div class="row mt-4" *ngIf="showLoanInstructions">
      <!-- content -->
      <div class="col-12" *ngIf="!enableEditLoanInstructions">
        <!-- header -->
        <div class="row mb-4">
          <!-- title -->
          <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12">
            <h4>{{ 'COMPANY.companyLoanLegalInstructionsInfo' | translate }}</h4>
          </div>
          <!-- edit -->
          <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 pull-right">
            <button class="btn btn-primary btn-xs btn-icon-only edit-form-btn" (click)="editLoanInstructionsData()">
              <app-feather-icons [icon]="'edit-3'"></app-feather-icons>
            </button>
          </div>
        </div>
        <!-- content -->
        <div class="card small-widget mb-3">
          <div class="card-body legal primary">
            <span class="widget-title">{{ 'COMPANY.formLabels.companyLoanInstructions' | translate }}</span>
            <div class="d-block align-items-end gap-1">
              <p [innerHTML]="companyLoanInstructions"></p>
            </div>
            <div class="bg-gradient">
              <app-feather-icons class="svg-feather" [icon]="'file-text'"></app-feather-icons>
            </div>
          </div>
        </div>
      </div>
      <!-- edit -->
      <div class="col-12" *ngIf="enableEditLoanInstructions">
        <div class="card small-widget mb-3">
          <div class="card-body ">
            <!-- form -->
            <form class="theme-form" [formGroup]="companyLoanInstructionsFrm">
              <!-- header -->
              <div class="row">
                <!-- title -->
                <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                  <h4>{{ 'HELPERS.edit' | translate }}&nbsp;{{ 'COMPANY.companyLoanLegalInstructions' | translate }}</h4>
                </div>
                <!-- help -->
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 pull-right">
                  <div class="btn-with-outside-txt-lbl help-btn" (click)="help()">
                    <small class="btn-inner-txt">{{ 'HELPERS.help' | translate }}</small> 
                    <a class="btn btn-light btn-xs btn-icon-only btn-icon-text-left">
                      <app-feather-icons [icon]="'help-circle'"></app-feather-icons>
                    </a>
                  </div>
                </div>
              </div>
              <!-- data -->
              <div class="row mt-4">
                <!-- companyLoanInstructions -->
                <div class="col-12">
                  <div class="form-group">
                    <label for="companyLoanInstructionsInput">{{ 'COMPANY.formLabels.companyLoanInstructions' | translate }} <span class="required-lbl">*</span></label>
                    <angular-editor id="companyLoanInstructionsInput" #companyLoanInstructionsInput name="companyLoanInstructionsInput" formControlName="companyLoanInstructions" [config]="editorConfig"></angular-editor>
                    <ng-container class="error-messages" *ngFor="let error of formErrorMessages.companyLoanInstructions">
                      <small class="text-danger error-msg" *ngIf="companyLoanInstructionsFrm.get('companyLoanInstructions').hasError(error.type) && (companyLoanInstructionsFrm.get('companyLoanInstructions').dirty || companyLoanInstructionsFrm.get('companyLoanInstructions').touched)">
                        {{ error.message }}.&nbsp;
                      </small>
                    </ng-container>
                    <p class="help-messages" *ngIf="toggleHelp">
                      <small>{{ 'COMPANY.formHelp.companyLoanInstructions' | translate }}</small>
                    </p>
                  </div>
                </div>
              </div>
              <!-- buttons -->
              <div class="row mt-4">
                <div class="col">
                  <div class="request-form-btns-container">
                    <button class="btn btn-light d-block btn-cancel" (click)="cancelEditLoanInstructionsData()" type="button">
                      <span>{{ 'HELPERS.cancel' | translate }}</span>
                    </button>
                    <button class="btn btn-dark d-block" (click)="checkEditLoanInstructionsData()" type="button">
                      <span>{{ 'HELPERS.save' | translate }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- company approval -->
    <div class="row mt-4" *ngIf="showCompanyApproval">
      <!-- content -->
      <div class="col-12" *ngIf="!enableEditCompanyApproval">
        <!-- header -->
        <div class="row mb-4">
          <!-- title -->
          <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12">
            <h4>{{ 'COMPANY.companyApprovalInfo' | translate }}</h4>
          </div>
          <!-- edit -->
          <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 pull-right">
            <button class="btn btn-primary btn-xs btn-icon-only edit-form-btn" (click)="editCompanyApprovalData()">
              <app-feather-icons [icon]="'edit-3'"></app-feather-icons>
            </button>
          </div>
        </div>
        <!-- content -->
        <div class="card small-widget mb-3">
          <div class="card-body legal primary">
            <p class="widget-title mb-2">{{ 'COMPANY.formLabels.companyApproval' | translate }}</p>
            <div class="d-block align-items-end gap-1">
              <p class="mb-2">
                <b>{{ 'COMPANY.companyAmountEdit' | translate }}: </b>
                <span *ngIf="companyAmountEdit">{{ 'COMPANY.companyAmountEditYes' | translate }}</span>
                <span *ngIf="!companyAmountEdit">{{ 'COMPANY.companyAmountEditNo' | translate }}</span>
              </p>
              <p [innerHTML]="companyApprovalTxt"></p>
            </div>
            <div class="bg-gradient">
              <app-feather-icons class="svg-feather" [icon]="'check-circle'"></app-feather-icons>
            </div>
          </div>
        </div>
      </div>
      <!-- edit -->
      <div class="col-12" *ngIf="enableEditCompanyApproval">
        <div class="card small-widget mb-3">
          <div class="card-body ">
            <!-- form -->
            <form class="theme-form" [formGroup]="companyApprovalFrm">
              <!-- header -->
              <div class="row">
                <!-- title -->
                <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                  <h4>{{ 'HELPERS.edit' | translate }}&nbsp;{{ 'COMPANY.companyApproval' | translate }}</h4>
                </div>
                <!-- help -->
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 pull-right">
                  <div class="btn-with-outside-txt-lbl help-btn" (click)="help()">
                    <small class="btn-inner-txt">{{ 'HELPERS.help' | translate }}</small> 
                    <a class="btn btn-light btn-xs btn-icon-only btn-icon-text-left">
                      <app-feather-icons [icon]="'help-circle'"></app-feather-icons>
                    </a>
                  </div>
                </div>
              </div>
              <!-- data -->
              <div class="row mt-4">
                <!-- companyAmountEdit -->
                <div class="col-12">
                  <div class="form-check form-switch company-amount-edit-switch">
                    <input #companyAmountEdit id="companyAmountEdit" type="checkbox" role="switch" formControlName="companyAmountEdit" class="form-check-input">
                    <label class="form-check-label" for="companyAmountEdit">{{ 'COMPANY.formLabels.companyAmountEdit' | translate }} <span class="required-lbl">*</span></label>
                    <ng-container class="error-messages" *ngFor="let error of formErrorMessages.companyAmountEdit">
                      <small class="text-danger error-msg" *ngIf="companyApprovalFrm.get('companyAmountEdit').hasError(error.type) && (companyApprovalFrm.get('companyAmountEdit').dirty || companyApprovalFrm.get('companyAmountEdit').touched)">
                        {{ error.message }}.&nbsp;
                      </small>
                    </ng-container>
                    <p class="help-messages" *ngIf="toggleHelp">
                      <small>{{ 'COMPANY.formHelp.companyAmountEdit' | translate }}</small>
                    </p>
                  </div>
                </div>
                <!-- companyApprovalRequired -->
                <div class="col-12">
                  <div class="form-check form-switch company-amount-edit-switch">
                    <input #companyApprovalRequired id="companyApprovalRequired" type="checkbox" role="switch" formControlName="companyApprovalRequired" class="form-check-input">
                    <label class="form-check-label" for="companyApprovalRequired">{{ 'COMPANY.formLabels.companyApprovalRequired' | translate }} <span class="required-lbl">*</span></label>
                    <ng-container class="error-messages" *ngFor="let error of formErrorMessages.companyApprovalRequired">
                      <small class="text-danger error-msg" *ngIf="companyApprovalFrm.get('companyApprovalRequired').hasError(error.type) && (companyApprovalFrm.get('companyApprovalRequired').dirty || companyApprovalFrm.get('companyApprovalRequired').touched)">
                        {{ error.message }}.&nbsp;
                      </small>
                    </ng-container>
                    <p class="help-messages" *ngIf="toggleHelp">
                      <small>{{ 'COMPANY.formHelp.companyApprovalRequired' | translate }}</small>
                    </p>
                  </div>
                </div>
                <!-- companyApprovalTxt -->
                <div class="col-12">
                  <div class="form-group">
                    <label for="companyApprovalTxtInput">{{ 'COMPANY.formLabels.companyApprovalTxt' | translate }}</label>
                    <angular-editor id="companyApprovalTxtInput" #companyApprovalTxtInput name="companyApprovalTxtInput" formControlName="companyApprovalTxt" [config]="editorConfig"></angular-editor>
                    <ng-container class="error-messages" *ngFor="let error of formErrorMessages.companyApprovalTxt">
                      <small class="text-danger error-msg" *ngIf="companyApprovalFrm.get('companyApprovalTxt').hasError(error.type) && (companyApprovalFrm.get('companyApprovalTxt').dirty || companyApprovalFrm.get('companyApprovalTxt').touched)">
                        {{ error.message }}.&nbsp;
                      </small>
                    </ng-container>
                    <p class="help-messages" *ngIf="toggleHelp">
                      <small>{{ 'COMPANY.formHelp.companyApprovalTxt' | translate }}</small>
                    </p>
                  </div>
                </div>
              </div>
              <!-- buttons -->
              <div class="row mt-4">
                <div class="col">
                  <div class="request-form-btns-container">
                    <button class="btn btn-light d-block btn-cancel" (click)="cancelEditCompanyApprovalData()" type="button">
                      <span>{{ 'HELPERS.cancel' | translate }}</span>
                    </button>
                    <button class="btn btn-dark d-block" (click)="checkEditCompanyApprovalData()" type="button">
                      <span>{{ 'HELPERS.save' | translate }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>