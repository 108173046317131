// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 03/06/2023
// import
import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
// translate
import { TranslateService } from '@ngx-translate/core';
// services
import { BreadcrumbsService } from 'src/app/shared/services/breadcrumbs.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
// interfaces
import { CompanyActivityInterface } from 'src/app/shared/interfaces/company-activity.interface';
// component
@Component({
  selector: 'app-company-activities-card',
  templateUrl: './company-activities-card.component.html',
  styleUrls: ['./company-activities-card.component.scss']
})
// class
export class CompanyActivitiesCardComponent implements OnInit, OnChanges {
  // variables
  @Input() companyActivitiesData: any = null;
  @Output() loaderEvent = new EventEmitter();
  @Output() openEvent = new EventEmitter();
  @Output() editEvent = new EventEmitter();
  @Output() deleteEvent = new EventEmitter();
  // companyActivities data
  companyActivities: any[] = [];
  // constructor
  constructor(
    public router: Router,
    public translate: TranslateService,
    public breadcrumbSv: BreadcrumbsService,
    public apiSv: ApiService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    private utilitiesSv: UtilitiesService,
  ) {
  }
  // life cycle
  async ngOnInit() {
    await this.setCompanyActivities();
  }
  async ngOnChanges(changes: SimpleChanges) {
    try {
      if (changes.companyActivitiesData) {
        await this.setCompanyActivities();
      }
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  // loader
  async updateLoader(loaderMsg: any) {
    this.utilitiesSv.updateLoader(loaderMsg);
  }
  // data
  async setCompanyActivities() {
    if (this.companyActivitiesData !== null) {
      // update companyActivities
      this.companyActivities = this.companyActivitiesData;
    }
  }
  openCompanyActivity(companyActivity: CompanyActivityInterface) {
    // emit data
    this.openEvent.emit(companyActivity);
  }
  editCompanyActivity(companyActivity: CompanyActivityInterface) {
    // emit data
    this.editEvent.emit(companyActivity);
  }
}
