// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 03/06/2023
// import
import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
// translate
import { TranslateService } from '@ngx-translate/core';
// environment
import { environment } from 'src/environments/environment';
// plugins
declare var $: any;
// services
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LocationService } from 'src/app/shared/services/location.service';
// interfaces
import { CompanyInterface } from 'src/app/shared/interfaces/company.interface';
// component
@Component({
  selector: 'app-company-card',
  templateUrl: './company-card.component.html',
  styleUrls: ['./company-card.component.scss']
})
// class
export class CompanyCardComponent implements OnInit, OnChanges {
  // variables
  @Input() companyData: CompanyInterface = null;
  @Input() origin: string = null;
  @Input() editEnable: boolean = false;
  @Output() loaderEvent = new EventEmitter();
  @Output() editEvent = new EventEmitter();
  // lang data
  currentLang: string = null;
  // company data
  companyActivityId: string = null;
  companyActivityCode: string = null;
  companyActivity: string = null;
  companyName: string = null;
  businessName: string = null;
  companyIdentification: string = null;
  companyConstitutionDate: string = null;
  companyLogo: string = null;
  companyPortrait: string = null;
  companyAbout: string = null;
  companyEmail: string = null;
  companyCellphoneCountryId: string = null;
  companyCellphoneCountryName: string = null;
  companyCellphoneCountryFlag: string = null;
  companyCellphoneCountryCodeCod: string = null;
  companyCellphoneCountryCode: string = null;
  companyCellphone: string = null;
  companyPhoneCountryId: string = null;
  companyPhoneCountryName: string = null;
  companyPhoneCountryFlag: string = null;
  companyPhoneCountryCodeCod: string = null;
  companyPhoneCountryCode: string = null;
  companyPhone: string = null;
  companyWhatsappCountryId: string = null;
  companyWhatsappCountryName: string = null;
  companyWhatsappCountryFlag: string = null;
  companyWhatsappCountryCodeCod: string = null;
  companyWhatsappCountryCode: string = null;
  companyWhatsapp: string = null;
  companyUriFacebook: string = null;
  companyUserInstagram: string = null;
  companyUserTwitter: string = null;
  companyUriTiktok: string = null;
  companyUriYoutube: string = null;
  companyUriLinkedin: string = null;
  companyWebProtocol: string = null;
  companyWeb: string = null;
  termsConditions: string = null;
  privacyPolicy: string = null;
  loanTermsConditions: string = null;
  loanPrivacyPolicy: string = null;
  companyLoanInstructions: string = null;
  companyAmountEdit: boolean = false;
  companyApprovalRequired: boolean = false;
  companyApprovalTxt: string = null;
  ceoFirstName: string = null;
  ceoLastName: string = null;
  ceoEmail: string = null;
  managerFirstName: string = null;
  managerLastName: string = null;
  managerEmail: string = null;
  companyStatus: any = null;

  // company location data
  countryId: string = null;
  countryName: string = null;
  countryFlag: string = null;
  stateId: string = null;
  stateName: string = null;
  cityId: string = null;
  cityName: string = null;
  address: string = null;
  address2: string = null;
  latitude: string = null;
  longitude: string = null;
  // constructor
  constructor(
    public translate: TranslateService,
    public apiSv: ApiService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    public locationSv: LocationService,
  ) {
    // get currentLang
    const currentLang = this.translate.getDefaultLang();
    // set currentLang
    this.currentLang = currentLang;
  }
  // life cycle
  async ngOnInit() {}
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.companyData) {
      await this.setData();
    }
  }
  // loader
  async updateLoader(loaderMsg: any) {
    this.loaderEvent.emit(loaderMsg);
  }
  // data
  async setData() {
    // check data
    if (this.companyData !== null) {
      // check origin 
      if (this.origin !== 'otherCompany') {
        // set single data
        this.companyActivityId = this.companyData.companyActivityId;
        this.companyActivityCode = this.companyData.companyActivityCode;
        this.companyActivity = this.companyData.companyActivity;
        this.companyName = this.companyData.companyName;
        this.businessName = this.companyData.businessName;
        this.companyIdentification = this.companyData.companyIdentification;
        this.companyConstitutionDate = this.companyData.companyConstitutionDate;
        this.companyLogo = this.companyData.companyLogo;
        this.companyPortrait = this.companyData.companyPortrait;
        this.companyAbout = this.companyData.companyAbout;
        this.companyEmail = this.companyData.companyEmail;
        this.countryId = this.companyData.countryId;
        this.countryName = this.companyData.countryName;
        this.countryFlag = this.companyData.countryFlag;
        this.stateId = this.companyData.stateId;
        this.stateName = this.companyData.stateName;
        this.cityId = this.companyData.cityId;
        this.cityName = this.companyData.cityName;
        this.address = this.companyData.address;
        this.address2 = this.companyData.address2;
        this.latitude = this.companyData.latitude;
        this.longitude = this.companyData.longitude;
        this.companyCellphoneCountryId = this.companyData.companyCellphoneCountryId;
        this.companyCellphoneCountryName = this.companyData.companyCellphoneCountryName;
        this.companyCellphoneCountryFlag = this.companyData.companyCellphoneCountryFlag;
        this.companyCellphoneCountryCodeCod = this.companyData.companyCellphoneCountryCodeCod;
        this.companyCellphoneCountryCode = this.companyData.companyCellphoneCountryCode;
        this.companyCellphone = this.companyData.companyCellphone;
        this.companyPhoneCountryId = this.companyData.companyPhoneCountryId;
        this.companyPhoneCountryName = this.companyData.companyPhoneCountryName;
        this.companyPhoneCountryFlag = this.companyData.companyPhoneCountryFlag;
        this.companyPhoneCountryCodeCod = this.companyData.companyPhoneCountryCodeCod;
        this.companyPhoneCountryCode = this.companyData.companyPhoneCountryCode;
        this.companyPhone = this.companyData.companyPhone;
        this.companyWhatsappCountryId = this.companyData.companyWhatsappCountryId;
        this.companyWhatsappCountryName = this.companyData.companyWhatsappCountryName;
        this.companyWhatsappCountryFlag = this.companyData.companyWhatsappCountryFlag;
        this.companyWhatsappCountryCodeCod = this.companyData.companyWhatsappCountryCodeCod;
        this.companyWhatsappCountryCode = this.companyData.companyWhatsappCountryCode;
        this.companyWhatsapp = this.companyData.companyWhatsapp;
        this.companyUriFacebook = this.companyData.companyUriFacebook;
        this.companyUserInstagram = this.companyData.companyUserInstagram;
        this.companyUserTwitter = this.companyData.companyUserTwitter;
        this.companyUriTiktok = this.companyData.companyUriTiktok;
        this.companyUriYoutube = this.companyData.companyUriYoutube;
        this.companyUriLinkedin = this.companyData.companyUriLinkedin;
        this.companyWebProtocol = this.companyData.companyWebProtocol;
        this.companyWeb = this.companyData.companyWeb;
        this.companyStatus = this.companyData.companyStatus;
      } else {
        // set single data
        this.companyActivityId = this.companyData.companyActivityId;
        this.companyActivityCode = this.companyData.companyActivityCode;
        this.companyActivity = this.companyData.companyActivity;
        this.companyName = this.companyData.companyName;
        this.companyIdentification = this.companyData.companyIdentification;
        this.countryId = this.companyData.countryId;
        this.countryName = this.companyData.countryName;
        this.countryFlag = this.companyData.countryFlag;
        this.stateId = this.companyData.stateId;
        this.stateName = this.companyData.stateName;
        this.cityId = this.companyData.cityId;
        this.cityName = this.companyData.cityName;
        this.address = this.companyData.address;
        this.address2 = this.companyData.address2;
        this.latitude = this.companyData.latitude;
        this.longitude = this.companyData.longitude;
        this.companyStatus = this.companyData.companyStatus;
      }
    }

  }
  // actions
  async edit() {
    // emit data
    this.editEvent.emit(this.companyData);
  }
}
