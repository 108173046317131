<div id="validation-section" class="fileUploadWrapper">
  <div class="row mt-4 mb-0">
    <!-- header -->
    <div class="col-12">
      <h4 class="request-title">{{ 'LOAN.loanValidations' | translate }}</h4>
      <p>{{ 'LOAN.loanValidationsMsg' | translate }}</p>
    </div>
    <!-- score -->
    <div id="score-section">
      <!-- score value -->
      <div class="row mt-4 mb-0">
        <!-- header -->
        <div class="col-md-7 col-sm-12">
          <h4 class="request-title mt-2">{{ 'LOAN.loanScoreCredit' | translate }}</h4>
        </div>
        <!-- score -->
        <div class="col-md-5 col-sm-12 text-center">
          <!-- value -->
          <div class="score-container" [class]="scoreClass">
            <span class="current-value amount-value">{{ score | number }}</span>
          </div>
        </div>
      </div>
      <!-- divider -->
      <hr>
    </div>
    <!-- documents -->
    <div class="col-12">
      <app-loan-documents 
          [userId]="userId"
          [loanId]="loanId"
          [target]="'loan'"
          [profileFileData]="profileFileData"
          [profileFileShow]="profileFileShow"
          [profileFileEdit]="fileEditEnable"
          [synthesisFileData]="synthesisFileData"
          [synthesisFileShow]="synthesisFileShow"
          [synthesisFileEdit]="fileEditEnable"
          [detailedFileData]="detailedFileData"
          [detailedFileShow]="detailedFileShow"
          [detailedFileEdit]="fileEditEnable"
          (loaderEvent)="updateLoader($event)"
          (actionEvent)="fileAction($event)">
        </app-loan-documents>
    </div>
  </div>
  <!-- divider -->
  <hr>
</div>