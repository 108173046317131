<!-- header -->
<div class="row mt-4 mb-0">
  <div class="col-8">
    <h4 class="request-title">{{ 'USERBANKACCOUNTS.bankAccounts' | translate }}</h4>
  </div>
  <div class="col-4 btn-add-bank" *ngIf="addEnable">
    <button class="btn btn-primary btn-xs btn-icon-only btn-icon-text-left" (click)="addUserBankAccountForm(bankAccountModal)">
      <app-feather-icons [icon]="'plus-circle'"></app-feather-icons>
    </button>
  </div>
</div>
<!-- list -->
<div class="row" *ngFor="let userBankAccount of userBankAccounts">
  <div class="col">
    <!-- card -->
    <div class="card small-widget mb-3">
      <div class="card-body " [ngClass]="{'primary': userBankAccount.userBankAccountActive === false, 'success': userBankAccount.userBankAccountActive === true}">
        <button class="btn btn-icon-only btn-edit btn-edit-bank" (click)="editUserBankAccount(bankAccountModal, userBankAccount)" *ngIf="editEnable">
          <app-feather-icons [icon]="'edit-3'"></app-feather-icons>
        </button>
        <span class="widget-title">{{ 'USERBANKACCOUNTS.formLabels.bank' | translate }}</span>
        <div class="user-body-content">
          <h4 class="content-title">{{ userBankAccount.bankName }}</h4>
          <p class="content-txt"><small>{{ userBankAccount.userBankAccountNumberDecoded | maskSensitiveData:4 }}</small></p>
          <p class="content-txt account-type">
            <small *ngIf="userBankAccount.userBankAccountType === 'savings'">{{ 'USERBANKACCOUNTS.formLabels.userBankAccountTypeSavings' | translate }}</small>
            <small *ngIf="userBankAccount.userBankAccountType === 'regular'">{{ 'USERBANKACCOUNTS.formLabels.userBankAccountTypeRegular' | translate }}</small>
          </p>
          <!-- certificate -->
          <app-loan-documents 
            *ngIf="showFiles"
            class="bank-account-certificate"
            [userId]="userId"
            [loanId]="loanId"
            [target]="'loan'"
            [editEnable]="fileEditEnable"
            [bankFileData]="userBankAccount.certificateFileData"
            [bankFileShow]="true"
            (loaderEvent)="updateLoader($event)"
            (actionEvent)="fileAction($event)">
          </app-loan-documents>
        </div>
        <div class="bg-gradient bank-card-icon" [ngClass]="{active: userBankAccount.userBankAccountActive}">
          <app-feather-icons class="svg-feather" [icon]="'pocket'"></app-feather-icons>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- bankAccount modal -->
<ng-template #bankAccountModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'USERBANKACCOUNTS.bankAccount' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeUserBankAccountForm()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-user-bank-account-form 
      [origin]="'dashboard'" 
      [formType]="formType"
      [userData]="userData" 
      [userBankAccountData]="userBankAccountSelected" 
      [banksCounter]="banksCounter" 
      [userBankAccountMainSelected]="userBankAccountMainSelected" 
      (loaderEvent)="updateLoader($event)" 
      (backEvent)="closeUserBankAccountForm($event)" 
      (addEvent)="saveAddUserBankAccount($event)" 
      (updateEvent)="saveUpdateUserBankAccount($event)">
    </app-user-bank-account-form>
  </div>
</ng-template>