import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// components
import { ContentComponent } from "./shared/components/layout/content/content.component";
import { BaseComponent } from "./shared/components/layout/base/base.component";
// routes
import { base } from "./shared/routes/base.routes"
import { content } from "./shared/routes/routes";
// guard
import { AdminGuard } from './shared/guard/admin.guard';
// routes
const routes: Routes = [
  {
    path: '',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: '',
    component: ContentComponent,
    canActivate: [AdminGuard],
    children: content
  },
  {
    path: '',
    component: BaseComponent,
    children: base
  },
  {
    path: '**',
    redirectTo: ''
  }
];
// module
@NgModule({
  imports: [[RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
})],
],
  exports: [RouterModule]
})
// class
export class AppRoutingModule { }
