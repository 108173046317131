// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 25/05/2023
// tslint:disable: max-line-length
// tslint:disable: no-unused-expression
// import
import { Pipe, PipeTransform } from '@angular/core';
import { UtilitiesService } from '../services/utilities.service';
// pipe
@Pipe({
  name: 'phonePipe'
})
// class
export class PhonePipe implements PipeTransform {
  // variables
  phoneValue: string;
  // constructor
  constructor(
    public utilitiesSv: UtilitiesService,
  ) { }
  // transform
  transform(value: string): any {
    // format phone number
    this.phoneValue = this.utilitiesSv.formatPhoneNumber(value);
    // return data
    return this.phoneValue;
  }
}
