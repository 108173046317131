// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 03/06/2023
// import
import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
// translate
import { TranslateService } from '@ngx-translate/core';
// services
// interfaces
import { UserAddressInterface } from 'src/app/shared/interfaces/user-address.interface';
// component
@Component({
  selector: 'app-user-addresses-list',
  templateUrl: './user-addresses-list.component.html',
  styleUrls: ['./user-addresses-list.component.scss']
})
// class
export class UserAddressesListComponent implements OnInit, OnChanges {
  // variables
  @Input() userAddressesData: any[] = [];
  @Input() editEnable: boolean = false;
  @Output() editEvent = new EventEmitter();
  @Output() deleteEvent = new EventEmitter();
  // variables
  mailingUserAddressSelected = false;
  userAddresses: any[] = [];
  // constructor
  constructor(
    public translate: TranslateService,
  ) {
  }
  // life cycle
  async ngOnInit() {
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.userAddressesData) {
      this.setUserAddresses();
    }
  }
  // data
  setUserAddresses() { 
    // check userAddressesData
    if (this.userAddressesData) {
      // set userAddresses
      this.userAddresses = this.userAddressesData;
    }
  }
  // actions
  async editAddress(userAddress: UserAddressInterface) {
    // emit data
    this.editEvent.emit(userAddress);
  }
  async deleteAddress(userAddressId: string) {
    // emit data
    this.deleteEvent.emit(userAddressId);
  }
}
