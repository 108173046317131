// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 25/05/2023
// import
import { Component, EventEmitter, OnInit, OnChanges, Input, Output, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
// translate
import { TranslateService } from '@ngx-translate/core';
// plugins
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { editorObservationGlobalConfigEn, editorObservationGlobalConfigEs } from 'src/app/shared/services/angular-editor-config';
// services
import { AuthService } from 'src/app/shared/services/auth.service';
import { EncodingService } from 'src/app/shared/services/encoding.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { LocationService } from 'src/app/shared/services/location.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
// interfaces
import { CompanyActivityInterface } from 'src/app/shared/interfaces/company-activity.interface';
// component
@Component({
  selector: 'app-company-activity-form',
  templateUrl: './company-activity-form.component.html',
  styleUrls: ['./company-activity-form.component.scss']
})
// class
export class CompanyActivityFormComponent implements OnInit, OnChanges {
  // variables
  @Input() companyActivityData: CompanyActivityInterface = null;
  @Output() loaderEvent = new EventEmitter();
  @Output() addEvent = new EventEmitter();
  @Output() updateEvent = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  // lang data
  currentLang: string = null;
  // editor configuration
  editorConfig: AngularEditorConfig = null;
  // form data
  public mainFrm: FormGroup;
  // messages data
  formErrorMessages: any = {};
  // help data
  toggleHelp = false;
  // saved data
  savedData: boolean = false;
  // company activity data
  companyActivity: string = null;
  // constructor
  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public authSv: AuthService,
    public encodingSv: EncodingService,
    public apiSv: ApiService,
    public locationSv: LocationService,
    public filterSv: FilterService,
    public alertSv: AlertService,
  ) {
    // get currentLang
    const currentLang = this.translate.getDefaultLang();
    // set currentLang
    this.currentLang = currentLang;
    // main form
    this.mainFrm = this.formBuilder.group({
      companyActivityCode: [null, [Validators.required]],
      companyActivity: [null, [Validators.required]],
      companyActivityStatus: [null, [Validators.required]],
    });
  }
  // life cycle
  async ngOnInit() {
    await this.translateMsgs();
    this.initData();
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.companyActivityData) {
      // check for companyActivityData
      if (this.companyActivityData !== null) {
        this.initData();
      }
    }
  }
  async initData() {
    // show loader
    this.loaderEvent.emit(this.translate.instant('HELPERS.loadingTxt'));
    // get data
    await this.setCompanyActivityData();
    // hide loader
    this.loaderEvent.emit(null);
  }
  // translate
  async translateMsgs() {
    // update angular editor
    if (this.currentLang === 'es') {
      this.editorConfig = editorObservationGlobalConfigEs;
    } else {
      this.editorConfig = editorObservationGlobalConfigEn;
    }
    // update form error messages
    this.formErrorMessages = {
      companyActivityCode: [
        { type: 'required', message: this.translate.instant('COMPANYACTIVITIES.formValidation.companyActivityCode.required') }
      ],
      companyActivity: [
        { type: 'required', message: this.translate.instant('COMPANYACTIVITIES.formValidation.companyActivity.required') }
      ],
      companyActivityStatus: [
        { type: 'required', message: this.translate.instant('COMPANYACTIVITIES.formValidation.companyActivityStatus.required') }
      ],
    };
  }
  // form
  async help() {
    if (this.toggleHelp) {
      this.toggleHelp = false;
    } else {
      this.toggleHelp = true;
    }
  }
  async setFocus(elementId: any) {
    elementId.focus();
  }
  checkFormField(fieldName: string) {
    // get field
    const field = this.mainFrm.get(fieldName);
    // check field
    const check = field?.invalid && field?.touched;
    // return data
    return check;
  }
  // navigation
  async cancel() {
    // emit data
    this.cancelEvent.emit(); // Return dat
  }
  async setCompanyActivityData() {
    // check companyActivity data
    if (this.companyActivityData !== null) {
      // set companyActivity
      const companyActivity: CompanyActivityInterface = this.companyActivityData;
      // set companyActivity data
      this.companyActivity = companyActivity.companyActivity;
      // set form data
      this.mainFrm.controls.companyActivityCode.setValue(companyActivity.companyActivityCode);
      this.mainFrm.controls.companyActivity.setValue(companyActivity.companyActivity);
      this.mainFrm.controls.companyActivityStatus.setValue(companyActivity.companyActivityStatus);
      // set savedData
      this.savedData = true;
    }
  }
  // actions
  async save() {
    // markAllAsTouched
    this.mainFrm.markAllAsTouched();
    // validate data
    if (!this.mainFrm.valid) {
      // send alert
      this.alertSv.showMessage(this.translate.instant('HELPERS.requiredFields'),'warning', this.translate.instant('HELPERS.warning'), true);
    } else {
      // check if data is been saved before
      if (!this.savedData) {
        // add data
        await this.addCompanyActivity();
      } else {
        // update data
        await this.updateCompanyActivity();
      }
    }
  }
  async addCompanyActivity() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // set companyActivityData
      const companyActivityData: CompanyActivityInterface = {
        companyActivityCode: this.mainFrm.controls.companyActivityCode.value,
        companyActivity: this.mainFrm.controls.companyActivity.value,
        companyActivityStatus: this.mainFrm.controls.companyActivityStatus.value,
      }
      // get auxCompanyActivity
      const auxCompanyActivity: any = await this.apiSv.addCompanyActivity(companyActivityData);
      // get data
      const data: any = auxCompanyActivity.data;
      // get companyActivityId
      const companyActivityId: string = data.id;
      // set savedData
      this.savedData = true;
      // set saveCompanyActivityData
      const saveCompanyActivityData: CompanyActivityInterface = {
        id: companyActivityId,
        companyActivityCode: this.mainFrm.controls.companyActivityCode.value,
        companyActivity: this.mainFrm.controls.companyActivity.value,
        companyActivityStatus: this.mainFrm.controls.companyActivityStatus.value,
      }
      // update companyActivityData
      this.companyActivityData = saveCompanyActivityData;
      // hide loader
      this.loaderEvent.emit(null);
      // emit data
      this.addEvent.emit(saveCompanyActivityData);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async updateCompanyActivity() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // set companyActivityData
      const companyActivityData: CompanyActivityInterface = {
        companyActivityCode: this.mainFrm.controls.companyActivityCode.value,
        companyActivity: this.mainFrm.controls.companyActivity.value,
        companyActivityStatus: this.mainFrm.controls.companyActivityStatus.value,
      }
      // update product
      await this.apiSv.updateCompanyActivity(this.companyActivityData.id, companyActivityData);
      // update companyActivityData
      this.companyActivityData.companyActivityCode = this.mainFrm.controls.companyActivityCode.value;
      this.companyActivityData.companyActivity = this.mainFrm.controls.companyActivity.value;
      this.companyActivityData.companyActivityStatus = this.mainFrm.controls.companyActivityStatus.value;
      // hide loader
      this.loaderEvent.emit(null);
      // emit data
      this.updateEvent.emit(this.companyActivityData);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
}
