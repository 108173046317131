// author: Alejandro Bermúdez
// company: Think In
// date: 25/05/2023
// import
import { Component, PLATFORM_ID, Inject, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { map, delay, withLatestFrom } from 'rxjs/operators';
// plugins
import Swal from 'sweetalert2';
import { Subject } from 'rxjs';
// translate
import { TranslateService } from '@ngx-translate/core';
// environment
import { environment } from 'src/environments/environment';
// services
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AuthService } from './shared/services/auth.service';
import { ApiService } from './shared/services/api.service';
import { LocationService } from './shared/services/location.service';
import { UtilitiesService } from './shared/services/utilities.service';
// interfaces
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  // user activity data
  userActivity;
  userInactive: Subject<any> = new Subject();
  // session data
  showHeader: boolean = true;
  showSideBar: boolean = true;
  showFooter: boolean = true;
  isLogin = false;
  // For ProgressBar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );
  // constructor
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public translate: TranslateService,
    private loader: LoadingBarService,
    public locationSv: LocationService,
    public authSv: AuthService,
    private apiSv: ApiService,
    private utilitiesSv: UtilitiesService,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      translate.addLangs(['en', 'es']);
      translate.setDefaultLang('es');
    }
    // checkVersion
    this.checkVersion();
    // get data
    this.getCountries();
    // checkLogin
    this.checkLogin();
    // checkSession
    const checkSession: any = this.authSv.checkSession();
    // check checkSession
    if (checkSession.logout) {
      // logout user
      this.authSv.logout();
    } else {
      // inactivity
      this.setTimeout();
      this.userInactive.subscribe(async () => {
        console.log('user has been inactive');
        // get isLoggedIn
        const isLoggedIn = localStorage.getItem('isLoggedIn');
        // get rememberPwd
        const rememberPwd = localStorage.getItem('rememberPwd');
        // check isLoggedIn
        if (rememberPwd === 'true') {
          // check isLoggedIn
          if (isLoggedIn === 'true') {
            // save lock status on storage
            localStorage.setItem('lockAccount', 'true');
            // go to unlock page
            this.utilitiesSv.goTo('auth/unlock', false, null);
          }
        } else {
          // check isLoggedIn
          if (isLoggedIn === 'true') {
            // logout user
            this.authSv.logout();
          }
        }
      });
    }
  }
  // actions
  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), environment.unlockTime);
  }
  async checkVersion() {
    try {
      // get currentVersion
      const currentVersion = environment.version;
      // get data from api
      await this.apiSv.version().then((response: any)=>{
        // get version
        const version: any = response.data.versionDashboard;
        // compare versions
        if (currentVersion !== version) {
          // show alert to get new profession
          Swal.fire({
            icon: 'warning',
            title: this.translate.instant('VERSION.update'),
            text: this.translate.instant('VERSION.required'),
            confirmButtonText: this.translate.instant('HELPERS.accept'),
            allowOutsideClick: false,
          }).then(async (result) => {
            if (result.isConfirmed) {
              // logout user
              await this.authSv.logout();
              // reload page
              window.location.reload();
            }
          })
        }
      }, error=>{
        console.log('error', error);
      });
    } catch (error) {
      console.log('error', error);
    }
  }
  async checkLogin() {
    const validate = localStorage.getItem('token');
    if ( validate === null || validate === undefined ) {
      this.isLogin = false;
    } else {
      this.isLogin = true;
    }
  }
  // listener
  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
  // get data
  async getCountries() {
    try {
      // get stored data countries
      const storedDataCountries: any = localStorage.getItem('countriesData');
      // check countries data
      if (!storedDataCountries) {
        // get data from api
        await this.apiSv.getCountries().then((response: any)=>{
          // get countries
          const countries: any = response.data;
          // save countries on storage
          localStorage.setItem('countriesData', JSON.stringify(countries));
        }, error=>{
          console.log('error', error);
        });
      }
    } catch (error) {
      console.log('error', error);
    }
  }
}
