export const environment = {
  production: true,
  version: "2.1.9",
  // secrets
  secretQR: "cr3d1P*TkN",
  secretApi: "AC7B638365CEE713CDD183FD8B882",
  secretToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZWNyZXQiOiIxMjM0NTY3ODkwIn0.pnbV5PxmNhe_marII8aZ-ktsY35ZpGtYvfdhdvFC5iI",
  // server data
  // server_api: 'https://api-dev.credipremium.co',
  // server_media: 'https://api-dev.credipremium.com/uploads/',
  // appURI: 'https://app-dev.credipremium.co',
  server_api: 'https://api.credipremium.co',
  server_media: 'https://api.credipremium.com/uploads/',
  appURI: 'https://app.credipremium.co',
  websiteURI: 'https://credipremium.co',
  websiteURITxt: 'www.credipremium.co',
  // media data
  maxFileData: 5242880,
  // country data
  countryName: 'Colombia',
  countryFlag: 'assets/images/flags/COL.png',
  countryCode: '57',
  countryCodeId: 'd27a2ec8-e942-4044-b00c-382caf3a2673',
  // simulator data
  companyId: '9efa13fa-3644-4681-b777-0cbf46b76fb6',
  company: {
    id: "9efa13fa-3644-4681-b777-0cbf46b76fb6",
    companyActivityId: null,
    companyName: "Empresa Simulador",
    businessName: "Empresa Simulador",
    companyIdentification: null,
    companyStatus: "inactive",
    city: null,
    cityId: null,
    cityName: null,
    state: null,
    stateId: null,
    stateName: null,
    country: null,
    countryId: null,
    countryName: null,
    countryFlag: null,
    address: null,
    address2: null,
    latitude: null,
    longitude: null,
  },
  productId: '9558d6c8-0720-441a-92cd-7c13766e9535',
  product: {
    id: '9558d6c8-0720-441a-92cd-7c13766e9535',
    productName: 'Libre Inversión Simulador',
    productDescription: '<font size="2">Crédito de libre Inversión Simulador de Crédito</font>',
    productPortrait: null,
    productIcon: null,
    loanMonthMin: 3,
    loanMonthMax: 48,
    loanMonthSteps: 1,
    loanAmountMin: 200000,
    loanAmountMax: 50000000,
    loanInterest: 2.52,
    loanAmountMaxLimit: true,
    loanMonthlyRate: 0,
    loanAnualRate: 0,
    loanReferralMinimum: 0,
    loanPaymentReceipts: 2,
    borrowingCapacityPercentage: 30,
    productStatus: false,
    paymentMethod: null,
    productInsurance: false,
    productCategoryId: "8584e1bb-9f45-4694-9300-5230fdf7f37b"
  },
  // paypal data
  payPalClientId: 'AUdcJ2BweQgeXHZZZpf9HobXs8vl8lpANbYmB87APmZMbxC22WnzETi_JZf6cX7WwcxUUeboY3rjyGt-',
  payPalSecret: 'EKEld8JYATKnGeAcepVF6ta9CflEzX0VCRdxjpA6Uz3uMmaqZDNF3fhBV8wkYh1VgpOS1CnPyrZ6cjKG',
  payPalCurrency: 'USD',
  // colors data
  colors: {
    chartColors: [
      '#FFCC11',
      '#C24700',
      '#AB3F00',
      '#FF904F',
      '#FF7424',
      '#702900',
      '#4F1D00',
      '#181818',
      '#373435',
    ],
    mainColor: '#FFCC11',
    secondaryColor: '#C24700',
    tertiaryColor: '#AB3F00',
    quaternaryColor: '#181818',
    mainTxtColor: '#181818',
    secondaryTxtColor: '#ffffff',
    paragraphTxtColor: '#373435',
    btnBgColor: '#FFCC11',
    btnBgHoverColor: '#FFFFFF',
    btnBorderColor: '#FFCC11',
    btnBorderHoverColor: '#FFCC11',
    btnTxtColor: '#FFFFFF',
    btnTxtHoverColor: '#FFCC11',
    cardBgColor1: '#FFCC11',
    cardBgColor2: '#AB3F00',
    cardBgColor3: '#0274A3',
    cardBgColor4: '#1D2127',
    cardBgColor5: '#777777',
    cardHoverColor1: '#AB3F00',
    cardHoverColor2: '#0274A3',
    cardHoverColor3: '#1D2127',
    cardHoverColor4: '#777777',
    cardHoverColor5: '#424242',
    cardTxtColor1: '#FFFFFF',
    cardTxtColor2: '#FFFFFF',
    cardTxtColor3: '#FFFFFF',
    cardTxtColor4: '#FFFFFF',
    cardTxtColor5: '#FFFFFF',
  },
  // map data
  googleMapKey: 'AIzaSyCcUTVa2PHNspNn4_zJpDjF1dlkkKvEc6M',
  mapBoxKey: 'pk.eyJ1IjoiY3JlZGlwcmVtaXVtIiwiYSI6ImNsbTRkZ3RpODJsbHUzY3A0YnIwam9kdXEifQ.3p05gBx7MsH17OdnDI6q6A',
  lat: 6.22900264129868,
  lng: -75.57837234758941,
  zoom: 12,
  // unlock data
  unlockTimeNumber: 1,
  unlockTimeMessure: 'minutes',
  unlockTime: 3000000,
  // algorand data
  algorandExplorer: 'https://algoexplorer.io/tx/',
  // statuses data
  statuses: [
    {
      statusName: 'canceled',
      statusTranslate: 'LOAN.loanStatusCanceled',
      statusTranslateProcess: 'LOAN.loanStatusCanceledProcess',
      statusNumber: -1
    },
    {
      statusName: 'started',
      statusTranslate: 'LOAN.loanStatusStarted',
      statusTranslateProcess: 'LOAN.loanStatusStartedProcess',
      statusNumber: 0
    },
    {
      statusName: 'sended',
      statusTranslate: 'LOAN.loanStatusSended',
      statusTranslateProcess: 'LOAN.loanStatusSendedProcess',
      statusNumber: 1
    },
    {
      statusName: 'accepted',
      statusTranslate: 'LOAN.loanStatusAccepted',
      statusTranslateProcess: 'LOAN.loanStatusAcceptedProcess',
      statusNumber: 2
    },
    {
      statusName: 'noPassedValidation',
      statusTranslate: 'LOAN.loanStatusNoPassedValidation',
      statusTranslateProcess: 'LOAN.loanStatusNoPassedValidationProcess',
      statusNumber: 3
    },
    {
      statusName: 'validated',
      statusTranslate: 'LOAN.loanStatusValidated',
      statusTranslateProcess: 'LOAN.loanStatusValidatedProcess',
      statusNumber: 4
    },
    {
      statusName: 'rejected',
      statusTranslate: 'LOAN.loanStatusRejected',
      statusTranslateProcess: 'LOAN.loanStatusRejectedProcess',
      statusNumber: 5
    },
    {
      statusName: 'preApproved',
      statusTranslate: 'LOAN.loanStatusPreApproved',
      statusTranslateProcess: 'LOAN.loanStatusPreApprovedProcess',
      statusNumber: 6
    },
    {
      statusName: 'readyToApprove',
      statusTranslate: 'LOAN.loanStatusReadyToApprove',
      statusTranslateProcess: 'LOAN.loanStatusReadyToApproveProcess',
      statusNumber: 7
    },
    {
      statusName: 'approved',
      statusTranslate: 'LOAN.loanStatusApproved',
      statusTranslateProcess: 'LOAN.loanStatusApprovedProcess',
      statusNumber: 8
    },
    {
      statusName: 'readyForDisbursement',
      statusTranslate: 'LOAN.loanStatusReadyForDisbursement',
      statusTranslateProcess: 'LOAN.loanStatusReadyForDisbursementProcess',
      statusNumber: 9
    },
    {
      statusName: 'disbursementOnProgress',
      statusTranslate: 'LOAN.loanStatusDisbursementOnProgress',
      statusTranslateProcess: 'LOAN.loanStatusDisbursementOnProgressProcess',
      statusNumber: 10
    },
    {
      statusName: 'disbursed',
      statusTranslate: 'LOAN.loanStatusDisbursed',
      statusTranslateProcess: 'LOAN.loanStatusDisbursedProcess',
      statusNumber: 11
    },
    {
      statusName: 'payed',
      statusTranslate: 'LOAN.loanStatusPayed',
      statusTranslateProcess: 'LOAN.loanStatusesPayed',
      statusNumber: 12
    },
    {
      statusName: 'preLegal',
      statusTranslate: 'LOAN.loanStatusPreLegal',
      statusTranslateProcess: 'LOAN.loanStatusesPreLegal',
      statusNumber: 13
    },
    {
      statusName: 'legal',
      statusTranslate: 'LOAN.loanStatusLegal',
      statusTranslateProcess: 'LOAN.loanStatusesLegal',
      statusNumber: 14
    },
    {
      statusName: 'negotiation',
      statusTranslate: 'LOAN.loanStatusNegotiation',
      statusTranslateProcess: 'LOAN.loanStatusesNegotiation',
      statusNumber: 15
    },
    {
      statusName: 'expired',
      statusTranslate: 'LOAN.loanStatusExpired',
      statusTranslateProcess: 'LOAN.loanStatusesExpired',
      statusNumber: 16
    },
  ]
};
