<div class="card social-profile">
  <!-- portrait -->
  <div class="card-portrait">
    <img class="portrait-image" [src]="productPortrait" [alt]="businessName" onError="src = 'assets/images/broken-image-slim.png'" *ngIf="productPortrait !== null">
    <img class="portrait-no-image" src="assets/images/broken-image-slim.png" [alt]="businessName" *ngIf="productPortrait === null">
  </div>
  <div class="card-body">
    <!-- edit -->
    <button class="btn btn-primary btn-xs btn-icon-only edit-form-btn" (click)="edit()" *ngIf="editEnable">
      <app-feather-icons [icon]="'edit-3'"></app-feather-icons>
    </button>
    <!-- productIcon -->
    <div class="social-img-wrap"> 
      <div class="social-img">
        <img [src]="productIcon" [alt]="businessName" onError="src = 'assets/images/broken-image-slim.png'" *ngIf="productIcon !== null">
        <img src="assets/images/broken-image-slim.png" alt="Avatar" *ngIf="productIcon === null">
      </div>
    </div>
    <!-- data -->
    <div class="row mt-4">
      <!-- header -->
      <div class="col-12">
        <h4 class="mb-4">{{ 'PRODUCT.productInfo' | translate }}</h4>
      </div>
      <!-- status -->
      <div class="col-12">
        <p class="table-status-txt">
          <span class="status-approved" *ngIf="productStatus === true">{{ 'STATUSES.active' | translate }}</span>
          <span class="status-rejected" *ngIf="productStatus === false">{{ 'STATUSES.unactive' | translate }}</span>
        </p>
      </div>
      <!-- product insurance -->
      <div class="col-12 text-center mt-3" *ngIf="productInsurance">
        <app-feather-icons class="product-insurance-icon" [icon]="'umbrella'"></app-feather-icons>
        <p class="mt-2">{{ 'PRODUCT.formLabels.productInsuranceMsg' | translate }}</p>
      </div>
      <!-- product -->
      <div class="col-12 mt-3">
        <div class="card small-widget mb-3">
          <div class="card-body primary user-body-card">
            <span class="widget-title">{{ productName }}</span>
            <div class="user-body-content">
              <!-- name -->
              <h6 class="content-title">
                <span>{{ productCategoryName }}</span>
              </h6>
              <!-- description -->
              <p [innerHTML]="productDescription"></p>
              <!-- status -->
              <p class="table-status-txt">
                <span class="status-approved" *ngIf="productStatus === true">{{ 'STATUSES.active' | translate }}</span>
                <span class="status-rejected" *ngIf="productStatus === false">{{ 'STATUSES.unactive' | translate }}</span>
              </p>
            </div>
            <div class="bg-gradient">
              <app-feather-icons class="svg-feather" [icon]="'tag'"></app-feather-icons>
            </div>
          </div>
        </div>
      </div>
      <!-- loan data -->
      <div class="col-12">
        <div class="card small-widget mb-3">
          <div class="card-body primary user-body-card">
            <span class="widget-title">{{ 'PRODUCT.productLoan' | translate }}</span>
            <div class="user-body-content">
              <div class="row">
                <div class="col-6">
                  <p class="m-0"><b>{{ 'PRODUCT.formLabels.loanMonthMin' | translate }}:</b> {{ loanMonthMin }}</p>
                  <p class="m-0"><b>{{ 'PRODUCT.formLabels.loanMonthMax' | translate }}:</b> {{ loanMonthMax }}</p>
                  <p class="m-0"><b>{{ 'PRODUCT.formLabels.loanMonthSteps' | translate }}:</b> {{ loanMonthSteps }}</p>
                  <p class="m-0"><b>{{ 'PRODUCT.formLabels.loanAmountMin' | translate }}:</b> {{ loanAmountMin | currency:'$' : 'symbol' : '1.0-0' }}</p>
                  <p class="m-0" *ngIf="loanAmountMaxLimit"><b>{{ 'PRODUCT.formLabels.loanAmountMax' | translate }}:</b> {{ loanAmountMax | currency:'$' : 'symbol' : '1.0-0' }}</p>
                  <p class="m-0" *ngIf="!loanAmountMaxLimit"><b>{{ 'PRODUCT.formLabels.loanAmountMax' | translate }}:</b> {{ loanAmountMax }} {{ 'PRODUCT.productLoanSalaryMsg' | translate }}</p>
                </div>
                <div class="col-6">
                  <p class="m-0"><b>{{ 'PRODUCT.formLabels.loanInterest' | translate }}:</b> {{ loanInterest }}%</p>
                  <p class="m-0" *ngIf="false"><b>{{ 'PRODUCT.formLabels.loanReferralMinimum' | translate }}:</b> {{ loanReferralMinimum }}</p> <!-- TODO: CONFIRM HOW REFERRALS WILL WORK -->
                  <p class="m-0"><b>{{ 'PRODUCT.formLabels.loanPaymentReceipts' | translate }}:</b> {{ loanPaymentReceipts }}</p>
                  <p class="m-0"><b>{{ 'PRODUCT.formLabels.borrowingCapacityPercentage' | translate }}:</b> {{ borrowingCapacityPercentage }}%</p>
                </div>
              </div>
            </div>
            <div class="bg-gradient">
              <app-feather-icons class="svg-feather" [icon]="'layers'"></app-feather-icons>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>