<!-- form -->
<form class="theme-form" [formGroup]="mainFrm">
  <!-- help -->
  <div class="row">
    <div class="col-12 pull-right">
      <div class="btn-with-outside-txt-lbl help-btn" (click)="help()">
        <small class="btn-inner-txt">{{ 'HELPERS.help' | translate }}</small> 
        <a class="btn btn-light btn-xs btn-icon-only btn-icon-text-left">
          <app-feather-icons [icon]="'help-circle'"></app-feather-icons>
        </a>
      </div>
    </div>
  </div>
  <!-- options -->
  <div class="row mt-4">
    <div class="col">
      <div class="step" (click)="setAddressType('home')">
        <a class="btn-step" [ngStyle]="addressType === 'home' ? {'background': '#FFCC11'} : {'background': '#DFDFDF'}">
          <app-feather-icons [icon]="'home'" [ngStyle]="addressType === 'home' ? {'color': '#FFFFFF'} : {'color': '#181818'}"></app-feather-icons>
        </a>
        <p class="step-txt">{{ 'LOCATION.formLabels.addressTypeHome' | translate }}</p>
      </div>
    </div>
    <div class="col">
      <div class="step" (click)="setAddressType('work')">
        <a class="btn-step" [ngStyle]="addressType === 'work' ? {'background': '#FFCC11'} : {'background': '#DFDFDF'}">
          <app-feather-icons [icon]="'coffee'" [ngStyle]="addressType === 'work' ? {'color': '#FFFFFF'} : {'color': '#181818'}"></app-feather-icons>
        </a>
        <p class="step-txt">{{ 'LOCATION.formLabels.addressTypeWork' | translate }}</p>
      </div>
    </div>
    <div class="col">
      <div class="step" (click)="setAddressType('other')">
        <a class="btn-step" [ngStyle]="addressType === 'other' ? {'background': '#FFCC11'} : {'background': '#DFDFDF'}">
          <app-feather-icons [icon]="'map'" [ngStyle]="addressType === 'other' ? {'color': '#FFFFFF'} : {'color': '#181818'}"></app-feather-icons>
        </a>
        <p class="step-txt">{{ 'LOCATION.formLabels.addressTypeOther' | translate }}</p>
      </div>
    </div>
  </div>
  <!-- instructions -->
  <div class="row">
    <div class="col">
      <p class="mb-0">{{ 'LOCATION.addressTypeInstructions' | translate }}</p>
      <p class="error-messages mt-0 mb-2" *ngIf="mainFrm.touched && addressType === null">
        <span class="text-danger error-msg">{{ 'LOCATION.formValidation.addressType.required' | translate }}</span>
      </p>
    </div>
  </div>
  <!-- data -->
  <div class="row mt-4">
    <!-- city -->
    <div class="col-12">
      <!-- label -->
      <div class="row p-0 m-0">
        <div class="col-12  p-0 m-0">
          <div class="form-group">
            <label class="col-form-label">
              <span *ngIf="countryName === null">{{ 'LOCATION.formLabels.countryStateCity' | translate }} <span class="required-lbl">*</span></span>
              <span *ngIf="countryName !== null">{{ countryName }}</span>
              <span *ngIf="stateName !== null"> - {{ stateName }}</span>
              <span *ngIf="cityName !== null"> - {{ cityName }}</span>
            </label>
          </div>
        </div>
      </div>
      <!-- country -->
      <div class="row" *ngIf="country === null">
        <div class="col-12">
          <div class="form-group location-selection">
            <ng-select [items]="allCountries"
              [selectOnTab]="true"
              bindLabel="countryName"
              bindValue="id"
              labelForId="countrySelect"
              [placeholder]="'LOCATION.formPlaceHolders.countrySelect' | translate"
              (change)="selectCountry()"
              #countrySelect id="countrySelect" name="countrySelect" formControlName="country">
            </ng-select>
          </div>
        </div>
      </div>
      <!-- state -->
      <div class="row" *ngIf="country != null && state === null">
        <div class="col-10">
          <div class="form-group location-selection">
            <ng-select [items]="allStates"
              [selectOnTab]="true"
              bindLabel="stateName"
              bindValue="id"
              labelForId="stateSelect"
              [placeholder]="'LOCATION.formPlaceHolders.stateSelect' | translate"
              (change)="selectState()"
              #stateSelect id="stateSelect" name="stateSelect" formControlName="state">
            </ng-select>
          </div>
        </div>
        <div class="col-2">
          <a class="btn btn-primary btn-icon-only btn-location-reset" (click)="resetLocationSelect()">
            <app-feather-icons [icon]="'refresh-cw'"></app-feather-icons>
          </a>
        </div>
      </div>
      <!-- city -->
      <div class="row" *ngIf="state != null">
        <div class="col-10">
          <div class="form-group location-selection">
            <ng-select [items]="allCities"
              [selectOnTab]="true"
              bindLabel="cityName"
              bindValue="id"
              labelForId="citySelect"
              [placeholder]="'LOCATION.formPlaceHolders.citySelect' | translate"
              (change)="selectCity()"
              #citySelect id="citySelect" name="citySelect" formControlName="city">
            </ng-select>
          </div>
        </div>
        <div class="col-2">
          <a class="btn btn-primary btn-icon-only btn-location-reset" (click)="resetLocationSelect()">
            <app-feather-icons [icon]="'refresh-cw'"></app-feather-icons>
          </a>
        </div>
      </div>
      <!-- error and help -->
      <div class="row p-0 m-0">
        <div class="col-12  p-0 m-0">
          <div class="form-group location-msgs">
            <ng-container class="error-messages" *ngFor="let error of formErrorMessages.city">
              <small class="text-danger error-msg" *ngIf="mainFrm.get('city').hasError(error.type) && (mainFrm.get('city').dirty || mainFrm.get('country').touched)">
                {{ error.message }}.&nbsp;
              </small>
            </ng-container>
            <p class="help-messages" *ngIf="toggleHelp">
              <small>{{ 'HELPERS.selectThe' | translate }}&nbsp;{{ 'LOCATION.formHelp.countryStateCity' | translate }}</small>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- address -->
    <div class="col-12">
      <div class="form-group">
        <label for="address">{{ 'LOCATION.formLabels.address' | translate }} <span class="required-lbl">*</span></label>
        <input class="form-control" #address id="address" type="text" inputmode="text" [placeholder]="'LOCATION.formPlaceHolders.address' | translate" formControlName="address" (keyup.enter)="setFocus(address2)">
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.address">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('address').hasError(error.type) && (mainFrm.get('address').dirty || mainFrm.get('address').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'LOCATION.formHelp.address' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- address2 -->
    <div class="col-12">
      <div class="form-group">
        <label for="address2">{{ 'LOCATION.formLabels.address2' | translate }}</label>
        <input class="form-control" #address2 id="address2" type="text" inputmode="text" [placeholder]="'LOCATION.formPlaceHolders.address2' | translate" formControlName="address2">
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'LOCATION.formHelp.address2' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- mailingAddress -->
    <div class="col-12">
      <div class="form-check form-switch mailingAddress-switch">
        <input #mailingAddress id="mailingAddress" type="checkbox" role="switch" formControlName="mailingAddress" class="form-check-input">
        <label class="form-check-label" for="mailingAddress">{{ 'LOCATION.mailingAddressInstructions' | translate }}</label>
      </div>
    </div>
  </div>
  <!-- buttons -->
  <div class="row mt-4">
    <div class="col">
      <div class="request-form-btns-container">
        <button class="btn btn-light d-block btn-cancel" (click)="cancel()" type="button">
          <span>{{ 'HELPERS.cancel' | translate }}</span>
        </button>
        <button class="btn btn-dark d-block" (click)="save()" type="button">
          <span>{{ 'HELPERS.save' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</form>
          