// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 03/06/2023
// tslint:disable: max-line-length
// tslint:disable: no-unused-expression
// imports
import { Component, Input, OnInit } from '@angular/core';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
// variables
declare var $: any;
declare var window: any;
// component
@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss'],
})
// class
export class ChartsComponent implements OnInit {
  // variables
  @Input() graphType: any;
  @Input() graphData: any;
  // chart data
  // valueMax: number = 0;
  chartType: ChartType = 'bar';
  chartLabels: any[] = [];
  chartData: any;
  chartOptions: any;
  chartColors: any[] = [];
  chartLegend = true;
  chartPlugins: any;
  // constructor
  constructor() {

  }
  // page life cycle
  async ngOnInit(){
    await this.initGraph();
  }
  // data
  async initGraph(){

    console.log('-- graphType', this.graphType);
    console.log('-- graphData', this.graphData);

    // check graph type
    switch (this.graphType) {
      case 'line':
        // this.lineGraph();
        break;
      case 'bar':
        this.barGraph();
        break;
      case 'bar-h':
        // this.barGraphHorizontal();
        break;
      case 'doughnut':
        // this.doughnutGraph();
        break;
      case 'radar':
        // this.radarGraph();
        break;
      case 'pie':
        // this.pieGraph();
        break;
      case 'bubble':
        // this.bubbleGraph();
        break;
    }
  }

  async barGraph() {
    // chart type
    this.chartType = 'bar';
    // chart labels
    this.chartLabels = this.graphData.chartLabels;
    // this.chartLabels = ['Apple', 'Banana', 'Kiwifruit'];
    // chart data
    const chartData: any[] = this.graphData.chartDataSet;

    // const chartData: ChartDataSets[]  = [
    //   { data: [45, 37, 60, 70, 46, 33], label: 'Best Fruits' }
    // ];
    // const chartData: ChartDataSets[]  = [
    //   {
    //       label: "Cotización",
    //       backgroundColor: "#9E419A",
    //       hoverBackgroundColor: "#9E419A",
    //       data: [10,20,50]
    //   },
    //   {
    //       label: "Protección",
    //       backgroundColor: "#FF5961",
    //       hoverBackgroundColor: "#FF5961",
    //       data: [40,20,5]
    //   },
    // ];
    // set chart data
    this.chartData = chartData;
    // chart options
    const chartOptions: any = {
      responsive: true,
      scales: {
        xAxes: [{
          ticks: {
            beginAtZero: false,
            stepSize: 1,
          }
        }],
        yAxes: [{
          ticks: {
            beginAtZero: true,
            stepSize: 1,
          }
        }]
      },
    };
    // set chart options
    this.chartOptions = chartOptions
    // chart colors
    
    // chart plugins
    this.chartPlugins = [];
    // chart legend
    this.chartLegend = true;
  }
}
