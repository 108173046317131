<!-- header -->
<div class="row m-0 pt-2">
  <div class="col-12">
    <div class="row">
      <div class="col-sm-8 col-xs-12">
        <h4>{{ 'LOAN.requestNumber' | translate }} - {{ loanNumber }}</h4>
      </div>
      <div class="col-sm-4 col-xs-12">
        <div class="page-pagination left-paginator pagination-theme">
          <nav aria-label="Pagination">
            <ul class="pagination">
              <!-- back arrow -->
              <li class="page-item" [ngClass]="{'disabled': previousLoan === null}">
                <a class="page-link" (click)="navigateTo(previousLoan)"  aria-label="Previous">
                  <span aria-hidden="true">
                    <i class="fa fa-chevron-left"></i>
                  </span>
                  <span class="sr-only">{{ 'HELPERS.previous' | translate }}</span>
                </a>
              </li>
              <!-- next arrow -->
              <li class="page-item" [ngClass]="{'disabled': nextLoan === null}">
                <a class="page-link" (click)="navigateTo(nextLoan)" aria-label="Next">
                  <span aria-hidden="true">
                    <i class="fa fa-chevron-right"></i>
                  </span>
                  <span class="sr-only">{{ 'HELPERS.next' | translate }}</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>

      </div>
    </div>
  </div>
  <div class="col-12">
    <h6 class="table-status-txt">
      <span class="status-started" *ngIf="lastStatus === 'started'">{{ 'LOAN.loanStatusStarted' | translate }}</span>
      <span class="status-sended" *ngIf="lastStatus === 'sended'">{{ 'LOAN.loanStatusSended' | translate }}</span>
      <span class="status-accepted" *ngIf="lastStatus === 'accepted'">{{ 'LOAN.loanStatusAccepted' | translate }}</span>
      <span class="status-noPassedValidation" *ngIf="lastStatus === 'noPassedValidation'">{{ 'LOAN.loanStatusNoPassedValidation' | translate }}</span>
      <span class="status-validated" *ngIf="lastStatus === 'validated'">{{ 'LOAN.loanStatusValidated' | translate }}</span>
      <span class="status-rejected" *ngIf="lastStatus === 'rejected'">{{ 'LOAN.loanStatusRejected' | translate }}</span>
      <span class="status-preApproved" *ngIf="lastStatus === 'preApproved'">{{ 'LOAN.loanStatusPreApproved' | translate }}</span>
      <span class="status-readyToApprove" *ngIf="lastStatus === 'readyToApprove'">{{ 'LOAN.loanStatusReadyToApprove' | translate }}</span>
      <span class="status-approved" *ngIf="lastStatus === 'approved'">{{ 'LOAN.loanStatusApproved' | translate }}</span>
      <span class="status-readyForDisbursement" *ngIf="lastStatus === 'readyForDisbursement'">{{ 'LOAN.loanStatusReadyForDisbursement' | translate }}</span>
      <span class="status-disbursementOnProgress" *ngIf="lastStatus === 'disbursementOnProgress'">{{ 'LOAN.loanStatusDisbursementOnProgress' | translate }}</span>
      <span class="status-disbursed" *ngIf="lastStatus === 'disbursed'">{{ 'LOAN.loanStatusDisbursed' | translate }}</span>
      <span class="status-payed" *ngIf="lastStatus === 'payed'">{{ 'LOAN.loanStatusPayed' | translate }}</span>
      <span class="status-preLegal" *ngIf="lastStatus === 'preLegal'">{{ 'LOAN.loanStatusPreLegal' | translate }}</span>
      <span class="status-legal" *ngIf="lastStatus === 'legal'">{{ 'LOAN.loanStatusLegal' | translate }}</span>
      <span class="status-negotiation" *ngIf="lastStatus === 'negotiation'">{{ 'LOAN.loanStatusNegotiation' | translate }}</span>
      <span class="status-expired" *ngIf="lastStatus === 'expired'">{{ 'LOAN.loanStatusExpired' | translate }}</span>
      <span class="status-canceled" *ngIf="lastStatus === 'canceled'">{{ 'LOAN.loanStatusCanceled' | translate }}</span>
    </h6>
  </div>
  <div class="col-12">
    <h6 class="work-type-title">
      <span *ngIf="workType === 'business'">{{ 'USERWORKS.formLabels.workTypeBusiness' | translate }}</span>
      <span *ngIf="workType === 'employee'">{{ 'USERWORKS.formLabels.workTypeEmployee' | translate }}</span>
      <span *ngIf="workType === 'entrepreneur'">{{ 'USERWORKS.formLabels.workTypeEntrepreneur' | translate }}</span>
      <span *ngIf="workType === 'home'">{{ 'USERWORKS.formLabels.workTypeHome' | translate }}</span>
      <span *ngIf="workType === 'student'">{{ 'USERWORKS.formLabels.workTypeStudent' | translate }}</span>
      <span *ngIf="workType === 'pensioner'">{{ 'USERWORKS.formLabels.workTypePensioner' | translate }}</span>
    </h6>
  </div>
</div>
<!-- tabs -->
<div class="row m-0 pt-4">
  <!-- request -->
  <div class="col" *ngIf="!isCompany || (isCompany && lastStatusNumber >= 8)">
    <div class="step" (click)="selectTab('request')">
      <a class="btn-step" [ngStyle]="currentTab === 'request' ? {'background': '#FFCC11'} : {'background': '#DFDFDF'}">
        <app-feather-icons [icon]="'sunrise'" [ngStyle]="currentTab === 'request' ? {'color': '#181818'} : {'color': '#181818'}"></app-feather-icons>
      </a>
      <p class="step-txt">{{ 'LOAN.loan' | translate }}</p>
    </div>
  </div>
  <!-- user -->
  <div class="col" *ngIf="!isCompany">
    <div class="step" (click)="selectTab('user')">
      <a class="btn-step" [ngStyle]="currentTab === 'user' ? {'background': '#FFCC11'} : {'background': '#DFDFDF'}">
        <app-feather-icons [icon]="'user'" [ngStyle]="currentTab === 'user' ? {'color': '#181818'} : {'color': '#181818'}"></app-feather-icons>
      </a>
      <p class="step-txt">{{ 'LOAN.user' | translate }}</p>
    </div>
  </div>
  <!-- work -->
  <div class="col" *ngIf="!isCompany">
    <div class="step" (click)="selectTab('work')">
      <a class="btn-step" [ngStyle]="currentTab === 'work' ? {'background': '#FFCC11'} : {'background': '#DFDFDF'}">
        <app-feather-icons [icon]="'briefcase'" [ngStyle]="currentTab === 'work' ? {'color': '#181818'} : {'color': '#181818'}"></app-feather-icons>
      </a>
      <p class="step-txt">{{ 'LOAN.work' | translate }}</p>
    </div>
  </div>
  <!-- financial -->
  <div class="col" *ngIf="!isCompany">
    <div class="step" (click)="selectTab('financial')">
      <a class="btn-step" [ngStyle]="currentTab === 'financial' ? {'background': '#FFCC11'} : {'background': '#DFDFDF'}">
        <app-feather-icons [icon]="'trending-up'" [ngStyle]="currentTab === 'financial' ? {'color': '#181818'} : {'color': '#181818'}"></app-feather-icons>
      </a>
      <p class="step-txt">{{ 'LOAN.financial' | translate }}</p>
    </div>
  </div>
  <!-- validation -->
  <div class="col" *ngIf="lastStatusNumber >= 4 && !isCompany">
    <div class="step" (click)="selectTab('validation')">
      <a class="btn-step" [ngStyle]="currentTab === 'validation' ? {'background': '#FFCC11'} : {'background': '#DFDFDF'}">
        <app-feather-icons [icon]="'check-circle'" [ngStyle]="currentTab === 'validation' ? {'color': '#181818'} : {'color': '#181818'}"></app-feather-icons>
      </a>
      <p class="step-txt">{{ 'LOAN.validation' | translate }}</p>
    </div>
  </div>
  <!-- documents -->
  <div class="col"  *ngIf="lastStatusNumber > 1 && !isCompany">
    <div class="step" (click)="selectTab('documents')">
      <a class="btn-step" [ngStyle]="currentTab === 'documents' ? {'background': '#FFCC11'} : {'background': '#DFDFDF'}">
        <app-feather-icons [icon]="'file'" [ngStyle]="currentTab === 'documents' ? {'color': '#181818'} : {'color': '#181818'}"></app-feather-icons>
      </a>
      <p class="step-txt">{{ 'LOANDOCUMENTS.loanDocumentsShort' | translate }}</p>
    </div>
  </div>
  <!-- payments -->
  <div class="col" *ngIf="lastStatusNumber >= 8">
    <div class="step" (click)="selectTab('payments')">
      <a class="btn-step" [ngStyle]="currentTab === 'payments' ? {'background': '#FFCC11'} : {'background': '#DFDFDF'}">
        <app-feather-icons [icon]="'dollar-sign'" [ngStyle]="currentTab === 'payments' ? {'color': '#181818'} : {'color': '#181818'}"></app-feather-icons>
      </a>
      <p class="step-txt">{{ 'LOAN.payments' | translate }}</p>
    </div>
  </div>
</div>
<!-- content -->
<div class="row m-0 pt-2" id="loanCardSection">
  <!-- request -->
  <div class="col-12" *ngIf="currentTab === 'request'">
    <!-- product -->
    <!-- ------------------------------------------------------------- -->
    <div id="product-section">
      <!-- product -->
      <div class="row mt-4 mb-0" *ngIf="loanInsuranceIds === null">
        <div class="col-12">
          <h4 class="request-title">{{ 'PRODUCT.product' | translate }}</h4>
          <h6 class="request-subtitle product-name">{{ productName }}</h6>
        </div>
      </div>
      <!-- product insurance -->
      <div class="row mt-4 mb-0" *ngIf="loanInsuranceIds !== null">
        <div class="col-md-7 col-sm-12">
          <h4 class="request-title">{{ 'PRODUCT.product' | translate }}</h4>
          <h6 class="request-subtitle product-name">{{ productName }}</h6>
        </div>
        <div class="col-md-5 col-sm-12">
          <h6 class="request-subtitle">{{ 'LOAN.loanInsuranceIds' | translate }}</h6>
          <p>{{ loanInsuranceIds }}</p>
        </div>
      </div>
      <!-- divider -->
      <hr>
    </div>
    <!-- request -->
    <!-- ------------------------------------------------------------- -->
    <div id="request-section">
      <!-- request -->
      <div class="row mt-4 mb-0">
        <!-- title -->
        <div class="col-12">
          <h4 class="request-title">{{ 'LOAN.requestClient' | translate }}</h4>
        </div>
        <!-- loanAmount -->
        <div class="col-md-9 col-sm-12">
          <label class="amount-lbl">{{ 'LOAN.loanAmount' | translate }}</label>
          <div class="percentage-resume">
            <span class="current-value amount-value">{{ loanAmount | currency:'$' : 'symbol' : '1.0-0' }}</span>
          </div>
        </div>
        <!-- loanPaymentMonths -->
        <div class="col-md-3 col-sm-12">
          <label class="monts-lbl">{{ 'LOAN.loanMonths' | translate }}</label>
          <div class="percentage-resume">
            <span class="current-value">{{ loanPaymentMonths }}</span>
          </div>
        </div>
      </div>
      <!-- divider -->
      <hr>
    </div>
    <!-- initial-documents -->
    <!-- ------------------------------------------------------------- -->
    <div id="initial-documents-section" class="fileUploadWrapper" *ngIf="lastStatusNumber === 0 || lastStatusNumber === 1">
      <div class="row mt-4 mb-0">
        <!-- header -->
        <div class="col-12">
          <h4 class="request-title">{{ 'LOAN.loanInitialDocuments' | translate }}</h4>
          <p>{{ 'LOAN.loanInitialDocumentsMsg' | translate }}</p>
        </div>
        <!-- resend email -->
        <div class="col-12 mt-2" *ngIf="enableSendInitialDocumentsData">
          <button class="btn btn-xs btn-dark custom-value-btn" (click)="sendFirstSteps()">{{ 'LOAN.loanInitialDocumentsResendEmail' | translate }}</button>
        </div>
        <!-- add other document -->
        <div class="col-12 mt-2" *ngIf="enableAddOtherDocumentsData">
          <button class="btn btn-xs btn-dark custom-value-btn" (click)="addOtherDocument()">{{ 'LOAN.loanDocumentAddOtherDocument' | translate }}</button>
        </div>
        <!-- business documents -->
        <app-loan-documents 
          *ngIf="workType === 'business'"
          [userId]="userId"
          [loanId]="loanId"
          [target]="'loan'"
          [idFrontFileData]="idFrontFileData"
          [idFrontFileShow]="true"
          [idFrontFileEdit]="enableEditInitialDocumentsData"
          [idBackFileData]="idBackFileData"
          [idBackFileShow]="true"
          [idBackFileEdit]="enableEditInitialDocumentsData"
          [bankFileData]="bankFileData"
          [bankFileShow]="true"
          [bankFileEdit]="enableEditInitialDocumentsData"
          [repIdFileData]="repIdFileData"
          [repIdFileShow]="true"
          [repIdFileEdit]="enableEditInitialDocumentsData"
          [rutFileData]="rutFileData"
          [rutFileShow]="true"
          [rutFileEdit]="enableEditInitialDocumentsData"
          [ccioFileData]="ccioFileData"
          [ccioFileShow]="true"
          [ccioFileEdit]="enableEditInitialDocumentsData"
          [pgFileData]="pgFileData"
          [pgFileShow]="true"
          [pgFileEdit]="enableEditInitialDocumentsData"
          [bankExtractsFileData]="bankExtractsFileData"
          [bankExtractsFileShow]="true"
          [bankExtractsFileEdit]="enableEditInitialDocumentsData"
          [otherDocument1FileData]="otherDocument1FileData"
          [otherDocument1FileShow]="showOtherDocumentFile1"
          [otherDocument1FileEdit]="enableEditInitialDocumentsData"
          [otherDocument2FileData]="otherDocument2FileData"
          [otherDocument2FileShow]="showOtherDocumentFile2"
          [otherDocument2FileEdit]="enableEditInitialDocumentsData"
          [otherDocument3FileData]="otherDocument3FileData"
          [otherDocument3FileShow]="showOtherDocumentFile3"
          [otherDocument3FileEdit]="enableEditInitialDocumentsData"
          (actionEvent)="fileAction($event)"
          (loaderEvent)="updateLoader($event)">
        </app-loan-documents>
        <!-- employee documents -->
        <app-loan-documents 
          *ngIf="workType === 'employee'"
          [userId]="userId"
          [loanId]="loanId"
          [target]="'loan'"
          [idFrontFileData]="idFrontFileData"
          [idFrontFileShow]="true"
          [idFrontFileEdit]="enableEditInitialDocumentsData"
          [idBackFileData]="idBackFileData"
          [idBackFileShow]="true"
          [idBackFileEdit]="enableEditInitialDocumentsData"
          [bankFileData]="bankFileData"
          [bankFileShow]="true"
          [bankFileEdit]="enableEditInitialDocumentsData"
          [laborFileData]="laborFileData"
          [laborFileShow]="true"
          [laborFileEdit]="enableEditInitialDocumentsData"
          [paymentReceiptFiles]="paymentReceiptFiles"
          [paymentReceiptShow]="true"
          [paymentReceiptEdit]="enableEditInitialDocumentsData"
          [otherDocument1FileData]="otherDocument1FileData"
          [otherDocument1FileShow]="showOtherDocumentFile1"
          [otherDocument1FileEdit]="enableEditInitialDocumentsData"
          [otherDocument2FileData]="otherDocument2FileData"
          [otherDocument2FileShow]="showOtherDocumentFile2"
          [otherDocument2FileEdit]="enableEditInitialDocumentsData"
          [otherDocument3FileData]="otherDocument3FileData"
          [otherDocument3FileShow]="showOtherDocumentFile3"
          [otherDocument3FileEdit]="enableEditInitialDocumentsData"
          (actionEvent)="fileAction($event)"
          (loaderEvent)="updateLoader($event)">
        </app-loan-documents>
        <!-- entrepreneur documents -->
        <app-loan-documents 
          *ngIf="workType === 'entrepreneur'"
          [userId]="userId"
          [loanId]="loanId"
          [target]="'loan'"
          [idFrontFileData]="idFrontFileData"
          [idFrontFileShow]="true"
          [idFrontFileEdit]="enableEditInitialDocumentsData"
          [idBackFileData]="idBackFileData"
          [idBackFileShow]="true"
          [idBackFileEdit]="enableEditInitialDocumentsData"
          [bankFileData]="bankFileData"
          [bankFileShow]="true"
          [bankFileEdit]="enableEditInitialDocumentsData"
          [rutFileData]="rutFileData"
          [rutFileShow]="true"
          [rutFileEdit]="enableEditInitialDocumentsData"
          [ccioFileData]="ccioFileData"
          [ccioFileShow]="true"
          [ccioFileEdit]="enableEditInitialDocumentsData"
          [bankExtractsFileData]="bankExtractsFileData"
          [bankExtractsFileShow]="true"
          [bankExtractsFileEdit]="enableEditInitialDocumentsData"
          [pgFileData]="pgFileData"
          [pgFileShow]="true"
          [pgFileEdit]="enableEditInitialDocumentsData"
          [utilitiesFileData]="utilitiesFileData"
          [utilitiesFileShow]="true"
          [utilitiesFileEdit]="enableEditInitialDocumentsData"
          [otherDocument1FileEdit]="enableEditInitialDocumentsData"
          [otherDocument1FileData]="otherDocument1FileData"
          [otherDocument1FileShow]="showOtherDocumentFile1"
          [otherDocument2FileData]="otherDocument2FileData"
          [otherDocument2FileShow]="showOtherDocumentFile2"
          [otherDocument2FileEdit]="enableEditInitialDocumentsData"
          [otherDocument3FileData]="otherDocument3FileData"
          [otherDocument3FileShow]="showOtherDocumentFile3"
          [otherDocument3FileEdit]="enableEditInitialDocumentsData"
          (actionEvent)="fileAction($event)"
          (loaderEvent)="updateLoader($event)">
        </app-loan-documents>
        <!-- home documents -->
        <app-loan-documents 
          *ngIf="workType === 'home'"
          [userId]="userId"
          [loanId]="loanId"
          [target]="'loan'"
          [idFrontFileData]="idFrontFileData"
          [idFrontFileEdit]="enableEditInitialDocumentsData"
          [idFrontFileShow]="true"
          [idBackFileData]="idBackFileData"
          [idBackFileShow]="true"
          [idBackFileEdit]="enableEditInitialDocumentsData"
          [bankFileData]="bankFileData"
          [bankFileShow]="true"
          [bankFileEdit]="enableEditInitialDocumentsData"
          [bankExtractsFileData]="bankExtractsFileData"
          [bankExtractsFileShow]="true"
          [bankExtractsFileEdit]="enableEditInitialDocumentsData"
          [utilitiesFileData]="utilitiesFileData"
          [utilitiesFileShow]="true"
          [utilitiesFileEdit]="enableEditInitialDocumentsData"
          [otherDocument1FileEdit]="enableEditInitialDocumentsData"
          [otherDocument1FileData]="otherDocument1FileData"
          [otherDocument1FileShow]="showOtherDocumentFile1"
          [otherDocument2FileData]="otherDocument2FileData"
          [otherDocument2FileShow]="showOtherDocumentFile2"
          [otherDocument2FileEdit]="enableEditInitialDocumentsData"
          [otherDocument3FileData]="otherDocument3FileData"
          [otherDocument3FileShow]="showOtherDocumentFile3"
          [otherDocument3FileEdit]="enableEditInitialDocumentsData"
          (actionEvent)="fileAction($event)"
          (loaderEvent)="updateLoader($event)">
        </app-loan-documents>
        <!-- student documents -->
        <app-loan-documents 
          *ngIf="workType === 'student'"
          [userId]="userId"
          [loanId]="loanId"
          [target]="'loan'"
          [editEnable]="true"
          [idFrontFileData]="idFrontFileData"
          [idFrontFileShow]="true"
          [idFrontFileEdit]="enableEditInitialDocumentsData"
          [idBackFileData]="idBackFileData"
          [idBackFileShow]="true"
          [idBackFileEdit]="enableEditInitialDocumentsData"
          [bankFileData]="bankFileData"
          [bankFileShow]="true"
          [bankFileEdit]="enableEditInitialDocumentsData"
          [bankExtractsFileData]="bankExtractsFileData"
          [bankExtractsFileShow]="true"
          [bankExtractsFileEdit]="enableEditInitialDocumentsData"
          [utilitiesFileData]="utilitiesFileData"
          [utilitiesFileShow]="true"
          [utilitiesFileEdit]="enableEditInitialDocumentsData"
          [otherDocument1FileData]="otherDocument1FileData"
          [otherDocument1FileShow]="showOtherDocumentFile1"
          [otherDocument1FileEdit]="enableEditInitialDocumentsData"
          [otherDocument2FileData]="otherDocument2FileData"
          [otherDocument2FileShow]="showOtherDocumentFile2"
          [otherDocument2FileEdit]="enableEditInitialDocumentsData"
          [otherDocument3FileData]="otherDocument3FileData"
          [otherDocument3FileShow]="showOtherDocumentFile3"
          [otherDocument3FileEdit]="enableEditInitialDocumentsData"
          (actionEvent)="fileAction($event)"
          (loaderEvent)="updateLoader($event)">
        </app-loan-documents>
        <!-- pensioner documents -->
        <app-loan-documents 
          *ngIf="workType === 'pensioner'"
          [userId]="userId"
          [loanId]="loanId"
          [target]="'loan'"
          [editEnable]="true"
          [idFrontFileData]="idFrontFileData"
          [idFrontFileShow]="true"
          [idFrontFileEdit]="enableEditInitialDocumentsData"
          [idBackFileData]="idBackFileData"
          [idBackFileShow]="true"
          [idBackFileEdit]="enableEditInitialDocumentsData"
          [bankFileData]="bankFileData"
          [bankFileShow]="true"
          [bankFileEdit]="enableEditInitialDocumentsData"
          [pensionerFileData]="pensionerFileData"
          [pensionerFileShow]="true"
          [pensionerFileEdit]="enableEditInitialDocumentsData"
          [otherDocument1FileData]="otherDocument1FileData"
          [otherDocument1FileShow]="showOtherDocumentFile1"
          [otherDocument1FileEdit]="enableEditInitialDocumentsData"
          [otherDocument2FileData]="otherDocument2FileData"
          [otherDocument2FileShow]="showOtherDocumentFile2"
          [otherDocument2FileEdit]="enableEditInitialDocumentsData"
          [otherDocument3FileData]="otherDocument3FileData"
          [otherDocument3FileShow]="showOtherDocumentFile3"
          [otherDocument3FileEdit]="enableEditInitialDocumentsData"
          (actionEvent)="fileAction($event)"
          (loaderEvent)="updateLoader($event)">
        </app-loan-documents>
      </div>
      <!-- divider -->
      <hr>
    </div>
    <!-- validation -->
    <!-- ------------------------------------------------------------- -->
    <div id="validation-section" class="fileUploadWrapper" *ngIf="lastStatusNumber === 2">
      <div class="row mt-4 mb-0">
        <!-- header -->
        <div class="col-12">
          <h4 class="request-title">{{ 'LOAN.loanValidations' | translate }}</h4>
          <p>{{ 'LOAN.loanValidationsMsg' | translate }}</p>
        </div>
        <!-- documents -->
        <app-loan-documents 
          [userId]="userId"
          [loanId]="loanId"
          [target]="'loan'"
          [synthesisFileData]="synthesisFileData"
          [synthesisFileShow]="true"
          [synthesisFileEdit]="editEnableSynthesisFileData === true"
          (actionEvent)="fileAction($event)"
          (loaderEvent)="updateLoader($event)">
        </app-loan-documents>
        <!-- scoreFrm -->
        <form class="theme-form" [formGroup]="scoreFrm" *ngIf="enableEditValidationData === true">
          <!-- score -->
          <div class="row mt-1" *ngIf="!customScore">
            <!-- value -->
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <label class="amount-lbl" for="score">{{ 'LOAN.loanScoreCredit' | translate }} <span class="required-lbl">*</span></label>
              <div class="percentage-resume">
                <span class="current-value amount-value">{{ scoreFrm.controls.score.value }}</span>
              </div>
            </div>
            <!-- range -->
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div class="form-group range-field">
                <input id="score" formControlName="score" type="range" step="1" min="150" max="950">
                <ng-container class="error-messages error-range" *ngFor="let error of formErrorMessages.score">
                  <small class="text-danger" *ngIf="scoreFrm.get('score').hasError(error.type) && (scoreFrm.get('score').dirty || scoreFrm.get('score').touched)">
                    {{ error.message }}.&nbsp;
                  </small>
                </ng-container>
                <p class="help-messages helper-range" *ngIf="toggleHelp">
                  <small>{{ 'LOAN.loanScoreHelp' | translate }}</small>
                </p>
              </div>
            </div>
            <!-- custom button -->
            <div class="col-12">
              <button class="btn btn-xs btn-dark custom-value-btn" (click)="toggleCustomScore()">{{ 'LOAN.loanScoreCustom' | translate }}</button>
            </div>
          </div>
          <!-- custom score -->
          <div class="row mt-1" *ngIf="customScore">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="form-group mt-4">
                <label for="score">{{ 'LOAN.loanScoreCredit' | translate }} <span class="required-lbl">*</span></label>
                <input class="form-control" #score id="score" type="number" inputmode="numeric" formControlName="score" min="150" max="950">
                <ng-container class="error-messages" *ngFor="let error of formErrorMessages.score">
                  <small class="text-danger error-msg" *ngIf="scoreFrm.get('score').hasError(error.type) && (scoreFrm.get('score').dirty || scoreFrm.get('score').touched)">
                    {{ error.message }}.&nbsp;
                  </small>
                </ng-container>
                <p class="help-messages" *ngIf="toggleHelp">
                  <small>{{ 'HELPERS.rangeHelp' | translate }}</small>
                </p>
              </div>
              <button class="btn btn-xs btn-dark custom-value-btn" (click)="toggleCustomScore()">{{ 'LOAN.loanScoreSave' | translate }}</button>
            </div>
          </div>
        </form>
      </div>
      <!-- divider -->
      <hr>
    </div>
    <!-- financials -->
    <!-- ------------------------------------------------------------- -->
    <div id="financials-section" *ngIf="lastStatus === 'validated'">
      <app-user-financial-card [financialData]="financialData" [showFamilyInfo]="true"></app-user-financial-card>
      <!-- divider -->
      <hr>
    </div>
    <!-- score -->
    <!-- ------------------------------------------------------------- -->
    <div id="score-section" *ngIf="lastStatusNumber >= 4 && lastStatus !== 'readyForDisbursement'">
      <!-- score value -->
      <div class="row mt-4 mb-0">
        <!-- header -->
        <div class="col-md-7 col-sm-12">
          <h4 class="request-title mt-2">{{ 'LOAN.loanScoreCredit' | translate }}</h4>
        </div>
        <!-- score -->
        <div class="col-md-5 col-sm-12 text-center">
          <!-- value -->
          <div class="score-container" [class]="scoreClass">
            <span class="current-value amount-value">{{ score | number }}</span>
          </div>
        </div>
        <!-- add detailedValidation -->
        <div class="col-12" *ngIf="editEnableDetailedFileData">
          <button class="btn btn-xs btn-dark custom-value-btn" (click)="addDetailValidation()">{{ 'LOAN.loanDocumentDetailedRequest' | translate }}</button>
        </div>
      </div>
      <!-- additional score -->
      <div class="row" *ngIf="lastStatus === 'validated'">
        <!-- documents -->
        <app-loan-documents 
          [userId]="userId"
          [loanId]="loanId"
          [target]="'loan'"
          [synthesisFileData]="synthesisFileData"
          [synthesisFileShow]="true"
          [synthesisFileEdit]="editEnableSynthesisFileData"
          [detailedFileData]="detailedFileData"
          [detailedFileShow]="loanDetailedValidation"
          [detailedFileEdit]="editEnableDetailedFileData"
          (actionEvent)="fileAction($event)"
          (loaderEvent)="updateLoader($event)">
        </app-loan-documents>
      </div>
      <!-- divider -->
      <hr>
    </div>
    <!-- borrowing-capacity -->
    <!-- ------------------------------------------------------------- -->
    <div id="borrowing-capacity-section" *ngIf="lastStatusNumber >= 4 && lastStatus !== 'readyForDisbursement'">
      <!-- borrowingCapacityFrm -->
      <form class="theme-form" [formGroup]="borrowingCapacityFrm">
        <!-- borrowingCapacity -->
        <div class="row mt-4">
          <!-- value -->
          <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12">
            <h4 class="request-title mt-2 mb-2">{{ 'LOAN.loanBorrowingCapacity' | translate }}</h4>
            <div class="percentage-resume">
              <span class="current-value amount-value">{{ borrowingCapacity | currency:'$' : 'symbol' : '1.0-0' }} <span class="amount-percentage">({{ borrowingCapacityPercentage | number }}%)</span></span>
            </div>
          </div>
          <!-- range -->
          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12" *ngIf="enableEditBorrowingCapacityData">
            <div class="form-group range-field mt-2">
              <input id="borrowingCapacity" formControlName="borrowingCapacity" type="range" step="1" min="1" max="100" disabled="!enableEditBorrowingCapacityData">
              <ng-container class="error-messages error-range" *ngFor="let error of formErrorMessages.borrowingCapacity">
                <small class="text-danger" *ngIf="borrowingCapacityFrm.get('borrowingCapacity').hasError(error.type) && (borrowingCapacityFrm.get('borrowingCapacity').dirty || borrowingCapacityFrm.get('borrowingCapacity').touched)">
                  {{ error.message }}.&nbsp;
                </small>
              </ng-container>
              <p class="help-messages helper-range" *ngIf="toggleHelp">
                <small>{{ 'LOAN.loanBorrowingCapacityHelp' | translate }}</small>
              </p>
            </div>
          </div>
        </div>
      </form>
      <!-- divider -->
      <hr>
    </div>
    <!-- singlePayment / loanInterest -->
    <!-- TODO: CHECK NGIF -->
    <!-- <div *ngIf="lastStatusNumber >= 6 && !(lastStatus === 'preApproved' || lastStatus === 'readyForDisbursement')"> -->
    <div *ngIf="lastStatusNumber >= 6 && !(lastStatus === 'readyForDisbursement')">
      <div class="row">
        <!-- singlePayment -->
        <div class="col-sm-6 col-xs-12">
          <h4 class="request-title mt-2 mb-2">{{ 'LOAN.loanSinglePaymentMonth' | translate }}</h4>
          <div class="percentage-resume">
            <span class="current-value amount-value">{{ singlePayment | currency:'$' : 'symbol' : '1.0-0' }}</span>
          </div>
        </div>
        <!-- loanInterest -->
        <div class="col-sm-6 col-xs-12">
          <h4 class="request-title mt-2 mb-2">{{ 'LOAN.loanInterestMonthly' | translate }}</h4>
          <div class="percentage-resume">
            <span class="current-value amount-value">{{ loanInterest | number: '1.0-2' }}%</span>
          </div>
        </div>
      </div>
      <!-- divider -->
      <hr>
    </div>
    <!-- pre-approval -->
    <!-- ------------------------------------------------------------- -->
    <!-- TODO: TO ENABLE CALC INTEREST -->
    <div id="pre-approval-section" *ngIf="enableEditPreApprovalData">
    <!-- <div id="pre-approval-section"> -->
      <!-- header -->
      <div class="row">
        <div class="col">
          <h4 class="request-title">{{ 'LOAN.preApprove' | translate }}</h4>
        </div>
      </div>
      <!-- preApprovalFrm -->
      <form class="theme-form" [formGroup]="preApprovalFrm">
        <!-- insuranceIds -->
        <div *ngIf="loanInsuranceIds !== null">
          <div class="row mt-4" >
            <div class="col-6">
              <h4 class="request-title">{{ 'LOAN.loanInsurances' | translate }}</h4>
              <p>{{ 'LOAN.loanInsuranceIdsHelp' | translate }}</p>
            </div>
            <div class="col-6">
              <div class="form-group mt-2">
                <label for="loanInsuranceIdsInput">{{ 'LOAN.loanInsuranceIds' | translate }}</label>
                <input class="form-control" #loanInsuranceIdsInput id="loanInsuranceIdsInput" type="text" inputmode="text" formControlName="loanInsuranceIds" [placeholder]="'LOAN.loanInsuranceIdsPlaceHolder' | translate">
                <p class="help-messages" *ngIf="toggleHelp">
                  <small>{{ 'LOAN.loanInsuranceIdsHelp' | translate }}</small>
                </p>
              </div>
            </div>
          </div>
          <!-- divider -->
          <hr>
        </div>
        <!-- preAmount -->
        <div class="row mt-1" *ngIf="!amountCustom">
          <!-- value -->
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <label class="amount-lbl" for="preAmount">{{ 'LOAN.loanAmountPreApprove' | translate }} <span class="required-lbl">*</span></label>
            <div class="percentage-resume">
              <span class="current-value amount-value">{{ amount | currency:'$' : 'symbol' : '1.0-0' }}</span><br>
              <span>
                <small class="product-values">
                  <b>{{ 'PRODUCT.loanProductMonths' | translate }}:</b>&nbsp;
                  {{ productAmountMin | currency:'$' : 'symbol' : '1.0-0' }}&nbsp;-&nbsp;
                  <span *ngIf="productAmountMaxLimit">{{ productAmountMax | currency:'$' : 'symbol' : '1.0-0' }}</span>
                  <span *ngIf="!productAmountMaxLimit">{{ productAmountMax }} {{ 'PRODUCT.productLoanSalaryMsg' | translate }}</span>
                </small>
              </span>
            </div>
          </div>
          <!-- range -->
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div class="form-group range-field">
              <input id="preAmount" formControlName="preAmount" type="range" step="50000" [min]="amountMin" [max]="amountMax">
              <ng-container class="error-messages error-range" *ngFor="let error of formErrorMessages.preAmount">
                <small class="text-danger" *ngIf="preApprovalFrm.get('preAmount').hasError(error.type) && (preApprovalFrm.get('preAmount').dirty || preApprovalFrm.get('preAmount').touched)">
                  {{ error.message }}.&nbsp;
                </small>
              </ng-container>
              <p class="help-messages helper-range" *ngIf="toggleHelp">
                <small>{{ 'LOAN.loanAmountPreApproveHelp' | translate }}</small>
              </p>
            </div>
          </div>
          <!-- custom button -->
          <div class="col-12">
            <button class="btn btn-xs btn-dark custom-value-btn" (click)="toggleCustomPreAmount()">{{ 'LOAN.loanAmountPreApproveCustom' | translate }}</button>
          </div>
        </div>
        <!-- custom preAmount -->
        <div class="row mt-1" *ngIf="amountCustom">
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="form-group mt-4">
              <label for="preAmountInput">{{ 'LOAN.loanAmountPreApprove' | translate }} <span class="required-lbl">*</span></label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input class="form-control" #preAmountInput id="preAmountInput" type="text" inputmode="numeric" formControlName="preAmount" [min]="amountMin" [max]="amountMax" (change)="checkPreAmount()">
              </div>
              <ng-container class="error-messages" *ngFor="let error of formErrorMessages.preAmount">
                <small class="text-danger error-msg" *ngIf="preApprovalFrm.get('preAmount').hasError(error.type) && (preApprovalFrm.get('preAmount').dirty || preApprovalFrm.get('preAmount').touched)">
                  {{ error.message }}.&nbsp;
                </small>
              </ng-container>
              <ng-container class="error-messages" *ngIf="(preApprovalFrm.get('preAmount').dirty || preApprovalFrm.get('preAmount').touched) && amountMinError">
                <small class="text-danger error-msg">
                  {{ 'LOAN.loanPreApproveAmountMin' | translate }}&nbsp;{{ amountMin | currency:'$' : 'symbol' : '1.0-0' }}
                </small>
              </ng-container>
              <ng-container class="error-messages" *ngIf="(preApprovalFrm.get('preAmount').dirty || preApprovalFrm.get('preAmount').touched) && amountMaxError">
                <small class="text-danger error-msg">
                  {{ 'LOAN.loanPreApproveAmountMax' | translate }}&nbsp;{{ amountMax | currency:'$' : 'symbol' : '1.0-0' }}
                </small>
              </ng-container>
              <p class="help-messages" *ngIf="toggleHelp">
                <small>{{ 'LOAN.loanAmountPreApproveHelp' | translate }}</small>
              </p>
            </div>
            <button class="btn btn-xs btn-dark custom-value-btn" (click)="toggleCustomPreAmount()">{{ 'LOAN.loanAmountPreApproveCustomSave' | translate }}</button>
          </div>
        </div>
        <!-- preMonths -->
        <div class="row mt-4" *ngIf="!monthsCustom">
          <!-- value -->
          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
            <label class="monts-lbl" for="preMonths">{{ 'LOAN.loanMonthsPreApprove' | translate }} <span class="required-lbl">*</span></label>
            <div class="percentage-resume">
              <span class="current-value">{{ preApprovalFrm.controls.preMonths.value }}</span><br>
              <span><small class="product-values"><b>{{ 'PRODUCT.loanProductMonths' | translate }}:</b> {{ productMonthMin }} - {{ productMonthMax }} </small></span>
            </div>
          </div>
          <!-- range -->
          <div class="col-xl-9 col-lg-8 col-md-6 col-sm-12">
            <div class="form-group range-field">
              <input id="preMonths" formControlName="preMonths" type="range" [step]="monthSteps" [min]="monthMin" [max]="monthMax">
              <ng-container class="error-messages error-range" *ngFor="let error of formErrorMessages.preMonths">
                <small class="text-danger" *ngIf="preApprovalFrm.get('preMonths').hasError(error.type) && (preApprovalFrm.get('preMonths').dirty || preApprovalFrm.get('preMonths').touched)">
                  {{ error.message }}.&nbsp;
                </small>
              </ng-container>
              <p class="help-messages helper-range" *ngIf="toggleHelp">
                <small>{{ 'LOAN.loanMonthsPreApproveHelp' | translate }}</small>
              </p>
            </div>
          </div>
          <!-- custom button -->
          <div class="col-12">
            <button class="btn btn-xs btn-dark custom-value-btn" (click)="toggleCustomMonths('preApprovalFrm')">{{ 'LOAN.loanMonthsPreApproveCustom' | translate }}</button>
          </div>
        </div>
        <!-- custom preMonths -->
        <div class="row mt-4" *ngIf="monthsCustom">
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="form-group mt-4">
              <label for="preMonthsInput">{{ 'LOAN.requestMonths' | translate }} <span class="required-lbl">*</span></label>
              <input class="form-control" #preMonthsInput id="preMonthsInput" type="number" inputmode="numeric" formControlName="preMonths" (change)="checkPreMonths()">
              <ng-container class="error-messages" *ngFor="let error of formErrorMessages.preMonths">
                <small class="text-danger error-msg" *ngIf="preApprovalFrm.get('preMonths').hasError(error.type) && (preApprovalFrm.get('preMonths').dirty || preApprovalFrm.get('preMonths').touched)">
                  {{ error.message }}.&nbsp;
                </small>
              </ng-container>
              <ng-container class="error-messages" *ngIf="(preApprovalFrm.get('preMonths').dirty || preApprovalFrm.get('preMonths').touched) && monthsMinError">
                <small class="text-danger error-msg">
                  {{ 'LOAN.requestMonthsMin' | translate }}&nbsp;{{ monthsMin }}&nbsp;{{ 'HELPERS.months' | translate }}
                </small>
              </ng-container>
              <ng-container class="error-messages" *ngIf="(preApprovalFrm.get('preMonths').dirty || preApprovalFrm.get('preMonths').touched) && monthsMaxError">
                <small class="text-danger error-msg">
                  {{ 'LOAN.requestMonthsMax' | translate }}&nbsp;{{ monthsMax }}&nbsp;{{ 'HELPERS.months' | translate }}
                </small>
              </ng-container>
              <p class="help-messages" *ngIf="toggleHelp">
                <small>{{ 'LOAN.requestMonthsHelp' | translate }}</small>
              </p>
            </div>
            <button class="btn btn-xs btn-dark custom-value-btn" (click)="toggleCustomMonths('preApprovalFrm')">{{ 'LOAN.loanMonthsPreApproveSave' | translate }}</button>
          </div>
        </div>
      </form>
      <!-- divider -->
      <hr>
      <!-- calcInterestFrm -->
      <form class="theme-form" [formGroup]="calcInterestFrm">
        <div class="row mt-4">
          <!-- loanInterest -->
          <div class="form-group col-lg-8 col-md-8 col-sm-12 mb-0">
            <label for="loanInterest">{{ 'LOAN.loanInterestMonthly' | translate }} <span class="required-lbl">*</span></label>
            <div class="input-group">
              <input class="form-control" #loanInterest id="loanInterest" type="number" inputmode="numeric" formControlName="loanInterest" min="0" max="100" placeholder="0">
              <div class="input-group-append">
                <span class="input-group-text">%</span>
              </div>
            </div>
          </div>
          <!-- calc btn -->
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="request-form-btns-container mt-4">
              <button *ngIf="!singlePaymentCalculated" class="btn btn-dark d-block" (click)="initCalcInterest()" type="button">
                <span>{{ 'HELPERS.calc' | translate }}</span>
              </button>
              <button *ngIf="singlePaymentCalculated" class="btn btn-dark d-block" (click)="reCalcInterest()" type="button">
                <span>{{ 'HELPERS.recalc' | translate }}</span>
              </button>
            </div>
          </div>
          <!-- loanType error msg -->
          <ng-container class="error-messages" *ngFor="let error of formErrorMessages.loanType">
            <div class="col-12" *ngIf="calcInterestFrm.get('loanType').hasError(error.type) && (calcInterestFrm.get('loanType').dirty || calcInterestFrm.get('loanType').touched)">
              <div class="form-group m-0">
                <small class="text-danger error-msg">
                  {{ error.message }}.&nbsp;
                </small>
              </div>
            </div>
          </ng-container>
          <!-- loanInterest error msg -->
          <ng-container class="error-messages" *ngFor="let error of formErrorMessages.loanInterest">
            <div class="col-12" *ngIf="calcInterestFrm.get('loanInterest').hasError(error.type) && (calcInterestFrm.get('loanInterest').dirty || calcInterestFrm.get('loanInterest').touched)">
              <div class="form-group m-0">
                <small class="text-danger error-msg">
                  {{ error.message }}.&nbsp;
                </small>
              </div>
            </div>
          </ng-container>
          <!-- help msgs -->
          <div class="col-12" *ngIf="toggleHelp">
            <div class="form-group m-0">
              <p class="help-messages" >
                <small>{{ 'LOAN.loanTypeHelp' | translate }}</small>
                <small>{{ 'LOAN.loanInterestHelp' | translate }}</small>
              </p>
            </div>
          </div>
        </div>
      </form>
      <!-- singlePaymentFrm -->
      <form class="theme-form mt-2" [formGroup]="singlePaymentFrm">
        <div class="row">
          <!-- singlePayment calc -->
          <div class="col-md-7 col-sm-12" *ngIf="!singlePaymentCustom">
            <label class="amount-lbl" for="amount">{{ 'LOAN.loanSinglePaymentMonth' | translate }}</label>
            <div class="percentage-resume">
              <span class="current-value amount-value">{{ singlePayment | currency:'$' : 'symbol' : '1.0-0' }}</span>
            </div>
          </div>
          <!-- singlePayment manual -->
          <div class="form-group col-md-7 col-sm-12" *ngIf="singlePaymentCustom">
            <label for="singlePayment">{{ 'LOAN.loanSinglePaymentMonth' | translate }} <span class="required-lbl">*</span></label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input class="form-control" #singlePayment id="singlePayment" type="text" inputmode="numeric" formControlName="singlePayment" [min]="amountMin" max="amountMax" placeholder="0">
            </div>
          </div>
          <!-- custom button -->
          <div class="col-5">
            <button class="btn btn-xs btn-dark custom-value-btn mt-3" (click)="toggleCustomSinglePayment()" *ngIf="!singlePaymentCustom">{{ 'LOAN.loanSinglePaymentCustom' | translate }}</button>
            <button class="btn btn-xs btn-dark custom-value-btn mt-4" (click)="toggleCustomSinglePayment()" *ngIf="singlePaymentCustom">{{ 'LOAN.loanSinglePaymentSelect' | translate }}</button>
          </div>
        </div>
      </form>
      <!-- divider -->
      <hr>
    </div>
    <!-- preApproved -->
    <!-- ------------------------------------------------------------- -->
    <div *ngIf="lastStatus === 'preApproved' && companyApproval">
      <!-- data -->
      <div class="row mt-4 mb-0">
        <div class="col-12">
          <h4 class="request-title">{{ 'LOAN.preApproved' | translate }}</h4>
        </div>
        <div class="col-md-9 col-sm-12">
          <label class="amount-lbl" for="amount">{{ 'LOAN.preApprovedAmount' | translate }}</label>
          <div class="percentage-resume">
            <span class="current-value amount-value">{{ loanAmountPreApproved | currency:'$' : 'symbol' : '1.0-0' }}</span>
          </div>
        </div>
        <div class="col-md-3 col-sm-12">
          <label class="monts-lbl" for="months">{{ 'LOAN.requestMonths' | translate }}</label>
          <div class="percentage-resume">
            <span class="current-value">{{ loanPaymentMonthsPreApproved }}</span>
          </div>
        </div>
      </div>
      <!-- divider -->
      <hr>
    </div>
    <!-- company-approval -->
    <!-- ------------------------------------------------------------- -->
    <div id="company-approval-section" *ngIf="lastStatus === 'readyToApprove' && companyApproval">
      <!-- header -->
      <div class="row">
        <div class="col-12">
          <h4 class="request-title">{{ 'LOAN.approve' | translate }}&nbsp;{{ businessName }}</h4>
        </div>
      </div>
      <!-- approvalFrm -->
      <form class="theme-form" [formGroup]="approvalFrm" *ngIf="companyAmountEdit">
        <!-- amount -->
        <div class="row" *ngIf="!companyAmountCustom">
          <!-- value -->
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <label class="amount-lbl" for="amount">{{ 'LOAN.loanAmount' | translate }} <span class="required-lbl">*</span></label>
            <div class="percentage-resume">
              <span class="current-value amount-value">{{ approvalFrm.controls.amount.value | currency:'$' : 'symbol' : '1.0-0' }}</span>
            </div>
          </div>
          <!-- range -->
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div class="form-group range-field">
              <input id="amount" formControlName="amount" type="range" step="50000" [min]="companyAmountMin" [max]="companyAmountMax">
              <ng-container class="error-messages error-range" *ngFor="let error of formErrorMessages.amount">
                <small class="text-danger" *ngIf="approvalFrm.get('amount').hasError(error.type) && (approvalFrm.get('amount').dirty || approvalFrm.get('amount').touched)">
                  {{ error.message }}.&nbsp;
                </small>
              </ng-container>
              <p class="help-messages helper-range" *ngIf="toggleHelp">
                <small>{{ 'LOAN.requestAmountHelp' | translate }}</small>
              </p>
            </div>
          </div>
          <!-- custom button -->
          <div class="col-12">
            <button class="btn btn-xs btn-dark custom-value-btn" (click)="toggleCompanyCustomAmount()">{{ 'LOAN.requestAmountCustom' | translate }}</button>
          </div>
        </div>
        <!-- custom amount -->
        <div class="row" *ngIf="companyAmountCustom">
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="form-group mt-4">
              <label for="amount">{{ 'LOAN.requestAmount' | translate }} <span class="required-lbl">*</span></label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input class="form-control" #amount id="amount" type="number" inputmode="numeric" formControlName="amount" [min]="companyAmountMin" [max]="companyAmountMax" (change)="checkAmount()">
              </div>
              <ng-container class="error-messages" *ngFor="let error of formErrorMessages.amount">
                <small class="text-danger error-msg" *ngIf="approvalFrm.get('amount').hasError(error.type) && (approvalFrm.get('amount').dirty || approvalFrm.get('amount').touched)">
                  {{ error.message }}.&nbsp;
                </small>
              </ng-container>
              <ng-container class="error-messages" *ngIf="(approvalFrm.get('amount').dirty || approvalFrm.get('amount').touched) && amountMinError">
                <small class="text-danger error-msg">
                  {{ 'LOAN.loanApproveAmountMin' | translate }}&nbsp;{{ amountMin | currency:'$' : 'symbol' : '1.0-0' }}
                </small>
              </ng-container>
              <ng-container class="error-messages" *ngIf="(approvalFrm.get('amount').dirty || approvalFrm.get('amount').touched) && amountMaxError">
                <small class="text-danger error-msg">
                  {{ 'LOAN.loanApproveAmountMax' | translate }}&nbsp;{{ amountMax | currency:'$' : 'symbol' : '1.0-0' }}
                </small>
              </ng-container>
              <p class="help-messages" *ngIf="toggleHelp">
                <small>{{ 'LOAN.requestAmountHelp' | translate }}</small>
              </p>
            </div>
            <button class="btn btn-xs btn-dark custom-value-btn" (click)="toggleCompanyCustomAmount()">{{ 'LOAN.requestAmountSave' | translate }}</button>
          </div>
        </div>
        <!-- months -->
        <div class="row mt-4" *ngIf="!companyMonthsCustom">
          <p>companyMonthMin: {{ companyMonthMin }}</p>
          <p>companyMonthMax: {{ companyMonthMax }}</p>
          <p>months: {{ approvalFrm.controls.months.value }}</p>
          <!-- value -->
          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
            <label class="monts-lbl" for="months">{{ 'LOAN.requestMonths' | translate }} <span class="required-lbl">*</span></label>
            <div class="percentage-resume">
              <span class="current-value">{{ approvalFrm.controls.months.value }}</span>
            </div>
          </div>
          <!-- range -->
          <div class="col-xl-9 col-lg-8 col-md-6 col-sm-12">
            <div class="form-group range-field">
              <input id="months" formControlName="months" type="range" [step]="companyMonthSteps" [min]="companyMonthMin" [max]="companyMonthMax">
              <ng-container class="error-messages error-range" *ngFor="let error of formErrorMessages.months">
                <small class="text-danger" *ngIf="approvalFrm.get('months').hasError(error.type) && (approvalFrm.get('months').dirty || approvalFrm.get('months').touched)">
                  {{ error.message }}.&nbsp;
                </small>
              </ng-container>
              <p class="help-messages helper-range" *ngIf="toggleHelp">
                <small>{{ 'LOAN.requestMonthsHelp' | translate }}</small>
              </p>
            </div>
          </div>
          <!-- custom button -->
          <div class="col-12">
            <button class="btn btn-xs btn-dark custom-value-btn" (click)="toggleCompanyCustomMonths()">{{ 'LOAN.requestMonthsCustom' | translate }}</button>
          </div>
        </div>
        <!-- custom months -->
        <div class="row mt-4" *ngIf="companyMonthsCustom">
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="form-group mt-4">
              <label for="months">{{ 'LOAN.requestMonths' | translate }} <span class="required-lbl">*</span></label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input class="form-control" #months id="months" type="number" inputmode="numeric" formControlName="months" [min]="companyMonthMin" [max]="companyMonthMax" (change)="checkMonths()">
              </div>
              <ng-container class="error-messages" *ngFor="let error of formErrorMessages.months">
                <small class="text-danger error-msg" *ngIf="approvalFrm.get('months').hasError(error.type) && (approvalFrm.get('months').dirty || approvalFrm.get('months').touched)">
                  {{ error.message }}.&nbsp;
                </small>
              </ng-container>
              <ng-container class="error-messages" *ngIf="(approvalFrm.get('months').dirty || approvalFrm.get('months').touched) && monthsMinError">
                <small class="text-danger error-msg">
                  {{ 'LOAN.requestMonthsMin' | translate }}&nbsp;{{ monthsMin }}&nbsp;{{ 'HELPERS.months' | translate }}
                </small>
              </ng-container>
              <ng-container class="error-messages" *ngIf="(approvalFrm.get('months').dirty || approvalFrm.get('months').touched) && monthsMaxError">
                <small class="text-danger error-msg">
                  {{ 'LOAN.requestMonthsMax' | translate }}&nbsp;{{ monthsMax }}&nbsp;{{ 'HELPERS.months' | translate }}
                </small>
              </ng-container>
              <p class="help-messages" *ngIf="toggleHelp">
                <small>{{ 'LOAN.requestMonthsHelp' | translate }}</small>
              </p>
            </div>
            <button class="btn btn-xs btn-dark custom-value-btn" (click)="toggleCompanyCustomMonths()">{{ 'LOAN.requestMonthsSave' | translate }}</button>
          </div>
        </div>
      </form>
      <!-- approved amount and months -->
      <div class="row mt-2 mb-0" *ngIf="!companyAmountEdit">
        <div class="col-md-9 col-sm-12">
          <label class="amount-lbl">{{ 'LOAN.approvedAmount' | translate }}</label>
          <div class="percentage-resume">
            <span class="current-value amount-value">{{ loanAmountPreApproved | currency:'$' : 'symbol' : '1.0-0' }}</span>
          </div>
        </div>
        <div class="col-md-3 col-sm-12">
          <label class="monts-lbl">{{ 'LOAN.requestMonths' | translate }}</label>
          <div class="percentage-resume">
            <span class="current-value">{{ loanPaymentMonthsPreApproved }}</span>
          </div>
        </div>
        <div class="col-12 mt-2">
          <label class="amount-lbl">{{ 'LOAN.loanSinglePaymentMonth' | translate }}</label>
          <div class="percentage-resume">
            <span class="current-value amount-value">{{ singlePayment | currency:'$' : 'symbol' : '1.0-0' }}</span>
          </div>
        </div>
      </div>
      <!-- documents -->
      <div class="row mt-2">
        <div class="col-12">
          <app-loan-documents 
            [userId]="userId"
            [loanId]="loanId"
            [target]="'loan'"
            [promissoryFileData]="promissoryFileData"
            [promissoryFileShow]="true"
            [promissoryFileEdit]="editEnablePromissoryFileData"
            [authorizationFileData]="authorizationFileData"
            [authorizationFileShow]="loanDetailedValidation"
            [authorizationFileEdit]="editEnableAuthorizationFileData"
            (actionEvent)="fileAction($event)"
            (loaderEvent)="updateLoader($event)">
          </app-loan-documents>
        </div>
      </div>
      <!-- company-promissory -->
      <div class="row">
        <!-- link -->
        <div class="col-12 mt-2" *ngIf="editEnableCompanyPromissoryFileData && !companyPromissoryUploaded">
          <a class="btn btn-xs btn-dark custom-value-btn" [href]="'/documentos/pagaduria/' + loanNumber"  target="_blank" rel="norel noopener noreferrer">{{ 'LOAN.loanPrintCompanyPromissory' | translate }}</a>
        </div>
        <!-- help -->
        <div class="col-12 mt-2" *ngIf="editEnableCompanyPromissoryFileData">
          <p class="help-messages" ><small>{{ 'LOAN.loanDocumentCompanyPromissoryHelp' | translate }}</small></p>
        </div>
        <!-- file -->
        <app-loan-documents 
          [userId]="userId"
          [loanId]="loanId"
          [target]="'loan'"
          [companyPromissoryFileData]="companyPromissoryFileData"
          [companyPromissoryFileShow]="true"
          [companyPromissoryFileEdit]="editEnableCompanyPromissoryFileData"
          (actionEvent)="fileAction($event)"
          (loaderEvent)="updateLoader($event)">
        </app-loan-documents>
      </div>
      <!-- divider -->
      <hr>
    </div>
    <!-- company approved -->
    <!-- ------------------------------------------------------------- -->
    <div *ngIf="(lastStatus === 'approved' && companyApproval) && lastStatus !== 'readyForDisbursement'">
      <!-- data -->
      <div class="row mt-4 mb-0">
        <div class="col-12">
          <h4 class="request-title">{{ 'LOAN.approve' | translate }}</h4>
        </div>
        <div class="col-md-9 col-sm-12">
          <label class="amount-lbl" for="amount">{{ 'LOAN.approved' | translate }}</label>
          <div class="percentage-resume">
            <span class="current-value amount-value">{{ loanAmountApproved | currency:'$' : 'symbol' : '1.0-0' }}</span>
          </div>
        </div>
        <div class="col-md-3 col-sm-12">
          <label class="monts-lbl" for="months">{{ 'LOAN.requestMonths' | translate }}</label>
          <div class="percentage-resume">
            <span class="current-value">{{ loanPaymentMonthsApproved }}</span>
          </div>
        </div>
      </div>
      <!-- divider -->
      <hr>
    </div>
    <!-- approval -->
    <!-- ------------------------------------------------------------- -->
    <div id="financial-approval-section" *ngIf="enableEditApprovalData">
      <!-- header -->
      <div class="row">
        <div class="col">
          <h4 class="request-title">{{ 'LOAN.approve' | translate }}</h4>
        </div>
      </div>
      <!-- approvalFrm -->
      <form class="theme-form" [formGroup]="approvalFrm">
        <!-- insuranceIds -->
        <div *ngIf="loanInsuranceIds !== null">
          <div class="row mt-4">
            <div class="col-6">
              <h4 class="request-title">{{ 'LOAN.loanInsurances' | translate }}</h4>
              <p>{{ 'LOAN.loanInsuranceIdsHelp' | translate }}</p>
            </div>
            <div class="col-6">
              <div class="form-group mt-2">
                <label for="loanInsuranceIdsInput">{{ 'LOAN.loanInsuranceIds' | translate }}</label>
                <input class="form-control" #loanInsuranceIdsInput id="loanInsuranceIdsInput" type="text" inputmode="text" formControlName="loanInsuranceIds" [placeholder]="'LOAN.loanInsuranceIdsPlaceHolder' | translate">
                <p class="help-messages" *ngIf="toggleHelp">
                  <small>{{ 'LOAN.loanInsuranceIdsHelp' | translate }}</small>
                </p>
              </div>
            </div>
          </div>
          <!-- divider -->
          <hr>
        </div>
        <!-- amount -->
        <div class="row mt-1" *ngIf="!amountCustom">
          <!-- value -->
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <label class="amount-lbl" for="amount">{{ 'LOAN.loanAmountApprove' | translate }} <span class="required-lbl">*</span></label>
            <div class="percentage-resume">
              <span class="current-value amount-value">{{ amount | currency:'$' : 'symbol' : '1.0-0' }}</span><br>
              <span>
                <small class="product-values">
                  <b>{{ 'PRODUCT.loanProductMonths' | translate }}:</b>&nbsp;
                  {{ productAmountMin | currency:'$' : 'symbol' : '1.0-0' }}&nbsp;-&nbsp;
                  <span *ngIf="productAmountMaxLimit">{{ productAmountMax | currency:'$' : 'symbol' : '1.0-0' }}</span>
                  <span *ngIf="!productAmountMaxLimit">{{ productAmountMax }} {{ 'PRODUCT.productLoanSalaryMsg' | translate }}</span>
                </small>
              </span>
            </div>
          </div>
          <!-- range -->
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div class="form-group range-field">
              <input id="amount" formControlName="amount" type="range" step="50000" [min]="amountMin" [max]="amountMax">
              <ng-container class="error-messages error-range" *ngFor="let error of formErrorMessages.amount">
                <small class="text-danger" *ngIf="approvalFrm.get('amount').hasError(error.type) && (approvalFrm.get('amount').dirty || approvalFrm.get('amount').touched)">
                  {{ error.message }}.&nbsp;
                </small>
              </ng-container>
              <p class="help-messages helper-range" *ngIf="toggleHelp">
                <small>{{ 'LOAN.loanAmountApproveHelp' | translate }}</small>
              </p>
            </div>
          </div>
          <!-- custom button -->
          <div class="col-12">
            <button class="btn btn-xs btn-dark custom-value-btn" (click)="toggleCustomAmount()">{{ 'LOAN.loanAmountApproveCustom' | translate }}</button>
          </div>
        </div>
        <!-- custom amount -->
        <div class="row mt-1" *ngIf="amountCustom">
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="form-group mt-4">
              <label for="amountInput">{{ 'LOAN.loanAmountApprove' | translate }} <span class="required-lbl">*</span></label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input class="form-control" #amountInput id="amountInput" type="text" inputmode="numeric" formControlName="amount" (change)="checkAmount()">
              </div>
              <ng-container class="error-messages" *ngFor="let error of formErrorMessages.amount">
                <small class="text-danger error-msg" *ngIf="approvalFrm.get('amount').hasError(error.type) && (approvalFrm.get('amount').dirty || approvalFrm.get('amount').touched)">
                  {{ error.message }}.&nbsp;
                </small>
              </ng-container>
              <ng-container class="error-messages" *ngIf="(approvalFrm.get('amount').dirty || approvalFrm.get('amount').touched) && amountMinError">
                <small class="text-danger error-msg">
                  {{ 'LOAN.loanApproveAmountMin' | translate }}&nbsp;{{ amountMin | currency:'$' : 'symbol' : '1.0-0' }}
                </small>
              </ng-container>
              <ng-container class="error-messages" *ngIf="(approvalFrm.get('amount').dirty || approvalFrm.get('amount').touched) && amountMaxError">
                <small class="text-danger error-msg">
                  {{ 'LOAN.loanApproveAmountMax' | translate }}&nbsp;{{ amountMax | currency:'$' : 'symbol' : '1.0-0' }}
                </small>
              </ng-container>
              <p class="help-messages" *ngIf="toggleHelp">
                <small>{{ 'LOAN.loanAmountApproveHelp' | translate }}</small>
              </p>
            </div>
            <button class="btn btn-xs btn-dark custom-value-btn" (click)="toggleCustomAmount()">{{ 'LOAN.loanAmountApproveSave' | translate }}</button>
          </div>
        </div>
        <!-- months -->
        <div class="row mt-4" *ngIf="!monthsCustom">
          <!-- value -->
          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
            <label class="monts-lbl" for="months">{{ 'LOAN.loanMonthsApprove' | translate }} <span class="required-lbl">*</span></label>
            <div class="percentage-resume">
              <span class="current-value">{{ approvalFrm.controls.months.value }}</span><br>
              <span><small class="product-values"><b>{{ 'PRODUCT.loanProductMonths' | translate }}:</b> {{ productMonthMin }} - {{ productMonthMax }} </small></span>
            </div>
          </div>
          <!-- range -->
          <div class="col-xl-9 col-lg-8 col-md-6 col-sm-12">
            <div class="form-group range-field">
              <input id="months" formControlName="months" type="range" [step]="monthSteps" [min]="monthMin" [max]="monthMax">
              <ng-container class="error-messages error-range" *ngFor="let error of formErrorMessages.months">
                <small class="text-danger" *ngIf="approvalFrm.get('months').hasError(error.type) && (approvalFrm.get('months').dirty || approvalFrm.get('months').touched)">
                  {{ error.message }}.&nbsp;
                </small>
              </ng-container>
              <p class="help-messages helper-range" *ngIf="toggleHelp">
                <small>{{ 'LOAN.loanMonthsApproveHelp' | translate }}</small>
              </p>
            </div>
          </div>
          <!-- custom button -->
          <div class="col-12">
            <button class="btn btn-xs btn-dark custom-value-btn" (click)="toggleCustomMonths('approvalFrm')">{{ 'LOAN.loanMonthsApproveCustom' | translate }}</button>
          </div>
        </div>
        <!-- custom months -->
        <div class="row mt-4" *ngIf="monthsCustom">
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="form-group mt-4">
              <label for="monthsInput">{{ 'LOAN.requestMonths' | translate }} <span class="required-lbl">*</span></label>
              <input class="form-control" #monthsInput id="monthsInput" type="number" inputmode="numeric" formControlName="months" (change)="checkMonths()">
              <ng-container class="error-messages" *ngFor="let error of formErrorMessages.months">
                <small class="text-danger error-msg" *ngIf="approvalFrm.get('months').hasError(error.type) && (approvalFrm.get('months').dirty || approvalFrm.get('months').touched)">
                  {{ error.message }}.&nbsp;
                </small>
              </ng-container>
              <ng-container class="error-messages" *ngIf="(approvalFrm.get('months').dirty || approvalFrm.get('months').touched) && monthsMinError">
                <small class="text-danger error-msg">
                  {{ 'LOAN.requestMonthsMin' | translate }}&nbsp;{{ monthsMin }}&nbsp;{{ 'HELPERS.months' | translate }}
                </small>
              </ng-container>
              <ng-container class="error-messages" *ngIf="(approvalFrm.get('months').dirty || approvalFrm.get('months').touched) && monthsMaxError">
                <small class="text-danger error-msg">
                  {{ 'LOAN.requestMonthsMax' | translate }}&nbsp;{{ monthsMax }}&nbsp;{{ 'HELPERS.months' | translate }}
                </small>
              </ng-container>
              <p class="help-messages" *ngIf="toggleHelp">
                <small>{{ 'LOAN.requestMonthsHelp' | translate }}</small>
              </p>
            </div>
            <button class="btn btn-xs btn-dark custom-value-btn" (click)="toggleCustomMonths('approvalFrm')">{{ 'LOAN.loanMonthsApproveSave' | translate }}</button>
          </div>
        </div>
      </form>
      <!-- divider -->
      <hr>
      <!-- calcInterestFrm -->
      <form class="theme-form" [formGroup]="calcInterestFrm">
        <div class="row mt-4">
          <!-- loanInterest -->
          <div class="form-group col-lg-8 col-md-8 col-sm-12 mb-0">
            <label for="loanInterest">{{ 'LOAN.loanInterestMonthly' | translate }} <span class="required-lbl">*</span></label>
            <div class="input-group">
              <input class="form-control" #loanInterest id="loanInterest" type="number" inputmode="numeric" formControlName="loanInterest" min="0" max="100" placeholder="0">
              <div class="input-group-append">
                <span class="input-group-text">%</span>
              </div>
            </div>
          </div>
          <!-- calc btn -->
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="request-form-btns-container mt-4">
              <button *ngIf="!singlePaymentCalculated" class="btn btn-dark d-block" (click)="initCalcInterest()" type="button">
                <span>{{ 'HELPERS.calc' | translate }}</span>
              </button>
              <button *ngIf="singlePaymentCalculated" class="btn btn-dark d-block" (click)="reCalcInterest()" type="button">
                <span>{{ 'HELPERS.recalc' | translate }}</span>
              </button>
            </div>
          </div>
          <!-- loanType error msg -->
          <ng-container class="error-messages" *ngFor="let error of formErrorMessages.loanType">
            <div class="col-12" *ngIf="calcInterestFrm.get('loanType').hasError(error.type) && (calcInterestFrm.get('loanType').dirty || calcInterestFrm.get('loanType').touched)">
              <div class="form-group m-0">
                <small class="text-danger error-msg">
                  {{ error.message }}.&nbsp;
                </small>
              </div>
            </div>
          </ng-container>
          <!-- loanInterest error msg -->
          <ng-container class="error-messages" *ngFor="let error of formErrorMessages.loanInterest">
            <div class="col-12" *ngIf="calcInterestFrm.get('loanInterest').hasError(error.type) && (calcInterestFrm.get('loanInterest').dirty || calcInterestFrm.get('loanInterest').touched)">
              <div class="form-group m-0">
                <small class="text-danger error-msg">
                  {{ error.message }}.&nbsp;
                </small>
              </div>
            </div>
          </ng-container>
          <!-- help msgs -->
          <div class="col-12" *ngIf="toggleHelp">
            <div class="form-group m-0">
              <p class="help-messages" >
                <small>{{ 'LOAN.loanTypeHelp' | translate }}</small>
                <small>{{ 'LOAN.loanInterestHelp' | translate }}</small>
              </p>
            </div>
          </div>
        </div>
      </form>
      <!-- singlePaymentFrm -->
      <form class="theme-form mt-2" [formGroup]="singlePaymentFrm">
        <div class="row">
          <!-- singlePayment calc -->
          <div class="col-md-7 col-sm-12" *ngIf="!singlePaymentCustom">
            <label class="amount-lbl" for="amount">{{ 'LOAN.loanSinglePaymentMonth' | translate }}</label>
            <div class="percentage-resume">
              <span class="current-value amount-value">{{ singlePayment | currency:'$' : 'symbol' : '1.0-0' }}</span>
            </div>
          </div>
          <!-- singlePayment manual -->
          <div class="form-group col-md-7 col-sm-12" *ngIf="singlePaymentCustom">
            <label for="singlePayment">{{ 'LOAN.loanSinglePaymentMonth' | translate }} <span class="required-lbl">*</span></label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input class="form-control" #singlePayment id="singlePayment" type="text" inputmode="numeric" formControlName="singlePayment" [min]="amountMin" [max]="amountMax" placeholder="0" (change)="checkSinglePayment()">
            </div>
            <ng-container class="error-messages" *ngIf="(singlePaymentFrm.get('singlePayment').dirty || singlePaymentFrm.get('singlePayment').touched) && singlePaymentMinError">
              <small class="text-danger error-msg">
                {{ 'LOAN.loanSinglePaymentMin' | translate }}&nbsp;{{ singlePaymentMin | currency:'$' : 'symbol' : '1.0-0' }}
              </small>
            </ng-container>
            <ng-container class="error-messages" *ngIf="(singlePaymentFrm.get('singlePayment').dirty || singlePaymentFrm.get('singlePayment').touched) && singlePaymentMaxError">
              <small class="text-danger error-msg">
                {{ 'LOAN.loanSinglePaymentMax' | translate }}&nbsp;{{ amountMax | currency:'$' : 'symbol' : '1.0-0' }}
              </small>
            </ng-container>
          </div>
          <!-- custom button -->
          <div class="col-5">
            <button class="btn btn-xs btn-dark custom-value-btn mt-3" (click)="toggleCustomSinglePayment()" *ngIf="!singlePaymentCustom">{{ 'LOAN.loanSinglePaymentCustom' | translate }}</button>
            <button class="btn btn-xs btn-dark custom-value-btn mt-4" (click)="toggleCustomSinglePayment()" *ngIf="singlePaymentCustom">{{ 'LOAN.loanSinglePaymentSelect' | translate }}</button>
          </div>
        </div>
      </form>
      <!-- divider -->
      <hr>
    </div>
    <!-- approved -->
    <!-- ------------------------------------------------------------- -->
    <div *ngIf="(lastStatusNumber === 6 || lastStatusNumber === 8 || lastStatusNumber >= 9)">
      <!-- data -->
      <div class="row mt-4 mb-0">
        <div class="col-12">
          <h4 class="request-title">{{ 'LOAN.approve' | translate }}</h4>
        </div>
        <div class="col-md-9 col-sm-12">
          <label class="amount-lbl" for="amount">{{ 'LOAN.approved' | translate }}</label>
          <div class="percentage-resume">
            <span class="current-value amount-value">{{ loanAmountApproved | currency:'$' : 'symbol' : '1.0-0' }}</span>
          </div>
        </div>
        <div class="col-md-3 col-sm-12">
          <label class="monts-lbl" for="months">{{ 'LOAN.requestMonths' | translate }}</label>
          <div class="percentage-resume">
            <span class="current-value">{{ loanPaymentMonthsApproved }}</span>
          </div>
        </div>
      </div>
      <!-- divider -->
      <hr>
    </div>
    <!-- disbursement-preparation -->
    <!-- ------------------------------------------------------------- -->
    <div *ngIf="lastStatus === 'preApproved' || lastStatus === 'approved'">
      <!-- data -->
      <div class="row mt-4 mb-0">
        <div class="col-12">
          <h4 class="request-title">{{ 'LOAN.loanLegalDocuments' | translate }}</h4>
        </div>
        <!-- resend email -->
        <div class="col-12 mt-2" >
          <button class="btn btn-xs btn-dark custom-value-btn" (click)="sendPromissory()" *ngIf="!companyApproval">{{ 'LOAN.loanDocumentDisbursementPreparationResendEmail' | translate }}</button>
          <button class="btn btn-xs btn-dark custom-value-btn" (click)="sendCompanyPromissoryAndAuthorization()" *ngIf="companyApproval">{{ 'LOAN.loanDocumentDisbursementPreparationResendEmailCompany' | translate }}</button>
        </div>
      </div>
      <!-- documents -->
      <div class="row">
        <app-loan-documents
          [userId]="userId"
          [loanId]="loanId"
          [target]="'loan'"
          [promissoryFileData]="promissoryFileData"
          [promissoryFileShow]="true"
          [promissoryFileEdit]="editEnablePromissoryFileData"
          [authorizationFileData]="authorizationFileData"
          [authorizationFileShow]="companyApproval"
          [authorizationFileEdit]="editEnableAuthorizationFileData"
          (actionEvent)="fileAction($event)"
          (loaderEvent)="updateLoader($event)">
        </app-loan-documents>
      </div>
      <!-- divider -->
      <hr>
    </div>
    <!-- ready-for-disbursement -->
    <!-- ------------------------------------------------------------- -->
    <div *ngIf="lastStatus === 'readyForDisbursement'">
      <!-- data -->
      <div class="row mt-4 mb-0">
        <div class="col-12">
          <h4 class="request-title">{{ 'LOAN.loanDisbursement' | translate }}</h4>
        </div>
        <div class="col-12">
          <label class="amount-lbl" for="amount">{{ 'LOAN.loanDisbursementAmount' | translate }}</label>
          <div class="percentage-resume">
            <span class="current-value amount-value">{{ loanAmountApproved | currency:'$' : 'symbol' : '1.0-0' }}</span>
          </div>
        </div>
      </div>
      <!-- bank info -->
      <div>
        <!-- header -->
        <div class="row mt-4 mb-0">
          <div class="col-12">
            <h4 class="request-title">{{ 'USERBANKACCOUNTS.bankAccount' | translate }}</h4>
          </div>
        </div>
        <!-- list -->
        <div class="row" *ngFor="let userBankAccount of userBankAccounts">
          <div class="col">
            <div class="card small-widget mb-3">
              <div class="card-body " [ngClass]="{'primary': userBankAccount.userBankAccountActive === false, 'success': userBankAccount.userBankAccountActive === true}">
                <span class="widget-title">{{ 'USERBANKACCOUNTS.formLabels.bank' | translate }}</span>
                <div class="user-body-content">
                  <h4 class="content-title">{{ userBankAccount.bankName }}</h4>
                  <p class="content-txt"><small>{{ userBankAccount.userBankAccountNumberDecoded }}</small></p>
                  <p class="content-txt account-type">
                    <small *ngIf="userBankAccount.userBankAccountType === 'savings'">{{ 'USERBANKACCOUNTS.formLabels.userBankAccountTypeSavings' | translate }}</small>
                    <small *ngIf="userBankAccount.userBankAccountType === 'regular'">{{ 'USERBANKACCOUNTS.formLabels.userBankAccountTypeRegular' | translate }}</small>
                  </p>
                </div>
                <div class="bg-gradient">
                  <app-feather-icons class="svg-feather" [icon]="'pocket'"></app-feather-icons>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- TODO: USE COMPONENT -->
        <!-- <app-user-bank-accounts [userData]="userData" [userBankAccounts]="userBankAccounts" [addEnable]="false" [editEnable]="false" [fileEditEnable]="false" (loaderEvent)="updateLoader($event)"></app-user-bank-accounts> -->

      </div>
      <!-- divider -->
      <hr>
    </div>
    <!-- observations -->
    <!-- ------------------------------------------------------------- -->
    <div *ngIf="editEnableObservations">
      <div class="row mt-4 mb-0">
        <div class="col-12">
          <h4 class="request-title">{{ 'LOAN.loanObservations' | translate }}</h4>
        </div>
        <div class="col-12">
          <!-- observation -->
          <form class="theme-form" [formGroup]="observationFrm">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="observationInput">{{ 'LOAN.loanObservation' | translate }} <span class="required-lbl">*</span></label>
                  <angular-editor id="observationInput" #observationInput name="observationInput" formControlName="observation" [config]="editorConfig"></angular-editor>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- buttons -->
    <!-- ------------------------------------------------------------- -->
    <div class="row mb-4">
      <div class="col">
        <!-- initial documents -->
        <div class="request-form-btns-container approval-btns" *ngIf="enableEditInitialDocumentsData">
          <button class="btn btn-dark d-block" (click)="checkInitialDocuments()" type="button">
            <span>{{ 'HELPERS.save' | translate }}</span>
          </button>
          <button class="btn btn-danger d-block btn-cancel" (click)="saveCancelation()" type="button">
            <span>{{ 'HELPERS.cancel' | translate }} {{ 'LOAN.request' | translate }}</span>
          </button>
        </div>
        <!-- validation -->
        <div class="request-form-btns-container approval-btns" *ngIf="enableEditValidationData">
          <button class="btn btn-dark d-block btn-danger" (click)="checkNoPassValidation()" type="button">
            <span>{{ 'HELPERS.validationNoPass' | translate }}</span>
          </button>
          <button class="btn btn-success d-block" (click)="checkValidation()" type="button">
            <span>{{ 'HELPERS.validated' | translate }}</span>
          </button>
          <button class="btn btn-danger d-block btn-cancel" (click)="saveCancelation()" type="button">
            <span>{{ 'HELPERS.cancel' | translate }} {{ 'LOAN.request' | translate }}</span>
          </button>
        </div>
        <!-- pre-approval -->
        <div class="request-form-btns-container approval-btns" *ngIf="enableEditPreApprovalData">
          <button class="btn btn-danger d-block" (click)="checkReject()" type="button">
            <span>{{ 'HELPERS.reject' | translate }}</span>
          </button>
          <button class="btn btn-success d-block" (click)="checkPreApproval()" type="button">
            <span>{{ 'HELPERS.preApprove' | translate }}</span>
          </button>
          <button class="btn btn-danger d-block btn-cancel" (click)="saveCancelation()" type="button">
            <span>{{ 'HELPERS.cancel' | translate }} {{ 'LOAN.request' | translate }}</span>
          </button>
        </div>
        <!-- company-disbursement-preparation -->
        <div class="request-form-btns-container approval-btns"  *ngIf="enableEditCompanyDisbursementData">
          <button class="btn btn-dark d-block" (click)="checkCompanyDisbursementPreparation()" type="button">
            <span>{{ 'LOAN.requestCompanyApproval' | translate }}</span>
          </button>
        </div>
        <!-- financial-disbursement-preparation -->
        <div class="request-form-btns-container approval-btns"  *ngIf="enableEditFinancialDisbursementData">
          <button class="btn btn-dark d-block" (click)="checkDisbursementPreparation()" type="button">
            <span>{{ 'LOAN.requestDisbursement' | translate }}</span>
          </button>
        </div>
        <!-- company-approval -->
        <div class="request-form-btns-container approval-btns"  *ngIf="enableEditCompanyApprovalData">
          <button class="btn btn-danger d-block" (click)="companyApprovalRejected()" type="button">
            <span>{{ 'HELPERS.reject' | translate }}</span>
          </button>
          <button class="btn btn-success d-block" (click)="checkCompanyApproval()" type="button">
            <span>{{ 'HELPERS.approve' | translate }}</span>
          </button>
        </div>
        <!-- approval -->
        <div class="request-form-btns-container approval-btns" *ngIf="enableEditApprovalData">
          <button class="btn btn-danger d-block" (click)="checkReject()" type="button">
            <span>{{ 'HELPERS.reject' | translate }}</span>
          </button>
          <button class="btn btn-success d-block" (click)="checkApproval()" type="button">
            <span>{{ 'HELPERS.approve' | translate }}</span>
          </button>
          <button class="btn btn-danger d-block btn-cancel" (click)="saveCancelation()" type="button">
            <span>{{ 'HELPERS.cancel' | translate }} {{ 'LOAN.request' | translate }}</span>
          </button>
        </div>

        <!-- test -->
        <div *ngIf="false">

          <p>companyApproval: {{ companyApproval }}</p>

          <button class="btn btn-success d-block mt-2" (click)="createPaymentsManually()" type="button">
            <span>Create Payments</span>
          </button>
          <button class="btn btn-success d-block mt-2" (click)="updatePaymentDatesManually()" type="button">
            <span>Update Payment Dates</span>
          </button>
          <button class="btn btn-success d-block mt-2" (click)="calcInterest()" type="button">
            <span>Cacl Interest</span>
          </button>
          <button class="btn btn-success d-block mt-2" (click)="test()" type="button">
            <span>{{ 'HELPERS.test' | translate }}</span>
          </button>
          <button class="btn btn-success d-block mt-2" (click)="sendFirstSteps()" type="button">
            <span>{{ 'HELPERS.test' | translate }} first steps</span>
          </button>
          <button class="btn btn-success d-block mt-2" (click)="sendNoPassValidationMsg()" type="button">
            <span>{{ 'HELPERS.test' | translate }} no pass validation</span>
          </button>
          <button class="btn btn-success d-block mt-2" (click)="sendRejectionMsg()" type="button">
            <span>{{ 'HELPERS.test' | translate }} rejection</span>
          </button>
          <button class="btn btn-success d-block mt-2" (click)="sendCompanyPromissoryAndAuthorization()" type="button">
            <span>{{ 'HELPERS.test' | translate }} promissory and authorization</span>
          </button>
          <button class="btn btn-success d-block mt-2" (click)="sendConfirmation()" type="button">
            <span>{{ 'HELPERS.test' | translate }} confirmation</span>
          </button>
          <button class="btn btn-success d-block mt-2" (click)="sendCompanyApproved()" type="button">
            <span>{{ 'HELPERS.test' | translate }} company approved</span>
          </button>
          <button class="btn btn-success d-block mt-2" (click)="sendPromissory()" type="button">
            <span>{{ 'HELPERS.test' | translate }} promissory</span>
          </button>
        </div>

      </div>
    </div>
    <!-- history -->
    <!-- ------------------------------------------------------------- -->
    <div class="row mt-4 mb-0">
      <div class="col-12">
        <h4 class="request-title">{{ 'LOAN.loanStatusesRequest' | translate }}</h4>
      </div>
      <div class="col-12 notification">
        <ul *ngIf="loanStatuses.length > 0" class="mt-4">
          <li class="d-flex" *ngFor="let loanStatus of loanStatuses">
            <div class="activity-dot-{{ loanStatus.loanStatus }}"></div>
            <div class="w-100 ms-3">
              <div class="status-avatar">
                <div class="status-avatar-media">
                  <img class="status-avatar-image rounded-circle" [src]="loanStatus.user.avatar" [alt]="loanStatus.user.firstName" onError="src = 'assets/images/broken-avatar.png'">
                </div>
                <h6 class="status-avatar-name" (click)="openUser(loanStatus.user)"><b>{{ loanStatus.user.firstName }}</b>&nbsp;{{ loanStatus.user.lastName }}</h6>
              </div>
              <p class="d-flex justify-content-between mb-2">
                <span class="date-content light-background">{{ loanStatus.loanStatusDate | momentPipe: 'date': currentLang: 'DD MMM YYYY hh:mm A' }}</span>
                <span>{{ loanStatus.loanStatusDate | timeElapsedPipe }}</span>
              </p>
              <h6 class="table-status-txt">
                <span class="status-started" *ngIf="loanStatus.loanStatus === 'started'">{{ 'LOAN.loanStatusStarted' | translate }}</span>
                <span class="status-sended" *ngIf="loanStatus.loanStatus === 'sended'">{{ 'LOAN.loanStatusSended' | translate }}</span>
                <span class="status-accepted" *ngIf="loanStatus.loanStatus === 'accepted'">{{ 'LOAN.loanStatusAccepted' | translate }}</span>
                <span class="status-noPassedValidation" *ngIf="loanStatus.loanStatus === 'noPassedValidation'">{{ 'LOAN.loanStatusNoPassedValidation' | translate }}</span>
                <span class="status-validated" *ngIf="loanStatus.loanStatus === 'validated'">{{ 'LOAN.loanStatusValidated' | translate }}</span>
                <span class="status-rejected" *ngIf="loanStatus.loanStatus === 'canceled'">{{ 'LOAN.loanStatusCanceled' | translate }}</span>
                <span class="status-rejected" *ngIf="loanStatus.loanStatus === 'rejected'">{{ 'LOAN.loanStatusRejected' | translate }}</span>
                <span class="status-preApproved" *ngIf="loanStatus.loanStatus === 'preApproved'">{{ 'LOAN.loanStatusPreApproved' | translate }}</span>
                <span class="status-readyToApprove" *ngIf="loanStatus.loanStatus === 'readyToApprove'">{{ 'LOAN.loanStatusReadyToApprove' | translate }}</span>
                <span class="status-approved" *ngIf="loanStatus.loanStatus === 'approved'">{{ 'LOAN.loanStatusApproved' | translate }}</span>
                <span class="status-readyForDisbursement" *ngIf="loanStatus.loanStatus === 'readyForDisbursement'">{{ 'LOAN.loanStatusReadyForDisbursement' | translate }}</span>
                <span class="status-disbursementOnProgress" *ngIf="loanStatus.loanStatus === 'disbursementOnProgress'">{{ 'LOAN.loanStatusDisbursementOnProgress' | translate }}</span>
                <span class="status-disbursed" *ngIf="loanStatus.loanStatus === 'disbursed'">{{ 'LOAN.loanStatusDisbursed' | translate }}</span>
                <span class="status-payed" *ngIf="loanStatus.loanStatus === 'payed'">{{ 'LOAN.loanStatusPayed' | translate }}</span>
                <span class="status-preLegal" *ngIf="loanStatus.loanStatus === 'preLegal'">{{ 'LOAN.loanStatusPreLegal' | translate }}</span>
                <span class="status-legal" *ngIf="loanStatus.loanStatus === 'legal'">{{ 'LOAN.loanStatusLegal' | translate }}</span>
                <span class="status-negotiation" *ngIf="loanStatus.loanStatus === 'negotiation'">{{ 'LOAN.loanStatusNegotiation' | translate }}</span>
                <span class="status-expired" *ngIf="loanStatus.loanStatus === 'expired'">{{ 'LOAN.loanStatusExpired' | translate }}</span>
                <span class="dot-notification"></span>
              </h6>
              <p class="f-light" [innerHTML]="loanStatus.loanStatusObservations"></p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- user -->
  <div class="col-12" *ngIf="currentTab === 'user'">
    <!-- user card -->
    <app-user-card 
      *ngIf="loanUserData !== null && !editUserData"
      [userData]="loanUserData"
      [showAddresses]="true"
      [userAddresses]="userAddressesData"
      [showDocuments]="true"
      [idFrontFileData]="idFrontFileData"
      [idBackFileData]="idBackFileData"
      [userAttachmentsData]="userAttachments"
      [editEnable]="enableEditUserData"
      [fileEditEnable]="enableEditUserData"
      [fullData]="true"
      (loaderEvent)="updateLoader($event)"
      (editEvent)="editUser()"
      (fileActionEvent)="fileAction($event)">
    </app-user-card>
    <!-- user edit -->
    <div class="card" *ngIf="editUserData">
      <div class="card-body">
        <app-user-form 
          [userData]="loanUserData" 
          [fullData]="true" 
          [origin]="'editUserLoan'" 
          [showDocuments]="true" 
          (loaderEvent)="updateLoader($event)" 
          (backEvent)="cancelUserEdit()" 
          (updateEvent)="saveUser($event)">
        </app-user-form>
      </div>
    </div>
  </div>
  <!-- work -->
  <div class="col-12" *ngIf="currentTab === 'work'">
    <!-- company card -->
    <app-user-company-card 
      *ngIf="companyData !== null && !editCompanyData"
      [companyData]="companyData"
      [editEnable]="enableEditCompanyData"
      (loaderEvent)="updateLoader($event)"
      (editEvent)="editCompany()">
    </app-user-company-card>
    <!-- company edit -->
    <div class="card" *ngIf="editCompanyData">
      <div class="card-body">
        <app-user-company-form 
          [companyData]="companyData" 
          (saveEvent)="saveCompany($event)"
          (cancelEvent)="cancelEditCompany()" 
          (loaderEvent)="updateLoader($event)">
        </app-user-company-form>
      </div>
    </div>
    <!-- user work card -->
    <app-user-work-card 
      *ngIf="userWorkData !== null && !editWorkData"
      [userWorkData]="userWorkData"
      [laborFileData]="laborFileData"
      [paymentReceiptFilesData]="paymentReceiptFiles"
      [rutFileData]="rutFileData"
      [ccioFileData]="ccioFileData"
      [bankExtractsFileData]="bankExtractsFileData"
      [pgFileData]="pgFileData"
      [utilitiesFileData]="utilitiesFileData"
      [pensionerFileData]="pensionerFileData"
      [editEnable]="enableEditWorkData"
      [fileEditEnable]="enableEditWorkData"
      [fullData]="true"
      (loaderEvent)="updateLoader($event)"
      (editEvent)="editWork()">
    </app-user-work-card>
    <!-- user work edit -->
    <div *ngIf="editWorkData">
      <app-user-work-form [origin]="'dashboard'" [editSalary]="false" [userWorkData]="userWorkData" (loaderEvent)="updateLoader($event)" (backEvent)="cancelEditWork()" (saveEvent)="saveWork($event)"></app-user-work-form>
    </div>
  </div>
  <!-- financial -->
  <div class="col-12" *ngIf="currentTab === 'financial'">
    <!-- header financials -->
    <div class="row mt-4 mb-4">
      <div class="col-12">
        <h4 class="request-title">{{ 'LOAN.financialClient' | translate }}</h4>
      </div>
    </div>
    <!-- card -->
    <app-user-financial-card 
      *ngIf="financialData !== null && !editFinancialData"
      [userData]="loanUserData"
      [financialData]="financialData"
      [showFamilyInfo]="true"
      [showBankInfo]="true"
      [userBankAccountsData]="userBankAccounts"
      [editEnable]="enableEditFinancialData"
      [fileEditEnable]="enableEditFinancialData"
      (loaderEvent)="updateLoader($event)"
      (editEvent)="editFinancial()"
      (fileActionEvent)="fileAction($event)">
    </app-user-financial-card>
    <!-- form -->
    <div class="mt-4" *ngIf="editFinancialData">
      <app-user-financial-form [origin]="'dashboard'" [userData]="loanUserData" [salary]="salary" [userFinancialData]="financialData" (loaderEvent)="updateLoader($event)" (backEvent)="cancelEditFinancial()" (saveEvent)="saveFinancial($event)"></app-user-financial-form>
    </div>
  </div>
  <!-- validation -->
  <div class="col-12" *ngIf="currentTab === 'validation'">
    <app-user-validation 
      *ngIf="score !== null"
      [loanId]="loanId"
      [score]="score"
      [fileEditEnable]="enableEdiValidationFilesData"
      [profileFileData]="profileFileData"
      [synthesisFileData]="synthesisFileData"
      [detailedFileData]="detailedFileData"
      (loaderEvent)="updateLoader($event)"
      (editEvent)="editFinancial($event)"
      (fileActionEvent)="fileAction($event)">
    </app-user-validation>
  </div>
  <!-- documents -->
  <div class="col-12" *ngIf="currentTab === 'documents'">
    <!-- business documents -->
    <app-loan-documents 
      *ngIf="workType === 'business'"
      [userId]="userId"
      [loanId]="loanId"
      [target]="'loan'"
      [promissoryFileData]="promissoryFileData"
      [promissoryFileShow]="true"
      [promissoryFileEdit]="enableEditDocumentFilesData"
      [authorizationFileData]="authorizationFileData"
      [authorizationFileShow]="loanDetailedValidation"
      [authorizationFileEdit]="editEnableAuthorizationFileData"
      [otherDocument1FileData]="otherDocument1FileData"
      [otherDocument1FileShow]="showOtherDocumentFile1"
      [otherDocument1FileEdit]="enableEditDocumentFilesData"
      [otherDocument2FileData]="otherDocument2FileData"
      [otherDocument2FileShow]="showOtherDocumentFile2"
      [otherDocument2FileEdit]="enableEditDocumentFilesData"
      [otherDocument3FileData]="otherDocument3FileData"
      [otherDocument3FileShow]="showOtherDocumentFile3"
      [otherDocument3FileEdit]="enableEditDocumentFilesData"
      [disbursementFileData]="disbursementFileData"
      [disbursementFileShow]="lastStatusNumber >= 11"
      [disbursementFileEdit]="enableEditDocumentFilesData"
      (actionEvent)="fileAction($event)"
      (loaderEvent)="updateLoader($event)">
    </app-loan-documents>
    <!-- employee documents -->
    <app-loan-documents 
      *ngIf="workType === 'employee'"
      [userId]="userId"
      [loanId]="loanId"
      [target]="'loan'"
      [promissoryFileData]="promissoryFileData"
      [promissoryFileShow]="true"
      [promissoryFileEdit]="enableEditDocumentFilesData"
      [authorizationFileData]="authorizationFileData"
      [authorizationFileShow]="loanDetailedValidation"
      [authorizationFileEdit]="enableEditDocumentFilesData"
      [companyPromissoryFileData]="companyPromissoryFileData"
      [companyPromissoryFileShow]="true"
      [companyPromissoryFileEdit]="enableEditDocumentFilesData"
      [otherDocument1FileData]="otherDocument1FileData"
      [otherDocument1FileShow]="showOtherDocumentFile1"
      [otherDocument1FileEdit]="enableEditDocumentFilesData"
      [otherDocument2FileData]="otherDocument2FileData"
      [otherDocument2FileShow]="showOtherDocumentFile2"
      [otherDocument2FileEdit]="enableEditDocumentFilesData"
      [otherDocument3FileData]="otherDocument3FileData"
      [otherDocument3FileShow]="showOtherDocumentFile3"
      [otherDocument3FileEdit]="enableEditDocumentFilesData"
      [disbursementFileData]="disbursementFileData"
      [disbursementFileShow]="lastStatusNumber >= 11"
      [disbursementFileEdit]="enableEditDocumentFilesData"
      (actionEvent)="fileAction($event)"
      (loaderEvent)="updateLoader($event)">
    </app-loan-documents>
    <!-- no employee & no business documents -->
    <app-loan-documents 
      *ngIf="!(workType === 'employee' || workType === 'business')"
      [userId]="userId"
      [loanId]="loanId"
      [target]="'loan'"
      [promissoryFileData]="promissoryFileData"
      [promissoryFileShow]="true"
      [promissoryFileEdit]="enableEditDocumentFilesData"
      [otherDocument1FileData]="otherDocument1FileData"
      [otherDocument1FileShow]="showOtherDocumentFile1"
      [otherDocument1FileEdit]="enableEditDocumentFilesData"
      [otherDocument2FileData]="otherDocument2FileData"
      [otherDocument2FileShow]="showOtherDocumentFile2"
      [otherDocument2FileEdit]="enableEditDocumentFilesData"
      [otherDocument3FileData]="otherDocument3FileData"
      [otherDocument3FileShow]="showOtherDocumentFile3"
      [otherDocument3FileEdit]="enableEditDocumentFilesData"
      [disbursementFileData]="disbursementFileData"
      [disbursementFileShow]="lastStatusNumber >= 11"
      [disbursementFileEdit]="enableEditDocumentFilesData"
      (actionEvent)="fileAction($event)"
      (loaderEvent)="updateLoader($event)">
    </app-loan-documents>
  </div>
  <!-- payments -->
  <div class="col-12" *ngIf="currentTab === 'payments'">
    <!-- header -->
    <div class="row mt-4" *ngIf="false">
      <div class="col-lg-4 col-sm-12">
        <h6 class="request-title mt-3">{{ 'LOAN.loanPayments' | translate }}</h6>
      </div>
      <!-- report payments -->
      <div class="col-lg-4 col-sm-12">
        <div class="btn-with-outside-txt-lbl mt-0" (click)="openReportPayments(reportPaymentsModal)">
          <small class="btn-inner-txt">{{ 'HELPERS.report' | translate }} <span class="btn-inner-subtxt">{{ 'LOANPAYMENTS.loanPayments' | translate }}</span></small> 
          <a class="btn btn-primary btn-xs btn-icon-only btn-icon-text-left btn-add-address">
            <app-feather-icons [icon]="'check-circle'"></app-feather-icons>
          </a>
        </div>
      </div>
      <!-- readjust payments -->
      <div class="col-lg-4 col-sm-12">
        <div class="btn-with-outside-txt-lbl mt-0" (click)="openReadjustPayment(readjustPaymentModal)">
          <small class="btn-inner-txt">{{ 'HELPERS.readjust' | translate }} <span class="btn-inner-subtxt">{{ 'LOANPAYMENTS.loanPayments' | translate }}</span></small> 
          <a class="btn btn-primary btn-xs btn-icon-only btn-icon-text-left btn-add-address">
            <app-feather-icons [icon]="'refresh-ccw'"></app-feather-icons>
          </a>
        </div>
      </div>
    </div>
    <!-- list -->
    <div class="row mt-4">
      <div class="col-12">
        <app-loan-payments 
          *ngIf="loanPayments.length > 0"
          [dataType]="(lastStatusNumber >= 8  && lastStatusNumber < 11) ? 'full' : 'payment'"
          [loanPaymentsData]="loanPayments" 
          [enablePayments]="isCompany ? false : true"
          (paymentEvent)="openPayment(paymentModal, $event)"
          (openDocumentEvent)="openPaymentDocument(paymentDocumentModal, $event)"
          (loaderEvent)="updateLoader($event)">
        </app-loan-payments>
      </div>
    </div>
    <hr>
  </div>
</div>
<!-- no-passed-validation modal -->
<ng-template #noPassedValidationModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'LOAN.loanReject' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismissNoPassValidation()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- noPassedValidationFrm -->
    <form class="theme-form" [formGroup]="noPassedValidationFrm">
      <div class="row mt-4">
        <!-- noPassedValidationType -->
        <div class="form-group select-fix col-12">
          <span class="caret-select"></span>
          <label for="noPassedValidationTypeSelect" class="col-form-label">{{ 'LOAN.loanNoPassedValidation' | translate }} <span class="required-lbl">*</span></label>
          <select class="form-control" #noPassedValidationTypeSelect id="noPassedValidationTypeSelect" name="noPassedValidationTypeSelect" formControlName="noPassedValidationType" [title]="'LOAN.loanNoPassedValidation' | translate">
            <option value="null" selected disabled>{{ 'HELPERS.selectAnOption' | translate }}</option>
            <option value="borrowingCapacity">{{ 'LOAN.loanRejectSelectBorrowingCapacity' | translate }}</option>
            <option value="creditHistory">{{ 'LOAN.loanRejectSelectCreditHistory' | translate }}</option>
            <option value="other">{{ 'LOAN.loanRejectSelectOther' | translate }}</option>
          </select>
          <ng-container class="error-messages" *ngFor="let error of formErrorMessages.noPassedValidationType">
            <small class="text-danger error-msg" *ngIf="noPassedValidationFrm.get('noPassedValidationType').hasError(error.type) && (noPassedValidationFrm.get('noPassedValidationType').dirty || noPassedValidationFrm.get('noPassedValidationType').touched)">
              {{ error.message }}.&nbsp;
            </small>
          </ng-container>
          <p class="help-messages" *ngIf="toggleHelp">
            <small>{{ 'USER.formHelp.noPassedValidationType' | translate }}</small>
          </p>
        </div>
        <!-- noPassedValidationMsg -->
        <div class="col-12">
          <div class="form-group">
            <label for="noPassedValidationMsgInput">{{ 'LOAN.loanObservation' | translate }}</label>
            <angular-editor  id="noPassedValidationMsgInput" #noPassedValidationMsgInput name="noPassedValidationMsgInput" formControlName="noPassedValidationMsg" [config]="editorConfig"></angular-editor>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="btn btn-light d-block" (click)="dismissNoPassValidation()" type="button">
      <span>{{ 'HELPERS.cancel' | translate }}</span>
    </button>
    <button class="btn btn-dark d-block" (click)="saveNoPassValidation()" type="button" [disabled]="!noPassedValidationFrm.valid">
      <span>{{ 'HELPERS.save' | translate }}</span>
    </button>
  </div>
</ng-template>
<!-- reject modal -->
<ng-template #rejectModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'LOAN.loanReject' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismissReject()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- rejectFrm -->
    <form class="theme-form" [formGroup]="rejectFrm">
      <div class="row mt-4">
        <!-- rejectType -->
        <div class="form-group select-fix col-12">
          <span class="caret-select"></span>
          <label for="rejectTypeSelect" class="col-form-label">{{ 'LOAN.loanReject' | translate }} <span class="required-lbl">*</span></label>
          <select class="form-control" #rejectTypeSelect id="rejectTypeSelect" name="rejectTypeSelect" formControlName="rejectType" [title]="'LOAN.loanReject' | translate">
            <option value="null" selected disabled>{{ 'HELPERS.selectAnOption' | translate }}</option>
            <option value="borrowingCapacity">{{ 'LOAN.loanRejectSelectBorrowingCapacity' | translate }}</option>
            <option value="creditHistory">{{ 'LOAN.loanRejectSelectCreditHistory' | translate }}</option>
            <option value="other">{{ 'LOAN.loanRejectSelectOther' | translate }}</option>
          </select>
          <ng-container class="error-messages" *ngFor="let error of formErrorMessages.rejectType">
            <small class="text-danger error-msg" *ngIf="rejectFrm.get('rejectType').hasError(error.type) && (rejectFrm.get('rejectType').dirty || rejectFrm.get('rejectType').touched)">
              {{ error.message }}.&nbsp;
            </small>
          </ng-container>
          <p class="help-messages" *ngIf="toggleHelp">
            <small>{{ 'USER.formHelp.rejectType' | translate }}</small>
          </p>
        </div>
        <!-- rejectMsg -->
        <div class="col-12">
          <div class="form-group">
            <label for="rejectMsgInput">{{ 'LOAN.loanObservation' | translate }}</label>
            <angular-editor  id="rejectMsgInput" #rejectMsgInput name="rejectMsgInput" formControlName="rejectMsg" [config]="editorConfig"></angular-editor>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="btn btn-light d-block" (click)="dismissReject()" type="button">
      <span>{{ 'HELPERS.cancel' | translate }}</span>
    </button>
    <button class="btn btn-dark d-block" (click)="saveReject()" type="button" [disabled]="!rejectFrm.valid">
      <span>{{ 'HELPERS.save' | translate }}</span>
    </button>
  </div>
</ng-template>
<!-- reportPayment modal -->
<ng-template #reportPaymentsModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'HELPERS.report' | translate }}&nbsp;{{ 'LOANPAYMENTS.loanPayments' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancelReadjustPayment()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <!-- title -->
      <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
        <h2>{{ 'HELPERS.total' | translate }}</h2>
      </div>
      <!-- total -->
      <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12">
        <h4 class="mt-1 pull-right">{{ amountSelectPayments | currency:'$' : 'symbol' : '1.0-0' }}</h4>
      </div>
      <!-- payments -->
      <div class="col-12">
        <app-loan-payments 
          class="loan-select-payments"
          *ngIf="loanPayments.length > 0"
          [dataType]="'select-payment'"
          [loanPaymentsData]="unpaidPayments" 
          [enablePayments]="false"
          (updateEvent)="updateSelectedPayments($event)"
          (loaderEvent)="updateLoader($event)">
        </app-loan-payments>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-light d-block" (click)="cancelReportPayments()" type="button">
      <span>{{ 'HELPERS.cancel' | translate }}</span>
    </button>
    <button class="btn btn-dark d-block" (click)="saveReportPayments()" type="button" [disabled]="!(amountSelectPayments > 0)">
      <span>{{ 'HELPERS.save' | translate }}</span>
    </button>
  </div>
</ng-template>
<!-- readjustPayment modal -->
<ng-template #readjustPaymentModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'HELPERS.readjust' | translate }}&nbsp;{{ 'LOANPAYMENTS.loanPayment' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancelReadjustPayment()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- loanPaymentFrm -->
    <form class="theme-form" [formGroup]="loanPaymentFrm">
      <div class="row mt-4">
        <!-- paymentNumber -->
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div class="form-group select-fix">
            <span class="caret-select"></span>
            <label for="paymentNumberSelect" class="col-form-label">{{ 'LOANPAYMENTS.loanPaymentNumber' | translate }} <span class="required-lbl">*</span></label>
            <select class="form-control" #paymentNumberSelect id="paymentNumberSelect" name="paymentNumberSelect" formControlName="paymentNumber" [title]="'LOANPAYMENTS.loanPaymentNumber' | translate" (change)="selectProfession()">
              <option value="null" selected disabled>{{ 'LOANPAYMENTS.loanPaymentNumberSelect' | translate }}</option>
              <option [value]="loanPayment.id" *ngFor="let loanPayment of unpaidPayments">{{ 'LOANPAYMENTS.loanPaymentNumberShort' | translate }}&nbsp;{{ loanPayment.loanPaymentNumber }}&nbsp;-&nbsp;{{ loanPayment.loanPaymentAmount | currency:'$' : 'symbol' : '1.0-0' }}</option>
            </select>
            <ng-container class="error-messages" *ngFor="let error of formErrorMessages.paymentNumber">
              <small class="text-danger error-msg" *ngIf="loanPaymentFrm.get('paymentNumber').hasError(error.type) && (loanPaymentFrm.get('paymentNumber').dirty || loanPaymentFrm.get('paymentNumber').touched)">
                {{ error.message }}.&nbsp;
              </small>
            </ng-container>
            <p class="help-messages" *ngIf="toggleHelp">
              <small>{{ 'LOANPAYMENTS.loanPaymentNumberHelp' | translate }}</small>
            </p>
          </div>
        </div>
        <!-- paymentAmount -->
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div class="form-group">
            <label for="paymentAmount">{{ 'LOANPAYMENTS.loanPaymentAmount' | translate }}  <span class="required-lbl">*</span></label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input class="form-control" #paymentAmount id="paymentAmount" type="text" inputmode="numeric" formControlName="paymentAmount" placeholder="0">
            </div>
            <ng-container class="error-messages" *ngFor="let error of formErrorMessages.paymentAmount">
              <small class="text-danger error-msg" *ngIf="loanPaymentFrm.get('paymentAmount').hasError(error.type) && (loanPaymentFrm.get('paymentAmount').dirty || loanPaymentFrm.get('paymentAmount').touched)">
                {{ error.message }}.&nbsp;
              </small>
            </ng-container>
            <p class="help-messages" *ngIf="toggleHelp">
              <small>{{ 'LOANPAYMENTS.loanPaymentAmountHelp' | translate }}</small>
            </p>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="btn btn-light d-block" (click)="cancelReadjustPayment()" type="button">
      <span>{{ 'HELPERS.cancel' | translate }}</span>
    </button>
    <button class="btn btn-dark d-block" (click)="saveReadjustPayment()" type="button" [disabled]="!loanPaymentFrm.valid">
      <span>{{ 'HELPERS.save' | translate }}</span>
    </button>
  </div>
</ng-template>
<!-- payment modal -->
<ng-template #paymentModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'LOANPAYMENTS.loanPayment' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="closePayment()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- loanPaymentFrm -->
    <form class="theme-form" [formGroup]="loanPaymentFrm">
      <div class="row mt-4">
        <!-- paymentNumber -->
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="loanPaymentFrm.controls.paymentNumber.value === null">
          <div class="form-group select-fix">
            <span class="caret-select"></span>
            <label for="paymentNumberSelect" class="col-form-label">{{ 'LOANPAYMENTS.loanPaymentNumber' | translate }} <span class="required-lbl">*</span></label>
            <select class="form-control" #paymentNumberSelect id="paymentNumberSelect" name="paymentNumberSelect" formControlName="paymentNumber" [title]="'LOANPAYMENTS.loanPaymentNumber' | translate">
              <option value="null" selected disabled>{{ 'LOANPAYMENTS.loanPaymentNumberSelect' | translate }}</option>
              <option [value]="loanPayment.id" *ngFor="let loanPayment of unpaidPayments">{{ 'LOANPAYMENTS.loanPaymentNumberShort' | translate }}&nbsp;{{ loanPayment.loanPaymentNumber }}&nbsp;-&nbsp;{{ loanPayment.loanPaymentAmount | currency:'$' : 'symbol' : '1.0-0' }}</option>
            </select>
            <ng-container class="error-messages" *ngFor="let error of formErrorMessages.paymentNumber">
              <small class="text-danger error-msg" *ngIf="loanPaymentFrm.get('paymentNumber').hasError(error.type) && (loanPaymentFrm.get('paymentNumber').dirty || loanPaymentFrm.get('paymentNumber').touched)">
                {{ error.message }}.&nbsp;
              </small>
            </ng-container>
            <p class="help-messages" *ngIf="toggleHelp">
              <small>{{ 'LOANPAYMENTS.loanPaymentNumberHelp' | translate }}</small>
            </p>
          </div>
        </div>
        <!-- paymentAmount -->
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div class="form-group">
            <label for="paymentAmount">{{ 'LOANPAYMENTS.loanPaymentAmountToPay' | translate }}  <span class="required-lbl">*</span></label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input class="form-control" #paymentAmount id="paymentAmount" type="text" inputmode="numeric" formControlName="paymentAmount" placeholder="0" *ngIf="loanPaymentMonthsApproved === (loanPayment && loanPayment.loanPaymentNumber)" disabled="true">
              <input class="form-control" #paymentAmount id="paymentAmount" type="text" inputmode="numeric" formControlName="paymentAmount" placeholder="0" *ngIf="loanPaymentMonthsApproved !== (loanPayment && loanPayment.loanPaymentNumber)">
            </div>
            <ng-container class="error-messages" *ngFor="let error of formErrorMessages.paymentAmount">
              <small class="text-danger error-msg" *ngIf="loanPaymentFrm.get('paymentAmount').hasError(error.type) && (loanPaymentFrm.get('paymentAmount').dirty || loanPaymentFrm.get('paymentAmount').touched)">
                {{ error.message }}.&nbsp;
              </small>
            </ng-container>
            <p class="help-messages" *ngIf="toggleHelp">
              <small>{{ 'LOANPAYMENTS.loanPaymentAmountHelp' | translate }}</small>
            </p>
          </div>
        </div>
        <!-- paymentInterestPercentage -->
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div class="form-group">
            <label for="paymentInterestPercentage">{{ 'LOANPAYMENTS.paymentInterestPercentage' | translate }} <span class="required-lbl">*</span></label>
            <div class="input-group">
              <input class="form-control" #paymentInterestPercentage id="paymentInterestPercentage" type="number" inputmode="numeric" formControlName="paymentInterestPercentage" min="0" max="100" placeholder="0" *ngIf="loanPaymentMonthsApproved === (loanPayment && loanPayment.loanPaymentNumber)" disabled="true">
              <input class="form-control" #paymentInterestPercentage id="paymentInterestPercentage" type="number" inputmode="numeric" formControlName="paymentInterestPercentage" min="0" max="100" placeholder="0" *ngIf="loanPaymentMonthsApproved !== (loanPayment && loanPayment.loanPaymentNumber)">
              <div class="input-group-append">
                <span class="input-group-text">%</span>
              </div>
            </div>
            <ng-container class="error-messages" *ngFor="let error of formErrorMessages.paymentInterestPercentage">
              <small class="text-danger error-msg" *ngIf="loanPaymentFrm.get('paymentInterestPercentage').hasError(error.type) && (loanPaymentFrm.get('paymentInterestPercentage').dirty || loanPaymentFrm.get('paymentInterestPercentage').touched)">
                {{ error.message }}.&nbsp;
              </small>
            </ng-container>
            <p class="help-messages" *ngIf="toggleHelp">
              <small>{{ 'LOANPAYMENTS.paymentInterestPercentageHelp' | translate }}</small>
            </p>
          </div>
        </div>
      </div>
    </form>
    <!-- document -->
    <app-loan-documents 
      class="payment-document"
      [loanPaymentId]="loanPaymentId"
      [target]="'loanPayment'"
      [loanPaymentFileData]="loanPaymentFileData"
      [loanPaymentFileShow]="true"
      [loanPaymentFileEdit]="true"
      (actionEvent)="fileAction($event)"
      (loaderEvent)="updateLoader($event)">
    </app-loan-documents>
  </div>
  <div class="modal-footer">
    <button class="btn btn-light d-block" (click)="closePayment()" type="button">
      <span>{{ 'HELPERS.cancel' | translate }}</span>
    </button>
    <button class="btn btn-dark d-block" (click)="checkPayment()" type="button">
      <span>{{ 'HELPERS.save' | translate }}</span>
    </button>
  </div>
</ng-template>
<!-- paymentDocument modal -->
<ng-template #paymentDocumentModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'LOANPAYMENTS.loanPaymentReceipt' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="closePaymentDocument()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-loan-documents 
      class="payment-document"
      [loanPaymentId]="loanPaymentId"
      [target]="'loanPayment'"
      [editEnable]="false"
      [loanPaymentFileData]="loanPaymentFileData"
      [loanPaymentFileShow]="true"
      (actionEvent)="fileAction($event)"
      (loaderEvent)="updateLoader($event)">
    </app-loan-documents>
  </div>
</ng-template>