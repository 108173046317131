<div class="row">
  <div class="col-12 text-center">
    <div class="no-data-icon">
      <app-feather-icons [icon]="'alert-triangle'"></app-feather-icons>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12 text-center">
    <h6 class="no-data-msg">{{ msg }}</h6>
  </div>
</div>