<!-- form -->
<form class="theme-form" [formGroup]="mainFrm">
  <!-- avatar -->
  <div class="row" *ngIf="fullData">
    <div class="col-12">
      <div class="social-img-wrap">
        <!-- image -->
        <div class="social-img">
          <img [src]="avatarSource" [alt]="firstName" onError="src = 'assets/images/broken-avatar.png'" *ngIf="avatarSource !== null">
          <img src="assets/images/broken-avatar.png" alt="Avatar" *ngIf="avatarSource === null">
        </div>
        <!-- file input -->
        <input type="file" #fileInput style="display: none" (change)="onFileSelected($event, avatarCropModal)" />
        <!-- button -->
        <div class="add-icon" (click)="editAvatar()" *ngIf="!savedData">
          <app-feather-icons class="feather-icon" [icon]="'camera'"></app-feather-icons>
        </div>
        <div class="edit-icon" (click)="editAvatar()" *ngIf="savedData">
          <app-feather-icons class="feather-icon" [icon]="'edit-3'" ></app-feather-icons>
        </div>
      </div>
    </div>
  </div>
  <!-- header -->
  <div class="row mt-4">
    <!-- title -->
    <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" [ngClass]="[origin === 'simulator' ? 'mt-3' : '']">
      <h4 *ngIf="origin === 'userB2C' || origin === 'userB2B'">{{ 'USER.userTitle' | translate }}</h4>
      <p *ngIf="origin === 'userB2C' || origin === 'userB2B'">{{ 'USER.userTxt' | translate }}</p>
      <p *ngIf="!(origin === 'userB2C' || origin === 'userB2B' || origin === 'simulator')">{{ 'USER.userTxtPersonal' | translate }}</p>
    </div>
    <!-- help -->
    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 pull-right">
      <div class="btn-with-outside-txt-lbl help-btn" [ngClass]="{'help-btn-simulator': origin === 'simulator'}" (click)="help()">
        <small class="btn-inner-txt" *ngIf="origin !== 'simulator'">{{ 'HELPERS.help' | translate }}</small> 
        <a class="btn btn-light btn-xs btn-icon-only btn-icon-text-left">
          <app-feather-icons [icon]="'help-circle'"></app-feather-icons>
        </a>
      </div>
    </div>
    <!-- description -->
    <div class="col-12" *ngIf="origin === 'simulator'">
      <p >{{ 'USER.userTxtSimulator' | translate }}</p>
    </div>
  </div>
  <!-- email / cellphone -->
  <div class="row mt-4">
    <!-- email -->
    <div [ngClass]="[fullData || origin === 'simulator' ? 'col-12' : 'col-lg-6 col-md-6 col-sm-12 col-xs-12']">
      <div class="form-group">
        <label class="col-form-label">{{ 'LOGIN.email.placeHolder' | translate }} <span class="required-lbl">*</span></label>
        <input class="form-control" #email id="email" type="email" inputmode="email"  [placeholder]="'USER.formPlaceHolders.email' | translate" formControlName="email" (change)="checkEmail()" (keyup.enter)="setFocus(cellphone)">
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.email">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('email').hasError(error.type) && (mainFrm.get('email').dirty || mainFrm.get('email').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <ng-container class="error-messages" *ngIf="origin === 'addUser' && !(emailStatus === 'not_found' || emailStatus === null) && mainFrm.controls.email.value !== null && (mainFrm.get('email').dirty || mainFrm.get('email').touched)">
          <small class="text-danger error-msg">
            {{ 'ERRORS.email_exists' | translate }}
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'USER.formHelp.email' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- cellphone -->
    <div [ngClass]="[origin === 'simulator' ? 'col-12' : 'col-lg-6 col-md-6 col-sm-12 col-xs-12']">
      <div [ngClass]="{'hidden': (selectCellphoneCode)}">
        <label for="cellphone">{{ 'USER.formLabels.cellphone' | translate }} <span class="required-lbl">*</span></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text phone-select-btn" (click)="openSelectPhoneCountry('cellphone')">
              <img class="phone-select-img" [src]="cellphoneCountryFlag" [alt]="cellphoneCountryName">&nbsp;
              <span class="select-custom-txt phone-select-txt">+{{ cellphoneCountryCode }}</span>&nbsp;
              <i class="fa fa-caret-down" aria-hidden="true"></i>
            </span>
          </div>
          <input class="form-control" #cellphone id="cellphone" type="tel" inputmode="tel" [placeholder]="'USER.formPlaceHolders.cellphone' | translate" formControlName="cellphone" (change)="checkPhoneNumber('cellphone')"/>
        </div>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.cellphone"> 
          <small class="text-danger error-msg" *ngIf="mainFrm.get('cellphone').hasError(error.type) && (mainFrm.get('cellphone').dirty || mainFrm.get('cellphone').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'USER.formHelp.cellphone' | translate }}</small>
        </p>
      </div>
      <div class="form-group" [ngClass]="{'hidden': (!selectCellphoneCode)}">
        <label for="cellphoneCountryCode" class="col-form-label required">{{ 'USER.formLabels.countryPhoneCode' | translate }}</label>
        <br>
        <ng-select [items]="allCountries"
          [selectOnTab]="true"
          bindLabel="countryName"
          bindValue="id"
          labelForId="cellphoneCountryCodeSelect"
          [placeholder]="'USER.formPlaceHolders.countryPhoneCode' | translate"
          (change)="selectCountry('cellphone')"
          #cellphoneCountryCodeSelect id="cellphoneCountryCodeSelect" name="cellphoneCountryCodeSelect" formControlName="cellphoneCountryCode">
        </ng-select>
      </div>
    </div>
    <!-- phone -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4" *ngIf="fullData">
      <div [ngClass]="{'hidden': (selectPhoneCode)}">
        <label for="phone">{{ 'USER.formLabels.phone' | translate }}</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text phone-select-btn" (click)="openSelectPhoneCountry('phone')">
              <img class="phone-select-img" [src]="phoneCountryFlag" [alt]="phoneCountryName">&nbsp;
              <span class="select-custom-txt phone-select-txt">+{{ phoneCountryCode }}</span>&nbsp;
              <i class="fa fa-caret-down" aria-hidden="true"></i>
            </span>
          </div>
          <input class="form-control" #phone id="phone" type="tel" inputmode="tel" [placeholder]="'USER.formPlaceHolders.phone' | translate" formControlName="phone" (change)="checkPhoneNumber('phone')"/>
        </div>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.phone"> 
          <small class="text-danger error-msg" *ngIf="mainFrm.get('phone').hasError(error.type) && (mainFrm.get('phone').dirty || mainFrm.get('phone').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'USER.formHelp.phone' | translate }}</small>
        </p>
      </div>
      <div class="form-group" [ngClass]="{'hidden': (!selectPhoneCode)}">
        <label for="phoneCountryCode" class="col-form-label">{{ 'USER.formLabels.countryPhoneCode' | translate }}</label>
        <br>
        <ng-select [items]="allCountries"
          [selectOnTab]="true"
          bindLabel="countryName"
          bindValue="id"
          labelForId="phoneCountryCodeSelect"
          [placeholder]="'USER.formPlaceHolders.countryPhoneCode' | translate"
          (change)="selectCountry('phone')"
          #phoneCountryCodeSelect id="phoneCountryCodeSelect" name="phoneCountryCodeSelect" formControlName="phoneCountryCode">
        </ng-select>
      </div>
    </div>
  </div>
  <!-- firstName / secondName / lastName / surname -->
  <div class="row" [ngClass]="[origin === 'simulator' ? 'mt-3' : '']">
    <!-- firstName -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group">
        <label for="firstName">{{ 'USER.formLabels.firstName' | translate }} <span class="required-lbl">*</span></label>
        <input class="form-control" #firstName id="firstName" type="text" inputmode="text" [placeholder]="'USER.formPlaceHolders.firstName' | translate" formControlName="firstName">
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.firstName">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('firstName').hasError(error.type) && (mainFrm.get('firstName').dirty || mainFrm.get('firstName').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'USER.formHelp.firstName' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- secondName -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="origin !== 'simulator'">
      <div class="form-group">
        <label for="secondName">{{ 'USER.formLabels.secondName' | translate }}</label>
        <input class="form-control" #secondName id="secondName" type="text" inputmode="text" [placeholder]="'USER.formPlaceHolders.secondName' | translate" formControlName="secondName" (keyup.enter)="setFocus(lastName)">
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.secondName">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('secondName').hasError(error.type) && (mainFrm.get('secondName').dirty || mainFrm.get('secondName').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'USER.formHelp.secondName' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- lastName -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group">
        <label for="lastName">{{ 'USER.formLabels.lastName' | translate }} <span class="required-lbl">*</span></label>
        <input class="form-control" #lastName id="lastName" type="text" inputmode="text" [placeholder]="'USER.formPlaceHolders.lastName' | translate" formControlName="lastName">
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.lastName">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('lastName').hasError(error.type) && (mainFrm.get('lastName').dirty || mainFrm.get('lastName').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'USER.formHelp.lastName' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- surname -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="origin !== 'simulator'">
      <div class="form-group">
        <label for="surname">{{ 'USER.formLabels.surname' | translate }}</label>
        <input class="form-control" #surname id="surname" type="text" inputmode="text" [placeholder]="'USER.formPlaceHolders.surname' | translate" formControlName="surname" (keyup.enter)="setFocus(birthDate)">
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.surname">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('surname').hasError(error.type) && (mainFrm.get('surname').dirty || mainFrm.get('surname').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'USER.formHelp.surname' | translate }}</small>
        </p>
      </div>
    </div>
  </div>
  <!-- birthDate / birthPlace -->
  <div class="row" *ngIf="origin !== 'simulator'">
    <!-- birthDate -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group">
        <label for="birthDate">{{ 'USER.formLabels.birthDate' | translate }} <span class="required-lbl">*</span></label>
        <input class="form-control digits" #birthDate id="birthDate" type="date" inputmode="text" [value]="maxBirthDate" formControlName="birthDate" placeholder="dd-mm-yyyy"/>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.birthDate">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('birthDate').hasError(error.type) && (mainFrm.get('birthDate').dirty || mainFrm.get('birthDate').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'USER.formHelp.birthDate' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- birthPlace -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <!-- label -->
      <div class="row p-0 m-0">
        <div class="col-12  p-0 m-0">
          <div class="form-group">
            <label class="col-form-label">
              <span *ngIf="birthPlaceCountry === null">{{ 'USER.formLabels.birthPlace' | translate }} <span class="required-lbl">*</span></span>
              <span *ngIf="birthPlaceCountryName !== null">{{ birthPlaceCountryName }}</span>
              <span *ngIf="birthPlaceStateName !== null"> - {{ birthPlaceStateName }}</span>
              <span *ngIf="birthPlaceCityName !== null"> - {{ birthPlaceCityName }}</span>
            </label>
          </div>
        </div>
      </div>
      <!-- country -->
      <div class="row" *ngIf="birthPlaceCountry === null">
        <div class="col-12">
          <div class="form-group location-selection">
            <ng-select [items]="allCountries"
              [selectOnTab]="true"
              bindLabel="countryName"
              bindValue="id"
              labelForId="birthPlaceCountrySelect"
              [placeholder]="'LOCATION.formPlaceHolders.countrySelect' | translate"
              (change)="selectCountry('birthPlace')"
              #birthPlaceCountrySelect id="birthPlaceCountrySelect" name="birthPlaceCountrySelect" formControlName="birthPlaceCountry">
            </ng-select>
          </div>
        </div>
      </div>
      <!-- state -->
      <div class="row" *ngIf="birthPlaceCountry != null && birthPlaceState === null">
        <div class="col-10">
          <div class="form-group location-selection">
            <ng-select [items]="allBirthPlaceStates"
              [selectOnTab]="true"
              bindLabel="stateName"
              bindValue="id"
              labelForId="birthPlaceStateSelect"
              [placeholder]="'LOCATION.formPlaceHolders.stateSelect' | translate"
              (change)="selectState('birthPlace')"
              #birthPlaceStateSelect id="birthPlaceStateSelect" name="birthPlaceStateSelect" formControlName="birthPlaceState">
            </ng-select>
          </div>
        </div>
        <div class="col-2">
          <a class="btn btn-primary btn-icon-only btn-location-reset" (click)="resetLocationSelect('birthPlace')">
            <app-feather-icons [icon]="'refresh-cw'"></app-feather-icons>
          </a>
        </div>
      </div>
      <!-- city -->
      <div class="row" *ngIf="birthPlaceState != null">
        <div class="col-10">
          <div class="form-group location-selection">
            <ng-select [items]="allBirthPlaceCities"
              [selectOnTab]="true"
              bindLabel="cityName"
              bindValue="id"
              labelForId="birthPlaceCitySelect"
              [placeholder]="'LOCATION.formPlaceHolders.citySelect' | translate"
              (change)="selectCity('birthPlace')"
              #birthPlaceCitySelect id="birthPlaceCitySelect" name="birthPlaceCitySelect" formControlName="birthPlaceCity">
            </ng-select>
          </div>
        </div>
        <div class="col-2">
          <a class="btn btn-primary btn-icon-only btn-location-reset" (click)="resetLocationSelect('birthPlace')">
            <app-feather-icons [icon]="'refresh-cw'"></app-feather-icons>
          </a>
        </div>
      </div>
      <!-- help -->
      <div class="row p-0 m-0">
        <div class="col-12  p-0 m-0">
          <div class="form-group location-msgs">
            <ng-container class="error-messages" *ngFor="let error of formErrorMessages.birthPlaceCity">
              <small class="text-danger error-msg" *ngIf="mainFrm.get('birthPlaceCity').hasError(error.type) && (mainFrm.get('birthPlaceCity').dirty || mainFrm.get('birthPlaceCity').touched)">
                {{ error.message }}.&nbsp;
              </small>
            </ng-container>
            <p class="help-messages" *ngIf="toggleHelp">
              <small>{{ 'HELPERS.selectThe' | translate }}&nbsp;{{ 'USER.formHelp.birthPlace' | translate }}</small>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- documentType / documentNumber -->
  <div class="row" *ngIf="origin !== 'simulator'">
    <!-- documentType -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group select-fix">
        <span class="caret-select"></span>
        <label for="documentTypeSelect" class="col-form-label">{{ 'USER.formLabels.documentType' | translate }} <span class="required-lbl">*</span></label>
        <select class="form-control" #documentTypeSelect id="documentTypeSelect" name="documentTypeSelect" formControlName="documentType" [title]="'USER.formLabels.documentTypeSelect' | translate">
          <option value="null" selected disabled>{{ 'USER.formLabels.documentTypeSelect' | translate }}</option>
          <option value="id">{{ 'USER.formLabels.documentTypeCedula' | translate }}</option>
          <option value="foreign">{{ 'USER.formLabels.documentTypeForeign' | translate }}</option>
          <option value="workPermission">{{ 'USER.formLabels.documentTypeWorkPermit' | translate }}</option>
          <option value="passport">{{ 'USER.formLabels.documentTypePassport' | translate }}</option>
        </select>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.documentType">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('documentType').hasError(error.type) && (mainFrm.get('documentType').dirty || mainFrm.get('documentType').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'USER.formHelp.documentType' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- documentNumber -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group">
        <label for="documentNumber">{{ 'USER.formLabels.documentNumber' | translate }} <span class="required-lbl">*</span></label>
        <input class="form-control" #documentNumber id="documentNumber" type="text" inputmode="text" [placeholder]="'USER.formPlaceHolders.documentNumber' | translate" formControlName="documentNumber">
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.documentNumber">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('documentNumber').hasError(error.type) && (mainFrm.get('documentNumber').dirty || mainFrm.get('documentNumber').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'USER.formHelp.documentNumber' | translate }}</small>
        </p>
      </div>
    </div>
  </div>
  <!-- documentExpeditionCity / documentExpeditionDate -->
  <div class="row" *ngIf="origin !== 'simulator'">
    <!-- documentExpeditionCity -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <!-- label -->
      <div class="row p-0 m-0">
        <div class="col-12  p-0 m-0">
          <div class="form-group">
            <label class="col-form-label">
              <span *ngIf="documentExpeditionCountry === null">{{ 'USER.formLabels.documentExpeditionPlace' | translate }} <span class="required-lbl">*</span></span>
              <span *ngIf="documentExpeditionCountryName !== null">{{ documentExpeditionCountryName }}</span>
              <span *ngIf="documentExpeditionStateName !== null"> - {{ documentExpeditionStateName }}</span>
              <span *ngIf="documentExpeditionCityName !== null"> - {{ documentExpeditionCityName }}</span>
            </label>
          </div>
        </div>
      </div>
      <!-- country -->
      <div class="row" *ngIf="documentExpeditionCountry === null">
        <div class="col-12">
          <div class="form-group location-selection">
            <ng-select [items]="allCountries"
              [selectOnTab]="true"
              bindLabel="countryName"
              bindValue="id"
              labelForId="documentExpeditionCountrySelect"
              [placeholder]="'LOCATION.formPlaceHolders.countrySelect' | translate"
              (change)="selectCountry('documentExpedition')"
              #documentExpeditionCountrySelect id="documentExpeditionCountrySelect" name="documentExpeditionCountrySelect" formControlName="documentExpeditionCountry">
            </ng-select>
          </div>
        </div>
      </div>
      <!-- state -->
      <div class="row" *ngIf="documentExpeditionCountry != null && documentExpeditionState === null">
        <div class="col-10">
          <div class="form-group location-selection">
            <ng-select [items]="allDocumentExpeditionStates"
              [selectOnTab]="true"
              bindLabel="stateName"
              bindValue="id"
              labelForId="documentExpeditionStateSelect"
              [placeholder]="'LOCATION.formPlaceHolders.stateSelect' | translate"
              (change)="selectState('documentExpedition')"
              #documentExpeditionStateSelect id="documentExpeditionStateSelect" name="documentExpeditionStateSelect" formControlName="documentExpeditionState">
            </ng-select>
          </div>
        </div>
        <div class="col-2">
          <a class="btn btn-primary btn-icon-only btn-location-reset" (click)="resetLocationSelect('documentExpedition')">
            <app-feather-icons [icon]="'refresh-cw'"></app-feather-icons>
          </a>
        </div>
      </div>
      <!-- city -->
      <div class="row" *ngIf="documentExpeditionState != null">
        <div class="col-10">
          <div class="form-group location-selection">
            <ng-select [items]="allDocumentExpeditionCities"
              [selectOnTab]="true"
              bindLabel="cityName"
              bindValue="id"
              labelForId="documentExpeditionCitySelect"
              [placeholder]="'LOCATION.formPlaceHolders.citySelect' | translate"
              (change)="selectCity('documentExpedition')"
              #documentExpeditionCitySelect id="documentExpeditionCitySelect" name="documentExpeditionCitySelect" formControlName="documentExpeditionCity">
            </ng-select>
          </div>
        </div>
        <div class="col-2">
          <a class="btn btn-primary btn-icon-only btn-location-reset" (click)="resetLocationSelect('documentExpedition')">
            <app-feather-icons [icon]="'refresh-cw'"></app-feather-icons>
          </a>
        </div>
      </div>
      <!-- help -->
      <div class="row p-0 m-0">
        <div class="col-12  p-0 m-0">
          <div class="form-group location-msgs">
            <ng-container class="error-messages" *ngFor="let error of formErrorMessages.documentExpeditionCity">
              <small class="text-danger error-msg" *ngIf="mainFrm.get('documentExpeditionCity').hasError(error.type) && (mainFrm.get('documentExpeditionCity').dirty || mainFrm.get('documentExpeditionCity').touched)">
                {{ error.message }}.&nbsp;
              </small>
            </ng-container>
            <p class="help-messages" *ngIf="toggleHelp">
              <small>{{ 'HELPERS.selectThe' | translate }}&nbsp;{{ 'USER.formHelp.documentExpeditionPlace' | translate }}</small>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- documentExpeditionDate -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group">
        <label for="documentExpeditionDate">{{ 'USER.formLabels.documentExpeditionDate' | translate }} <span class="required-lbl">*</span></label>
        <input class="form-control digits" #documentExpeditionDate id="documentExpeditionDate" type="date" inputmode="text" formControlName="documentExpeditionDate"/>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.documentExpeditionDate">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('documentExpeditionDate').hasError(error.type) && (mainFrm.get('documentExpeditionDate').dirty || mainFrm.get('documentExpeditionDate').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'USER.formHelp.documentExpeditionDate' | translate }}</small>
        </p>
      </div>
    </div>
  </div>
  <!-- gender / civilState -->
  <div class="row" *ngIf="origin !== 'simulator'">
    <!-- gender -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group select-fix">
        <span class="caret-select"></span>
        <label for="genderSelect" class="col-form-label">{{ 'USER.formLabels.gender' | translate }} <span class="required-lbl">*</span></label>
        <select class="form-control" #genderSelect id="genderSelect" name="genderSelect" formControlName="gender" [title]="'USER.formLabels.genderSelect' | translate">
          <option value="null" selected disabled>{{ 'USER.formPlaceHolders.gender' | translate }}</option>
          <option value="male">{{ 'HELPERS.genderMale' | translate }}</option>
          <option value="female">{{ 'HELPERS.genderFemale' | translate }}</option>
        </select>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.gender">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('gender').hasError(error.type) && (mainFrm.get('gender').dirty || mainFrm.get('gender').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'USER.formHelp.gender' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- civilState -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group select-fix">
        <span class="caret-select"></span>
        <label for="civilStateSelect" class="col-form-label">{{ 'USER.formLabels.civilState' | translate }} <span class="required-lbl">*</span></label>
        <select class="form-control" #civilStateSelect id="civilStateSelect" name="civilStateSelect" formControlName="civilState" [title]="'USER.formLabels.civilStateSelect' | translate">
          <option value="null" selected disabled>{{ 'USER.formPlaceHolders.civilState' | translate }}</option>
          <option value="single">{{ 'USER.formLabels.civilStateSingle' | translate }}</option>
          <option value="married">{{ 'USER.formLabels.civilStateMarried' | translate }}</option>
          <option value="divorced">{{ 'USER.formLabels.civilStateDivorced' | translate }}</option>
          <option value="widow">{{ 'USER.formLabels.civilStateWidow' | translate }}</option>
          <option value="freeUnion">{{ 'USER.formLabels.civilStateFreeUnion' | translate }}</option>
        </select>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.civilState">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('civilState').hasError(error.type) && (mainFrm.get('civilState').dirty || mainFrm.get('civilState').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'USER.formHelp.civilState' | translate }}</small>
        </p>
      </div>
    </div>
  </div>
  <!-- nationality -->
  <div class="row" *ngIf="origin !== 'simulator'">
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group">
        <label for="nationalitySelect" class="col-form-label">{{ 'USER.formLabels.nationality' | translate }} <span class="required-lbl">*</span></label>
        <ng-select [items]="allCountries"
          [selectOnTab]="true"
          bindLabel="countryName"
          bindValue="id"
          labelForId="nationalitySelect"
          [placeholder]="'USER.formPlaceHolders.nationality' | translate"
          #nationalitySelect id="nationalitySelect" name="nationalitySelect" formControlName="nationality">
        </ng-select>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.nationality">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('nationality').hasError(error.type) && (mainFrm.get('nationality').dirty || mainFrm.get('nationality').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'USER.formHelp.nationality' | translate }}</small>
        </p>
      </div>
    </div>
  </div>
  <!-- account management -->
  <div *ngIf="fullData">
    <!-- header -->
    <div class="row">
      <div class="col-12">
        <h4>{{ 'USER.userAdminTitle' | translate }}</h4>
        <p>{{ 'USER.userAdminTxt' | translate }}</p>
      </div>
    </div>
    <!-- roles / status -->
    <div class="row mt-4">
      <!-- status -->
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="userEditStatus">
        <div class="form-group select-fix">
          <span class="caret-select"></span>
          <label for="statusSelect" class="col-form-label">{{ 'USER.formLabels.status' | translate }} <span class="required-lbl">*</span></label>
          <select class="form-control" #statusSelect id="statusSelect" name="statusSelect" formControlName="status" [title]="'STATUSES.selectStatus' | translate">
            <option value="null" selected disabled>{{ 'STATUSES.selectStatus' | translate }}</option>
            <option value="pending">{{ 'STATUSES.pending' | translate }}</option>
            <option value="active">{{ 'STATUSES.active' | translate }}</option>
            <option value="unactive">{{ 'STATUSES.unactive' | translate }}</option>
          </select>
          <ng-container class="error-messages" *ngFor="let error of formErrorMessages.status">
            <small class="text-danger error-msg" *ngIf="mainFrm.get('status').hasError(error.type) && (mainFrm.get('status').dirty || mainFrm.get('status').touched)">
              {{ error.message }}.&nbsp;
            </small>
          </ng-container>
          <p class="help-messages" *ngIf="toggleHelp">
            <small>{{ 'USER.formHelp.status' | translate }}</small>
          </p>
        </div>
      </div>
      <!-- roles -->
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="(userEditFinancial || userEditCompany)">
        <div class="form-group">
          <label for="rolesSelect" class="col-form-label">{{ 'USER.formLabels.roles' | translate }} <span class="required-lbl">*</span></label>
          <ng-select [items]="allRoles"
            [selectOnTab]="true"
            [multiple]="true"
            bindLabel="roleName"
            bindValue="id"
            labelForId="rolesSelect"
            [placeholder]="'USER.formLabels.rolesSelect' | translate"
            #rolesSelect id="rolesSelect" name="rolesSelect" formControlName="roles">
          </ng-select>
          <ng-container class="error-messages" *ngFor="let error of formErrorMessages.roles">
            <small class="text-danger error-msg" *ngIf="mainFrm.get('roles').hasError(error.type) && (mainFrm.get('roles').dirty || mainFrm.get('roles').touched)">
              {{ error.message }}.&nbsp;
            </small>
          </ng-container>
          <p class="help-messages" *ngIf="toggleHelp">
            <small>{{ 'USER.formHelp.roles' | translate }}</small>
          </p>
        </div>
      </div>
    </div>
  </div>
</form>
<!-- pwd form -->
<form class="theme-form" [formGroup]="pwdFrm" *ngIf="!(origin === 'userB2C' || origin === 'userB2B' || origin === 'simulator' || origin === 'editUserLoan')">
  <div class="row" *ngIf="userShowPwdEdit">
    <!-- pwd -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group">
        <label for="password">{{ 'USER.formLabels.passwordNew' | translate }}</label>
        <input class="form-control" #password id="password" [type]="showPwd ? 'text' : 'password'" inputmode="text" placeholder="*********" formControlName="password" (keyup.enter)="setFocus(confirmPwd)">
        <div class="show-hide" (click)="togglePwd()">
          <app-feather-icons *ngIf="!showPwd" [icon]="'eye'"></app-feather-icons>
          <app-feather-icons *ngIf="showPwd"  [icon]="'eye-off'"></app-feather-icons>
        </div>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.password">
          <small class="text-danger" *ngIf="pwdFrm.get('password').hasError(error.type) && (pwdFrm.get('password').dirty || pwdFrm.get('password').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'USER.formHelp.passwordLogin' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- confirmPwd -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group">
        <label for="confirmPwd">{{ 'USER.formLabels.confirmPwd' | translate }}</label>
        <input class="form-control" #confirmPwd id="confirmPwd" [type]="showConfirmPwd ? 'text' : 'password'" inputmode="text" placeholder="*********" formControlName="confirmPwd">
        <div class="show-hide" (click)="toggleConfirmPwd()">
          <app-feather-icons *ngIf="!showConfirmPwd" [icon]="'eye'"></app-feather-icons>
          <app-feather-icons *ngIf="showConfirmPwd"  [icon]="'eye-off'"></app-feather-icons>
        </div>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.confirmPwd">
          <small class="text-danger" *ngIf="pwdFrm.get('confirmPwd').hasError(error.type) && (pwdFrm.get('confirmPwd').dirty || pwdFrm.get('confirmPwd').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'USER.formHelp.confirmPwd' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- password mismatch -->
    <div class="col-12 password-mismatch" *ngIf="passwordMismatch">
      <ng-container class="error-messages">
        <small class="text-danger">
          {{ 'USER.formValidation.password.match' | translate }}
        </small>
      </ng-container>
    </div>
  </div>
</form>
<!-- buttons -->
<div class="row mt-4">
  <div class="col">
    <div class="request-form-btns-container">
      <button class="btn btn-light d-block btn-cancel" (click)="goBack()" type="button">
        <span *ngIf="(origin === 'userB2C' || origin === 'userB2B' || origin === 'simulator')">{{ 'HELPERS.back' | translate }}</span>
        <span *ngIf="!(origin === 'userB2C' || origin === 'userB2B' || origin === 'simulator')">{{ 'HELPERS.cancel' | translate }}</span>
      </button>
      <button class="btn btn-dark d-block" (click)="save()" type="button" *ngIf="origin !== 'simulator'">
        <span *ngIf="(origin === 'userB2C' || origin === 'userB2B')">{{ 'HELPERS.next' | translate }}</span>
        <span *ngIf="!(origin === 'userB2C' || origin === 'userB2B' || origin === 'simulator')">{{ 'HELPERS.save' | translate }}</span>
      </button>
      <button class="btn btn-dark d-block" (click)="save()" type="button" *ngIf="origin === 'simulator'">
        <span>{{ 'LOAN.requestSend' | translate }}</span>
      </button>
    </div>
  </div>
</div>
<!-- avatar crop modal -->
<ng-template #avatarCropModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Avatar</h4>
    <button type="button" class="close" aria-label="Close" (click)="avatarCroppingClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <image-cropper 
      [imageChangedEvent]="avatarChangedEvent" 
      [maintainAspectRatio]="true"
      [containWithinAspectRatio]="containWithinAspectRatio" 
      [aspectRatio]="1 / 1" 
      [resizeToWidth]="320"
      [cropperMinWidth]="128" 
      [onlyScaleDown]="true" 
      [roundCropper]="true"
      [canvasRotation]="canvasRotation" 
      [transform]="transform" 
      [alignImage]="'center'"
      format="png" 
      (imageCropped)="imageCropped($event)"
      (loadImageFailed)="imageLoadImageFailed()">
    </image-cropper>
    <div class="btn-showcase">
      <button class="btn btn-xs btn-primary btn-icon-only" (click)="rotateLeft()"><app-feather-icons [icon]="'rotate-ccw'"></app-feather-icons></button>
      <button class="btn btn-xs btn-primary btn-icon-only" (click)="rotateRight()"><app-feather-icons [icon]="'rotate-cw'"></app-feather-icons></button>
      <!-- <button class="btn btn-xs btn-primary btn-icon-only" (click)="flipHorizontal()"><app-feather-icons [icon]="'more-horizontal'"></app-feather-icons></button>
      <button class="btn btn-xs btn-primary btn-icon-only" (click)="flipVertical()"><app-feather-icons [icon]="'more-vertical'"></app-feather-icons></button> -->
      <button class="btn btn-xs btn-primary btn-icon-only" (click)="zoomOut()"><app-feather-icons [icon]="'zoom-out'"></app-feather-icons></button>
      <button class="btn btn-xs btn-primary btn-icon-only" (click)="zoomIn()"><app-feather-icons [icon]="'zoom-in'"></app-feather-icons></button>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-light d-block" (click)="avatarCroppingClose()" type="button">
      <span>{{ 'HELPERS.cancel' | translate }}</span>
    </button>
    <button class="btn btn-dark d-block" (click)="avatarCroppingFinish()" type="button">
      <span>{{ 'HELPERS.save' | translate }}</span>
    </button>
  </div>
</ng-template>