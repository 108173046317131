<div class="card o-hidden profile-greeting">
  <div class="card-body">
    <div class="media">
      <div class="badge-groups w-100">
        <div class="badge f-12">
          <app-feather-icons [icon]="'clock'"></app-feather-icons>
          <span id="txt" class="ms-1">{{ time }}</span>
        </div>
      </div>
    </div>
    <div class="greeting-user text-center">
      <!-- avatar -->
      <div class="profile-vector">
        <img class="home-avatar" [src]="avatar" [alt]="firstName" onError="src = 'https://credipremium.co/wp-content/uploads/logos/icon-svg-orange.svg'">
      </div>
      <!-- greeting -->
      <h4 class="f-w-600">
        <span id="greeting">{{ greeting }}&nbsp;{{ firstName }}</span>
        <span class="right-circle ms-1">
          <i class="fa fa-check-circle f-14 middle"></i>
        </span>
      </h4>
      <!-- phrase -->
      <p class="phrase-txt">{{ inspirationalPhrase }}</p>
      <!-- requests -->
      <button *ngIf="totalRequests > 0" type="button" [routerLink]="urlData" routerLinkActive="router-link-active" class="btn btn-loans btn-white">
        <span *ngIf="requestString === 'LOAN.loans'">{{ requestString | translate }}</span>
        <span *ngIf="requestString !== 'LOAN.loans'">{{ 'LOAN.loans' | translate }}&nbsp;{{ requestString | translate }}</span>
      </button>
      <p class="requests-total-txt" *ngIf="false">
        <span *ngIf="totalRequests == 0">{{ 'HOME.greetingNoRequests' | translate }}</span>
        <span *ngIf="totalRequests == 1">{{ 'HOME.greeting1Request' | translate }}</span>
        <span *ngIf="totalRequests > 1">{{ 'HOME.greeting1' | translate }}&nbsp;{{ totalRequests | number }}&nbsp;{{ 'HOME.greeting2' | translate }}</span>
      </p>
    </div>
  </div>
</div>
