// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 25/05/2023
// tslint:disable: max-line-length
// tslint:disable: no-unused-expression
// import
import { Pipe, PipeTransform } from '@angular/core';
// pipe
@Pipe({
  name: 'leadingZerosPipe'
})
// class
export class LeadingZerosPipe implements PipeTransform {
  // variables
  mvalue: string;
  // transform
  transform(value: string, ...args: any[]): any {
    var s = value+"";
    var size = args[0];
    while (s.length < size) s = "0" + s;
    return s;
  }

}
