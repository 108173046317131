<div class="chart-wrapper">
  <!-- line -->
  <!-- <canvas baseChart 
    *ngIf="graphType === 'line'"
    [datasets]="chartData" 
    [labels]="chartLabels" 
    [options]="chartOptions"
    [colors]="chartColors" 
    [legend]="chartLegend" 
    [chartType]="chartType" 
    [plugins]="chartPlugins">
  </canvas> -->
  <!-- bar -->
  <canvas baseChart 
    *ngIf="graphType === 'bar'"
    [datasets]="chartData"
    [labels]="chartLabels"
    [options]="chartOptions"
    [plugins]="chartPlugins"
    [legend]="chartLegend"
    [chartType]="chartType">
  </canvas>
  <!-- bar horizontal -->
  <!-- <canvas baseChart 
    *ngIf="graphType === 'bar-h'"
    [datasets]="chartData"
    [labels]="chartLabels"
    [options]="chartOptions"
    [plugins]="chartPlugins"
    [legend]="chartLegend"
    [chartType]="chartType">
  </canvas> -->
  <!-- doughnut -->
  <!-- <canvas baseChart 
    *ngIf="graphType === 'doughnut'"
    [data]="chartData"
    [labels]="chartLabels"
    [chartType]="chartType">
  </canvas> -->
  <!-- radar -->
  <!-- <canvas baseChart 
    *ngIf="graphType === 'radar'"
    [datasets]="chartData"
    [options]="chartOptions"
    [labels]="chartLabels"
    [chartType]="chartType">
  </canvas> -->
  <!-- pie -->
  <!-- <canvas baseChart 
    *ngIf="graphType === 'pie'"
    [data]="chartData" 
    [labels]="chartLabels" 
    [chartType]="chartType"
    [colors]="chartColors"
    [options]="chartOptions"
    [plugins]="chartPlugins"
    [legend]="chartLegend">
  </canvas> -->
  <!-- bubble -->
  <!-- <canvas baseChart 
    *ngIf="graphType === 'bubble'"
    [datasets]="chartData"
    [options]="chartOptions"
    [colors]="chartColors"
    [legend]="chartLegend"
    [chartType]="chartType">
  </canvas> -->
</div>