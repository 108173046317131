// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 25/05/2023
// tslint:disable: max-line-length
// tslint:disable: no-unused-expression
// import
import { Pipe, PipeTransform } from '@angular/core';
// pipe
@Pipe({
  name: 'maskSensitiveData'
})
// class
export class MaskSensitiveDataPipe implements PipeTransform {
  transform(value: string, visibleChars: number = 4, maskChar: string = '*'): string {
    if (!value) {
      return '';
    }
    const visiblePart = value.slice(-visibleChars);
    const maskedPart = maskChar.repeat(value.length - visibleChars);
    return maskedPart + visiblePart;
  }

}
