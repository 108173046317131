// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 29/05/2023
// import
import { Component, Input, OnInit } from '@angular/core';
// environment
import { environment } from 'src/environments/environment';
// translate
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  // variables
  @Input() lineation: string = 'center';
  public today: number = Date.now();
  version = environment.version;
  // constructor
  constructor(
    public translate: TranslateService,
  ) { }
  // life cycle
  ngOnInit(): void {
  }
}
