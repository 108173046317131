// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 30/05/2023
// import
import { Component, EventEmitter, Input, OnInit, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
// translate
import { TranslateService } from '@ngx-translate/core';
// services
import { AuthService } from 'src/app/shared/services/auth.service';
import { EncodingService } from 'src/app/shared/services/encoding.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
// interfaces
import { UserInterface } from 'src/app/shared/interfaces/user.interface';
import { UserFinancialInterface } from 'src/app/shared/interfaces/user-financial.interface';
// component
@Component({
  selector: 'app-user-financial-form',
  templateUrl: './user-financial-form.component.html',
  styleUrls: ['./user-financial-form.component.scss']
})
// class
export class UserFinancialFormComponent implements OnInit, OnChanges {
  // variables
  @Input() userData: UserInterface = null;
  @Input() salary: number = 0;
  @Input() userFinancialData: UserFinancialInterface = null;
  @Input() workActivity: string = null;
  @Input() origin: string = null;
  @Output() loaderEvent = new EventEmitter();
  @Output() saveEvent = new EventEmitter();
  @Output() backEvent = new EventEmitter();
  // form data
  public mainFrm: FormGroup;
  // messages data
  formErrorMessages: any = {};
  // help data
  toggleHelp = false;
  // financial data
  userFinancial: UserFinancialInterface = null;
  savedData: boolean = false;
  totalIncome: number = 0;
  totalOutcome: number = 0;
  // constructor
  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public authSv: AuthService,
    public encodingSv: EncodingService,
    public apiSv: ApiService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    private utilitiesSv: UtilitiesService,
  ) {
    // main form
    this.mainFrm = this.formBuilder.group({
      professionalFees: [null, [this.checkMinValue(0), this.checkMaxValue(9999999999)]],
      otherIncome: [null, [this.checkMinValue(0), this.checkMaxValue(9999999999)]],
      otherIncomeDescription: [null],
      rent: [null, [this.checkMinValue(0), this.checkMaxValue(9999999999)]],
      familyExpenses: [null, [this.checkMinValue(0), this.checkMaxValue(9999999999)]],
      credits: [null, [this.checkMinValue(0), this.checkMaxValue(9999999999)]],
      otherExpenses: [null, [this.checkMinValue(0), this.checkMaxValue(9999999999)]],
      otherExpensesDescription: [null],
      publicResourcesHandling: [false, [Validators.required]],
      otherAssets: [null, [this.checkMinValue(0), this.checkMaxValue(9999999999)]],
      otherAssetsDescription: [null],
      otherLiabilities: [null, [this.checkMinValue(0), this.checkMaxValue(9999999999)]],
      otherLiabilitiesDescription: [null],
      originFunds: [null, [Validators.required]],
      dependents: [null, [Validators.required, this.checkMinValue(0)]],
      housingType: [null, Validators.required],
      userFinancialActive: [true, [Validators.required]],
    });
    // check for form changes
    this.mainFrm.valueChanges.subscribe((val) => {
      // check professionalFees
      if (typeof val.professionalFees === 'string') {
        // mask value
        const maskedVal = this.utilitiesSv.convertStringToCurrency(val.professionalFees);
        // check matched values
        if (val.professionalFees !== maskedVal) {
          this.mainFrm.patchValue({professionalFees: maskedVal});
        }
      }
      // check otherIncome
      if (typeof val.otherIncome === 'string') {
        // mask value
        const maskedVal =  this.utilitiesSv.convertStringToCurrency(val.otherIncome);
        // check matched values
        if (val.otherIncome !== maskedVal) {
          this.mainFrm.patchValue({otherIncome: maskedVal});
        }
      }
      // check rent
      if (typeof val.rent === 'string') {
        // mask value
        const maskedVal = this.utilitiesSv.convertStringToCurrency(val.rent);
        // check matched values
        if (val.rent !== maskedVal) {
          this.mainFrm.patchValue({rent: maskedVal});
        }
      }
      // check familyExpenses
      if (typeof val.familyExpenses === 'string') {
        // mask value
        const maskedVal = this.utilitiesSv.convertStringToCurrency(val.familyExpenses);
        // check matched values
        if (val.familyExpenses !== maskedVal) {
          this.mainFrm.patchValue({familyExpenses: maskedVal});
        }
      }
      // check credits
      if (typeof val.credits === 'string') {
        // mask value
        const maskedVal = this.utilitiesSv.convertStringToCurrency(val.credits);
        // check matched values
        if (val.credits !== maskedVal) {
          this.mainFrm.patchValue({credits: maskedVal});
        }
      }
      // check otherExpenses
      if (typeof val.otherExpenses === 'string') {
        // mask value
        const maskedVal = this.utilitiesSv.convertStringToCurrency(val.otherExpenses);
        // check matched values
        if (val.otherExpenses !== maskedVal) {
          this.mainFrm.patchValue({otherExpenses: maskedVal});
        }
      }
      // check otherAssets
      if (typeof val.otherAssets === 'string') {
        // mask value
        const maskedVal = this.utilitiesSv.convertStringToCurrency(val.otherAssets);
        // check matched values
        if (val.otherAssets !== maskedVal) {
          this.mainFrm.patchValue({otherAssets: maskedVal});
        }
      }
      // check otherLiabilities
      if (typeof val.otherLiabilities === 'string') {
        // mask value
        const maskedVal = this.utilitiesSv.convertStringToCurrency(val.otherLiabilities);
        // check matched values
        if (val.otherLiabilities !== maskedVal) {
          this.mainFrm.patchValue({otherLiabilities: maskedVal});
        }
      }
      // updateIncome
      this.updateIncome();
      // updateOutcome
      this.updateOutcome();
    });
  }
  // life cycle
  async ngOnInit() {
    // translate data
    await this.translateMsgs();
  }
  async ngOnChanges(changes: SimpleChanges) {
    // check salary
    if (changes.salary) {
      // updateIncome
      this.updateIncome();
    }
    if (changes.userFinancialData) {
      // get data
      await this.setInitialData();
      await this.setUserFinancialData();
    }
    if (changes.workActivity) {
      // get data
      await this.setInitialData();
      await this.setUserFinancialData();
    }
  }
  // translate
  async translateMsgs () {
    this.formErrorMessages = {
      dependents: [
        { type: 'required', message: this.translate.instant('USERFINANTIALS.formValidation.dependents.required') },
        { type: 'minValue', message: this.translate.instant('USERFINANTIALS.formValidation.dependents.minLength') },
      ],
      housingType: [
        { type: 'required', message: this.translate.instant('USERFINANTIALS.formValidation.housingType.required') },
      ],
      professionalFees: [
        { type: 'minValue', message: this.translate.instant('USERFINANTIALS.formValidation.professionalFees.minLength') },
        { type: 'maxValue', message: this.translate.instant('USERFINANTIALS.formValidation.professionalFees.maxLength') },
      ],
      income: [
        { type: 'required', message: this.translate.instant('USERFINANTIALS.formValidation.income.required') },
        { type: 'minValue', message: this.translate.instant('USERFINANTIALS.formValidation.income.minLength') },
        { type: 'maxValue', message: this.translate.instant('USERFINANTIALS.formValidation.income.maxLength') },
      ],
      otherIncome: [
        { type: 'minValue', message: this.translate.instant('USERFINANTIALS.formValidation.otherIncome.minLength') },
        { type: 'maxValue', message: this.translate.instant('USERFINANTIALS.formValidation.otherIncome.maxLength') },
      ],
      rent: [
        { type: 'minValue', message: this.translate.instant('USERFINANTIALS.formValidation.rent.minLength') },
        { type: 'maxValue', message: this.translate.instant('USERFINANTIALS.formValidation.rent.maxLength') },
      ],
      familyExpenses: [
        { type: 'minValue', message: this.translate.instant('USERFINANTIALS.formValidation.familyExpenses.minLength') },
        { type: 'maxValue', message: this.translate.instant('USERFINANTIALS.formValidation.familyExpenses.maxLength') },
      ],
      credits: [
        { type: 'minValue', message: this.translate.instant('USERFINANTIALS.formValidation.credits.minLength') },
        { type: 'maxValue', message: this.translate.instant('USERFINANTIALS.formValidation.credits.maxLength') },
      ],
      expenses: [
        { type: 'required', message: this.translate.instant('USERFINANTIALS.formValidation.expenses.required') },
        { type: 'minValue', message: this.translate.instant('USERFINANTIALS.formValidation.expenses.minLength') },
        { type: 'maxValue', message: this.translate.instant('USERFINANTIALS.formValidation.expenses.maxLength') },
      ],
      otherExpenses: [
        { type: 'minValue', message: this.translate.instant('USERFINANTIALS.formValidation.otherExpenses.minLength') },
        { type: 'maxValue', message: this.translate.instant('USERFINANTIALS.formValidation.otherExpenses.maxLength') },
      ],
      publicResourcesHandling: [
        { type: 'required', message: this.translate.instant('USERFINANTIALS.formValidation.publicResourcesHandling.required') },
      ],
      otherAssets: [
        { type: 'required', message: this.translate.instant('USERFINANTIALS.formValidation.otherAssets.required') },
        { type: 'minValue', message: this.translate.instant('USERFINANTIALS.formValidation.otherAssets.minLength') },
        { type: 'maxValue', message: this.translate.instant('USERFINANTIALS.formValidation.otherAssets.maxLength') },
      ],
      otherLiabilities: [
        { type: 'required', message: this.translate.instant('USERFINANTIALS.formValidation.otherLiabilities.required') },
        { type: 'minValue', message: this.translate.instant('USERFINANTIALS.formValidation.otherLiabilities.minLength') },
        { type: 'maxValue', message: this.translate.instant('USERFINANTIALS.formValidation.otherLiabilities.maxLength') },
      ],
      originFunds: [
        { type: 'required', message: this.translate.instant('USERFINANTIALS.formValidation.originFunds.required') },
      ],
    };
  }
  // navigation
  async goBack() {
    // emit data
    this.backEvent.emit(); // Return dat
  }
  // data
  async setInitialData() {
    // check workActivity
    if (this.workActivity === 'business') {
      // professionalFees
      this.mainFrm.get('professionalFees')?.setValidators(null);
      this.mainFrm.controls['professionalFees'].updateValueAndValidity();
      // rent
      this.mainFrm.get('rent')?.setValidators(null);
      this.mainFrm.controls['rent'].updateValueAndValidity();
      // familyExpenses
      this.mainFrm.get('familyExpenses')?.setValidators(null);
      this.mainFrm.controls['familyExpenses'].updateValueAndValidity();
      // credits
      this.mainFrm.get('credits')?.setValidators(null);
      this.mainFrm.controls['credits'].updateValueAndValidity();
      // publicResourcesHandling
      this.mainFrm.get('publicResourcesHandling')?.setValidators(null);
      this.mainFrm.controls['publicResourcesHandling'].updateValueAndValidity();
      // originFunds
      this.mainFrm.get('originFunds')?.setValidators(null);
      this.mainFrm.controls['originFunds'].updateValueAndValidity();
      // dependents
      this.mainFrm.get('dependents')?.setValidators(null);
      this.mainFrm.controls['dependents'].updateValueAndValidity();
      // housingType
      this.mainFrm.get('housingType')?.setValidators(null);
      this.mainFrm.controls['housingType'].updateValueAndValidity();
      // userFinancialActive
      this.mainFrm.get('userFinancialActive')?.setValidators(null);
      this.mainFrm.controls['userFinancialActive'].updateValueAndValidity();
      // otherIncome
      this.mainFrm.get('otherIncome')?.setValidators([Validators.required, this.checkMinValue(0), this.checkMaxValue(9999999999)]);
      this.mainFrm.controls['otherIncome'].updateValueAndValidity();
      this.mainFrm.controls.otherIncome.setValue(this.salary);
      // otherExpenses
      this.mainFrm.get('otherExpenses')?.setValidators([Validators.required, this.checkMinValue(0), this.checkMaxValue(9999999999)]);
      this.mainFrm.controls['otherExpenses'].updateValueAndValidity();
      // otherIncome
      this.mainFrm.get('otherAssets')?.setValidators([Validators.required, this.checkMinValue(0), this.checkMaxValue(9999999999)]);
      this.mainFrm.controls['otherAssets'].updateValueAndValidity();
      // otherExpenses
      this.mainFrm.get('otherLiabilities')?.setValidators([Validators.required, this.checkMinValue(0), this.checkMaxValue(9999999999)]);
      this.mainFrm.controls['otherLiabilities'].updateValueAndValidity();
      // set form data
      this.mainFrm.controls.professionalFees.setValue(0);
      this.mainFrm.controls.rent.setValue(0);
      this.mainFrm.controls.familyExpenses.setValue(0);
      this.mainFrm.controls.credits.setValue(0);
      this.mainFrm.controls.otherIncomeDescription.setValue(null);
      this.mainFrm.controls.otherExpensesDescription.setValue(null);
      this.mainFrm.controls.publicResourcesHandling.setValue(false);
      this.mainFrm.controls.otherAssetsDescription.setValue(null);
      this.mainFrm.controls.otherLiabilitiesDescription.setValue(null);
      this.mainFrm.controls.originFunds.setValue('NULL');
      this.mainFrm.controls.dependents.setValue(0);
      this.mainFrm.controls.housingType.setValue('own');
    } else {
      // professionalFees
      this.mainFrm.get('professionalFees')?.setValidators([this.checkMinValue(0), this.checkMaxValue(9999999999)]);
      this.mainFrm.controls['professionalFees'].updateValueAndValidity();
      // rent
      this.mainFrm.get('rent')?.setValidators([this.checkMinValue(0), this.checkMaxValue(9999999999)]);
      this.mainFrm.controls['rent'].updateValueAndValidity();
      // familyExpenses
      this.mainFrm.get('familyExpenses')?.setValidators([this.checkMinValue(0), this.checkMaxValue(9999999999)]);
      this.mainFrm.controls['familyExpenses'].updateValueAndValidity();
      // credits
      this.mainFrm.get('credits')?.setValidators([this.checkMinValue(0), this.checkMaxValue(9999999999)]);
      this.mainFrm.controls['credits'].updateValueAndValidity();
      // publicResourcesHandling
      this.mainFrm.get('publicResourcesHandling')?.setValidators([this.checkMinValue(0), this.checkMaxValue(9999999999)]);
      this.mainFrm.controls['publicResourcesHandling'].updateValueAndValidity();
      // originFunds
      this.mainFrm.get('originFunds')?.setValidators([Validators.required]);
      this.mainFrm.controls['originFunds'].updateValueAndValidity();
      // dependents
      this.mainFrm.get('dependents')?.setValidators([Validators.required, this.checkMinValue(0)]);
      this.mainFrm.controls['dependents'].updateValueAndValidity();
      // housingType
      this.mainFrm.get('housingType')?.setValidators([Validators.required]);
      this.mainFrm.controls['housingType'].updateValueAndValidity();
      // userFinancialActive
      this.mainFrm.get('userFinancialActive')?.setValidators([Validators.required]);
      this.mainFrm.controls['userFinancialActive'].updateValueAndValidity();
      // otherIncome
      this.mainFrm.get('otherIncome')?.setValidators([this.checkMinValue(0), this.checkMaxValue(9999999999)]);
      this.mainFrm.controls['otherIncome'].updateValueAndValidity();
      // otherExpenses
      this.mainFrm.get('otherExpenses')?.setValidators([this.checkMinValue(0), this.checkMaxValue(9999999999)]);
      this.mainFrm.controls['otherExpenses'].updateValueAndValidity();
      // otherIncome
      this.mainFrm.get('otherAssets')?.setValidators([this.checkMinValue(0), this.checkMaxValue(9999999999)]);
      this.mainFrm.controls['otherAssets'].updateValueAndValidity();
      // otherExpenses
      this.mainFrm.get('otherLiabilities')?.setValidators([this.checkMinValue(0), this.checkMaxValue(9999999999)]);
      this.mainFrm.controls['otherLiabilities'].updateValueAndValidity();
    }
  }
  async setUserFinancialData() {
    // check for userFinancialData
    if (this.userFinancialData !== null) {
      // get userFinancial
      const userFinancial: UserFinancialInterface = this.userFinancialData;
      // set user financial data
      this.userFinancial = userFinancial;
      // check workActivity
      if (this.workActivity === 'business') {
        // mask value
        const otherExpensesFormatted = userFinancial.otherExpenses !== null ? this.utilitiesSv.convertStringToCurrency(userFinancial.otherExpenses.toString()) : null;
        const otherAssetsFormatted = userFinancial.otherAssets !== null ? this.utilitiesSv.convertStringToCurrency(userFinancial.otherAssets.toString()) : null;
        const otherLiabilitiesFormatted = userFinancial.otherLiabilities !== null ? this.utilitiesSv.convertStringToCurrency(userFinancial.otherLiabilities.toString()) : null;
        // set form data
        this.mainFrm.controls.otherExpenses.setValue(otherExpensesFormatted);
        this.mainFrm.controls.otherAssets.setValue(otherAssetsFormatted);
        this.mainFrm.controls.otherLiabilities.setValue(otherLiabilitiesFormatted);
      } else {
        // mask value
        const professionalFeesFormatted = userFinancial.professionalFees !== null ? this.utilitiesSv.convertStringToCurrency(userFinancial.professionalFees.toString()) : null;
        const otherIncomeFormatted = userFinancial.otherIncome !== null ? this.utilitiesSv.convertStringToCurrency(userFinancial.otherIncome.toString()) : null;
        const rentFormatted = userFinancial.rent !== null ? this.utilitiesSv.convertStringToCurrency(userFinancial.rent.toString()) : null;
        const familyExpensesFormatted = userFinancial.familyExpenses !== null ? this.utilitiesSv.convertStringToCurrency(userFinancial.familyExpenses.toString()) : null;
        const creditsFormatted = userFinancial.credits !== null ? this.utilitiesSv.convertStringToCurrency(userFinancial.credits.toString()) : null;
        const otherExpensesFormatted = userFinancial.otherExpenses !== null ? this.utilitiesSv.convertStringToCurrency(userFinancial.otherExpenses.toString()) : null;
        const otherAssetsFormatted = userFinancial.otherAssets !== null ? this.utilitiesSv.convertStringToCurrency(userFinancial.otherAssets.toString()) : null;
        const otherLiabilitiesFormatted = userFinancial.otherLiabilities !== null ? this.utilitiesSv.convertStringToCurrency(userFinancial.otherLiabilities.toString()) : null;
        // set form data
        this.mainFrm.controls.professionalFees.setValue(professionalFeesFormatted);
        this.mainFrm.controls.otherIncome.setValue(otherIncomeFormatted);
        this.mainFrm.controls.otherIncomeDescription.setValue(userFinancial.otherIncomeDescription);
        this.mainFrm.controls.rent.setValue(rentFormatted);
        this.mainFrm.controls.familyExpenses.setValue(familyExpensesFormatted);
        this.mainFrm.controls.credits.setValue(creditsFormatted);
        this.mainFrm.controls.otherExpenses.setValue(otherExpensesFormatted);
        this.mainFrm.controls.otherExpensesDescription.setValue(userFinancial.otherExpensesDescription);
        this.mainFrm.controls.publicResourcesHandling.setValue(userFinancial.publicResourcesHandling);
        this.mainFrm.controls.otherAssets.setValue(otherAssetsFormatted);
        this.mainFrm.controls.otherAssetsDescription.setValue(userFinancial.otherAssetsDescription);
        this.mainFrm.controls.otherLiabilities.setValue(otherLiabilitiesFormatted);
        this.mainFrm.controls.otherLiabilitiesDescription.setValue(userFinancial.otherLiabilitiesDescription);
        this.mainFrm.controls.originFunds.setValue(userFinancial.originFunds);
        this.mainFrm.controls.dependents.setValue(userFinancial.dependents);
        this.mainFrm.controls.housingType.setValue(userFinancial.housingType);
      }
      // set savedData
      this.savedData = true;
    } 
  }
  // form
  async help () {
    if (this.toggleHelp) {
      this.toggleHelp = false;
    } else {
      this.toggleHelp = true;
    }
  }
  async setFocus(elementId: any) {
    elementId.focus();
  }
  checkFormField(fieldName: string) {
    // get field
    const field = this.mainFrm.get(fieldName);
    // check field
    const check = field?.invalid && field?.touched;
    // return data
    return check;
  }
  checkMinValue(checkValue: number) {
    return (control: FormControl) => {
      // check value
      if (control.value !== null) {
        // get currentValue
        const currentValue: any = typeof control.value === 'string' ? this.utilitiesSv.removeDotsFromCurrencyString(control.value) : control.value;
        // check currentValue
        if (currentValue < checkValue && currentValue !== '') {
          return { minValue: true }
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }
  checkMaxValue(checkValue: number) {
    return (control: FormControl) => {
      // check value
      if (control.value !== null) {
        // get currentValue
        const currentValue: any = typeof control.value === 'string' ? parseInt(this.utilitiesSv.removeDotsFromCurrencyString(control.value)) :control.value;
        // check amountMax
        if (currentValue > checkValue && currentValue !== '') {
          return { maxValue: true }
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }
  // navigation
  async goToPage(page: string, params?: any | null){
    // navigate to page
    this.utilitiesSv.goTo(page, true, params);
  }
  // actions
  async updateOtherIncome() {
    // get otherIncome
    const otherIncome = parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(this.mainFrm.controls.otherIncome.value));
    // check workActivity
    if (this.workActivity !== 'business') {
      // check if otherIncome is more than 0
      if (otherIncome > 0) {
        this.mainFrm.get('otherIncomeDescription')?.setValidators([Validators.required]);
        this.mainFrm.controls['otherIncomeDescription'].updateValueAndValidity();
      } else {
        this.mainFrm.get('otherIncomeDescription')?.setValidators(null);
        this.mainFrm.controls['otherIncomeDescription'].updateValueAndValidity();
      }
    }
    // updateIncome
    this.updateIncome();
  }
  async updateIncome() {
    // get incomeData
    const salary: number = this.salary;
    let professionalFees: any = this.mainFrm.controls.professionalFees.value;
    professionalFees = professionalFees === null ? 0 : professionalFees;
    professionalFees = typeof professionalFees === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(professionalFees)) : parseFloat(professionalFees);
    professionalFees = (Number.isNaN(professionalFees)) ? 0 : professionalFees;
    let otherIncome: any = this.mainFrm.controls.otherIncome.value;
    otherIncome = otherIncome === null ? 0 : otherIncome;
    otherIncome = typeof otherIncome === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(otherIncome)) : parseFloat(otherIncome);
    otherIncome = (Number.isNaN(otherIncome)) ? 0 : otherIncome;
    // update totalIncome
    this.totalIncome = salary + professionalFees + otherIncome;
  }
  async updateOtherExpenses() {
    // get otherExpenses
    let otherExpenses: any = this.mainFrm.controls.otherExpenses.value;
    otherExpenses = otherExpenses === null ? 0 : otherExpenses;
    otherExpenses = typeof otherExpenses === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(otherExpenses)) : parseFloat(otherExpenses);
    otherExpenses = (Number.isNaN(otherExpenses)) ? 0 : otherExpenses;
    // check workActivity
    if (this.workActivity !== 'business') {
      // check if otherExpenses is more than 0
      if (otherExpenses > 0) {
        this.mainFrm.get('otherExpensesDescription')?.setValidators([Validators.required]);
        this.mainFrm.controls['otherExpensesDescription'].updateValueAndValidity();
      } else {
        this.mainFrm.get('otherExpensesDescription')?.setValidators(null);
        this.mainFrm.controls['otherExpensesDescription'].updateValueAndValidity();
      }
    }
    // updateOutcome
    this.updateOutcome();
  }
  async updateOutcome() {
    // get incomeData
    let rent: any = this.mainFrm.controls.rent.value;
    rent = rent === null ? 0 : rent;
    rent = typeof rent === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(rent)) : parseFloat(rent);
    rent = (Number.isNaN(rent)) ? 0 : rent;
    let familyExpenses: any = this.mainFrm.controls.familyExpenses.value;
    familyExpenses = familyExpenses === null ? 0 : familyExpenses;
    familyExpenses = typeof familyExpenses === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(familyExpenses)) : parseFloat(familyExpenses);
    familyExpenses = (Number.isNaN(familyExpenses)) ? 0 : familyExpenses;
    let credits: any = this.mainFrm.controls.credits.value;
    credits = credits === null ? 0 : credits;
    credits = typeof credits === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(credits)) : parseFloat(credits);
    credits = (Number.isNaN(credits)) ? 0 : credits;
    let otherExpenses: any = this.mainFrm.controls.otherExpenses.value;
    otherExpenses = otherExpenses === null ? 0 : otherExpenses;
    otherExpenses = typeof otherExpenses === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(otherExpenses)) : parseFloat(otherExpenses);
    otherExpenses = (Number.isNaN(otherExpenses)) ? 0 : otherExpenses;
    // update totalOutcome
    this.totalOutcome = rent + familyExpenses + credits + otherExpenses;
  }
  async updateOtherAssets() {
    // get otherAssets
    let otherAssets: any = this.mainFrm.controls.otherAssets.value;
    otherAssets = otherAssets === null ? 0 : otherAssets;
    otherAssets = typeof otherAssets === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(otherAssets)) : parseFloat(otherAssets);
    otherAssets = (Number.isNaN(otherAssets)) ? 0 : otherAssets;
    // check workActivity
    if (this.workActivity !== 'business') {
      // check if otherAssets is more than 0
      if (otherAssets > 0) {
        this.mainFrm.get('otherAssetsDescription')?.setValidators([Validators.required]);
        this.mainFrm.controls['otherAssetsDescription'].updateValueAndValidity();
      } else {
        this.mainFrm.get('otherAssetsDescription')?.setValidators(null);
        this.mainFrm.controls['otherAssetsDescription'].updateValueAndValidity();
      }
    }
  }
  async updateOtherLiabilities() {
    // get otherLiabilities
    let otherLiabilities: any = this.mainFrm.controls.otherLiabilities.value;
    otherLiabilities = otherLiabilities === null ? 0 : otherLiabilities;
    otherLiabilities = typeof otherLiabilities === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(otherLiabilities)) : parseFloat(otherLiabilities);
    otherLiabilities = (Number.isNaN(otherLiabilities)) ? 0 : otherLiabilities;
    // check workActivity
    if (this.workActivity !== 'business') {
      // check if otherLiabilities is more than 0
      if (otherLiabilities > 0) {
        this.mainFrm.get('otherLiabilitiesDescription')?.setValidators([Validators.required]);
        this.mainFrm.controls['otherLiabilitiesDescription'].updateValueAndValidity();
      } else {
        this.mainFrm.get('otherLiabilitiesDescription')?.setValidators(null);
        this.mainFrm.controls['otherLiabilitiesDescription'].updateValueAndValidity();
      }
    }
  }
  async save() {
    // markAllAsTouched
    this.mainFrm.markAllAsTouched();
    // validate data
    if (!this.mainFrm.valid) {
      // send alert
      this.alertSv.showMessage(this.translate.instant('HELPERS.requiredFields'),'warning', this.translate.instant('HELPERS.warning'), true);
    } else {
      // check if user is been saved before
      if (this.savedData) {
        // update userFinancial
        await this.updateUserFinancial();
      } else {
        // add userFinancial
        await this.addUserFinancial();
      }
    }
  }
  async addUserFinancial() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // get data
      // professionalFees
      let professionalFees: any = this.mainFrm.controls.professionalFees.value;
      professionalFees = professionalFees === null ? 0 : professionalFees;
      professionalFees = typeof professionalFees === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(professionalFees)) : parseFloat(professionalFees);
      professionalFees = (Number.isNaN(professionalFees)) ? 0 : professionalFees;
      // otherIncome
      let otherIncome: any = this.mainFrm.controls.otherIncome.value;
      otherIncome = otherIncome === null ? 0 : otherIncome;
      otherIncome = typeof otherIncome === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(otherIncome)) : parseFloat(otherIncome);
      otherIncome = (Number.isNaN(otherIncome)) ? 0 : otherIncome;
      // rent
      let rent: any = this.mainFrm.controls.rent.value;
      rent = rent === null ? 0 : rent;
      rent = typeof rent === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(rent)) : parseFloat(rent);
      rent = (Number.isNaN(rent)) ? 0 : rent;
      // familyExpenses
      let familyExpenses: any = this.mainFrm.controls.familyExpenses.value;
      familyExpenses = familyExpenses === null ? 0 : familyExpenses;
      familyExpenses = typeof familyExpenses === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(familyExpenses)) : parseFloat(familyExpenses);
      familyExpenses = (Number.isNaN(familyExpenses)) ? 0 : familyExpenses;
      // credits
      let credits: any = this.mainFrm.controls.credits.value;
      credits = credits === null ? 0 : credits;
      credits = typeof credits === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(credits)) : parseFloat(credits);
      credits = (Number.isNaN(credits)) ? 0 : credits;
      // otherExpenses
      let otherExpenses: any = this.mainFrm.controls.otherExpenses.value;
      otherExpenses = otherExpenses === null ? 0 : otherExpenses;
      otherExpenses = typeof otherExpenses === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(otherExpenses)) : parseFloat(otherExpenses);
      otherExpenses = (Number.isNaN(otherExpenses)) ? 0 : otherExpenses;
      // otherAssets
      let otherAssets: any = this.mainFrm.controls.otherAssets.value;
      otherAssets = otherAssets === null ? 0 : otherAssets;
      otherAssets = typeof otherAssets === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(otherAssets)) : parseFloat(otherAssets);
      otherAssets = (Number.isNaN(otherAssets)) ? 0 : otherAssets;
      // otherLiabilities
      let otherLiabilities: any = this.mainFrm.controls.otherLiabilities.value;
      otherLiabilities = otherLiabilities === null ? 0 : otherLiabilities;
      otherLiabilities = typeof otherLiabilities === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(otherLiabilities)) : parseFloat(otherLiabilities);
      otherLiabilities = (Number.isNaN(otherLiabilities)) ? 0 : otherLiabilities;
      // originFunds
      const originFunds = this.mainFrm.controls.originFunds.value; // TODO: CHECK ORIGIN FUNDS / MAKE NULLABLE ON DATABASE

      console.log('this.origin', this.origin);

      // set userFinancialData
      const userFinancialData: UserFinancialInterface = {
        ...((this.origin !== 'b2c') && {userId: this.userData.id}),
        professionalFees: professionalFees,
        otherIncome: otherIncome,
        otherIncomeDescription: this.mainFrm.controls.otherIncomeDescription.value,
        rent: rent,
        familyExpenses: familyExpenses,
        credits: credits,
        otherExpenses: otherExpenses,
        otherExpensesDescription: this.mainFrm.controls.otherExpensesDescription.value,
        publicResourcesHandling: this.mainFrm.controls.publicResourcesHandling.value === 'true' ? true : false,
        otherAssets: otherAssets,
        otherAssetsDescription: this.mainFrm.controls.otherAssetsDescription.value,
        otherLiabilities: otherLiabilities,
        otherLiabilitiesDescription: this.mainFrm.controls.otherLiabilitiesDescription.value,
        originFunds: this.origin !== 'b2c' ? originFunds : originFunds.toString(),
        housingType: this.mainFrm.controls.housingType.value,
        dependents: this.mainFrm.controls.dependents.value,
        userFinancialActive: this.mainFrm.controls.userFinancialActive.value,
      }
      // save financial on API
      let auxUserFinancial: any = null;
      // check origin
      if (this.origin === 'b2c') {
        auxUserFinancial =await this.apiSv.addPublicUserFinancial(userFinancialData);
      } else {
        auxUserFinancial =await this.apiSv.addUserFinancial(userFinancialData);
      }
      // get userFinancialId
      const userFinancialId = auxUserFinancial.data;
      // add userFinancialId
      userFinancialData.id = userFinancialId;
      // update userFinancial
      this.userFinancial = userFinancialData;
      // hide loader
      this.loaderEvent.emit(null);
      // emit data
      this.saveEvent.emit(userFinancialData);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async updateUserFinancial() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // get data
      // professionalFees
      let professionalFees: any = this.mainFrm.controls.professionalFees.value;
      professionalFees = professionalFees === null ? 0 : professionalFees;
      professionalFees = typeof professionalFees === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(professionalFees)) : parseFloat(professionalFees);
      professionalFees = (Number.isNaN(professionalFees)) ? 0 : professionalFees;
      // otherIncome
      let otherIncome: any = this.mainFrm.controls.otherIncome.value;
      otherIncome = otherIncome === null ? 0 : otherIncome;
      otherIncome = typeof otherIncome === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(otherIncome)) : parseFloat(otherIncome);
      otherIncome = (Number.isNaN(otherIncome)) ? 0 : otherIncome;
      // rent
      let rent: any = this.mainFrm.controls.rent.value;
      rent = rent === null ? 0 : rent;
      rent = typeof rent === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(rent)) : parseFloat(rent);
      rent = (Number.isNaN(rent)) ? 0 : rent;
      // familyExpenses
      let familyExpenses: any = this.mainFrm.controls.familyExpenses.value;
      familyExpenses = familyExpenses === null ? 0 : familyExpenses;
      familyExpenses = typeof familyExpenses === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(familyExpenses)) : parseFloat(familyExpenses);
      familyExpenses = (Number.isNaN(familyExpenses)) ? 0 : familyExpenses;
      // credits
      let credits: any = this.mainFrm.controls.credits.value;
      credits = credits === null ? 0 : credits;
      credits = typeof credits === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(credits)) : parseFloat(credits);
      credits = (Number.isNaN(credits)) ? 0 : credits;
      // otherExpenses
      let otherExpenses: any = this.mainFrm.controls.otherExpenses.value;
      otherExpenses = otherExpenses === null ? 0 : otherExpenses;
      otherExpenses = typeof otherExpenses === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(otherExpenses)) : parseFloat(otherExpenses);
      otherExpenses = (Number.isNaN(otherExpenses)) ? 0 : otherExpenses;
      // otherAssets
      let otherAssets: any = this.mainFrm.controls.otherAssets.value;
      otherAssets = otherAssets === null ? 0 : otherAssets;
      otherAssets = typeof otherAssets === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(otherAssets)) : parseFloat(otherAssets);
      otherAssets = (Number.isNaN(otherAssets)) ? 0 : otherAssets;
      // otherLiabilities
      let otherLiabilities: any = this.mainFrm.controls.otherLiabilities.value;
      otherLiabilities = otherLiabilities === null ? 0 : otherLiabilities;
      otherLiabilities = typeof otherLiabilities === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(otherLiabilities)) : parseFloat(otherLiabilities);
      otherLiabilities = (Number.isNaN(otherLiabilities)) ? 0 : otherLiabilities;
      // originFunds
      const originFunds = this.mainFrm.controls.originFunds.value; // TODO: CHECK ORIGIN FUNDS
      // set userFinancialData
      const userFinancialData: UserFinancialInterface = {
        professionalFees: professionalFees,
        otherIncome: otherIncome,
        otherIncomeDescription: this.mainFrm.controls.otherIncomeDescription.value,
        rent: rent,
        familyExpenses: familyExpenses,
        credits: credits,
        otherExpenses: otherExpenses,
        otherExpensesDescription: this.mainFrm.controls.otherExpensesDescription.value,
        publicResourcesHandling: this.mainFrm.controls.publicResourcesHandling.value === 'true' ? true : false,
        otherAssets: otherAssets,
        otherAssetsDescription: this.mainFrm.controls.otherAssetsDescription.value,
        otherLiabilities: otherLiabilities,
        otherLiabilitiesDescription: this.mainFrm.controls.otherLiabilitiesDescription.value,
        originFunds: this.origin !== 'b2c' ? originFunds : originFunds.toString(),
        housingType: this.mainFrm.controls.housingType.value,
        dependents: this.mainFrm.controls.dependents.value,
      }
      // save financial on API
      let auxUserFinancial: any = null;
      // check origin
      if (this.origin === 'b2c') {
        auxUserFinancial =await this.apiSv.updatePublicUserFinancial(this.userFinancialData.id, userFinancialData);
      } else {
        auxUserFinancial =await this.apiSv.updateUserFinancial(this.userFinancialData.id, userFinancialData);
      }
      // save address on API
      await this.apiSv.updatePublicUserFinancial(this.userFinancial.id, userFinancialData);
      // update userFinancial Id
      userFinancialData.id = this.userFinancial.id;
      // hide loader
      this.loaderEvent.emit(null);
      // emit data
      this.saveEvent.emit(userFinancialData);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
}
