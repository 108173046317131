// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 25/05/2023
// import
import { Component, EventEmitter, OnInit, OnChanges, Input, Output, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
// translate
import { TranslateService } from '@ngx-translate/core';
// services
import { AuthService } from 'src/app/shared/services/auth.service';
import { EncodingService } from 'src/app/shared/services/encoding.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { LocationService } from 'src/app/shared/services/location.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
// interfaces
import { PositionInterface } from 'src/app/shared/interfaces/position.interface';
// component
@Component({
  selector: 'app-position-form',
  templateUrl: './position-form.component.html',
  styleUrls: ['./position-form.component.scss']
})
// class
export class PositionFormComponent implements OnInit, OnChanges {
  // variables
  @Input() positionData: PositionInterface = null;
  @Output() loaderEvent = new EventEmitter();
  @Output() addEvent = new EventEmitter();
  @Output() updateEvent = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();
  // lang data
  currentLang: string = null;
  // form data
  public mainFrm: FormGroup;
  // messages data
  formErrorMessages: any = {};
  // help data
  toggleHelp = false;
  // saved data
  savedData: boolean = false;
  // position data
  positionId: string = null;
  positionName: string = null;
  // constructor
  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public authSv: AuthService,
    public encodingSv: EncodingService,
    public apiSv: ApiService,
    public locationSv: LocationService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    private utilitiesSv: UtilitiesService,
  ) {
    // main form
    this.mainFrm = this.formBuilder.group({
      positionName: [null, [Validators.required]],
      positionStatus: [null, [Validators.required]],
    });
  }
  // life cycle
  async ngOnInit() {
    await this.translateMsgs();
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.positionData) {
      // check for positionData
      if (this.positionData !== null) {
        this.initData();
      }
    }
  }
  async initData() {
    // show loader
    this.loaderEvent.emit(this.translate.instant('HELPERS.loadingTxt'));
    // get data
    await this.setPositionData();
    // hide loader
    this.loaderEvent.emit(null);
  }
  // translate
  async translateMsgs() {
    // update form error messages
    this.formErrorMessages = {
      positionName: [
        { type: 'required', message: this.translate.instant('POSITION.formValidation.positionName.required') }
      ],
      positionStatus: [
        { type: 'required', message: this.translate.instant('POSITION.formValidation.positionStatus.required') }
      ],
    };
  }
  // form
  async help() {
    if (this.toggleHelp) {
      this.toggleHelp = false;
    } else {
      this.toggleHelp = true;
    }
  }
  async setFocus(elementId: any) {
    elementId.focus();
  }
  checkFormField(fieldName: string) {
    // get field
    const field = this.mainFrm.get(fieldName);
    // check field
    const check = field?.invalid && field?.touched;
    // return data
    return check;
  }
  // navigation
  async cancel() {
    // emit data
    this.cancelEvent.emit(); // Return dat
  }
  async setPositionData() {
    // check position data
    if (this.positionData !== null) {
      // set position
      const position: PositionInterface = this.positionData;
      // set position data
      this.positionId = position.id;
      this.positionName = position.positionName;
      // set form data
      this.mainFrm.controls.positionName.setValue(position.positionName);
      this.mainFrm.controls.positionStatus.setValue(position.positionStatus);
      // set savedData
      this.savedData = true;
    }
  }
  // actions
  async save() {
    // markAllAsTouched
    this.mainFrm.markAllAsTouched();
    // validate data
    if (!this.mainFrm.valid) {
      // send alert
      this.alertSv.showMessage(this.translate.instant('HELPERS.requiredFields'),'warning', this.translate.instant('HELPERS.warning'), true);
    } else {
      // check if data is been saved before
      if (!this.savedData) {
        // add data
        await this.addPosition();
      } else {
        // update data
        await this.updatePosition();
      }
    }
  }
  async addPosition() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // get name data
      const positionName = this.utilitiesSv.capitalizeString(this.mainFrm.controls.positionName.value);
      // set positionData
      const positionData: PositionInterface = {
        positionName,
        positionStatus: this.mainFrm.controls.positionStatus.value,
      }
      // get auxPosition
      const auxPosition: any = await this.apiSv.addPosition(positionData);
      // get data
      const data: any = auxPosition.data;
      // get positionId
      const positionId: string = data.id;
      // update positionId
      this.positionId = positionId;
      // set savedData
      this.savedData = true;
      // set savePositionData
      const savePositionData: PositionInterface = {
        id: positionId,
        positionName: this.mainFrm.controls.positionName.value,
        positionStatus: this.mainFrm.controls.positionStatus.value
      }
      // update positionData
      this.positionData = savePositionData;
      // hide loader
      this.loaderEvent.emit(null);
      // emit data
      this.addEvent.emit(savePositionData);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async updatePosition() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // get name data
      const positionName = this.utilitiesSv.capitalizeString(this.mainFrm.controls.positionName.value);
      // set positionData
      const positionData: PositionInterface = {
        positionName,
        positionStatus: this.mainFrm.controls.positionStatus.value
      }
      // update position
      await this.apiSv.updatePosition(this.positionData.id, positionData);
      // update positionData
      this.positionData.positionName = positionName;
      this.positionData.positionStatus = this.mainFrm.controls.positionStatus.value;
      // hide loader
      this.loaderEvent.emit(null);
      // emit data
      this.updateEvent.emit(this.positionData);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
}
