// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 19/12/2022
// import
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
// plugins
import Swal from 'sweetalert2';
// translate
import { TranslateService } from '@ngx-translate/core';
// services
import { AuthService } from 'src/app/shared/services/auth.service';
import { EncodingService } from 'src/app/shared/services/encoding.service';
import { NavService } from 'src/app/shared/services/nav.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
import { UserInterface } from 'src/app/shared/interfaces/user.interface';
// component
@Component({
  selector: 'app-otp-form',
  templateUrl: './otp-form.component.html',
  styleUrls: ['./otp-form.component.scss']
})
// class
export class OTPFormComponent implements OnInit {
  // variables
  @Input() userId: string = null;
  @Input() firstName: string = null;
  @Input() email: string = null;
  @Input() otpType: string = null;
  @Output() loaderEvent = new EventEmitter();
  @Output() saveEvent = new EventEmitter();
  @Output() backEvent = new EventEmitter();
  // form data
  otpForm: FormGroup;
  // messages data
  formErrorMessages: any = {};
  // help data
  toggleHelp = false;
  // email data
  initialEmail: string = null;
  // constructor
  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public authSv: AuthService,
    public encodingSv: EncodingService,
    public navServices: NavService, 
    public apiSv: ApiService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    private utilitiesSv: UtilitiesService,
  ) {
    // forms
    this.otpForm = this.formBuilder.group({
      code: [null, [Validators.required, Validators.minLength(6)]],
    });
  }
  // life cycle
  async ngOnInit() {
    // check otpType
    if (this.otpType === 'register' || this.otpType === 'registerB2C') {
      // set otp validators
      this.otpForm.get('code')?.setValidators([Validators.required, Validators.minLength(6)]);
      this.otpForm.controls['code'].updateValueAndValidity();
    } else {
      // set otp validators
      this.otpForm.get('code')?.setValidators([Validators.required, Validators.minLength(4)]);
      this.otpForm.controls['code'].updateValueAndValidity();
    }
    // set initialEmail
    this.initialEmail = this.email;
  }
  // translate
  async translateMsgs() {
    this.formErrorMessages = {
      cellphone: [
        { type: 'required', message: this.translate.instant('OTP.otpMustEnter') },
        { type: 'minLength', message: this.translate.instant('OTP.otpComplete') },
      ]
    };
  }
  languageToggle() {
    this.navServices.language = !this.navServices.language;
  }
  // from
  async help () {
    if (this.toggleHelp) {
      this.toggleHelp = false;
    } else {
      this.toggleHelp = true;
    }
  }
  async setFocus(elementId: any) {
    elementId.focus();
  }
  // navigation
  async goToPage(page: string, params?: any | null){
    // navigate to page
    this.utilitiesSv.goTo(page, true, params);
  }
  // actions
  async resendCode() {
    // go to server
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.sending'));
      // clear codes
      await this.clearCode();
      // check otpType
      if (this.otpType === 'register' || this.otpType === 'registerB2C') {
        // send code
        await this.apiSv.sendCode(this.email, 'register');
      } else {
        // send code
        await this.apiSv.sendCode(this.email, this.otpType);
      }
      // hide loader
      this.loaderEvent.emit(null);
      // show alert
      this.alertSv.showMessage(this.translate.instant('OTP.verifyCodeSendedAgain'),'warning', this.translate.instant('OTP.accountConfirmation'), true);
    } catch (error) {
      console.log('error', error);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async clearCode() {
    // clear value of previous input
    this.otpForm.controls.code.setValue(null);
  }
  async sendOTP() {
    try {
      // validate data
      if (!this.otpForm.valid) {
        this.alertSv.showMessage(this.translate.instant('OTP.otpMustEnter'),'warning', this.translate.instant('OTP.confirmation'), true);
      } else {
        // show loader
        this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
        // get code
        const code = this.otpForm.controls.code.value;
        // save code on storage
        localStorage.setItem('code', code);
        // init checkCode 
        let checkCode: any;
        // check otpType
        if (this.otpType === 'register' || this.otpType === 'registerB2C') {
          // set checkCode 
          checkCode = await this.apiSv.validateCodeRegister(this.email, code);
        } else {
          // set checkCode 
          checkCode = await this.apiSv.validateCodeForgot(this.email, code);
        }
        // get token
        const token: any = checkCode.data.accessToken;
        // get user data
        const userData: any = await this.encodingSv.decodeToken(token);
        // get userRoles
        const userRoles: any = JSON.parse(userData.userRoles);
        // get currentRole
        const currentRole: any = this.utilitiesSv.getGreaterActiveRole(userRoles);
        // hide loader
        this.loaderEvent.emit(null);
        // set otpData
        const otpData: any = {
          code,
          userRoles,
          currentRole,
          email: this.email
        }
        // emit data
        this.saveEvent.emit(otpData);
      }
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async editEmail() {
    try {
      // show alert
      Swal.fire({
        icon: 'info',
        title: this.translate.instant('USER.user'),
        text: this.translate.instant('OTP.enterNewEmail'),
        input: 'email',
        inputAttributes: {
          autocapitalize: 'on'
        },
        inputPlaceholder: this.translate.instant('USER.formPlaceHolders.email'),
        confirmButtonText: this.translate.instant('HELPERS.save'),
        showCancelButton: true,
        cancelButtonText: this.translate.instant('HELPERS.cancel'),
        allowOutsideClick: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          // show loader
          this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
          // get email
          const email: any = result.value;
          // set userData
          const userData: UserInterface = {
            email: email,
          }
          // update user email
          await this.apiSv.updatePublicUser(userData);
          // get stored data user
          const storedDataUser: any = localStorage.getItem('userData');
          // check user data
          if (storedDataUser != null) {
            // get data user
            const userData: UserInterface = JSON.parse(storedDataUser);
            // set data user
            userData.email = email;
            // update user on localStorage
            localStorage.setItem('userData', JSON.stringify(userData));
          }
          // update email
          this.email = email;
          // resend code
          await this.resendCode();
          // hide loader
          this.loaderEvent.emit(null);
        }
      });
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
}
