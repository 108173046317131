// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 29/09/2023
// import
import { Component, OnInit, Input } from '@angular/core';
// component
@Component({
  selector: 'app-total-payed',
  templateUrl: './total-payed.component.html',
  styleUrls: ['./total-payed.component.scss']
})
export class TotalPayedComponent {
  // variables
  @Input() totalPayed: number = 0;
}
