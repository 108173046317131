// author: Alejandro Bermúdez
// company: Think In
// date: 25/05/2023
// tslint:disable: max-line-length
// tslint:disable: no-unused-expression
// import
import { Injectable } from '@angular/core';
import { Router, RouterEvent, NavigationExtras } from '@angular/router';
// reducers
import { Action, Store } from '@ngrx/store';
// variables
declare var $: any;
// injectable
@Injectable({
  providedIn: 'root'
})
// class
export class MediaService {
  // variables
  audio: any = new Audio();
  // loader data
  loaderStatus: boolean = false;
  // constructor
  constructor(
    private router: Router,
  ) { }
  // file data
  // -----------------------------------------------------------------------------------------------------------------------------
  getFileExtension(filePath: string): string {
    const fileName = filePath.split('/').pop();
    if (fileName) {
      const fileExtension = fileName.split('.').pop();
      if (fileExtension) {
        return fileExtension.toLowerCase();
      }
    }
    return '';
  }
  getFileType(extension: string): string {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
    const videoExtensions = ['mp4', 'avi', 'mov'];
    const audioExtensions = ['mp3', 'wav', 'ogg'];
    const pdfExtensions = ['pdf'];
  
    if (imageExtensions.includes(extension.toLowerCase())) {
      return 'image';
    } else if (videoExtensions.includes(extension.toLowerCase())) {
      return 'video';
    } else if (audioExtensions.includes(extension.toLowerCase())) {
      return 'audio';
    } else if (pdfExtensions.includes(extension.toLowerCase())) {
      return 'pdf';
    } else {
      return 'other';
    }
  }
  getFileNameAndExtension(filePath: string): string {
    const fileName = filePath.split('/').pop();
    if (fileName) {
      const name = fileName.split('.').slice(0, -1).join('.');
      const extension = fileName.split('.').pop();
      return `${name}.${extension}`.toLowerCase();
    }
    return '';
  }
  // file handler
  getBase64(file: any) {
    return new Promise((resolve, reject) => {
      try {
        if (this.isBase64(file) === false) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload =  () => {
            resolve(reader.result);
          };
          reader.onerror =  (error) =>{
            console.log('error: ', error);
            reject(error);
          };  
        } else {
          resolve(file);
        }
      } catch (error: any) {
        console.log('error: ', error);
        reject(error);
      }
    })
  }
  isBase64(str: any) {
    try {
      if (typeof(str) == 'string') {
        if (str ==='' || str.trim() ==='') { 
          return false; 
        }
        return btoa(atob(str)) == str;
      } else {
        return false; 
      }
    } catch (err) {
        return false;
    }
  }
  dataURItoBlob(dataURI: any) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
        byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }
    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    let ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], {type:mimeString});
  }
  dataURLtoFile(dataURI: any, fileName: any) {
    const arr = dataURI.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while(n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, {type:mime});
  }
}
