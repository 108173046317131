<div [ngClass]="layout.config.settings.layout_type" [class]="layout.config.settings.layout_version">
  <!-- page-wrapper -->
  <div class="page-wrapper" id="canvas-bookmark" [ngClass]="layoutClass">
    <!-- header -->
    <app-header></app-header>
    <!-- page -->
    <div class="page-body-wrapper">
      <!-- sidebar -->
      <div class="sidebar-wrapper" [attr.sidebar-layout]="layout.config.settings.sidebar_type" [class.close_icon]="navServices.collapseSidebar">
        <app-sidebar></app-sidebar>
      </div>
      <!-- body -->
      <div class="page-body">
        <main [@fadeInAnimation]="getRouterOutletState(o)">
          <router-outlet #o="outlet"></router-outlet>
        </main>
      </div>
      <!-- footer -->
      <footer class="footer">
        <app-footer [lineation]="'left'"></app-footer>
      </footer>
    </div>
  </div>
</div>
<!-- overlay -->
<div class="bg-overlay1"></div>
<!-- loader -->
<app-loader-inner></app-loader-inner>
<!-- whatsapp -->
<a class="whatsapp-btn" target="_blank" rel="noopener noreferrer" href="https://wa.me/+573234815179?text=Hola%20,%20necesito%20ayuda%20con%20la%20plataforma">
  <i class="fa fa-whatsapp" aria-hidden="true"></i>
</a>