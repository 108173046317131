<div class="card">
   <div class="card-header card-no-border">
     <h5>{{ 'REPORT.loanRequestVsApproved' | translate }}</h5>
     <div class="dropdown">
        <button class="btn dropdown-toggle" type="button" (click)="toggleTimeQuery()">{{ 'DATEDATA.timeQueryOptions.' + selectedTimeQuery | translate }}</button>
        <div class="dropdown-menu dropdown-menu-end" [class.show]="showTimeQuery">
          <a class="dropdown-item" (click)="setTimeQuery(timeQueryOption)" *ngFor="let timeQueryOption of timeQueryOptions">{{ 'DATEDATA.timeQueryOptions.' + timeQueryOption | translate }}</a>
        </div>
      </div>
   </div>
   <div class="card-body pt-0">
     <div class="row m-0 overall-card overview-card">
       <div class="col-xl-12 col-md-12 col-sm-12 p-0">
         <div class="chart-right">
           <div class="row">
             <div class="col-xl-12">
               <div class="card-body p-0">
                 <ul class="balance-data">
                   <li><span class="circle bg-light"></span><span class="f-light ms-1">{{ 'REPORT.loanRequest' | translate }}</span></li>
                   <li><span class="circle bg-primary"> </span><span class="f-light ms-1">{{ 'REPORT.loanApproved' | translate }}</span></li>
                 </ul>
                 <div class="current-sale-container order-container">
                   <div class="overview-wrapper" id="orderoverview">
                    <apx-chart
                      *ngIf="dataLoaded"
                      [series]="loanRequestVsApprovedData.series"
                      [chart]="loanRequestVsApprovedData.chart"
                      [plotOptions]="loanRequestVsApprovedData.plotOptions"
                      [grid]="loanRequestVsApprovedData.grid"
                      [dataLabels]="loanRequestVsApprovedData.dataLabels"
                      [stroke]="loanRequestVsApprovedData.stroke"
                      [fill]="loanRequestVsApprovedData.fill"
                      [legend]="loanRequestVsApprovedData.legend"
                      [colors]="loanRequestVsApprovedData.colors"
                      [xaxis]="loanRequestVsApprovedData.xaxis"
                      [yaxis]="loanRequestVsApprovedData.yaxis"
                      [labels]="loanRequestVsApprovedData.labels"
                      [states]="loanRequestVsApprovedData.states"
                      [responsive]="loanRequestVsApprovedData.responsive"
                    ></apx-chart>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
 </div>