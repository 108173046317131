// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 29/09/2023
// import
import { Component, OnInit, Input } from '@angular/core';
// component
@Component({
  selector: 'app-total-approved',
  templateUrl: './total-approved.component.html',
  styleUrls: ['./total-approved.component.scss']
})
export class TotalApprovedComponent {
  // variables
  @Input() totalApproved: number = 0;
}
