import { Routes } from '@angular/router';


export const content: Routes = [
  {
    path: '',
    loadChildren: () => import('../../pages/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'companies',
    loadChildren: () => import('../../pages/companies/companies.module').then(m => m.CompaniesModule),
  },
  {
    path: 'users',
    loadChildren: () => import('../../pages/users/users.module').then(m => m.UsersModule),
  },
  {
    path: 'loans',
    loadChildren: () => import('../../pages/loans/loans.module').then(m => m.LoansModule),
  },
  {
    path: 'batches',
    loadChildren: () => import('../../pages/batches/batches.module').then(m => m.BatchesModule),
  },
  {
    path: 'products',
    loadChildren: () => import('../../pages/products/products.module').then(m => m.ProductsModule),
  },
  {
    path: 'banks',
    loadChildren: () => import('../../pages/banks/banks.module').then(m => m.BanksModule),
  },
  {
    path: 'professions',
    loadChildren: () => import('../../pages/config/professions/professions.module').then(m => m.ProfessionsModule),
  },
  {
    path: 'occupations',
    loadChildren: () => import('../../pages/config/occupations/occupations.module').then(m => m.OccupationsModule),
  },
  {
    path: 'positions',
    loadChildren: () => import('../../pages/config/positions/positions.module').then(m => m.PositionsModule),
  },
  {
    path: 'config',
    loadChildren: () => import('../../pages/config/general/general.module').then(m => m.GeneralModule),
  },
  {
    path: 'legal',
    loadChildren: () => import('../../pages/config/legal/legal.module').then(m => m.LegalModule),
  },
];
