// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 25/05/2023
// import
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
// translate
import { TranslateService } from '@ngx-translate/core';
// services
import { AuthService } from 'src/app/shared/services/auth.service';
import { EncodingService } from 'src/app/shared/services/encoding.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
// component
@Component({
  selector: 'app-email-form',
  templateUrl: './email-form.component.html',
  styleUrls: ['./email-form.component.scss']
})
// class
export class EmailFormComponent implements OnInit {
  @Output() loaderEvent = new EventEmitter();
  @Output() continueEvent = new EventEmitter();
  // form data
  emailForm: FormGroup;
  // messages data
  formErrorMessages: any = {};
  // help data
  toggleHelp = false;
  // constructor
  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public authSv: AuthService,
    public encodingSv: EncodingService,
    public apiSv: ApiService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    private utilitiesSv: UtilitiesService,
  ) { 
    // forms
    this.emailForm = this.formBuilder.group({
      email: [null, Validators.required],
    });
  }
  // life cycle
  async ngOnInit() {}
  // from
  async setFocus(elementId: any) {
    elementId.focus();
  }
  // navigation
  async goToPage(page: string, params?: any | null){
    // navigate to page
    this.utilitiesSv.goTo(page, true, params);
  }
  // actions
  async sendCode() {
    try {
      // validate data
      if (!this.emailForm.valid) {
        this.alertSv.showMessage(this.translate.instant('FORGOT.requiredFields'),'warning', this.translate.instant('FORGOT.title'), true);
      } else {
        // show loader
        this.loaderEvent.emit(this.translate.instant('HELPERS.sending'));
        // get email
        const email = this.emailForm.controls.email.value;
        // set sendCodeAux 
        const sendCodeAux: any = await this.apiSv.sendCode(email, 'forgot');
        // set sendCodeStatus
        const sendCodeStatus: any = sendCodeAux.data;
        // hide loader
        this.loaderEvent.emit(null);
        // set emailData
        const emailData: any = {
          email,
          sendCodeStatus,
        }
        // emit data
        this.continueEvent.emit(emailData);
      }
    } catch (error) {
      console.log('error', error);
      // handle error
      this.filterSv.handleError(error);
    }
  }
}
