// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 25/05/2023
// import
import { Component, EventEmitter, OnInit, OnChanges, Input, Output, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
// translate
import { TranslateService } from '@ngx-translate/core';
// environment
import { environment } from 'src/environments/environment';
// plugins
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { editorObservationGlobalConfigEn, editorObservationGlobalConfigEs } from 'src/app/shared/services/angular-editor-config';
import { ImageTransform, ImageCroppedEvent, base64ToFile, Dimensions, LoadedImage } from 'ngx-image-cropper';
// services
import { AuthService } from 'src/app/shared/services/auth.service';
import { EncodingService } from 'src/app/shared/services/encoding.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { LocationService } from 'src/app/shared/services/location.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
import { MediaService } from 'src/app/shared/services/media.service';
// interfaces
import { UserInterface } from 'src/app/shared/interfaces/user.interface';
import { ProductInterface } from 'src/app/shared/interfaces/product.interface';
import { ProductCategoryInterface } from 'src/app/shared/interfaces/product-category.interface';
// component
@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss']
})
// class
export class ProductFormComponent implements OnInit, OnChanges {
  // variables
  @Input() productData: ProductInterface = null;
  @Output() loaderEvent = new EventEmitter();
  @Output() addEvent = new EventEmitter();
  @Output() updateEvent = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  // lang data
  currentLang: string = null;
  // editor configuration
  editorConfig: AngularEditorConfig = null;
  // form data
  public mainFrm: FormGroup;
  // messages data
  formErrorMessages: any = {};
  // help data
  toggleHelp = false;
  // saved data
  savedData: boolean = false;
  // roles data
  isAdmin: boolean = false;
  isFinancial: boolean = false;
  isCompany: boolean = false;
  // product data
  productName: string = null;
  // productIcon data
  productIconFile: any = null;
  productIconSource: any = null;
  productIconUpdate: boolean = false;
  // productPortrait data
  productPortraitFile: any = null;
  productPortraitSource: any = null;
  productPortraitUpdate: boolean = false;
  // edit image data
  imageChangedEvent: any = null;
  editImageSelection: string = null;
  canvasRotation: number = 0;
  scale: number = 1;
  showCropper: boolean = false;
  containWithinAspectRatio: boolean = false;
  transform: ImageTransform = {};
  // current user data
  currentUserData: UserInterface = null;
  allRoles: any[] = [];
  // product categories data
  productCategories: any[] = [];
  allProductCategories: any[] = [];
  // constructor
  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public authSv: AuthService,
    public encodingSv: EncodingService,
    public apiSv: ApiService,
    public locationSv: LocationService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    private utilitiesSv: UtilitiesService,
    private mediaSv: MediaService,
    private modalService: NgbModal,
  ) {
    // get currentLang
    const currentLang = this.translate.getDefaultLang();
    // set currentLang
    this.currentLang = currentLang;
    // main form
    this.mainFrm = this.formBuilder.group({
      productCategory: [null, [Validators.required]],
      productName: [null, [Validators.required]],
      productDescription: [null],
      loanMonthMin: [null, [Validators.required]],
      loanMonthMax: [null, [Validators.required]],
      loanMonthSteps: [null, [Validators.required]],
      loanAmountMaxLimit: [false, [Validators.required]],
      loanAmountMin: [null, [Validators.required]],
      loanAmountMax: [null, [Validators.required]],
      loanInterest: [null, [Validators.required]],
      loanPaymentReceipts: [null, [Validators.required]],
      borrowingCapacityPercentage: [null, [Validators.required]],
      productInsurance: [false, [Validators.required]],
      productStatus: [false, [Validators.required]],
    });
    // check for form changes
    this.mainFrm.valueChanges.subscribe(async (val) => {
      // check loanAmountMin
      if (typeof val.loanAmountMin === 'string') {
        // mask value
        const maskedVal = this.utilitiesSv.convertStringToCurrency(val.loanAmountMin);
        // check matched values
        if (val.loanAmountMin !== maskedVal) {
          // set form value
          this.mainFrm.patchValue({loanAmountMin: maskedVal});
        }
      }
      // check loanAmountMax
      if (typeof val.loanAmountMax === 'string') {
        // get loanAmountMaxLimit
        const loanAmountMaxLimit = val.loanAmountMaxLimit;
        // check loanAmountMaxLimit
        if (!loanAmountMaxLimit) {
          // mask value
          const maskedVal = this.utilitiesSv.convertStringToCurrency(val.loanAmountMax);
          // check matched values
          if (val.loanAmountMax !== maskedVal) {
            // set form value
            this.mainFrm.patchValue({loanAmountMax: maskedVal});
          }
        }
      }
    });
  }
  // life cycle
  async ngOnInit() {
    await this.translateMsgs();
    this.generalData();
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.productData) {
      this.initData();
    }
  }
  async generalData() {
    // show loader
    this.loaderEvent.emit(this.translate.instant('HELPERS.loadingTxt'));
    // get data
    await this.getCurrentUserData();
    await this.getProductCategories();
    // hide loader
    this.loaderEvent.emit(null);
  }
  async initData() {
    // show loader
    this.loaderEvent.emit(this.translate.instant('HELPERS.loadingTxt'));
    // get data
    await this.generalData();
    await this.setProductData();
    // hide loader
    this.loaderEvent.emit(null);
  }
  // translate
  async translateMsgs() {
    // update angular editor
    if (this.currentLang === 'es') {
      this.editorConfig = editorObservationGlobalConfigEs;
    } else {
      this.editorConfig = editorObservationGlobalConfigEn;
    }
    // update form error messages
    this.formErrorMessages = {
      productCategory: [
        { type: 'required', message: this.translate.instant('PRODUCT.formValidation.productCategory.required') }
      ],
      productName: [
        { type: 'required', message: this.translate.instant('PRODUCT.formValidation.productName.required') }
      ],
      loanMonthMin: [
        { type: 'required', message: this.translate.instant('PRODUCT.formValidation.loanMonthMin.required') }
      ],
      loanMonthMax: [
        { type: 'required', message: this.translate.instant('PRODUCT.formValidation.loanMonthMax.required') }
      ],
      loanMonthSteps: [
        { type: 'required', message: this.translate.instant('PRODUCT.formValidation.loanMonthSteps.required') }
      ],
      loanAmountMin: [
        { type: 'required', message: this.translate.instant('PRODUCT.formValidation.loanAmountMin.required') }
      ],
      loanAmountMax: [
        { typeLimit: 'required', message: this.translate.instant('PRODUCT.formValidation.loanAmountMax.requiredLimit') },
        { typeTimes: 'required', message: this.translate.instant('PRODUCT.formValidation.loanAmountMax.requiredTimes') },
      ],
      loanAmountMaxLimit: [
        { type: 'required', message: this.translate.instant('PRODUCT.formValidation.loanAmountMaxLimit.required') }
      ],
      loanInterest: [
        { type: 'required', message: this.translate.instant('PRODUCT.formValidation.loanInterest.required') }
      ],
      loanPaymentReceipts: [
        { type: 'required', message: this.translate.instant('PRODUCT.formValidation.loanPaymentReceipts.required') }
      ],
      borrowingCapacityPercentage: [
        { type: 'required', message: this.translate.instant('PRODUCT.formValidation.borrowingCapacityPercentage.required') }
      ],
      productInsurance: [
        { type: 'required', message: this.translate.instant('PRODUCT.formValidation.productInsurance.required') }
      ],
      productStatus: [
        { type: 'required', message: this.translate.instant('PRODUCT.formValidation.productStatus.required') }
      ],
    };
  }
  // form
  async help() {
    if (this.toggleHelp) {
      this.toggleHelp = false;
    } else {
      this.toggleHelp = true;
    }
  }
  async setFocus(elementId: any) {
    elementId.focus();
  }
  checkFormField(fieldName: string) {
    // get field
    const field = this.mainFrm.get(fieldName);
    // check field
    const check = field?.invalid && field?.touched;
    // return data
    return check;
  }
  // navigation
  async cancel() {
    // emit data
    this.cancelEvent.emit(); // Return dat
  }
  // data
  async getCurrentUserData() {
    // get stored data user
    const storedDataUser: any = localStorage.getItem('userData');
    // check user data
    if (storedDataUser != null) {
      // get data user
      const userData: UserInterface = JSON.parse(storedDataUser);
      // update data user
      this.currentUserData = userData;
      // check roles
      const checkAdmin = await this.utilitiesSv.checkRoles(userData.role, ['support', 'administrator']);
      this.isAdmin = checkAdmin;
      const checkFinancial = await this.utilitiesSv.checkRoles(userData.role, ['support', 'administrator', 'board', 'treasure', 'businessAgent']);
      this.isFinancial = checkFinancial;
      const checkCompany = await this.utilitiesSv.checkRoles(userData.role, ['ceo', 'manager', 'auxiliar']);
      this.isCompany = checkCompany;
    }
  }
  async getProductCategories() {
    try {
      // set productCategoriesData
      const productCategoriesData = {
        limit: 100000,
        page: 1,
        productId: null,
        productCategory: null,
        productCategoryStatus: null,
      }
      // get data from api
      await this.apiSv.getProductCategories(productCategoriesData).then((response: any)=>{
        // get productCategories
        const productCategories = response.data.result;
        // set allProductCategories
        const allProductCategories: any = [];
        // loop productCategories
        productCategories.map((productCategory: ProductCategoryInterface) => {
          // push productCategories
          this.productCategories.push(productCategory);
          // set productCategoryData
          const productCategoryData = {
            id: productCategory.id,
            productCategory: productCategory.productCategory
          }
          // push country
          allProductCategories.push(productCategoryData);
        });
        // sort productCategories
        const productCategoriesSorted = this.utilitiesSv.sortArrayByKey(allProductCategories, 'productCategory');
        // set productCategoryNullData
        const productCategoryNullData = {
          id: null,
          productCategory: this.translate.instant('HELPERS.selectAnOption')
        }
        // unshift company activity
        productCategoriesSorted.unshift(productCategoryNullData);
        // update all company activities
        this.allProductCategories = productCategoriesSorted;
      }, error=>{
        console.log('error', error);
      });
    } catch (error: any) {
      console.log('error', error);
    }
  }
  async setProductData() {
    // check product data
    if (this.productData !== null) {
      // set product
      const product: ProductInterface = this.productData;
      // set productIcon
      this.productIconSource = product.productIcon;
      // set product data
      this.productName = product.productName;
      // set form data
      this.mainFrm.controls.productCategory.setValue(product.productCategoryId);
      this.mainFrm.controls.productName.setValue(product.productName);
      this.mainFrm.controls.productDescription.setValue(product.productDescription);
      this.mainFrm.controls.loanMonthMin.setValue(product.loanMonthMin);
      this.mainFrm.controls.loanMonthMax.setValue(product.loanMonthMax);
      this.mainFrm.controls.loanMonthSteps.setValue(product.loanMonthSteps);
      this.mainFrm.controls.loanAmountMin.setValue(product.loanAmountMin);
      this.mainFrm.controls.loanAmountMax.setValue(product.loanAmountMax);
      this.mainFrm.controls.loanAmountMaxLimit.setValue(product.loanAmountMaxLimit);
      this.mainFrm.controls.loanInterest.setValue(product.loanInterest);
      this.mainFrm.controls.loanPaymentReceipts.setValue(product.loanPaymentReceipts);
      this.mainFrm.controls.borrowingCapacityPercentage.setValue(product.borrowingCapacityPercentage);
      this.mainFrm.controls.productInsurance.setValue(product.productInsurance);
      this.mainFrm.controls.productStatus.setValue(product.productStatus);
      // set savedData
      this.savedData = true;
    }
  }
  // actions
  async save() {
    // markAllAsTouched
    this.mainFrm.markAllAsTouched();
    // validate data
    if (!this.mainFrm.valid) {
      // send alert
      this.alertSv.showMessage(this.translate.instant('HELPERS.requiredFields'),'warning', this.translate.instant('HELPERS.warning'), true);
    } else {
      // check if data is been saved before
      if (!this.savedData) {
        // add data
        await this.addProduct();
      } else {
        // update data
        await this.updateProduct();
      }
    }
  }
  async addProduct() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // get name data
      const productName = this.utilitiesSv.capitalizeString(this.mainFrm.controls.productName.value);
      const productCategoryId = this.mainFrm.controls.productCategory.value;
      const auxProductCategory: any = await this.apiSv.getProductCategory(productCategoryId);
      const productCategory: ProductCategoryInterface = auxProductCategory.data;
      const productCategoryName: string = productCategory.productCategory;
      // loanAmountMin
      let loanAmountMin: any = this.mainFrm.controls.loanAmountMin.value;
      loanAmountMin = loanAmountMin === null ? 0 : loanAmountMin;
      loanAmountMin = typeof loanAmountMin === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(loanAmountMin)) : parseFloat(loanAmountMin);
      loanAmountMin = (Number.isNaN(loanAmountMin)) ? 0 : loanAmountMin;
      // loanAmountMax
      let loanAmountMax: any = this.mainFrm.controls.loanAmountMax.value;
      loanAmountMax = loanAmountMax === null ? 0 : loanAmountMax;
      loanAmountMax = typeof loanAmountMax === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(loanAmountMax)) : parseFloat(loanAmountMax);
      loanAmountMax = (Number.isNaN(loanAmountMax)) ? 0 : loanAmountMax;
      // init productDescription
      let productDescription: string = this.mainFrm.controls.productDescription.value;
      // check if observation is not null
      if (productDescription !== null) {
        // fix productDescription fontsize
        productDescription = this.utilitiesSv.replaceStringOnString(productDescription, '<font size="3">', '<font size="2">');
        // check if there is not font size parameter
        if (!productDescription.includes('<font size="2">')) {
          productDescription = '<font size="2">' + productDescription + '</font>';
        }
      }
      // set productData
      const productData: ProductInterface = {
        productCategoryId,
        productName,
        ...((this.productIconUpdate) && {productIcon: this.productIconSource}),
        ...((this.productPortraitUpdate) && {productPortrait: this.productPortraitSource}),
        productDescription,
        loanMonthMin: this.mainFrm.controls.loanMonthMin.value,
        loanMonthMax: this.mainFrm.controls.loanMonthMax.value,
        loanMonthSteps: this.mainFrm.controls.loanMonthSteps.value,
        loanAmountMin,
        loanAmountMax,
        loanAmountMaxLimit: this.mainFrm.controls.loanAmountMaxLimit.value,
        loanInterest: this.mainFrm.controls.loanInterest.value,
        loanMonthlyRate: 0,
        loanAnualRate: 0,
        loanReferralMinimum: 0,
        loanPaymentReceipts: this.mainFrm.controls.loanPaymentReceipts.value,
        borrowingCapacityPercentage: this.mainFrm.controls.borrowingCapacityPercentage.value,
        productInsurance: this.mainFrm.controls.productInsurance.value,
        productStatus: this.mainFrm.controls.productStatus.value,
      }
      // get auxProduct
      const auxProduct: any = await this.apiSv.addProduct(productData);
      // get data
      const data: any = auxProduct.data;
      // get productId
      const productId: string = data.id;
      // set savedData
      this.savedData = true;
      // set saveProductData
      const saveProductData: ProductInterface = {
        id: productId,
        productIcon: this.productIconSource,
        productPortrait: this.productPortraitSource,
        productName,
        productCategoryId,
        productCategoryName,
        productDescription: this.mainFrm.controls.productDescription.value,
        loanMonthMin: this.mainFrm.controls.loanMonthMin.value,
        loanMonthMax: this.mainFrm.controls.loanMonthMax.value,
        loanMonthSteps: this.mainFrm.controls.loanMonthSteps.value,
        loanAmountMin,
        loanAmountMax,
        loanAmountMaxLimit: this.mainFrm.controls.loanAmountMaxLimit.value,
        loanInterest: this.mainFrm.controls.loanInterest.value,
        loanMonthlyRate: 0,
        loanAnualRate: 0,
        loanReferralMinimum: 0,
        loanPaymentReceipts: this.mainFrm.controls.loanPaymentReceipts.value,
        borrowingCapacityPercentage: this.mainFrm.controls.borrowingCapacityPercentage.value,
        productInsurance: this.mainFrm.controls.productInsurance.value,
        productStatus: this.mainFrm.controls.productStatus.value,
      }
      // update productData
      this.productData = saveProductData;
      // hide loader
      this.loaderEvent.emit(null);
      // emit data
      this.addEvent.emit(saveProductData);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async updateProduct() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // get name data
      const productName = this.utilitiesSv.capitalizeString(this.mainFrm.controls.productName.value);
      const productCategoryId = this.mainFrm.controls.productCategory.value;
      const auxProductCategory: any = await this.apiSv.getProductCategory(productCategoryId);
      const productCategory: ProductCategoryInterface = auxProductCategory.data;
      const productCategoryName: string = productCategory.productCategory;
      // loanAmountMin
      let loanAmountMin: any = this.mainFrm.controls.loanAmountMin.value;
      loanAmountMin = loanAmountMin === null ? 0 : loanAmountMin;
      loanAmountMin = typeof loanAmountMin === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(loanAmountMin)) : parseFloat(loanAmountMin);
      loanAmountMin = (Number.isNaN(loanAmountMin)) ? 0 : loanAmountMin;
      // loanAmountMax
      let loanAmountMax: any = this.mainFrm.controls.loanAmountMax.value;
      loanAmountMax = loanAmountMax === null ? 0 : loanAmountMax;
      loanAmountMax = typeof loanAmountMax === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(loanAmountMax)) : parseFloat(loanAmountMax);
      loanAmountMax = (Number.isNaN(loanAmountMax)) ? 0 : loanAmountMax;
      // init productDescription
      let productDescription: string = this.mainFrm.controls.productDescription.value;
      // check if observation is not null
      if (productDescription !== null) {
        // fix productDescription fontsize
        productDescription = this.utilitiesSv.replaceStringOnString(productDescription, '<font size="3">', '<font size="2">');
        // check if there is not font size parameter
        if (!productDescription.includes('<font size="2">')) {
          productDescription = '<font size="2">' + productDescription + '</font>';
        }
      }
      // set productData
      const productData: ProductInterface = {
        productCategoryId,
        productName,
        ...((this.productIconUpdate) && {productIcon: this.productIconSource}),
        ...((this.productPortraitUpdate) && {productPortrait: this.productPortraitSource}),
        productDescription,
        loanMonthMin: this.mainFrm.controls.loanMonthMin.value,
        loanMonthMax: this.mainFrm.controls.loanMonthMax.value,
        loanMonthSteps: this.mainFrm.controls.loanMonthSteps.value,
        loanAmountMin,
        loanAmountMax,
        loanAmountMaxLimit: this.mainFrm.controls.loanAmountMaxLimit.value,
        loanInterest: this.mainFrm.controls.loanInterest.value,
        loanMonthlyRate: 0,
        loanAnualRate: 0,
        loanReferralMinimum: 0,
        loanPaymentReceipts: this.mainFrm.controls.loanPaymentReceipts.value,
        borrowingCapacityPercentage: this.mainFrm.controls.borrowingCapacityPercentage.value,
        productInsurance: this.mainFrm.controls.productInsurance.value,
        productStatus: this.mainFrm.controls.productStatus.value,
      }
      // update product
      await this.apiSv.updateProduct(this.productData.id, productData);
      // update productData
      this.productData.productName = productName;
      this.productData.productIcon = this.productIconSource;
      this.productData.productPortrait = this.productPortraitSource;
      this.productData.productCategoryId = productCategoryId;
      this.productData.productCategoryName = productCategoryName;
      this.productData.productDescription = this.mainFrm.controls.productDescription.value;
      this.productData.loanMonthMin = this.mainFrm.controls.loanMonthMin.value;
      this.productData.loanMonthMax = this.mainFrm.controls.loanMonthMax.value;
      this.productData.loanMonthSteps = this.mainFrm.controls.loanMonthSteps.value;
      this.productData.loanAmountMin = loanAmountMin;
      this.productData.loanAmountMax = loanAmountMax;
      this.productData.loanAmountMaxLimit = this.mainFrm.controls.loanAmountMaxLimit.value;
      this.productData.loanInterest = this.mainFrm.controls.loanInterest.value;
      this.productData.loanMonthlyRate = 0;
      this.productData.loanAnualRate = 0;
      this.productData.loanReferralMinimum = 0;
      this.productData.loanPaymentReceipts = this.mainFrm.controls.loanPaymentReceipts.value;
      this.productData.borrowingCapacityPercentage = this.mainFrm.controls.borrowingCapacityPercentage.value;
      this.productData.productInsurance = this.mainFrm.controls.productInsurance.value;
      this.productData.productStatus = this.mainFrm.controls.productStatus.value;
      // hide loader
      this.loaderEvent.emit(null);
      // emit data
      this.updateEvent.emit(this.productData);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  // image
  async editImage(editImage: string) {
    // editImage
    this.editImageSelection = editImage;
    // trigger hidden input file
    this.fileInput.nativeElement.click();
  }
  async onFileSelected(event: any, modal: any) {
    const file = event.target.files?.[0];
    if (file) {
      // check fileType
      if (file.type.startsWith('image/')) {
        // get uploaded file data
        const fileSize = file.size;
        // check file size
        if (fileSize >= environment.maxFileData) {
          // hide loader
          this.loaderEvent.emit(null);
          // show loader
          this.alertSv.showMessage(this.translate.instant('HELPERS.fileTooBig'),'error', this.translate.instant('HELPERS.error'), true);
        } else {
          // set image changed event
          this.imageChangedEvent = event;
          // showCropper
          this.showCropper = true;
          // open crop modal
          this.modalService.open(modal);
        }
      } else {
        // hide loader
        this.loaderEvent.emit(null);
        // show loader
        this.alertSv.showMessage(this.translate.instant('HELPERS.fileNoImage'),'error', this.translate.instant('HELPERS.error'), true);
      }
    } else {
      // hide loader
      this.loaderEvent.emit(null);
    }
  }
  async uploadFile(file: any) {
    const imageData = new FormData()
    imageData.append('file', file);
    // send file to api
    const imageFile: any = await this.apiSv.uploadFile(imageData);
    // get file data
    const fileData: any = imageFile.data;
    const filePath = fileData.filePath;
    // save file
    this.updateFile(filePath);
  }
  async updateFile(filePath: string) {
    try {
      // check editImageSelection
      switch (this.editImageSelection) {
        case 'icon':
          // mark productIconUpdate
          this.productIconUpdate = true;
          // update icon source
          this.productIconSource = filePath;
          break;
        case 'portrait':
          // mark productPortraitUpdate
          this.productPortraitUpdate = true;
          // update portrait source
          this.productPortraitSource = filePath;
          break;
      }
      // hide loader
      this.loaderEvent.emit(null);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  imageCroppingClose() {
    // check editImageSelection
    switch (this.editImageSelection) {
      case 'icon':
        // clear icon
        this.productIconSource = null;
        break;
      case 'portrait':
        // clear portrait
        this.productPortraitSource = null;
        break;
    }
    // clear image event
    this.imageChangedEvent = null;
    // hide modal
    this.modalService.dismissAll();
  }
  async imageCroppingFinish() {
    // hide modal
    this.modalService.dismissAll();
    // show loader
    this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
    // init baseFileName
    let baseFileName: string = null;
    // init base 64 data
    let b64Data: string = null;
    // check editImageSelection
    switch (this.editImageSelection) {
      case 'icon':
        // clear icon
        this.productIconFile = null;
        // get base 64 data
        b64Data = this.productIconSource;
        // set baseFileName
        baseFileName = 'icon.png';
        break;
      case 'portrait':
        // clear portrait
        this.productPortraitFile = null;
        // get base 64 data
        b64Data = this.productPortraitSource;
        // set baseFileName
        baseFileName = 'portrait.png';
        break;
    }
    // get file data
    const productName = this.mainFrm.controls.productName.value;
    const fileName = productName === null ? baseFileName : await this.utilitiesSv.slugify(productName) + '-' + baseFileName;
    var file = this.mediaSv.dataURLtoFile(b64Data, fileName);
    // upload file
    await this.uploadFile(file);
  }
  // cropping media
  imageCropped(event: ImageCroppedEvent) {
    // check editImageSelection
    switch (this.editImageSelection) {
      case 'icon':
        // clear logo
        this.productIconSource = event.base64;
        break;
      case 'portrait':
        // clear portrait
        this.productPortraitSource = event.base64;
        break;
    }
  }
  imageLoadImageFailed() {
    // show message
    this.alertSv.showMessage(this.translate.instant('HELPERS.fileLoadImageError'),'error', this.translate.instant('HELPERS.error'), true);
  }
  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }
  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }
  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }
  flipHorizontal() {
    this.transform = {
        ...this.transform,
        flipH: !this.transform.flipH
    };
  }
  flipVertical() {
    this.transform = {
        ...this.transform,
        flipV: !this.transform.flipV
    };
  }
  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }
  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }
}
