// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 25/05/2023
// import
import { Component, EventEmitter, OnInit, OnChanges, Input, Output, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
// translate
import { TranslateService } from '@ngx-translate/core';
// environment
import { environment } from 'src/environments/environment';
// plugins
declare var $: any;
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { editorCompanyAboutGlobalConfigEn, editorCompanyAboutGlobalConfigEs } from 'src/app/shared/services/angular-editor-config';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageTransform, ImageCroppedEvent, base64ToFile, Dimensions, LoadedImage } from 'ngx-image-cropper';
// services
import { AuthService } from 'src/app/shared/services/auth.service';
import { EncodingService } from 'src/app/shared/services/encoding.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { LocationService } from 'src/app/shared/services/location.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
import { MediaService } from 'src/app/shared/services/media.service';
// interfaces
import { CountryInterface } from 'src/app/shared/interfaces/country.interface';
import { UserInterface } from 'src/app/shared/interfaces/user.interface';
import { CompanyInterface } from 'src/app/shared/interfaces/company.interface';
import { CompanyActivityInterface } from 'src/app/shared/interfaces/company-activity.interface';
import { StateInterface } from 'src/app/shared/interfaces/state.interface';
import { CityInterface } from 'src/app/shared/interfaces/city.interface';
// component
@Component({
  selector: 'app-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.scss']
})
// class
export class CompanyFormComponent implements OnInit, OnChanges {
  // variables
  @Input() companyData: CompanyInterface = null;
  @Input() origin: string = null;
  @Output() loaderEvent = new EventEmitter();
  @Output() addEvent = new EventEmitter();
  @Output() updateEvent = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  // lang data
  currentLang: string = null;
  // editor configuration
  editorConfig: AngularEditorConfig = null;
  // form data
  public mainFrm: FormGroup;
  showWebProtocol: boolean = false;
  // messages data
  formErrorMessages: any = {};
  // help data
  toggleHelp = false;
  savedData: boolean = false;
  // companyLogo data
  companyLogoFile: any = null;
  companyLogoSource: any = null;
  companyLogoUpdate: boolean = false;
  // companyPortrait data
  companyPortraitFile: any = null;
  companyPortraitSource: any = null;
  companyPortraitUpdate: boolean = false;
  // edit image data
  imageChangedEvent: any = null;
  editImageSelection: string = null;
  canvasRotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  // current user data
  currentUserData: UserInterface = null;
  // roles data
  isAdmin: boolean = false;
  isFinancial: boolean = false;
  isCompany: boolean = false;
  // location data
  loadingLocation: boolean = false;
  // country data
  countries: any[] = [];
  allCountries: any[] = [];
  countryName: string = null;
  countryFlag: string = null;
  countryCode: string = null;
  countryCodeId: string = null;
  countryId: string = null;
  country: CountryInterface = null;
  // state data
  states: any[] = [];
  allStates: any[] = [];
  stateId: string = null;
  stateName: string = null;
  state: StateInterface = null;
  // city data
  cities: any[] = [];
  allCities: any[] = [];
  cityId: string = null;
  cityName: string = null;
  city: CityInterface = null;
  // company data
  businessName: string = null;
  // company activities data
  companyActivities: any[] = [];
  allCompanyActivities: any[] = [];
  // company cellphone country code data
  selectCompanyCellphoneCode: boolean = false;
  companyCellphoneCountryId: string = null;
  companyCellphoneCountry: CountryInterface = null;
  companyCellphoneCountryName: string = environment.countryName;
  companyCellphoneCountryFlag: string = environment.countryFlag;
  companyCellphoneCountryCode: string = environment.countryCode;
  companyCellphoneCountryCodeId: string = environment.countryCodeId;
  // company phone country code data
  selectCompanyPhoneCode: boolean = false;
  companyPhoneCountryId: string = null;
  companyPhoneCountry: CountryInterface = null;
  companyPhoneCountryName: string = environment.countryName;
  companyPhoneCountryFlag: string = environment.countryFlag;
  companyPhoneCountryCode: string = environment.countryCode;
  companyPhoneCountryCodeId: string = environment.countryCodeId;
  // company phone country code data
  selectCompanyWhatsappCode: boolean = false;
  companyWhatsappCountryId: string = null;
  companyWhatsappCountry: CountryInterface = null;
  companyWhatsappCountryName: string = environment.countryName;
  companyWhatsappCountryFlag: string = environment.countryFlag;
  companyWhatsappCountryCode: string = environment.countryCode;
  companyWhatsappCountryCodeId: string = environment.countryCodeId;
  // constructor
  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public authSv: AuthService,
    public encodingSv: EncodingService,
    public apiSv: ApiService,
    public locationSv: LocationService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    private utilitiesSv: UtilitiesService,
    private mediaSv: MediaService,
    private modalService: NgbModal,
  ) {
    // get currentLang
    const currentLang = this.translate.getDefaultLang();
    // set currentLang
    this.currentLang = currentLang;
    // main form
    this.mainFrm = this.formBuilder.group({
      companyActivity: [null, [Validators.required]],
      companyName: [null, [Validators.required]],
      businessName: [null, [Validators.required]],
      companyIdentification: [null, [Validators.required]],
      companyConstitutionDate: [null, [this.dateValidator()]],
      companyAbout: [null],
      companyEmail: [null, Validators.email],
      country: [null, [Validators.required]],
      state: [null, [Validators.required]],
      city: [null, [Validators.required]],
      address: [null, [Validators.required]],
      address2: [null],
      companyCellphoneCountryCode: [null],
      companyCellphone: [null, [Validators.minLength(4), Validators.maxLength(13), Validators.pattern('^[0-9]+$')]],
      companyPhoneCountryCode: [null],
      companyPhone: [null, [Validators.minLength(4), Validators.maxLength(13), Validators.pattern('^[0-9]+$')]],
      companyWhatsappCountryCode: [null],
      companyWhatsapp: [null, [Validators.minLength(4), Validators.maxLength(13), Validators.pattern('^[0-9]+$')]],
      companyUriFacebook: [null],
      companyUserInstagram: [null],
      companyUserTwitter: [null],
      companyUriTiktok: [null],
      companyUriYoutube: [null],
      companyUriLinkedin: [null],
      companyWebProtocol: ['https://'],
      companyWeb: [null],
      companyStatus: [null, Validators.required],
    });
  }
  // life cycle
  async ngOnInit() {
    await this.translateMsgs();
    this.generalData();
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.companyData) {
      this.initData();
    }
  }
  async generalData() {
    // show loader
    this.loaderEvent.emit(this.translate.instant('HELPERS.loadingTxt'));
    // get data
    await this.getCountries();
    await this.setInitialCountries();
    await this.getCompanyActivities();
    await this.getCurrentUserData();
    // hide loader
    this.loaderEvent.emit(null);
  }
  async initData() {
    // show loader
    this.loaderEvent.emit(this.translate.instant('HELPERS.loadingTxt'));
    // check origin
    if (!(this.origin === 'otherCompany' || this.origin === 'otherCompanyLoan')) {
      // businessName
      this.mainFrm.get('businessName')?.setValidators([Validators.required]);
      this.mainFrm.controls['businessName'].updateValueAndValidity();
      // companyConstitutionDate
      this.mainFrm.get('companyConstitutionDate')?.setValidators([this.dateValidator()]);
      this.mainFrm.controls['companyConstitutionDate'].updateValueAndValidity();
      // companyCellphone
      this.mainFrm.get('companyCellphone')?.setValidators([Validators.minLength(4), Validators.maxLength(13), Validators.pattern('^[0-9]+$')]);
      this.mainFrm.controls['companyCellphone'].updateValueAndValidity();
      // companyPhone
      this.mainFrm.get('companyPhone')?.setValidators([Validators.minLength(4), Validators.maxLength(13), Validators.pattern('^[0-9]+$')]);
      this.mainFrm.controls['companyPhone'].updateValueAndValidity();
      // companyWhatsapp
      this.mainFrm.get('companyWhatsapp')?.setValidators([Validators.minLength(4), Validators.maxLength(13), Validators.pattern('^[0-9]+$')]);
      this.mainFrm.controls['companyWhatsapp'].updateValueAndValidity();
      // set initial country code
      this.mainFrm.controls.companyCellphoneCountryCode.setValue(this.countryCodeId);
      this.mainFrm.controls.companyPhoneCountryCode.setValue(this.countryCodeId);
      this.mainFrm.controls.companyWhatsappCountryCode.setValue(this.countryCodeId);
      // set initial status
      this.mainFrm.controls.companyStatus.setValue(true);
      // get data
      await this.getCompanyActivities();
    } else {
      // businessName
      this.mainFrm.get('businessName')?.setValidators(null);
      this.mainFrm.controls['businessName'].updateValueAndValidity();
      // companyConstitutionDate
      this.mainFrm.get('companyConstitutionDate')?.setValidators(null);
      this.mainFrm.controls['companyConstitutionDate'].updateValueAndValidity();
      // companyCellphone
      this.mainFrm.get('companyCellphone')?.setValidators(null);
      this.mainFrm.controls['companyCellphone'].updateValueAndValidity();
      // companyPhone
      this.mainFrm.get('companyPhone')?.setValidators(null);
      this.mainFrm.controls['companyPhone'].updateValueAndValidity();
      // companyWhatsapp
      this.mainFrm.get('companyWhatsapp')?.setValidators(null);
      this.mainFrm.controls['companyWhatsapp'].updateValueAndValidity();
      // set initial status
      this.mainFrm.controls.companyStatus.setValue('pendingForReview');
    }
    // get data
    await this.generalData();
    await this.setCompanyData();
    // hide loader
    this.loaderEvent.emit(null);
  }
  // translate
  async translateMsgs() {
    // update angular editor
    if (this.currentLang === 'es') {
      this.editorConfig = editorCompanyAboutGlobalConfigEs;
    } else {
      this.editorConfig = editorCompanyAboutGlobalConfigEn;
    }
    // update form error messages
    this.formErrorMessages = {
      companyActivity: [
        { type: 'required', message: this.translate.instant('COMPANY.formValidation.companyActivity.required') }
      ],
      companyName: [
        { type: 'required', message: this.translate.instant('COMPANY.formValidation.companyName.required') }
      ],
      businessName: [
        { type: 'required', message: this.translate.instant('COMPANY.formValidation.businessName.required') }
      ],
      companyIdentification: [
        { type: 'required', message: this.translate.instant('COMPANY.formValidation.companyIdentification.required') }
      ],
       companyConstitutionDate: [
        { type: 'required', message: this.translate.instant('COMPANY.formValidation.companyConstitutionDate.required') },
        { type: 'invalidDate', message: this.translate.instant('HELPERS.invalidDate') },
      ],
      companyEmail: [
        { type: 'required', message: this.translate.instant('COMPANY.formValidation.companyEmail.required') },
      ],
      companyCellphoneCountryCode: [
        { type: 'required', message: this.translate.instant('COMPANY.formValidation.countryPhoneCode.required') }
      ],
      companyCellphone: [
        { type: 'minlength', message: this.translate.instant('COMPANY.formValidation.companyCellphone.minLength') },
        { type: 'maxlength', message: this.translate.instant('COMPANY.formValidation.companyCellphone.maxLength') },
        { type: 'pattern', message: this.translate.instant('COMPANY.formValidation.companyCellphone.pattern') },
      ],
      companyPhoneCountryCode: [
        { type: 'required', message: this.translate.instant('COMPANY.formValidation.countryPhoneCode.required') }
      ],
      companyPhone: [
        { type: 'minlength', message: this.translate.instant('COMPANY.formValidation.companyPhone.minLength') },
        { type: 'maxlength', message: this.translate.instant('COMPANY.formValidation.companyPhone.maxLength') },
        { type: 'pattern', message: this.translate.instant('COMPANY.formValidation.companyPhone.pattern') },
      ],
      companyStatus: [
        { type: 'required', message: this.translate.instant('COMPANY.formValidation.companyStatus.required') }
      ],
      country: [
        { type: 'required', message: this.translate.instant('LOCATION.formValidation.country.required') }
      ],
      state: [
        { type: 'required', message: this.translate.instant('LOCATION.formValidation.state.required') }
      ],
      city: [
        { type: 'required', message: this.translate.instant('LOCATION.formValidation.city.required') }
      ],
      address: [
        { type: 'required', message: this.translate.instant('LOCATION.formValidation.address.required') }
      ],
    };
  }
  // form
  async help() {
    if (this.toggleHelp) {
      this.toggleHelp = false;
    } else {
      this.toggleHelp = true;
    }
  }
  async setFocus(elementId: any) {
    elementId.focus();
  }
  checkFormField(fieldName: string) {
    // get field
    const field = this.mainFrm.get(fieldName);
    // check field
    const check = field?.invalid && field?.touched;
    // return data
    return check;
  }
  async checkPhoneNumber(checkFrom: string) {
    // checkFrom
    switch (checkFrom) {
      case 'cellphone':
        const cellphoneCode = this.companyCellphoneCountryCode;
        const cellphoneNumber = this.mainFrm.controls.companyCellphone.value;
        if (cellphoneNumber !== null) {
          const cellphoneCheckPlus = cellphoneNumber.includes('+' + cellphoneCode);
          // check cellphone
          if (cellphoneCheckPlus) {
            this.mainFrm.controls.companyCellphone.setValue(cellphoneNumber.replace('+' + cellphoneCode,''));
          }
        }
        break;
      case 'phone':
        const phoneCode = this.companyPhoneCountryCode;
        const phoneNumber = this.mainFrm.controls.companyPhone.value;
        if (phoneNumber !== null) {
          const phoneCheckPlus = phoneNumber.includes('+' + phoneCode);
          // check phone
          if (phoneCheckPlus) {
            this.mainFrm.controls.companyPhone.setValue(phoneNumber.replace('+' + phoneCode,''));
          }
        }
        break;
      case 'whatsApp':
        const whatsAppCode = this.companyWhatsappCountryCode;
        const whatsAppNumber = this.mainFrm.controls.companyWhatsapp.value;
        if (whatsAppNumber !== null) {
          const whatsAppCheckPlus = whatsAppNumber.includes('+' + whatsAppCode);
          // check whatsApp
          if (whatsAppCheckPlus) {
            this.mainFrm.controls.companyWhatsapp.setValue(whatsAppNumber.replace('+' + whatsAppCode,''));
          }
        }
        break;
    }
  }
  dateValidator() {
    return (control: FormControl) => {
      const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
      const valid = dateRegex.test(control.value);
      return (control.value !== null && control.value.length !== 10 && !valid) ? { invalidDate: true } : null;
    };
  }
  async toggleWebProtocol() {
    // toggle web protocol
    this.showWebProtocol = this.showWebProtocol === true ? false : true;
  }
  async selectWebProtocol(webProtocol: string) {
    // update form
    this.mainFrm.controls.companyWebProtocol.setValue(webProtocol);
    // hide web protocol
    this.showWebProtocol = false;
  }
  // navigation
  async cancel() {
    // emit data
    this.cancelEvent.emit(); // Return dat
  }
  // location
  async getCountries() {
    try {
      // get stored data countries
      const storedDataCountries: any = localStorage.getItem('countriesData');
      // set allCountries
      const allCountries: any = [];
      // set countries
      let countries: any;
      // check countries data
      if (!storedDataCountries) {
        // get data from api
        await this.apiSv.getCountries().then((response: any)=>{
          // get countries
          countries = response.data;
          // save countries on storage
          localStorage.setItem('countriesData', JSON.stringify(countries));
          // loop countries
          countries.map((country: CountryInterface) => {
            // push countries
            this.countries.push(country);
            // set countryData
            const countryData = {
              id: country.id,
              countryName: country.countryName
            }
            // push country
            allCountries.push(countryData);
          });
          // sort countries
          const countriesSorted = this.utilitiesSv.sortArrayByKey(allCountries, 'countryName');
          // set countryNullData
          const countryNullData = {
            id: null,
            countryName: this.translate.instant('LOCATION.formPlaceHolders.countrySelect')
          }
          // unshift country
          countriesSorted.unshift(countryNullData);
          // update all countries
          this.allCountries = countriesSorted;
        }, error=>{
          console.log('error', error);
        });
      } else {
        // get countries
        countries = JSON.parse(storedDataCountries);
        // loop countries
        countries.map((country: CountryInterface) => {
          // push countries
          this.countries.push(country);
          // set countryData
          const countryData = {
            id: country.id,
            countryName: country.countryName
          }
          // push country
          allCountries.push(countryData);
        });
        // sort countries
        const countriesSorted = this.utilitiesSv.sortArrayByKey(allCountries, 'countryName');
        // set countryNullData
        const countryNullData = {
          id: null,
          countryName: this.translate.instant('LOCATION.formPlaceHolders.countrySelect')
        }
        // unshift country
        countriesSorted.unshift(countryNullData);
        // update all countries
        this.allCountries = countriesSorted;
      }
    } catch (error: any) {
      console.log('error', error);
    }
  }
  async setInitialCountries() {
    this.mainFrm.controls.companyCellphoneCountryCode.setValue(this.companyCellphoneCountryCodeId);
    await this.selectCountry('cellphone');
    this.mainFrm.controls.companyPhoneCountryCode.setValue(this.companyPhoneCountryCodeId);
    await this.selectCountry('phone');
    this.mainFrm.controls.companyWhatsappCountryCode.setValue(this.companyWhatsappCountryCodeId);
    await this.selectCountry('whatsapp');
  }
  async selectCountry(selectFrom: string) {
    // check selectFrom
    switch (selectFrom) {
      case 'location':
        // get data
        const countryId: any = this.mainFrm.controls.country.value;
        const countryIndex: any = this.countries.findIndex((country: any) => country.id === countryId); 
        const country: CountryInterface = this.countries[countryIndex];
        // set birthDate country
        this.countryId = country.id;
        this.countryName = country.countryName;
        this.countryFlag = 'assets/images/flags/' + country.countryFlag + '.png';
        this.country = country;
        // clear birthDate states
        this.stateId = null;
        this.stateName = null;
        this.state = null;
        // clear birthDate cities
        this.cityId = null;
        this.cityName = null;
        this.city = null;
        // load states
        await this.getStates(country.id);
        break;
      case 'cellphone':
        // get data
        const companyCellphoneCountryId: any = this.mainFrm.controls.companyCellphoneCountryCode.value;
        const companyCellphoneCountryIndex: any = this.countries.findIndex((country: any) => country.id === companyCellphoneCountryId); 
        const companyCellphoneCountry: CountryInterface = this.countries[companyCellphoneCountryIndex];
        // set companyCellphone country
        this.companyCellphoneCountryId = companyCellphoneCountry.id;
        this.companyCellphoneCountry = companyCellphoneCountry;
        this.companyCellphoneCountryName = companyCellphoneCountry.countryName;
        this.companyCellphoneCountryFlag = 'assets/images/flags/' + companyCellphoneCountry.countryFlag + '.png';
        this.companyCellphoneCountryCode = companyCellphoneCountry.numericCode;
        this.companyCellphoneCountryCodeId = companyCellphoneCountry.id;
        // check cellphone number
        await this.checkPhoneNumber('cellphone');
        // close select cellphone code
        this.selectCompanyCellphoneCode = false;
        break;
      case 'phone':
        // get data
        const companyPhoneCountryId: any = this.mainFrm.controls.companyPhoneCountryCode.value;
        const companyPhoneCountryIndex: any = this.countries.findIndex((country: any) => country.id === companyPhoneCountryId); 
        const companyPhoneCountry: CountryInterface = this.countries[companyPhoneCountryIndex];
        // set companyPhone country
        this.companyPhoneCountryId = companyPhoneCountry.id;
        this.companyPhoneCountry = companyPhoneCountry;
        this.companyPhoneCountryName = companyPhoneCountry.countryName;
        this.companyPhoneCountryFlag = 'assets/images/flags/' + companyPhoneCountry.countryFlag + '.png';
        this.companyPhoneCountryCode = companyPhoneCountry.numericCode;
        this.companyPhoneCountryCodeId = companyPhoneCountry.id;
        // check phone number
        await this.checkPhoneNumber('phone');
        // close select phone code
        this.selectCompanyPhoneCode = false;
        break;
      case 'whatsapp':
        // get data
        const companyWhatsappCountryId: any = this.mainFrm.controls.companyWhatsappCountryCode.value;
        const companyWhatsappCountryIndex: any = this.countries.findIndex((country: any) => country.id === companyWhatsappCountryId); 
        const companyWhatsappCountry: CountryInterface = this.countries[companyWhatsappCountryIndex];
        // set companyWhatsapp country
        this.companyWhatsappCountryId = companyWhatsappCountry.id;
        this.companyWhatsappCountry = companyWhatsappCountry;
        this.companyWhatsappCountryName = companyWhatsappCountry.countryName;
        this.companyWhatsappCountryFlag = 'assets/images/flags/' + companyWhatsappCountry.countryFlag + '.png';
        this.companyWhatsappCountryCode = companyWhatsappCountry.numericCode;
        this.companyWhatsappCountryCodeId = companyWhatsappCountry.id;
        // check whatsapp number
        await this.checkPhoneNumber('whatsapp');
        // close select whatsapp code
        this.selectCompanyWhatsappCode = false;
        break;
    }
  }
  openSelectPhoneCountry(selectFrom: string) {
    switch (selectFrom) {
      case 'cellphone':
        this.selectCompanyCellphoneCode = true;
        break;
      case 'phone':
        this.selectCompanyPhoneCode = true;
        break;
      case 'whatsapp':
        this.selectCompanyWhatsappCode = true;
        break;
    }
  }
  async getStates(countryId: string) {
    try {
      // show location loader
      this.loadingLocation = true;
      // get data
      const states: any = await this.apiSv.getStates(countryId);
      // set allStates
      const allStates: any = [];
      // loop states
      states.data.forEach((state: StateInterface) => {
        // push states
        this.states.push(state);
        // set stateData
        const stateData = {
          id: state.id,
          stateName: state.name
        }
        // push state
        allStates.push(stateData);
      });
      // sort states
      const birthPlaceStatesSorted = this.utilitiesSv.sortArrayByKey(allStates, 'stateName');
      // update all states
      this.allStates = birthPlaceStatesSorted;
      // hide location loader
      this.loadingLocation = false;
    } catch (error: any) {
      console.log('error', error);
      // hide location loader
      this.loadingLocation = false;
    }
  }
  async selectState() {
    // get data
    const stateId: any = this.mainFrm.controls.state.value;
    const stateIndex: any = this.states.findIndex((state: any) => state.id === stateId); 
    const state: any = this.states[stateIndex];
    // set birthDate state
    this.stateId = state.id;
    this.stateName = state.name;
    this.state = state;
    // clear birthDate cities
    this.cityId = null;
    this.cityName = null;
    this.city = null;
    // load states
    await this.getCities(state.id);
  }
  async getCities(stateId: string) {
    try {
      // show location loader
      this.loadingLocation = true;
      // get data
      const cities: any = await this.apiSv.getCities(stateId);
      // set allCities
      const allCities: any = [];
      // loop cities
      cities.data.forEach((city: CityInterface) => {
        // push cities
        this.cities.push(city);
        // set birthPlaceCityData
        const birthPlaceCityData = {
          id: city.id,
          cityName: city.name
        }
        // push city
        allCities.push(birthPlaceCityData);
      });
      // sort cities
      const citiesSorted = this.utilitiesSv.sortArrayByKey(allCities, 'cityName');
      // update all cities
      this.allCities = citiesSorted;
      // hide location loader
      this.loadingLocation = false;
    } catch (error: any) {
      console.log('error', error);
      // hide location loader
      this.loadingLocation = false;
    }
  }
  async selectCity() {
    // get data
    const cityId: any = this.mainFrm.controls.city.value;
    const cityIndex: any = this.cities.findIndex((state: any) => state.id === cityId); 
    const city: any = this.cities[cityIndex];
    // set birthDate state
    this.cityId = city.id;
    this.cityName = city.name;
    this.city = city;
  }
  async resetLocationSelect() {
    // set country
    this.countryId = null;
    this.country = null;
    this.countryName = null;
    // clear states
    this.stateId = null;
    this.state = null;
    this.stateName = null;
    // clear cities
    this.cityId = null;
    this.city = null;
    this.cityName = null;
    // clear form data
    this.mainFrm.controls.country.setValue(null);
    this.mainFrm.controls.state.setValue(null);
    this.mainFrm.controls.city.setValue(null);
  }
  // data
  async getCompanyActivities() {
    try {
      // set companyActivitiesData
      const companyActivitiesData = {
        limit: 100000,
        page: 1,
        companyActivity: null,
        companyActivityStatus: null,
        companyId: null
      }
      // init auxCompanyActivities
      let auxCompanyActivities: any = null;
      // init companyActivities
      let companyActivities: any = null;
      // check origin
      if (this.origin === 'otherCompanyLoan') {
        auxCompanyActivities = await this.apiSv.getPublicCompanyActivities();
        // get companyActivities
        companyActivities = auxCompanyActivities.data;
      } else {
        auxCompanyActivities = await this.apiSv.getCompanyActivities(companyActivitiesData);
        // get companyActivities
        companyActivities = auxCompanyActivities.data.result;
      }
      // set allCompanyActivities
      const allCompanyActivities: any = [];
      // loop companyActivities
      companyActivities.map((companyActivity: CompanyActivityInterface) => {
        // push companyActivities
        this.companyActivities.push(companyActivity);
        // set companyActivityData
        const companyActivityData = {
          id: companyActivity.id,
          companyActivity: companyActivity.companyActivityCode + ' - ' + companyActivity.companyActivity
        }
        // push country
        allCompanyActivities.push(companyActivityData);
      });
      // sort companyActivities
      const companyActivitiesSorted = this.utilitiesSv.sortArrayByKey(allCompanyActivities, 'companyActivity');
      // set companyActivityNullData
      const companyActivityNullData = {
        id: null,
        companyActivity: this.translate.instant('HELPERS.selectAnOption')
      }
      // unshift company activity
      companyActivitiesSorted.unshift(companyActivityNullData);
      // update all company activities
      this.allCompanyActivities = companyActivitiesSorted;
    } catch (error: any) {
      console.log('error', error);
    }
  }
  async getCurrentUserData() {
    // get stored data user
    const storedDataUser: any = localStorage.getItem('userData');
    // check user data
    if (storedDataUser != null) {
      // get data user
      const userData: UserInterface = JSON.parse(storedDataUser);
      // update data user
      this.currentUserData = userData;
      // check roles
      const checkAdmin = await this.utilitiesSv.checkRoles(userData.role, ['support', 'administrator']);
      this.isAdmin = checkAdmin;
      const checkFinancial = await this.utilitiesSv.checkRoles(userData.role, ['support', 'administrator', 'board', 'treasure', 'businessAgent']);
      this.isFinancial = checkFinancial;
      const checkCompany = await this.utilitiesSv.checkRoles(userData.role, ['ceo', 'manager', 'auxiliar']);
      this.isCompany = checkCompany;
    }
  }
  async setCompanyData() {
    // check company data
    if (this.companyData !== null) {
      // set company
      const company: CompanyInterface = this.companyData;

      console.log('company', company);

      // check origin
      if (!(this.origin === 'otherCompany' || this.origin === 'otherCompanyLoan')) {
        // set companyPortrait
        this.companyPortraitSource = company.companyPortrait;
        // set companyLogo
        this.companyLogoSource = company.companyLogo;
        // set company data
        this.businessName = company.businessName;
        // set form data
        this.mainFrm.controls.companyActivity.setValue(company.companyActivityId);
        this.mainFrm.controls.companyName.setValue(company.companyName);
        this.mainFrm.controls.businessName.setValue(company.businessName);
        this.mainFrm.controls.companyIdentification.setValue(company.companyIdentification);
        // set companyConstitutionDate data
        const companyConstitutionDate = moment.utc(company.companyConstitutionDate).format('YYYY-MM-DD');
        this.mainFrm.controls.companyConstitutionDate.setValue(companyConstitutionDate);
        this.mainFrm.controls.companyAbout.setValue(company.companyAbout);
        this.mainFrm.controls.companyEmail.setValue(company.companyEmail);
        this.mainFrm.controls.country.setValue(company.countryId);
        this.mainFrm.controls.state.setValue(company.stateId);
        this.mainFrm.controls.city.setValue(company.cityId);
        this.mainFrm.controls.address.setValue(company.address);
        this.mainFrm.controls.address2.setValue(company.address2);
        this.mainFrm.controls.companyCellphoneCountryCode.setValue(company.companyCellphoneCountryCode);
        this.mainFrm.controls.companyCellphone.setValue(company.companyCellphone);
        this.mainFrm.controls.companyPhoneCountryCode.setValue(company.companyPhoneCountryCode);
        this.mainFrm.controls.companyPhone.setValue(company.companyPhone);
        this.mainFrm.controls.companyWhatsappCountryCode.setValue(company.companyWhatsappCountryCode);
        this.mainFrm.controls.companyWhatsapp.setValue(company.companyWhatsapp);
        this.mainFrm.controls.companyUriFacebook.setValue(company.companyUriFacebook);
        this.mainFrm.controls.companyUserInstagram.setValue(company.companyUserInstagram);
        this.mainFrm.controls.companyUserTwitter.setValue(company.companyUserTwitter);
        this.mainFrm.controls.companyUriTiktok.setValue(company.companyUriTiktok);
        this.mainFrm.controls.companyUriYoutube.setValue(company.companyUriYoutube);
        this.mainFrm.controls.companyUriLinkedin.setValue(company.companyUriLinkedin);
        this.mainFrm.controls.companyWebProtocol.setValue(company.companyWebProtocol);
        this.mainFrm.controls.companyWeb.setValue(company.companyWeb);
        this.mainFrm.controls.companyStatus.setValue(company.companyStatus);
        // set selects
        // check companyCellphone
        if (!(company.companyCellphone === null || company.companyCellphone === undefined)) {
          await this.selectCountry('cellphone');
          this.checkPhoneNumber('cellphone');
        }
        // check companyPhone
        if (!(company.companyPhone === null || company.companyPhone === undefined)) {
          await this.selectCountry('phone');
          this.checkPhoneNumber('phone');
        }
        // check companyWhatsapp
        if (!(company.companyWhatsapp === null || company.companyWhatsapp === undefined)) {
          await this.selectCountry('whatsapp');
          this.checkPhoneNumber('whatsapp');
        }
        // check countryId
        if (!(company.countryId === null || company.countryId === undefined)) {
          await this.selectCountry('location');
          await this.selectState();
          await this.selectCity();
        }
      }else {
        // set companyPortrait
        this.companyPortraitSource = company.companyPortrait;
        // set companyLogo
        this.companyLogoSource = company.companyLogo;
        // set company data
        this.businessName = company.businessName;
        // set form data
        this.mainFrm.controls.companyActivity.setValue(company.companyActivityId);
        this.mainFrm.controls.companyName.setValue(company.companyName);
        this.mainFrm.controls.companyIdentification.setValue(company.companyIdentification);
        this.mainFrm.controls.country.setValue(company.countryId);
        this.mainFrm.controls.state.setValue(company.stateId);
        this.mainFrm.controls.city.setValue(company.cityId);
        this.mainFrm.controls.address.setValue(company.address);
        this.mainFrm.controls.address2.setValue(company.address2);
        this.mainFrm.controls.companyStatus.setValue(company.companyStatus);
        // check countryId
        if (!(company.countryId === null || company.countryId === undefined)) {
          await this.selectCountry('location');
          await this.selectState();
          await this.selectCity();
        }
      }
      // set savedData
      this.savedData = true;
    }
  }
  // actions
  async save() {
    // markAllAsTouched
    this.mainFrm.markAllAsTouched();

    console.log('this.mainFrm', this.mainFrm);

    // check origin
    if (!(this.origin === 'otherCompany' || this.origin === 'otherCompanyLoan')) {
      // revalidate
      await this.checkPhoneNumber('cellphone');
      await this.checkPhoneNumber('phone');
      await this.checkPhoneNumber('whatsapp');
      // validate data
      if (!this.mainFrm.valid) {
        // send alert
        this.alertSv.showMessage(this.translate.instant('HELPERS.requiredFields'),'warning', this.translate.instant('HELPERS.warning'), true);
      } else {
        // check if company is been saved before
        if (!this.savedData) {
          // add company
          await this.addCompany();
        } else {
          // update company
          await this.updateCompany();
        }
      }
    } else {
      // validate data
      if (!this.mainFrm.valid) {
        // send alert
        this.alertSv.showMessage(this.translate.instant('HELPERS.requiredFields'),'warning', this.translate.instant('HELPERS.warning'), true);
      } else {
        // check if company is been saved before
        if (!this.savedData) {
          // add company
          await this.addOtherCompany();
        } else {
          // update company
          await this.updateOtherCompany();
        }
      }
    }
  }
  async addCompany() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // get name data
      const companyName = this.mainFrm.controls.companyName.value;
      const businessName = this.mainFrm.controls.businessName.value;
      // init companyAbout
      let companyAbout: string = this.mainFrm.controls.companyAbout.value;
      // check if observation is not null
      if (companyAbout !== null) {
        // fix companyAbout fontsize
        companyAbout = this.utilitiesSv.replaceStringOnString(companyAbout, '<font size="3">', '<font size="2">');
        // check if there is not font size parameter
        if (!companyAbout.includes('<font size="2">')) {
          companyAbout = '<font size="2">' + companyAbout + '</font>';
        }
      }
      // set companyData
      const companyData: CompanyInterface = {
        ...((this.companyLogoUpdate) && {companyLogo: this.companyLogoSource}),
        ...((this.companyPortraitUpdate) && {companyPortrait: this.companyPortraitSource}),
        companyActivityId: this.mainFrm.controls.companyActivity.value,
        companyName: companyName !== null ? this.utilitiesSv.capitalizeString(companyName) : null,
        businessName: businessName !== null ? this.utilitiesSv.capitalizeString(businessName) : null,
        companyIdentification: this.mainFrm.controls.companyIdentification.value,
        companyConstitutionDate: this.mainFrm.controls.companyConstitutionDate.value,
        companyAbout,
        companyEmail: this.mainFrm.controls.companyEmail.value,
        cityId: this.mainFrm.controls.city.value,
        address: this.mainFrm.controls.address.value,
        address2: this.mainFrm.controls.address2.value,
        companyCellphoneCountryCode: this.mainFrm.controls.companyCellphoneCountryCode.value,
        companyCellphone: this.mainFrm.controls.companyCellphone.value,
        companyPhoneCountryCode: this.mainFrm.controls.companyPhoneCountryCode.value,
        companyPhone: this.mainFrm.controls.companyPhone.value,
        companyWhatsappCountryCode: this.mainFrm.controls.companyWhatsappCountryCode.value,
        companyWhatsapp: this.mainFrm.controls.companyWhatsapp.value,
        companyUriFacebook: this.mainFrm.controls.companyUriFacebook.value,
        companyUserInstagram: this.mainFrm.controls.companyUserInstagram.value,
        companyUserTwitter: this.mainFrm.controls.companyUserTwitter.value,
        companyUriTiktok: this.mainFrm.controls.companyUriTiktok.value,
        companyUriYoutube: this.mainFrm.controls.companyUriYoutube.value,
        companyUriLinkedin: this.mainFrm.controls.companyUriLinkedin.value,
        companyWebProtocol: this.mainFrm.controls.companyWebProtocol.value,
        companyWeb: this.mainFrm.controls.companyWeb.value,
        companyStatus: this.mainFrm.controls.companyStatus.value,
      }
      // get auxCompany
      const auxCompany: any = await this.apiSv.addCompany(companyData);
      // get data
      const data: any = auxCompany.data;
      // get companyId
      const companyId: string = data.id;
      // set savedData
      this.savedData = true;
      // set saveCompanyData
      const saveCompanyData: CompanyInterface = {
        id: companyId,
        companyLogo: this.companyLogoSource,
        companyPortrait: this.companyPortraitSource,
        companyActivity: this.mainFrm.controls.companyActivity.value,
        companyName: companyName !== null ? this.utilitiesSv.capitalizeString(companyName) : null,
        businessName: businessName !== null ? this.utilitiesSv.capitalizeString(businessName) : null,
        companyIdentification: this.mainFrm.controls.companyIdentification.value,
        companyConstitutionDate: this.mainFrm.controls.companyConstitutionDate.value,
        companyAbout,
        companyEmail: this.mainFrm.controls.companyEmail.value,
        countryFlag: this.countryFlag,
        countryId: this.countryId,
        stateId: this.stateId,
        stateName: this.stateName,
        cityId: this.cityId,
        cityName: this.cityName,
        address: this.mainFrm.controls.address.value,
        address2: this.mainFrm.controls.address2.value,
        companyCellphoneCountryCode: this.mainFrm.controls.companyCellphoneCountryCode.value,
        companyCellphone: this.mainFrm.controls.companyCellphone.value,
        companyPhoneCountryCode: this.mainFrm.controls.companyPhoneCountryCode.value,
        companyPhone: this.mainFrm.controls.companyPhone.value,
        companyWhatsappCountryCode: this.mainFrm.controls.companyWhatsappCountryCode.value,
        companyWhatsapp: this.mainFrm.controls.companyWhatsapp.value,
        companyUriFacebook: this.mainFrm.controls.companyUriFacebook.value,
        companyUserInstagram: this.mainFrm.controls.companyUserInstagram.value,
        companyUserTwitter: this.mainFrm.controls.companyUserTwitter.value,
        companyUriTiktok: this.mainFrm.controls.companyUriTiktok.value,
        companyUriYoutube: this.mainFrm.controls.companyUriYoutube.value,
        companyUriLinkedin: this.mainFrm.controls.companyUriLinkedin.value,
        companyWebProtocol: this.mainFrm.controls.companyWebProtocol.value,
        companyWeb: this.mainFrm.controls.companyWeb.value,
        companyStatus: this.mainFrm.controls.companyStatus.value,
      }
      // update companyData
      this.companyData = saveCompanyData;
      // hide loader
      this.loaderEvent.emit(null);
      // emit data
      this.addEvent.emit(saveCompanyData);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async updateCompany() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // get name data
      const companyName = this.mainFrm.controls.companyName.value;
      const businessName = this.mainFrm.controls.businessName.value;
      // init companyAbout
      let companyAbout: string = this.mainFrm.controls.companyAbout.value;
      // check if observation is not null
      if (companyAbout !== null) {
        // fix companyAbout fontsize
        companyAbout = this.utilitiesSv.replaceStringOnString(companyAbout, '<font size="3">', '<font size="2">');
        // check if there is not font size parameter
        if (!companyAbout.includes('<font size="2">')) {
          companyAbout = '<font size="2">' + companyAbout + '</font>';
        }
      }
      // set companyData
      const companyData: CompanyInterface = {
        ...((this.companyLogoUpdate) && {companyLogo: this.companyLogoSource}),
        ...((this.companyPortraitUpdate) && {companyPortrait: this.companyPortraitSource}),
        companyActivityId: this.mainFrm.controls.companyActivity.value,
        companyName: companyName !== null ? this.utilitiesSv.capitalizeString(companyName) : null,
        businessName: businessName !== null ? this.utilitiesSv.capitalizeString(businessName) : null,
        companyIdentification: this.mainFrm.controls.companyIdentification.value,
        companyConstitutionDate: this.mainFrm.controls.companyConstitutionDate.value,
        companyAbout,
        companyEmail: this.mainFrm.controls.companyEmail.value,
        cityId: this.mainFrm.controls.city.value,
        address: this.mainFrm.controls.address.value,
        address2: this.mainFrm.controls.address2.value,
        companyCellphoneCountryCode: this.mainFrm.controls.companyCellphoneCountryCode.value,
        companyCellphone: this.mainFrm.controls.companyCellphone.value,
        companyPhoneCountryCode: this.mainFrm.controls.companyPhoneCountryCode.value,
        companyPhone: this.mainFrm.controls.companyPhone.value,
        companyWhatsappCountryCode: this.mainFrm.controls.companyWhatsappCountryCode.value,
        companyWhatsapp: this.mainFrm.controls.companyWhatsapp.value,
        companyUriFacebook: this.mainFrm.controls.companyUriFacebook.value,
        companyUserInstagram: this.mainFrm.controls.companyUserInstagram.value,
        companyUserTwitter: this.mainFrm.controls.companyUserTwitter.value,
        companyUriTiktok: this.mainFrm.controls.companyUriTiktok.value,
        companyUriYoutube: this.mainFrm.controls.companyUriYoutube.value,
        companyUriLinkedin: this.mainFrm.controls.companyUriLinkedin.value,
        companyWebProtocol: this.mainFrm.controls.companyWebProtocol.value,
        companyWeb: this.mainFrm.controls.companyWeb.value,
        companyStatus: this.mainFrm.controls.companyStatus.value,
      }
      // update company
      await this.apiSv.updateCompany(this.companyData.id, companyData);
      // update companyData
      this.companyData.companyLogo = this.companyLogoSource;
      this.companyData.companyPortrait = this.companyPortraitSource;
      this.companyData.companyActivityId = this.mainFrm.controls.companyActivity.value;
      this.companyData.companyName = companyName !== null ? this.utilitiesSv.capitalizeString(companyName) : null;
      this.companyData.businessName = businessName !== null ? this.utilitiesSv.capitalizeString(businessName) : null;
      this.companyData.companyIdentification = this.mainFrm.controls.companyIdentification.value;
      this.companyData.companyConstitutionDate = this.mainFrm.controls.companyConstitutionDate.value;
      this.companyData.companyAbout = companyAbout;
      this.companyData.companyEmail = this.mainFrm.controls.companyEmail.value;
      this.companyData.cityId = this.mainFrm.controls.city.value;
      this.companyData.countryFlag = this.countryFlag;
      this.companyData.countryId = this.countryId;
      this.companyData.stateId = this.stateId;
      this.companyData.stateName = this.stateName;
      this.companyData.cityId = this.cityId;
      this.companyData.cityName = this.cityName;
      this.companyData.address = this.mainFrm.controls.address.value;
      this.companyData.address2 = this.mainFrm.controls.address2.value;
      this.companyData.companyCellphoneCountryCode = this.mainFrm.controls.companyCellphoneCountryCode.value;
      this.companyData.companyCellphone = this.mainFrm.controls.companyCellphone.value;
      this.companyData.companyPhoneCountryCode = this.mainFrm.controls.companyPhoneCountryCode.value;
      this.companyData.companyPhone = this.mainFrm.controls.companyPhone.value;
      this.companyData.companyWhatsappCountryCode = this.mainFrm.controls.companyWhatsappCountryCode.value;
      this.companyData.companyWhatsapp = this.mainFrm.controls.companyWhatsapp.value;
      this.companyData.companyUriFacebook = this.mainFrm.controls.companyUriFacebook.value;
      this.companyData.companyUserInstagram = this.mainFrm.controls.companyUserInstagram.value;
      this.companyData.companyUserTwitter = this.mainFrm.controls.companyUserTwitter.value;
      this.companyData.companyUriTiktok = this.mainFrm.controls.companyUriTiktok.value;
      this.companyData.companyUriYoutube = this.mainFrm.controls.companyUriYoutube.value;
      this.companyData.companyUriLinkedin = this.mainFrm.controls.companyUriLinkedin.value;
      this.companyData.companyWebProtocol = this.mainFrm.controls.companyWebProtocol.value;
      this.companyData.companyWeb = this.mainFrm.controls.companyWeb.value;
      this.companyData.companyStatus = this.mainFrm.controls.companyStatus.value;
      // hide loader
      this.loaderEvent.emit(null);
      // emit data
      this.updateEvent.emit(this.companyData);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async addOtherCompany() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // get name data
      const companyName = this.mainFrm.controls.companyName.value;
      // set companyData
      const companyData: CompanyInterface = {
        // companyActivityId: this.mainFrm.controls.companyActivity.value, // TODO: ADD ON API
        companyName: companyName !== null ? this.utilitiesSv.capitalizeString(companyName) : null,
        // companyIdentification: this.mainFrm.controls.companyIdentification.value, // TODO: ADD ON API
        cityId: this.mainFrm.controls.city.value,
        address: this.mainFrm.controls.address.value,
        address2: this.mainFrm.controls.address2.value,
        companyStatus: this.origin !== 'otherCompanyLoan' ?  this.mainFrm.controls.companyStatus.value : 'pendingForReview',
      }
      // init auxCompany
      let auxCompany: any = null;
      // init ata
      let data: any = null;
      // init companyId
      let companyId: string = null;
      // check origin
      if (this.origin === 'otherCompanyLoan') {
        auxCompany = await this.apiSv.addPublicOtherCompany(companyData);
        // get data
        data = auxCompany.data;
        // get companyId
        companyId = data;
      } else {
        auxCompany = await this.apiSv.addOtherCompany(companyData);
        // get data
        data = auxCompany.data;
        // get companyId
        companyId = data.id;
      }
      // set savedData
      this.savedData = true;
      // set saveCompanyData
      const saveCompanyData: CompanyInterface = {
        id: companyId,
        companyActivityId: this.mainFrm.controls.companyActivity.value,
        companyName: companyName !== null ? this.utilitiesSv.capitalizeString(companyName) : null,
        companyIdentification: this.mainFrm.controls.companyIdentification.value,
        countryFlag: this.countryFlag,
        countryId: this.countryId,
        stateId: this.stateId,
        stateName: this.stateName,
        cityId: this.cityId,
        cityName: this.cityName,
        address: this.mainFrm.controls.address.value,
        address2: this.mainFrm.controls.address2.value,
        companyStatus: this.mainFrm.controls.companyStatus.value,
      }
      // update companyData
      this.companyData = saveCompanyData;

      console.log('saveCompanyData', saveCompanyData);

      // hide loader
      this.loaderEvent.emit(null);
      // emit data
      this.addEvent.emit(saveCompanyData);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async updateOtherCompany() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // get name data
      const companyName = this.mainFrm.controls.companyName.value;
      // set companyData
      const companyData: CompanyInterface = {
        companyActivityId: this.mainFrm.controls.companyActivity.value,
        companyName: companyName !== null ? this.utilitiesSv.capitalizeString(companyName) : null,
        companyIdentification: this.mainFrm.controls.companyIdentification.value,
        cityId: this.mainFrm.controls.city.value,
        address: this.mainFrm.controls.address.value,
        address2: this.mainFrm.controls.address2.value,
        companyStatus: this.mainFrm.controls.companyStatus.value,
      }
      // check origin
      if (this.origin === 'otherCompanyLoan') {
        // update company
        await this.apiSv.updatePublicOtherCompany(this.companyData.id, companyData);
      } else {
        // update company
        await this.apiSv.updateOtherCompany(this.companyData.id, companyData);
      }
      // update companyData
      this.companyData.companyActivityId = this.mainFrm.controls.companyActivity.value;
      this.companyData.companyName = companyName !== null ? this.utilitiesSv.capitalizeString(companyName) : null;
      this.companyData.companyIdentification = this.mainFrm.controls.companyIdentification.value;
      this.companyData.countryFlag = this.countryFlag;
      this.companyData.countryId = this.countryId;
      this.companyData.country = this.country;
      this.companyData.stateId = this.stateId;
      this.companyData.stateName = this.stateName;
      this.companyData.state = this.state;
      this.companyData.cityId = this.cityId;
      this.companyData.cityName = this.cityName;
      this.companyData.city = this.city;
      this.companyData.address = this.mainFrm.controls.address.value;
      this.companyData.address2 = this.mainFrm.controls.address2.value;
      this.companyData.companyStatus = this.mainFrm.controls.companyStatus.value;
      // hide loader
      this.loaderEvent.emit(null);
      // emit data
      this.updateEvent.emit(this.companyData);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  // image
  async editImage(editImage: string) {
    // editImage
    this.editImageSelection = editImage;
    // trigger hidden input file
    this.fileInput.nativeElement.click();
  }
  async onFileSelected(event: any, modal: any) {
    const file = event.target.files?.[0];
    if (file) {
      // check fileType
      if (file.type.startsWith('image/')) {
        // get uploaded file data
        const fileSize = file.size;
        // check file size
        if (fileSize >= environment.maxFileData) {
          // hide loader
          this.loaderEvent.emit(null);
          // show loader
          this.alertSv.showMessage(this.translate.instant('HELPERS.fileTooBig'),'error', this.translate.instant('HELPERS.error'), true);
        } else {
          // set image changed event
          this.imageChangedEvent = event;
          // showCropper
          this.showCropper = true;
          // open crop modal
          this.modalService.open(modal);
        }
      } else {
        // hide loader
        this.loaderEvent.emit(null);
        // show loader
        this.alertSv.showMessage(this.translate.instant('HELPERS.fileNoImage'),'error', this.translate.instant('HELPERS.error'), true);
      }
    } else {
      // hide loader
      this.loaderEvent.emit(null);
    }
  }
  async uploadFile(file: any) {
    const imageData = new FormData()
    imageData.append('file', file);
    // send file to api
    const imageFile: any = await this.apiSv.uploadFile(imageData);
    // get file data
    const fileData: any = imageFile.data;
    const filePath = fileData.filePath;
    // save file
    this.updateFile(filePath);
  }
  async updateFile(filePath: string) {
    try {
      // check editImageSelection
      switch (this.editImageSelection) {
        case 'logo':
          // mark companyLogoUpdate
          this.companyLogoUpdate = true;
          // update logo source
          this.companyLogoSource = filePath;
          break;
        case 'portrait':
          // mark companyPortraitUpdate
          this.companyPortraitUpdate = true;
          // update portrait source
          this.companyPortraitSource = filePath;
          break;
      }
      // hide loader
      this.loaderEvent.emit(null);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  imageCroppingClose() {
    // check editImageSelection
    switch (this.editImageSelection) {
      case 'logo':
        // clear logo
        this.companyLogoFile = null;
        break;
      case 'portrait':
        // clear portrait
        this.companyPortraitFile = null;
        break;
    }
    // clear image event
    this.imageChangedEvent = null;
    // hide modal
    this.modalService.dismissAll();
  }
  async imageCroppingFinish() {
    // hide modal
    this.modalService.dismissAll();
    // show loader
    this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
    // init baseFileName
    let baseFileName: string = null;
    // init base 64 data
    let b64Data: string = null;
    // check editImageSelection
    switch (this.editImageSelection) {
      case 'logo':
        // clear logo
        this.companyLogoFile = null;
        // get base 64 data
        b64Data = this.companyLogoSource;
        // set baseFileName
        baseFileName = 'logo.png';
        break;
      case 'portrait':
        // clear portrait
        this.companyPortraitFile = null;
        // get base 64 data
        b64Data = this.companyPortraitSource;
        // set baseFileName
        baseFileName = 'portrait.png';
        break;
    }
    // get file data
    const businessName = this.mainFrm.controls.businessName.value;
    const fileName = businessName === null ? baseFileName : await this.utilitiesSv.slugify(businessName) + '-' + baseFileName;
    var file = this.mediaSv.dataURLtoFile(b64Data, fileName);
    // upload file
    await this.uploadFile(file);
  }
  // cropping media
  imageCropped(event: ImageCroppedEvent) {
    // check editImageSelection
    switch (this.editImageSelection) {
      case 'logo':
        // clear logo
        this.companyLogoSource = event.base64;
        break;
      case 'portrait':
        // clear portrait
        this.companyPortraitSource = event.base64;
        break;
    }
  }
  imageLoadImageFailed() {
    // show message
    this.alertSv.showMessage(this.translate.instant('HELPERS.fileLoadImageError'),'error', this.translate.instant('HELPERS.error'), true);
  }
  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }
  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }
  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }
  flipHorizontal() {
    this.transform = {
        ...this.transform,
        flipH: !this.transform.flipH
    };
  }
  flipVertical() {
    this.transform = {
        ...this.transform,
        flipV: !this.transform.flipV
    };
  }
  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }
  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }
}
