// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 03/06/2023
// import
import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
// translate
import { TranslateService } from '@ngx-translate/core';
// services
import { BreadcrumbsService } from 'src/app/shared/services/breadcrumbs.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
// interfaces
import { BankInterface } from 'src/app/shared/interfaces/bank.interface';
// component
@Component({
  selector: 'app-banks-card',
  templateUrl: './banks-card.component.html',
  styleUrls: ['./banks-card.component.scss']
})
// class
export class BanksCardComponent implements OnInit, OnChanges {
  // variables
  @Input() banksData: any = null;
  @Output() loaderEvent = new EventEmitter();
  @Output() openEvent = new EventEmitter();
  @Output() editEvent = new EventEmitter();
  @Output() deleteEvent = new EventEmitter();
  // banks data
  banks: any[] = [];
  // constructor
  constructor(
    public router: Router,
    public translate: TranslateService,
    public breadcrumbSv: BreadcrumbsService,
    public apiSv: ApiService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    private utilitiesSv: UtilitiesService,
  ) {
  }
  // life cycle
  async ngOnInit() {
    await this.setBanks();
  }
  async ngOnChanges(changes: SimpleChanges) {
    try {
      if (changes.banksData) {
        await this.setBanks();
      }
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  // loader
  async updateLoader(loaderMsg: any) {
    this.utilitiesSv.updateLoader(loaderMsg);
  }
  // data
  async setBanks() {
    if (this.banksData !== null) {
      // update banks
      this.banks = this.banksData;
    }
  }
  openBank(bank: BankInterface) {
    // emit data
    this.openEvent.emit(bank);
  }
  editBank(bank: BankInterface) {
    // emit data
    this.editEvent.emit(bank);
  }
}
