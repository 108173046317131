<!-- form -->
<form class="theme-form" [formGroup]="mainFrm">
  <!-- images -->
  <div class="row">
    <!-- logo -->
    <div class="col-12">
      <div class="logo-container">
        <div class="social-img-wrap">
          <!-- image -->
          <div class="social-img">
            <img [src]="logoSource" [alt]="configName" onError="src = 'assets/images/broken-image-slim.png'" *ngIf="logoSource !== null">
            <img src="assets/images/broken-image-slim.png" [alt]="configName" *ngIf="logoSource === null">
          </div>
          <!-- button -->
          <div class="add-icon" (click)="editImage()" *ngIf="!savedData">
            <app-feather-icons class="feather-icon" [icon]="'camera'"></app-feather-icons>
          </div>
          <div class="edit-icon" (click)="editImage()" *ngIf="savedData">
            <app-feather-icons class="feather-icon" [icon]="'edit-3'" ></app-feather-icons>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- file input -->
  <input type="file" #fileInput style="display: none" (change)="onFileSelected($event, imageCropModal)" />
  <!-- header -->
  <div class="row mt-4">
    <!-- title -->
    <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
      <h4>{{ 'CONFIG.config' | translate }}</h4>
      <p>{{ 'CONFIG.configInfo' | translate }}</p>
    </div>
    <!-- help -->
    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 pull-right">
      <div class="btn-with-outside-txt-lbl help-btn" (click)="help()">
        <small class="btn-inner-txt">{{ 'HELPERS.help' | translate }}</small> 
        <a class="btn btn-light btn-xs btn-icon-only btn-icon-text-left">
          <app-feather-icons [icon]="'help-circle'"></app-feather-icons>
        </a>
      </div>
    </div>
  </div>
  <!-- companyName / documentNumber -->
  <div class="row mt-4">
    <!-- companyName -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group">
        <label for="companyName">{{ 'CONFIG.formLabels.companyName' | translate }} <span class="required-lbl">*</span></label>
        <input class="form-control" #companyName id="companyName" type="text" inputmode="text" [placeholder]="'CONFIG.formPlaceHolders.companyName' | translate" formControlName="companyName">
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.companyName">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('companyName').hasError(error.type) && (mainFrm.get('companyName').dirty || mainFrm.get('companyName').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'CONFIG.formHelp.companyName' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- documentNumber -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group">
        <label for="documentNumber">{{ 'CONFIG.formLabels.documentNumber' | translate }} <span class="required-lbl">*</span></label>
        <input class="form-control" #documentNumber id="documentNumber" type="text" inputmode="text" [placeholder]="'CONFIG.formPlaceHolders.documentNumber' | translate" formControlName="documentNumber">
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.documentNumber">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('documentNumber').hasError(error.type) && (mainFrm.get('documentNumber').dirty || mainFrm.get('documentNumber').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'CONFIG.formHelp.documentNumber' | translate }}</small>
        </p>
      </div>
    </div>
  </div>
  <!-- buttons -->
  <div class="row mt-4">
    <div class="col">
      <div class="request-form-btns-container">
        <button class="btn btn-dark d-block" (click)="save()" type="button">
          <span>{{ 'HELPERS.save' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</form>
<!-- image crop modal -->
<ng-template #imageCropModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Avatar</h4>
    <button type="button" class="close" aria-label="Close" (click)="imageCroppingClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <image-cropper 
      [imageChangedEvent]="imageChangedEvent" 
      [maintainAspectRatio]="true"
      [containWithinAspectRatio]="containWithinAspectRatio" 
      [aspectRatio]="1 / 1" 
      [resizeToWidth]="320"
      [cropperMinWidth]="128" 
      [onlyScaleDown]="true" 
      [roundCropper]="true"
      [canvasRotation]="canvasRotation" 
      [transform]="transform" 
      [alignImage]="'center'"
      format="png" 
      (imageCropped)="imageCropped($event)"
      (loadImageFailed)="imageLoadImageFailed()">
    </image-cropper>
    <div class="btn-showcase">
      <button class="btn btn-xs btn-primary btn-icon-only" (click)="rotateLeft()"><app-feather-icons [icon]="'rotate-ccw'"></app-feather-icons></button>
      <button class="btn btn-xs btn-primary btn-icon-only" (click)="rotateRight()"><app-feather-icons [icon]="'rotate-cw'"></app-feather-icons></button>
      <!-- <button class="btn btn-xs btn-primary btn-icon-only" (click)="flipHorizontal()"><app-feather-icons [icon]="'more-horizontal'"></app-feather-icons></button>
      <button class="btn btn-xs btn-primary btn-icon-only" (click)="flipVertical()"><app-feather-icons [icon]="'more-vertical'"></app-feather-icons></button> -->
      <button class="btn btn-xs btn-primary btn-icon-only" (click)="zoomOut()"><app-feather-icons [icon]="'zoom-out'"></app-feather-icons></button>
      <button class="btn btn-xs btn-primary btn-icon-only" (click)="zoomIn()"><app-feather-icons [icon]="'zoom-in'"></app-feather-icons></button>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-light d-block" (click)="imageCroppingClose()" type="button">
      <span>{{ 'HELPERS.cancel' | translate }}</span>
    </button>
    <button class="btn btn-dark d-block" (click)="imageCroppingFinish()" type="button">
      <span>{{ 'HELPERS.save' | translate }}</span>
    </button>
  </div>
</ng-template>