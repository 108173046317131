// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 29/05/2023
// import
import { Component, EventEmitter, ViewChild, OnInit, OnChanges, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
// translate
import { TranslateService } from '@ngx-translate/core';
// plugins
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
// services
import { AuthService } from 'src/app/shared/services/auth.service';
import { EncodingService } from 'src/app/shared/services/encoding.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
// interfaces
import { UserInterface } from 'src/app/shared/interfaces/user.interface';
import { UserAddressInterface } from 'src/app/shared/interfaces/user-address.interface';
import { CityInterface } from 'src/app/shared/interfaces/city.interface';
// variables
declare var $: any;
// component
@Component({
  selector: 'app-user-addresses',
  templateUrl: './user-addresses.component.html',
  styleUrls: ['./user-addresses.component.scss']
})
// class
export class UserAddressesComponent implements OnInit, OnChanges {
  // variables
  @Input() userData: UserInterface = null;
  @Input() userAddressesData: any[] = [];
  @Input() origin: string = null;
  @Input() editEnable: boolean = false;
  @Output() loaderEvent = new EventEmitter();
  @Output() saveEvent = new EventEmitter();
  @Output() backEvent = new EventEmitter();
  // form data
  public mainFrm: FormGroup;
  // messages data
  formErrorMessages: any = {};
  // help data
  toggleHelp = false;
  // address data
  userAddresses: any[] = [];
  userAddress: UserAddressInterface = null;
  savedData: boolean = false;
  userAddressTempId: number = 0;
  formType: string = null;
  mailingUserAddressSelected: boolean = false;
  mailingUserAddressId: string = null;
  // constructor
  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public authSv: AuthService,
    public encodingSv: EncodingService,
    public apiSv: ApiService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    public utilitiesSv: UtilitiesService,
    private modalService: NgbModal,
  ) { }
  // life cycle
  async ngOnInit() {
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.userAddressesData) {
      await this.setUserAddresses();
    }
  }
  // navigation
  async goBack() {
    // emit data
    this.backEvent.emit(); // Return data
  }
  // data
  async setUserAddresses() { 
    // check for userAddressesData
    if (this.userAddressesData && this.userAddressesData.length > 0) {
      // clear userAddresses
      this.userAddresses = [];
      // loop userAddressesData
      this.userAddressesData.map(async (userAddress: any) => {
        // check for mailingAddress
        if (userAddress.mailingAddress) {
          // set mainUserAddress
          this.mailingUserAddressSelected = true;
          this.mailingUserAddressId = userAddress.id;
        }
        // get location data
        const cityId = userAddress.cityId;
        const cityAux: any = await this.apiSv.getCity(cityId);
        const city: CityInterface = cityAux.data;
        const cityName = city.name;
        const countryId = city.countryId;
        const countryName = city.countryName;
        const stateId = city.stateId;
        const stateName = city.stateName;
        userAddress.cityName = cityName;
        userAddress.stateId = stateId;
        userAddress.stateName = stateName;
        userAddress.countryId = countryId;
        userAddress.countryName = countryName;
        // push userAddress
        this.userAddresses.push(userAddress);
      });
    }
    // hide loader
    this.loaderEvent.emit(null);
  }
  // actions
  addAddress(modal: any) {
    this.userAddress = null;
    this.formType = 'add';
    this.modalService.open(modal, { backdrop: 'static' });
  }
  editAddress(modal: any, userAddress: UserAddressInterface) {
    this.userAddress = userAddress;
    this.formType = userAddress.saveType === 'stored' ? 'edit' : 'edit-stored';
    this.modalService.open(modal, { backdrop: 'static' });
  }
  deleteAddress(userAddressId: string) {
    try {
      // get userAddressIndex
      const userAddressIndex = this.userAddresses.findIndex((userAddress: UserAddressInterface) => userAddress.id === userAddressId);
      // get userAddress
      const userAddress = this.userAddresses[userAddressIndex];
      // show alert
      Swal.fire({
        icon: 'error',
        title: this.translate.instant('LOCATION.formLabels.address'),
        text: this.translate.instant('LOCATION.deleteAddress'),
        confirmButtonText: this.translate.instant('HELPERS.yes'),
        showCancelButton: true,
        cancelButtonText: this.translate.instant('HELPERS.no'),
        allowOutsideClick: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          // check userAddress saveType
          if (userAddress.saveType === 'stored') {
            // delete address on API
            await this.apiSv.removeUserAddress(userAddress.id);
          }
          // check array length
          if (this.userAddresses.length === 1) {
            // remove address from array
            this.userAddresses = [];
            // update userAddresses on storage
            localStorage.removeItem('userAddressesData');
            // check array length
          } else {
            // remove address from array
            this.userAddresses.splice(userAddressIndex, 1);
            // update userAddresses on storage
            localStorage.setItem('userAddressesData', JSON.stringify(this.userAddresses));
          }
          // check new array length
          if (this.userAddresses.length === 0) {
            this.mailingUserAddressSelected = false;
            this.mailingUserAddressId = null;
          }
        }
      });
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  cancelAddress() {
    this.userAddress = null;
    this.formType = null;
    this.modalService.dismissAll();
  }
  async saveAddress(address: UserAddressInterface) {
    // init addressId 
    let addressId: string = null;
    // check formType
    if (this.formType === 'add') {
      // set userAddressTempId
      this.userAddressTempId = this.userAddressTempId + 1;
      // set addressId 
      addressId = 'address-' + (this.userAddressTempId + 1).toString();
    } else {
      // set addressId 
      addressId = address.id;
    }
    // check mailingAddress is been seted before
    if (this.mailingUserAddressId != null && address.mailingAddress && this.mailingUserAddressId !== addressId) {
      // show alert
      Swal.fire({
        icon: 'error',
        title: this.translate.instant('LOCATION.mailingAddress'),
        text: this.translate.instant('LOCATION.mailingAddressReplace'),
        confirmButtonText: this.translate.instant('HELPERS.yes'),
        showCancelButton: true,
        cancelButtonText: this.translate.instant('HELPERS.no'),
        allowOutsideClick: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          // get currentAddressIndex
          const currentAddressIndex: any = this.userAddresses.findIndex((address: any) => address.id === this.mailingUserAddressId);
          // unselect mailingUserAddressId
          this.userAddresses[currentAddressIndex].mailingAddress = false;
          // check formType
          if (this.formType === 'add' || this.formType === 'edit-stored') {
            // set userAddress saveType
            address.saveType = 'update-stored';
          } else {
            // set userAddress saveType
            address.saveType = 'update';
          }
          // add addressId
          address.id = addressId;
          // update new mailingUserAddressId
          this.mailingUserAddressId = addressId;
          // set userAddress
          this.setUserAddress(address);
        } else {
          // uncheck current mailingAddress
          address.mailingAddress = false;
          // set userAddress
          this.setUserAddress(address);
        }
      });
    } else {
      // check if saving address is marked as mailingAddress
      if (address.mailingAddress) {
        // update new mailingUserAddressId
        this.mailingUserAddressSelected = true;
        this.mailingUserAddressId = addressId;
      }
      // add addressId
      address.id = addressId;
      // set userAddress
      this.setUserAddress(address);
    }
  }
  async setUserAddress(address: UserAddressInterface) {
    // check formType
    if (this.formType === 'add') {
      // set userAddress saveType
      address.saveType = 'add';
      // push address data
      this.userAddresses.push(address);
    } else if (this.formType === 'edit-stored') {
      // set userAddress saveType
      address.saveType = 'update-stored';
      // get address index
      const addressIndex: any = this.userAddresses.findIndex((addressList: any) => addressList.id === address.id);
      // edit address data
      this.userAddresses[addressIndex] = address;
    } else {
      // set userAddress saveType
      address.saveType = 'update';
      // get address index
      const addressIndex: any = this.userAddresses.findIndex((addressList: any) => addressList.id === address.id);
      // edit address data
      this.userAddresses[addressIndex] = address;
    }
    // update userAddresses on storage
    localStorage.setItem('userAddressesData', JSON.stringify(this.userAddresses));
    // clear data
    this.userAddress = null;
    this.formType = null;
    // check main addresses
    const mainAddress = await this.checkMainAddresses();
    // check mainAddress
    if (!mainAddress) {
      // clear mainAddress
      this.mailingUserAddressSelected = false;
      this.mailingUserAddressId = null
    }
    // close modal
    this.modalService.dismissAll();
  }
  checkMainAddresses(): boolean {
    // init mainUserAddress
    let mainUserAddress = false;
    // loop userAddresses
    for (const userAddressItem of this.userAddresses) {
      // check if userAddress y marked as true
      if (userAddressItem.mailingAddress) {
        mainUserAddress = true;
      }
    }
    return mainUserAddress
  }
  async save() {
    // validate data
    if (this.userAddresses.length === 0) {
      // send alert
      this.alertSv.showMessage(this.translate.instant('HELPERS.requiredFields'),'warning', this.translate.instant('HELPERS.warning'), true);
    } else {
      // call login
      await this.saveUserAddresses();
    }
  }
  async saveUserAddresses() {
    try {
      // get mainAddress
      const mainAddress = this.checkMainAddresses();
      // check mainAddress
      if (!mainAddress) {
        // send alert
        this.alertSv.showMessage(this.translate.instant('LOCATION.mailingAddressMustHaveOne'), 'error', this.translate.instant('LOCATION.mailingAddress'), true);
      } else {
        // init userAddresses
        const userAddresses: any[] = [];
        // show loader
        this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
        // loop addresses
        for (const userAddressItem of this.userAddresses) {
          // check saveType
          if (userAddressItem.saveType === 'add' || userAddressItem.saveType === 'update-stored') {
            // set userAddressData
            const userAddressData: UserAddressInterface = {
              ...((this.origin !== 'b2c') && {userId: this.userData.id}),
              userAddressType: userAddressItem.addressType,
              cityId: userAddressItem.cityId,
              address: userAddressItem.address,
              address2: userAddressItem.address2,
              mailingAddress: userAddressItem.mailingAddress
            }
            // init auxUserAddress
            let auxUserAddress: any = null;
            // init userAddressId
            let userAddressId: string = null;
            // check origin
            if (this.origin === 'b2c') {
              // save address on API
              auxUserAddress = await this.apiSv.addPublicUserAddress(userAddressData);
              userAddressId = auxUserAddress.data;
            } else {
              // save address on API
              auxUserAddress = await this.apiSv.addUserAddress(userAddressData);
              userAddressId = auxUserAddress.data.id;
            }
            // get userAddress
            const userAddress: UserAddressInterface = userAddressItem;
            // update userAddress id
            userAddress.id = userAddressId;
            // update saveType
            userAddress.saveType = 'stored';
            // push userAddresses
            userAddresses.push (userAddress);
          } else if (userAddressItem.saveType === 'update') {
            // set userAddressData
            const userAddressData: UserAddressInterface = {
              userAddressType: userAddressItem.addressType,
              cityId: userAddressItem.cityId,
              address: userAddressItem.address,
              address2: userAddressItem.address2,
              mailingAddress: userAddressItem.mailingAddress
            }
            // check origin
            if (this.origin === 'b2c') {
              // save address on API
              await this.apiSv.updatePublicUserAddress(userAddressItem.id, userAddressData);
            } else {
              // save address on API
              await this.apiSv.updateUserAddress(userAddressItem.id, userAddressData);
            }
            // get userAddress
            const userAddress: UserAddressInterface = userAddressItem;
            // update saveType
            userAddress.saveType = 'stored';
            // push userAddresses
            userAddresses.push (userAddress);
          } else {
            // push userAddresses
            userAddresses.push (userAddressItem);
          }
        }
        // update userAddresses
        this.userAddresses = userAddresses;
        // hide loader
        this.loaderEvent.emit(null);
        // emit data
        this.saveEvent.emit(userAddresses);
      }
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
}
