// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 25/05/2023
// tslint:disable: max-line-length
// tslint:disable: no-unused-expression
// import
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
// translate
import { TranslateService } from '@ngx-translate/core';
// component
@Component({
  selector: 'app-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss']
})
// class
export class NoDataComponent implements OnInit {
  // variables
  @Input() msg: any = null;
  @Output() clearEvent: EventEmitter<any>;
  // constructor
  constructor(
    public translate: TranslateService,
  ) { }
  // life cycle
  async ngOnInit() {
  }
  // actions
  clear() {
    this.clearEvent.emit();
  }
}
