// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 25/05/2023
// tslint:disable: max-line-length
// tslint:disable: no-unused-expression
// import
import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

// pipe
@Pipe({
  name: 'mainUriPipe'
})
// class
export class MainUriPipe implements PipeTransform {
  mainUri = environment.websiteURI;
  transform(value: string): string {

    console.log('uri', this.mainUri + value);

    return this.mainUri + value;
  }
}
