<div class="list-group company-selection">
  <!-- companies -->
  <a class="list-group-item list-group-item-action" (click)="selectCompany(company)" [ngClass]="{'active': company.id === companySelected}" *ngFor="let company of companies">
    <!-- logo -->
    <div class="company-logo">
      <img [src]="company.companyLogo" [alt]="company.businessName" onError="src = 'assets/images/logo/profile-ring.png'"/>
    </div>
    <!-- company name -->
    <div class="company-name">
      <p>{{ company.businessName }}</p>
    </div>
  </a>
</div>