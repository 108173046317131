<!-- form -->
<form class="theme-form" [formGroup]="mainFrm">
  <!-- images -->
  <div class="row">
    <!-- icon -->
    <div class="col-12">
      <div class="logo-container">
        <div class="social-img-wrap">
          <!-- image -->
          <div class="social-img">
            <img [src]="bankLogoSource" [alt]="bankName" onError="src = 'assets/images/broken-image-slim.png'" *ngIf="bankLogoSource !== null">
            <img src="assets/images/broken-image-slim.png" [alt]="bankName" *ngIf="bankLogoSource === null">
          </div>
          <!-- button -->
          <div class="add-icon" (click)="editImage()" *ngIf="!savedData">
            <app-feather-icons class="feather-icon" [icon]="'camera'"></app-feather-icons>
          </div>
          <div class="edit-icon" (click)="editImage()" *ngIf="savedData">
            <app-feather-icons class="feather-icon" [icon]="'edit-3'" ></app-feather-icons>
          </div>
        </div>
      </div>
      <p class="help-messages" *ngIf="toggleHelp">
        <small>{{ 'BANK.formHelp.bankLogo' | translate }}</small>
      </p>
    </div>
  </div>
  <!-- file input -->
  <input type="file" #fileInput style="display: none" (change)="onFileSelected($event, imageCropModal)" />
  <!-- header -->
  <div class="row mt-4">
    <!-- title -->
    <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
      <h4>{{ 'BANK.bank' | translate }}</h4>
      <p>{{ 'BANK.bankInfo' | translate }}</p>
    </div>
    <!-- help -->
    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 pull-right">
      <div class="btn-with-outside-txt-lbl help-btn" (click)="help()">
        <small class="btn-inner-txt">{{ 'HELPERS.help' | translate }}</small> 
        <a class="btn btn-light btn-xs btn-icon-only btn-icon-text-left">
          <app-feather-icons [icon]="'help-circle'"></app-feather-icons>
        </a>
      </div>
    </div>
  </div>
  <!-- bankName -->
  <div class="row">
    <!-- bankName -->
    <div class="col-6">
      <div class="form-group">
        <label for="bankName">{{ 'BANK.formLabels.bankName' | translate }} <span class="required-lbl">*</span></label>
        <input class="form-control" #bankName id="bankName" type="text" inputmode="text" [placeholder]="'BANK.formPlaceHolders.bankName' | translate" formControlName="bankName">
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.bankName">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('bankName').hasError(error.type) && (mainFrm.get('bankName').dirty || mainFrm.get('bankName').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'BANK.formHelp.bankName' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- bankCountries -->
    <div class="col-6">
      <div class="form-group">
        <label for="bankCountries">{{ 'BANK.formLabels.bankCountries' | translate }} <span class="required-lbl">*</span></label>
        <ng-select [items]="allCountries"
          [selectOnTab]="true"
          bindLabel="countryName"
          bindValue="id"
          labelForId="countrySelect"
          [placeholder]="'LOCATION.formPlaceHolders.countrySelect' | translate"
          [multiple]="true"
          (change)="selectCountry()"
          #countrySelect id="countrySelect" name="countrySelect" formControlName="bankCountries">
        </ng-select>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.bankCountries">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('bankCountries').hasError(error.type) && (mainFrm.get('bankCountries').dirty || mainFrm.get('bankCountries').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'BANK.formHelp.bankCountries' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- bankStatus -->
    <div class="col-12">
      <div class="form-check form-switch bankStatus-switch">
        <input #bankStatus id="bankStatus" type="checkbox" role="switch" formControlName="bankStatus" class="form-check-input">
        <label class="form-check-label" for="bankStatus">{{ 'BANK.formHelp.bankStatus' | translate }}</label>
      </div>
    </div>
  </div>
  <!-- buttons -->
  <div class="row mt-4">
    <div class="col">
      <div class="request-form-btns-container">
        <button class="btn btn-light d-block btn-cancel" (click)="cancel()" type="button">
          <span>{{ 'HELPERS.cancel' | translate }}</span>
        </button>
        <button class="btn btn-dark d-block" (click)="save()" type="button">
          <span>{{ 'HELPERS.save' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</form>
<!-- image crop modal -->
<ng-template #imageCropModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Avatar</h4>
    <button type="button" class="close" aria-label="Close" (click)="imageCroppingClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <image-cropper 
      [imageChangedEvent]="imageChangedEvent" 
      [maintainAspectRatio]="true"
      [containWithinAspectRatio]="containWithinAspectRatio" 
      [aspectRatio]="1 / 1" 
      [resizeToWidth]="320"
      [cropperMinWidth]="128" 
      [onlyScaleDown]="true" 
      [roundCropper]="true"
      [canvasRotation]="canvasRotation" 
      [transform]="transform" 
      [alignImage]="'center'"
      format="png" 
      (imageCropped)="imageCropped($event)"
      (loadImageFailed)="imageLoadImageFailed()">
    </image-cropper>
    <div class="btn-showcase">
      <button class="btn btn-xs btn-primary btn-icon-only" (click)="rotateLeft()"><app-feather-icons [icon]="'rotate-ccw'"></app-feather-icons></button>
      <button class="btn btn-xs btn-primary btn-icon-only" (click)="rotateRight()"><app-feather-icons [icon]="'rotate-cw'"></app-feather-icons></button>
      <!-- <button class="btn btn-xs btn-primary btn-icon-only" (click)="flipHorizontal()"><app-feather-icons [icon]="'more-horizontal'"></app-feather-icons></button>
      <button class="btn btn-xs btn-primary btn-icon-only" (click)="flipVertical()"><app-feather-icons [icon]="'more-vertical'"></app-feather-icons></button> -->
      <button class="btn btn-xs btn-primary btn-icon-only" (click)="zoomOut()"><app-feather-icons [icon]="'zoom-out'"></app-feather-icons></button>
      <button class="btn btn-xs btn-primary btn-icon-only" (click)="zoomIn()"><app-feather-icons [icon]="'zoom-in'"></app-feather-icons></button>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-light d-block" (click)="imageCroppingClose()" type="button">
      <span>{{ 'HELPERS.cancel' | translate }}</span>
    </button>
    <button class="btn btn-dark d-block" (click)="imageCroppingFinish()" type="button">
      <span>{{ 'HELPERS.save' | translate }}</span>
    </button>
  </div>
</ng-template>