<div class="card social-profile">
  <div class="card-body">
    <!-- edit -->
    <button class="btn btn-primary btn-xs btn-icon-only edit-form-btn" (click)="edit()" *ngIf="editEnable">
      <app-feather-icons [icon]="'edit-3'"></app-feather-icons>
    </button>
    <!-- data -->
    <div class="row mt-4">
      <!-- header -->
      <div class="col-12" *ngIf="fullData">
        <h4 class="mb-4">{{ 'USERWORKS.userWorkInfo' | translate }}</h4>
      </div>
      <!-- workType -->
      <div [ngClass]="{'col-12': professionName !== null, 'col-6': professionName === null}">
        <div class="card small-widget mb-3">
          <div class="card-body primary user-body-card">
            <span class="widget-title">{{ 'USERWORKS.formLabels.workType' | translate }}</span>
            <div class="user-body-content">
              <h6 class="content-title">
                <span *ngIf="workType === 'employee'">{{ 'USERWORKS.formLabels.workTypeEmployee' | translate }}</span>
                <span *ngIf="workType === 'entrepreneur'">{{ 'USERWORKS.formLabels.workTypeEntrepreneur' | translate }}</span>
                <span *ngIf="workType === 'home'">{{ 'USERWORKS.formLabels.workTypeHome' | translate }}</span>
                <span *ngIf="workType === 'student'">{{ 'USERWORKS.formLabels.workTypeStudent' | translate }}</span>
                <span *ngIf="workType === 'pensioner'">{{ 'USERWORKS.formLabels.workTypePensioner' | translate }}</span>
              </h6>
            </div>
            <div class="bg-gradient">
              <app-feather-icons *ngIf="workType === 'employee'" class="svg-feather" [icon]="'users'"></app-feather-icons>
              <app-feather-icons *ngIf="workType === 'entrepreneur'" class="svg-feather" [icon]="'coffee'"></app-feather-icons>
              <app-feather-icons *ngIf="workType === 'home'" class="svg-feather" [icon]="'home'"></app-feather-icons>
              <app-feather-icons *ngIf="workType === 'student'" class="svg-feather" [icon]="'award'"></app-feather-icons>
              <app-feather-icons *ngIf="workType === 'pensioner'" class="svg-feather" [icon]="'flag'"></app-feather-icons>
            </div>
          </div>
        </div>
      </div>
      <!-- profession -->
      <div class="col-6" *ngIf="professionName !== null">
        <div class="card small-widget mb-3">
          <div class="card-body primary user-body-card">
            <span class="widget-title">{{ 'USERWORKS.formLabels.profession' | translate }}</span>
            <div class="user-body-content">
              <h6 class="content-title">
                {{ professionName }}
              </h6>
            </div>
            <div class="bg-gradient">
              <app-feather-icons class="svg-feather" [icon]="'coffee'"></app-feather-icons>
            </div>
          </div>
        </div>
      </div>
      <!-- position -->
      <div class="col-6" *ngIf="workType === 'employee'">
        <div class="card small-widget mb-3">
          <div class="card-body primary user-body-card">
            <span class="widget-title">{{ 'USERWORKS.formLabels.position' | translate }}</span>
            <div class="user-body-content">
              <h6 class="content-title">
                {{ positionName }}
              </h6>
            </div>
            <div class="bg-gradient">
              <app-feather-icons class="svg-feather" [icon]="'briefcase'"></app-feather-icons>
            </div>
          </div>
        </div>
      </div>
      <!-- occupation -->
      <div class="col-6" *ngIf="workType !== 'employee'">
        <div class="card small-widget mb-3">
          <div class="card-body primary user-body-card">
            <span class="widget-title">{{ 'USERWORKS.formLabels.occupation' | translate }}</span>
            <div class="user-body-content">
              <h6 class="content-title">
                {{ occupationName }}
              </h6>
            </div>
            <div class="bg-gradient">
              <app-feather-icons class="svg-feather" [icon]="'briefcase'"></app-feather-icons>
            </div>
          </div>
        </div>
      </div>
      <!-- contract -->
      <div class="col-12" *ngIf="workType === 'employee'">
        <div class="card small-widget mb-3">
          <div class="card-body primary user-body-card">
            <span class="widget-title">{{ 'USERWORKS.contractInfo' | translate }}</span>
            <div class="user-body-content">
              <p class="content-txt">
                <small>
                  <b>{{ 'USERWORKS.formLabels.contractType' | translate }}:</b>&nbsp;
                  <span *ngIf="contractType === 'fixed'">{{ 'USERWORKS.formLabels.contractTypeFixed' | translate }}</span>
                  <span *ngIf="contractType === 'undefined'">{{ 'USERWORKS.formLabels.contractTypeUndefined' | translate }}</span>
                  <span *ngIf="contractType === 'work'">{{ 'USERWORKS.formLabels.contractTypeWork' | translate }}</span>
                </small>
              </p>
              <p class="content-txt"><small><b>{{ 'LOAN.contractSalary' | translate }}:</b> {{ contractSalary | currency:'$' : 'symbol' : '1.0-0' }}</small></p>
              <p class="content-txt"><small><b>{{ 'USERWORKS.formLabels.contractStart' | translate }}:</b> {{ contractStart | momentPipe: 'date': currentLang: 'DD MMM YYYY' }}</small></p>
              <p class="content-txt" *ngIf="contractEnd !== null"><small><b>{{ 'USERWORKS.formLabels.contractEnd' | translate }}:</b> {{ contractEnd | momentPipe: 'date': currentLang: 'DD MMM YYYY' }}</small></p>
            </div>
            <div class="bg-gradient">
              <app-feather-icons class="svg-feather" [icon]="'bookmark'"></app-feather-icons>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- other data -->
    <div class="row mt-4" *ngIf="fullData">
      <!-- documents -->
      <div class="col-12 mb-4">


        <!-- <app-loan-documents 
          [userId]="userId"
          [loanId]="loanId"
          [target]="'loan'"
          [editEnable]="fileEditEnable"
          [laborFileData]="laborFile"
          [laborFileShow]="true"
          [paymentReceiptFiles]="paymentReceiptFiles"
          [paymentReceiptShow]="true"
          (loaderEvent)="updateLoader($event)"
          (actionEvent)="fileAction($event)">
        </app-loan-documents> -->


        <!-- business documents -->
        <app-loan-documents 
          *ngIf="workType === 'business'"
          [userId]="userId"
          [loanId]="loanId"
          [target]="'loan'"
          [editEnable]="fileEditEnable"
          [rutFileData]="rutFile"
          [rutFileShow]="true"
          [ccioFileData]="ccioFile"
          [ccioFileShow]="true"
          [bankExtractsFileData]="bankExtractsFile"
          [bankExtractsFileShow]="true"
          [pgFileData]="pgFile"
          [pgFileShow]="true"
          (actionEvent)="fileAction($event)"
          (loaderEvent)="updateLoader($event)">
        </app-loan-documents>
        <!-- employee documents -->
        <app-loan-documents 
          *ngIf="workType === 'employee'"
          [userId]="userId"
          [loanId]="loanId"
          [target]="'loan'"
          [editEnable]="fileEditEnable"
          [laborFileData]="laborFile"
          [laborFileShow]="true"
          [paymentReceiptFiles]="paymentReceiptFiles"
          [paymentReceiptShow]="true"
          (actionEvent)="fileAction($event)"
          (loaderEvent)="updateLoader($event)">
        </app-loan-documents>
        <!-- entrepreneur documents -->
        <app-loan-documents 
          *ngIf="workType === 'entrepreneur'"
          [userId]="userId"
          [loanId]="loanId"
          [target]="'loan'"
          [editEnable]="fileEditEnable"
          [rutFileData]="rutFile"
          [rutFileShow]="true"
          [ccioFileData]="ccioFile"
          [ccioFileShow]="true"
          [bankExtractsFileData]="bankExtractsFile"
          [bankExtractsFileShow]="true"
          [pgFileData]="pgFile"
          [pgFileShow]="true"
          [utilitiesFileData]="utilitiesFile"
          [utilitiesFileShow]="true"
          (actionEvent)="fileAction($event)"
          (loaderEvent)="updateLoader($event)">
        </app-loan-documents>
        <!-- home documents -->
        <app-loan-documents 
          *ngIf="workType === 'home'"
          [userId]="userId"
          [loanId]="loanId"
          [target]="'loan'"
          [editEnable]="fileEditEnable"
          [bankExtractsFileData]="bankExtractsFile"
          [bankExtractsFileShow]="true"
          [utilitiesFileData]="utilitiesFile"
          [utilitiesFileShow]="true"
          (actionEvent)="fileAction($event)"
          (loaderEvent)="updateLoader($event)">
        </app-loan-documents>
        <!-- student documents -->
        <app-loan-documents 
          *ngIf="workType === 'student'"
          [userId]="userId"
          [loanId]="loanId"
          [target]="'loan'"
          [editEnable]="fileEditEnable"
          [bankExtractsFileData]="bankExtractsFile"
          [bankExtractsFileShow]="true"
          [utilitiesFileData]="utilitiesFile"
          [utilitiesFileShow]="true"
          (actionEvent)="fileAction($event)"
          (loaderEvent)="updateLoader($event)">
        </app-loan-documents>
        <!-- pensioner documents -->
        <app-loan-documents 
          *ngIf="workType === 'pensioner'"
          [userId]="userId"
          [loanId]="loanId"
          [target]="'loan'"
          [editEnable]="fileEditEnable"
          [pensionerFileData]="pensionerFile"
          [pensionerFileShow]="true"
          (actionEvent)="fileAction($event)"
          (loaderEvent)="updateLoader($event)">
        </app-loan-documents>

      </div>
    </div>
  </div>
</div>
<!-- image modal -->
<ng-template #userImageModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ fileModalTitle }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="closePreview()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <img class="modal-image-preview" [src]="fileModalImage" onError="src = 'assets/images/broken-image-slim.png'">
  </div>
</ng-template>