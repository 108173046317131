// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 25/05/2023
// import
import { Component, EventEmitter, OnInit, OnChanges, Input, Output, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
// translate
import { TranslateService } from '@ngx-translate/core';
// services
import { AuthService } from 'src/app/shared/services/auth.service';
import { EncodingService } from 'src/app/shared/services/encoding.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { LocationService } from 'src/app/shared/services/location.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
// interfaces
import { OccupationInterface } from 'src/app/shared/interfaces/occupation.interface';
// component
@Component({
  selector: 'app-occupation-form',
  templateUrl: './occupation-form.component.html',
  styleUrls: ['./occupation-form.component.scss']
})
// class
export class OccupationFormComponent implements OnInit, OnChanges {
  // variables
  @Input() occupationData: OccupationInterface = null;
  @Output() loaderEvent = new EventEmitter();
  @Output() addEvent = new EventEmitter();
  @Output() updateEvent = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();
  // lang data
  currentLang: string = null;
  // form data
  public mainFrm: FormGroup;
  // messages data
  formErrorMessages: any = {};
  // help data
  toggleHelp = false;
  // saved data
  savedData: boolean = false;
  // occupation data
  occupationId: string = null;
  occupationName: string = null;
  // constructor
  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public authSv: AuthService,
    public encodingSv: EncodingService,
    public apiSv: ApiService,
    public locationSv: LocationService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    private utilitiesSv: UtilitiesService,
  ) {
    // main form
    this.mainFrm = this.formBuilder.group({
      occupationName: [null, [Validators.required]],
      occupationStatus: [null, [Validators.required]],
    });
  }
  // life cycle
  async ngOnInit() {
    await this.translateMsgs();
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.occupationData) {
      // check for occupationData
      if (this.occupationData !== null) {
        this.initData();
      }
    }
  }
  async initData() {
    // show loader
    this.loaderEvent.emit(this.translate.instant('HELPERS.loadingTxt'));
    // get data
    await this.setOccupationData();
    // hide loader
    this.loaderEvent.emit(null);
  }
  // translate
  async translateMsgs() {
    // update form error messages
    this.formErrorMessages = {
      occupationName: [
        { type: 'required', message: this.translate.instant('OCCUPATION.formValidation.occupationName.required') }
      ],
      occupationStatus: [
        { type: 'required', message: this.translate.instant('OCCUPATION.formValidation.occupationStatus.required') }
      ],
    };
  }
  // form
  async help() {
    if (this.toggleHelp) {
      this.toggleHelp = false;
    } else {
      this.toggleHelp = true;
    }
  }
  async setFocus(elementId: any) {
    elementId.focus();
  }
  checkFormField(fieldName: string) {
    // get field
    const field = this.mainFrm.get(fieldName);
    // check field
    const check = field?.invalid && field?.touched;
    // return data
    return check;
  }
  // navigation
  async cancel() {
    // emit data
    this.cancelEvent.emit(); // Return dat
  }
  async setOccupationData() {
    // check occupation data
    if (this.occupationData !== null) {
      // set occupation
      const occupation: OccupationInterface = this.occupationData;
      // set occupation data
      this.occupationId = occupation.id;
      this.occupationName = occupation.occupationName;
      // set form data
      this.mainFrm.controls.occupationName.setValue(occupation.occupationName);
      this.mainFrm.controls.occupationStatus.setValue(occupation.occupationStatus);
      // set savedData
      this.savedData = true;
    }
  }
  // actions
  async save() {
    // markAllAsTouched
    this.mainFrm.markAllAsTouched();
    // validate data
    if (!this.mainFrm.valid) {
      // send alert
      this.alertSv.showMessage(this.translate.instant('HELPERS.requiredFields'),'warning', this.translate.instant('HELPERS.warning'), true);
    } else {
      // check if data is been saved before
      if (!this.savedData) {
        // add data
        await this.addOccupation();
      } else {
        // update data
        await this.updateOccupation();
      }
    }
  }
  async addOccupation() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // get name data
      const occupationName = this.utilitiesSv.capitalizeString(this.mainFrm.controls.occupationName.value);
      // set occupationData
      const occupationData: OccupationInterface = {
        occupationName,
        occupationStatus: this.mainFrm.controls.occupationStatus.value,
      }
      // get auxOccupation
      const auxOccupation: any = await this.apiSv.addOccupation(occupationData);
      // get data
      const data: any = auxOccupation.data;
      // get occupationId
      const occupationId: string = data.id;
      // update occupationId
      this.occupationId = occupationId;
      // set savedData
      this.savedData = true;
      // set saveOccupationData
      const saveOccupationData: OccupationInterface = {
        id: occupationId,
        occupationName: this.mainFrm.controls.occupationName.value,
        occupationStatus: this.mainFrm.controls.occupationStatus.value
      }
      // update occupationData
      this.occupationData = saveOccupationData;
      // hide loader
      this.loaderEvent.emit(null);
      // emit data
      this.addEvent.emit(saveOccupationData);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async updateOccupation() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // get name data
      const occupationName = this.utilitiesSv.capitalizeString(this.mainFrm.controls.occupationName.value);
      // set occupationData
      const occupationData: OccupationInterface = {
        occupationName,
        occupationStatus: this.mainFrm.controls.occupationStatus.value
      }
      // update occupation
      await this.apiSv.updateOccupation(this.occupationData.id, occupationData);
      // update occupationData
      this.occupationData.occupationName = occupationName;
      this.occupationData.occupationStatus = this.mainFrm.controls.occupationStatus.value;
      // hide loader
      this.loaderEvent.emit(null);
      // emit data
      this.updateEvent.emit(this.occupationData);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
}
