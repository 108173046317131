<div class="card">
  <div class="card-header card-no-border mt-4">
    <div class="header-top">
      <div class="row">
        <div class="col-12">
          <h5 class="test-title-css m-0">{{ 'REPORT.loanProducts' | translate }}</h5>
        </div>
      </div>
      <div class="card-header-right-icon">
        <div class="dropdown">
          <button class="btn dropdown-toggle" type="button" (click)="toggleTimeQuery()">{{ 'DATEDATA.timeQueryOptions.' + selectedTimeQuery | translate }}</button>
          <div class="dropdown-menu dropdown-menu-end" [class.show]="showTimeQuery">
            <a class="dropdown-item" (click)="setCustomTimeQuery(timeQueryOption)" *ngFor="let timeQueryOption of timeQueryOptions">{{ 'DATEDATA.timeQueryOptions.' + timeQueryOption | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body pt-0">
    <div class="row m-0 overall-card">
      <div class="col-xl-9 col-md-8 col-sm-12 p-0">
        <div class="chart-right">
          <div class="row">
            <div class="col-xl-12">
              <div class="card-body p-0">
                <div class="current-sale-container">
                  <div id="chart-currently">
                    <apx-chart
                      *ngIf="dataLoaded"
                      [series]="loanProductsChartData.series"
                      [chart]="loanProductsChartData.chart"
                      [stroke]="loanProductsChartData.stroke"
                      [grid]="loanProductsChartData.grid"
                      [plotOptions]="loanProductsChartData.plotOptions"
                      [colors]="loanProductsChartData.colors"
                      [fill]="loanProductsChartData.fill"
                      [labels]="loanProductsChartData.labels"
                      [markers]="loanProductsChartData.markers"
                      [xaxis]="loanProductsChartData.xaxis"
                      [tooltip]="loanProductsChartData.tooltip"
                      [yaxis]="loanProductsChartData.yaxis"
                      [responsive]="loanProductsChartData.responsive">
                    </apx-chart>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-4 col-sm-12 p-0">
        <div class="row g-sm-4 g-2">
          <ng-container *ngFor="let item of loanTotalsData">
            <div class="col-xl-12 col-md-12 col-sm-12">
              <div class="light-card balance-card widget-hover">
                <div class="svg-box">
                  <svg class="svg-fill">
                    <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ item.icon }}"></use>
                  </svg>
                </div>
                <div>
                  <span class="f-light">{{ item.title }}</span>
                  <h6 class="mt-1 mb-0">{{ item.price }}</h6>
                </div>
                <div class="ms-auto text-end growth-diff">
                  <span class="font-{{ item.colorClass }}">{{ item.growth }}</span>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
