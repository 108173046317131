// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 03/06/2023
// import
// import
import { Component, EventEmitter, OnInit, OnChanges, Input, Output, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
// translate
import { TranslateService } from '@ngx-translate/core';
// environment
import { environment } from 'src/environments/environment';
// plugins
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ImageTransform, ImageCroppedEvent } from 'ngx-image-cropper';
// services
import { AuthService } from 'src/app/shared/services/auth.service';
import { EncodingService } from 'src/app/shared/services/encoding.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { LocationService } from 'src/app/shared/services/location.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
import { MediaService } from 'src/app/shared/services/media.service';
// interfaces
import { UserInterface } from 'src/app/shared/interfaces/user.interface';
import { ConfigInterface } from 'src/app/shared/interfaces/config.interface';
// component
@Component({
  selector: 'app-config-form',
  templateUrl: './config-form.component.html',
  styleUrls: ['./config-form.component.scss']
})
// class
export class ConfigFormComponent implements OnInit {
  // variables
  @Input() configData: ConfigInterface = null;
  @Output() loaderEvent = new EventEmitter();
  @Output() addEvent = new EventEmitter();
  @Output() updateEvent = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  // lang data
  currentLang: string = null;
  // editor configuration
  editorConfig: AngularEditorConfig = null;
  // form data
  public mainFrm: FormGroup;
  // messages data
  formErrorMessages: any = {};
  // help data
  toggleHelp = false;
  // saved data
  savedData: boolean = false;
  // roles data
  isSupport: boolean = false;
  isAdmin: boolean = false;
  // logo data
  logoFile: any = null;
  logoSource: any = null;
  logoUpdate: boolean = false;
  // edit image data
  imageChangedEvent: any = null;
  canvasRotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  // current user data
  currentUserData: UserInterface = null;
  // constructor
  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public authSv: AuthService,
    public encodingSv: EncodingService,
    public apiSv: ApiService,
    public locationSv: LocationService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    private utilitiesSv: UtilitiesService,
    private mediaSv: MediaService,
    private modalService: NgbModal,
  ) {
    // get currentLang
    const currentLang = this.translate.getDefaultLang();
    // set currentLang
    this.currentLang = currentLang;
    // main form
    this.mainFrm = this.formBuilder.group({
      companyName: [null, [Validators.required]],
      documentNumber: [null, [Validators.required]]
    });
  }
  // life cycle
  async ngOnInit() {
    await this.translateMsgs();
    this.initData();
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.configData) {
      // check for configData
      if (this.configData !== null) {
        this.initData();
      }
    }
  }
  async initData() {
    // show loader
    this.loaderEvent.emit(this.translate.instant('HELPERS.loadingTxt'));
    // get data
    await this.getCurrentUserData();
    await this.setConfigData();
    // hide loader
    this.loaderEvent.emit(null);
  }
  // translate
  async translateMsgs() {
    // update form error messages
    this.formErrorMessages = {
      companyName: [
        { type: 'required', message: this.translate.instant('CONFIG.formValidation.companyName.required') }
      ],
      documentNumber: [
        { type: 'required', message: this.translate.instant('CONFIG.formValidation.documentNumber.required') }
      ],
      logo: [
        { type: 'required', message: this.translate.instant('CONFIG.formValidation.logo.required') }
      ],
    };
  }
  // form
  async help() {
    if (this.toggleHelp) {
      this.toggleHelp = false;
    } else {
      this.toggleHelp = true;
    }
  }
  async setFocus(elementId: any) {
    elementId.focus();
  }
  checkFormField(fieldName: string) {
    // get field
    const field = this.mainFrm.get(fieldName);
    // check field
    const check = field?.invalid && field?.touched;
    // return data
    return check;
  }
  // navigation
  async cancel() {
    // emit data
    this.cancelEvent.emit(); // Return dat
  }
  // data
  async getCurrentUserData() {
    // get stored data user
    const storedDataUser: any = localStorage.getItem('userData');
    // check user data
    if (storedDataUser != null) {
      // get data user
      const userData: UserInterface = JSON.parse(storedDataUser);
      // update data user
      this.currentUserData = userData;
      // check roles
      const checkSupport = await this.utilitiesSv.checkRoles(userData.role, ['support']);
      this.isSupport = checkSupport;
      const checkAdmin = await this.utilitiesSv.checkRoles(userData.role, ['support', 'administrator']);
      this.isAdmin = checkAdmin;
    }
  }
  async setConfigData() {
    // check config data
    if (this.configData !== null) {
      // set config
      const config: ConfigInterface = this.configData;
      // set logo
      this.logoSource = config.logo;
      // set form data
      this.mainFrm.controls.companyName.setValue(config.companyName);
      this.mainFrm.controls.documentNumber.setValue(config.documentNumber);
    }
  }
  // actions
  async save() {
    // markAllAsTouched
    this.mainFrm.markAllAsTouched();
    // validate data
    if (!this.mainFrm.valid) {
      // send alert
      this.alertSv.showMessage(this.translate.instant('HELPERS.requiredFields'),'warning', this.translate.instant('HELPERS.warning'), true);
    } else {
      // update data
      await this.updateConfig();
    }
  }
  async updateConfig() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // get name data
      const companyName = this.utilitiesSv.capitalizeString(this.mainFrm.controls.companyName.value);
      // set configData
      const configData: ConfigInterface = {
        companyName,
        documentNumber: this.mainFrm.controls.documentNumber.value,
      }
      // update config
      await this.apiSv.updateConfig(this.configData.id, configData);
      // update configData
      this.configData.companyName = companyName;
      this.configData.logo = this.logoSource;
      this.configData.documentNumber = this.mainFrm.controls.documentNumber.value;
      // hide loader
      this.loaderEvent.emit(null);
      // emit data
      this.updateEvent.emit(this.configData);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  // image
  async editImage() {
    // trigger hidden input file
    this.fileInput.nativeElement.click();
  }
  async onFileSelected(event: any, modal: any) {
    const file = event.target.files?.[0];
    if (file) {
      // check fileType
      if (file.type.startsWith('image/')) {
        // get uploaded file data
        const fileSize = file.size;
        // check file size
        if (fileSize >= environment.maxFileData) {
          // hide loader
          this.loaderEvent.emit(null);
          // show loader
          this.alertSv.showMessage(this.translate.instant('HELPERS.fileTooBig'),'error', this.translate.instant('HELPERS.error'), true);
        } else {
          // set image changed event
          this.imageChangedEvent = event;
          // showCropper
          this.showCropper = true;
          // open crop modal
          this.modalService.open(modal);
        }
      } else {
        // hide loader
        this.loaderEvent.emit(null);
        // show loader
        this.alertSv.showMessage(this.translate.instant('HELPERS.fileNoImage'),'error', this.translate.instant('HELPERS.error'), true);
      }
    } else {
      // hide loader
      this.loaderEvent.emit(null);
    }
  }
  async uploadFile(file: any) {
    const imageData = new FormData()
    imageData.append('file', file);
    // send file to api
    const imageFile: any = await this.apiSv.uploadFile(imageData);
    // get file data
    const fileData: any = imageFile.data;
    const filePath = fileData.filePath;
    // save file
    this.updateFile(filePath);
  }
  async updateFile(filePath: string) {
    try {
      // mark logoUpdate
      this.logoUpdate = true;
      // update icon source
      this.logoSource = filePath;
      // hide loader
      this.loaderEvent.emit(null);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  imageCroppingClose() {
    // clear icon
    this.logoSource = null;
    // clear image event
    this.imageChangedEvent = null;
    // hide modal
    this.modalService.dismissAll();
  }
  async imageCroppingFinish() {
    // hide modal
    this.modalService.dismissAll();
    // show loader
    this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
    // init baseFileName
    let baseFileName: string = null;
    // init base 64 data
    let b64Data: string = null;
    // clear icon
    this.logoFile = null;
    // get base 64 data
    b64Data = this.logoSource;
    // set baseFileName
    baseFileName = 'icon.png';
    // get file data
    const configName = this.mainFrm.controls.configName.value;
    const fileName = configName === null ? baseFileName : await this.utilitiesSv.slugify(configName) + '-' + baseFileName;
    var file = this.mediaSv.dataURLtoFile(b64Data, fileName);
    // upload file
    await this.uploadFile(file);
  }
  // cropping media
  imageCropped(event: ImageCroppedEvent) {
    // clear logo
    this.logoSource = event.base64;
  }
  imageLoadImageFailed() {
    // show message
    this.alertSv.showMessage(this.translate.instant('HELPERS.fileLoadImageError'),'error', this.translate.instant('HELPERS.error'), true);
  }
  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }
  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }
  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }
  flipHorizontal() {
    this.transform = {
        ...this.transform,
        flipH: !this.transform.flipH
    };
  }
  flipVertical() {
    this.transform = {
        ...this.transform,
        flipV: !this.transform.flipV
    };
  }
  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }
  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }
}
