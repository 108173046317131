import { environment } from '../../../environments/environment';
const route_api = environment.server_api;
const route_media = route_api + '/uploads/';
const route_files = route_api + '/file';
const route_terms = route_api + '/config/terms';
const route_privacy = route_api + '/config/privacy';
const route_config = route_api + '/config';
const route_notifications = route_api + '/notification';
const route_professions = route_api + '/professions';
const route_occupations = route_api + '/occupations';
const route_positions = route_api + '/positions';
const route_location = route_api + '/locations';
const route_banks = route_api + '/banks'; 
const route_products = route_api + '/products'; 
const route_product_categories = route_api + '/products/product-categories'; 
const route_companies = route_api + '/companies'; 
const route_other_companies = route_api + '/other-companies'; 
const route_company_activities = route_api + '/company-activities'; 
const route_auth = route_api + '/auth'; 
const route_users = route_api + '/users'; 
const route_companies_users = route_api + '/companies-users'; 
const route_loans = route_api + '/loans'; 
const route_loan_statuses = route_api + '/loan-statuses'; 
const route_batches = route_api + '/batches'; 
const route_batch_has_loans = route_api + '/batch-has-loans'; 
const route_batch_status = route_api + '/batch-status'; 
const route_batch_has_loan_payments = route_api + '/batch-has-loan-payments'; 
const route_transactions = route_api + '/transactions';  
const route_companies_products = route_api + '/companies-products'
const route_venues = route_api + '/venues';  
const route_user_attachment= route_api + '/user-attachment'; 
const route_user_address = route_api + '/user-address';
const route_reports = route_api + '/reports';
export {
  route_media,
  route_files,
  route_terms,
  route_privacy,
  route_config,
  route_notifications,
  route_professions,
  route_occupations,
  route_positions,
  route_location,
  route_banks,
  route_products,
  route_product_categories,
  route_companies,
  route_other_companies,
  route_company_activities,
  route_auth,
  route_users,
  route_loans,
  route_loan_statuses,
  route_batches,
  route_transactions,
  route_batch_has_loans,
  route_batch_status,
  route_batch_has_loan_payments,
  route_companies_users,
  route_companies_products,
  route_venues,
  route_user_attachment,
  route_user_address,
  route_reports
};
