// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 03/10/2023
// import
import { Component, Input } from "@angular/core";
// translate
import { TranslateService } from '@ngx-translate/core';
// plugins
import { ApexAnnotations, ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexGrid, ApexLegend, ApexMarkers, ApexNonAxisChartSeries, ApexPlotOptions, ApexResponsive, ApexStroke, ApexTheme, ApexTitleSubtitle, ApexXAxis, ApexYAxis } from "ng-apexcharts";
// services
import { ApiService } from 'src/app/shared/services/api.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
// interfaces
import { CompanyInterface } from "src/app/shared/interfaces/company.interface";
@Component({
  selector: 'app-loan-top-5',
  templateUrl: './loan-top-5.component.html',
  styleUrls: ['./loan-top-5.component.scss']
})
export class LoanTop5Component {
  // variables
  @Input() companyData: CompanyInterface = null;
  // loader
  showLoader: boolean = false;
  loaderMsg: string = null;
  // time query
  public showTimeQuery: boolean = false;
  timeQueryOptions: any[] = ['today', 'week', 'month', 'trimester', 'semester', 'year']
  selectedTimeQuery: string = 'week';
  selectedDateStart: string = null;
  selectedDateEnd: string = null;
  selectedTimeStart: string = null;
  selectedTimeEnd: string = null;
  // loans data
  loanTop5Data: any = null;
  dataLoaded: boolean = false;
  // constructor
  constructor(
    public translate: TranslateService,
    public apiSv: ApiService,
    private utilitiesSv: UtilitiesService,
  ) {}
  // life cycle
  async ngOnInit() {
    await this.setTimeQuery('week');
    // await this.getLoanTop5Data();
  }
  // navigation
  async goToPage(page: string, params?: any | null){
    // navigate to page
    this.utilitiesSv.goTo(page, true, params);
  }
  // actions
  toggleTimeQuery() {
    this.showTimeQuery = !this.showTimeQuery;
  }
  async setTimeQuery(timeQueryOption: string) {
    // update selectedTimeQuery
    this.selectedTimeQuery = timeQueryOption;
    // get selected time
    const selectedTime = this.utilitiesSv.getTimeQuery(timeQueryOption);
    // update times
    this.selectedDateStart = selectedTime.dateStart;
    this.selectedDateEnd = selectedTime.dateEnd;
    this.selectedTimeStart = selectedTime.timeStart;
    this.selectedTimeEnd = selectedTime.timeEnd;
    await this.getLoanTop5Data();
    // toggle time query
    this.toggleTimeQuery();
  }
  openLoan(loanNumber: string) {
    // navigate to page
    this.goToPage('loans/detail/' + loanNumber);
  }
  openUser(userId: string) {
    // navigate to page
    this.goToPage('users/detail/' + userId);
  }
  // data
  async getLoanTop5Data() {
    return new Promise(async (resolve, reject) => {
      try {
        // show loader
        this.showLoader = true;
        this.loaderMsg = this.translate.instant('HELPERS.loadingTxt') + ' ' + this.translate.instant('REPORT.loanApprovedVsRequested');
        // get loanTop5Data
        const loanTop5Data = {
          companyId: this.companyData !== null ? this.companyData.id : null,
          timeQueryOption: this.selectedTimeQuery,
          selectedDateStart: this.selectedDateStart,
          selectedDateEnd: this.selectedDateEnd,
          selectedTimeStart: this.selectedTimeStart,
          selectedTimeEnd: this.selectedTimeEnd,
        }
        // get data from api
        await this.apiSv.getLoanTop5(loanTop5Data).then(async (loanTop5Response: any)=>{

          console.log('loanTop5Response', loanTop5Response);

          // check data
          if (loanTop5Response.data) {
            // get loanTop5Data
            const loanTop5Data: any = loanTop5Response.data;
            // init top5s
            const top5s: any[] = [];
            // loop loanTop5Data
            for (let index = 0; index < loanTop5Data.length; index++) {
              // get loan
              const loan = loanTop5Data[index];
              // get loanTop5
              const loanTop5 = {
                profile: loan.user.avatar,
                name: loan.user.firstName + ' ' + loan.user.lastName,
                to: loan.loanNumber,
                count: loan.approvedAmount,
                count2: loan.amount,
                className: loan.approvedAmount <  loan.amount ? 'danger' : 'success'
              }
              // push top5s
              top5s.push(loanTop5);
            }
            // update loanTop5Data
            this.loanTop5Data = top5s;
            // update dataLoaded
            this.dataLoaded = true;
          }
          // resolve
          resolve(true);
        }, error=>{
          console.log('error', error);
          reject(error);
        });
      } catch (error) {
        console.log('error', error);
        // hide loader
        this.showLoader = false;
        this.loaderMsg = null;
        reject(error);
      }
    });
  }
}
