import { Routes } from '@angular/router';
import { AdminGuard } from '../guard/admin.guard';
import { ContentComponent } from '../components/layout/content/content.component';

export const base: Routes = [
  {
    path: 'error-page',
    loadChildren: () => import('../../pages/error/error.module').then(m => m.ErrorModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('../../pages/authentication/authentication.module').then(m => m.AuthenticationModule),
  },
  {
    path: 'mglnk',
    loadChildren: () => import('../../pages/magic-link/magic-link.module').then(m => m.MagicLinkModule),
  },
  {
    path: 'documentos',
    loadChildren: () => import('../../pages/documents/documents.module').then(m => m.DocumentsModule),
  },
  {
    path: 'coming-soon',
    loadChildren: () => import('../../pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule),
  },
  {
    path: 'legal',
    loadChildren: () => import('../../pages/legal/legal.module').then(m => m.LegalModule),
  },
  {
    path: 'maintenance',
    loadChildren: () => import('../../pages/maintenance/maintenance.module').then(m => m.MaintenanceModule),
  },
  {
    path: 'solicitudes',
    loadChildren: () => import('../../pages/solicitudes/solicitudes.module').then(m => m.SolicitudesModule),
  }
];
