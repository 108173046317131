<!-- header -->
<div class="row">
  <!-- title -->
  <div class="col-lg-8 col-md-7 col-sm-6 col-xs-12">
    <h4 class="text-left">{{ 'LOCATION.addresses' | translate }}</h4>
    <p class="text-left" *ngIf="origin !== 'dashboard'">{{ 'LOCATION.userAddressesInstructions' | translate }}</p>
  </div>
  <!-- add -->
  <div class="col-lg-4 col-md-5 col-sm-6 col-xs-12" *ngIf="editEnable">
    <div class="btn-with-outside-txt-lbl" (click)="addAddress(addressModal)">
      <small class="btn-inner-txt">{{ 'HELPERS.add' | translate }} <span class="btn-inner-subtxt">{{ 'LOCATION.formLabels.address' | translate }}</span></small> 
      <a class="btn btn-primary btn-xs btn-icon-only btn-icon-text-left btn-add-address">
        <app-feather-icons [icon]="'map-pin'"></app-feather-icons>
      </a>
    </div>
  </div>
</div>
<!-- list -->
<div class="row" [ngClass]="{'addresses-list-row': origin === 'dashboard'}">
  <div class="col-12">
    <app-user-addresses-list [userAddressesData]="userAddresses" [editEnable]="editEnable" (editEvent)="editAddress(addressModal, $event)" (deleteEvent)="deleteAddress($event)"></app-user-addresses-list>
  </div>
</div>
<!-- buttons -->
<div class="row mt-4" *ngIf="origin !== 'dashboard'">
  <div class="col">
    <div class="request-form-btns-container">
      <button class="btn btn-light d-block btn-cancel" (click)="goBack()" type="button">
        <span>{{ 'HELPERS.back' | translate }}</span>
      </button>
      <button class="btn btn-primary d-block" (click)="addAddress(addressModal)" type="button" *ngIf="userAddresses.length === 0">
        <span>{{ 'HELPERS.add' | translate }}&nbsp;{{ 'LOCATION.formLabels.address' | translate }}</span>
      </button>
      <button class="btn btn-dark d-block" (click)="save()" type="button" *ngIf="userAddresses.length > 0">
        <span>{{ 'HELPERS.next' | translate }}</span>
      </button>
    </div>
  </div>
</div>
<!-- modal -->
<ng-template #addressModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'LOCATION.formLabels.address' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-user-address-form 
      [formType]="formType" 
      [mailingUserAddressSelected]="mailingUserAddressSelected" 
      [userAddress]="userAddress" 
      (saveEvent)="saveAddress($event)" 
      (cancelEvent)="cancelAddress()">
    </app-user-address-form>
  </div>
</ng-template>
          