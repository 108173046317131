// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 03/06/2023
// import
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
// translate
import { TranslateService } from '@ngx-translate/core';
// services
import { AuthService } from 'src/app/shared/services/auth.service';
import { EncodingService } from 'src/app/shared/services/encoding.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
// interfaces
import { UserInterface } from 'src/app/shared/interfaces/user.interface';
import { CompanyInterface } from 'src/app/shared/interfaces/company.interface';
// component
@Component({
  selector: 'app-user-referral-card',
  templateUrl: './user-referral-card.component.html',
  styleUrls: ['./user-referral-card.component.scss']
})
// class
export class UserReferralCardComponent implements OnInit {
  // variables
  @Input() formAction: string = null;
  @Output() continueEvent = new EventEmitter();
  // form data
  public mainFrm: FormGroup;
  // messages data
  formErrorMessages: any = {};
  // help data
  toggleHelp = false;
  // token data
  token: string = null;
  // user data
  userData: UserInterface = null;
  // user data
  companyData: CompanyInterface = null;
  // constructor
  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public authSv: AuthService,
    public encodingSv: EncodingService,
    public apiSv: ApiService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    private utilitiesSv: UtilitiesService,
  ) {
    // main form
    this.mainFrm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required],
    });
  }
  // life cycle
  async ngOnInit() {
    await this.translateMsgs();
  }
  // translate
  async translateMsgs () {
    this.formErrorMessages = {
      email: [
        { type: 'required', message: this.translate.instant('USER.formValidation.emailLogin.required') },
        { type: 'email', message: this.translate.instant('USER.formValidation.emailLogin.email') },
      ],
      password: [
        { type: 'required', message: this.translate.instant('USER.formValidation.password.required') },
      ],
    };
  }
  // data
  async getToken() {
    // get stored data user
    const storedToken: any = localStorage.getItem('token');
    // get data user
    const token: string = storedToken;
    // check user data
    if (token) {
      this.token = token;
    }
  }
  async getUserData() {
    // get stored data user
    const storedDataUser: any = localStorage.getItem('userData');
    // get data user
    const userData: UserInterface = JSON.parse(storedDataUser);
    // check user data
    if (userData) {
      this.userData = userData;
    }
  }
  async getCompanyData() {
    // get stored data company
    const storedDataCompany: any = localStorage.getItem('companyData');
    // get data company
    const companyData: UserInterface = JSON.parse(storedDataCompany);
    // check company data
    if (companyData) {
      this.companyData = companyData;
    }
  }
  // form
  async help () {
    if (this.toggleHelp) {
      this.toggleHelp = false;
    } else {
      this.toggleHelp = true;
    }
  }
  async setFocus(elementId: any) {
    elementId.focus();
  }
  checkFormField(fieldName: string) {
    // get field
    const field = this.mainFrm.get(fieldName);
    // check field
    const check = field?.invalid && field?.touched;
    // return data
    return check;
  }
  // navigation
  async goToPage(page: string, params?: any | null){
    // navigate to page
    this.utilitiesSv.goTo(page, true, params);
  }
  // actions
  async save() {
    // validate data
    if (!this.mainFrm.valid) {
      // send alert
      this.alertSv.showMessage(this.translate.instant('HELPERS.requiredFields'),'warning', this.translate.instant('HELPERS.warning'), true);
    } else {
      
      // call login
      await this.login();
    }
  }
  async login() {
    // set data
    const loginData: any = {
      email: this.mainFrm.controls.email.value,
      password: this.mainFrm.controls.password.value
    };
    // log in
    const loginAction: any = await this.authSv.login(loginData);
    // check loginAction
    switch (loginAction) {
      case 'logout':
        this.authSv.logout();
        break;
      case 'confirm':
        // get user data
        await this.getUserData();
        // get company data
        await this.getCompanyData();
        // check form action
        if (this.formAction == 'continue') {
          // set data
          const data: any = {
            actionAfter: loginAction,
            token: this.token,
            userData: this.userData,
            ...((this.companyData) && {companyData: this.companyData})
          }
          // emit data
          this.continueEvent.emit(data);
        } else {
          this.goToPage('auth/confirmation/' + this.userData.email + '/register');
        }
        break;
      case 'login':
        // get user data
        await this.getUserData();
        // get company data
        await this.getCompanyData();
        // check form action
        if (this.formAction == 'continue') {
          // set data
          const data: any = {
            actionAfter: loginAction,
            userData: this.userData,
            ...((this.companyData) && {companyData: this.companyData})
          }
          // emit data
          this.continueEvent.emit(data);
        } else {
          // go home
          this.goToPage('/');
        }
        break;
    }
  }
}
