<!-- form -->
<form class="theme-form" [formGroup]="mainFrm">
  <!-- help btn -->
  <div class="btn-with-outside-txt-lbl help-btn" (click)="help()">
    <a class="btn btn-light btn-xs btn-icon-only btn-icon-text-left">
      <app-feather-icons [icon]="'help-circle'"></app-feather-icons>
    </a>
  </div>
  <!-- range instructions -->
  <div class="row range-slider-container">
    <div class="col-lg-10 col-md-11 col-sm-11 range-slider-msg">
      <p class="range-slider-lbl text-left">
        <span class="lbl-regular">{{ 'HELPERS.arrowsRange' | translate }}</span>
        <span class="lbl-small">{{ 'HELPERS.arrowsRangeSmall' | translate }}</span>
      </p>
    </div>
    <div class="col-lg-2 col-md-1 col-sm-1 range-slider-arrows">
      <div class="row">
        <div class="col-6 text-right p-0">
          <app-feather-icons [icon]="'arrow-left-circle'"></app-feather-icons>
        </div>
        <div class="col-6 text-left p-0">
          <app-feather-icons [icon]="'arrow-right-circle'"></app-feather-icons>
        </div>
      </div>
    </div>
  </div>
  <!-- amount -->
  <div class="row amount-container">
    <!-- value -->
    <div class="col-lg-4 col-md-5 col-sm-5 col-xs-12 amount-value-col">
      <label class="amount-lbl mt-3" for="amount">{{ 'LOAN.requestAmount' | translate }} <span class="required-lbl">*</span></label>
      <div class="percentage-resume">
        <span class="current-value amount-value">{{ amount | currency:'$' : 'symbol' : '1.0-0' }}</span>
      </div>
    </div>
    <!-- range -->
    <div class="col-lg-8 col-md-7 col-sm-7 col-xs-12 amount-range-col">
      <div class="form-group range-field">
        <input id="amount" formControlName="amount" type="range" step="50000" [min]="amountMin" [max]="amountMax">
      </div>
    </div>
    <!-- msgs -->
    <div class="col-12 amount-msgs-col">
      <ng-container class="error-messages error-range" *ngFor="let error of formErrorMessages.amount">
        <small class="text-danger" *ngIf="mainFrm.get('amount').hasError(error.type) && (mainFrm.get('amount').dirty || mainFrm.get('amount').touched)">
          {{ error.message }}.&nbsp;
        </small>
      </ng-container>
      <p class="help-messages helper-range" *ngIf="toggleHelp">
        <small>{{ 'LOAN.requestAmountHelp' | translate }}</small>
      </p>
    </div>
  </div>
  <!-- months -->
  <div class="row mt-2">
    <!-- value -->
    <div class="col-lg-3 col-md-4 col-sm-5 col-xs-12 months-value-col">
      <label class="monts-lbl" for="months">{{ 'LOAN.requestMonths' | translate }} <span class="required-lbl">*</span></label>
      <div class="percentage-resume">
        <span class="current-value">{{ mainFrm.controls.months.value }}</span>
      </div>
    </div>
    <!-- range -->
    <div class="col-lg-9 col-md-8 col-sm-7 col-xs-12 months-range-col">
      <div class="form-group range-field">
        <input id="months" formControlName="months" type="range" [step]="monthSteps" [min]="monthMin" [max]="monthMax">
      </div>
    </div>
    <!-- msgs -->
    <div class="col-12 months-msgs-col">
      <ng-container class="error-messages error-range" *ngFor="let error of formErrorMessages.months">
        <small class="text-danger" *ngIf="mainFrm.get('months').hasError(error.type) && (mainFrm.get('months').dirty || mainFrm.get('months').touched)">
          {{ error.message }}.&nbsp;
        </small>
      </ng-container>
      <p class="help-messages helper-range" *ngIf="toggleHelp">
        <small>{{ 'LOAN.requestMonthsHelp' | translate }}</small>
      </p>
    </div>
  </div>
  <!-- salary / probability / save -->
  <div class="row">
    <!-- salary -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group mt-2">
        <label for="salary">
          <span *ngIf="workActivity !== 'business'">{{ 'LOAN.salary' | translate }}&nbsp;</span>
          <span *ngIf="workActivity === 'business'">{{ 'LOAN.companyIncome' | translate }}&nbsp;</span>
          <span class="required-lbl">*</span></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input class="form-control" #salary id="salary" type="text" inputmode="numeric" [min]="salaryMin" [max]="salaryMax" formControlName="salary" placeholder="0">
        </div>
      </div>
    </div>
    <!-- single payment -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="single-payment">
        <h4 class="request-title mt-2 mb-0">{{ 'LOAN.loanSinglePaymentMonth' | translate }}</h4>
        <div class="percentage-resume">
          <span class="current-value amount-value">{{ singlePayment | currency:'$' : 'symbol' : '1.0-0' }}</span>
        </div>
      </div>
    </div>
    <!-- probability -->
    <div class="col-12">
      <div id="progress-container">
        <div [ngClass]="getProgressBarClass()">
          <span id="progress-label">{{ getProgressLabel() }}</span>
        </div>
      </div>
    </div>
    <!-- salary msgs -->
    <div class="col-12 salary-msgs">
      <ng-container class="error-messages" *ngFor="let error of formErrorMessages.salary">
        <small class="text-danger error-msg" *ngIf="mainFrm.get('salary').hasError(error.type) && (mainFrm.get('salary').dirty || mainFrm.get('salary').touched)">
          {{ error.message }}.&nbsp;
        </small>
      </ng-container>
      <p class="help-messages" *ngIf="toggleHelp">
        <small>{{ 'LOAN.contractSalaryHelp' | translate }}</small>
      </p>
    </div>
    <!-- save -->
    <div class="col-12">
      <div class="request-form-btns-container">
        <button class="btn btn-primary d-block" (click)="save()" type="button">
          <span>{{ 'LOAN.requestNow' | translate }}</span>
        </button>
      </div>
    </div>
    <!-- note -->
    <div class="col-12">
      <p class="help-messages">
        <small>
          <span class="terms-txt">
            {{ 'LOAN.acceptance0Continue' | translate }} <a routerLink="/legal/terminos-y-condiciones" target="_blank" rel="norel noopener noreferrer">{{ 'LOAN.terms' | translate }}</a> {{ 'LOAN.acceptance2' | translate }} <a routerLink="/legal/politica-de-privacidad" target="_blank"  rel="norel noopener noreferrer">{{ 'LOAN.privacy' | translate }}</a> {{ 'LOAN.acceptance3' | translate }}
          </span>
        </small>
      </p>
    </div>
  </div>
</form>
          