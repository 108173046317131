<!-- data -->
<div class="card">
  <div class="card-header card-no-border">
    <div class="header-top">
      <h5 class="m-0">{{ 'REPORT.loanTop5Values' | translate }}</h5>
      <div class="dropdown" *ngIf="false">
        <button class="btn dropdown-toggle" type="button" (click)="toggleTimeQuery()">{{ 'DATEDATA.timeQueryOptions.' + selectedTimeQuery | translate }}</button>
        <div class="dropdown-menu dropdown-menu-end" [class.show]="showTimeQuery">
          <a class="dropdown-item" (click)="setTimeQuery(timeQueryOption)" *ngFor="let timeQueryOption of timeQueryOptions">{{ 'DATEDATA.timeQueryOptions.' + timeQueryOption | translate }}</a>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body pt-0 activity-card">
    <!-- data -->
    <div class="appointment-table customer-table table-responsive" *ngIf="dataLoaded">
      <table class="table table-bordernone">
        <tbody>
          <tr *ngFor="let item of loanTop5Data">
            <td>
              <img class="img-fluid img-40 rounded-circle me-2" src="{{item.profile}}" alt="user"  onError="src = 'assets/images/broken-avatar.png'"/>
            </td>
            <td class="img-content-box">
              <a class="user-link" (click)="openUser(item.user.id)">{{item.name}}</a><br>
              <a class="loan-number-link" (click)="openLoan(item.loanNumber)">{{item.to}}</a>
            </td>
            <td class="text-end">
              <span class="font-{{item.className}}">{{item.count | currency:'$' : 'symbol' : '1.0-0' }}</span>
              <span class="d-block f-light">{{item.count2 | currency:'$' : 'symbol' : '1.0-0' }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- no data -->
    <div class="no-data-container inner-container" *ngIf="!dataLoaded">
      <app-no-data [msg]="'REPORT.dataEmpty' | translate"></app-no-data>
    </div>
  </div>
</div>
