import { environment } from '../../../environments/environment';
import {
  ActionReducerMap,
  MetaReducer
} from '@ngrx/store';
import { loaderReducer } from './loader';
import { profileReducer } from './update-profile';
import { companyReducer } from './update-company';
import { loansCountReducer } from './update-loans';
import { batchesCountReducer } from './update-batches';
export interface State {}

export const reducers: ActionReducerMap<State> = {
  loaderReducer: loaderReducer,
  profileReducer: profileReducer,
  companyReducer: companyReducer,
  loansCountReducer: loansCountReducer,
  batchesCountReducer: batchesCountReducer,
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
