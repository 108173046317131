import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';

// translate
import { TranslateService } from '@ngx-translate/core';
// Menu
export interface Menu {
	headTitle1?: string,
	headTitle2?: string,
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeId?: string;
	badgeType?: string;
	badgeValue?: string;
	badgesCount?: boolean;
	active?: boolean;
	bookmark?: boolean;
	roles?: any;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnDestroy {

	private unsubscriber: Subject<any> = new Subject();
	public  screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

	// Search Box
	public search: boolean = false;

	// Language
	public language: boolean = false;
	
	// Mega Menu
	public megaMenu: boolean = false;
	public levelMenu: boolean = false;
	public megaMenuCollapse: boolean = window.innerWidth < 1199 ? true : false;

	// Collapse Sidebar
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

	// For Horizontal Layout Mobile
	public horizontal: boolean = window.innerWidth < 991 ? false : true;

	// Full screen
	public fullScreen: boolean = false;

	// constructor
	constructor(
		private router: Router,
		public translate: TranslateService,
	) {
		this.setScreenWidth(window.innerWidth);
		fromEvent(window, 'resize').pipe(
			debounceTime(1000),
			takeUntil(this.unsubscriber)
		).subscribe((evt: any) => {
			this.setScreenWidth(evt.target.innerWidth);
			if (evt.target.innerWidth < 991) {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			}
			if(evt.target.innerWidth < 1199) {
				this.megaMenuCollapse = true;
			}
		});
		if(window.innerWidth < 991) { // Detect Route change sidebar close
			this.router.events.subscribe(event => { 
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			});
		}
	}

	ngOnDestroy() {
		// this.unsubscriber.next();
		this.unsubscriber.complete();
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}

	MENUITEMS: Menu[] = [
		{
			path: '/', title: this.translate.instant('MENU.home'), icon: 'home', type: 'link', active: false, roles: ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar', 'client']
		},
		{
			headTitle1: this.translate.instant('MENU.loans'), headTitle2: this.translate.instant('MENU.loansInfo'), roles: ['support', 'administrator', 'board', 'treasure', 'businessAgent']
		},
		{
			path: '/loans/all', title: this.translate.instant('MENU.loansAll'), icon: 'layers', type: 'link', active: false, roles: ['support', 'administrator', 'board', 'businessAgent'], badgesCount: true, badgeId: 'loanAll', badgeValue: '0'
		},
		{
			title: this.translate.instant('MENU.loansInitial'), icon: 'file-plus', type: 'sub', active: false, roles: ['support', 'administrator', 'businessAgent'], badgesCount: true, badgeId: 'loanInitial', badgeValue: '0', children: [
				{ path: '/loans/list/started', title: this.translate.instant('LOAN.loanStatusesStarted'), type: 'link', roles: ['support', 'administrator', 'businessAgent'], badgesCount: true, badgeId: 'loanStarted', badgeValue: '0' },
				{ path: '/loans/list/sended', title: this.translate.instant('LOAN.loanStatusesSended'), type: 'link', roles: ['support', 'administrator', 'businessAgent'], badgesCount: true, badgeId: 'loanSended', badgeValue: '0' },
				{ path: '/loans/list/accepted', title: this.translate.instant('LOAN.loanStatusesAccepted'), type: 'link', roles: ['support', 'administrator', 'businessAgent'], badgesCount: true, badgeId: 'loanAccepted', badgeValue: '0' },
				{ path: '/loans/list/noPassedValidation', title: this.translate.instant('LOAN.loanStatusesNoPassedValidation'), type: 'link', roles: ['support', 'administrator', 'businessAgent'], badgesCount: true, badgeId: 'loanNoPassedValidation', badgeValue: '0' },
				{ path: '/loans/list/validated', title: this.translate.instant('LOAN.loanStatusesValidated'), type: 'link', roles: ['support', 'administrator', 'businessAgent'], badgesCount: true, badgeId: 'loanValidated', badgeValue: '0' },
				{ path: '/loans/list/preApproved', title: this.translate.instant('LOAN.loanStatusesPreApproved'), type: 'link', roles: ['support', 'administrator', 'businessAgent'], badgesCount: true, badgeId: 'loanPreApproved', badgeValue: '0' },
				{ path: '/loans/list/readyToApprove', title: this.translate.instant('LOAN.loanStatusesReadyToApprove'), type: 'link', roles: ['support', 'administrator', 'businessAgent'], badgesCount: true, badgeId: 'loanReadyToApprove', badgeValue: '0' },
				{ path: '/loans/list/approved', title: this.translate.instant('LOAN.loanStatusesApproved'), type: 'link', roles: ['support', 'administrator', 'businessAgent'], badgesCount: true, badgeId: 'loanApproved', badgeValue: '0' },
				{ path: '/loans/list/rejected', title: this.translate.instant('LOAN.loanStatusesRejected'), type: 'link', roles: ['support', 'administrator', 'businessAgent'], badgesCount: true, badgeId: 'loanRejected', badgeValue: '0' },
				{ path: '/loans/list/readyForDisbursement', title: this.translate.instant('LOAN.loanStatusesReadyForDisbursement'), type: 'link', roles: ['support', 'administrator', 'businessAgent'], badgesCount: true, badgeId: 'loanReadyForDisbursement', badgeValue: '0' },
				{ path: '/loans/list/disbursementOnProgress', title: this.translate.instant('LOAN.loanStatusesDisbursementOnProgress'), type: 'link', roles: ['support', 'administrator', 'businessAgent'], badgesCount: true, badgeId: 'loanDisbursementOnProgress', badgeValue: '0' },
			]
		},
		
		{
			path: '/loans/list/validated', title: this.translate.instant('MENU.loansToApprove'), icon: 'check-square', type: 'link', active: false, roles: ['support', 'administrator', 'board'], badgesCount: true, badgeId: 'loanValidated', badgeValue: '0'
		},
		{
			path: '/loans/list/preApproved', title: this.translate.instant('MENU.loansPreApproved'), icon: 'check-circle', type: 'link', active: false, roles: ['support', 'administrator', 'board', 'businessAgent'], badgesCount: true, badgeId: 'loanPreApproved', badgeValue: '0'
		},
		{
			path: '/loans/list/readyToApprove', title: this.translate.instant('MENU.loansCompanyApproval'), icon: 'check', type: 'link', active: false, roles: ['support', 'administrator', 'board', 'businessAgent'], badgesCount: true, badgeId: 'loanReadyToApprove', badgeValue: '0'
		},
		{
			path: '/loans/list/readyForDisbursement', title: this.translate.instant('MENU.loanReadyForDisbursement'), icon: 'arrow-up-right', type: 'link', active: false, roles: ['support', 'administrator', 'board', 'treasure'], badgesCount: true, badgeId: 'loanReadyForDisbursement', badgeValue: '0'
		},
		{
			path: '/loans/list/disbursementOnProgress', title: this.translate.instant('MENU.loansDisbursementOnProgress'), icon: 'arrow-up-right', type: 'link', active: false, roles: ['support', 'administrator', 'board', 'treasure'], badgesCount: true, badgeId: 'loanDisbursementOnProgress', badgeValue: '0'
		},
		{
			path: '/loans/all', title: this.translate.instant('MENU.loansAllLong'), icon: 'layers', type: 'link', active: false, roles: ['support', 'ceo', 'manager', 'auxiliar'], badgesCount: true, badgeId: 'loanAll', badgeValue: '0'
		},
		{
			path: '/loans/list/readyToApprove', title: this.translate.instant('MENU.loansReadyToApproveCompany'), icon: 'check', type: 'link', active: false, roles: ['support', 'ceo', 'manager', 'auxiliar'], badgesCount: true, badgeId: 'loanReadyToApprove', badgeValue: '0'
		},
		{
			path: '/loans/list/rejected', title: this.translate.instant('LOAN.loanStatusesRejected'), icon: 'x', type: 'link', active: false, roles: ['support', 'ceo', 'manager', 'auxiliar'], badgesCount: true, badgeId: 'loanRejected', badgeValue: '0'
		},
		{
			path: '/loans/list/disbursed', title: this.translate.instant('MENU.loansActive'), icon: 'dollar-sign', type: 'link', active: false, roles: ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'], badgesCount: true, badgeId: 'loanDisbursed', badgeValue: '0'
		},
		{
			path: '/loans/list/payed', title: this.translate.instant('MENU.loansFinished'), icon: 'check-square', type: 'link', active: false, roles: ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'], badgesCount: true, badgeId: 'loanPayed', badgeValue: '0'
		},
		// {
		// 	title: this.translate.instant('MENU.loansLate'), icon: 'alert-circle', type: 'sub', active: false, roles: ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'], badgesCount: true, badgeId: 'loanLate', badgeValue: '0', children: [
		// 		{ path: '/loans/list/preLegal', title: this.translate.instant('LOAN.loanStatusesPreLegal'), type: 'link', roles: ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'], badgesCount: true, badgeId: 'loanPreLegal', badgeValue: '0' },
		// 		{ path: '/loans/list/legal', title: this.translate.instant('LOAN.loanStatusesLegal'), type: 'link', roles: ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'], badgesCount: true, badgeId: 'loanLegal', badgeValue: '0' },
		// 		{ path: '/loans/list/negotiation', title: this.translate.instant('LOAN.loanStatusesNegotiation'), type: 'link', roles: ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'], badgesCount: true, badgeId: 'loanNegotiation', badgeValue: '0' },
		// 		{ path: '/loans/list/expired', title: this.translate.instant('LOAN.loanStatusesExpired'), type: 'link', roles: ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar'], badgesCount: true, badgeId: 'loanExpired', badgeValue: '0' },
		// 	]
		// },
		{
			headTitle1: this.translate.instant('MENU.batches'), headTitle2: this.translate.instant('MENU.batchesInfo'), roles: ['support', 'administrator', 'board', 'treasure']
		},
		{
			path: '/batches/list/disbursement', title: this.translate.instant('MENU.batchDisbursements'), icon: 'download', type: 'link', active: false, roles: ['support', 'administrator', 'board', 'treasure'], badgesCount: true, badgeId: 'batchDisbursement', badgeValue: '0'
		},
		{
			path: '/batches/list/payment', title: this.translate.instant('MENU.batchPayments'), icon: 'credit-card', type: 'link', active: false, roles: ['support', 'administrator', 'board', 'ceo', 'manager', 'auxiliar'], badgesCount: true, badgeId: 'batchPayment', badgeValue: '0'
		},
		// {
		// 	path: '/batches/list/conciliations', title: this.translate.instant('MENU.conciliations'), icon: 'thumbs-up', type: 'link', active: false, roles: ['support', 'administrator', 'board', 'treasure', 'ceo', 'manager', 'auxiliar']
		// },
		// {
		// 	path: '/reports/list', title: this.translate.instant('MENU.reports'), icon: 'pie-chart', type: 'link', active: false, roles: ['support', 'administrator', 'board', 'treasure', 'ceo', 'manager', 'auxiliar']
		// },
		{
			headTitle1: this.translate.instant('MENU.platform'), headTitle2: this.translate.instant('MENU.platformInfo'), roles: ['support', 'administrator', 'businessAgent']
		},
		{
			path: '/users/list/employee', title: this.translate.instant('MENU.usersClients'), icon: 'users', type: 'link', active: false, roles: ['businessAgent']
		},
		{
			title: this.translate.instant('MENU.users'), icon: 'users', type: 'sub', active: false, roles: ['support', 'administrator'], children: [
				{ path: '/users/list/all', title: this.translate.instant('MENU.usersAll'), type: 'link', roles: ['support', 'administrator'] },
				{ path: '/users/list/support', title: this.translate.instant('MENU.usersSupport'), type: 'link', roles: ['support'] },
				{ path: '/users/list/administrator', title: this.translate.instant('MENU.usersAdministrators'), type: 'link', roles: ['support', 'administrator'] },
				{ path: '/users/list/board', title: this.translate.instant('MENU.usersBoard'), type: 'link', roles: ['support', 'administrator'] },
				{ path: '/users/list/treasure', title: this.translate.instant('MENU.usersTreasures'), type: 'link', roles: ['support', 'administrator'] },
				{ path: '/users/list/businessAgent', title: this.translate.instant('MENU.usersBusinessAgents'), type: 'link', roles: ['support', 'administrator'] },
				{ path: '/users/list/ceo', title: this.translate.instant('MENU.usersCEOs'), type: 'link', roles: ['support', 'administrator'] },
				{ path: '/users/list/manager', title: this.translate.instant('MENU.usersManagers'), type: 'link', roles: ['support', 'administrator'] },
				{ path: '/users/list/auxiliar', title: this.translate.instant('MENU.usersAuxiliars'), type: 'link', roles: ['support', 'administrator'] },
				{ path: '/users/list/client', title: this.translate.instant('MENU.usersClients'), type: 'link', roles: ['support', 'administrator'] },
			]
		},
		{
			title: this.translate.instant('MENU.companies'), icon: 'briefcase', type: 'sub', active: false, roles: ['support', 'administrator'], children: [
				{ path: '/companies/list', title: this.translate.instant('MENU.covenant'), type: 'link', roles: ['support', 'administrator'] },
				{ path: '/companies/other-companies/list', title: this.translate.instant('MENU.otherCompanies'), type: 'link', roles: ['support', 'administrator'] },
				{ path: '/companies/activities/list', title: this.translate.instant('MENU.companyActivities'), type: 'link', roles: ['support', 'administrator'] },
			]
		},
		{
			title: this.translate.instant('MENU.products'), icon: 'tag', type: 'sub', active: false, roles: ['support', 'administrator'], children: [
				{ path: '/products/list', title: this.translate.instant('MENU.products'), type: 'link', roles: ['support', 'administrator'] },
				{ path: '/products/categories/list', title: this.translate.instant('MENU.categories'), type: 'link', roles: ['support', 'administrator'] },
				// { path: '/products/insurances/list', title: this.translate.instant('MENU.insurances'), type: 'link', roles: ['support', 'administrator'] },
			]
		},
		{
			title: this.translate.instant('MENU.legal'), icon: 'feather', type: 'sub', active: false, roles: ['support', 'administrator'], children: [
				{ path: '/legal/terms', title: this.translate.instant('MENU.terms'), type: 'link', roles: ['support', 'administrator'] },
				{ path: '/legal/privacy', title: this.translate.instant('MENU.privacy'),type: 'link', roles: ['support', 'administrator'] },
			]
		},
		{
			title: this.translate.instant('MENU.general'), icon: 'settings', type: 'sub', active: false, roles: ['support', 'administrator'], children: [
				{ path: '/banks/list', title: this.translate.instant('MENU.banks'), type: 'link', roles: ['support', 'administrator'] },
				{ path: '/professions/list', title: this.translate.instant('MENU.professions'), type: 'link', roles: ['support', 'administrator'] },
				{ path: '/occupations/list', title: this.translate.instant('MENU.occupations'), type: 'link', roles: ['support', 'administrator'] },
				{ path: '/positions/list', title: this.translate.instant('MENU.positions'), type: 'link', roles: ['support', 'administrator'] },
				{ path: '/locations/list', title: this.translate.instant('MENU.location'), type: 'link', roles: ['support'] },
				{ path: '/config', title: this.translate.instant('MENU.general'), type: 'link', roles: ['support', 'administrator'] },
			]
		},
		{
			headTitle1: this.translate.instant('MENU.platform'), headTitle2: this.translate.instant('MENU.platformInfo'), roles: ['support', 'ceo', 'manager']
		},
		{
			path: '/companies/detail/mine', title: this.translate.instant('MENU.myCompany'), icon: 'briefcase', type: 'link', active: false, roles: ['support', 'ceo', 'manager']
		},
		// {
		// 	path: '/users/list/employee', title: this.translate.instant('MENU.MyEmployees'), icon: 'users', type: 'link', active: false, roles: ['support', 'ceo', 'manager']
		// },
		{
			headTitle1: this.translate.instant('MENU.credits'), headTitle2: this.translate.instant('MENU.creditsInfo'), roles: ['support', 'client']
		},
		{
			path: '/loans/mine', title: this.translate.instant('MENU.myLoans'), icon: 'layers', type: 'link', active: false, roles: ['support', 'client']
		},
		{
			path: '/loans/my-payments', title: this.translate.instant('MENU.myPayments'), icon: 'credit-card', type: 'link', active: false, roles: ['support', 'client']
		},
		{
			path: '/banks/mine', title: this.translate.instant('MENU.myBanks'), icon: 'dollar-sign', type: 'link', active: false, roles: ['support', 'client']
		},
		{
			headTitle1: this.translate.instant('MENU.help'), headTitle2: this.translate.instant('MENU.helpDetail'), roles: ['support']
		},
		{
			title: this.translate.instant('MENU.legal'), icon: 'feather', type: 'sub', active: false, roles: ['support'], children: [
				{ path: '/config/terms-and-conditions', title: this.translate.instant('MENU.terms'), type: 'link', roles: ['support'] },
				{ path: '/config/privacy-policy', title: this.translate.instant('MENU.privacy'), type: 'link', roles: ['support'] },
			]
		},
		// {
		// 	path: '/faqs/list', title: this.translate.instant('MENU.faq'), icon: 'help-circle', type: 'link', active: false, roles: ['support']
		// },	
	];

	MEGAMENUITEMS: Menu[] = [
		{
			title: 'Error Pages', type: 'sub', active: true, children: [
				{ path: '/error-page/error-400', title: 'Error Page 400', type: 'link' },
				{ path: '/error-page/error-401', title: 'Error Page 401', type: 'link' },
				{ path: '/error-page/error-403', title: 'Error Page 403', type: 'link' },
				{ path: '/error-page/error-404', title: 'Error Page 404', type: 'link' },
				{ path: '/error-page/error-500', title: 'Error Page 500', type: 'link' },
				{ path: '/error-page/error-503', title: 'Error Page 503', type: 'link' },
			]
		},
		{
			title: 'Authentication', type: 'sub', active: false, children: [
				{ path: '/auth/simple-login', title: 'Login Simple', type: 'link' },
				{ path: '/auth/login-with-background-image', title: 'Login BG Image', type: 'link' },
				{ path: '/auth/login-with-background-video', title: 'Login BG Video', type: 'link' },
				{ path: '/auth/simple-register', title: 'Simple Register', type: 'link' },
				{ path: '/auth/register-with-background-image', title: 'Register BG Image', type: 'link' },
				{ path: '/auth/register-with-background-video', title: 'Register BG Video', type: 'link' }
			]
		},
		{
			title: 'Usefull Pages', type: 'sub', active: false, children: [
				{ path: '/search-pages', title: 'Search Pages', type: 'link' },
				{ path: '/auth/unlock-user', title: 'Unlock User', type: 'link' },
				{ path: '/auth/forgot-password', title: 'Forgot Password', type: 'link' },
				{ path: '/auth/change-password', title: 'Reset Password', type: 'link' },
				{ path: '/maintenance', title: 'Maintenance', type: 'link' }
			]
		},
		{
			title: 'Email templates', type: 'sub', active: false, children: [
				{ path: 'http://admin.pixelstrap.com/cuba/theme/basic-template.html', title: 'Basic Email', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/email-header.html', title: 'Basic With Header', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/template-email.html', title: 'Ecomerce Template', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/template-email-2.html', title: 'Email Template 2', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/ecommerce-templates.html', title: 'Ecommerce Email', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/email-order-success.html', title: 'Order Success', type: 'extTabLink' }
			]
		},
		{
			title: 'Coming Soon', type: 'sub', active: false, children: [
				{ path: '/coming-soon/simple', title: 'Coming Simple', type: 'link' },
				{ path: '/coming-soon/simple-with-bg-img', title: 'Coming BG Image', type: 'link' },
				{ path: '/coming-soon/simple-with-bg-vid', title: 'Coming BG Video', type: 'link' }
			]
		},
	];

	LEVELMENUITEMS: Menu[] = [
		{
			path: '/file-manager', title: 'File Manager', icon: 'git-pull-request', type: 'link'
		},
		// {
		// 	title: 'Users', icon: 'users', type: 'sub', active: false, children: [
		// 		{ path: '/user/team-details', title: 'All Users', icon: 'users', type: 'link' },
		// 		{ path: '/user/profile', title: 'User Profile', icon: 'users', type: 'link' },
		// 		{ path: '/user/edit-profile', title: 'Edit Profile', icon: 'users', type: 'link' },
		// 	]
		// },
		{ path: '/bookmarks', title: 'Bookmarks', icon: 'heart', type: 'link' },
		{ path: '/calender', title: 'Calender', icon: 'calendar', type: 'link' },
		{ path: '/social-app', title: 'Social App', icon: 'zap', type: 'link' }
	];

	roles: ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar', 'client']

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	megaItems = new BehaviorSubject<Menu[]>(this.MEGAMENUITEMS);
	levelmenuitems = new BehaviorSubject<Menu[]>(this.LEVELMENUITEMS);

}
