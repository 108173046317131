<!-- table -->
<div class="table-responsive">
  <table class="table table-hover">
    <!-- table header -->
    <thead>
      <tr>
        <th class="col-2 align-middle" scope="col"><p class="table-header-title">{{ 'PRODUCTCATEGORIES.formLabels.productCategoryIcon' | translate }}</p></th>
        <th class="col-5 align-middle" scope="col"><p class="table-header-title">{{ 'PRODUCTCATEGORIES.productCategory' | translate }}</p></th>
        <th class="col-4 align-middle text-center" scope="col"><p class="table-header-title">{{ 'STATUSES.status' | translate }}</p></th>
        <th class="col-1 align-middle" scope="col"></th>
      </tr>
    </thead>
    <!-- table body -->
    <tbody>
      <tr *ngFor="let productCategory of productCategories">
        <td class="col-2 align-middle table-user" scope="col">
          <div class="media">
            <div class="media-left">
              <img class="media-object rounded-circle" [src]="productCategory.productCategoryIcon" [alt]="productCategory.productCategory" onError="src = 'assets/images/broken-image-slim.png'">
            </div>
          </div>
        </td>
        <td class="col-5 align-middle" scope="col">{{ productCategory.productCategory }}</td>
        <td class="col-4 align-middle text-center">
          <p class="table-status-txt">
            <span class="status-approved" *ngIf="productCategory.productCategoryStatus === true">{{ 'STATUSES.activeFemale' | translate }}</span>
            <span class="status-rejected" *ngIf="productCategory.productCategoryStatus === false">{{ 'STATUSES.unactiveFemale' | translate }}</span>
          </p>
        </td>
        <td class="col-1 align-middle">
          <a class="btn btn-dark btn-xs btn-icon-only" (click)="editProductCategory(productCategory)">
            <app-feather-icons [icon]="'edit-3'"></app-feather-icons>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>