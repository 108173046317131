// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 25/05/2023
// import
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
// reducers
import { Store } from '@ngrx/store';
// plugins
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// services
import { NavService, Menu } from '../../../../services/nav.service';
// translate
import { TranslateService } from '@ngx-translate/core';
// interfaces
import { UpdateDataInterface } from 'src/app/shared/reducers/interfaces';
import { UserInterface } from 'src/app/shared/interfaces/user.interface';
// services
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
// component
@Component({
  selector: 'app-mega-menu',
  templateUrl: './mega-menu.component.html',
  styleUrls: ['./mega-menu.component.scss']
})
export class MegaMenuComponent implements OnInit {
  // variables
  public megaItems: Menu[];
  public levelmenuitems: Menu[];
  // form data
  public mainFrm: FormGroup;
  // time query
  public showTimeQuery: boolean = false;
  timeQueryOptions: any[] = ['today', 'week', 'month', 'trimester', 'semester', 'year']
  selectedTimeQuery: string = 'today';
  selectedDateStart: string = null;
  selectedDateEnd: string = null;
  // messages data
  formErrorMessages: any = {};
  // help data
  toggleHelp = false;
  // lang data
  currentLang: string = null;
  // user data
  userData: UserInterface = null;
  isFinancial: boolean = false;
  reportDownloadEnable: boolean = false;
  // constructor
  constructor(
    public navServices: NavService,
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public apiSv: ApiService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    private utilitiesSv: UtilitiesService,
    private modalService: NgbModal,
    private dataState: Store<UpdateDataInterface>,
  ) { 
    // update data
    this.dataState.subscribe(async(state) =>{
      // profile 
      if (state.profileReducer) {
        await this.getUserData();
      }
    });
    // get currentLang
    const currentLang = this.translate.getDefaultLang();
    // set currentLang
    this.currentLang = currentLang;
    // nav services
    this.navServices.megaItems.subscribe(megaItems => this.megaItems = megaItems);
    this.navServices.levelmenuitems.subscribe(levelmenuitems => this.levelmenuitems = levelmenuitems);
    // readjustPayments form
    this.mainFrm = this.formBuilder.group({
      dateTimeStart: [null, Validators.required],
      dateTimeEnd: [null, Validators.required],
    });
  }
  // life cycle
  async ngOnInit() {
    await this.getUserData();
    // get selected time
    const selectedTime = this.utilitiesSv.getTimeQuery('today');
    // update times
    this.selectedDateStart = selectedTime.dateStart;
    this.selectedDateEnd = selectedTime.dateEnd;
    this.mainFrm.controls.dateTimeStart.setValue(selectedTime.dateStart);
    this.mainFrm.controls.dateTimeEnd.setValue(selectedTime.dateEnd);
  }
  // loader
  async updateLoader(loaderMsg: any) {
    this.utilitiesSv.updateLoader(loaderMsg);
  }
  // translate
  async translateMsgs() {
    // update form error messages
    this.formErrorMessages = {
      dateTimeStart: [
        { type: 'required', message: this.translate.instant('REPORT.dateStartRequired') },
      ],
      dateTimeEnd: [
        { type: 'required', message: this.translate.instant('REPORT.dateEndRequired') },
      ],
    };
  }
  // form
  async clearForms() {
    this.mainFrm.reset();
  }
  async help () {
    if (this.toggleHelp) {
      this.toggleHelp = false;
    } else {
      this.toggleHelp = true;
    }
  }
  async setFocus(elementId: any) {
    elementId.focus();
  }
  // data
  async getUserData() {
    // get stored data user
    const storedDataUser: any = localStorage.getItem('userData');
    // get data user
    const userData: UserInterface = JSON.parse(storedDataUser);
    // check user data
    if (userData) {
      // set data user
      this.userData = userData;
      // check roles
      const checkFinancial = await this.utilitiesSv.checkRoles(userData.role, ['support', 'administrator', 'businessAgent']);
      this.isFinancial = checkFinancial;
      const checkReportDownload = await this.utilitiesSv.checkRoles(userData.role, ['support', 'administrator', 'board', 'treasure', 'businessAgent']);
      this.reportDownloadEnable = checkReportDownload;
    }
  }
  // actions
  megaMenuToggle() {
    this.navServices.levelMenu = false;
    this.navServices.megaMenu  = !this.navServices.megaMenu;
    if(window.innerWidth < 991) { 
      this.navServices.collapseSidebar = true;
    }
  }
  levelMenuToggle() {
    this.navServices.megaMenu  = false;
    this.navServices.levelMenu = !this.navServices.levelMenu;
    if(window.innerWidth < 991) { 
      this.navServices.collapseSidebar = true;
    }
  }
  toggleTimeQuery() {
    this.showTimeQuery = !this.showTimeQuery;
  }
  async setTimeQuery(timeQueryOption: string) {
    // update selectedTimeQuery
    this.selectedTimeQuery = timeQueryOption;
    // get selected time
    const selectedTime = this.utilitiesSv.getTimeQuery(timeQueryOption);

    console.log('selectedTime', selectedTime);
    console.log('this.selectedTimeQuery', this.selectedTimeQuery);

    // update times
    this.selectedDateStart = selectedTime.dateStart;
    this.selectedDateEnd = selectedTime.dateEnd;
    this.mainFrm.controls.dateTimeStart.setValue(selectedTime.dateStart);
    this.mainFrm.controls.dateTimeEnd.setValue(selectedTime.dateEnd);
    // toggle time query
    this.toggleTimeQuery();
  }
  async openFilterDatesExcelReport(modal: any) {
    // open modal
    this.modalService.open(modal);
  }
  async cancelFilterDatesExcelReport() {
    // hide modal
    this.modalService.dismissAll();
  }
  async checkExcelReportDates() {
    // markAllAsTouched
    this.mainFrm.markAllAsTouched();
    // validate data
    if (!this.mainFrm.valid) {
      // send alert
      this.alertSv.showMessage(this.translate.instant('HELPERS.requiredFields'),'warning', this.translate.instant('HELPERS.warning'), true);
    } else {
      // readjustPayment
      await this.downloadExcelReport();
      // hide modal
      this.modalService.dismissAll();
    }
  }
  async downloadExcelReport() {
    return new Promise(async (resolve, reject) => {
      try {
        // show loader
        this.updateLoader(this.translate.instant('HELPERS.generating') + ' ' + this.translate.instant('REPORT.report'));
        // set date data
        const dateStart = moment(this.mainFrm.controls.dateTimeStart.value).format('YYYY-MM-DD');
        const dateEnd = moment(this.mainFrm.controls.dateTimeEnd.value).format('YYYY-MM-DD');
        const timeStart = '00:00'
        const timeEnd = '23:59';
        // set excelReportData
        const excelReportData: any = {
          dateStart,
          timeStart,
          dateEnd,
          timeEnd,
        }
        
        console.log('excelReportData', excelReportData);
        
        // get data from api
        await this.apiSv.getExcelReport(excelReportData).then(async (excelReport: any)=>{

          console.log('excelReport', excelReport);

          // get fileUri
          const fileUri: string = excelReport.data.file;
          this.updateLoader(this.translate.instant('HELPERS.downloading') + ' ' + this.translate.instant('REPORT.report'));
          setTimeout(async () => {
            // hide loader
            this.updateLoader(null);

            console.log('fileUri', fileUri);
            
            // openFile
            await this.openFile(fileUri);
          }, 1000);
          // resolve
          resolve(true);
        }, error=>{
          // hide loader
          this.updateLoader(null);
          console.log('error', error);
          reject(error);
        });
      } catch (error) {
        console.log('error', error);
        // hide loader
        this.updateLoader(null);
        reject(error);
      }
    });
  }
  async openFile(uri: string) {
    console.log('uri', uri);
    // open external link file
    this.utilitiesSv.externalLinkSameTab(uri);
  }
  // toggle menu
  toggleNavActive(item) {
    if (!item.active) {
      this.megaItems.forEach(a => {
        if (this.megaItems.includes(item)) {
          a.active = false;
        }
        if (!a.children) { return false; }
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }
}
