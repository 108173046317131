<!-- form -->
<form class="theme-form" [formGroup]="mainFrm">
  <!-- images -->
  <div class="row">
    <!-- icon -->
    <div class="col-12">
      <div class="logo-container">
        <div class="social-img-wrap">
          <!-- image -->
          <div class="social-img">
            <img [src]="productCategoryIconSource" [alt]="productCategory" onError="src = 'assets/images/broken-image-slim.png'" *ngIf="productCategoryIconSource !== null">
            <img src="assets/images/broken-image-slim.png" [alt]="productCategory" *ngIf="productCategoryIconSource === null">
          </div>
          <!-- button -->
          <div class="add-icon" (click)="editImage()" *ngIf="!savedData">
            <app-feather-icons class="feather-icon" [icon]="'camera'"></app-feather-icons>
          </div>
          <div class="edit-icon" (click)="editImage()" *ngIf="savedData">
            <app-feather-icons class="feather-icon" [icon]="'edit-3'" ></app-feather-icons>
          </div>
        </div>
      </div>
      <ng-container class="error-messages" *ngIf="productCategoryIconSource === null && mainFrm.dirty">
        <small class="text-danger error-msg">
          {{ 'PRODUCTCATEGORIES.formValidation.productCategoryIcon.required' | translate }}
        </small>
      </ng-container>
      <p class="help-messages" *ngIf="toggleHelp">
        <small>{{ 'PRODUCTCATEGORIES.formHelp.productCategoryIcon' | translate }}</small>
      </p>
    </div>
  </div>
  <!-- file input -->
  <input type="file" #fileInput style="display: none" (change)="onFileSelected($event, imageCropModal)" />
  <!-- header -->
  <div class="row mt-4">
    <!-- title -->
    <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
      <h4>{{ 'PRODUCTCATEGORIES.productCategory' | translate }}</h4>
      <p>{{ 'PRODUCTCATEGORIES.productCategoryInfo' | translate }}</p>
    </div>
    <!-- help -->
    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 pull-right">
      <div class="btn-with-outside-txt-lbl help-btn" (click)="help()">
        <small class="btn-inner-txt">{{ 'HELPERS.help' | translate }}</small> 
        <a class="btn btn-light btn-xs btn-icon-only btn-icon-text-left">
          <app-feather-icons [icon]="'help-circle'"></app-feather-icons>
        </a>
      </div>
    </div>
  </div>
  <!-- productCategory -->
  <div class="row">
    <!-- productCategory -->
    <div class="col-12">
      <div class="form-group">
        <label for="productCategory">{{ 'PRODUCTCATEGORIES.formLabels.productCategory' | translate }} <span class="required-lbl">*</span></label>
        <input class="form-control" #productCategory id="productCategory" type="text" inputmode="text" [placeholder]="'PRODUCTCATEGORIES.formPlaceHolders.productCategory' | translate" formControlName="productCategory">
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.productCategory">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('productCategory').hasError(error.type) && (mainFrm.get('productCategory').dirty || mainFrm.get('productCategory').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'PRODUCTCATEGORIES.formHelp.productCategory' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- productCategoryStatus -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-check form-switch productCategoryStatus-switch">
        <input #productCategoryStatus id="productCategoryStatus" type="checkbox" role="switch" formControlName="productCategoryStatus" class="form-check-input">
        <label class="form-check-label" for="productCategoryStatus">{{ 'PRODUCTCATEGORIES.formHelp.productCategoryStatus' | translate }}</label>
      </div>
    </div>
  </div>
  <!-- buttons -->
  <div class="row mt-4">
    <div class="col">
      <div class="request-form-btns-container">
        <button class="btn btn-light d-block btn-cancel" (click)="cancel()" type="button">
          <span>{{ 'HELPERS.cancel' | translate }}</span>
        </button>
        <button class="btn btn-dark d-block" (click)="save()" type="button">
          <span>{{ 'HELPERS.save' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</form>
<!-- image crop modal -->
<ng-template #imageCropModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Avatar</h4>
    <button type="button" class="close" aria-label="Close" (click)="imageCroppingClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <image-cropper 
      [imageChangedEvent]="imageChangedEvent" 
      [maintainAspectRatio]="true"
      [containWithinAspectRatio]="containWithinAspectRatio" 
      [aspectRatio]="1 / 1" 
      [resizeToWidth]="320"
      [cropperMinWidth]="128" 
      [onlyScaleDown]="true" 
      [roundCropper]="true"
      [canvasRotation]="canvasRotation" 
      [transform]="transform" 
      [alignImage]="'center'"
      format="png" 
      (imageCropped)="imageCropped($event)"
      (loadImageFailed)="imageLoadImageFailed()">
    </image-cropper>
    <div class="btn-showcase">
      <button class="btn btn-xs btn-primary btn-icon-only" (click)="rotateLeft()"><app-feather-icons [icon]="'rotate-ccw'"></app-feather-icons></button>
      <button class="btn btn-xs btn-primary btn-icon-only" (click)="rotateRight()"><app-feather-icons [icon]="'rotate-cw'"></app-feather-icons></button>
      <!-- <button class="btn btn-xs btn-primary btn-icon-only" (click)="flipHorizontal()"><app-feather-icons [icon]="'more-horizontal'"></app-feather-icons></button>
      <button class="btn btn-xs btn-primary btn-icon-only" (click)="flipVertical()"><app-feather-icons [icon]="'more-vertical'"></app-feather-icons></button> -->
      <button class="btn btn-xs btn-primary btn-icon-only" (click)="zoomOut()"><app-feather-icons [icon]="'zoom-out'"></app-feather-icons></button>
      <button class="btn btn-xs btn-primary btn-icon-only" (click)="zoomIn()"><app-feather-icons [icon]="'zoom-in'"></app-feather-icons></button>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-light d-block" (click)="imageCroppingClose()" type="button">
      <span>{{ 'HELPERS.cancel' | translate }}</span>
    </button>
    <button class="btn btn-dark d-block" (click)="imageCroppingFinish()" type="button">
      <span>{{ 'HELPERS.save' | translate }}</span>
    </button>
  </div>
</ng-template>