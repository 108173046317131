// author: Andrés Esquivel / Alejandro Bermúdez
// company: Think In
// date: 25/05/2023
// tslint:disable: max-line-length
// tslint:disable: no-unused-expression
// import
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// translate
import { TranslateService } from '@ngx-translate/core';
// plugins
import Swal from 'sweetalert2';
// services
import { AlertService } from './alert.service';
// injectable
@Injectable({
  providedIn: 'root'
})
// class
export class FilterService {
  // constructor
  constructor (
    private router: Router,
    public translate: TranslateService,
    private alertSv: AlertService,
  ) { }
  // translate
  // actions
  handleError(error) {
    try {

      console.log('error', error);

      // get errorMsg
      let errorMsg: string = error.error.data;
      // check error msg
      if (errorMsg === undefined ) {
        errorMsg = null;
      }

      console.log('errorMsg', errorMsg);     
      
      // TODO: error handlers from api

      // check errorMsg
      switch (errorMsg) {
        case 'Expired': {
          // show alert
          Swal.fire({
            icon: 'error',
            title: this.translate.instant('ERRORS.error'),
            text: this.translate.instant('ERRORS.sessionExpired'),
            confirmButtonText: this.translate.instant('HELPERS.accept'),
            allowOutsideClick: true,
          }).then(async (result) => {
            if (result.isConfirmed) {
              // clear storage
              localStorage.clear();
              // reload location
              location.reload();
            }
          });
          break;
        }
        case 'Unauthorized': {
          // show alert
          Swal.fire({
            icon: 'error',
            title: this.translate.instant('ERRORS.error'),
            text: this.translate.instant('ERRORS.user_not_permissions'),
            confirmButtonText: this.translate.instant('HELPERS.accept'),
            allowOutsideClick: true,
          }).then(async (result) => {
            if (result.isConfirmed) {
              // reload location
              // location.reload();
            }
          });
          break;
        }
        case 'Invalid role': {
          // show alert
          Swal.fire({
            icon: 'error',
            title: this.translate.instant('ERRORS.error'),
            text: this.translate.instant('ERRORS.user_not_access'),
            confirmButtonText: this.translate.instant('HELPERS.accept'),
            allowOutsideClick: true,
          }).then(async (result) => {
            if (result.isConfirmed) {
              // reload location
              location.reload();
            }
          });
          break;
        }
        case 'invalid code': {
          // send alert
          this.alertSv.showMessage(this.translate.instant('OTP.otpCorrect'), 'warning', this.translate.instant('OTP.confirmation'), true);
          break;
        }
        case 'user_not_found': {
          // send alert
          this.alertSv.showMessage(this.translate.instant('ERRORS.user_not_found'), 'error', this.translate.instant('HELPERS.error'), true);
          break;
        }
        case 'password_incorrect': {
          // send alert
          this.alertSv.showMessage(this.translate.instant('ERRORS.password_incorrect'), 'error', this.translate.instant('HELPERS.error'), true);
          break;
        }
        case 'api': {
          // send alert
          this.alertSv.showMessage(this.translate.instant('ERRORS.errorMsg'), 'error', this.translate.instant('HELPERS.error'), true);
          break;
        }
        case 'specific': {
          // send alert
          this.alertSv.showMessage(this.translate.instant('ERRORS.errorMsg'), 'error', this.translate.instant('HELPERS.error'), true);
          break;
        }
        case 'general': {
          // send alert
          this.alertSv.showMessage(error, 'error');
          break;
        }
        default: {
          this.alertSv.showMessage(this.translate.instant('ERRORS.errorMsg'), 'error', this.translate.instant('HELPERS.error'), true, this.translate.instant('HELPERS.accept'));
          break;
        }
      }
    } catch (err) {
      this.alertSv.showMessage(
        this.translate.instant('ERRORS.errorMsg'),
        'error',
        this.translate.instant('HELPERS.error'),
        true,
        this.translate.instant('HELPERS.accept')
      );
    }
  }
}
