<!-- form -->
<form class="theme-form" [formGroup]="mainFrm">
  <!-- header -->
  <div class="row">
    <!-- title -->
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
      <h4>{{ 'LOAN.workActivityTitle' | translate }}</h4>
      <p *ngIf="origin === 'b2c'">{{ 'LOAN.workActivityComplete' | translate }}</p>
    </div>
    <!-- help -->
    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 pull-right">
      <div class="btn-with-outside-txt-lbl help-btn" (click)="help()">
        <small class="btn-inner-txt">{{ 'HELPERS.help' | translate }}</small> 
        <a class="btn btn-light btn-xs btn-icon-only btn-icon-text-left">
          <app-feather-icons [icon]="'help-circle'"></app-feather-icons>
        </a>
      </div>
    </div>
  </div>
  <!-- data -->
  <div class="row mt-4">
    <!-- salary -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="!(origin === 'b2c' || origin === 'loan-add')">
      <div class="form-group">
        <label for="salary" class="col-form-label">{{ 'LOAN.salary' | translate }} <span class="required-lbl">*</span></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input class="form-control" #salary id="salary" type="text" inputmode="numeric" [min]="salaryMin" [max]="salaryMax" formControlName="contractSalary" placeholder="0" [ngClass]="{'input-disabled': !editSalary}">
        </div>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.salary">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('salary').hasError(error.type) && (mainFrm.get('salary').dirty || mainFrm.get('salary').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'LOAN.contractSalaryHelp' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- profession -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="mainFrm.controls.profession.value !== null">
      <div class="form-group select-fix">
        <span class="caret-select"></span>
        <label for="professionSelect" class="col-form-label">{{ 'USERWORKS.formLabels.profession' | translate }} <span class="required-lbl">*</span></label>
        <select class="form-control" #professionSelect id="professionSelect" name="professionSelect" formControlName="profession" [title]="'USERWORKS.formLabels.profession' | translate" (change)="selectProfession()">
          <option value="null" selected disabled>{{ 'USERWORKS.formPlaceHolders.profession' | translate }}</option>
          <option [value]="profession.id" *ngFor="let profession of professions">{{ profession.professionName }}</option>
        </select>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.profession">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('profession').hasError(error.type) && (mainFrm.get('profession').dirty || mainFrm.get('profession').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'USERWORKS.formHelp.profession' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- position -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="workActivity === 'employee'">
      <div class="form-group select-fix">
        <span class="caret-select"></span>
        <label for="positionSelect" class="col-form-label">{{ 'USERWORKS.formLabels.position' | translate }} <span class="required-lbl">*</span></label>
        <select class="form-control" #positionSelect id="positionSelect" name="positionSelect" formControlName="position" [title]="'USERWORKS.formLabels.position' | translate" (change)="selectPosition()">
          <option value="null" selected disabled>{{ 'USERWORKS.formPlaceHolders.position' | translate }}</option>
          <option [value]="position.id" *ngFor="let position of positions">{{ position.positionName }}</option>
        </select>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.position">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('position').hasError(error.type) && (mainFrm.get('position').dirty || mainFrm.get('position').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'USERWORKS.formHelp.position' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- contractType -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12"  *ngIf="workActivity === 'employee'">
      <div class="form-group select-fix">
        <span class="caret-select"></span>
        <label for="contractTypeSelect" class="col-form-label">{{ 'USERWORKS.formLabels.contractType' | translate }} <span class="required-lbl">*</span></label>
        <select class="form-control " #contractTypeSelect id="contractTypeSelect" name="contractTypeSelect" formControlName="contractType" [title]="'USERWORKS.formLabels.contractType' | translate">
          <option value="null" selected disabled>{{ 'USERWORKS.formPlaceHolders.contractType' | translate }}</option>
          <option value="fixed">{{ 'USERWORKS.formLabels.contractTypeFixed' | translate }}</option>
          <option value="undefined">{{ 'USERWORKS.formLabels.contractTypeUndefined' | translate }}</option>
          <option value="work">{{ 'USERWORKS.formLabels.contractTypeWork' | translate }}</option>
        </select>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.contractType">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('contractType').hasError(error.type) && (mainFrm.get('contractType').dirty || mainFrm.get('contractType').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'USERWORKS.formHelp.contractType' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- contractStart -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12"  *ngIf="workActivity === 'employee'">
      <div class="form-group">
        <label for="contractStart" class="col-form-label">{{ 'USERWORKS.formLabels.contractStart' | translate }} <span class="required-lbl">*</span></label>
        <input class="form-control digits" #contractStartInput id="contractStartInput" type="date" inputmode="text" formControlName="contractStart" placeholder="dd-mm-yyyy"/>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.contractStart">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('contractStart').hasError(error.type) && (mainFrm.get('contractStart').dirty || mainFrm.get('contractStart').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'USERWORKS.formHelp.contractStart' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- contractEnd -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="workActivity === 'employee' && mainFrm.controls.contractType.value !== 'undefined'">
      <div class="form-group">
        <label for="contractEnd" class="col-form-label">{{ 'USERWORKS.formLabels.contractEnd' | translate }}</label>
        <input class="form-control digits" #contractEndInput id="contractEndInput" type="date" inputmode="text" formControlName="contractEnd" placeholder="dd-mm-yyyy"/>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.contractEnd">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('contractEnd').hasError(error.type) && (mainFrm.get('contractEnd').dirty || mainFrm.get('contractEnd').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'USERWORKS.formHelp.contractEnd' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- occupation -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="workActivity !== 'employee'">
      <div class="form-group select-fix">
        <span class="caret-select"></span>
        <label for="occupationSelect" class="col-form-label">{{ 'USERWORKS.formLabels.occupation' | translate }} <span class="required-lbl">*</span></label>
        <select class="form-control" #occupationSelect id="occupationSelect" name="occupationSelect" formControlName="occupation" [title]="'USERWORKS.formLabels.occupationSelect' | translate" (change)="selectOccupation()">
          <option value="null" selected disabled>{{ 'USERWORKS.formPlaceHolders.occupation' | translate }}</option>
          <option [value]="occupation.id" *ngFor="let occupation of occupations">{{ occupation.occupationName }}</option>
        </select>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.occupation">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('occupation').hasError(error.type) && (mainFrm.get('occupation').dirty || mainFrm.get('occupation').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'USERWORKS.formHelp.occupation' | translate }}</small>
        </p>
      </div>
    </div>
  </div>
  <!-- buttons -->
  <div class="row mt-4">
    <div class="col">
      <div class="request-form-btns-container">
        <button class="btn btn-light d-block btn-cancel" (click)="goBack()" type="button">
          <span *ngIf="(origin === 'b2c' || origin === 'loan-add')">{{ 'HELPERS.back' | translate }}</span>
          <span *ngIf="!(origin === 'b2c' || origin === 'loan-add')">{{ 'HELPERS.cancel' | translate }}</span>
        </button>
        <button class="btn btn-dark d-block" (click)="save()" type="button">
          <span *ngIf="(origin === 'b2c' || origin === 'loan-add')">{{ 'HELPERS.next' | translate }}</span>
          <span *ngIf="!(origin === 'b2c' || origin === 'loan-add')">{{ 'HELPERS.save' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</form>
          