// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 30/05/2023
// import
import { Component, EventEmitter, Input, OnInit, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
// translate
import { TranslateService } from '@ngx-translate/core';
// environment
import { environment } from 'src/environments/environment';
// plugins
import Swal from 'sweetalert2';
// services
import { AuthService } from 'src/app/shared/services/auth.service';
import { EncodingService } from 'src/app/shared/services/encoding.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
// interfaces
import { UserInterface } from 'src/app/shared/interfaces/user.interface';
import { UserBankAccountInterface } from 'src/app/shared/interfaces/user-bank-account.interface';
import { CountryInterface } from 'src/app/shared/interfaces/country.interface';
import { BankInterface } from 'src/app/shared/interfaces/bank.interface';
// component
@Component({
  selector: 'app-user-bank-account-form',
  templateUrl: './user-bank-account-form.component.html',
  styleUrls: ['./user-bank-account-form.component.scss']
})
// class
export class UserBankAccountFormComponent implements OnInit, OnChanges {
  // variables
  @Input() userData: UserInterface = null;
  @Input() userBankAccountData: UserBankAccountInterface = null;
  @Input() banksCounter: number = 0;
  @Input() origin: string = null;
  @Input() formType: string = null;
  @Input() userBankAccountMainSelected: boolean = false;
  @Output() loaderEvent = new EventEmitter();
  @Output() addEvent = new EventEmitter();
  @Output() updateEvent = new EventEmitter();
  @Output() backEvent = new EventEmitter();
  // form data
  public mainFrm: FormGroup;
  // messages data
  formErrorMessages: any = {};
  // help data
  toggleHelp = false;
  // bank data
  banks: any[] = [];
  allBanks: any[] = [];
  otherBanks: any[] = [];
  savedData: boolean = false;
  // country data
  countries: any[] = [];
  allCountries: any[] = [];
  countryName: string = environment.countryName;
  countryFlag: string = environment.countryFlag;
  countryCode: string = environment.countryCode;
  countryCodeId: string = environment.countryCodeId; // d27a2ec8-e942-4044-b00c-382caf3a2673
  bankCountryId: string = null;
  bankCountry: CountryInterface = null;
  // constructor
  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public authSv: AuthService,
    public encodingSv: EncodingService,
    public apiSv: ApiService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    private utilitiesSv: UtilitiesService,
  ) {
    // main form
    this.mainFrm = this.formBuilder.group({
      countryBank: [null],
      bank: [null, [Validators.required]],
      userBankAccountType: [null, [Validators.required]],
      userBankAccountNumber: [null, [Validators.required]],
      userBankAccountActive: [true, [Validators.required]],
    });
  }
  // life cycle
  async ngOnInit() {
    // translate data
    await this.translateMsgs();
    // get data
    await this.getCountries();
    // set default bankCountryId
    this.bankCountryId = this.countryCodeId;
    // get banks
    await this.getBanks();
    // TODO: CHECK IF OTHER BANKS WILL BE A FEATURE OR NOT
    // await this.getOtherBanks();
    // await this.addOtherBankOption();
    // check formType
    if (this.formType === 'add') {
      // set initial userBankAccountActive
      const userBankAccountActive = this.userBankAccountMainSelected ? false : true;
      this.mainFrm.controls.userBankAccountActive.setValue(userBankAccountActive);
    }
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.userData || changes.userBankAccountData) {
      // set default bankCountryId
      this.bankCountryId = this.countryCodeId;
      // get banks
      await this.getBanks();
      // check for userBankAccountData
      if (this.userBankAccountData !== null) {
        // get data
        await this.setUserBankAccountData();
      }
    }
  }
  // translate
  async translateMsgs () {
    this.formErrorMessages = {
      countryBank: [
        { type: 'required', message: this.translate.instant('USERBANKACCOUNTS.formValidation.countryBank.required') },
      ],
      bank: [
        { type: 'required', message: this.translate.instant('USERBANKACCOUNTS.formValidation.bank.required') },
      ],
      userBankAccountType: [
        { type: 'required', message: this.translate.instant('USERBANKACCOUNTS.formValidation.userBankAccountType.required') },
      ],
      userBankAccountNumber: [
        { type: 'required', message: this.translate.instant('USERBANKACCOUNTS.formValidation.userBankAccountNumber.required') },
      ],
    };
  }
  // loader
  async updateLoader(loaderMsg: any) {
    this.loaderEvent.emit(loaderMsg);
  }
  // navigation
  async goBack() {
    // emit data
    this.backEvent.emit(); // Return dat
  }
  // location
  async getCountries() {
    try {
      // get stored data countries
      const storedDataCountries: any = localStorage.getItem('countriesData');
      // set allCountries
      const allCountries: any = [];
      // set countries
      let countries: any;
      // check countries data
      if (!storedDataCountries) {
        // get data from api
        await this.apiSv.getCountries().then((response: any)=>{
          // get countries
          countries = response.data;
          // save countries on storage
          localStorage.setItem('countriesData', JSON.stringify(countries));
          // loop countries
          countries.map((country: CountryInterface) => {
            // push countries
            this.countries.push(country);
            // set countryData
            const countryData = {
              id: country.id,
              countryName: country.countryName
            }
            // push country
            allCountries.push(countryData);
          });
          // sort countries
          const countriesSorted = this.utilitiesSv.sortArrayByKey(allCountries, 'countryName');
          // set countryNullData
          const countryNullData = {
            id: null,
            countryName: this.translate.instant('LOCATION.formPlaceHolders.countrySelect')
          }
          // unshift country
          countriesSorted.unshift(countryNullData);
          // update all countries
          this.allCountries = countriesSorted;
          // set initial country bank
          this.mainFrm.controls.countryBank.setValue(this.countryCodeId);
        }, error=>{
          console.log('error', error);
        });
      } else {
        // get countries
        countries = JSON.parse(storedDataCountries);
        // loop countries
        countries.map((country: CountryInterface) => {
          // push countries
          this.countries.push(country);
          // set countryData
          const countryData = {
            id: country.id,
            countryName: country.countryName
          }
          // push country
          allCountries.push(countryData);
        });
        // sort countries
        const countriesSorted = this.utilitiesSv.sortArrayByKey(allCountries, 'countryName');
        // set countryNullData
        const countryNullData = {
          id: null,
          countryName: this.translate.instant('LOCATION.formPlaceHolders.countrySelect')
        }
        // unshift country
        countriesSorted.unshift(countryNullData);
        // update all countries
        this.allCountries = countriesSorted;
        // set initial country bank
        this.mainFrm.controls.countryBank.setValue(this.countryCodeId);
      }
    } catch (error: any) {
      console.log('error', error);
    }
  }
  async selectCountry() {
    // get data
    const countryId: any = this.mainFrm.controls.nationality.value;
    const countryIndex: any = this.countries.findIndex((country: any) => country.id === countryId); 
    const country: CountryInterface = this.countries[countryIndex];
    // set nationality country
    this.bankCountryId = country.id;
    this.bankCountry = country;
    await this.getBanks();
  }
  // data
  async setUserBankAccountData() {
    // check userBankAccountData
    if (this.userBankAccountData !== null) {
      // set userBankAccount
      const userBankAccount: UserBankAccountInterface = this.userBankAccountData;
      // set form data
      this.mainFrm.controls.bank.setValue(userBankAccount.bankId);
      this.mainFrm.controls.userBankAccountType.setValue(userBankAccount.userBankAccountType);
      this.mainFrm.controls.userBankAccountActive.setValue(userBankAccount.userBankAccountActive);
      this.mainFrm.controls.userBankAccountNumber.setValue(userBankAccount.userBankAccountNumberDecoded);
      // mark saveData as true
      this.savedData = true;
    }
  }
  // bank
  async getBanks() {
    try {
      // set allBanks
      const allBanks: any = [];
      // set banks
      let banks: any;
      // init auxBanks
      let auxBanks: any = null;
      // check origin
      if (this.origin === 'b2c') {
        auxBanks = await this.apiSv.getPublicBanks(this.bankCountryId);
        // get banks
        banks = auxBanks.data;
      } else {
        // set bankData
        const bankData = {
          limit: 100000,
          page: 1,
          bankName: null,
          bankStatus: true,
          countryId: this.bankCountryId,
        }
        auxBanks = await this.apiSv.getBanks(bankData);
        // get banks
        banks = auxBanks.data.result;
      }
      // loop banks
      banks.map((bank: BankInterface) => {
        // push banks
        this.banks.push(bank);
        // set bankData
        const bankData = {
          id: bank.id,
          bankName: bank.bankName
        }
        // push bank
        allBanks.push(bankData);
      });
      // sort banks
      const banksSorted = this.utilitiesSv.sortArrayByKey(allBanks, 'bankName');
      // set bankNullData
      const bankNullData = {
        id: null,
        bankName: this.translate.instant('BANK.formPlaceHolders.bankName')
      }
      // unshift bank
      banksSorted.unshift(bankNullData);
      // update all banks
      this.allBanks = banksSorted;
    } catch (error) {
      console.log('error', error);
    }
  }
  async getOtherBanks() {
    // get stored data banks
    const storedDataBanks: any = localStorage.getItem('otherBanksData');
    // check otherBanksData
    if (storedDataBanks) {
      // set otherBanksData
      const otherBanksData = JSON.parse(storedDataBanks);
      // loop otherBanksData
      otherBanksData.map((bank: BankInterface) => {
        // add other bank
        this.banks.push(bank);
      });
    }
  }
  async addOtherBankOption() {
    // set bankData
    const otherBankData = {
      id: 'new',
      bankName: this.translate.instant('HELPERS.other')
    }
    // add other bank
    this.banks.push(otherBankData);
  }
  async selectBank() {
    // get bank
    const bank = this.mainFrm.controls.bank.value; 
    // check if bank is other
    if (bank === 'new') {
      // show alert to get new bank
      Swal.fire({
        icon: 'warning',
        title: this.translate.instant('BANK.formLabels.bank'),
        text: this.translate.instant('USERBANKACCOUNTS.bankOther'),
        input: 'text',
        inputAttributes: {
          autocapitalize: 'on'
        },
        inputPlaceholder: this.translate.instant('BANK.formPlaceHolders.bankOther'),
        confirmButtonText: this.translate.instant('HELPERS.accept'),
        showCancelButton: true,
        cancelButtonText: this.translate.instant('HELPERS.cancel'),
        customClass: {
          cancelButton: 'btn-swal-cancel'
        },
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          // get bank
          const bank: any = result.value;
          // add bank
          await this.addBank(bank);
        }
      })
    }
  }
  async addBank(bank: string) {
    // set bankData
    const bankData: BankInterface = {
      bankName: bank
    }
    // save address on API
    const auxBank: any = await this.apiSv.addPublicBank(bankData);
    // get bankId
    const bankId = auxBank.data.id;
    // set bankSavingData
    const bankSavingData: BankInterface  = {
      id: bankId,
      bankName: bank
    }
    // update bank
    this.banks.push(bankSavingData);
    // update other banks
    this.otherBanks.push(bankSavingData);
    // save other banks data on storage
    localStorage.setItem('otherBanksData', JSON.stringify(this.otherBanks));
    // select new bank
    this.mainFrm.controls.bank.setValue(bankId);
  }
  // form
  async help () {
    if (this.toggleHelp) {
      this.toggleHelp = false;
    } else {
      this.toggleHelp = true;
    }
  }
  async setFocus(elementId: any) {
    elementId.focus();
  }
  checkFormField(fieldName: string) {
    // get field
    const field = this.mainFrm.get(fieldName);
    // check field
    const check = field?.invalid && field?.touched;
    // return data
    return check;
  }
  // navigation
  async goToPage(page: string, params?: any | null){
    // navigate to page
    this.utilitiesSv.goTo(page, true, params);
  }
  // actions
  async save() {
    // markAllAsTouched
    this.mainFrm.markAllAsTouched();
    // validate data
    if (!this.mainFrm.valid) {
      // send alert
      this.alertSv.showMessage(this.translate.instant('HELPERS.requiredFields'),'warning', this.translate.instant('HELPERS.warning'), true);
    } else {
      // check if user is been saved before
      if (this.savedData) {
        // update userBankAccount
        await this.updateUserBankAccount();
      } else {
        // add userBankAccount
        await this.addUserBankAccount();
      }
    }
  }
  async addUserBankAccount() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // get bankId
      const bankId = this.mainFrm.controls.bank.value;
      // get bankIndex
      const bankIndex = this.banks.findIndex((bank: BankInterface) => bank.id === bankId);
      // get bank
      const bank = this.banks[bankIndex];
      // get userBankAccountNumberEncoded
      const userBankAccountNumberEncoded = this.encodingSv.encodeCrypto(this.mainFrm.controls.userBankAccountNumber.value);
      // set userBankAccountData
      const userBankAccountData: UserBankAccountInterface = {
        userId: this.userData.id,
        bankId: bank.id,
        bankName: bank.bankName,
        bankLogo: bank.bankLogo,
        userBankAccountType: this.mainFrm.controls.userBankAccountType.value,
        userBankAccountNumber: this.mainFrm.controls.userBankAccountNumber.value,
        userBankAccountNumberDecoded: this.mainFrm.controls.userBankAccountNumber.value,
        userBankAccountNumberEncoded: userBankAccountNumberEncoded,
        userBankAccountActive: this.mainFrm.controls.userBankAccountActive.value,
      }
      // hide loader
      this.loaderEvent.emit(null);
      // emit data
      this.addEvent.emit(userBankAccountData);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async updateUserBankAccount() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // get bankId
      const bankId = this.mainFrm.controls.bank.value;
      // get bankIndex
      const bankIndex = this.banks.findIndex((bank: BankInterface) => bank.id === bankId);
      // get bank
      const bank = this.banks[bankIndex];
      // get userBankAccountNumberEncoded
      const userBankAccountNumberEncoded = this.encodingSv.encodeCrypto(this.mainFrm.controls.userBankAccountNumber.value);
      // update userBankAccount
      this.userBankAccountData.bankId = bank.id;
      this.userBankAccountData.bankName = bank.bankName;
      this.userBankAccountData.bankLogo = bank.bankLogo;
      this.userBankAccountData.bankId = this.mainFrm.controls.bank.value;
      this.userBankAccountData.userBankAccountType = this.mainFrm.controls.userBankAccountType.value;
      this.userBankAccountData.userBankAccountNumber = this.mainFrm.controls.userBankAccountNumber.value;
      this.userBankAccountData.userBankAccountNumberDecoded = this.mainFrm.controls.userBankAccountNumber.value;
      this.userBankAccountData.userBankAccountNumberEncoded = userBankAccountNumberEncoded;
      this.userBankAccountData.userBankAccountActive = this.mainFrm.controls.userBankAccountActive.value;
      // hide loader
      this.loaderEvent.emit(null);
      // emit data
      this.updateEvent.emit(this.userBankAccountData);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
}