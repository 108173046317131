// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 29/05/2023
// import
import { Component, EventEmitter, OnInit, OnChanges, Input, Output, SimpleChanges} from '@angular/core';
// translate
import { TranslateService } from '@ngx-translate/core';
// services
import { ApiService } from 'src/app/shared/services/api.service';
import { LocationService } from 'src/app/shared/services/location.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
// interfaces
import { CompanyInterface } from 'src/app/shared/interfaces/company.interface';
import { UserInterface } from 'src/app/shared/interfaces/user.interface';
// component
@Component({
  selector: 'app-user-company-card',
  templateUrl: './user-company-card.component.html',
  styleUrls: ['./user-company-card.component.scss']
})
// class
export class UserCompanyCardComponent implements OnInit, OnChanges {
  // variables
  @Input() editEnable: boolean = false;
  @Input() companyData: CompanyInterface = null;
  @Output() loaderEvent = new EventEmitter();
  @Output() editEvent = new EventEmitter();
  // loader data
  loadingMsg: string = null;
  loaderStatus: boolean = false;
  // search data
  typingTimer: any = null;
  // company data
  companyId: string = null;
  companyName: string = null;
  businessName: string = null;
  companyAbout: string = null;
  companyLogo: string = null;
  companyPortrait: string = null;
  companyEmail: string = null;
  companyPhoneCountryCode: string = null;
  companyPhoneCountryCodeCod: string = null;
  companyPhoneCountryName: string = null;
  companyPhoneCountryFlag: string = null;
  companyPhone: string = null;
  companyCellphoneCountryCode: string = null;
  companyCellphoneCountryCodeCod: string = null;
  companyCellphoneCountryName: string = null;
  companyCellphoneCountryFlag: string = null;
  companyCellphone: string = null;
  companyWhatsappCountryCode: string = null;
  companyWhatsappCountryCodeCod: string = null;
  companyWhatsappCountryName: string = null;
  companyWhatsappCountryFlag: string = null;
  companyWhatsapp: string = null;
  whatsappMsgIntro: string = null;
  whatsappMsgEnding: string = null;
  companyUriFacebook: string = null;
  companyUserInstagram: string = null;
  companyUserTwitter: string = null;
  companyUriLinkedin: string = null;
  companyUriTiktok: string = null;
  companyUriYoutube: string = null;
  companyWebProtocol: string = null;
  companyWeb: string = null;
  // role variables
  isAdmin: boolean = false;
  isFinancial: boolean = false;
  // constructor
  constructor(
    public translate: TranslateService,
    public apiSv: ApiService,
    public locationSv: LocationService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    private utilitiesSv: UtilitiesService,
  ) {
  }
  // life cycle
  async ngOnInit() {
    await this.getUserData();
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.companyData) {
      // get data
      await this.setData();
      this.whatsappMsgIntro = this.translate.instant('HELPERS.whatsAppCompanyMsgIntro');
      this.whatsappMsgEnding = this.translate.instant('HELPERS.whatsAppCompanyMsgEnding');
    }
  }
  // data
  async getUserData() {
    // get stored data user
    const storedDataUser: any = localStorage.getItem('userData');
    // get data user
    const userData: UserInterface = JSON.parse(storedDataUser);
    // check user data
    if (userData) {
      // check roles
      const checkAdmin = await this.utilitiesSv.checkRoles(userData.role, ['support', 'administrator']);
      this.isAdmin = checkAdmin;
      const checkFinancial = await this.utilitiesSv.checkRoles(userData.role, ['support', 'administrator', 'board', 'treasure', 'businessAgent']);
      this.isFinancial = checkFinancial;
    }
  }
  async setData() {
    // check company data
    if (this.companyData) {
      // company data
      this.companyId = this.companyData.id;
      this.companyName = this.companyData.companyName;
      this.businessName = this.companyData.businessName;
      this.companyAbout = this.companyData.companyAbout;
      this.companyLogo = this.companyData.companyLogo;
      this.companyPortrait = this.companyData.companyPortrait;
      this.companyEmail = this.companyData.companyEmail;
      this.companyPhoneCountryCode = this.companyData.companyPhoneCountryCode;
      this.companyPhoneCountryCodeCod = this.companyData.companyPhoneCountryCodeCod;
      this.companyPhoneCountryName = this.companyData.companyPhoneCountryName;
      this.companyPhoneCountryFlag = this.companyData.companyPhoneCountryFlag;
      this.companyPhone = this.companyData.companyPhone;
      this.companyCellphoneCountryCode = this.companyData.companyCellphoneCountryCode;
      this.companyCellphoneCountryCodeCod = this.companyData.companyCellphoneCountryCodeCod;
      this.companyCellphoneCountryName = this.companyData.companyCellphoneCountryName;
      this.companyCellphoneCountryFlag = this.companyData.companyCellphoneCountryFlag;
      this.companyCellphone = this.companyData.companyCellphone;
      this.companyWhatsappCountryCode = this.companyData.companyWhatsappCountryCode;
      this.companyWhatsappCountryCodeCod = this.companyData.companyWhatsappCountryCodeCod;
      this.companyWhatsappCountryName = this.companyData.companyWhatsappCountryName;
      this.companyWhatsappCountryFlag = this.companyData.companyWhatsappCountryFlag;
      this.companyWhatsapp = this.companyData.companyWhatsapp;
      this.companyUriFacebook = this.companyData.companyUriFacebook;
      this.companyUserInstagram = this.companyData.companyUserInstagram;
      this.companyUserTwitter = this.companyData.companyUserTwitter;
      this.companyUriLinkedin = this.companyData.companyUriLinkedin;
      this.companyUriTiktok = this.companyData.companyUriTiktok;
      this.companyUriYoutube = this.companyData.companyUriYoutube;
      this.companyWebProtocol = this.companyData.companyWebProtocol;
      this.companyWeb = this.companyData.companyWeb;
    }
  }
  async editCompany() {
    // emit data
    this.editEvent.emit(this.companyData);
  }
}
