
<!-- <div class="loader-container">
  <div class="loader-box">
    <div class="loader-6"></div>
    <div class="loader-msg">{{ loadingMsg }}</div>
  </div>
</div> -->

<!-- spinner -->
<ngx-spinner size="default" type="ball-clip-rotate-pulse">
	<p class="loader-msg" style="color: white">{{ loadingMsg }}</p>
</ngx-spinner>

<!-- loading data -->
<!-- <div class="row">
  <div class="col-12">
    <div class="loader-container">
      <div class="loader-box">
        <div class="loader-6"></div>
      </div>
      <p class="loading-txt">{{ loadingMsg }}</p>
    </div>
  </div>
</div> -->