<div class="card">
   <div class="card-header card-no-border">
     <h5>Order Overview</h5>
   </div>
   <div class="card-body pt-0">
     <div class="row m-0 overall-card overview-card">
       <div class="col-xl-12 col-md-12 col-sm-12 p-0">
         <div class="chart-right">
           <div class="row">
             <div class="col-xl-12">
               <div class="card-body p-0">
                 <ul class="balance-data">
                   <li><span class="circle bg-secondary"></span><span class="f-light ms-1">Refunds</span></li>
                   <li><span class="circle bg-primary"> </span><span class="f-light ms-1">Earning</span></li>
                   <li><span class="circle bg-success"> </span><span class="f-light ms-1">Order</span></li>
                 </ul>
                 <div class="current-sale-container order-container">
                   <div class="overview-wrapper" id="orderoverview">
                    <apx-chart
                      [series]="orderOverview.series"
                      [chart]="orderOverview.chart"
                      [stroke]="orderOverview.stroke"
                      [grid]="orderOverview.grid"
                      [plotOptions]="orderOverview.plotOptions"
                      [colors]="orderOverview.colors"
                      [fill]="orderOverview.fill"
                      [labels]="orderOverview.labels"
                      [markers]="orderOverview.markers"
                      [xaxis]="orderOverview.xaxis"
                      [tooltip]="orderOverview.tooltip"
                      [yaxis]="orderOverview.yaxis"
                      [responsive]="orderOverview.responsive"
                      ></apx-chart>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
 </div>