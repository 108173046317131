// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 29/09/2023
// import
import { Component, OnInit, Input } from '@angular/core';
// component
@Component({
  selector: 'app-total-no-passed-validation',
  templateUrl: './total-no-passed-validation.component.html',
  styleUrls: ['./total-no-passed-validation.component.scss']
})
export class TotalNoPassedValidationComponent {
  // variables
  @Input() totalNoPassedValidation: number = 0;
}
