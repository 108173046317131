<div class="card social-profile">
  <div class="card-body">
    <!-- edit -->
    <button class="btn btn-primary btn-xs btn-icon-only edit-form-btn" (click)="edit()" *ngIf="editEnable">
      <app-feather-icons [icon]="'edit-3'"></app-feather-icons>
    </button>
    <!-- data -->
    <div class="row mt-4">
      <!-- header -->
      <div class="col-12">
        <h4 class="mb-4">{{ 'COMPANYACTIVITIES.companyActivityInfo' | translate }}</h4>
      </div>
      <!-- product category -->
      <div class="col-12">
        <div class="card small-widget mb-3">
          <div class="card-body primary user-body-card">
            <div class="user-body-content text-center">
              <!-- name -->
              <h6 class="content-title">
                <span>{{ companyActivity }}</span>
              </h6>
              <!-- status -->
              <p class="table-status-txt mt-2">
                <span class="status-approved" *ngIf="companyActivityStatus === true">{{ 'STATUSES.activeFemale' | translate }}</span>
                <span class="status-rejected" *ngIf="companyActivityStatus === false">{{ 'STATUSES.unactiveFemale' | translate }}</span>
              </p>
            </div>
            <div class="bg-gradient">
              <app-feather-icons class="svg-feather" [icon]="'bookmark'"></app-feather-icons>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>