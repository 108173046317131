export let tasks = [
    {
        task_title: 'Client meeting',
        project_name: 'General',
        task_desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been',
    },
    {
        task_title: 'Plan webinar',
        project_name: 'General',
        task_desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been',
    },
    {
        task_title: 'Email newsletter',
        project_name: 'General',
        task_desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been',
    },
    {
        task_title: 'Publish podcast',
        project_name: 'General',
        task_desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been',
    },
    {
        task_title: 'Email newsletter',
        project_name: 'General',
        task_desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been',
    },
    {
        task_title: 'Email newsletter',
        project_name: 'General',
        task_desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been',
    },
    {
        task_title: 'Publish podcast',
        project_name: 'General',
        task_desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been',
    },
    {
        task_title: 'Client meeting',
        project_name: 'General',
        task_desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been',
    },
    {
        task_title: 'Client meeting',
        project_name: 'General',
        task_desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been',
    },
    {
        task_title: 'Launch website',
        project_name: 'General',
        task_desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been',
    },
]



