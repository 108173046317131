<div class="media loan-media" (click)="selectItem(loan)" [ngClass]="{'active': loan.loanNumber === loanSelectedNumber}">
  <div class="media-left">
    <img class="media-object rounded-circle" [src]="loan.user.avatar" [alt]="loan.user.firstName" onError="src = 'assets/images/broken-avatar.png'">
  </div>
  <div class="media-body">
    <h4 class="loan-number">{{ loan.loanNumber }}</h4>
    <h6 class="media-heading loan-user">
      <strong>{{ loan.user.firstName }}</strong><br>
      {{ loan.user.lastName }}
    </h6>
    <p class="loan-amount" *ngIf="loan.loanStatusLast === 'started'">{{ loan.loanAmount | currency:'$' : 'symbol' : '1.0-0' }}</p>
    <p class="loan-amount" *ngIf="loan.loanStatusLast === 'sended'">{{ loan.loanAmount | currency:'$' : 'symbol' : '1.0-0' }}</p>
    <p class="loan-amount" *ngIf="loan.loanStatusLast === 'accepted'">{{ loan.loanAmount | currency:'$' : 'symbol' : '1.0-0' }}</p>
    <p class="loan-amount" *ngIf="loan.loanStatusLast === 'validated'">{{ loan.loanAmount | currency:'$' : 'symbol' : '1.0-0' }}</p>
    <p class="loan-amount" *ngIf="loan.loanStatusLast === 'noPassedValidation'">{{ loan.loanAmount | currency:'$' : 'symbol' : '1.0-0' }}</p>
    <p class="loan-amount" *ngIf="loan.loanStatusLast === 'preApproved'">{{ loan.loanAmountPreApproved | currency:'$' : 'symbol' : '1.0-0' }}</p>
    <p class="loan-amount" *ngIf="loan.loanStatusLast === 'rejected'">{{ loan.loanAmount | currency:'$' : 'symbol' : '1.0-0' }}</p>
    <p class="loan-amount" *ngIf="loan.loanStatusLast === 'approved'">{{ loan.loanAmountApproved | currency:'$' : 'symbol' : '1.0-0' }}</p>
    <p class="loan-amount" *ngIf="loan.loanStatusLast === 'disbursementOnProgress'">{{ loan.loanAmountApproved | currency:'$' : 'symbol' : '1.0-0' }}</p>
    <p class="loan-amount" *ngIf="loan.loanStatusLast === 'disbursed'">{{ loan.loanAmountApproved | currency:'$' : 'symbol' : '1.0-0' }}</p>
    <p class="loan-amount" *ngIf="loan.loanStatusLast === 'payed'">{{ loan.loanAmountApproved | currency:'$' : 'symbol' : '1.0-0' }}</p>
    <p class="loan-amount" *ngIf="loan.loanStatusLast === 'preLegal'">{{ loan.loanAmountApproved | currency:'$' : 'symbol' : '1.0-0' }}</p>
    <p class="loan-amount" *ngIf="loan.loanStatusLast === 'legal'">{{ loan.loanAmountApproved | currency:'$' : 'symbol' : '1.0-0' }}</p>
    <p class="loan-amount" *ngIf="loan.loanStatusLast === 'negotiation'">{{ loan.loanAmountApproved | currency:'$' : 'symbol' : '1.0-0' }}</p>
    <p class="loan-amount" *ngIf="loan.loanStatusLast === 'expired'">{{ loan.loanAmountApproved | currency:'$' : 'symbol' : '1.0-0' }}</p>
    <p class="loan-amount" *ngIf="loan.loanStatusLast === 'canceled'">{{ loan.loanAmountApproved | currency:'$' : 'symbol' : '1.0-0' }}</p>
    <p class="loan-date">{{ loan.loanStatusDateLast | momentPipe: 'date': currentLang: 'DD MMM YYYY' }}</p>
    <p class="loan-date">{{ loan.loanStatusDateLast | momentPipe: 'TimeAmPm': currentLang: 'hh:mm A' }}</p>
  </div>
</div>