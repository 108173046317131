// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 03/06/2023
// import
import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
// translate
import { TranslateService } from '@ngx-translate/core';
// services
import { BreadcrumbsService } from 'src/app/shared/services/breadcrumbs.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
// interfaces
import { PositionInterface } from 'src/app/shared/interfaces/position.interface';
// component
@Component({
  selector: 'app-positions-card',
  templateUrl: './positions-card.component.html',
  styleUrls: ['./positions-card.component.scss']
})
// class
export class PositionsCardComponent implements OnInit, OnChanges {
  // variables
  @Input() positionsData: any = null;
  @Output() loaderEvent = new EventEmitter();
  @Output() openEvent = new EventEmitter();
  @Output() editEvent = new EventEmitter();
  @Output() deleteEvent = new EventEmitter();
  // positions data
  positions: any[] = [];
  // constructor
  constructor(
    public router: Router,
    public translate: TranslateService,
    public breadcrumbSv: BreadcrumbsService,
    public apiSv: ApiService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    private utilitiesSv: UtilitiesService,
  ) {
  }
  // life cycle
  async ngOnInit() {
    await this.setPositions();
  }
  async ngOnChanges(changes: SimpleChanges) {
    try {
      if (changes.positionsData) {
        await this.setPositions();
      }
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  // loader
  async updateLoader(loaderMsg: any) {
    this.utilitiesSv.updateLoader(loaderMsg);
  }
  // data
  async setPositions() {
    if (this.positionsData !== null) {
      // update positions
      this.positions = this.positionsData;
    }
  }
  openPosition(position: PositionInterface) {
    // emit data
    this.openEvent.emit(position);
  }
  editPosition(position: PositionInterface) {
    // emit data
    this.editEvent.emit(position);
  }
}
