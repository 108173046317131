import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '../../../../services/nav.service';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss']
})
export class LanguagesComponent implements OnInit {
  // variables
  @Input() origin: any = null;

  public language: boolean = false;

  public languages: any[] = [
    {
      language: 'English',
      code: 'en',
      type: 'US',
      icon: 'us'
    },
    {
      language: 'Español',
      code: 'es',
      type: 'ES',
      icon: 'es'
    },
  ]

  public selectedLanguage: any = {
    language: 'Español',
    code: 'es',
    type: 'ES',
    icon: 'es'
  }

  constructor(public navServices: NavService, private translate: TranslateService) { }
  // 
  ngOnInit() {
  }

  changeLanguage(lang) {
    this.translate.use(lang.code)
    this.selectedLanguage = lang;
  }

}
