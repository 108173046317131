// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 03/06/2023
// import
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// translate
import { TranslateService } from '@ngx-translate/core';
// interfaces
import { LoanInterface } from 'src/app/shared/interfaces/loan.interface';
// component
@Component({
  selector: 'app-loan-item',
  templateUrl: './loan-item.component.html',
  styleUrls: ['./loan-item.component.scss']
})
// class
export class LoanItemComponent implements OnInit {
  // variables
  @Input() loan: any = null;
  @Input() loanSelectedNumber: string = null;
  @Output() selectEvent = new EventEmitter();
  // lang data
  currentLang: string = null;
  // constructor
  constructor(
    public translate: TranslateService,
  ) {
    // get currentLang
    const currentLang = this.translate.getDefaultLang();
    // set currentLang
    this.currentLang = currentLang;
  }
  // life cycle
  async ngOnInit() {
  }
  selectItem(loan: LoanInterface) {
    // emit data
    this.selectEvent.emit(loan);
  }
}
