<div class="card social-profile">
  <div class="card-body p-5">
    <!-- edit -->
    <button class="btn btn-primary btn-xs btn-icon-only edit-form-btn" (click)="edit()" *ngIf="editEnable">
      <app-feather-icons [icon]="'edit-3'"></app-feather-icons>
    </button>
    <!-- avatar -->
    <div class="social-img-wrap"> 
      <div class="social-img">
        <img [src]="avatar" [alt]="firstName" onError="src = 'assets/images/broken-avatar.png'" *ngIf="avatar !== null">
        <img src="assets/images/broken-avatar.png" alt="Avatar" *ngIf="avatar === null">
      </div>
    </div>
    <!-- nationality -->
    <div class="nationality-icon" *ngIf="nationalityName !== null">
      <p class="nationality-male">
        <span class="nationality-txt">{{ nationalityName }}</span>
        <img class="nationality-img" [src]="nationalityFlag" [alt]="nationalityName">
      </p>
    </div>
    <!-- status -->
    <div class="row mt-3" *ngIf="(!isClient) && (currentUserData.id !== userId)">
      <div class="col-12">
        <p class="table-status-txt">
          <span class="status-sended" *ngIf="userStatus === 'pending'">{{ 'STATUSES.pending' | translate }}</span>
          <span class="status-approved" *ngIf="userStatus === 'active'">{{ 'STATUSES.active' | translate }}</span>
          <span class="status-rejected" *ngIf="userStatus === 'unactive'">{{ 'STATUSES.unactive' | translate }}</span>
        </p>
      </div>
    </div>
    <!-- names & contact -->
    <div class="social-details">
      <!-- names -->
      <h5 class="mb-1">
        <b>{{ firstName }}<span *ngIf="secondName !== null">&nbsp;{{ secondName }}</span></b>
      </h5>
      <p class="mt-0">
        {{ lastName }}<span *ngIf="surname !== null">&nbsp;{{ surname }}</span>
      </p>
      <!-- gender -->
      <div class="gender-icon" *ngIf="gender !== null">
        <p class="gender-male" *ngIf="gender === 'male'">
          <span class="gender-txt">{{ 'USER.formLabels.genderMasculine' | translate }}</span>
          <img class="gender-img" src="assets/images/user/icon-gender-male.png" alt="Gender">
        </p>
        <p class="gender-female" *ngIf="gender === 'female'">
          <span class="gender-txt">{{ 'USER.formLabels.genderFemenine' | translate }}</span>
          <img class="gender-img" src="assets/images/user/icon-gender-female.png" alt="Gender">
        </p>
        <p class="gender-other" *ngIf="gender === 'other'">
          <span class="gender-txt">{{ 'USER.formLabels.genderOther' | translate }}</span>
          <img class="gender-img" src="assets/images/user/icon-gender-other.png" alt="Gender">
        </p>
      </div>
      <!-- contact options -->
      <ul class="card-social">
        <li>
          <a class="contact-option" [href]="'mailto:' + email">
            <app-feather-icons class="feather-icon" [icon]="'mail'"></app-feather-icons>
            <span class="social-btn-txt">{{ email }}</span>
          </a>
        </li>
        <li *ngIf="phone !== null">
          <a class="contact-option" [href]="'tel: +' + phoneCountryCodeCod + phone">
            <app-feather-icons class="feather-icon" [icon]="'phone-call'"></app-feather-icons>
            <span class="social-btn-txt">+{{ phoneCountryCodeCod }}&nbsp;{{ phone }}</span>
          </a>
        </li>
        <li *ngIf="cellphone !== null">
          <a class="contact-option" [href]="'tel: +' + cellphoneCountryCodeCod + cellphone">
            <app-feather-icons class="feather-icon" [icon]="'smartphone'"></app-feather-icons>
            <span class="social-btn-txt">+{{ cellphoneCountryCodeCod }}&nbsp;{{ cellphone }}</span>
          </a>
        </li>
      </ul>
    </div>
    <!-- other data -->
    <div class="row mt-4" *ngIf="fullData">
      <div *ngIf="showDocuments">
        <!-- document -->
        <div class="col-12">
          <div class="card small-widget mb-3">
            <div class="card-body primary user-body-card">
              <span class="widget-title">{{ 'USER.formLabels.document' | translate }}</span>
              <div class="user-body-content">
                <h6 class="content-title">{{ documentNumber | number: '1.0-0' }}</h6>
                <p class="content-txt">
                  <small *ngIf="documentType === 'id'">{{ 'USER.formLabels.documentTypeCedulaShort' | translate }}</small>
                  <small *ngIf="documentType === 'foreign'">{{ 'USER.formLabels.documentTypeForeign' | translate }}</small>
                  <small *ngIf="documentType === 'workPermission'">{{ 'USER.formLabels.documentTypeWorkPermit' | translate }}</small>
                  <small *ngIf="documentType === 'passport'">{{ 'USER.formLabels.documentTypePassport' | translate }}</small>
                </p>
                <p *ngIf="documentExpeditionDate !== null" class="content-txt"><small><b>{{ 'USER.formLabels.documentExpedition' | translate }}</b></small></p>
                <p *ngIf="documentExpeditionDate !== null" class="content-txt"><small>{{ documentExpeditionDate | momentPipe: 'date': currentLang: 'DD MMM YYYY' }}</small></p>
                <p *ngIf="documentExpeditionDate !== null" class="content-txt"><small>{{ documentExpeditionCityName }} - {{ documentExpeditionStateName }} - {{ documentExpeditionCountryName }}</small></p>
              </div>
              <div class="bg-gradient">
                <app-feather-icons class="svg-feather" [icon]="'speaker'"></app-feather-icons>
              </div>
            </div>
          </div>
        </div>
        <!-- documents -->
        <div class="col-12 mb-4">
          <app-loan-documents 
            [userId]="userId"
            [loanId]="loanId"
            [target]="'loan'"
            [editEnable]="fileEditEnable"
            [idFrontFileData]="idFrontFile"
            [idFrontFileShow]="true"
            [idBackFileData]="idBackFile"
            [idBackFileShow]="true"
            (loaderEvent)="updateLoader($event)"
            (actionEvent)="fileAction($event)">
          </app-loan-documents>
        </div>
      </div>
      <!-- bday -->
      <div class="col-12" *ngIf="birthDate !== null">
        <div class="card small-widget mb-3">
          <div class="card-body primary user-body-card">
            <span class="widget-title">{{ 'USER.formLabels.birth' | translate }}</span>
            <div class="user-body-content">
              <h6 class="content-title">{{ birthDate | momentPipe: 'date': currentLang: 'DD MMM YYYY' }}</h6>
              <p class="content-txt"><small>{{ birthPlaceStateName }} - {{ birthPlaceCityName }} - {{ birthPlaceCountryName }}</small></p>
            </div>
            <div class="bg-gradient">
              <app-feather-icons class="svg-feather" [icon]="'gift'"></app-feather-icons>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- addresses -->
    <div class="row"  *ngIf="showAddresses">
      <div class="col-12">
        <app-user-addresses [userData]="userData" [userAddressesData]="userAddresses" [editEnable]="editEnable" [origin]="'dashboard'"></app-user-addresses>
      </div>
    </div>
  </div>
</div>
<!-- modal -->
<ng-template #addressModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'LOCATION.formLabels.address' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-user-address-form [formType]="formType" [mailingUserAddressSelected]="mailingUserAddressSelected" [userAddress]="userAddress" (saveEvent)="saveAddress($event)" (cancelEvent)="cancelAddress()"></app-user-address-form>
  </div>
</ng-template>