<div class="card small-widget mb-4">
  <div class="card-body primary">
    <span class="widget-title">{{ 'REPORT.totalPayed' | translate }}</span>
    <div class="d-block align-items-end gap-1">
      <h4>{{ totalPayed | currency:'$' : 'symbol' : '1.0-0' }}</h4>
    </div>
    <div class="bg-gradient">
      <app-feather-icons class="svg-feather" [icon]="'sunset'"></app-feather-icons>
    </div>
  </div>
</div>