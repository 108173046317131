// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 03/06/2023
// import
import { Component, EventEmitter, Input, Output, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
// translate
import { TranslateService } from '@ngx-translate/core';
// plugins
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { editorCompanyAboutGlobalConfigEn, editorCompanyAboutGlobalConfigEs } from 'src/app/shared/services/angular-editor-config';
// services
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
// interfaces
import { CompanyInterface } from 'src/app/shared/interfaces/company.interface';
// component
@Component({
  selector: 'app-company-legal-card',
  templateUrl: './company-legal-card.component.html',
  styleUrls: ['./company-legal-card.component.scss']
})
// class
export class CompanyLegalCardComponent implements OnInit {
  // variables
  @Input() companyData: CompanyInterface = null;
  @Input() showLegal: boolean = false;
  @Input() editLegal: boolean = false;
  @Input() showLoanLegal: boolean = false;
  @Input() editLoanLegal: boolean = false;
  @Input() showLoanInstructions: boolean = false;
  @Input() editLoanInstructions: boolean = false;
  @Input() showCompanyApproval: boolean = false;
  @Input() editCompanyApproval: boolean = false;
  @Output() loaderEvent = new EventEmitter();
  // editor configuration
  editorConfig: AngularEditorConfig = null;
  // lang data
  currentLang: string = null;
  // company data
  companyId: string = null;
  termsConditions: string = null;
  privacyPolicy: string = null;
  loanTermsConditions: string = null;
  loanPrivacyPolicy: string = null;
  companyLoanInstructions: string = null;
  companyAmountEdit: boolean = false;
  companyApprovalRequired: boolean = false;
  companyApprovalTxt: string = null;
  enableEditLegal: boolean = false;
  enableEditLoanLegal: boolean = false;
  enableEditLoanInstructions: boolean = false;
  enableEditCompanyApproval: boolean = false;
  // form data
  public legalFrm: FormGroup;
  public loanLegalFrm: FormGroup;
  public companyLoanInstructionsFrm: FormGroup;
  public companyApprovalFrm: FormGroup;
  // messages data
  formErrorMessages: any = {};
  // help data
  toggleHelp = false;
  // constructor
  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public apiSv: ApiService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    private utilitiesSv: UtilitiesService,
  ) {
    // get currentLang
    const currentLang = this.translate.getDefaultLang();
    // set currentLang
    this.currentLang = currentLang;
    // legal form
    this.legalFrm = this.formBuilder.group({
      termsConditions: [null, [Validators.required]],
      privacyPolicy: [null, [Validators.required]],
    });
    // loanLegal form
    this.loanLegalFrm = this.formBuilder.group({
      loanTermsConditions: [null, [Validators.required]],
      loanPrivacyPolicy: [null, [Validators.required]],
    });
    // loanInstructions form
    this.companyLoanInstructionsFrm = this.formBuilder.group({
      companyLoanInstructions: [null, [Validators.required]],
    });
    // companyApproval form
    this.companyApprovalFrm = this.formBuilder.group({
      companyAmountEdit: [false, [Validators.required]],
      companyApprovalRequired: [false, [Validators.required]],
      companyApprovalTxt: [null],
    });
  }
  // life cycle
  async ngOnInit() {
    this.translateMsgs();
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.companyData) {
      if (this.companyData !== null) {
        await this.setData();
      }
    }
  }
  // translate
  async translateMsgs () {
    // update angular editor
    if (this.currentLang === 'es') {
      this.editorConfig = editorCompanyAboutGlobalConfigEs;
    } else {
      this.editorConfig = editorCompanyAboutGlobalConfigEn;
    }
    // form error messages
    this.formErrorMessages = {
      termsConditions: [
        { type: 'required', message: this.translate.instant('COMPANY.formValidation.termsConditions.required') },
      ],
      privacyPolicy: [
        { type: 'required', message: this.translate.instant('COMPANY.formValidation.privacyPolicy.required') },
      ],
      loanTermsConditions: [
        { type: 'required', message: this.translate.instant('COMPANY.formValidation.loanTermsConditions.required') },
      ],
      loanPrivacyPolicy: [
        { type: 'required', message: this.translate.instant('COMPANY.formValidation.loanPrivacyPolicy.required') },
      ],
      companyLoanInstructions: [
        { type: 'required', message: this.translate.instant('COMPANY.formValidation.companyLoanInstructions.required') },
      ],
      companyAmountEdit: [
        { type: 'required', message: this.translate.instant('COMPANY.formValidation.companyAmountEdit.required') },
      ],
      companyApprovalRequired: [
        { type: 'required', message: this.translate.instant('COMPANY.formValidation.companyApprovalRequired.required') },
      ],
      companyApprovalTxt: [
        { type: 'required', message: this.translate.instant('COMPANY.formValidation.companyApprovalTxt.required') },
      ],
    };
  }
  // loader
  async updateLoader(loaderMsg: any) {
    this.loaderEvent.emit(loaderMsg);
  }
  // data
  async setData() {
    this.companyId = this.companyData.id;
    // legal
    this.termsConditions = this.companyData.termsConditions;
    this.privacyPolicy = this.companyData.privacyPolicy;
    this.legalFrm.controls.termsConditions.setValue(this.companyData.termsConditions);
    this.legalFrm.controls.privacyPolicy.setValue(this.companyData.privacyPolicy);
    // loan legal
    this.loanTermsConditions = this.companyData.loanTermsConditions;
    this.loanPrivacyPolicy = this.companyData.loanPrivacyPolicy;
    this.loanLegalFrm.controls.loanTermsConditions.setValue(this.companyData.loanTermsConditions);
    this.loanLegalFrm.controls.loanPrivacyPolicy.setValue(this.companyData.loanPrivacyPolicy);
    // loan instructions
    this.companyLoanInstructions = this.companyData.companyLoanInstructions;
    this.companyLoanInstructionsFrm.controls.companyLoanInstructions.setValue(this.companyData.companyLoanInstructions);
    // company approval
    this.companyAmountEdit = this.companyData.companyAmountEdit;
    this.companyApprovalRequired = this.companyData.companyApprovalRequired;
    this.companyApprovalTxt = this.companyData.companyApprovalTxt;
    this.companyApprovalFrm.controls.companyAmountEdit.setValue(this.companyData.companyAmountEdit);
    this.companyApprovalFrm.controls.companyApprovalRequired.setValue(this.companyData.companyApprovalRequired);
    this.companyApprovalFrm.controls.companyApprovalTxt.setValue(this.companyData.companyApprovalTxt);
  }
  // form
  async help () {
    if (this.toggleHelp) {
      this.toggleHelp = false;
    } else {
      this.toggleHelp = true;
    }
  }
  async setFocus(elementId: any) {
    elementId.focus();
  }
  // legal
  async editLegalData() {
    // show legal edit
    this.enableEditLegal = true;
  }
  async cancelEditLegalData() {
    // hide legal edit
    this.enableEditLegal = false;
  }
  async checkEditLegalData() {
    // markAllAsTouched
    this.legalFrm.markAllAsTouched();
    // validate data
    if (!this.legalFrm.valid) {
      // send alert
      this.alertSv.showMessage(this.translate.instant('HELPERS.requiredFields'),'warning', this.translate.instant('HELPERS.warning'), true);
    } else {
      // save LegalData
      await this.saveLegalData();
    }
  }
  async saveLegalData() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));

      // init termsConditions
      let termsConditions: string = this.legalFrm.controls.termsConditions.value;
      // check if observation is not null
      if (termsConditions !== null) {
        // fix termsConditions fontsize
        termsConditions = this.utilitiesSv.replaceStringOnString(termsConditions, '<font size="3">', '<font size="2">');
        // check if there is not font size parameter
        if (!termsConditions.includes('<font size="2">')) {
          termsConditions = '<font size="2">' + termsConditions + '</font>';
        }
      }
      // init privacyPolicy
      let privacyPolicy: string = this.legalFrm.controls.privacyPolicy.value;
      // check if observation is not null
      if (privacyPolicy !== null) {
        // fix privacyPolicy fontsize
        privacyPolicy = this.utilitiesSv.replaceStringOnString(privacyPolicy, '<font size="3">', '<font size="2">');
        // check if there is not font size parameter
        if (!privacyPolicy.includes('<font size="2">')) {
          privacyPolicy = '<font size="2">' + privacyPolicy + '</font>';
        }
      }
      // set legalData
      const legalData = {
        termsConditions: this.legalFrm.controls.termsConditions.value, 
        privacyPolicy: this.legalFrm.controls.privacyPolicy.value 
      }
      // update company legal
      await this.apiSv.updateCompany(this.companyData.id, legalData);
      // update data
      this.termsConditions = termsConditions;
      this.privacyPolicy = privacyPolicy;
      // hide legal edit
      this.enableEditLegal = false;
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  // loanLegal
  async editLoanLegalData() {
    // show legal edit
    this.enableEditLoanLegal = true;
  }
  async cancelEditLoanLegalData() {
    // hide legal edit
    this.enableEditLoanLegal = false;
  }
  async checkEditLoanLegalData() {
    // markAllAsTouched
    this.loanLegalFrm.markAllAsTouched();
    // validate data
    if (!this.loanLegalFrm.valid) {
      // send alert
      this.alertSv.showMessage(this.translate.instant('HELPERS.requiredFields'),'warning', this.translate.instant('HELPERS.warning'), true);
    } else {
      // save LoanLegalData
      await this.saveLoanLegalData();
    }
  }
  async saveLoanLegalData() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // init loanTermsConditions
      let loanTermsConditions: string = this.loanLegalFrm.controls.loanTermsConditions.value;
      // check if observation is not null
      if (loanTermsConditions !== null) {
        // fix loanTermsConditions fontsize
        loanTermsConditions = this.utilitiesSv.replaceStringOnString(loanTermsConditions, '<font size="3">', '<font size="2">');
        // check if there is not font size parameter
        if (!loanTermsConditions.includes('<font size="2">')) {
          loanTermsConditions = '<font size="2">' + loanTermsConditions + '</font>';
        }
      }
      // init loanPrivacyPolicy
      let loanPrivacyPolicy: string = this.loanLegalFrm.controls.loanPrivacyPolicy.value;
      // check if observation is not null
      if (loanPrivacyPolicy !== null) {
        // fix loanPrivacyPolicy fontsize
        loanPrivacyPolicy = this.utilitiesSv.replaceStringOnString(loanPrivacyPolicy, '<font size="3">', '<font size="2">');
        // check if there is not font size parameter
        if (!loanPrivacyPolicy.includes('<font size="2">')) {
          loanPrivacyPolicy = '<font size="2">' + loanPrivacyPolicy + '</font>';
        }
      }
      // set loanLegalData
      const loanLegalData = {
        loanTermsConditions: this.loanLegalFrm.controls.loanTermsConditions.value, 
        loanPrivacyPolicy: this.loanLegalFrm.controls.loanPrivacyPolicy.value 
      }
      // update loan legal
      await this.apiSv.updateCompany(this.companyData.id, loanLegalData);
      // update data
      this.loanTermsConditions = loanTermsConditions;
      this.loanPrivacyPolicy = loanPrivacyPolicy;
      // hide legal edit
      this.enableEditLoanLegal = false;
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  // companyLoanInstructions
  async editLoanInstructionsData() {
    // show legal edit
    this.enableEditLoanInstructions = true;
  }
  async cancelEditLoanInstructionsData() {
    // hide legal edit
    this.enableEditLoanInstructions = false;
  }
  async checkEditLoanInstructionsData() {
    // markAllAsTouched
    this.companyLoanInstructionsFrm.markAllAsTouched();
    // validate data
    if (!this.companyLoanInstructionsFrm.valid) {
      // send alert
      this.alertSv.showMessage(this.translate.instant('HELPERS.requiredFields'),'warning', this.translate.instant('HELPERS.warning'), true);
    } else {
      // save LoanInstructionsData
      await this.saveLoanInstructionsData();
    }
  }
  async saveLoanInstructionsData() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // init companyLoanInstructions
      let companyLoanInstructions: string = this.companyLoanInstructionsFrm.controls.companyLoanInstructions.value;
      // check if observation is not null
      if (companyLoanInstructions !== null) {
        // fix companyLoanInstructions fontsize
        companyLoanInstructions = this.utilitiesSv.replaceStringOnString(companyLoanInstructions, '<font size="3">', '<font size="2">');
        // check if there is not font size parameter
        if (!companyLoanInstructions.includes('<font size="2">')) {
          companyLoanInstructions = '<font size="2">' + companyLoanInstructions + '</font>';
        }
      }
      // set companyInstructionsData
      const companyInstructionsData = {
        companyLoanInstructions
      }
      // update company instructions
      await this.apiSv.updateCompany(this.companyData.id, companyInstructionsData);
      // update data
      this.companyLoanInstructions = companyLoanInstructions;
      // hide instructions edit
      this.enableEditLoanInstructions = false;
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  // companyApproval
  async editCompanyApprovalData() {
    // show legal edit
    this.enableEditCompanyApproval = true;
  }
  async cancelEditCompanyApprovalData() {
    // hide legal edit
    this.enableEditCompanyApproval = false;
  }
  async checkEditCompanyApprovalData() {
    // markAllAsTouched
    this.companyApprovalFrm.markAllAsTouched();
    // validate data
    if (!this.companyApprovalFrm.valid) {
      // send alert
      this.alertSv.showMessage(this.translate.instant('HELPERS.requiredFields'),'warning', this.translate.instant('HELPERS.warning'), true);
    } else {
      // save CompanyApprovalData
      await this.saveCompanyApprovalData();
    }
  }
  async saveCompanyApprovalData() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // init companyApprovalTxt
      let companyApprovalTxt: string = this.companyApprovalFrm.controls.companyApprovalTxt.value;
      // check if observation is not null
      if (companyApprovalTxt !== null) {
        // fix companyApprovalTxt fontsize
        companyApprovalTxt = this.utilitiesSv.replaceStringOnString(companyApprovalTxt, '<font size="3">', '<font size="2">');
        // check if there is not font size parameter
        if (!companyApprovalTxt.includes('<font size="2">')) {
          companyApprovalTxt = '<font size="2">' + companyApprovalTxt + '</font>';
        }
      }
      // set companyApprovalData
      const companyApprovalData = {
        companyAmountEdit: this.companyApprovalFrm.controls.companyAmountEdit.value, 
        companyApprovalRequired: this.companyApprovalFrm.controls.companyApprovalRequired.value, 
        companyApprovalTxt
      }
      // update company approval
      await this.apiSv.updateCompany(this.companyData.id, companyApprovalData);
      // update data
      this.companyAmountEdit = this.companyApprovalFrm.controls.companyAmountEdit.value;
      this.companyApprovalRequired = this.companyApprovalFrm.controls.companyApprovalRequired.value;
      this.companyApprovalTxt = companyApprovalTxt;
      // hide approval edit
      this.enableEditCompanyApproval = false;
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
}
