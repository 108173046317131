// author: Alejandro Bermúdez Restrepo
// product: Think In
// date: 03/06/2023
// import
import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
// translate
import { TranslateService } from '@ngx-translate/core';
// services
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
// interfaces
import { ProductInterface } from 'src/app/shared/interfaces/product.interface';
// component
@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
// class
export class ProductCardComponent implements OnInit {
  // variables
  @Input() productData: ProductInterface = null;
  @Input() fullData: boolean = false;
  @Input() editEnable: boolean = false;
  @Output() loaderEvent = new EventEmitter();
  @Output() editEvent = new EventEmitter();
  // lang data
  currentLang: string = null;
  // product data
  productCategoryId: string = null;
  productCategoryName: string = null;
  productName: string = null;
  productDescription: string = null;
  productPortrait: string = null;
  paymentMethod: string = null;
  productIcon: string = null;
  loanMonthMin: number = 0;
  loanMonthMax: number = 0;
  loanMonthSteps: number = 0;
  loanAmountMin: number = 0;
  loanAmountMax: number = 0;
  loanAmountMaxLimit: boolean = false;
  loanInterest: number = 0;
  loanMonthlyRate: number = 0;
  loanAnualRate: number = 0;
  loanReferralMinimum: number = 0;
  loanPaymentReceipts: number = 0;
  borrowingCapacityPercentage: number = 0;
  productInsurance: boolean = false;
  productStatus: boolean = false;
  // constructor
  constructor(
    public translate: TranslateService,
    public apiSv: ApiService,
    public filterSv: FilterService,
    public alertSv: AlertService,
  ) {
    // get currentLang
    const currentLang = this.translate.getDefaultLang();
    // set currentLang
    this.currentLang = currentLang;
  }
  // life cycle
  async ngOnInit() {}
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.productData) {
      if (this.productData !== null) {
        await this.setData();
      }
    }
  }
  // loader
  async updateLoader(loaderMsg: any) {
    this.loaderEvent.emit(loaderMsg);
  }
  // data
  async setData() {
    // set single data
    this.productCategoryId = this.productData.productCategoryId;
    this.productCategoryName = this.productData.productCategoryName;
    this.productName = this.productData.productName;
    this.productDescription = this.productData.productDescription;
    this.productPortrait = this.productData.productPortrait;
    this.paymentMethod = this.productData.paymentMethod;
    this.productIcon = this.productData.productIcon;
    this.loanMonthMin = this.productData.loanMonthMin;
    this.loanMonthMax = this.productData.loanMonthMax;
    this.loanMonthSteps = this.productData.loanMonthSteps;
    this.loanAmountMin = this.productData.loanAmountMin;
    this.loanAmountMax = this.productData.loanAmountMax;
    this.loanAmountMaxLimit = this.productData.loanAmountMaxLimit;
    this.loanInterest = this.productData.loanInterest;
    this.loanMonthlyRate = this.productData.loanMonthlyRate;
    this.loanAnualRate = this.productData.loanAnualRate;
    this.loanReferralMinimum = this.productData.loanReferralMinimum;
    this.loanPaymentReceipts = this.productData.loanPaymentReceipts;
    this.borrowingCapacityPercentage = this.productData.borrowingCapacityPercentage;
    this.productInsurance = this.productData.productInsurance;
    this.productStatus = this.productData.productStatus;
  }
  // actions
  async edit() {
    // emit data
    this.editEvent.emit(this.productData);
  }
}