// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 25/05/2023
// import
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
// translate
import { TranslateService } from '@ngx-translate/core';
// services
import { AuthService } from 'src/app/shared/services/auth.service';
import { EncodingService } from 'src/app/shared/services/encoding.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
// interfaces
import { UserInterface } from 'src/app/shared/interfaces/user.interface';
// variables
import Swal from 'sweetalert2';
declare var $: any;
// component
@Component({
  selector: 'app-change-pwd-form',
  templateUrl: './change-pwd-form.component.html',
  styleUrls: ['./change-pwd-form.component.scss']
})
// class
export class ChangePwdFormComponent implements OnInit {
  // variables
  @Input() origin: string = null;
  @Input() userId: string = null;
  @Input() email: string = null;
  @Input() code: string = null;
  @Output() loaderEvent = new EventEmitter();
  @Output() continueEvent = new EventEmitter();
  // form data
  mainFrm: FormGroup;
  showPwd: boolean = false;
  showConfirmPwd: boolean = false;
  passwordMismatch: boolean = false;
  // messages data
  formErrorMessages: any = {};
  // help data
  toggleHelp = false;
  // constructor
  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public authSv: AuthService,
    public encodingSv: EncodingService,
    public apiSv: ApiService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    private utilitiesSv: UtilitiesService,
  ) { 
    // forms
    this.mainFrm = this.formBuilder.group({
      password: [null, [Validators.required, Validators.minLength(6), Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,30}$')]],
      confirmPwd: [null, Validators.required],
    }, {
      validators: this.password.bind(this)
    });
  }
  // life cycle
  async ngOnInit() {
    await this.translateMsgs();
  }
  // translate
  async translateMsgs () {
    this.formErrorMessages = {
      password: [
        { type: 'required', message: this.translate.instant('USER.formValidation.password.required') },
        { type: 'minLength', message: this.translate.instant('USER.formValidation.password.minLength') },
        { type: 'pattern', message: this.translate.instant('USER.formValidation.password.pattern') },
        { type: 'match', message: this.translate.instant('USER.formValidation.confirmPwd.match') },
      ],
      confirmPwd: [
        { type: 'required', message: this.translate.instant('USER.formValidation.confirmPwd.required') },
      ],
    };
  }
  // form
  async help () {
    if (this.toggleHelp) {
      this.toggleHelp = false;
    } else {
      this.toggleHelp = true;
    }
  }
  async togglePwd() {
    this.showPwd = !this.showPwd;
  }
  async toggleConfirmPwd() {
    this.showConfirmPwd = !this.showConfirmPwd;
  }
  async setFocus(elementId: any) {
    elementId.focus();
  }
  password(formGroup: FormGroup) {
    // get data
    const password = formGroup.get('password').value;
    const passwordDirty = formGroup.get('password').dirty;
    const passwordTouched = formGroup.get('password').touched;
    const confirmPwd = formGroup.get('confirmPwd').value;
    const confirmPwdDirty = formGroup.get('confirmPwd').dirty;
    const confirmPwdTouched = formGroup.get('confirmPwd').touched;
    // check if password and confirmPwd have been touched or dirty
    if ((passwordDirty || passwordTouched) && (confirmPwdDirty || confirmPwdTouched)) {
      // check if password and confirmPwd are the same
      if (password === confirmPwd) {
        // set passwordMismatch
        this.passwordMismatch = false;
        // return data
        return null;
      } else {
        // set passwordMismatch
        this.passwordMismatch = true;
        // return data
        return { passwordNotMatch: true };
      }
    }
  }
  // navigation
  async goToPage(page: string, params?: any | null){
    // navigate to page
    this.utilitiesSv.goTo(page, true, params);
  }
  // actions
  async save() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // check origin
      switch (this.origin) {
        case 'change-pwd':
          // get resetData
          const resetData: any = {
            email: this.email,
            code: this.code,
            password: this.mainFrm.controls.password.value,
          };
          // init auxReset
          const auxReset: any = await this.apiSv.resetPassword(resetData);
          // check if aux was success
          if (auxReset.data.accessToken) {
            // set data
            const loginData: any = {
              email: this.email,
              password: this.mainFrm.controls.password.value
            };
            // log in
            await this.authSv.login(loginData);
            // hide loader
            this.loaderEvent.emit(null);
            // emit data
            this.continueEvent.emit('login');
          } else {
            // hide loader
            this.loaderEvent.emit(null);
            // emit data
            this.continueEvent.emit('logout');
          }
          break;
        case 'profile':
          // set data
          const changeData: any = {
            password: this.mainFrm.controls.password.value
          };
          // update pwd
          const auxChange: any = await this.apiSv.changePassword(changeData);
          // check if aux was success
          if (auxChange.data.accessToken) {
            // hide loader
            this.loaderEvent.emit(null);
            // emit data
            this.continueEvent.emit('saved');
          } else {
            // hide loader
            this.loaderEvent.emit(null);
            // emit data
            this.continueEvent.emit('error');
          }
          break;
      }
    } catch (error) {
      console.log('error', error);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async cancelCode() {
    // set changePwdData
    const changePwdData: any = {
      codeStatus: false,
    }
    // emit data
    this.continueEvent.emit(changePwdData);
  }
}
