// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 03/06/2023
// import
import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges  } from '@angular/core';
// translate
import { TranslateService } from '@ngx-translate/core';
// services
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
// component
@Component({
  selector: 'app-user-validation',
  templateUrl: './user-validation.component.html',
  styleUrls: ['./user-validation.component.scss']
})
// class
export class UserValidationComponent implements OnInit, OnChanges  {
  // variables
  @Input() userId: string = null;
  @Input() loanId: string = null;
  @Input() score: number = 0;
  @Input() fileEditEnable: boolean = false;
  @Input() profileFileData: any = null;
  @Input() synthesisFileData: any = null;
  @Input() detailedFileData: any = null;
  @Output() loaderEvent = new EventEmitter();
  @Output() editEvent = new EventEmitter();
  @Output() fileActionEvent = new EventEmitter();
  // lang data
  currentLang: string = null;
  // score data
  scoreMax: number = 1000;
  scoreSection: number = this.scoreMax / 9;
  scoreClass: string = null;
  // file data
  profileFileShow: boolean = false;
  synthesisFileShow: boolean = false;
  detailedFileShow: boolean = false;
  // constructor
  constructor(
    public translate: TranslateService,
    public apiSv: ApiService,
    public filterSv: FilterService,
    public alertSv: AlertService,
  ) {
    // get currentLang
    const currentLang = this.translate.getDefaultLang();
    // set currentLang
    this.currentLang = currentLang;
  }
  // life cycle
  async ngOnInit() {}
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.score) {
      await this.setData();
    }
  }
  // loader
  async updateLoader(loaderMsg: any) {
    this.loaderEvent.emit(loaderMsg);
  }
  // data
  async setData() {
    this.profileFileShow = this.profileFileData.filePath !== null ? true : false;
    this.synthesisFileShow = this.synthesisFileData.filePath !== null ? true : false;
    this.detailedFileShow = this.detailedFileData.filePath !== null ? true : false;
    // get score
    const score: number = this.score;
    // check score
    if (score > 0 && score <= (this.scoreSection * 1)) {
      // update class
      this.scoreClass = 'score-low-low';
    } else if (score > (this.scoreSection * 1) && score <= (this.scoreSection * 2)) {
      // update class
      this.scoreClass = 'score-low-mid';
    } else if (score > (this.scoreSection * 2) && score <= (this.scoreSection * 3)) {
      // update class
      this.scoreClass = 'score-low-high';
    } else if (score > (this.scoreSection * 3) && score <= (this.scoreSection * 4)) {
      // update class
      this.scoreClass = 'score-mid-low';
    } else if (score > (this.scoreSection * 4) && score <= (this.scoreSection * 5)) {
      // update class
      this.scoreClass = 'score-mid-mid';
    } else if (score > (this.scoreSection * 5) && score <= (this.scoreSection * 6)) {
      // update class
      this.scoreClass = 'score-mid-high';
    } else if (score > (this.scoreSection * 6) && score <= (this.scoreSection * 7)) {
      // update class
      this.scoreClass = 'score-high-low';
    } else if (score > (this.scoreSection * 7) && score <= (this.scoreSection * 8)) {
      // update class
      this.scoreClass = 'score-high-mid';
    } else if (score > (this.scoreSection * 8)) {
      // update class
      this.scoreClass = 'score-high-hig';
    }
  }
  // files
  async fileAction(action: any) {
    this.fileActionEvent.emit(action);
  }
}
