// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 19/06/2023
// import
import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
// translate
import { TranslateService } from '@ngx-translate/core';
// environment
import { environment } from 'src/environments/environment';
// plugins
declare var $: any;
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { editorObservationGlobalConfigEn, editorObservationGlobalConfigEs } from 'src/app/shared/services/angular-editor-config';
// services
import { EncodingService } from 'src/app/shared/services/encoding.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
import { MediaService } from 'src/app/shared/services/media.service';
// interfaces
import { LoanInterface } from 'src/app/shared/interfaces/loan.interface';
import { LoanStatusInterface } from 'src/app/shared/interfaces/loan-status.interface';
import { LoanDocumentInterface } from 'src/app/shared/interfaces/loan-document.interface';
import { LoanPaymentInterface } from 'src/app/shared/interfaces/loan-payment.interface';
import { LoanPaymentStatusInterface } from 'src/app/shared/interfaces/loan-payment-status.interface';
import { CompanyInterface } from 'src/app/shared/interfaces/company.interface';
import { OtherCompanyInterface } from 'src/app/shared/interfaces/other-company.interface';
import { ProductInterface } from 'src/app/shared/interfaces/product.interface';
import { UserInterface } from 'src/app/shared/interfaces/user.interface';
import { UserAddressInterface } from 'src/app/shared/interfaces/user-address.interface';
import { UserBankAccountInterface } from 'src/app/shared/interfaces/user-bank-account.interface';
import { BankInterface } from 'src/app/shared/interfaces/bank.interface';
import { UserWorkInterface } from 'src/app/shared/interfaces/user-work.interface';
import { UserFinancialInterface } from 'src/app/shared/interfaces/user-financial.interface';
import { CountryInterface } from 'src/app/shared/interfaces/country.interface';
import { CityInterface } from 'src/app/shared/interfaces/city.interface';
import { ProfessionInterface } from 'src/app/shared/interfaces/profession.interface';
import { PositionInterface } from 'src/app/shared/interfaces/position.interface';
import { OccupationInterface } from 'src/app/shared/interfaces/occupation.interface';
// component
@Component({
  selector: 'app-loan-card',
  templateUrl: './loan-card.component.html',
  styleUrls: ['./loan-card.component.scss']
})
// class
export class LoanCardComponent implements OnInit, OnChanges {
  // variables
  @Input() loanNumber: string = null;
  @Output() loaderEvent = new EventEmitter();
  @Output() saveEvent = new EventEmitter();
  @ViewChild('preAmountInput') preAmountInput: ElementRef;
  @ViewChild('preMonthsInput') preMonthsInput: ElementRef;
  @ViewChild('amountInput') amountInput: ElementRef;
  @ViewChild('monthsInput') monthsInput: ElementRef;
  // form data
  public observationFrm: FormGroup;
  public scoreFrm: FormGroup;
  public borrowingCapacityFrm: FormGroup;
  public preApprovalFrm: FormGroup;
  public approvalFrm: FormGroup;
  public calcInterestFrm: FormGroup;
  public singlePaymentFrm: FormGroup;
  public noPassedValidationFrm: FormGroup;
  public rejectFrm: FormGroup;
  public loanPaymentFrm: FormGroup;
  // editor configuration
  editorConfig: AngularEditorConfig = null;
  editEnableObservations: boolean = false;
  // messages data
  formErrorMessages: any = {};
  // help data
  toggleHelp = false;
  // edit data
  editEnable: boolean = true;
  // app data
  appUri: string = null;
  // loader data
  loadingMsg: string = null;
  loaderStatus: boolean = false;
  // lang data
  currentLang: string = null;
  // user data
  userData: UserInterface = null;
  userId: string = null;
  editUserData: boolean = false;
  enableEditUserData: boolean = false;
  // role variables
  isAdmin: boolean = false;
  isFinancial: boolean = false;
  isCompany: boolean = false;
  isClient: boolean = false;
  // tab data
  currentTab: string = 'request';
  // navigation data
  previousLoan: string = null;
  nextLoan: string = null;
  // loan data
  loanId: string = null;
  loanType: string = null;
  loanCode: string = null;
  loanSinglePayment: number = 0;
  loanInterest: number = 0;
  loanAdditionalInfoConsulted: boolean = false;
  loanMonthMin: number = 0;
  loanMonthMax: number = 0;
  loanMonthSteps: number = 0;
  loanAmountMin: number = 0;
  loanAmountMax: number = 0;
  loanAmountMaxLimit: boolean = true;
  loanMonthlyRate: number = 0;
  loanAnualRate: number = 0;
  loanReferralMinimum: number = 0;
  loanPaymentReceipts: number = 0;
  borrowingCapacityPercentage: number = 0;
  borrowingCapacity: number = 0;
  loanRequestStep: number = 0;
  loanStatusLast: string = null;
  loanStatusDateLast: string = null;
  loanProfileValidation: boolean = false;
  loanSynthesisValidation: boolean = false;
  loanDetailedValidation: boolean = false;
  loanPromissoryLoaded: boolean = false;
  loanManagerSignature: boolean = false;
  loanValidationScore: number = 0;
  loanInsuranceIds: string = null;
  // score data
  scoreMin: number = 150;
  scoreMax: number = 950;
  scoreSection: number = (this.scoreMax - this.scoreMin) / 9;
  score: number = 150;
  scoreClass: string = null;
  customScore: boolean = false;
  // salary data
  salary: number = 0;
  // requested data
  loanAmount: any = 0;
  loanPaymentMonths: any = 0;
  // loan insurances data
  showLoanInsuranceIds: boolean = false;
  // pre-approved data
  loanAmountPreApproved: any = 0;
  loanPaymentMonthsPreApproved: any = 0;
  // company approved data
  companyAmount: number = 0;
  companyAmountMin: number = 100000;
  companyAmountMax: number = 100000000;
  companyAmountCustom: boolean = false;
  companyMonths: number = 0;
  companyMonthMin: number = 6;
  companyMonthMax: number = 48;
  companyMonthSteps: number = 3;
  companyMonthsCustom: boolean = false;
  loanAmountApprovedCompany: any = 0;
  loanPaymentMonthsApprovedCompany: any = 0;
  // approved data
  amount: number = 0;
  amountMin: number = 100000;
  amountMinError: boolean = false;
  amountMax: number = 100000000;
  amountMaxError: boolean = false;
  amountCustom: boolean = false;
  months: number = 0;
  monthMin: number = 6;
  monthMinError: boolean = false;
  monthMax: number = 48;
  monthMaxError: boolean = false;
  monthSteps: number = 3;
  monthsCustom: boolean = false;
  loanAmountApproved: any = 0;
  loanPaymentMonthsApproved: any = 0;
  // loan payments data
  loanPayments: any[] = [];
  unpaidPayments: any[] = [];
  selectedPayments: any[] = [];
  selectPayments: boolean = false;
  amountSelectPayments: number = 0;
  // loan statuses data
  loanStatuses: any[] = [];
  lastStatus: string = null;
  lastStatusNumber: number = 0;
  statuses: any[] = environment.statuses;
  // payment data
  singlePayment: number = 0;
  singlePaymentMin: number = 0;
  singlePaymentMinError: boolean = false;
  singlePaymentMaxError: boolean = false;
  singlePaymentCustom: boolean = false;
  singlePaymentCalculated: boolean = false;
  monthlyEffectiveRate: number = 0;
  loanPayment: LoanPaymentInterface = null;
  loanPaymentId: string = null;
  // company data
  companyData: any = null;
  companyId: string = null;
  businessName: string = null;
  companyApproval: boolean = null;
  companyAmountEdit: boolean = null;
  editCompanyData: boolean = false;
  enableEditCompanyData: boolean = false;
  // product data
  productId: string = null;
  productName: string = null;
  productData: ProductInterface = null;
  productAmountMin: number = 100000;
  productAmountMax: number = 100000000;
  productAmountMaxLimit: boolean = false;
  productMonthMin: number = 6;
  productMonthMax: number = 48;
  // loan user data
  loanUserData: UserInterface = null;
  // user work data
  workType: string = null;
  userWorkId: string = null;
  userWorkData: UserWorkInterface = null;
  editWorkData: boolean = false;
  enableEditWorkData: boolean = false;
  // user address data
  userAddressesData: any[] = [];
  // user financial totals data
  financialData: any = null;
  editFinancialData: boolean = false;
  enableEditFinancialData: boolean = false;
  // user bank account data
  userBankAccounts: any[] = [];
  enableEditBankAccountData: boolean = false;
  // bank data
  banks: any[] = [];
  allBanks: any[] = [];
  // files data
  idFrontFileData: any = null;
  idBackFileData: any = null;
  requestFileData: any = null;
  bankFileData: any = null;
  laborFileData: any = null;
  promissoryFileData: any = null;
  authorizationFileData: any = null;
  disbursementFileData: any = null;
  loanPaymentFileData: any = null;
  acceptanceFileData: any = null;
  companyPromissoryFileData: any = null;
  companyPromissoryUploaded: boolean = false;
  profileFileData: any = null;
  synthesisFileData: any = null;
  detailedFileData: any = null;
  paymentReceiptFiles: any[] = [];
  rutFileData: any = null; 
  ccioFileData: any = null; 
  repIdFileData: any = null; 
  pgFileData: any = null; 
  bankExtractsFileData: any = null; 
  pensionerFileData: any = null; 
  utilitiesFileData: any = null;
  otherDocument1FileData: any = null;
  showOtherDocumentFile1: boolean = false;
  otherDocumentFileAdded1: boolean = false;
  otherDocument2FileData: any = null;
  showOtherDocumentFile2: boolean = false;
  otherDocumentFileAdded2: boolean = false;
  otherDocument3FileData: any = null;
  showOtherDocumentFile3: boolean = false;
  otherDocumentFileAdded3: boolean = false;
  // files edit enabling
  enableEditInitialDocumentsData: boolean = null;
  enableSendInitialDocumentsData: boolean = null;
  enableAddOtherDocumentsData: boolean = null;
  editEnableIdFrontFileData: boolean = null;
  editEnableIdBackFileData: boolean = null;
  editEnableRequestFileData: boolean = null;
  editEnableBankFileData: boolean = null;
  editEnableLaborFileData: boolean = null;
  editEnablePromissoryFileData: boolean = null;
  editEnableInstructionsFileData: boolean = null;
  editEnableAuthorizationFileData: boolean = null;
  editEnableDisbursementFileData: boolean = null;
  editEnableAcceptanceFileData: boolean = null;
  editEnableCompanyPromissoryFileData: boolean = null;
  editEnableProfileFileData: boolean = null;
  editEnableSynthesisFileData: boolean = null;
  editEnableDetailedFileData: boolean = null;
  editEnablePaymentReceiptFiles: boolean = null;
  editEnableRutFileData: boolean = null;
  editEnableCCIOFileData: boolean = null;
  editEnableRepIdFileData: boolean = null;
  editEnablePGFileData: boolean = null;
  editEnableBankExtractsFileData: boolean = null;
  editEnablePensionerFileData: boolean = null;
  editEnableUtilitiesFileData: boolean = null;
  editEnableOtherDocument1FileData: boolean = null;
  editEnableOtherDocument2FileData: boolean = null;
  editEnableOtherDocument3FileData: boolean = null;
  // edit according statuses data
  enableEditValidationData: boolean = null;
  enableEditBorrowingCapacityData: boolean = null;
  enableEditPreApprovalData: boolean = null;
  enableEditCompanyApprovalData: boolean = null;
  enableEditCompanyDisbursementData: boolean = null;
  enableEditFinancialDisbursementData: boolean = null;
  enableEditApprovalData: boolean = null;
  enableEdiValidationFilesData: boolean = null;
  enableEditDocumentFilesData: boolean = null;
  // constructor
  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public encodingSv: EncodingService,
    public apiSv: ApiService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    private utilitiesSv: UtilitiesService,
    private mediaSv: MediaService,
    private modalService: NgbModal,
  ) {
    // get currentLang
    const currentLang = this.translate.getDefaultLang();
    // set currentLang
    this.currentLang = currentLang;
    // set appUri
    this.appUri = environment.appURI;
    // observation form
    this.observationFrm = this.formBuilder.group({
      observation: [null],
    });
    // score form
    this.scoreFrm = this.formBuilder.group({
      score: [150, [Validators.required, this.checkScoreMin(), this.checkScoreMax(), this.checkScoreCustom()]],
    });
    // borrowingCapacity form
    this.borrowingCapacityFrm = this.formBuilder.group({
      borrowingCapacity: [null, Validators.required],
    });
    // preApproval form
    this.preApprovalFrm = this.formBuilder.group({
      loanInsuranceIds: [null],
      preAmount: [null, [Validators.required, this.checkAmountCustom()]],
      preMonths: [null, [Validators.required, this.checkMonthCustom()]],
    });
    // approval form
    this.approvalFrm = this.formBuilder.group({
      loanInsuranceIds: [null],
      amount: [null, [Validators.required, this.checkAmountCustom()]],
      months: [null, [Validators.required, this.checkMonthCustom()]],
    });
    // calcInterest form
    this.calcInterestFrm = this.formBuilder.group({
      loanType: ['fixedInterest', [Validators.required, this.checkMinValue(0), this.checkMaxValue(100)]],
      loanInterest: [null, [Validators.required, this.checkMinValue(0), this.checkMaxValue(100)]],
    });
    // singlePayment form
    this.singlePaymentFrm = this.formBuilder.group({
      singlePayment: [null, [Validators.required]],
    });
    // noPassedValidation form
    this.noPassedValidationFrm = this.formBuilder.group({
      noPassedValidationType: [null, Validators.required],
      noPassedValidationMsg: [null],
    });
    // reject form
    this.rejectFrm = this.formBuilder.group({
      rejectType: [null, Validators.required],
      rejectMsg: [null],
    });
    // loanPayment form
    this.loanPaymentFrm = this.formBuilder.group({
      paymentNumber: [null, Validators.required],
      paymentAmount: [null, [Validators.required, this.checkPaymentMin()]],
      paymentInterestPercentage: [null, [Validators.required, Validators.min(0.1)]],
    });
    // check for form changes
    // --------------------------------------------------------------
    // borrowingCapacityFrm
    this.borrowingCapacityFrm.valueChanges.subscribe((val) => {
      // check preAmount
      if (typeof val.borrowingCapacity === 'number') {
        this.calcBorrowingCapacity();
      }
    });
    // preApprovalFrm
    this.preApprovalFrm.valueChanges.subscribe((val) => {
      // check preAmount
      if (typeof val.preAmount === 'string') {
        // mask value
        const maskedVal = this.utilitiesSv.convertStringToCurrency(val.preAmount);
        // check matched values
        if (val.preAmount !== maskedVal) {
          this.preApprovalFrm.patchValue({preAmount: maskedVal});
        }
      }
      // update amount
      this.amount = Math.ceil(typeof val.preAmount === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(val.preAmount)) : val.preAmount);
      // update months
      this.months = val.preMonths;
      // get loanInterest
      const loanInterest = this.loanInterest;
      // check loanInterest
      if (loanInterest > 0) {
        this.calcBorrowingCapacity();
        this.calcInterest();
      }
    });
    // approvalFrm
    this.approvalFrm.valueChanges.subscribe((val) => {
      // check amount
      if (typeof val.amount === 'string') {
        // mask value
        const maskedVal = this.utilitiesSv.convertStringToCurrency(val.amount);
        // check matched values
        if (val.amount !== maskedVal) {
          this.approvalFrm.patchValue({amount: maskedVal});
        }
      }
      // update amount
      this.amount = Math.ceil(typeof val.amount === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(val.amount)) : val.amount);
      // update months
      this.months = val.months;
      // get loanInterest
      const loanInterest = this.loanInterest;
      // check loanInterest
      if (loanInterest > 0) {
        this.calcBorrowingCapacity();
        this.calcInterest();
      }
    });
    // calcInterestFrm
    this.calcInterestFrm.valueChanges.subscribe((val) => {
      // update loanInterest
      this.loanInterest = val.loanInterest;
      this.loanMonthlyRate = val.loanInterest;
      const base = 1 + val.loanInterest;
      const exponent = 12;
      this.loanAnualRate = base * exponent - 1;
    });
    // singlePaymentFrm
    this.singlePaymentFrm.valueChanges.subscribe((val) => {
      // check amount
      if (typeof val.singlePayment === 'string') {
        // mask value
        const maskedVal = this.utilitiesSv.convertStringToCurrency(val.singlePayment);
        // check matched values
        if (val.singlePayment !== maskedVal) {
          this.singlePaymentFrm.patchValue({singlePayment: maskedVal});
        }
      }
      // check singlePayment
      if (val.singlePayment !== null) {
        // update singlePayment
        const singlePaymentVal: number = parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(val.singlePayment));
        this.singlePayment = Math.ceil(singlePaymentVal);
      }
    });
    // loanPaymentFrm
    this.loanPaymentFrm.valueChanges.subscribe((val) => {
      // check amount
      if (typeof val.paymentAmount === 'string') {
        // mask value
        const maskedVal = this.utilitiesSv.convertStringToCurrency(val.paymentAmount);
        // check matched values
        if (val.paymentAmount !== maskedVal) {
          this.loanPaymentFrm.patchValue({paymentAmount: maskedVal});
        }
      }
    });
  }
  // life cycle
  async ngOnInit() {
    this.translateMsgs();
  }
  async ngOnChanges(changes: SimpleChanges) {
    try {
      if (changes.loanNumber) {
        // check if loanData has data
        if (this.loanNumber !== null) {
          await this.loadData();
        }
      }
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async loadData() {
    // show loader
    this.loaderEvent.emit(this.translate.instant('HELPERS.loadingTxt'));
    // get data
    await this.clearForms();
    await this.clearData();
    await this.getUserData();
    await this.getLoanData();
    await this.getLoanStatusesData();
    await this.getLoanPaymentsData();
    await this.getProductData();
    await this.getUserWorkData();
    await this.getLoanDocumentsData();
    await this.getLoanUserData();
    await this.getUserAttachmentsData();
    await this.getUserBankAccounts();
    await this.getUserAddresses();
    // check companyId
    if (this.userWorkData.companyId !== null) {
      await this.getCompanyData();
    }
    // check otherCompanyId
    if (this.userWorkData.otherCompanyId !== null) {
      await this.getOtherCompanyData();
    }
    await this.getUserFinancialsData();
    await this.calcBorrowingCapacity();
    await this.calcInterest();
    await this.setMinMax();
    await this.setApprovalValues();
    await this.evaluateActions();
    // hide loader
    this.loaderEvent.emit(null);
  }
  // navigation
  async navigateTo(loanNumber: string) {
    // this.loanNumber = loanNumber;
    // await this.loadData();
    // navigate to page
    this.goToPage('loans/detail/' + loanNumber);
  }

  // TESTING
  async test() {
    // // set mglnkPromissoryData
    // const mglnkPromissoryData = {
    //   // set email
    //   email: this.loanUserData.email,
    //   // set link data
    //   module: 'documentos',
    //   submodule: 'pagare',
    //   id: this.loanNumber,
    // }
    // // set encodedMglnk
    // const encodedPromissoryMglnk = this.encodingSv.encodeBTOA(JSON.stringify(mglnkPromissoryData));
    // // set sendData
    // const sendData = {
    //   loanNumber: this.loanNumber,
    //   firstName: this.loanUserData.firstName,
    //   email:this.loanUserData.email,
    //   productName: this.productData !== null && this.productData.productName ? this.productData.productName : this.translate.instant('PRODUCT.productFree'),
    //   singlePayment: '$' + this.utilitiesSv.convertStringToCurrency((this.utilitiesSv.roundNumber(this.singlePayment, 'up', 0)).toString()),
    //   loanAmountApproved: '$' + this.utilitiesSv.convertStringToCurrency((this.utilitiesSv.roundNumber(this.loanAmountApproved, 'up', 0)).toString()),
    //   loanPaymentMonthsApproved: this.loanPaymentMonthsApproved,
    //   promissoryGeneratedFile: this.appUri + '/mglnk/' + encodedPromissoryMglnk,
    // }

    // console.log('encodedPromissoryMglnk', encodedPromissoryMglnk);
    // console.log('encodedMglnk', '/mglnk/' + encodedPromissoryMglnk);
    // console.log('sendData', sendData);


    // const loanPaymentStart = 39377102.26;
    // const singlePayment = 2353530.23;
    // const paymentInterestPercentageValue = 2.52;
    // const paymentInterestPercentage = paymentInterestPercentageValue / 100;
    // const futureValue = this.utilitiesSv.roundNumber((singlePayment * Math.pow(1 + paymentInterestPercentage, 1)), 'up', 0);

    // const loanPaymentInterest = this.utilitiesSv.roundNumber((loanPaymentStart * paymentInterestPercentage), 'up', 0);
    // const loanPaymentEnd = this.utilitiesSv.roundNumber((loanPaymentStart - (-loanPaymentInterest)), 'up', 0);

    // const leftPayments = 21;

    // // get new amount
    // const newAmount: number = loanPaymentEnd + futureValue;

    // console.log('singlePayment', singlePayment);
    // console.log('paymentInterestPercentageValue', paymentInterestPercentageValue);
    // console.log('paymentInterestPercentage', paymentInterestPercentage);
    // console.log('futureValue', futureValue);
    // console.log('loanPaymentInterest', loanPaymentInterest);
    // console.log('loanPaymentEnd', loanPaymentEnd);
    // console.log('leftPayments', leftPayments);
    // console.log('newAmount', newAmount);

    // // calc newSinglePayment
    // const numerator = newAmount * Math.pow((1 + (paymentInterestPercentageValue) / 100), leftPayments) * (paymentInterestPercentageValue) / 100;
    // const denominator = Math.pow((1 + (paymentInterestPercentageValue) / 100), leftPayments) - 1;
    // const newSinglePayment = this.utilitiesSv.roundNumber((numerator / denominator), 'up', 0);

    // const newSinglePayment = this.utilitiesSv.calculateLoanPayment(paymentInterestPercentageValue / 100, 0, (-loanPaymentEnd));

    // console.log('numerator:', numerator);
    // console.log('denominator:', denominator);
    // console.log('newSinglePayment:', newSinglePayment);

    // this.loanAmountApproved = 10000000;
    // this.loanPaymentMonthsApproved = 24;
    // this.loanInterest = 2.52;
    // this.singlePayment = 560364;
    
    // await this.createLoanPayments();


    // await this.updatePaymentDates('2023-08-18');


    this.loanUserData.email = 'idemshall@gmail.com'
    this.sendFirstSteps();


  }
  async createPaymentsManually() {
    // this.loanAmountApproved =  18000000;
    // this.loanPaymentMonthsApproved = 24;
    // this.loanInterest = 2.37;
    // this.singlePayment =  992033;
    
    await this.createLoanPayments();
  }
  async updatePaymentDatesManually() {
    // show alert to go next step
    Swal.fire({
      icon: 'success',
      title: 'Payment Dates',
      text: '',
      html: '<input type="date" id="swal-input1" class="swal2-input">',
      focusConfirm: false,
      preConfirm: () => {
        const input = document.getElementById('swal-input1') as HTMLInputElement;
        return input.value;
      },
      confirmButtonText: this.translate.instant('HELPERS.continue'),
      showCancelButton: true,
      cancelButtonText: this.translate.instant('HELPERS.cancel'), 
      customClass: {
        cancelButton: 'btn-swal-cancel'
      },
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const givenDate = result.value;
        this.updatePaymentDates(givenDate);
      }
    })

  }

  // translate
  async translateMsgs() {
    // update angular editor
    if (this.currentLang === 'es') {
      this.editorConfig = editorObservationGlobalConfigEs;
    } else {
      this.editorConfig = editorObservationGlobalConfigEn;
    }
    // update form error messages
    this.formErrorMessages = {
      score: [
        { type: 'required', message: this.translate.instant('LOAN.loanScoreError') },
        { type: 'scoreCustom', message: this.translate.instant('LOAN.loanScoreSavingRequired') },
        { type: 'minScore', message: this.translate.instant('LOAN.loanScoreMin') + ' ' + this.scoreMin },
        { type: 'maxScore', message: this.translate.instant('LOAN.loanScoreMax') + ' ' + this.scoreMax },
      ],
      borrowingCapacity: [
        { type: 'required', message: this.translate.instant('LOAN.loanBorrowingCapacityError') },
      ],
      singlePayment: [
        { type: 'required', message: this.translate.instant('LOAN.loanSinglePaymentError') },
      ],
      loanType: [
        { type: 'required', message: this.translate.instant('LOAN.loanTypeError') },
      ],
      loanInterest: [
        { type: 'required', message: this.translate.instant('LOAN.loanInterestError') },
      ],
      preAmount: [
        { type: 'required', message: this.translate.instant('LOAN.loanAmountPreApproveError') },
        { type: 'amountCustom', message: this.translate.instant('LOAN.requestAmountSavingRequired') },
        { type: 'minAmount', message: this.translate.instant('LOAN.requestAmountMin') + ' ' + this.utilitiesSv.convertStringToCurrency(this.amountMin.toString()) },
        { type: 'maxAmount', message: this.translate.instant('LOAN.requestAmountMax') + ' ' + this.utilitiesSv.convertStringToCurrency(this.amountMax.toString()) },
      ],
      preMonths: [
        { type: 'required', message: this.translate.instant('LOAN.loanMonthsPreApproveError') },
        { type: 'monthsCustom', message: this.translate.instant('LOAN.requestMonthsSavingRequired') },
        { type: 'minMonth', message: this.translate.instant('LOAN.requestMonthsMin') + ' ' + this.utilitiesSv.convertStringToCurrency(this.monthMin.toString()) + ' ' + this.translate.instant('HELPERS.months') },
        { type: 'maxMonth', message: this.translate.instant('LOAN.requestMonthsMax') + ' ' + this.utilitiesSv.convertStringToCurrency(this.monthMax.toString()) + ' ' + this.translate.instant('HELPERS.months') },
      ],
      amount: [
        { type: 'required', message: this.translate.instant('LOAN.loanAmountApproveError') },
        { type: 'amountCustom', message: this.translate.instant('LOAN.requestAmountSavingRequired') },
        { type: 'minAmount', message: this.translate.instant('LOAN.requestAmountMin') + ' ' + this.utilitiesSv.convertStringToCurrency(this.amountMin.toString()) },
        { type: 'maxAmount', message: this.translate.instant('LOAN.requestAmountMax') + ' ' + this.utilitiesSv.convertStringToCurrency(this.amountMax.toString()) },
      ],
      months: [
        { type: 'required', message: this.translate.instant('LOAN.loanMonthsApproveError') },
        { type: 'monthsCustom', message: this.translate.instant('LOAN.requestMonthsSavingRequired') },
        { type: 'minMonth', message: this.translate.instant('LOAN.requestMonthsMin') + ' ' + this.utilitiesSv.convertStringToCurrency(this.monthMin.toString()) + ' ' + this.translate.instant('HELPERS.months') },
        { type: 'maxMonth', message: this.translate.instant('LOAN.requestMonthsMax') + ' ' + this.utilitiesSv.convertStringToCurrency(this.monthMax.toString()) + ' ' + this.translate.instant('HELPERS.months') },
      ],
      noPassedValidationType: [
        { type: 'required', message: this.translate.instant('LOAN.loanNoPassedValidationError') },
      ],
      rejectType: [
        { type: 'required', message: this.translate.instant('LOAN.loanRejectError') },
      ],
      paymentNumber: [
        { type: 'required', message: this.translate.instant('LOANPAYMENTS.loanPaymentNumberError') },
      ],
      paymentAmount: [
        { type: 'required', message: this.translate.instant('LOANPAYMENTS.loanPaymentAmountError') },
        { type: 'minPayment', message: this.translate.instant('LOANPAYMENTS.loanPaymentAmountMin') },
      ],
      paymentInterestPercentage: [
        { type: 'required', message: this.translate.instant('LOANPAYMENTS.paymentInterestPercentageError') },
        { type: 'min', message: this.translate.instant('LOANPAYMENTS.paymentInterestPercentageError') },
      ],
    };
  }
  // loader
  async updateLoader(loaderMsg: any) {
    this.loaderEvent.emit(loaderMsg);
  }
  // form
  async clearForms() {
    this.observationFrm.reset();
    this.scoreFrm.reset();
    this.scoreFrm.controls.score.setValue(this.scoreMin);
    this.borrowingCapacityFrm.reset();
    this.preApprovalFrm.reset();
    this.approvalFrm.reset();
    this.calcInterestFrm.reset();
    this.singlePaymentFrm.reset();
    this.noPassedValidationFrm.reset();
    this.rejectFrm.reset();
    this.loanPaymentFrm.reset();
  }
  async clearData() {
    // user data
    this.userData = null;
    this.userId = null;
    // role variables
    this.isAdmin = false;
    this.isFinancial = false;
    this.isCompany = false;
    this.isClient = false;
    // tab data
    this.currentTab = 'request';
    // loan data
    this.loanId = null;
    this.loanType = null;
    this.loanCode = null;
    this.loanSinglePayment = 0;
    this.loanInterest = 0;
    this.loanAdditionalInfoConsulted = false;
    this.loanMonthMin = 0;
    this.loanMonthMax = 0;
    this.loanMonthSteps = 0;
    this.loanAmountMin = 0;
    this.loanAmountMax = 0;
    this.loanAmountMaxLimit = true;
    this.loanMonthlyRate = 0;
    this.loanAnualRate = 0;
    this.loanReferralMinimum = 0;
    this.loanPaymentReceipts = 0;
    this.borrowingCapacityPercentage = 0;
    this.borrowingCapacity = 0;
    this.loanRequestStep = 0;
    this.loanStatusLast = null;
    this.loanStatusDateLast = null;
    this.loanProfileValidation = false;
    this.loanSynthesisValidation = false;
    this.loanDetailedValidation = false;
    this.loanPromissoryLoaded = false;
    this.loanManagerSignature = false;
    this.loanValidationScore = 0;
    this.loanInsuranceIds = null;
    // score data
    this.scoreMin = 150;
    this.scoreMax = 950;
    this.scoreSection = (this.scoreMax - this.scoreMin) / 9;
    this.score = 150;
    this.scoreClass = null;
    // salary data
    this.salary = 0;
    // requested data
    this.loanAmount = 0;
    this.loanPaymentMonths = 0;
    // pre-approved data
    this.loanAmountPreApproved = 0;
    this.loanPaymentMonthsPreApproved = 0;
    // company approved data
    this.companyAmount = 0;
    this.companyAmountMin = 100000;
    this.companyAmountMax = 100000000;
    this.companyAmountCustom = false;
    this.companyMonths = 0;
    this.companyMonthMin = 6;
    this.companyMonthMax = 48;
    this.companyMonthSteps = 3;
    this.companyMonthsCustom = false;
    this.loanAmountApprovedCompany = 0;
    this.loanPaymentMonthsApprovedCompany = 0;
    // approved data
    this.amount = 0;
    this.amountMin = 100000;
    this.amountMax = 100000000;
    this.amountCustom = false;
    this.months = 0;
    this.monthMin = 6;
    this.monthMax = 48;
    this.monthSteps = 3;
    this.monthsCustom = false;
    this.loanAmountApproved = 0;
    this.loanPaymentMonthsApproved = 0;
    // loan payments data
    this.loanPayments = [];
    // loan statuses data
    this.loanStatuses = [];
    this.lastStatus = null;
    this.lastStatusNumber = 0;
    this.statuses = environment.statuses;
    // payment data
    this.singlePayment = 0;
    this.singlePaymentCustom = false;
    this.singlePaymentCalculated = false;
    this.monthlyEffectiveRate = 0;
    // company data
    this.companyId = null;
    this.companyData = null;
    this.businessName = null;
    this.companyApproval = null;
    this.companyAmountEdit = null;
    // product data
    this.productId = null;
    this.productData = null;
    this.productAmountMin = 100000;
    this.productAmountMax = 100000000;
    this.productAmountMaxLimit = false;
    this.productMonthMin = 6;
    this.productMonthMax = 48;
    // loan user data
    this.loanUserData = null;
    // user work data
    this.userWorkId = null;
    this.userWorkData = null;
    // user address data
    this.userAddressesData = [];
    // user financial totals data
    this.financialData = null;
    // user bank account data
    this.userBankAccounts = [];
    // bank data
    this.banks = [];
    this.allBanks = [];
    // files data
    this.idFrontFileData = null;
    this.idBackFileData = null;
    this.requestFileData = null;
    this.bankFileData = null;
    this.laborFileData = null;
    this.promissoryFileData = null;
    this.authorizationFileData = null;
    this.disbursementFileData = null;
    this.acceptanceFileData = null;
    this.companyPromissoryFileData = null;
    this.companyPromissoryUploaded = false;
    this.profileFileData = null;
    this.synthesisFileData = null;
    this.detailedFileData = null;
    this.paymentReceiptFiles = [];
    this.rutFileData = null; 
    this.ccioFileData = null; 
    this.repIdFileData = null; 
    this.pgFileData = null; 
    this.bankExtractsFileData = null; 
    this.pensionerFileData = null; 
    this.utilitiesFileData = null;
    this.otherDocument1FileData = null;
    this.otherDocument2FileData = null;
    this.otherDocument3FileData = null;
  }
  async help () {
    if (this.toggleHelp) {
      this.toggleHelp = false;
    } else {
      this.toggleHelp = true;
    }
  }
  async setFocus(elementId: any) {
    elementId.focus();
  }
  checkFormField(fieldName: string) {
    // get field
    const field = this.approvalFrm.get(fieldName);
    // check field
    const check = field?.invalid && field?.touched;
    // return data
    return check;
  }
  checkPreAmount() {
    // get preAmount
    const preAmount: any = this.preApprovalFrm.controls.preAmount.value;
    // check value
    if (preAmount !== null) {
      // get currentAmount
      const currentAmount: number = typeof preAmount === 'string' ? parseInt(this.utilitiesSv.removeDotsFromCurrencyString(preAmount)) : preAmount;
      // set newAmountMin && newAmountMax
      const newAmountMin = this.amountMin;
      const newAmountMax = this.amountMax;
      // check amountMin
      if (currentAmount < newAmountMin) {
        // show amountMinError
        this.amountMinError = true;
      }
      // check amountMax
      if (currentAmount > newAmountMax) {
        // show amountMaxError
        this.amountMaxError = true;
      }
    }
  }
  checkPreMonths() {
    // get preMonths
    const preMonths: number = this.preApprovalFrm.controls.preMonths.value;
    // check value
    if (preMonths !== null) {
      // set newMonthMin && newMonthMax
      const newMonthMin = this.monthMin;
      const newMonthMax = this.monthMax;
      // check monthMin
      if (preMonths < newMonthMin) {
        // show monthMinError
        this.monthMinError = true;
      }
      // check monthMax
      if (preMonths > newMonthMax) {
        // show monthMaxError
        this.monthMaxError = true;
      }
    }
  }
  checkAmount() {
    // get amount
    const amount: any = this.approvalFrm.controls.amount.value;
    // check value
    if (amount !== null) {
      // get currentAmount
      const currentAmount: number = typeof amount === 'string' ? parseInt(this.utilitiesSv.removeDotsFromCurrencyString(amount)) : amount;
      // set newAmountMin & newAmountMax
      const newAmountMin = this.amountMin;
      const newAmountMax = this.amountMax;
      // check amountMin
      if (currentAmount < newAmountMin) {
        // show amountMinError
        this.amountMinError = true;
      }
      // check amountMax
      if (currentAmount > newAmountMax) {
        // show amountMaxError
        this.amountMaxError = true;
      }
    }
  }
  checkMonths() {
    // get months
    const months: number = this.approvalFrm.controls.months.value;
    // check value
    if (months !== null) {
      // set newMonthMin && newMonthMax
      const newMonthMin = this.monthMin;
      const newMonthMax = this.monthMax;
      // check monthMin
      if (months < newMonthMin) {
        // show monthMinError
        this.monthMinError = true;
      }
      // check monthMax
      if (months > newMonthMax) {
        // show monthMaxError
        this.monthMaxError = true;
      }
    }
  }
  checkSinglePayment() {
    // get singlePayment
    const singlePayment: any = this.singlePaymentFrm.controls.singlePayment.value;
    // check value
    if (singlePayment !== null) {
      // get currentSinglePayment
      const currentSinglePayment: number = typeof singlePayment === 'string' ? parseInt(this.utilitiesSv.removeDotsFromCurrencyString(singlePayment)) : singlePayment;
      // set singlePaymentMin & singlePaymentMax
      const singlePaymentMin = this.singlePaymentMin;
      const singlePaymentMax = this.amountMax;
      // check singlePaymentMin
      if (currentSinglePayment < singlePaymentMin) {
        // show singlePaymentMinError
        this.singlePaymentMinError = true;
      }
      // check singlePaymentMax
      if (currentSinglePayment > singlePaymentMax) {
        // show singlePaymentMaxError
        this.singlePaymentMaxError = true;
      }
    }
  }
  checkMonthMin() {
    return (control: FormControl) => {
      // check value
      if (control.value !== null) {
        // get currentMonth
        const currentMonth: number = control.value;
        // set newMonthMin
        const newMonthMin = this.monthMin;
        // check monthMin
        if (currentMonth < newMonthMin) {
          return { minMonth: true }
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }
  checkMonthMax() {
    return (control: FormControl) => {
      // check value
      if (control.value !== null) {
        // get currentMonth
        const currentMonth: number = control.value;
        // set newMonthMax
        const newMonthMax = this.monthMax;
        // check monthMax
        if (currentMonth > newMonthMax) {
          return { maxMonth: true }
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }
  checkMinValue(checkValue: number) {
    return (control: FormControl) => {
      // check value
      if (control.value !== null) {
        // get currentValue
        const currentValue: any = typeof control.value === 'string' ? this.utilitiesSv.removeDotsFromCurrencyString(control.value) :control.value;
        // check currentValue
        if (currentValue < checkValue && currentValue !== '') {
          return { minValue: true }
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }
  checkMaxValue(checkValue: number) {
    return (control: FormControl) => {
      // check value
      if (control.value !== null) {
        // get currentValue
        const currentValue: any = typeof control.value === 'string' ? parseInt(this.utilitiesSv.removeDotsFromCurrencyString(control.value)) :control.value;
        // check amountMax
        if (currentValue > checkValue && currentValue !== '') {
          return { maxValue: true }
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }
  checkAmountCustom() {
    return (control: FormControl) => {
      // check value
      if (control.value !== null) {
        // check amountCustom
        if (this.amountCustom) {
          return { amountCustom: true }
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }
  checkMonthCustom() {
    return (control: FormControl) => {
      // check value
      if (control.value !== null) {
        // check monthsCustom
        if (this.monthsCustom) {
          return { monthsCustom: true }
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }
  checkScoreMin() {
    return (control: FormControl) => {
      // check value
      if (control.value !== null) {
        // check score min
        if (control.value < this.scoreMin) {
          return { minScore: true }
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }
  checkScoreMax() {
    return (control: FormControl) => {
      // check value
      if (control.value !== null) {
        // check score max
        if (control.value > this.scoreMax) {
          return { maxScore: true }
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }
  checkScoreCustom() {
    return (control: FormControl) => {
      // check value
      if (control.value !== null) {
        // check customScore
        if (this.customScore) {
          return { scoreCustom: true }
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }
  checkPaymentMin() {
    return (control: FormControl) => {
      // check value
      if (control.value !== null) {
        // get currentPayment
        const currentPayment: number = typeof control.value === 'string' ? this.utilitiesSv.removeDotsFromCurrencyString(control.value) :control.value;
        // get minimumPaymentPercentage
        const minimumPaymentPercentage = (this.loanPayment.loanPaymentAmount * 10) / 100;
        // check amountMin
        if (currentPayment < minimumPaymentPercentage) {
          return { minPayment: true }
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }
  // navigation
  async toggleCustomPreAmount() {
    // get currentAmount
    const currentAmount: number = typeof this.preApprovalFrm.controls.preAmount.value === 'string' ? parseInt(this.utilitiesSv.removeDotsFromCurrencyString(this.preApprovalFrm.controls.preAmount.value)) : this.preApprovalFrm.controls.preAmount.value;
    // set amountMin and amountMax
    const amountMin = this.amountMin;
    const amountMax = this.amountMax;
    // check amountMin & amountMax
    if (currentAmount >= amountMin && currentAmount <= amountMax) {
      // check amountCustom
      if (this.amountCustom === true) {
        const newAmount = this.preApprovalFrm.controls.preAmount.value;
        const amountVal: number = parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(newAmount));
        this.amount = amountVal;
        this.preApprovalFrm.controls.preAmount.setValue(amountVal);
        // hide amountCustom
        this.amountCustom = false;
      } else {
        const amountInt = Math.ceil(this.amount);
        const maskedVal = this.utilitiesSv.convertStringToCurrency(amountInt.toString());
        this.preApprovalFrm.patchValue({preAmount: maskedVal});
        // show amountCustom
        this.amountCustom = true;
        // wait a moment
        setTimeout(() => {
          // set focus on input
          this.preAmountInput.nativeElement.focus();
          // select input content
          this.preAmountInput.nativeElement.select();
        }, 100);
      }
    } else {
      // send alert
      const alertMin = this.translate.instant('LOAN.loanPreApproveAmountMin') + ' $' + this.utilitiesSv.convertStringToCurrency(this.amountMin.toString());
      const alertMax = this.translate.instant('LOAN.loanPreApproveAmountMaxAnd') + ' $' + this.utilitiesSv.convertStringToCurrency(this.amountMax.toString());
      const alertMsg = alertMin + ', ' + alertMax;
      this.alertSv.showMessage(alertMsg,'warning', this.translate.instant('HELPERS.warning'), true);
    }
  }
  async toggleCustomAmount() {
    // get currentAmount
    const currentAmount: number = typeof this.approvalFrm.controls.amount.value === 'string' ? parseInt(this.utilitiesSv.removeDotsFromCurrencyString(this.approvalFrm.controls.amount.value)) : this.approvalFrm.controls.amount.value;
    // set amountMin and amountMax
    const amountMin = this.amountMin;
    const amountMax = this.amountMax;
    // check amountMin & amountMax
    if (currentAmount >= amountMin && currentAmount <= amountMax) {
      // check amountCustom
      if (this.amountCustom === true) {
        const newAmount = this.approvalFrm.controls.amount.value;
        const amountVal: number = parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(newAmount));
        this.amount = amountVal;
        this.approvalFrm.controls.amount.setValue(amountVal);
        // hide amountCustom
        this.amountCustom = false;
      } else {
        const amountInt = Math.ceil(this.amount);
        const maskedVal = this.utilitiesSv.convertStringToCurrency(amountInt.toString());
        this.approvalFrm.patchValue({amount: maskedVal});
        // show amountCustom
        this.amountCustom = true;
        // wait a moment
        setTimeout(() => {
          // set focus on input
          this.amountInput.nativeElement.focus();
          // select input content
          this.amountInput.nativeElement.select();
        }, 100);
      }
    } else {
      // send alert
      const alertMin = this.translate.instant('LOAN.loanApproveAmountMin') + ' $' + this.utilitiesSv.convertStringToCurrency(this.amountMin.toString());
      const alertMax = this.translate.instant('LOAN.loanApproveAmountMaxAnd') + ' $' + this.utilitiesSv.convertStringToCurrency(this.amountMax.toString());
      const alertMsg = alertMin + ', ' + alertMax;
      this.alertSv.showMessage(alertMsg,'warning', this.translate.instant('HELPERS.warning'), true);
    }
  }
  async toggleCustomMonths(form: string) {
    // init currentMonth
    let currentMonth: number = 0;
    // check form
    if (form === 'preApprovalFrm') {
      currentMonth = this.preApprovalFrm.controls.preMonths.value;
    } else {
      currentMonth = this.approvalFrm.controls.months.value;
    }
    // set monthMin & monthMax
    const monthMin = this.monthMin;
    const monthMax = this.monthMax;
    // check monthMax
    if (currentMonth >= monthMin && currentMonth <= monthMax) {
      // check monthsCustom
      if (this.monthsCustom === true) {
        this.monthsCustom = false;
      } else {
        this.monthsCustom = true;
        // wait a moment
        setTimeout(() => {
          // check form
          if (form === 'preApprovalFrm') {
            // set focus on input
            this.preMonthsInput.nativeElement.focus();
            // select input content
            this.preMonthsInput.nativeElement.select();
          } else {
            // set focus on input
            this.monthsInput.nativeElement.focus();
            // select input content
            this.monthsInput.nativeElement.select();
          }
        }, 100);
      }
    } else {
      // send alert
      const alertMin = this.translate.instant('LOAN.requestMonthsMin') + ' ' + this.monthMin;
      const alertMax = this.translate.instant('LOAN.requestMonthsMaxAnd') + ' ' + this.monthMax;
      const alertMsg = alertMin + ', ' + alertMax;
      this.alertSv.showMessage(alertMsg,'warning', this.translate.instant('HELPERS.warning'), true);
    }
  }
  async toggleCompanyCustomAmount() {
    // get currentAmount
    const currentAmount: number = typeof this.preApprovalFrm.controls.preAmount.value === 'string' ? parseInt(this.utilitiesSv.removeDotsFromCurrencyString(this.preApprovalFrm.controls.preAmount.value)) : this.preApprovalFrm.controls.preAmount.value;
    // set amountMin and amountMax
    const amountMin = this.amountMin;
    const amountMax = this.amountMax;
    // check amountMin & amountMax
    if (currentAmount >= amountMin && currentAmount <= amountMax) {
      this.companyAmountCustom = this.companyAmountCustom === true ? false : true;
    } else {
      // send alert
      const alertMin = this.translate.instant('LOAN.loanPreApproveAmountMin') + ' $' + this.utilitiesSv.convertStringToCurrency(this.amountMin.toString());
      const alertMax = this.translate.instant('LOAN.loanPreApproveAmountMaxAnd') + ' $' + this.utilitiesSv.convertStringToCurrency(this.amountMax.toString());
      const alertMsg = alertMin + ', ' + alertMax;
      this.alertSv.showMessage(alertMsg,'warning', this.translate.instant('HELPERS.warning'), true);
    }
  }
  async toggleCompanyCustomMonths() {
    // get currentMonth
    const currentMonth: number = this.approvalFrm.controls.months.value;
    // set monthMin & monthMax
    const monthMin = this.monthMin;
    const monthMax = this.monthMax;
    // check monthMax
    if (currentMonth >= monthMin && currentMonth <= monthMax) {
      this.companyMonthsCustom = this.companyMonthsCustom === true ? false : true;
    } else {
      // send alert
      const alertMsg = this.translate.instant('LOAN.requestMonthsMax') + ' ' + this.utilitiesSv.convertStringToCurrency(this.monthMax.toString()) + ' ' + this.translate.instant('HELPERS.months');
      this.alertSv.showMessage(alertMsg,'warning', this.translate.instant('HELPERS.warning'), true);
    }
  }
  async toggleCustomSinglePayment() {
    // check singlePaymentCustom
    if (this.singlePaymentCustom === true) {
      const newSinglePayment = this.singlePaymentFrm.controls.singlePayment.value;
      const singlePaymentVal: number = parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(newSinglePayment));
      this.singlePayment = Math.ceil(singlePaymentVal);
      // hide singlePaymentCustom
      this.singlePaymentCustom = false;
    } else {
      const singlePaymentInt = Math.ceil(this.singlePayment);
      const maskedVal = this.utilitiesSv.convertStringToCurrency(singlePaymentInt.toString());
      this.singlePaymentFrm.patchValue({singlePayment: maskedVal});
      // show singlePaymentCustom
      this.singlePaymentCustom = true;
    }
  }
  async toggleCustomScore() {
    // get currentScore
    const currentScore: number = this.scoreFrm.controls.score.value;
    // set scoreMin & scoreMax
    const scoreMin = 150;
    const scoreMax = 950;
    // check monthMax
    if (currentScore >= scoreMin && currentScore <= scoreMax) {
      this.customScore = this.customScore === true ? false : true;
    } else {
      // send alert
      const alertMin = this.translate.instant('LOAN.loanScoreMin') + ' ' + scoreMin;
      const alertMax = this.translate.instant('LOAN.loanScoreMaxAnd') + ' ' + scoreMax;
      const alertMsg = alertMin + ', ' + alertMax;
      this.alertSv.showMessage(alertMsg,'warning', this.translate.instant('HELPERS.warning'), true);
    }  
  }
  async selectTab(tab: string) {
    // update tab
    this.currentTab = tab;
  }
  goToByScroll(id: any) {
    // Remove "link" from the ID
    id = id.replace("link", "");
    // Scroll
    $('html,body').animate({
      scrollTop: $("#" + id).offset().top - 60
    }, 'slow');
  }
  async goToPage(page: string, params?: any | null){
    // navigate to page
    this.utilitiesSv.goTo(page, true, params);
  }
  // user
  async getUserData() {
    // get stored data user
    const storedDataUser: any = localStorage.getItem('userData');
    // get data user
    const userData: UserInterface = JSON.parse(storedDataUser);
    // check user data
    if (userData) {
      // set data user
      this.userData = userData;
      // check roles
      const checkAdmin = await this.utilitiesSv.checkRoles(userData.role, ['support', 'administrator']);
      this.isAdmin = checkAdmin;
      const checkFinancial = await this.utilitiesSv.checkRoles(userData.role, ['support', 'administrator', 'board', 'treasure', 'businessAgent']);
      this.isFinancial = checkFinancial;
      const checkCompany = await this.utilitiesSv.checkRoles(userData.role, ['ceo', 'manager', 'auxiliar']);
      this.isCompany = checkCompany;
    }
  }
  // loans
  async getLoanData() {
    return new Promise(async (resolve, reject) => {
      try {
        // show loader
        this.loaderEvent.emit(this.translate.instant('HELPERS.loadingTxt') + ' ' + this.translate.instant('LOAN.loan'));
        // get loanNumber
        const loanNumber: any = this.loanNumber;
        // check loan id
        if (loanNumber !== null) {
          // get data from api
          await this.apiSv.getLoan(loanNumber).then(async (loanResponse: any)=>{
            // get loan
            const loan: LoanInterface = loanResponse.data;
            // set loan data
            this.loanId = loan.id;
            this.loanCode = loan.loanCode;
            this.loanAdditionalInfoConsulted = loan.loanAdditionalInfoConsulted;
            this.loanAmountMin = loan.loanAmountMin;
            this.loanAmountMax = loan.loanAmountMax;
            this.loanMonthMin = loan.loanMonthMin;
            this.loanMonthMax = loan.loanMonthMax;
            this.loanMonthSteps = loan.loanMonthSteps;
            this.loanAmountMaxLimit = loan.loanAmountMaxLimit;
            this.loanReferralMinimum = loan.loanReferralMinimum;
            this.loanRequestStep = loan.loanRequestStep;
            this.loanProfileValidation = loan.loanProfileValidation;
            this.loanSynthesisValidation = loan.loanSynthesisValidation;
            this.loanDetailedValidation = loan.loanDetailedValidation;
            this.loanPromissoryLoaded = loan.loanPromissoryLoaded;
            this.loanManagerSignature = loan.loanManagerSignature;
            this.loanValidationScore = loan.loanValidationScore;
            this.loanInsuranceIds = loan.loanInsuranceIds;
            this.userWorkId = loan.userWorkId;
            this.productId = loan.productId;
            // get navigation data
            await this.apiSv.getLoanNavigation(loan.id).then(async (navigationResponse: any)=>{
              this.previousLoan = navigationResponse.data.next === null ? null : navigationResponse.data.next.loanNumber;
              this.nextLoan = navigationResponse.data.previous === null ? null : navigationResponse.data.previous.loanNumber;
            });
            // set loanPaymentReceipts
            this.loanPaymentReceipts = loan.loanPaymentReceipts;
            // get borrowingCapacity
            this.borrowingCapacityPercentage = loan.borrowingCapacityPercentage;
            this.borrowingCapacity = loan.borrowingCapacity;
            // get interests
            this.loanInterest = loan.loanInterest;
            if (loan.loanInterest > 0) {
              this.singlePaymentCalculated = true;
            }
            this.loanMonthlyRate = loan.loanMonthlyRate;
            this.loanAnualRate = loan.loanAnualRate;
            // get loanStatus data
            const loanStatus = loan.loanStatusLast;
            const loanStatusDate = loan.loanStatusDateLast;
            // set loanStatus data
            this.lastStatus = loanStatus;
            this.loanStatusLast = loanStatus;
            this.loanStatusDateLast = loanStatusDate;
            // calcStatus
            await this.calcStatus();
            // update score
            this.score = loan.loanValidationScore < this.scoreMin ? this.scoreMin : loan.loanValidationScore;
            // calcScore
            await this.calcScore();
            // update borrowingCapacity form data
            this.borrowingCapacityFrm.controls.borrowingCapacity.setValue(loan.borrowingCapacityPercentage);
            // update loanType
            this.loanType = loan.loanType;
            // check loanType
            if (loan.loanType !== 'unset') {
              this.calcInterestFrm.controls.loanType.setValue(loan.loanType);
            } else {
              this.calcInterestFrm.controls.loanType.setValue('fixedInterest');
            }
            // check loanInterest
            if (loan.loanInterest > 0) {
              // update calcInterest form data
              this.calcInterestFrm.controls.loanInterest.setValue(loan.loanInterest);
            }
            // set loan amounts
            this.loanSinglePayment = loan.loanSinglePayment;
            this.singlePayment = loan.loanSinglePayment;
            this.loanAmount = loan.loanAmount;
            this.loanPaymentMonths = loan.loanPaymentMonths;
            this.loanAmountApproved = loan.loanAmountApproved;
            this.loanPaymentMonthsApproved = loan.loanPaymentMonthsApproved;
            this.loanAmountPreApproved = loan.loanAmountPreApproved;
            this.loanPaymentMonthsPreApproved = loan.loanPaymentMonthsPreApproved;
            this.loanAmountApprovedCompany = loan.loanAmountApprovedCompany;
            this.loanPaymentMonthsApprovedCompany = loan.loanPaymentMonthsApprovedCompany;
            // resolve
            resolve(true);
          }, error=>{
            console.log('error', error);
            reject(error);
          });
        }
      } catch (error) {
        console.log('error', error);
        // hide loader
        this.loaderEvent.emit(null);
        reject(error);
      }
    });
  }
  // loan statuses
  async getLoanStatusesData() {
    return new Promise(async (resolve, reject) => {
      try {
        // show loader
        this.loaderEvent.emit(this.translate.instant('HELPERS.loadingTxt') + ' ' + this.translate.instant('LOAN.loanStatuses'));
        // get data from api
        await this.apiSv.getLoanStatuses(this.loanId).then(async (loanStatusesResponse: any)=>{
          // get loanStatuses
          const loanStatuses: any = loanStatusesResponse.data;

          console.log('loanStatuses', loanStatuses);

          // loop loan statuses
          for (let index = 0; index < loanStatuses.length; index++) {
            // set loanStatus
            const loanStatus: LoanStatusInterface = loanStatuses[index];
            // set loanStatusData
            const loanStatusData: any = {
              loanId: this.loanId,
              user: loanStatus.user,
              loanStatus: loanStatus.loanStatus,
              loanStatusObservations: loanStatus.loanStatusObservations,
              loanStatusDate: loanStatus.createdAt,
              loanNoPassedValidationRejectOption: loanStatus.loanNoPassedValidationRejectOption,
            }
            // push loanStatusData
            this.loanStatuses.push(loanStatusData);
          }
          // resolve
          resolve(true);
        }, error=>{
          console.log('error', error);
          reject(error);
        });
      } catch (error) {
        console.log('error', error);
        // hide loader
        this.loaderEvent.emit(null);
        reject(error);
      }
    });
  }
  async saveLoanStatus(status: string, statusNumber: number, rejectOption?: string) {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // get currentDateTime
      const currentDateTime = moment().toISOString();
      // get observation
      let observation: string = null;
      // check statusNumber
      if (statusNumber === 3) {
        observation = this.noPassedValidationFrm.controls.noPassedValidationMsg.value;
      } else if (statusNumber === 5) {
        observation = this.rejectFrm.controls.rejectMsg.value;
      } else {
        observation = this.observationFrm.controls.observation.value;
      }
      // check if observation is not null
      if (observation !== null) {
        // fix observation fontsize
        observation = this.utilitiesSv.replaceStringOnString(observation, '<font size="3">', '<font size="2">');
        // check if there is not font size parameter
        if (!observation.includes('<font size="2">')) {
          observation = '<font size="2">' + observation + '</font>';
        }
      }
      // set loanListStatusData
      const loanListStatusData: LoanStatusInterface = {
        loanId: this.loanId,
        user: this.userData,
        loanStatus: status,
        loanStatusObservations: observation,
        loanStatusDate: currentDateTime,
        loanNoPassedValidationRejectOption: rejectOption
      }
      // push new status to loan statuses
      this.loanStatuses.push(loanListStatusData);
      // set loanStatusData
      const loanStatusData: LoanStatusInterface = {
        loanId: this.loanId,
        userId: this.userData.id,
        loanStatus: status,
        loanStatusObservations: observation,
        loanNoPassedValidationRejectOption: rejectOption
      }
      // add loanStatus
      await this.apiSv.addLoanStatus(loanStatusData);
      // set loanData
      const loanData: LoanInterface = {
        loanStatusLast: status,
        loanStatusDateLast: currentDateTime,
      }
      // update loanStatus on loan
      await this.apiSv.updateLoan(this.loanId, loanData);
      // update local loanStatus
      this.lastStatus = status;
      this.lastStatusNumber = statusNumber;
      // reset observationFrm
      this.observationFrm.controls.observation.setValue(null);
      this.observationFrm.markAsUntouched();
      // set loanStatusUpdateData
      const loanStatusUpdateData = {
        loanId: this.loanId,
        loanStatus: status,
        loanStatusNumber: statusNumber,
        companyApproval: this.companyApproval
      }
      // actions
      await this.clearActions();
      await this.evaluateActions();
      // update loan status
      this.saveEvent.emit(loanStatusUpdateData);
      // hide loader
      this.loaderEvent.emit(null);
    } catch (error) {
      console.log('error', error);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  // loan documents
  async getLoanDocumentsData() {
    return new Promise(async (resolve, reject) => {
      try {
        // show loader
        this.loaderEvent.emit(this.translate.instant('HELPERS.loadingTxt') + ' ' + this.translate.instant('LOAN.loanDocuments'));
        // set loanDocumentData
        const loanDocumentData = {
          limit: 100000,
          page: 1,
          loanId: this.loanId,
          loanDocumentType: null,
        }
        // get data from api
        await this.apiSv.getLoanDocuments(loanDocumentData).then(async (loanDocumentResponse: any)=>{
          // get loanDocuments
          const loanDocuments: any = loanDocumentResponse.data.result;
          // clear data
          this.requestFileData = null;
          this.laborFileData = null;
          this.promissoryFileData = null;
          this.authorizationFileData = null;
          this.disbursementFileData = null;
          this.acceptanceFileData = null;
          this.companyPromissoryFileData = null;
          this.profileFileData = null;
          this.synthesisFileData = null;
          this.detailedFileData = null;
          this.companyPromissoryUploaded = false;
          // clear paymentReceiptFiles
          this.paymentReceiptFiles = [];
          // check loanDocuments
          if (loanDocuments && loanDocuments.length > 0) {
            // init paymentReceiptsLoaded
            let paymentReceiptsLoaded: number = 0;
            // loop userAttachments
            for (let i = 0; i < loanDocuments.length; i++) {
              // get loanDocument
              const loanDocument: LoanDocumentInterface = loanDocuments[i];
              // get loanDocumentType
              const loanDocumentType = loanDocument.loanDocumentType;
              // get fileExtension
              const fileExtension = this.mediaSv.getFileExtension(loanDocument.loanDocument);
              // get fileType
              const fileType = this.mediaSv.getFileType(fileExtension);
              // get fileName
              const fileName = this.mediaSv.getFileNameAndExtension(loanDocument.loanDocument);
              // check documentType
              switch (loanDocumentType) {
                case 'request':
                  // set requestFileData
                  const requestFileData = {
                    id: loanDocument.id,
                    parentId: loanDocument.loanId,
                    docType: loanDocumentType,
                    file: null,
                    fileId: loanDocument.id,
                    fileType: fileType,
                    fileName: fileName,
                    filePath: loanDocument.loanDocument,
                    fileKey: loanDocument.fileKey,
                    fileDate: loanDocument.update_at,
                    title: this.translate.instant('LOAN.loanDocumentRequest'),
                    image: 'assets/svg/icon-document-request.svg',
                    showDropZone: false,
                  }
                  // update requestFileData
                  this.requestFileData = requestFileData;
                  break;
                case 'labor':
                  // set laborFileData
                  const laborFileData = {
                    id: loanDocument.id,
                    parentId: loanDocument.loanId,
                    docType: loanDocumentType,
                    file: null,
                    fileId: loanDocument.id,
                    fileType: fileType,
                    fileName: fileName,
                    filePath: loanDocument.loanDocument,
                    fileKey: loanDocument.fileKey,
                    fileDate: loanDocument.update_at,
                    title: this.translate.instant('LOAN.loanDocumentLabor'),
                    image: 'assets/svg/icon-document-labor.svg',
                    showDropZone: false,
                  }
                  // update laborFileData
                  this.laborFileData = laborFileData;
                  break;
                case 'paymentReceipts':
                  // add paymentReceiptsLoaded cant
                  paymentReceiptsLoaded ++;
                  // set loanDocumentPaymentReceiptData
                  const loanDocumentPaymentReceiptData = {
                    id: loanDocument.id,
                    parentId: loanDocument.loanId,
                    docType: loanDocumentType,
                    file: null,
                    fileId: loanDocument.id,
                    fileType: fileType,
                    fileName: fileName,
                    filePath: loanDocument.loanDocument,
                    fileKey: loanDocument.fileKey,
                    fileDate: loanDocument.update_at,
                    title: this.translate.instant('LOAN.loanDocumentPaymentReceipt') + '-' + paymentReceiptsLoaded,
                    image: 'assets/svg/icon-document-payment-receipt.svg',
                    showDropZone: false,
                  }
                  // push paymentReceiptsFileData
                  this.paymentReceiptFiles.push(loanDocumentPaymentReceiptData);
                  break;
                case 'promissory':
                  // set promissoryFileData
                  const promissoryFileData = {
                    id: loanDocument.id,
                    parentId: loanDocument.loanId,
                    docType: loanDocumentType,
                    file: null,
                    fileId: loanDocument.id,
                    fileType: fileType,
                    fileName: fileName,
                    filePath: loanDocument.loanDocument,
                    fileKey: loanDocument.fileKey,
                    fileDate: loanDocument.update_at,
                    title: this.translate.instant('LOAN.loanDocumentPromissory'),
                    image: 'assets/svg/icon-document-promissory.svg',
                    showDropZone: false,
                  }
                  // update promissoryFileData
                  this.promissoryFileData = promissoryFileData;
                  break;
                case 'authorization':
                  // set authorizationFileData
                  const authorizationFileData = {
                    id: loanDocument.id,
                    parentId: loanDocument.loanId,
                    docType: loanDocumentType,
                    file: null,
                    fileId: loanDocument.id,
                    fileType: fileType,
                    fileName: fileName,
                    filePath: loanDocument.loanDocument,
                    fileKey: loanDocument.fileKey,
                    fileDate: loanDocument.update_at,
                    title: this.translate.instant('LOAN.loanDocumentAuthorization'),
                    image: 'assets/svg/icon-document-authorization.svg',
                    showDropZone: false,
                  }
                  // update authorizationFileData
                  this.authorizationFileData = authorizationFileData;
                  this.loanDetailedValidation = true;
                  break;
                case 'disbursement':
                  // set disbursementFileData
                  const disbursementFileData = {
                    id: loanDocument.id,
                    parentId: loanDocument.loanId,
                    docType: loanDocumentType,
                    file: null,
                    fileId: loanDocument.id,
                    fileType: fileType,
                    fileName: fileName,
                    filePath: loanDocument.loanDocument,
                    fileKey: loanDocument.fileKey,
                    fileDate: loanDocument.update_at,
                    title: this.translate.instant('LOAN.loanDocumentDisbursement'),
                    image: 'assets/svg/icon-document-disbursement.svg',
                    showDropZone: false,
                  }
                  // update disbursementFileData
                  this.disbursementFileData = disbursementFileData;
                  break;
                case 'acceptance':
                  // set acceptanceFileData
                  const acceptanceFileData = {
                    id: loanDocument.id,
                    parentId: loanDocument.loanId,
                    docType: loanDocumentType,
                    file: null,
                    fileId: loanDocument.id,
                    fileType: fileType,
                    fileName: fileName,
                    filePath: loanDocument.loanDocument,
                    fileKey: loanDocument.fileKey,
                    fileDate: loanDocument.update_at,
                    title: this.translate.instant('LOAN.loanDocumentAcceptance'),
                    image: 'assets/svg/icon-document-acceptance.svg',
                    showDropZone: false,
                  }
                  // update acceptanceFileData
                  this.acceptanceFileData = acceptanceFileData;
                  break;
                case 'companyPromissory':
                  // set companyPromissoryFileData
                  const companyPromissoryFileData = {
                    id: loanDocument.id,
                    parentId: loanDocument.loanId,
                    docType: loanDocumentType,
                    file: null,
                    fileId: loanDocument.id,
                    fileType: fileType,
                    fileName: fileName,
                    filePath: loanDocument.loanDocument,
                    fileKey: loanDocument.fileKey,
                    fileDate: loanDocument.update_at,
                    title: this.translate.instant('LOAN.loanDocumentPromissory'),
                    image: 'assets/svg/icon-document-company-promissory.svg',
                    showDropZone: false,
                  }
                  // update companyPromissoryFileData
                  this.companyPromissoryFileData = companyPromissoryFileData;
                  // mark companyPromissory as uploaded
                  this.companyPromissoryUploaded = true;
                  break;
                case 'profile':
                  // set profileFileData
                  const profileFileData = {
                    id: loanDocument.id,
                    parentId: loanDocument.loanId,
                    docType: loanDocumentType,
                    file: null,
                    fileId: loanDocument.id,
                    fileType: fileType,
                    fileName: fileName,
                    filePath: loanDocument.loanDocument,
                    fileKey: loanDocument.fileKey,
                    fileDate: loanDocument.update_at,
                    title: this.translate.instant('LOAN.loanDocumentProfile'),
                    image: 'assets/svg/icon-document-profile.svg',
                    showDropZone: false,
                  }
                  // update profileFileData
                  this.profileFileData = profileFileData;
                  break;
                case 'synthesis':
                  // set synthesisFileData
                  const synthesisFileData = {
                    id: loanDocument.id,
                    parentId: loanDocument.loanId,
                    docType: loanDocumentType,
                    file: null,
                    fileId: loanDocument.id,
                    fileType: fileType,
                    fileName: fileName,
                    filePath: loanDocument.loanDocument,
                    fileKey: loanDocument.fileKey,
                    fileDate: loanDocument.update_at,
                    title: this.translate.instant('LOAN.loanDocumentSynthesis'),
                    image: 'assets/svg/icon-document-synthesis.svg',
                    showDropZone: false,
                  }
                  // update synthesisFileData
                  this.synthesisFileData = synthesisFileData;
                  break;
                case 'detailed':
                  // set detailedFileData
                  const detailedFileData = {
                    id: loanDocument.id,
                    parentId: loanDocument.loanId,
                    docType: loanDocumentType,
                    file: null,
                    fileId: loanDocument.id,
                    fileType: fileType,
                    fileName: fileName,
                    filePath: loanDocument.loanDocument,
                    fileKey: loanDocument.fileKey,
                    fileDate: loanDocument.update_at,
                    title: this.translate.instant('LOAN.loanDocumentDetailed'),
                    image: 'assets/svg/icon-document-payment-receipt.svg',
                    showDropZone: false,
                  }
                  // update detailedFileData
                  this.detailedFileData = detailedFileData;
                  break;
                case 'rut':
                  // set rutFileData
                  const rutFileData = {
                    id: loanDocument.id,
                    parentId: loanDocument.loanId,
                    docType: loanDocumentType,
                    file: null,
                    fileId: loanDocument.id,
                    fileType: fileType,
                    fileName: fileName,
                    filePath: loanDocument.loanDocument,
                    fileKey: loanDocument.fileKey,
                    fileDate: loanDocument.update_at,
                    title: this.translate.instant('LOAN.loanDocumentRut'),
                    image: 'assets/svg/icon-document-rut.svg',
                    showDropZone: false,
                  }
                  // update rutFileData
                  this.rutFileData = rutFileData;
                  break;
                case 'ccio':
                  // set ccioFileData
                  const ccioFileData = {
                    id: loanDocument.id,
                    parentId: loanDocument.loanId,
                    docType: loanDocumentType,
                    file: null,
                    fileId: loanDocument.id,
                    fileType: fileType,
                    fileName: fileName,
                    filePath: loanDocument.loanDocument,
                    fileKey: loanDocument.fileKey,
                    fileDate: loanDocument.update_at,
                    title: this.translate.instant('LOAN.loanDocumentCCIO'),
                    image: 'assets/svg/icon-document-ccio.svg',
                    showDropZone: false,
                  }
                  // update ccioFileData
                  this.ccioFileData = ccioFileData;
                  break;
                case 'repId':
                  // set repIdFileData
                  const repIdFileData = {
                    id: loanDocument.id,
                    parentId: loanDocument.loanId,
                    docType: loanDocumentType,
                    file: null,
                    fileId: loanDocument.id,
                    fileType: fileType,
                    fileName: fileName,
                    filePath: loanDocument.loanDocument,
                    fileKey: loanDocument.fileKey,
                    fileDate: loanDocument.update_at,
                    title: this.translate.instant('LOAN.loanDocumentRepId'),
                    image: 'assets/svg/icon-document-rep-id.svg',
                    showDropZone: false,
                  }
                  // update repIdFileData
                  this.repIdFileData = repIdFileData;
                  break;
                case 'pg':
                  // set pgFileData
                  const pgFileData = {
                    id: loanDocument.id,
                    parentId: loanDocument.loanId,
                    docType: loanDocumentType,
                    file: null,
                    fileId: loanDocument.id,
                    fileType: fileType,
                    fileName: fileName,
                    filePath: loanDocument.loanDocument,
                    fileKey: loanDocument.fileKey,
                    fileDate: loanDocument.update_at,
                    title: this.translate.instant('LOAN.loanDocumentPG'),
                    image: 'assets/svg/icon-document-pg.svg',
                    showDropZone: false,
                  }
                  // update pgFileData
                  this.pgFileData = pgFileData;
                  break;
                case 'bankExtracts':
                  // set bankExtractsFileData
                  const bankExtractsFileData = {
                    id: loanDocument.id,
                    parentId: loanDocument.loanId,
                    docType: loanDocumentType,
                    file: null,
                    fileId: loanDocument.id,
                    fileType: fileType,
                    fileName: fileName,
                    filePath: loanDocument.loanDocument,
                    fileKey: loanDocument.fileKey,
                    fileDate: loanDocument.update_at,
                    title: this.translate.instant('LOAN.loanDocumentBankExtracts'),
                    image: 'assets/svg/icon-document-bank-extracts.svg',
                    showDropZone: false,
                  }
                  // update bankExtractsFileData
                  this.bankExtractsFileData = bankExtractsFileData;
                  break;
                case 'pensioner':
                  // set pensionerFileData
                  const pensionerFileData = {
                    id: loanDocument.id,
                    parentId: loanDocument.loanId,
                    docType: loanDocumentType,
                    file: null,
                    fileId: loanDocument.id,
                    fileType: fileType,
                    fileName: fileName,
                    filePath: loanDocument.loanDocument,
                    fileKey: loanDocument.fileKey,
                    fileDate: loanDocument.update_at,
                    title: this.translate.instant('LOAN.loanDocumentPensioner'),
                    image: 'assets/svg/icon-document-pensioner.svg',
                    showDropZone: false,
                  }
                  // update pensionerFileData
                  this.pensionerFileData = pensionerFileData;
                  break;
                case 'utilities':
                  // set utilitiesFileData
                  const utilitiesFileData = {
                    id: loanDocument.id,
                    parentId: loanDocument.loanId,
                    docType: loanDocumentType,
                    file: null,
                    fileId: loanDocument.id,
                    fileType: fileType,
                    fileName: fileName,
                    filePath: loanDocument.loanDocument,
                    fileKey: loanDocument.fileKey,
                    fileDate: loanDocument.update_at,
                    title: this.translate.instant('LOAN.loanDocumentUtilities'),
                    image: 'assets/svg/icon-document-utilities.svg',
                    showDropZone: false,
                  }
                  // update utilitiesFileData
                  this.utilitiesFileData = utilitiesFileData;
                  break;
                case 'otherDocument1':
                  // set otherDocument1FileData
                  const otherDocument1FileData = {
                    id: loanDocument.id,
                    parentId: loanDocument.loanId,
                    docType: loanDocumentType,
                    file: null,
                    fileId: loanDocument.id,
                    fileType: fileType,
                    fileName: fileName,
                    filePath: loanDocument.loanDocument,
                    fileKey: loanDocument.fileKey,
                    fileDate: loanDocument.update_at,
                    title: this.translate.instant('LOAN.loanDocumentOtherDocument') + '-1',
                    image: 'assets/svg/icon-document-other-documents.svg',
                    showDropZone: false,
                  }
                  // update otherDocument1FileData
                  this.otherDocument1FileData = otherDocument1FileData;
                  // update showOtherDocumentFile1
                  this.showOtherDocumentFile1 = true;
                  // update otherDocumentFileAdded1
                  this.otherDocumentFileAdded1 = true;
                  break;
                case 'otherDocument2':
                  // set otherDocument2FileData
                  const otherDocument2FileData = {
                    id: loanDocument.id,
                    parentId: loanDocument.loanId,
                    docType: loanDocumentType,
                    file: null,
                    fileId: loanDocument.id,
                    fileType: fileType,
                    fileName: fileName,
                    filePath: loanDocument.loanDocument,
                    fileKey: loanDocument.fileKey,
                    fileDate: loanDocument.update_at,
                    title: this.translate.instant('LOAN.loanDocumentOtherDocument') + '-2',
                    image: 'assets/svg/icon-document-other-documents.svg',
                    showDropZone: false,
                  }
                  // update otherDocument2FileData
                  this.otherDocument2FileData = otherDocument2FileData;
                  // update showOtherDocumentFile2
                  this.showOtherDocumentFile2 = true;
                  // update otherDocumentFileAdded2
                  this.otherDocumentFileAdded2 = true;
                  break;
                case 'otherDocument3':
                  // set otherDocument3FileData
                  const otherDocument3FileData = {
                    id: loanDocument.id,
                    parentId: loanDocument.loanId,
                    docType: loanDocumentType,
                    file: null,
                    fileId: loanDocument.id,
                    fileType: fileType,
                    fileName: fileName,
                    filePath: loanDocument.loanDocument,
                    fileKey: loanDocument.fileKey,
                    fileDate: loanDocument.update_at,
                    title: this.translate.instant('LOAN.loanDocumentOtherDocument') + '-3',
                    image: 'assets/svg/icon-document-other-documents.svg',
                    showDropZone: false,
                  }
                  // update otherDocument3FileData
                  this.otherDocument3FileData = otherDocument3FileData;
                  // update showOtherDocumentFile3
                  this.showOtherDocumentFile3 = true;
                  // update otherDocumentFileAdded3
                  this.otherDocumentFileAdded3 = true;
                  break;
              }
            };
            // get loanPaymentReceipts
            const loanPaymentReceipts: number = this.loanPaymentReceipts;
            // compare paymentReceiptsLoaded with loanPaymentReceipts
            if (paymentReceiptsLoaded !== loanPaymentReceipts) {
              // get missingReceipts
              const missingReceipts = loanPaymentReceipts - paymentReceiptsLoaded;
              // generate missing paymentReceipts
              for (let j = 0; j < missingReceipts; j++) {
                // set loanDocumentPaymentReceiptData
                const loanDocumentPaymentReceiptData = {
                  id: 'paymentReceipt-' + (j + 1),
                  parentId: this.loanId,
                  docType: 'paymentReceipts',
                  file: null,
                  fileId: 'paymentReceipt-' + (j + 1),
                  fileType: null,
                  fileName: null,
                  filePath: null,
                  fileKey: null,
                  fileDate: null,
                  title: this.translate.instant('LOAN.loanDocumentPaymentReceipt') + '-' + (j + 1),
                  image: 'assets/svg/icon-document-payment-receipt.svg',
                  showDropZone: false,
                }
                // push paymentReceiptsFileData
                this.paymentReceiptFiles.push(loanDocumentPaymentReceiptData);
              }
            }
          }
          // requestFileData
          // -----------------------------------------------------------------
          // check if requestFileData is null
          if (this.requestFileData === null) {
            // set requestFileData
            const requestFileData = {
              id: 'request',
              parentId: this.loanId,
              docType: 'request',
              file: null,
              fileId: 'request',
              fileType: null,
              fileName: null,
              filePath: null,
              fileKey: null,
              fileDate: null,
              title: this.translate.instant('LOAN.loanDocumentRequest'),
              image: 'assets/svg/icon-document-request.svg',
              showDropZone: false,
            }
            // update requestFileData
            this.requestFileData = requestFileData;
          }
          // laborFileData
          // -----------------------------------------------------------------
          // check workType
          if (this.workType === 'employee') {
            // check if laborFileData is null
            if (this.laborFileData === null) {
              // set laborFileData
              const laborFileData = {
                id: 'labor',
                parentId: this.loanId,
                docType: 'labor',
                file: null,
                fileId: 'labor',
                fileType: null,
                fileName: null,
                filePath: null,
                fileKey: null,
                fileDate: null,
                title: this.translate.instant('LOAN.loanDocumentLabor'),
                image: 'assets/svg/icon-document-labor.svg',
                showDropZone: false,
              }
              // update laborFileData
              this.laborFileData = laborFileData;
            }
          }
          // promissoryFileData
          // -----------------------------------------------------------------
          // check if promissoryFileData is null
          if (this.promissoryFileData === null) {
            // set promissoryFileData
            const promissoryFileData = {
              id: 'promissory',
              parentId: this.loanId,
              docType: 'promissory',
              file: null,
              fileId: 'promissory',
              fileType: null,
              fileName: null,
              filePath: null,
              fileKey: null,
              fileDate: null,
              title: this.translate.instant('LOAN.loanDocumentPromissory'),
              image: 'assets/svg/icon-document-promissory.svg',
              showDropZone: false,
            }
            // update promissoryFileData
            this.promissoryFileData = promissoryFileData;
          }
          // authorizationFileData
          // -----------------------------------------------------------------
          // check if authorizationFileData is null
          if (this.authorizationFileData === null) {
            // set authorizationFileData
            const authorizationFileData = {
              id: 'authorization',
              parentId: this.loanId,
              docType: 'authorization',
              file: null,
              fileId: 'authorization',
              fileType: null,
              fileName: null,
              filePath: null,
              fileKey: null,
              fileDate: null,
              title: this.translate.instant('LOAN.loanDocumentAuthorization'),
              image: 'assets/svg/icon-document-authorization.svg',
              showDropZone: false,
            }
            // update authorizationFileData
            this.authorizationFileData = authorizationFileData;
          }
          // disbursementFileData
          // -----------------------------------------------------------------
          // check if disbursementFileData is null
          if (this.disbursementFileData === null) {
            // set disbursementFileData
            const disbursementFileData = {
              id: 'disbursement',
              parentId: this.loanId,
              docType: 'disbursement',
              file: null,
              fileId: 'disbursement',
              fileType: null,
              fileName: null,
              filePath: null,
              fileKey: null,
              fileDate: null,
              title: this.translate.instant('LOAN.loanDocumentDisbursement'),
              image: 'assets/svg/icon-document-disbursement.svg',
              showDropZone: false,
            }
            // update disbursementFileData
            this.disbursementFileData = disbursementFileData;
          }
          // acceptanceFileData
          // -----------------------------------------------------------------
          // check if acceptanceFileData is null
          if (this.acceptanceFileData === null) {
            // set acceptanceFileData
            const acceptanceFileData = {
              id: 'acceptance',
              parentId: this.loanId,
              docType: 'acceptance',
              file: null,
              fileId: 'acceptance',
              fileType: null,
              fileName: null,
              filePath: null,
              fileKey: null,
              fileDate: null,
              title: this.translate.instant('LOAN.loanDocumentAcceptance'),
              image: 'assets/svg/icon-document-acceptance.svg',
              showDropZone: false,
            }
            // update acceptanceFileData
            this.acceptanceFileData = acceptanceFileData;
          }
          // companyPromissoryFileData
          // -----------------------------------------------------------------
          // check if companyPromissoryFileData is null
          if (this.companyPromissoryFileData === null) {
            // set companyPromissoryFileData
            const companyPromissoryFileData = {
              id: 'companyPromissory',
              parentId: this.loanId,
              docType: 'companyPromissory',
              file: null,
              fileId: 'companyPromissory',
              fileType: null,
              fileName: null,
              filePath: null,
              fileKey: null,
              fileDate: null,
              title: this.translate.instant('LOAN.loanDocumentPromissory'),
              image: 'assets/svg/icon-document-company-promissory.svg',
              showDropZone: false,
            }
            // update companyPromissoryFileData
            this.companyPromissoryFileData = companyPromissoryFileData;
          }
          // profileFileData
          // -----------------------------------------------------------------
          // check if profileFileData is null
          if (this.profileFileData === null) {
            // set profileFileData
            const profileFileData = {
              id: 'profile',
              parentId: this.loanId,
              docType: 'profile',
              file: null,
              fileId: 'profile',
              fileType: null,
              fileName: null,
              filePath: null,
              fileKey: null,
              fileDate: null,
              title: this.translate.instant('LOAN.loanDocumentProfile'),
              image: 'assets/svg/icon-document-profile.svg',
              showDropZone: false,
            }
            // update profileFileData
            this.profileFileData = profileFileData;
          }
          // synthesisFileData
          // -----------------------------------------------------------------
          // check if synthesisFileData is null
          if (this.synthesisFileData === null) {
            // set synthesisFileData
            const synthesisFileData = {
              id: 'synthesis',
              parentId: this.loanId,
              docType: 'synthesis',
              file: null,
              fileId: 'synthesis',
              fileType: null,
              fileName: null,
              filePath: null,
              fileKey: null,
              fileDate: null,
              title: this.translate.instant('LOAN.loanDocumentSynthesis'),
              image: 'assets/svg/icon-document-synthesis.svg',
              showDropZone: false,
            }
            // update synthesisFileData
            this.synthesisFileData = synthesisFileData;
          }
          // detailedFileData
          // -----------------------------------------------------------------
          // check if detailedFileData is null
          if (this.detailedFileData === null) {
            // set detailedFileData
            const detailedFileData = {
              id: 'detailed',
              parentId: this.loanId,
              docType: 'detailed',
              file: null,
              fileId: 'detailed',
              fileType: null,
              fileName: null,
              filePath: null,
              fileKey: null,
              fileDate: null,
              title: this.translate.instant('LOAN.loanDocumentDetailed'),
              image: 'assets/svg/icon-document-payment-receipt.svg',
              showDropZone: false,
            }
            // update detailedFileData
            this.detailedFileData = detailedFileData;
          }
          // loanPaymentReceipts
          // -----------------------------------------------------------------
          // check workType
          if (this.workType === 'employee') {
            // check if loanPaymentReceipts is null
            if (this.paymentReceiptFiles.length === 0) {
              // get loanPaymentReceipts
              const loanPaymentReceipts: number = this.loanPaymentReceipts;
              // generate loanPaymentReceipts
              for (let k = 0; k < loanPaymentReceipts; k++) {
                // set loanDocumentPaymentReceiptData
                const loanDocumentPaymentReceiptData = {
                  id: 'paymentReceipt-' + (k + 1),
                  parentId: this.loanId,
                  docType: 'paymentReceipts',
                  file: null,
                  fileId: 'paymentReceipt-' + (k + 1),
                  fileType: null,
                  fileName: null,
                  filePath: null,
                  fileKey: null,
                  fileDate: null,
                  title: this.translate.instant('LOAN.loanDocumentPaymentReceipt') + '-' + (k + 1),
                  image: 'assets/svg/icon-document-payment-receipt.svg',
                  showDropZone: false,
                }
                // push paymentReceiptsFileData
                this.paymentReceiptFiles.push(loanDocumentPaymentReceiptData);
              }
            }
          }
          // rutFileData
          // -----------------------------------------------------------------
          // check if rutFileData is null
          if (this.rutFileData === null) {
            // set rutFileData
            const rutFileData = {
              id: 'rut',
              parentId: this.loanId,
              docType: 'rut',
              file: null,
              fileId: 'rut',
              fileType: null,
              fileName: null,
              filePath: null,
              fileKey: null,
              fileDate: null,
              title: this.translate.instant('LOAN.loanDocumentRut'),
              image: 'assets/svg/icon-document-rut.svg',
              showDropZone: false,
            }
            // update rutFileData
            this.rutFileData = rutFileData;
          }
          // ccioFileData
          // -----------------------------------------------------------------
          // check if ccioFileData is null
          if (this.ccioFileData === null) {
            // set ccioFileData
            const ccioFileData = {
              id: 'ccio',
              parentId: this.loanId,
              docType: 'ccio',
              file: null,
              fileId: 'ccio',
              fileType: null,
              fileName: null,
              filePath: null,
              fileKey: null,
              fileDate: null,
              title: this.translate.instant('LOAN.loanDocumentCCIO'),
              image: 'assets/svg/icon-document-ccio.svg',
              showDropZone: false,
            }
            // update ccioFileData
            this.ccioFileData = ccioFileData;
          }
          // repIdFileData
          // -----------------------------------------------------------------
          // check if repIdFileData is null
          if (this.repIdFileData === null) {
            // set repIdFileData
            const repIdFileData = {
              id: 'repId',
              parentId: this.loanId,
              docType: 'repId',
              file: null,
              fileId: 'repId',
              fileType: null,
              fileName: null,
              filePath: null,
              fileKey: null,
              fileDate: null,
              title: this.translate.instant('LOAN.loanDocumentRepId'),
              image: 'assets/svg/icon-document-rep-id.svg',
              showDropZone: false,
            }
            // update repIdFileData
            this.repIdFileData = repIdFileData;
          }
          // pgFileData
          // -----------------------------------------------------------------
          // check if pgFileData is null
          if (this.pgFileData === null) {
            // set pgFileData
            const pgFileData = {
              id: 'pg',
              parentId: this.loanId,
              docType: 'pg',
              file: null,
              fileId: 'pg',
              fileType: null,
              fileName: null,
              filePath: null,
              fileKey: null,
              fileDate: null,
              title: this.translate.instant('LOAN.loanDocumentPG'),
              image: 'assets/svg/icon-document-pg.svg',
              showDropZone: false,
            }
            // update pgFileData
            this.pgFileData = pgFileData;
          }
          // bankExtractsFileData
          // -----------------------------------------------------------------
          // check if bankExtractsFileData is null
          if (this.bankExtractsFileData === null) {
            // set bankExtractsFileData
            const bankExtractsFileData = {
              id: 'bankExtracts',
              parentId: this.loanId,
              docType: 'bankExtracts',
              file: null,
              fileId: 'bankExtracts',
              fileType: null,
              fileName: null,
              filePath: null,
              fileKey: null,
              fileDate: null,
              title: this.translate.instant('LOAN.loanDocumentBankExtracts'),
              image: 'assets/svg/icon-document-bank-extracts.svg',
              showDropZone: false,
            }
            // update bankExtractsFileData
            this.bankExtractsFileData = bankExtractsFileData;
          }
          // pensionerFileData
          // -----------------------------------------------------------------
          // check if pensionerFileData is null
          if (this.pensionerFileData === null) {
            // set pensionerFileData
            const pensionerFileData = {
              id: 'pensioner',
              parentId: this.loanId,
              docType: 'pensioner',
              file: null,
              fileId: 'pensioner',
              fileType: null,
              fileName: null,
              filePath: null,
              fileKey: null,
              fileDate: null,
              title: this.translate.instant('LOAN.loanDocumentPensioner'),
              image: 'assets/svg/icon-document-pensioner.svg',
              showDropZone: false,
            }
            // update pensionerFileData
            this.pensionerFileData = pensionerFileData;
          }
          // utilitiesFileData
          // -----------------------------------------------------------------
          // check if utilitiesFileData is null
          if (this.utilitiesFileData === null) {
            // set utilitiesFileData
            const utilitiesFileData = {
              id: 'utilities',
              parentId: this.loanId,
              docType: 'utilities',
              file: null,
              fileId: 'utilities',
              fileType: null,
              fileName: null,
              filePath: null,
              fileKey: null,
              fileDate: null,
              title: this.translate.instant('LOAN.loanDocumentUtilities'),
              image: 'assets/svg/icon-document-utilities.svg',
              showDropZone: false,
            }
            // update utilitiesFileData
            this.utilitiesFileData = utilitiesFileData;
          }
          // otherDocument1FileData
          // -----------------------------------------------------------------
          // check if otherDocument1FileData is null
          if (this.otherDocument1FileData === null) {
            // set otherDocument1FileData
            const otherDocument1FileData = {
              id: 'otherDocument1',
              parentId: this.loanId,
              docType: 'otherDocument1',
              file: null,
              fileId: 'otherDocument1',
              fileType: null,
              fileName: null,
              filePath: null,
              fileKey: null,
              fileDate: null,
              title: this.translate.instant('LOAN.loanDocumentOtherDocument') + '-1',
              image: 'assets/svg/icon-document-other-documents.svg',
              showDropZone: false,
            }
            // update otherDocument1FileData
            this.otherDocument1FileData = otherDocument1FileData;
          }
          // otherDocument2FileData
          // -----------------------------------------------------------------
          // check if otherDocument2FileData is null
          if (this.otherDocument2FileData === null) {
            // set otherDocument2FileData
            const otherDocument2FileData = {
              id: 'otherDocument2',
              parentId: this.loanId,
              docType: 'otherDocument2',
              file: null,
              fileId: 'otherDocument2',
              fileType: null,
              fileName: null,
              filePath: null,
              fileKey: null,
              fileDate: null,
              title: this.translate.instant('LOAN.loanDocumentOtherDocument') + '-2',
              image: 'assets/svg/icon-document-other-documents.svg',
              showDropZone: false,
            }
            // update otherDocument2FileData
            this.otherDocument2FileData = otherDocument2FileData;
          }
          // otherDocument3FileData
          // -----------------------------------------------------------------
          // check if otherDocument3FileData is null
          if (this.otherDocument3FileData === null) {
            // set otherDocument3FileData
            const otherDocument3FileData = {
              id: 'otherDocument3',
              parentId: this.loanId,
              docType: 'otherDocument3',
              file: null,
              fileId: 'otherDocument3',
              fileType: null,
              fileName: null,
              filePath: null,
              fileKey: null,
              fileDate: null,
              title: this.translate.instant('LOAN.loanDocumentOtherDocument') + '-3',
              image: 'assets/svg/icon-document-other-documents.svg',
              showDropZone: false,
            }
            // update otherDocument3FileData
            this.otherDocument3FileData = otherDocument3FileData;
          }
          // resolve
          resolve(true);
        }, error=>{
          console.log('error', error);
          reject(error);
        });
      } catch (error) {
        console.log('error', error);
        // hide loader
        this.loaderEvent.emit(null);
        reject(error);
      }
    });
  }
  async addOtherDocument() {
    // check showOtherDocumentFile1
    if (!this.showOtherDocumentFile1) {
      // check showOtherDocumentFile1
      this.showOtherDocumentFile1 = true;
      return;
    }
    // check showOtherDocumentFile2
    if (!this.showOtherDocumentFile2) {
      // check showOtherDocumentFile2
      this.showOtherDocumentFile2 = true;
      return;
    }
    // check showOtherDocumentFile3
    if (!this.showOtherDocumentFile3) {
      // check showOtherDocumentFile3
      this.showOtherDocumentFile3 = true;
      return;
    }
    // show alert
    this.alertSv.showMessage(this.translate.instant('LOAN.loanDocumentNoOtherDocument'),'error', this.translate.instant('HELPERS.error'), true);
  }
  // loan payments
  async getLoanPaymentsData() {
    return new Promise(async (resolve, reject) => {
      try {
        // show loader
        this.loaderEvent.emit(this.translate.instant('HELPERS.loadingTxt') + ' ' + this.translate.instant('LOAN.loanPayments'));
        // set loanPaymentData
        const loanPaymentData = {
          companyId: null,
          batchId: null,
          loanNumber: this.loanNumber,
          userId: null,
          loanPaymentNumber: null,
          loanPaymentAmount: null,
          loanPaymentStatuses: null,
          loanPaymentMonth: null,
        }
        // get data from api
        await this.apiSv.getLoanPayments(loanPaymentData).then(async (loanPaymentResponse: any)=>{
          // get loanPayments
          const loanPaymentsData: any = loanPaymentResponse.data;
          // check loanPayments
          if (loanPaymentsData) {
            // clear loanPayments
            this.loanPayments = [];
            // init loanPayments
            const loanPayments: any[] = [];
            // loop loanPayments
            loanPaymentsData.map((loanPayment: LoanPaymentInterface) => {
              // init fileExtension
              let fileExtension: string = null;
              // init fileType
              let fileType: string = null;
              // init fileName
              let fileName: string = null;
              // init loanPaymentFileData
              let loanPaymentFileData: any = null;
              // check for payment Receipt
              if (loanPayment.loanPaymentReceipt) {
                // get fileExtension
                fileExtension = this.mediaSv.getFileExtension(loanPayment.loanPaymentReceipt);
                // get fileType
                fileType = this.mediaSv.getFileType(fileExtension);
                // get fileName
                fileName = this.mediaSv.getFileNameAndExtension(loanPayment.loanPaymentReceipt);
                // set loanPaymentFileData
                loanPaymentFileData = {
                  id: loanPayment.id,
                  parentId: this.loanId,
                  docType: 'loanPayment',
                  file: null,
                  fileId: loanPayment.id,
                  fileType: fileType,
                  fileName: fileName,
                  filePath: loanPayment.loanPaymentReceipt,
                  fileKey: loanPayment.loanPaymentReceipt,
                  fileDate: null,
                  title: this.translate.instant('LOAN.loanDocumentBank'),
                  image: 'assets/svg/icon-document-payment-receipt.svg',
                  showDropZone: false,
                }
              } else {
                loanPaymentFileData = {
                  id: loanPayment.id,
                  parentId: this.loanId,
                  docType: 'loanPayment',
                  file: null,
                  fileId: 'loanPayment',
                  fileType: null,
                  fileName: null,
                  filePath: null,
                  fileKey: null,
                  fileDate: null,
                  title: this.translate.instant('LOAN.loanDocumentBank'),
                  image: 'assets/svg/icon-document-payment-receipt.svg',
                  showDropZone: false,
                }
              }
              // update loanPaymentFileData
              loanPayment.loanPaymentFileData = loanPaymentFileData;
              // push loanPayment
              loanPayments.push(loanPayment);
            });
            // orderLoanPayments
            const orderedLoanPayments = this.utilitiesSv.sortArrayByKey(loanPayments, 'loanPaymentNumber');
            // update loanPayments
            this.loanPayments = orderedLoanPayments;
          }
          // resolve
          resolve(true);
        }, error=>{
          console.log('error', error);
          reject(error);
        });
      } catch (error) {
        console.log('error', error);
        // hide loader
        this.loaderEvent.emit(null);
        reject(error);
      }
    });
  }
  // user work
  async getUserWorkData() {
    return new Promise(async (resolve, reject) => {
      try {
        // show loader
        this.loaderEvent.emit(this.translate.instant('HELPERS.loadingTxt') + ' ' + this.translate.instant('USERWORKS.userWork'));
        // get userWorkId
        const userWorkId: any = this.userWorkId;
        // check userWork id
        if (userWorkId !== null) {
          // get data from api
          await this.apiSv.getUserWork(userWorkId).then(async (userWorkResponse: any)=>{
            // get userWork
            const userWork: UserWorkInterface = userWorkResponse.data;

            console.log('userWork', userWork);

            // position data
            if (userWork.positionId) {
              const auxPosition: any = await this.apiSv.getPosition(userWork.positionId);
              const position: PositionInterface = auxPosition.data;
              userWork.positionName = position.positionName;
            } else {
              userWork.positionName = null;
            }
            // occupation data
            if (userWork.occupationId || userWork.occupationsId) {
              const occupationId = userWork.occupationId ? userWork.occupationId : userWork.occupationsId;
              const auxOccupation: any = await this.apiSv.getOccupation(occupationId);
              const occupation: OccupationInterface = auxOccupation.data;
              userWork.occupationName = occupation.occupationName;
            } else {
              userWork.occupationName = null;
            }
            // set userWork data
            this.userWorkData = userWork;
            this.workType = userWork.workType;
            // set salary
            this.salary = userWork.contractSalary;
            // resolve
            resolve(true);
          }, error=>{
            console.log('error', error);
            reject(error);
          });
        }
      } catch (error) {
        console.log('error', error);
        // hide loader
        this.loaderEvent.emit(null);
        reject(error);
      }
    });
  }
  // user
  async getLoanUserData() {
    return new Promise(async (resolve, reject) => {
      try {
        // show loader
        this.loaderEvent.emit(this.translate.instant('HELPERS.loadingTxt') + ' ' + this.translate.instant('USER.employeeInfo'));
        // get data from api
        await this.apiSv.getUser(this.userWorkData.userId).then(async (userResponse: any)=>{
          // get user
          const user: UserInterface = userResponse.data;
          // set user
          await this.setLoanUserData(user);
          // resolve
          resolve(true);
        }, error=>{
          console.log('error', error);
          reject(error);
        });
      } catch (error) {
        console.log('error', error);
        // hide loader
        this.loaderEvent.emit(null);
        reject(error);
      }
    });
  }
  async setLoanUserData(user: UserInterface) {
    // update userId
    this.userId = user.id;
    // check cellphoneCountryCode
    if (user.cellphoneCountryCode) {
      const auxCellphoneCountry: any = await this.apiSv.getCountry(user.cellphoneCountryCode);
      const cellphoneCountry: CountryInterface = auxCellphoneCountry.data;
      user.cellphoneCountryName = cellphoneCountry.countryName;
      user.cellphoneCountryCodeCod = cellphoneCountry.numericCode;
    }
    // check phoneCountryCode
    if (user.phoneCountryCode) {
      const auxPhoneCountry: any = await this.apiSv.getCountry(user.phoneCountryCode);
      const phoneCountry: CountryInterface = auxPhoneCountry.data;
      user.phoneCountryName = phoneCountry.countryName;
      user.phoneCountryCodeCod = phoneCountry.numericCode;
    }
    // check nationality
    if (user.nationalityId) {
      const auxCountry: any = await this.apiSv.getCountry(user.nationalityId);
      const nationality: CountryInterface = auxCountry.data;
      user.nationalityName = nationality.countryName;
      user.nationalityFlag = 'assets/images/flags/' + nationality.countryFlag + '.png';
    }
    // check birthPlace
    if (user.birthPlaceId) {
      const auxBirthPlace: any = await this.apiSv.getCity(user.birthPlaceId);
      const birthPlace: CityInterface = auxBirthPlace.data;
      user.birthPlaceCountryName = birthPlace.countryName;
      user.birthPlaceStateName = birthPlace.stateName;
      user.birthPlaceCityName = birthPlace.name;
    }
    // check documentExpeditionCity
    if (user.documentExpeditionCityId) {
      const auxDocumentExpeditionPlace: any = await this.apiSv.getCity(user.documentExpeditionCityId);
      const documentExpeditionPlace: CityInterface = auxDocumentExpeditionPlace.data;
      user.documentExpeditionCountryName = documentExpeditionPlace.countryName;
      user.documentExpeditionStateName = documentExpeditionPlace.stateName;
      user.documentExpeditionCityName = documentExpeditionPlace.name;
    }
    // profession data
    if (user.professionId) {
      const auxProfession: any = await this.apiSv.getProfession(user.professionId);
      const profession: ProfessionInterface = auxProfession.data;
      user.professionName = profession.professionName;
      this.userWorkData.professionId = profession.id;
      this.userWorkData.professionName = profession.professionName;
    } else {
      user.professionName = null;
      this.userWorkData.professionId = null;
      this.userWorkData.professionName = null;
    }
    // set user data
    this.loanUserData = user;
  }
  // user attachments
  async getUserAttachmentsData() {
    return new Promise(async (resolve, reject) => {
      try {
        // show loader
        this.loaderEvent.emit(this.translate.instant('HELPERS.loadingTxt') + ' ' + this.translate.instant('USER.formLabels.documents'));
        // get data from api
        await this.apiSv.getUserAttachments(this.userId).then(async (userAttachmentResponse: any)=>{
          // get userAttachments
          const userAttachments: any = userAttachmentResponse.data;
          // clear data
          this.idFrontFileData = null;
          this.idBackFileData = null;
          // check userAttachments
          if (userAttachments && userAttachments.length > 0) {
            // init check idBack
            let foundIdBack: boolean = false;
            // loop userAttachments
            for (let i = 0; i < userAttachments.length; i++) {
              // get userAttachment
              const userAttachment = userAttachments[i];
              // get fileExtension
              const fileExtension = this.mediaSv.getFileExtension(userAttachment.userAttachment);
              // get fileType
              const fileType = this.mediaSv.getFileType(fileExtension);
              // get fileName
              const fileName = this.mediaSv.getFileNameAndExtension(userAttachment.userAttachment);
              // check userAttachmentType
              switch (userAttachment.userAttachmentType) {
                case 'idFront':{
                  // set idFrontFileData
                  const idFrontFileData = {
                    id: userAttachment.id,
                    parentId: this.userId,
                    docType: userAttachment.userAttachmentType,
                    file: null,
                    fileId: userAttachment.id,
                    fileType: fileType,
                    fileName: fileName,
                    filePath: userAttachment.userAttachment,
                    fileKey: userAttachment.fileKey,
                    fileDate: userAttachment.update_at,
                    title: this.translate.instant('LOAN.loanDocumentUserIdFront'),
                    image: 'assets/svg/icon-document-front.svg',
                    showDropZone: false,
                  }
                  // update idFrontFileData
                  this.idFrontFileData = idFrontFileData;
                  break;
                }
                case 'idBack':{
                  // set idBackFileData
                  const idBackFileData = {
                    id: userAttachment.id,
                    parentId: this.userId,
                    docType: userAttachment.userAttachmentType,
                    file: null,
                    fileId: userAttachment.id,
                    fileType: fileType,
                    fileName: fileName,
                    filePath: userAttachment.userAttachment,
                    fileKey: userAttachment.fileKey,
                    fileDate: userAttachment.update_at,
                    title: this.translate.instant('LOAN.loanDocumentUserIdBack'),
                    image: 'assets/svg/icon-document-reverse.svg',
                    showDropZone: false,
                  }
                  // update idBackFileData
                  this.idBackFileData = idBackFileData;
                  // update foundIdBack
                  foundIdBack = true;
                  break;
                }
              }
            }
            // check foundIdBack
            if (!foundIdBack) {
              // set idBackFileData
              const idBackFileData = {
                id: 'newIdBack',
                parentId: this.userId,
                docType: 'idBack',
                file: null,
                fileId: null,
                fileType: null,
                fileName: null,
                filePath: null,
                fileKey: null,
                fileDate: null,
                title: this.translate.instant('LOAN.loanDocumentUserIdBack'),
                image: 'assets/svg/icon-document-reverse.svg',
                showDropZone: false,
              }
              // update idBackFileData
              this.idBackFileData = idBackFileData;
            }
          }
          // check if idFrontFileData is null
          // -----------------------------------------------------------------
          if (this.idFrontFileData === null) {
            // set idFrontFileData
            const idFrontFileData = {
              id: 'idFront',
              parentId: this.userId,
              docType: 'idFront',
              file: null,
              fileId: 'idFront',
              fileType: null,
              fileName: null,
              filePath: null,
              fileKey: null,
              fileDate: null,
              title: this.translate.instant('LOAN.loanDocumentUserIdFront'),
              image: 'assets/svg/icon-document-front.svg',
              showDropZone: false,
            }
            // update idFrontFileData
            this.idFrontFileData = idFrontFileData;
          }
          // check if idBackFileData is null
          // -----------------------------------------------------------------
          if (this.idBackFileData === null) {
            // set idBackFileData
            const idBackFileData = {
              id: 'idBack',
              parentId: this.userId,
              docType: 'idBack',
              file: null,
              fileId: 'idBack',
              fileType: null,
              fileName: null,
              filePath: null,
              fileKey: null,
              fileDate: null,
              title: this.translate.instant('LOAN.loanDocumentUserIdBack'),
              image: 'assets/svg/icon-document-reverse.svg',
              showDropZone: false,
            }
            // update idBackFileData
            this.idBackFileData = idBackFileData;
          }
          // resolve
          resolve(true);
        }, error=>{
          console.log('error', error);
          reject(error);
        });
      } catch (error) {
        console.log('error', error);
        // hide loader
        this.loaderEvent.emit(null);
        reject(error);
      }
    });
  }
  // user addresses
  async getUserAddresses() {
    // get data from api
    await this.apiSv.getUserAddresses(this.userId).then((response: any)=>{
      // get userAddresses
      const userAddresses = response.data;
      // clear userAddresses
      this.userAddressesData = [];
      // loop banks
      userAddresses.map(async (userAddress: UserAddressInterface) => {
        // get location data
        const cityId = userAddress.cityId;
        const cityAux: any = await this.apiSv.getCity(cityId);
        const city: CityInterface = cityAux.data;
        const cityName = city.name;
        const countryId = city.countryId;
        const countryName = city.countryName;
        const stateId = city.stateId;
        const stateName = city.stateName;
        userAddress.cityName = cityName;
        userAddress.stateId = stateId;
        userAddress.stateName = stateName;
        userAddress.countryId = countryId;
        userAddress.countryName = countryName;
        // push
        this.userAddressesData.push(userAddress)
      });
    }, error=>{
      console.log('error', error);
    });
  }
  // user bank accounts
  async getUserBankAccounts() {
    // get data from api
    await this.apiSv.getUserBankAccounts(this.userId).then(async (response: any)=>{
      // get userBankAccounts
      const userBankAccounts = response.data;
      // clear userBankAccounts
      this.userBankAccounts = [];
      // init userBankAccountId
      let userBankAccountId: string = null;
      // loop banks
      for (let index = 0; index < userBankAccounts.length; index++) {
        const userBankAccount:UserBankAccountInterface = userBankAccounts[index];
        // get bankData
        const bankData: any = await this.apiSv.getBank(userBankAccount.bankId);
        // get bank
        const bank: BankInterface = bankData.data;
        // init fileExtension
        let fileExtension: string = null;
        // init fileType
        let fileType: string = null;
        // init fileName
        let fileName: string = null;
        // init certificateFileData
        let certificateFileData: any = null;
        // update userBankAccountId
        userBankAccountId = userBankAccount.id;
        // check for certificateFile
        if (userBankAccount.certificateFile) {
          // get fileExtension
          fileExtension = this.mediaSv.getFileExtension(userBankAccount.certificateFile);
          // get fileType
          fileType = this.mediaSv.getFileType(fileExtension);
          // get fileName
          fileName = this.mediaSv.getFileNameAndExtension(userBankAccount.certificateFile);
          // set certificateFileData
          certificateFileData = {
            id: userBankAccount.id,
            parentId: this.loanId,
            docType: 'bank',
            file: null,
            fileId: userBankAccount.id,
            fileType: fileType,
            fileName: fileName,
            filePath: userBankAccount.certificateFile,
            fileKey: userBankAccount.certificateFileKey,
            fileDate: null,
            title: this.translate.instant('LOAN.loanDocumentBank'),
            image: 'assets/svg/icon-document-bank.svg',
            showDropZone: false,
          }
        } else {
          certificateFileData = {
            id: userBankAccountId,
            parentId: this.loanId,
            docType: 'bank',
            file: null,
            fileId: 'bank',
            fileType: null,
            fileName: null,
            filePath: null,
            fileKey: null,
            fileDate: null,
            title: this.translate.instant('LOAN.loanDocumentBank'),
            image: 'assets/svg/icon-document-bank.svg',
            showDropZone: false,
          }
        }
        // check userBankAccountActive
        if (userBankAccount.userBankAccountActive) {
          // check certificateFile
          if (userBankAccount.certificateFile !== null) {
            // update bankFileData
            this.bankFileData = certificateFileData;
          }
        }
        const userBankAccountNumber = userBankAccount.userBankAccountNumber;
        const userBankAccountNumberEncoded = userBankAccountNumber;
        const userBankAccountNumberDecoded = this.encodingSv.decodeCrypto(userBankAccount.userBankAccountNumber);
        // set userBankAccountData
        const userBankAccountData: UserBankAccountInterface = {
          id: userBankAccount.id,
          userBankAccountType: userBankAccount.userBankAccountType,
          userBankAccountNumber: userBankAccount.userBankAccountNumber,
          userBankAccountNumberEncoded,
          userBankAccountNumberDecoded,
          bankId: bank.id,
          bankLogo: bank.bankLogo,
          bankName: bank.bankName,
          userBankAccountActive: userBankAccount.userBankAccountActive,
          certificateFileData: certificateFileData,
        }
        // push userBankAccounts
        this.userBankAccounts.push(userBankAccountData);
      }
      // bankFileData
      // -----------------------------------------------------------------
      // check if bankFileData is null
      if (this.bankFileData === null) {
        // set bankFileData
        const bankFileData = {
          id: userBankAccountId,
          parentId: this.loanId,
          docType: 'bank',
          file: null,
          fileId: 'bank',
          fileType: null,
          fileName: null,
          filePath: null,
          fileKey: null,
          fileDate: null,
          title: this.translate.instant('LOAN.loanDocumentBank'),
          image: 'assets/svg/icon-document-bank.svg',
          showDropZone: false,
        }
        // update bankFileData
        this.bankFileData = bankFileData;
      }
    }, error=>{
      console.log('error', error);
    });
  }
  // user financials
  async getUserFinancialsData() {
    return new Promise(async (resolve, reject) => {
      try {
        // show loader
        this.loaderEvent.emit(this.translate.instant('HELPERS.loadingTxt') + ' ' + this.translate.instant('USERFINANTIALS.finantialInfo'));
        // get data from api
        await this.apiSv.getLoanUserFinancials(this.loanId).then(async (userFinancialsResponse: any)=>{
          // get userFinancials
          const userFinancials: any = userFinancialsResponse.data.result;
          // check userFinancials
          if (userFinancials.length > 0) {
            // set financialData
            const financialData = {
              id: userFinancials[0].id,
              userId: this.loanUserData.id,
              dependents: userFinancials[0].dependents,
              housingType: userFinancials[0].housingType,
              nationalityId: this.loanUserData.nationalityId,
              totalIncome: this.salary + userFinancials[0].professionalFeesTotal + userFinancials[0].otherIncomeTotal,
              salary: this.salary,
              totalOutcome: userFinancials[0].rent + userFinancials[0].familyExpenses + userFinancials[0].credits + userFinancials[0].otherExpenses,
              professionalFees: userFinancials[0].professionalFees,
              otherIncome: userFinancials[0].otherIncome,
              otherIncomeDescription: userFinancials[0].otherIncomeDescription,
              rent: userFinancials[0].rent,
              familyExpenses: userFinancials[0].familyExpenses,
              credits: userFinancials[0].credits,
              otherExpenses: userFinancials[0].otherExpenses,
              publicResourcesHandling: userFinancials[0].publicResourcesHandling,
              otherAssets: userFinancials[0].otherAssets,
              otherAssetsDescription: userFinancials[0].otherAssetsDescription,
              otherLiabilities: userFinancials[0].otherLiabilities,
              otherLiabilitiesDescription: userFinancials[0].otherLiabilitiesDescription,
              originFunds: userFinancials[0].originFunds,
              otherExpensesDescription: userFinancials[0].otherExpensesDescription,
              userFinancialActive: userFinancials[0].userFinancialActive,
            }
            // update financialData
            this.financialData = financialData;
          }
          // resolve
          resolve(true);
        }, error=>{
          console.log('error', error);
          reject(error);
        });
      } catch (error) {
        console.log('error', error);
        // hide loader
        this.loaderEvent.emit(null);
        reject(error);
      }
    });
  }
  // company
  async getCompanyData() {
    return new Promise(async (resolve, reject) => {
      try {
        // check company
        if (this.userWorkData.companyId !== null) {
          // show loader
          this.loaderEvent.emit(this.translate.instant('HELPERS.loadingTxt') + ' ' + this.translate.instant('COMPANY.companyInfo'));
          // get companyId
          const companyId = this.userWorkData.companyId;
          // get data from api
          await this.apiSv.getCompany(companyId).then(async (companyResponse: any)=>{
            // get company
            const company: CompanyInterface = companyResponse.data;
            // set companyId
            company.id = companyId;
            // check companyCellphoneCountryCode
            if (company.companyCellphoneCountryCode !== null) {
              const auxCellphoneCountry: any = await this.apiSv.getCountry(company.companyCellphoneCountryCode);
              const cellphoneCountry: CountryInterface = auxCellphoneCountry.data;
              company.companyCellphoneCountryName = cellphoneCountry.countryName;
              company.companyCellphoneCountryFlag = 'assets/images/flags/' + cellphoneCountry.countryFlag + '.png';
              company.companyCellphoneCountryCodeCod = cellphoneCountry.numericCode;
            }
            // check companyPhoneCountryCode
            if (company.companyPhoneCountryCode !== null) {
              const auxCellphoneCountry: any = await this.apiSv.getCountry(company.companyPhoneCountryCode);
              const cellphoneCountry: CountryInterface = auxCellphoneCountry.data;
              company.companyPhoneCountryName = cellphoneCountry.countryName;
              company.companyPhoneCountryFlag = 'assets/images/flags/' + cellphoneCountry.countryFlag + '.png';
              company.companyPhoneCountryCodeCod = cellphoneCountry.numericCode;
            }
            // check companyWhatsappCountryCode
            if (company.companyWhatsappCountryCode !== null) {
              const auxCellphoneCountry: any = await this.apiSv.getCountry(company.companyWhatsappCountryCode);
              const cellphoneCountry: CountryInterface = auxCellphoneCountry.data;
              company.companyWhatsappCountryName = cellphoneCountry.countryName;
              company.companyWhatsappCountryFlag = 'assets/images/flags/' + cellphoneCountry.countryFlag + '.png';
              company.companyWhatsappCountryCodeCod = cellphoneCountry.numericCode;
            }
            // set company data
            this.companyId = company.id;
            this.companyData = company;
            // set businessName data
            this.businessName = company.businessName;
            // set companyApproval
            this.companyApproval = company.companyApprovalRequired;
            // set companyAmountEdit
            this.companyAmountEdit = company.companyAmountEdit;
            // resolve
            resolve(true);
          }, error=>{
            console.log('error', error);
            reject(error);
          });
        }
      } catch (error) {
        console.log('error', error);
        // hide loader
        this.loaderEvent.emit(null);
        reject(error);
      }
    });
  }
  async getOtherCompanyData() {
    return new Promise(async (resolve, reject) => {
      try {
        // check otherCompany
        if (this.userWorkData.otherCompanyId !== null) {
          // show loader
          this.loaderEvent.emit(this.translate.instant('HELPERS.loadingTxt') + ' ' + this.translate.instant('COMPANY.companyInfo'));
          // get otherCompanyId
          const otherCompanyId = this.userWorkData.otherCompanyId;
          // check otherCompanyId
          if (otherCompanyId !== null) {
            // get data from api
            await this.apiSv.getOtherCompany(otherCompanyId).then(async (companyResponse: any)=>{
              // get company
              const company: OtherCompanyInterface = companyResponse.data;
              // update businessName
              company.businessName = company.companyName;
              // set company data
              this.companyId = company.id;
              this.companyData = company;
              // resolve
              resolve(true);
            }, error=>{
              console.log('error', error);
              reject(error);
            });
          }
        }
      } catch (error) {
        console.log('error', error);
        // hide loader
        this.loaderEvent.emit(null);
        reject(error);
      }
    });
  }
  // product
  async getProductData() {
    return new Promise(async (resolve, reject) => {
      try {
        // show loader
        this.loaderEvent.emit(this.translate.instant('HELPERS.loadingTxt') + ' ' + this.translate.instant('PRODUCT.product'));
        // get productId
        const productId = this.productId;
        // check product id
        if (productId !== null) {
          // get data from api
          await this.apiSv.getProduct(productId).then(async (productResponse: any)=>{
            // get product
            const product: ProductInterface = productResponse.data;
            // set product data
            this.productData = product;
            this.productName = product.productName;
            this.productAmountMin = product.loanAmountMin;
            this.productAmountMax = product.loanAmountMax;
            this.productAmountMaxLimit = product.loanAmountMaxLimit;
            this.productMonthMin = product.loanMonthMin;
            this.productMonthMax = product.loanMonthMax;
            // check loanInterest
            if ((this.loanInterest === 0 || this.loanInterest === null) && product.loanInterest > 0) {
              // set loanInterest
              this.loanInterest = product.loanInterest;
              this.calcInterestFrm.controls.loanInterest.setValue(product.loanInterest);
            }
            // resolve
            resolve(true);
          }, error=>{
            console.log('error', error);
            // hide loader
            this.loaderEvent.emit(null);
            reject(error);
          });
        } else {
          // resolve
          resolve(true);
        }
      } catch (error) {
        console.log('error', error);
        // hide loader
        this.loaderEvent.emit(null);
        reject(error);
      }
    });
  }
  // actions
  async setMinMax() {
    // get loan data
    const salary = this.salary;
    const loanAmountMin = this.loanAmountMin;
    const loanAmountMax = this.loanAmountMax;
    const loanAmountMaxLimit = this.loanAmountMaxLimit;
    const loanMonthMin = this.loanMonthMin;
    let loanMonthMax = this.loanMonthMax;
    const loanMonthSteps = this.loanMonthSteps;
    // init new min max amount
    let newAmountMin: number = 0;
    let newAmountMax: number = 0;
    // check for limit
    if (loanAmountMaxLimit) {
      // set new min max amount
      newAmountMin = loanAmountMin;
      newAmountMax = loanAmountMax;
    } else {
      // set new min max amount
      newAmountMin = loanAmountMin;
      newAmountMax = salary * loanAmountMax;
    }
    // check last status
    if (this.lastStatusNumber === 4) {
      this.amount = this.loanAmount;
      this.months = this.loanPaymentMonths;
      // check max amount requested
      if (this.loanAmount > newAmountMax) {
        newAmountMax = this.loanAmount;
      }
      // check max months requested
      if (this.loanPaymentMonths > loanMonthMax) {
        loanMonthMax = this.loanPaymentMonths;
      }
    } else if (this.lastStatusNumber === 6) {
      this.amount = this.loanAmountPreApproved;
      this.months = this.loanPaymentMonthsPreApproved;
    } else if (this.lastStatusNumber >= 7) {
      this.amount = this.loanAmountApproved;
      this.months = this.loanPaymentMonthsApproved;
    }
    // update amount range data
    this.amountMin = newAmountMin;
    this.amountMax = newAmountMax;
    // update months data
    this.monthMin = loanMonthMin;
    this.monthMax = loanMonthMax;
    this.monthSteps = loanMonthSteps;  
    // check for companyApproval
    if (this.companyApproval && this.lastStatusNumber == 6) {
      this.companyAmountMin = this.amountMin;
      this.companyMonthMin = this.monthMin;
      this.companyMonthSteps = this.monthSteps;
      this.companyAmountMax = this.loanAmountPreApproved;
      this.companyMonthMax = this.loanPaymentMonthsPreApproved;
    }
  }
  async calcStatus() {
    // get lastStatus
    const lastStatus = this.lastStatus;
    // get statusIndex
    const statusIndex: any = this.statuses.findIndex((status: any) => status.statusName === lastStatus); 
    // get status
    const status: any = this.statuses[statusIndex];
    // set lastStatusNumber
    this.lastStatusNumber = status.statusNumber;
  }
  async calcScore() {
    // get score
    const score: number = this.score;
    // check score
    if (score > 0 && score <= (this.scoreSection * 1)) {
      // update class
      this.scoreClass = 'score-low-low';
    } else if (score > (this.scoreSection * 1) && score <= (this.scoreSection * 2)) {
      // update class
      this.scoreClass = 'score-low-mid';
    } else if (score > (this.scoreSection * 2) && score <= (this.scoreSection * 3)) {
      // update class
      this.scoreClass = 'score-low-high';
    } else if (score > (this.scoreSection * 3) && score <= (this.scoreSection * 4)) {
      // update class
      this.scoreClass = 'score-mid-low';
    } else if (score > (this.scoreSection * 4) && score <= (this.scoreSection * 5)) {
      // update class
      this.scoreClass = 'score-mid-mid';
    } else if (score > (this.scoreSection * 5) && score <= (this.scoreSection * 6)) {
      // update class
      this.scoreClass = 'score-mid-high';
    } else if (score > (this.scoreSection * 6) && score <= (this.scoreSection * 7)) {
      // update class
      this.scoreClass = 'score-high-low';
    } else if (score > (this.scoreSection * 7) && score <= (this.scoreSection * 8)) {
      // update class
      this.scoreClass = 'score-high-mid';
    } else if (score > (this.scoreSection * 8)) {
      // update class
      this.scoreClass = 'score-high-hig';
    }
  }
  async evaluateActions() {
    // get status data
    const loanStatus = this.lastStatus;
    const statusNumber = this.lastStatusNumber;
    // checkInitialDocuments
    const checkInitialDocumentsRole = await this.utilitiesSv.checkRoles(this.userData.role, ['support', 'administrator', 'businessAgent']);
    const checkInitialDocuments = (checkInitialDocumentsRole && (statusNumber === 0 || statusNumber === 1));
    this.enableEditInitialDocumentsData = checkInitialDocuments;
    this.enableSendInitialDocumentsData = checkInitialDocuments;
    this.enableAddOtherDocumentsData = checkInitialDocuments;

    // checkValidation
    const checkValidationRole = await this.utilitiesSv.checkRoles(this.userData.role, ['support', 'administrator', 'businessAgent']);
    const checkValidation = (checkValidationRole && (statusNumber === 2));
    this.enableEditValidationData = checkValidation;

    // checkBorrowingCapacity
    const checkBorrowingCapacityRole = await this.utilitiesSv.checkRoles(this.userData.role, ['support', 'administrator', 'board']);
    const checkBorrowingCapacity = (checkBorrowingCapacityRole && ((statusNumber === 4) && loanStatus !== 'readyForDisbursement'));
    this.enableEditBorrowingCapacityData = checkBorrowingCapacity;
    // checkPreApproval
    const checkPreApprovalRole = await this.utilitiesSv.checkRoles(this.userData.role, ['support', 'administrator', 'board']);
    const checkPreApproval = (checkPreApprovalRole && (loanStatus === 'validated' && this.companyApproval));
    this.enableEditPreApprovalData = checkPreApproval;

    // checkCompanyApproval
    const checkCompanyApprovalRole = await this.utilitiesSv.checkRoles(this.userData.role, ['support', 'ceo', 'manager']);
    const checkCompanyApproval = (checkCompanyApprovalRole && (loanStatus === 'readyToApprove' && this.companyApproval));
    this.enableEditCompanyApprovalData = checkCompanyApproval;
    this.editEnablePromissoryFileData = checkCompanyApproval;
    this.editEnableInstructionsFileData = checkCompanyApproval;
    this.editEnableAuthorizationFileData = checkCompanyApproval;
    this.editEnableCompanyPromissoryFileData = checkCompanyApproval;

    // checkApproval
    const checkApprovalRole = await this.utilitiesSv.checkRoles(this.userData.role, ['support', 'administrator', 'board']);
    const checkApproval = (checkApprovalRole && (loanStatus === 'validated' && !this.companyApproval));
    this.enableEditApprovalData = checkApproval;

    // checkDataCreditoFiles
    const checkDataCreditoFilesRole = await this.utilitiesSv.checkRoles(this.userData.role, ['support', 'administrator', 'businessAgent']);
    this.editEnableProfileFileData = checkDataCreditoFilesRole && (statusNumber === 2);
    this.editEnableSynthesisFileData = checkDataCreditoFilesRole && (statusNumber === 2);
    
    // checkDataCreditoDetailedFiles
    const checkDataCreditoDetailedFilesRole = await this.utilitiesSv.checkRoles(this.userData.role, ['support', 'administrator', 'board']);
    this.editEnableDetailedFileData = checkDataCreditoDetailedFilesRole && (statusNumber === 4);

    // checkCompanyDisbursementPreparation
    const checkCompanyDisbursementPreparationRole = await this.utilitiesSv.checkRoles(this.userData.role, ['support', 'administrator', 'treasure', 'businessAgent']);
    const checkCompanyDisbursementPreparation = (checkCompanyDisbursementPreparationRole && (loanStatus === 'preApproved' && this.companyApproval));
    this.enableEditCompanyDisbursementData = checkCompanyDisbursementPreparation;

    // checkFinancialDisbursementPreparation
    const checkFinancialDisbursementPreparationRole = await this.utilitiesSv.checkRoles(this.userData.role, ['support', 'administrator', 'treasure', 'businessAgent']);
    const checkFinancialDisbursementPreparation = (checkFinancialDisbursementPreparationRole && (loanStatus === 'approved' && !this.companyApproval));
    this.enableEditFinancialDisbursementData = checkFinancialDisbursementPreparation;

    // checkFinancialDisbursementPreparationFiles
    const checkFinancialDisbursementPreparationFilesRole = await this.utilitiesSv.checkRoles(this.userData.role, ['support', 'administrator', 'businessAgent']);
    const checkFinancialDisbursementPreparationFiles = (checkFinancialDisbursementPreparationFilesRole && ((loanStatus === 'preApproved' || loanStatus === 'approved')));

    this.editEnablePromissoryFileData = checkFinancialDisbursementPreparationFiles;
    this.editEnableInstructionsFileData = checkFinancialDisbursementPreparationFiles;
    this.editEnableAuthorizationFileData = checkFinancialDisbursementPreparationFiles;

    // // checkFinancialDisbursementCompanyPreparationFiles
    // const checkFinancialDisbursementCompanyPreparationFilesRole = await this.utilitiesSv.checkRoles(this.userData.role, ['support', 'administrator', 'businessAgent']);
    // const checkFinancialDisbursementCompanyPreparationFiles = (checkFinancialDisbursementCompanyPreparationFilesRole && ((loanStatus === 'preApproved' || loanStatus === 'approved') && this.companyApproval));
    // this.editEnablePromissoryFileData = checkFinancialDisbursementCompanyPreparationFiles;
    // this.editEnableInstructionsFileData = checkFinancialDisbursementCompanyPreparationFiles;
    // this.editEnableAuthorizationFileData = checkFinancialDisbursementCompanyPreparationFiles;

    // // checkFinancialDisbursementPreparationFiles
    // const checkFinancialDisbursementPreparationFilesRole = await this.utilitiesSv.checkRoles(this.userData.role, ['support', 'administrator', 'businessAgent']);
    // const checkFinancialDisbursementPreparationFiles = (checkFinancialDisbursementPreparationFilesRole && ((loanStatus === 'preApproved' || loanStatus === 'approved') && !this.companyApproval));
    // this.editEnablePromissoryFileData = checkFinancialDisbursementPreparationFiles;
    // this.editEnableInstructionsFileData = checkFinancialDisbursementPreparationFiles;
    // this.editEnableAuthorizationFileData = checkFinancialDisbursementPreparationFiles;

    // checkDisbursement
    const checkDisbursementRole = await this.utilitiesSv.checkRoles(this.userData.role, ['support', 'administrator', 'board', 'treasure', 'businessAgent', 'ceo', 'manager', 'auxiliar']);
    const checkDisbursement = (checkDisbursementRole && (statusNumber === 9 || statusNumber === 10));
    this.editEnableDisbursementFileData = checkDisbursement;
    
    // checkValidationFiles
    const checkValidationFilesRole = await this.utilitiesSv.checkRoles(this.userData.role, ['support', 'administrator', 'businessAgent']);
    const checkValidationFiles = (checkValidationFilesRole && (statusNumber === 9 || statusNumber === 10));
    this.enableEdiValidationFilesData = checkValidationFiles;
    
    // checkDocumentFiles
    const checkDocumentFilesRole = await this.utilitiesSv.checkRoles(this.userData.role, ['support', 'administrator', 'businessAgent']);
    const checkDocumentFiles = (checkDocumentFilesRole && (statusNumber === 9 || statusNumber === 10));
    this.enableEditDocumentFilesData = checkDocumentFiles;

    // checkUser
    const checkUserRole = await this.utilitiesSv.checkRoles(this.userData.role, ['support', 'administrator']);
    const checkUser = (checkUserRole && ((statusNumber >= 0 && statusNumber <= 8) && !(statusNumber == 3 || statusNumber == 5)));
    this.enableEditUserData = checkUser;
    this.editEnableIdFrontFileData = checkUser;
    this.editEnableIdBackFileData = checkUser;
    this.editEnableRequestFileData = checkUser;
    this.editEnableBankFileData = checkUser;
    this.editEnableLaborFileData = checkUser;
    this.editEnablePaymentReceiptFiles = checkUser;
    this.editEnableRutFileData = checkUser;
    this.editEnableCCIOFileData = checkUser;
    this.editEnableRepIdFileData = checkUser;
    this.editEnablePGFileData = checkUser;
    this.editEnableBankExtractsFileData = checkUser;
    this.editEnablePensionerFileData = checkUser;
    this.editEnableUtilitiesFileData = checkUser;
    this.editEnableOtherDocument1FileData = checkUser;
    this.editEnableOtherDocument2FileData = checkUser;
    this.editEnableOtherDocument3FileData = checkUser;
    // checkCompany
    const checkCompanyRole = await this.utilitiesSv.checkRoles(this.userData.role, ['support', 'administrator', 'businessAgent']);
    const checkCompany = (checkCompanyRole && ((statusNumber >= 0 && statusNumber <= 8) && !(statusNumber == 3 || statusNumber == 5)));
    this.enableEditCompanyData = checkCompany;
    // checkWork
    const checkWorkRole = await this.utilitiesSv.checkRoles(this.userData.role, ['support', 'administrator', 'businessAgent']);
    const checkWork = (checkWorkRole && ((statusNumber >= 0 && statusNumber <= 8) && !(statusNumber == 3 || statusNumber == 5)));
    this.enableEditWorkData = checkWork;
    // checkFinancial
    const checkFinancialRole = await this.utilitiesSv.checkRoles(this.userData.role, ['support', 'administrator', 'businessAgent']);
    const checkFinancial = (checkFinancialRole && ((statusNumber >= 0 && statusNumber <= 8) && !(statusNumber == 3 || statusNumber == 5)));
    this.enableEditFinancialData = checkFinancial;
    // checkBankAccount
    const checkBankAccountRole = await this.utilitiesSv.checkRoles(this.userData.role, ['support', 'administrator', 'businessAgent']);
    const checkBankAccount = (checkBankAccountRole && ((statusNumber >= 0 && statusNumber <= 8) && !(statusNumber == 3 || statusNumber == 5)));
    this.enableEditBankAccountData = checkBankAccount;

    // console.log('this.userData.role', this.userData.role);
    // console.log('loanStatus', loanStatus);
    // console.log('this.companyApproval', this.companyApproval);

    // console.log('lastStatus', this.lastStatus);
    // console.log('statusNumber', statusNumber);
    // console.log('checkInitialDocuments', checkInitialDocuments);
    // console.log('checkValidation', checkValidation);
    // console.log('checkBorrowingCapacity', checkBorrowingCapacity);
    // console.log('checkPreApproval', checkPreApproval);
    // console.log('checkCompanyApproval', checkCompanyApproval);
    // console.log('checkCompanyDisbursementPreparation', checkCompanyDisbursementPreparation);
    // console.log('checkFinancialDisbursementPreparation', checkFinancialDisbursementPreparation);

    // check observation statuses
    if (((statusNumber >= 0 && statusNumber <= 15) && !(statusNumber == 3 || statusNumber == 5 || statusNumber == 12 || statusNumber == 16))) {
      // check for roles and statuses
      if (checkInitialDocuments || checkValidation || checkBorrowingCapacity || checkPreApproval || checkCompanyApproval || checkApproval || checkCompanyDisbursementPreparation  || checkFinancialDisbursementPreparation) {
        this.editEnableObservations = true;
      }
    }
  }
  async clearActions() {
    // files edit enabling
    this.enableEditInitialDocumentsData = null;
    this.enableSendInitialDocumentsData = null;
    this.enableAddOtherDocumentsData = null;
    this.editEnableIdFrontFileData = null;
    this.editEnableIdBackFileData = null;
    this.editEnableRequestFileData = null;
    this.editEnableBankFileData = null;
    this.editEnableLaborFileData = null;
    this.editEnablePromissoryFileData = null;
    this.editEnableInstructionsFileData = null;
    this.editEnableAuthorizationFileData = null;
    this.editEnableDisbursementFileData = null;
    this.editEnableAcceptanceFileData = null;
    this.editEnableCompanyPromissoryFileData = null;
    this.editEnableProfileFileData = null;
    this.editEnableSynthesisFileData = null;
    this.editEnableDetailedFileData = null;
    this.editEnablePaymentReceiptFiles = null;
    this.editEnableRutFileData = null;
    this.editEnableCCIOFileData = null;
    this.editEnableRepIdFileData = null;
    this.editEnablePGFileData = null;
    this.editEnableBankExtractsFileData = null;
    this.editEnablePensionerFileData = null;
    this.editEnableUtilitiesFileData = null;
    this.editEnableOtherDocument1FileData = null;
    this.editEnableOtherDocument2FileData = null;
    this.editEnableOtherDocument3FileData = null;
    // edit according statuses data
    this.enableEditValidationData = null;
    this.enableEditBorrowingCapacityData = null;
    this.enableEditPreApprovalData = null;
    this.enableEditCompanyApprovalData = null;
    this.enableEditApprovalData = null;
    this.enableEditCompanyDisbursementData = null;
    this.enableEditFinancialDisbursementData = null;
    this.enableEdiValidationFilesData = null;
    this.enableEditDocumentFilesData = null;
    // modules data
    this.enableEditUserData = null;
    this.enableEditCompanyData = null;
    this.enableEditWorkData = null;
    this.enableEditFinancialData = null;
    this.enableEditBankAccountData = null;
    this.editEnableObservations = null;
  }
  async calcBorrowingCapacity() {
    // get borrowingCapacity
    const maxBorrowingCapacityPercentage: number = this.borrowingCapacityFrm.controls.borrowingCapacity.value;
    // check for financialData
    if (this.financialData !== null) {
      // income
      const salary: number = this.salary;
      const professionalFeesTotal = this.financialData.professionalFees;
      const otherIncomeTotal = this.financialData.otherIncome;
      // outcome
      const rentTotal = this.financialData.rent;
      const familyExpensesTotal = this.financialData.familyExpenses;
      const creditsTotal = this.financialData.credits;
      const otherExpensesTotal = this.financialData.otherExpenses;
      // totals
      const totalIncome = salary + professionalFeesTotal + otherIncomeTotal;
      const totalOutcome = rentTotal + familyExpensesTotal;
      const totalDebts = creditsTotal + otherExpensesTotal;
      // monthly
      const monthlyIncome = totalIncome;
      const monthlyOutcome = totalOutcome;
      // calc
      const borrowingCapacityAvailable = monthlyIncome - monthlyOutcome - totalDebts;
      const borrowingCapacity = (borrowingCapacityAvailable * (maxBorrowingCapacityPercentage / 100));
      // check borrowingCapacity
      if (borrowingCapacity > 0) {
        // update borrowingCapacity
        this.borrowingCapacityPercentage = maxBorrowingCapacityPercentage;
        this.borrowingCapacity = borrowingCapacity;
        // update totals
        this.financialData.totalIncome = salary + professionalFeesTotal + otherIncomeTotal;
        this.financialData.totalOutcome = rentTotal + familyExpensesTotal + creditsTotal + otherExpensesTotal;
      }
    }
  }
  async initCalcInterest() {
    // markAllAsTouched
    this.calcInterestFrm.markAllAsTouched();
    // validate data
    if (this.calcInterestFrm.valid) {
      // init amount & months form check
      let amountMonthsCheck = false;
      // check approval
      if (this.lastStatus === 'validated' && (!this.companyApproval || this.companyApproval === null)) {
        // markAllAsTouched
        this.approvalFrm.markAllAsTouched();
        // validate data
        if (this.approvalFrm.valid) {
          amountMonthsCheck = true;
        }
      } else if ((this.lastStatus === 'validated' || this.lastStatus === 'readyToApprove') && this.companyApproval) {
        // markAllAsTouched
        this.preApprovalFrm.markAllAsTouched();
        // validate data
        if (this.preApprovalFrm.valid) {
          amountMonthsCheck = true;
        }
      }
      // check amountMonthsCheck
      if (amountMonthsCheck) {
        this.singlePaymentCalculated = true;
        this.calcBorrowingCapacity();
        this.calcInterest();
      }
    }
  }
  async reCalcInterest() {
    // markAllAsTouched
    this.calcInterestFrm.markAllAsTouched();
    // validate data
    if (this.calcInterestFrm.valid) {
      this.singlePaymentCalculated = false;
      await this.calcInterest();
      this.singlePaymentCalculated = true;
    }
  }
  async calcInterest() {
    // check if interest is been seted
    if (!this.singlePaymentCalculated) {
      // get loanInterest
      const loanInterest = this.loanInterest;
      // get amount
      const amount = this.amount;
      // get months
      const months = this.months;
      // check data to calculate
      if (loanInterest > 0 && amount > 0 && months > 0) {
        // init calc
        const numerator = amount * Math.pow((1 + (loanInterest) / 100), months) * (loanInterest) / 100;
        const denominator = Math.pow((1 + (loanInterest) / 100), months) - 1;
        const singlePayment = numerator / denominator;
        // update singlePayment
        this.singlePayment = singlePayment;
        this.singlePaymentMin = singlePayment;
        // update rates
        this.loanMonthlyRate = loanInterest;
        const base = 1 + loanInterest;
        const exponent = 12;
        const loanAnualRate = base ** exponent - 1;
        this.loanAnualRate = loanAnualRate;

        // TODO: CHECK RECALC

        console.log('denominator', denominator);
        console.log('numerator', numerator);
        console.log('denominator', denominator);
        console.log('singlePayment', singlePayment);
        console.log('base', base);
        console.log('exponent', exponent);
        console.log('loanAnualRate', loanAnualRate);
  
        // =(amount*((1+loanInterest)^months)*loanInterest)/(((1+loanInterest)^months)-1)
        
        // // set monthlyEffectiveRate
        // const monthlyEffectiveRate = Math.pow(1 + (loanInterest/100), 1 / 12) - 1;
        // // calc monthly payment
        // const numerator = monthlyEffectiveRate * Math.pow(1 + monthlyEffectiveRate, months);
        // const denominator = Math.pow(1 + monthlyEffectiveRate, months) - 1;
        // const finalResult = (amount * numerator) / denominator;
        // const singlePayment = finalResult;
        // // update singlePayment
        // this.singlePayment = singlePayment;
      }
    }
  }
  async setApprovalValues() {
    setTimeout(() => {
      // check for companyApproval
      if (this.companyApproval) {
        // check if loan is been approved
        if (this.lastStatusNumber >= 7) {
          // update pre-approval form data
          this.preApprovalFrm.controls.preAmount.setValue(this.loanAmountPreApproved);
          this.preApprovalFrm.controls.preMonths.setValue(this.loanPaymentMonthsPreApproved);
          // update approval form data
          this.approvalFrm.controls.amount.setValue(this.loanAmountApprovedCompany);
          this.approvalFrm.controls.months.setValue(this.loanPaymentMonthsApprovedCompany);
        } else if (this.lastStatusNumber < 7) {
          // check if loan pass validation or is not rejected 
          if (!((this.lastStatusNumber == 3) || (this.lastStatusNumber == 5))) {
            // check if loan is pre-approved
            if (this.lastStatusNumber == 6) {
              // update pre-approval form data
              this.preApprovalFrm.controls.preAmount.setValue(this.loanAmountPreApproved);
              this.preApprovalFrm.controls.preMonths.setValue(this.loanPaymentMonthsPreApproved);
              // get approved company data
              const loanAmountApprovedCompany = this.loanAmountApprovedCompany === 0 ? this.loanAmountPreApproved : this.loanAmountApprovedCompany;
              const loanPaymentMonthsApprovedCompany = this.loanPaymentMonthsApprovedCompany === 0 ? this.loanPaymentMonthsPreApproved : this.loanPaymentMonthsApprovedCompany;
              // update approval form data
              this.approvalFrm.controls.amount.setValue(loanAmountApprovedCompany);
              this.approvalFrm.controls.months.setValue(loanPaymentMonthsApprovedCompany);
            } else {
              // update pre-approval form data
              this.preApprovalFrm.controls.preAmount.setValue(this.loanAmount);
              this.preApprovalFrm.controls.preMonths.setValue(this.loanPaymentMonths);
              // update approval form data
              this.approvalFrm.controls.amount.setValue(this.loanAmount);
              this.approvalFrm.controls.months.setValue(this.loanPaymentMonths);
            }
          }
        }
      } else {
        // check if loan is been approved
        if (this.lastStatusNumber >= 7) {
          // update approval form data
          this.approvalFrm.controls.amount.setValue(this.loanAmountApproved);
          this.approvalFrm.controls.months.setValue(parseInt(this.loanPaymentMonthsApproved));
        } else if (this.lastStatusNumber < 7) {
          // check if loan pass validation or is not rejected 
          if (!((this.lastStatusNumber == 3) || (this.lastStatusNumber == 5))) {
            // check if loan is pre-approved
            if (this.lastStatusNumber == 6) {
              // update approval form data
              this.approvalFrm.controls.amount.setValue(this.loanAmountApproved);
              this.approvalFrm.controls.months.setValue(parseInt(this.loanPaymentMonthsApproved));
              
            } else {
              // update approval form data
              this.approvalFrm.controls.amount.setValue(this.loanAmount);
              this.approvalFrm.controls.months.setValue(parseInt(this.loanPaymentMonths));
            }
          }
        }
      }
    }, 1000);
  }
  async copyHash(hash: string) {
    navigator.clipboard.writeText(hash);
  }
  async showToken(modal: any) {
    this.modalService.open(modal);
  }
  async showConfirmation(nextFunction: string, title: string, text: string) {
    // show alert to go next step
    Swal.fire({
      icon: 'warning',
      title,
      text,
      confirmButtonText: this.translate.instant('HELPERS.yes'),
      showCancelButton: true,
      cancelButtonText: this.translate.instant('HELPERS.no'), 
      customClass: {
        cancelButton: 'btn-swal-cancel'
      },
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        // check nextFunction
        switch (nextFunction) {
          case 'initialDocumentsUncomplete':
            this.saveInitialDocuments();
            break;
          case 'initialDocuments':
            this.saveInitialDocuments();
            break;
          case 'noPassValidation':
            this.saveNoPassValidation();
            break;
          case 'validation':
            this.saveValidation();
            break;
          case 'reject':
            this.saveReject();
            break;
          case 'preApproval':
            this.savePreApproval();
            break;
          case 'companyDisbursementPreparation':
            this.saveCompanyDisbursementPreparation();
            break;
          case 'disbursementPreparation':
            this.saveDisbursementPreparation();
            break;
          case 'companyApproval':
            this.saveCompanyApproval();
            break;
          case 'approval':
            this.saveApproval();
            break;
        }
      } else {
        // check if is initial documents uncomplete
        if (nextFunction === 'initialDocumentsUncomplete') {
          // send alert
          this.alertSv.showMessage(this.translate.instant('LOAN.loanDocumentCheck'),'error', this.translate.instant('HELPERS.error'), true);
        }
      }
    })
  }
  // files
  async fileAction(fileAction: any) {
    const target = fileAction.target;
    const data = fileAction.data;
    // check target and update data
    switch (target) {
      case 'idFront':
        this.idFrontFileData = data;
        break;
      case 'idBack':
        this.idBackFileData = data;
        break;
      case 'bank':
        this.userBankAccounts[0].certificateFileData = data;
        this.bankFileData = data;
        break;
      case 'request':
        this.requestFileData = data;
        break;
      case 'labor':
        this.laborFileData = data;
        break;
      case 'promissory':
        // update promissoryFileData
        this.promissoryFileData = data;
        // update loanPromissoryLoaded
        this.loanPromissoryLoaded = true;
        // set loanPromissoryData
        const loanPromissoryData: LoanInterface = {
          loanPromissoryLoaded: true,
        } 
        // update loanPromissoryLoaded on api
        await this.apiSv.updateLoan(this.loanId, loanPromissoryData);
        break;
      case 'authorization':
        this.authorizationFileData = data;
        break;
      case 'disbursement':
        this.disbursementFileData = data;
        break;
      case 'loanPayment':
        this.loanPaymentFileData = data;
        break;
      case 'acceptance':
        this.acceptanceFileData = data;
        break;
      case 'companyPromissory':
        // update companyPromissoryFileData
        this.companyPromissoryFileData = data;
        // update loanManagerSignature
        this.loanManagerSignature = true;
        // set loanCompanyPromissoryData
        const loanCompanyPromissoryData: LoanInterface = {
          loanManagerSignature: true,
        } 
        // update loanManagerSignature on api
        await this.apiSv.updateLoan(this.loanId, loanCompanyPromissoryData);
        break;
      case 'profile':
        // update profileFileData
        this.profileFileData = data;
        // update loanProfileValidation
        this.loanProfileValidation = true;
        // set loanProfileData
        const loanProfileData: LoanInterface = {
          loanProfileValidation: true,
        } 
        // update loanProfileValidation on api
        await this.apiSv.updateLoan(this.loanId, loanProfileData);
        break;
      case 'synthesis':
        // update synthesisFileData
        this.synthesisFileData = data;
        // update loanSynthesisValidation
        this.loanSynthesisValidation = true;
        // set loanSynthesisData
        const loanSynthesisData: LoanInterface = {
          loanSynthesisValidation: true,
        } 
        // update loanSynthesisValidation on api
        await this.apiSv.updateLoan(this.loanId, loanSynthesisData);
        break;
      case 'detailed':
        // update detailedFileData
        this.detailedFileData = data;
        // update loanDetailedValidation
        this.loanDetailedValidation = true;
        // set loanDetailedData
        const loanDetailedData: LoanInterface = {
          loanDetailedValidation: true,
        } 
        // update loanDetailedValidation on api
        await this.apiSv.updateLoan(this.loanId, loanDetailedData);
        break;
      case 'paymentReceipts':
        // update paymentReceiptFile
        this.paymentReceiptFiles = data;
        break;
      case 'rut':
        // update rutFileData
        this.rutFileData = data;
        break;
      case 'ccio':
        // update ccioFileData
        this.ccioFileData = data;
        break;
      case 'repId':
        // update repIdFileData
        this.repIdFileData = data;
        break;
      case 'pg':
        // update pgFileData
        this.pgFileData = data;
        break;
      case 'bankExtracts':
        // update bankExtractsFileData
        this.bankExtractsFileData = data;
        break;
      case 'pensioner':
        // update pensionerFileData
        this.pensionerFileData = data;
        break;
      case 'utilities':
        // update utilitiesFileData
        this.utilitiesFileData = data;
        break;
      case 'otherDocument1':
        // update otherDocument1FileData
        this.otherDocument1FileData = data;
        break;
      case 'otherDocument2':
        // update otherDocument2FileData
        this.otherDocument2FileData = data;
        break;
      case 'otherDocument3':
        // update otherDocument3FileData
        this.otherDocument3FileData = data;
        break;
    }
  }
  // cancelation
  async saveCancelation() {
    // saveLoanStatus
    await this.saveLoanStatus('canceled', -1);
  }
  // initial documents
  async checkInitialDocuments() {
    // check workType
    if (this.workType === 'business') {
      // check documents
      if (this.idFrontFileData.filePath === null || this.bankFileData.filePath === null || this.rutFileData.filePath === null || this.ccioFileData.filePath === null || this.bankExtractsFileData.filePath === null || this.pgFileData.filePath === null ) {
        // show confirmation
        this.showConfirmation('initialDocumentsUncomplete', this.translate.instant('LOAN.initialDocumentsTitle'), this.translate.instant('LOAN.loanDocumentCheckContinue'));
      } else {
        this.showConfirmation('initialDocuments', this.translate.instant('LOAN.initialDocumentsTitle'), this.translate.instant('LOAN.initialDocumentsAsk'));
      }
    } else if (this.workType === 'employee') {
      // check documents
      if (this.idFrontFileData.filePath === null || this.bankFileData.filePath === null || this.laborFileData.filePath === null) {
        // show confirmation
        this.showConfirmation('initialDocumentsUncomplete', this.translate.instant('LOAN.initialDocumentsTitle'), this.translate.instant('LOAN.loanDocumentCheckContinue'));
      } else {
        // init paymentReceiptFileCount
        let paymentReceiptFileCount: number = 0;
        // get loanPaymentReceipts
        const loanPaymentReceipts = this.loanPaymentReceipts;
        // check paymentReceiptFiles
        for (let i = 0; i < this.paymentReceiptFiles.length; i++) {
          // check paymentReceiptFile
          if (this.paymentReceiptFiles[i].paymentReceiptFilePath !== null) {
            // increase paymentReceiptFileCount
            paymentReceiptFileCount ++;
          }
        }
        // compare loanPaymentReceipts with paymentReceiptFileCount
        if (loanPaymentReceipts === paymentReceiptFileCount) {
          // show confirmation
          this.showConfirmation('initialDocumentsUncomplete', this.translate.instant('LOAN.initialDocumentsTitle'), this.translate.instant('LOAN.loanDocumentCheckContinue'));
        } else {
          // send alert
          this.alertSv.showMessage(this.translate.instant('LOAN.loanDocumentPaymentReceiptCheck'),'error', this.translate.instant('HELPERS.error'), true);
        }
      }
    } else if (this.workType === 'entrepreneur') {
      // check documents
      if (this.idFrontFileData.filePath === null || this.bankFileData.filePath === null || this.rutFileData.filePath === null || this.bankExtractsFileData.filePath === null) {
        // show confirmation
        this.showConfirmation('initialDocumentsUncomplete', this.translate.instant('LOAN.initialDocumentsTitle'), this.translate.instant('LOAN.loanDocumentCheckContinue'));
      } else {
        // show confirmation
        this.showConfirmation('initialDocuments', this.translate.instant('LOAN.initialDocumentsTitle'), this.translate.instant('LOAN.initialDocumentsAsk'));
      }
    } else if (this.workType === 'home') {
      // check documents
      if (this.idFrontFileData.filePath === null || this.bankFileData.filePath === null || this.bankExtractsFileData.filePath === null) {
        // show confirmation
        this.showConfirmation('initialDocumentsUncomplete', this.translate.instant('LOAN.initialDocumentsTitle'), this.translate.instant('LOAN.loanDocumentCheckContinue'));
      } else {
        // show confirmation
        this.showConfirmation('initialDocuments', this.translate.instant('LOAN.initialDocumentsTitle'), this.translate.instant('LOAN.initialDocumentsAsk'));
      }
    } else if (this.workType === 'student') {
      // check documents
      if (this.idFrontFileData.filePath === null || this.bankFileData.filePath === null || this.bankExtractsFileData.filePath === null) {
        // show confirmation
        this.showConfirmation('initialDocumentsUncomplete', this.translate.instant('LOAN.initialDocumentsTitle'), this.translate.instant('LOAN.loanDocumentCheckContinue'));
      } else {
        // show confirmation
        this.showConfirmation('initialDocuments', this.translate.instant('LOAN.initialDocumentsTitle'), this.translate.instant('LOAN.initialDocumentsAsk'));
      }
    } else if (this.workType === 'pensioner') {
      // check documents
      if (this.idFrontFileData.filePath === null || this.bankFileData.filePath === null || this.pensionerFileData.filePath === null) {
        // show confirmation
        this.showConfirmation('initialDocumentsUncomplete', this.translate.instant('LOAN.initialDocumentsTitle'), this.translate.instant('LOAN.loanDocumentCheckContinue'));
      } else {
        // show confirmation
        this.showConfirmation('initialDocuments', this.translate.instant('LOAN.initialDocumentsTitle'), this.translate.instant('LOAN.initialDocumentsAsk'));
      }
    }
  }
  async saveInitialDocuments() {
    // saveLoanStatus
    await this.saveLoanStatus('accepted', 2);
    // go to start
    this.goToByScroll('myBody');
  }
  async sendFirstSteps() {
    // set mglnkData
    const mglnkData = {
      // set email
      email: this.loanUserData.email,
      // set link data
      module: 'loans',
      submodule: 'detail',
      id: this.loanNumber,
    }
    // set encodedMglnk
    const encodedMglnk = this.encodingSv.encodeBTOA(JSON.stringify(mglnkData));
    // set sendData
    const sendData = {
      workActivity: this.userWorkData.workType,
      loanPaymentReceipts: this.productData.loanPaymentReceipts,
      loanNumber: this.loanNumber,
      firstName: this.loanUserData.firstName,
      lastName: this.userData.lastName,
      email: this.loanUserData.email,
      productName: this.productData !== null && this.productData.productName ? this.productData.productName : this.translate.instant('PRODUCT.productFree'),
      loanUri: this.appUri + '/mglnk/' + encodedMglnk,
    }
    // init auxSendEmail
    await this.apiSv.sendFirstSteps(sendData);
    // check for status
    if (this.loanStatusLast === 'started') {
      // get currentDateTime
      const currentDateTime = moment().toISOString();
      // set loanListStatusData
      const loanListStatusData: LoanStatusInterface = {
        loanId: this.loanId,
        user: this.userData,
        loanStatus: 'sended',
        loanStatusObservations: null,
        loanStatusDate: currentDateTime,
        loanNoPassedValidationRejectOption: null
      }
      // push new status to loan statuses
      this.loanStatuses.push(loanListStatusData);
      // set loanStatusData
      const loanStatusData: LoanStatusInterface = {
        loanId: this.loanId,
        userId: this.userData.id,
        loanStatus: 'sended',
        loanStatusObservations: null,
        loanNoPassedValidationRejectOption: null
      }
      // add loanStatus
      await this.apiSv.addLoanStatus(loanStatusData);
      // set loanData
      const loanData: LoanInterface = {
        loanStatusLast: 'sended',
        loanStatusDateLast: currentDateTime,
      }
      // update loanStatus on loan
      await this.apiSv.updateLoan(this.loanId, loanData);
      // update local loanStatus
      this.lastStatus = 'sended';
      this.lastStatusNumber = 1;
    }
  }
  // validation
  async checkValidation() {
    // markAllAsTouched
    this.scoreFrm.markAllAsTouched;
    // check scoreFrm
    if (!this.scoreFrm.valid) {
      // send alert
      this.alertSv.showMessage(this.translate.instant('LOAN.loanScoreCheck'),'error', this.translate.instant('HELPERS.error'), true);
    } else {
      // check documents
      if (this.synthesisFileData.filePath === null) {
        // send alert
        this.alertSv.showMessage(this.translate.instant('LOAN.loanDocumentCheck'),'error', this.translate.instant('HELPERS.error'), true);
      } else {
        this.showConfirmation('validation', this.translate.instant('LOAN.validationTitle'), this.translate.instant('LOAN.validationPassValidation'));
      }
    }
  }
  async saveValidation() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // set loanData
      const loanData: LoanInterface = {
        loanValidationScore: this.scoreFrm.controls.score.value,
        loanSynthesisValidation: true
      } 
      // update loanStatus
      await this.apiSv.updateLoan(this.loanId, loanData);
      // update score
      this.score = this.scoreFrm.controls.score.value;
      this.calcScore();
      // saveLoanStatus
      await this.saveLoanStatus('validated', 4);
      // go to start
      this.goToByScroll('myBody');
      // hide loader
      this.loaderEvent.emit(null);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async addDetailValidation() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // set loanData
      const loanData: LoanInterface = {
        loanDetailedValidation: true,
      }
      // update loanDetailedValidation
      await this.apiSv.updateLoan(this.loanId, loanData);
      // update loanDetailedValidation
      this.loanDetailedValidation = true;
      // hide loader
      this.loaderEvent.emit(null);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async removeDetailValidation() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // set loanData
      const loanData: LoanInterface = {
        loanDetailedValidation: false,
      }
      // update loanDetailedValidation
      await this.apiSv.updateLoan(this.loanId, loanData);
      // update loanDetailedValidation
      this.loanDetailedValidation = false;
      // hide loader
      this.loaderEvent.emit(null);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  // noPassValidation
  async openNoPassValidation(modal: any) {
    // open modal to select reject option
    this.modalService.open(modal);
  }
  async dismissNoPassValidation() {
    // hide modal
    this.modalService.dismissAll();
  }
  async checkNoPassValidation() {
    this.showConfirmation('noPassValidation', this.translate.instant('LOAN.validationTitle'), this.translate.instant('LOAN.validationNoPassValidation'));
  }
  async saveNoPassValidation() {
    // get noPassedValidationOption
    const noPassedValidationOption = this.observationFrm.controls.observation.value;
    // saveLoanStatus
    await this.saveLoanStatus('noPassedValidation', 3, noPassedValidationOption);
    // send noPassedValidationMsg
    await this.sendNoPassValidationMsg();
  }
  async sendNoPassValidationMsg() {
    // set mglnkData
    const mglnkData = {
      // set email
      email: this.loanUserData.email,
      // set link data
      module: 'loans',
      submodule: 'detail',
      id: this.loanNumber,
    }
    // set encodedMglnk
    const encodedMglnk = this.encodingSv.encodeBTOA(JSON.stringify(mglnkData));
    // set sendData
    const sendData = {
      firstName: this.loanUserData.firstName,
      email: this.loanUserData.email,
      productName: this.productData !== null && this.productData.productName ? this.productData.productName : this.translate.instant('PRODUCT.productFree'),
      loanUri: this.appUri + '/mglnk/' + encodedMglnk,
    }
    // send
    await this.apiSv.sendNoPassValidationMsg(sendData);
  }
  // rejection
  async dismissReject() {
    // hide modal
    this.modalService.dismissAll();
  }
  async checkReject() {
    this.showConfirmation('reject', this.translate.instant('LOAN.rejectTitle'), this.translate.instant('LOAN.rejectAsk'));
  }
  async saveReject() {
    // get rejectOption
    const rejectOption = this.rejectFrm.controls.rejectType.value;
    // saveLoanStatus
    await this.saveLoanStatus('rejected', 5, rejectOption);
    // send rejectionMsg
    await this.sendRejectionMsg();
  }
  async sendRejectionMsg() {
    // set mglnkData
    const mglnkData = {
      // set email
      email: this.loanUserData.email,
      // set link data
      module: 'loans',
      submodule: 'detail',
      id: this.loanNumber,
    }
    // set encodedMglnk
    const encodedMglnk = this.encodingSv.encodeBTOA(JSON.stringify(mglnkData));
    // set sendData
    const sendData = {
      firstName: this.loanUserData.firstName,
      lastName: this.loanUserData.lastName,
      email: this.loanUserData.email,
      productName: this.productData !== null && this.productData.productName ? this.productData.productName : this.translate.instant('PRODUCT.productFree'),
      loanUri: this.appUri + '/mglnk/' + encodedMglnk,
    }
    // send
    await this.apiSv.sendRejectionMsg(sendData);
  }
  // pre-approval
  async preApprovalRejected(modal: any) {
    // open modal to select reject option
    this.modalService.open(modal);
  }
  async checkPreApproval() {
    // markAllAsTouched
    this.preApprovalFrm.markAllAsTouched;
    this.calcInterestFrm.markAllAsTouched;
    // check if singlePayment is seted
    if (this.singlePayment > 0) {
      // check preApprovalFrm
      if (!this.preApprovalFrm.valid) {
        // send alert
        this.alertSv.showMessage(this.translate.instant('LOAN.loanPreApproveCheck'),'error', this.translate.instant('HELPERS.error'), true);
      } else {
        // check calcInterestFrm
        if (!this.calcInterestFrm.valid) {
          // send alert
          this.alertSv.showMessage(this.translate.instant('LOAN.loanInterestCalc'),'error', this.translate.instant('HELPERS.error'), true);
        } else {
          // check loanDetailedValidation
          if (this.loanDetailedValidation && this.synthesisFileData === null) {
            // show alert to get new profession
            Swal.fire({
              icon: 'warning',
              title: this.translate.instant('LOAN.loanDocumentDetailed'),
              text: this.translate.instant('LOAN.loanDocumentDetailedContinue'),
              confirmButtonText: this.translate.instant('HELPERS.continue'),
              showCancelButton: true,
              cancelButtonText: this.translate.instant('HELPERS.cancel'), 
              customClass: {
                cancelButton: 'btn-swal-cancel'
              },
              allowOutsideClick: false,
            }).then(async (result) => {
              if (result.isConfirmed) {
                // removeDetailValidation
                await this.removeDetailValidation();
                // savePreApproval
                this.showConfirmation('preApproval', this.translate.instant('LOAN.preApprovalTitle'), this.translate.instant('LOAN.preApprovalAsk'));
              }
            });
          } else {
            // savePreApproval
            this.showConfirmation('preApproval', this.translate.instant('LOAN.preApprovalTitle'), this.translate.instant('LOAN.preApprovalAsk'));
          }
        }
      }
    } else {
      // send alert
      this.alertSv.showMessage(this.translate.instant('LOAN.loanSinglePaymentCheck'),'error', this.translate.instant('HELPERS.error'), true);
    }
  }
  async savePreApproval() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // get preApproved data
      const loanInterest: any = this.loanInterest;
      const loanMonthlyRate: any = this.loanMonthlyRate;
      const loanAnualRate: any = this.loanAnualRate;
      const loanSinglePayment: number = this.utilitiesSv.roundNumber(this.singlePayment, 'up', 0);
      const preApprovedAmount: number = typeof this.preApprovalFrm.controls.preAmount.value === 'string' ? parseInt(this.utilitiesSv.removeDotsFromCurrencyString(this.preApprovalFrm.controls.preAmount.value)) : this.preApprovalFrm.controls.preAmount.value;
      const preApprovedMonths: number = typeof this.preApprovalFrm.controls.preMonths.value === 'string' ? parseInt(this.utilitiesSv.removeDotsFromCurrencyString(this.preApprovalFrm.controls.preMonths.value)) : this.preApprovalFrm.controls.preMonths.value;
      // check for companyApproval requirement
      if (this.companyApproval) {
        // set company approval values
        this.companyAmountMax = preApprovedAmount;
        this.companyMonthMax = preApprovedMonths;
        this.companyMonthSteps = 1;
        this.approvalFrm.controls.amount.setValue(preApprovedAmount);
        this.approvalFrm.controls.months.setValue(preApprovedMonths);
        // check if company can edit amount
        if (!this.companyAmountEdit) {
          // update approved data
          this.loanAmountApprovedCompany = preApprovedAmount;
          this.loanPaymentMonthsApprovedCompany = preApprovedMonths;
          this.loanAmountApproved = this.companyAmountEdit === true ? 0 : preApprovedAmount;
          this.loanPaymentMonthsApproved =this.companyAmountEdit === true ? 0 : preApprovedMonths;
          // createLoanPayments
          await this.createLoanPayments();
        }
      } else {
        // update approved data
        this.loanAmountApprovedCompany = preApprovedAmount;
        this.loanPaymentMonthsApprovedCompany = preApprovedMonths;
        this.loanAmountApproved = this.companyAmountEdit === true ? 0 : preApprovedAmount;
        this.loanPaymentMonthsApproved =this.companyAmountEdit === true ? 0 : preApprovedMonths;
        // createLoanPayments
        await this.createLoanPayments();
      }
      // set loanData
      const loanData: LoanInterface = {
        loanInterest: loanInterest,
        loanMonthlyRate: loanMonthlyRate,
        loanAnualRate: loanAnualRate,
        loanSinglePayment: loanSinglePayment,
        loanAmountPreApproved: preApprovedAmount,
        loanPaymentMonthsPreApproved: preApprovedMonths,
        loanAmountApprovedCompany:  this.companyAmountEdit === true ? 0 : preApprovedAmount,
        loanPaymentMonthsApprovedCompany: this.companyAmountEdit === true ? 0 : preApprovedMonths,
        loanAmountApproved:  this.companyAmountEdit === true ? 0 : preApprovedAmount,
        loanPaymentMonthsApproved: this.companyAmountEdit === true ? 0 : preApprovedMonths,
        ...((this.preApprovalFrm.controls.loanInsuranceIds.value !== null) && {loanInsuranceIds: this.preApprovalFrm.controls.loanInsuranceIds.value})
      }
      // update loan data
      await this.apiSv.updateLoan(this.loanId, loanData);
      // update approved data
      this.loanAmountPreApproved = preApprovedAmount;
      this.loanPaymentMonthsPreApproved = preApprovedMonths;
      this.loanAmountApprovedCompany = this.companyAmountEdit === true ? 0 : preApprovedAmount;
      this.loanPaymentMonthsApprovedCompany =this.companyAmountEdit === true ? 0 : preApprovedMonths;
      this.loanAmountApproved = this.companyAmountEdit === true ? 0 : preApprovedAmount;
      this.loanPaymentMonthsApproved =this.companyAmountEdit === true ? 0 : preApprovedMonths;
      // check for companyApproval requirement
      if (!this.companyApproval) {
        // send emails
        await this.sendCompanyPromissoryAndAuthorization();
      } else {
        // check company approval and amount edit
        if (this.companyApproval && !this.companyAmountEdit) {
          // send emails
          await this.sendCompanyPromissoryAndAuthorization();
        }
      }
      // saveLoanStatus
      this.saveLoanStatus('preApproved', 6);
      // go to start
      this.goToByScroll('myBody');
      // hide loader
      this.loaderEvent.emit(null);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }  
  // company-disbursement-preparation
  async checkCompanyDisbursementPreparation() {
    // check documents
    if (this.promissoryFileData.filePath === null || this.authorizationFileData.filePath === null) {
      // send alert
      this.alertSv.showMessage(this.translate.instant('LOAN.loanDocumentDisbursementPreparationCompanyCheck'),'error', this.translate.instant('HELPERS.error'), true);
    } else {
      this.showConfirmation('companyDisbursementPreparation', this.translate.instant('LOAN.companyDisbursementPreparationTitle'), this.translate.instant('LOAN.companyDisbursementPreparationAsk'));
    }
  }
  async saveCompanyDisbursementPreparation() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // send emails
      await this.sendConfirmation();
      // saveLoanStatus
      this.saveLoanStatus('readyToApprove', 7);
      // go to start
      this.goToByScroll('myBody');
      // hide loader
      this.loaderEvent.emit(null);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  // disbursement-preparation
  async checkDisbursementPreparation() {
    // check documents
    if (this.promissoryFileData.filePath === null) {
      // send alert
      this.alertSv.showMessage(this.translate.instant('LOAN.loanDocumentDisbursementPreparationCheck'),'error', this.translate.instant('HELPERS.error'), true);
    } else {
      this.showConfirmation('disbursementPreparation', this.translate.instant('LOAN.disbursementPreparationTitle'), this.translate.instant('LOAN.disbursementPreparationAsk'));
    }
  }
  async saveDisbursementPreparation() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // saveLoanStatus
      this.saveLoanStatus('readyForDisbursement', 9);
      // go to start
      this.goToByScroll('myBody');
      // hide loader
      this.loaderEvent.emit(null);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  // promissory-and-loan-authorization
  async sendCompanyPromissoryAndAuthorization() {
    try {
      // set mglnkPromissoryData
      const mglnkPromissoryData = {
        // set email
        email: this.loanUserData.email,
        // set link data
        module: 'documentos',
        submodule: 'pagare',
        id: this.loanNumber,
      }
      // set encodedMglnk
      const encodedPromissoryMglnk = this.encodingSv.encodeBTOA(JSON.stringify(mglnkPromissoryData));
      // set mglnkAuthorizationData
      const mglnkAuthorizationData = {
        // set email
        email: this.loanUserData.email,
        // set link data
        module: 'documentos',
        submodule: 'autorizacion-descuento',
        id: this.loanNumber,
      }
      // set encodedMglnk
      const encodedAuthorizationMglnk = this.encodingSv.encodeBTOA(JSON.stringify(mglnkAuthorizationData));
      // set sendData
      const sendData = {
        loanNumber: this.loanNumber,
        firstName: this.loanUserData.firstName,
        email: this.loanUserData.email,
        productName: this.productData !== null && this.productData.productName ? this.productData.productName : this.translate.instant('PRODUCT.productFree'),
        loanAmountPreApproved: '$' + this.utilitiesSv.convertStringToCurrency((this.utilitiesSv.roundNumber(this.loanAmountPreApproved, 'up', 0)).toString()),
        promissoryGeneratedFile: this.appUri + '/mglnk/' + encodedPromissoryMglnk,
        authorizationGeneratedFile: this.appUri + '/mglnk/' + encodedAuthorizationMglnk,
      }
      // send emails
      await this.apiSv.sendCompanyPromissoryAndAuthorization(sendData);
    } catch (error) {
      console.log('error', error);
    }
  }
  // company-promissory
  async printCompanyPromissory() {
    this.utilitiesSv.goTo('/documentos/pagare/' + this.loanNumber, false, null)
  }
  // confirmation
  async sendConfirmation() {
    try {
      // set ceoData
      const ceoData = {
        companyId: this.companyId,
        userRole: 'ceo',
      }
      // get ceo data from api
      const ceoAux: any = await this.apiSv.getUsersByCompanyAndRole(ceoData);
      // get ceo
      const ceo: UserInterface = ceoAux.data[0];
      // init ceo data
      let ceoFirstName: string = null;
      let ceoLastName: string = null;
      let ceoEmail: string = null;
      // check ceoData
      if (ceo) {
        ceoFirstName = ceo.firstName;
        ceoLastName = ceo.lastName;
        ceoEmail = ceo.email;
      }
      // set managerData
      const managerData = {
        companyId: this.companyId,
        userRole: 'manager',
      }
      // get manager data from api
      const managerAux: any = await this.apiSv.getUsersByCompanyAndRole(managerData);
      // get manager
      const manager: UserInterface = managerAux.data[0];
      // init manager data
      let managerFirstName: string = null;
      let managerLastName: string = null;
      let managerEmail: string = null;
      // check managerData
      if (manager) {
        managerFirstName = manager.firstName;
        managerLastName = manager.lastName;
        managerEmail = manager.email;
      }
      // set mglnkData
      const mglnkData = {
        // set email
        email: this.loanUserData.email,
        // set link data
        module: 'loans',
        submodule: 'detail',
        id: this.loanNumber,
      }
      // set encodedMglnk
      const encodedMglnk = this.encodingSv.encodeBTOA(JSON.stringify(mglnkData));
      // set mglnkPromissoryData
      const mglnkPromissoryData = {
        // set email
        email: this.loanUserData.email,
        // set link data
        module: 'documentos',
        submodule: 'pagaduria',
        id: this.loanNumber,
      }
      // set encodedPromissoryMglnk
      const encodedPromissoryMglnk = this.encodingSv.encodeBTOA(JSON.stringify(mglnkPromissoryData));
      // set sendData
      const sendData = {
        loanNumber: this.loanNumber,
        ceoFirstName,
        ceoLastName,
        ceoEmail,
        managerFirstName,
        managerLastName,
        managerEmail,
        firstName: this.loanUserData.firstName,
        lastName: this.loanUserData.lastName,
        documentNumber:this.loanUserData.documentNumber,
        productName: this.productData !== null && this.productData.productName ? this.productData.productName : this.translate.instant('PRODUCT.productFree'),
        loanAmountPreApproved: '$' + this.utilitiesSv.convertStringToCurrency((this.utilitiesSv.roundNumber(this.loanAmountPreApproved, 'up', 0)).toString()),
        loanPaymentMonthsPreApproved: this.loanPaymentMonthsPreApproved,
        loanUri: this.appUri + '/mglnk/' + encodedMglnk,
        companyPromissoryGeneratedFile: this.appUri + '/mglnk/' + encodedPromissoryMglnk,
      }
      // send emails
      await this.apiSv.sendConfirmation(sendData);
    } catch (error) {
      console.log('error', error);
    }
  }
  // company-approval
  async companyApprovalRejected() {
    // saveLoanStatus
    await this.saveLoanStatus('rejected', 5, 'rejectedForCompany');
  }
  async checkCompanyApproval() {
    // markAllAsTouched
    this.approvalFrm.markAllAsTouched;
    // check companyPromissoryFileData
    if (!(this.companyPromissoryFileData.filePath === null)) {
      // check companyAmountEdit
      if (this.companyAmountEdit) {
        // check approvalFrm
        if (!this.approvalFrm.valid) {
          // send alert
          this.alertSv.showMessage(this.translate.instant('LOAN.loanPreApproveCheck'),'error', this.translate.instant('HELPERS.error'), true);
        } else {
          this.showConfirmation('companyApproval', this.translate.instant('LOAN.approvalTitle'), this.translate.instant('LOAN.approvalAsk'));
        }
      } else {
        this.showConfirmation('companyApproval', this.translate.instant('LOAN.approvalTitle'), this.translate.instant('LOAN.approvalAsk'));
      }
    } else {
      // send alert
      this.alertSv.showMessage(this.translate.instant('LOAN.loanDocumentCompanyPromissoryCheck'),'error', this.translate.instant('HELPERS.error'), true);
    }
  }
  async saveCompanyApproval() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // get preApproved data
      const loanInterest: number = this.loanInterest;
      const loanMonthlyRate: any = this.loanMonthlyRate;
      const loanAnualRate: any = this.loanAnualRate;
      const loanSinglePayment: number = this.utilitiesSv.roundNumber(this.singlePayment, 'up', 0);
      const approvedAmount: number = typeof this.approvalFrm.controls.amount.value === 'string' ? parseInt(this.utilitiesSv.removeDotsFromCurrencyString(this.approvalFrm.controls.amount.value)) : this.approvalFrm.controls.amount.value;
      const approvedMonths: number = typeof this.approvalFrm.controls.months.value === 'string' ? parseInt(this.utilitiesSv.removeDotsFromCurrencyString(this.approvalFrm.controls.months.value)) : this.approvalFrm.controls.months.value;
      // set loanData
      const loanData: LoanInterface = {
        loanInterest,
        loanMonthlyRate,
        loanAnualRate,
        loanSinglePayment: loanSinglePayment,
        loanAmountApprovedCompany:  this.companyAmountEdit === true ? approvedAmount : this.loanAmountApproved,
        loanPaymentMonthsApprovedCompany: this.companyAmountEdit === true ? approvedMonths : this.loanPaymentMonthsApproved,
        loanAmountApproved:  this.companyAmountEdit === true ? approvedAmount : this.loanAmountApproved,
        loanPaymentMonthsApproved: this.companyAmountEdit === true ? approvedMonths : this.loanPaymentMonthsApproved,
      }
      // update loan data
      await this.apiSv.updateLoan(this.loanId, loanData);
      // check company approval and amount edit
      if (this.companyApproval && this.companyAmountEdit) {
        // update approved data
        this.loanAmountApprovedCompany = approvedAmount;
        this.loanPaymentMonthsApprovedCompany = approvedMonths;
        this.loanAmountApproved = this.companyAmountEdit === true ? 0 : approvedAmount;
        this.loanPaymentMonthsApproved =this.companyAmountEdit === true ? 0 : approvedMonths;
        // createLoanPayments
        await this.createLoanPayments();
        // createLoanPayments
        await this.sendCompanyPromissoryAndAuthorization();
      }
      // sendCompanyApproved
      await this.sendCompanyApproved();
      // saveLoanStatus
      await this.saveLoanStatus('approved', 8);
      // saveLoanStatus
      await this.saveLoanStatus('readyForDisbursement', 9);
      // go to start
      this.goToByScroll('myBody');
      // hide loader
      this.loaderEvent.emit(null);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async sendCompanyApproved() {
    try {
      // set ceoData
      const ceoData = {
        companyId: this.companyId,
        userRole: 'ceo',
      }
      // get ceo data from api
      const ceoAux: any = await this.apiSv.getUsersByCompanyAndRole(ceoData);
      // get ceo
      const ceo: UserInterface = ceoAux.data[0];
      // init ceo data
      let ceoFirstName: string = null;
      let ceoLastName: string = null;
      let ceoEmail: string = null;
      // check ceoData
      if (ceo) {
        ceoFirstName = ceo.firstName;
        ceoLastName = ceo.lastName;
        ceoEmail = ceo.email;
      }
      // set managerData
      const managerData = {
        companyId: this.companyId,
        userRole: 'manager',
      }
      // get manager data from api
      const managerAux: any = await this.apiSv.getUsersByCompanyAndRole(managerData);
      // get manager
      const manager: UserInterface = managerAux.data[0];
      // init manager data
      let managerFirstName: string = null;
      let managerLastName: string = null;
      let managerEmail: string = null;
      // check managerData
      if (manager) {
        managerFirstName = manager.firstName;
        managerLastName = manager.lastName;
        managerEmail = manager.email;
      }
      // set mglnkData
      const mglnkData = {
        // set email
        email: this.loanUserData.email,
        // set link data
        module: 'loans',
        submodule: 'detail',
        id: this.loanNumber,
      }
      // set encodedMglnk
      const encodedMglnk = this.encodingSv.encodeBTOA(JSON.stringify(mglnkData));
      // set sendData
      const sendData = {
        loanNumber: this.loanNumber,
        ceoFirstName,
        ceoLastName,
        ceoEmail,
        managerFirstName,
        managerLastName,
        managerEmail,
        firstName: this.loanUserData.firstName,
        lastName: this.loanUserData.lastName,
        email:this.loanUserData.email,
        documentNumber:this.loanUserData.documentNumber,
        productName: this.productData !== null && this.productData.productName ? this.productData.productName : this.translate.instant('PRODUCT.productFree'),
        singlePayment: '$' + this.utilitiesSv.convertStringToCurrency((this.utilitiesSv.roundNumber(this.singlePayment, 'up', 0)).toString()),
        loanAmountApproved: '$' + this.utilitiesSv.convertStringToCurrency((this.utilitiesSv.roundNumber(this.loanAmountApproved, 'up', 0)).toString()),
        loanPaymentMonthsApproved: this.loanPaymentMonthsApproved.toString(),
        loanUri: this.appUri + '/mglnk/' + encodedMglnk,
        companyApprovalTxt: this.companyData.companyApprovalTxt
      }
      // send emails
      await this.apiSv.sendCompanyApproved(sendData);
    } catch (error) {
      console.log('error', error);
    }
  }
  // approval
  async approvalRejected(modal: any) {
    // open modal to select reject option
    this.modalService.open(modal);
  }
  async checkApproval() {
    // markAllAsTouched
    this.calcInterestFrm.markAllAsTouched;
    // check if singlePayment is seted
    if (this.singlePayment > 0) {
      // check approvalFrm
      if (!this.approvalFrm.valid) {
        // send alert
        this.alertSv.showMessage(this.translate.instant('LOAN.loanApproveCheck'),'error', this.translate.instant('HELPERS.error'), true);
      } else {
        // check calcInterestFrm
        if (!this.calcInterestFrm.valid) {
          // send alert
          this.alertSv.showMessage(this.translate.instant('LOAN.loanInterestCalc'),'error', this.translate.instant('HELPERS.error'), true);
        } else {
          this.showConfirmation('approval', this.translate.instant('LOAN.approvalTitle'), this.translate.instant('LOAN.approvalAsk'));
        }
      }
    } else {
      // send alert
      this.alertSv.showMessage(this.translate.instant('LOAN.loanSinglePaymentCheck'),'error', this.translate.instant('HELPERS.error'), true);
    }
  }
  async saveApproval() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // get preApproved data
      const loanInterest: number = this.loanInterest;
      const loanMonthlyRate: number = this.loanMonthlyRate;
      const loanAnualRate: number = this.loanAnualRate;
      const loanSinglePayment: number = this.utilitiesSv.roundNumber(this.singlePayment, 'up', 0);
      const approvedAmount: number = typeof this.approvalFrm.controls.amount.value === 'string' ? parseInt(this.utilitiesSv.removeDotsFromCurrencyString(this.approvalFrm.controls.amount.value)) : this.approvalFrm.controls.amount.value;
      const approvedMonths: number = typeof this.approvalFrm.controls.months.value === 'string' ? parseInt(this.utilitiesSv.removeDotsFromCurrencyString(this.approvalFrm.controls.months.value)) : this.approvalFrm.controls.months.value;
      // set loanData
      const loanData: LoanInterface = {
        loanInterest,
        loanMonthlyRate,
        loanAnualRate,
        loanSinglePayment: loanSinglePayment,
        loanAmountApproved: approvedAmount,
        loanPaymentMonthsApproved: approvedMonths,
        ...((this.preApprovalFrm.controls.loanInsuranceIds.value !== null) && {loanInsuranceIds: this.preApprovalFrm.controls.loanInsuranceIds.value})
      }
      // update loan data
      await this.apiSv.updateLoan(this.loanId, loanData);
      // update approved data
      this.loanAmountApproved = approvedAmount;
      this.loanPaymentMonthsApproved = approvedMonths;
      // createLoanPayments
      await this.createLoanPayments();
      // send emails
      await this.sendPromissory();
      // saveLoanStatus
      await this.saveLoanStatus('approved', 6);
      // go to start
      this.goToByScroll('myBody');
      // hide loader
      this.loaderEvent.emit(null);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async sendPromissory() {
    // set mglnkPromissoryData
    const mglnkPromissoryData = {
      // set email
      email: this.loanUserData.email,
      // set link data
      module: 'documentos',
      submodule: 'pagare',
      id: this.loanNumber,
    }
    // set encodedMglnk
    const encodedPromissoryMglnk = this.encodingSv.encodeBTOA(JSON.stringify(mglnkPromissoryData));
    // set sendData
    const sendData = {
      loanNumber: this.loanNumber,
      firstName: this.loanUserData.firstName,
      email:this.loanUserData.email,
      productName: this.productData !== null && this.productData.productName ? this.productData.productName : this.translate.instant('PRODUCT.productFree'),
      singlePayment: '$' + this.utilitiesSv.convertStringToCurrency((this.utilitiesSv.roundNumber(this.singlePayment, 'up', 0)).toString()),
      loanAmountApproved: '$' + this.utilitiesSv.convertStringToCurrency((this.utilitiesSv.roundNumber(this.loanAmountApproved, 'up', 0)).toString()),
      loanPaymentMonthsApproved: this.loanPaymentMonthsApproved,
      promissoryGeneratedFile: this.appUri + '/mglnk/' + encodedPromissoryMglnk,
    }
    // send emails
    await this.apiSv.sendPromissory(sendData);
  }
  // loan-payments
  async createLoanPayments() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // get currentDateTime
      const currentDateTime = moment().toISOString();
      // get loan data
      const amount = this.loanAmountApproved;
      const months = this.loanPaymentMonthsApproved;
      const loanInterest = (this.loanInterest) / 100;
      const singlePayment = this.singlePayment;
      // init loanPaymentStart
      let loanPaymentStart = amount;
      // clear loanPayments
      this.loanPayments = [];
      // init loanPayments
      const loanPayments: any[] = [];
      // loop payments
      for (let i = 0; i < months; i++) {
        // get loan payment data
        const loanPaymentNumber = i + 1;
        const loanPaymentAmount = this.utilitiesSv.roundNumber(singlePayment, 'up', 0);
        const loanPaymentInterest = this.utilitiesSv.roundNumber((loanPaymentStart * loanInterest), 'up', 0);
        const loanPaymentDebt = this.utilitiesSv.roundNumber((loanPaymentAmount - loanPaymentInterest), 'up', 0);
        const loanPaymentEnd = this.utilitiesSv.roundNumber((loanPaymentStart - loanPaymentDebt), 'up', 0);
        // set loaderMsg
        const loaderMsg = this.translate.instant('HELPERS.creating') + ' ' + this.translate.instant('LOAN.loanMonth') + ' ' + loanPaymentNumber.toString();
        // show loader
        this.loaderEvent.emit(loaderMsg);
        // get loanPaymentData
        const loanPaymentData: LoanPaymentInterface = {
          loanId: this.loanId,
          loanPaymentNumber,
          loanPaymentStart,
          loanPaymentAmount,
          loanPaymentInterest,
          loanPaymentInterestPercentage: this.loanInterest,
          loanPaymentDebt,
          loanPaymentEnd: loanPaymentEnd < 100 ? 0 : loanPaymentEnd,
          loanPaymentStatusLast: 'pending',
          loanPaymentStatusDateLast: currentDateTime,
          loanPaymentOverduedDays: 0,
          loanPaymentDueDate: null,
        }
        // update loanPaymentStart
        loanPaymentStart = loanPaymentEnd;
        // add loanPayment
        const auxLoanPayment: any = await this.apiSv.addLoanPayment(loanPaymentData);
        // get loanPayment
        const loanPayment = auxLoanPayment.data;
        // update loanPaymentId
        loanPaymentData.id = loanPayment.id;
        // add loanPaymentReceipt
        loanPaymentData.loanPaymentReceipt = null;
        // set loanPaymentFileData
        const loanPaymentFileData = {
          id: loanPayment.id,
          parentId: this.loanId,
          docType: 'loanPayment',
          file: null,
          fileId: 'loanPayment',
          fileType: null,
          fileName: null,
          filePath: null,
          fileKey: null,
          fileDate: null,
          title: this.translate.instant('LOAN.loanDocumentBank'),
          image: 'assets/svg/icon-document-payment-receipt.svg',
          showDropZone: false,
        }
        // add loanPaymentFileData
        loanPaymentData.loanPaymentFileData = loanPaymentFileData;
        // set loanPaymentStatusData
        const loanPaymentStatusData: LoanPaymentStatusInterface = {
          loanPaymentId: loanPayment.id,
          loanPaymentStatus: 'pending',
          loanPaymentStatusObservation: null
        }
        // add loanPaymentStatusData
        await this.apiSv.addLoanPaymentStatus(loanPaymentStatusData);
        // push loanPaymentData to loanPayments array
        loanPayments.push(loanPaymentData);
      }
      // orderLoanPayments
      const orderedLoanPayments = this.utilitiesSv.sortArrayByKey(loanPayments, 'loanPaymentNumber');
      // update loanPayments
      this.loanPayments = orderedLoanPayments; 
      // hide loader
      this.loaderEvent.emit(null);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async updatePaymentDates(paymentDueDate: string) {
    return new Promise(async (resolve, reject) => {
      try {
        // loop loanPayments
        for (let index = 0; index < this.loanPayments.length; index++) {
          // get loanPayment
          const loanPayment: LoanPaymentInterface = this.loanPayments[index];
          // set loaderMsg
          const loaderMsg = this.translate.instant('HELPERS.updating') + ' ' + this.translate.instant('LOAN.loanPaymentDateMonth') + ' ' + loanPayment.loanPaymentNumber.toString() + ' ' + this.translate.instant('LOAN.loanFromRequest') + ' ' + this.loanNumber;
          // show loader
          this.updateLoader(loaderMsg);
          // update paymentDueDate
          paymentDueDate = this.utilitiesSv.addToDate(paymentDueDate, 1, 'months');
          loanPayment.loanPaymentDueDate = paymentDueDate;
          // get loanPaymentDueDate
          const loanPaymentDueDate = paymentDueDate;
          // set loanPaymentUpdateData
          const loanPaymentUpdateData = {
            loanPaymentDueDate
          }
          // update loanPaymentDueDate
          await this.apiSv.updateLoanPayment(loanPayment.id, loanPaymentUpdateData);
          // update loanPayment
          this.loanPayments[index] = loanPayment;
        }
        // show loader
        this.updateLoader(null);
      } catch (error) {
        console.log('error', error);
        // hide loader
        this.updateLoader(null);
        reject(error);
      }
    });
  }
  // users
  async editUser() {
    // update editUser
    this.editUserData = true;
  }
  async saveUser(user: UserInterface) {
    // set user
    await this.setLoanUserData(user);
    // edit workData 
    this.editUserData = false;
  }
  async cancelUserEdit() {
    // update editUser
    this.editUserData = false;
  }
  // company
  async editCompany() {
    // edit workData 
    this.editCompanyData = true;
  }
  async cancelEditCompany() {
    // edit workData 
    this.editCompanyData = false;
  }
  async saveCompany(companyData: CompanyInterface) {
    // update companyData
    this.companyId = companyData.id;
    this.companyData = companyData;
    this.businessName = companyData.businessName;
    this.companyApproval = companyData.companyApprovalRequired;
    this.companyAmountEdit = companyData.companyAmountEdit;
    // edit workData 
    this.editCompanyData = false;
  }
  // work
  async editWork() {
    // edit workData 
    this.editWorkData = true;
  }
  async cancelEditWork() {
    // edit workData 
    this.editWorkData = false;
  }
  async saveWork(userWorkData: UserWorkInterface) {
    // update userWorkData
    this.userWorkData = userWorkData;
    // update salary
    this.salary = userWorkData.contractSalary;
    // edit workData 
    this.editWorkData = false;
  }
  // financial
  async editFinancial() {
    // edit financialData 
    this.editFinancialData = true;
  }
  async cancelEditFinancial() {
    // edit financialData 
    this.editFinancialData = false;
  }
  async saveFinancial(financialData: UserFinancialInterface) {
    // update financialData
    this.financialData = financialData;
    // update salary
    this.salary = this.userWorkData.contractSalary;
    // edit financialData 
    this.editFinancialData = false;
  }
  // report payments
  async openReportPayments(modal: any) {
    // loop selectedPayments
    for (let i = 0; i < this.loanPayments.length; i++) {
      // get loanPayment
      const loanPayment: any = this.loanPayments[i];
      // check selection
      if (loanPayment.loanPaymentStatusLast !== 'payed') {
        // push unpaidPayments
        this.unpaidPayments.push(loanPayment);
      }
    }
    // open modal
    this.modalService.open(modal);
  }
  async cancelReportPayments() {
    // hide modal
    this.modalService.dismissAll();
  }
  async updateSelectedPayments(loanPayments: any) {
    // update selectedPayments
    this.selectedPayments = loanPayments;
    // checkSelectedPayments
    await this.checkSelectedPayments();
  }
  async saveReportPayments() {
    // validate data
    if (this.amountSelectPayments <= 0) {
      // send alert
      this.alertSv.showMessage(this.translate.instant('LOANPAYMENTS.loanPaymentSelectionError'),'error', this.translate.instant('HELPERS.error'), true);
    } else {
      // reportPayments
      await this.reportPayments();
    }
  }
  async checkSelectedPayments() {
    // clear amountSelectPayments
    this.amountSelectPayments = 0;
    // loop selectedPayments
    for (let i = 0; i < this.selectedPayments.length; i++) {
      // get selectedPayment
      const selectedPayment: any = this.selectedPayments[i];
      // check selection
      if (selectedPayment.selected) {
        // update amountSelectPayments
        this.amountSelectPayments = this.amountSelectPayments + selectedPayment.loanPaymentAmount;
      }
    }
  }
  async reportPayments() {
    try {
      // loop selectedPayments
      for (let i = 0; i < this.selectedPayments.length; i++) {
        // get loanPayment
        const loanPayment: any = this.selectedPayments[i];
        // check selection
        if (loanPayment.selected) {
          // set loaderMsg
          const loaderMsg = this.translate.instant('HELPERS.updating') + ' ' + this.translate.instant('LOAN.loanPaymentDateMonth') + ' ' + loanPayment.loanPaymentNumber.toString() + ' ' + this.translate.instant('LOAN.loanFromRequest') + ' ' + loanPayment.loanNumber;
          // show loader
          this.updateLoader(loaderMsg) ;
          // get currentDateTime
          const currentDateTime = moment().toISOString();
          // set loanPaymentData
          const loanPaymentData = {
            loanPaymentOverduedDays: 0,
            loanPaymentStatusLast: 'payed',
            loanPaymentStatusDateLast: currentDateTime
          }
          // update loanPaymentData
          await this.apiSv.updateLoanPayment(loanPayment.id, loanPaymentData);
          // set loanPaymentStatusData
          const loanPaymentStatusData = {
            loanPaymentId: loanPayment.id,
            loanPaymentStatus: 'payed',
            loanPaymentStatusObservation: null
          }
          // add loanPaymentStatusData
          await this.apiSv.addLoanPaymentStatus(loanPaymentStatusData);
          // get loanPaymentIndex
          const loanPaymentIndex = this.loanPayments.findIndex((loanPaymentSearch: any) => loanPaymentSearch.id === loanPayment.id);
          // update loanPayment data
          this.loanPayments[loanPaymentIndex].loanPaymentStatusDateLast = currentDateTime;
          this.loanPayments[loanPaymentIndex].loanPaymentStatusLast = 'payed';
        }
      }
      // hide loader
      this.updateLoader(null);
      // hide modal
      this.modalService.dismissAll();
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.updateLoader(null);
      // hide modal
      this.modalService.dismissAll();
    }
  }
  async openPayment(modal: any, loanPayment: LoanPaymentInterface) {
    // reset loanPaymentFrm
    this.loanPaymentFrm.reset();
    // update loanPayment
    this.loanPayment = loanPayment;
    this.loanPaymentId = loanPayment.id;
    this.loanPaymentFileData = loanPayment.loanPaymentFileData;
    // get suggestedPayment
    const suggestedPayment = await this.getSuggestedPayment(loanPayment);
    // get loanPaymentAmount
    const loanPaymentAmount = this.utilitiesSv.convertStringToCurrency(suggestedPayment.toString());
    // set payment form data
    this.loanPaymentFrm.controls.paymentNumber.setValue(loanPayment.id);
    this.loanPaymentFrm.controls.paymentAmount.setValue(loanPaymentAmount);
    this.loanPaymentFrm.controls.paymentInterestPercentage.setValue(loanPayment.loanPaymentInterestPercentage);
    // open modal
    this.modalService.open(modal);
  }
  async closePayment() {
    // clear loanPayment
    this.loanPayment = null;
    this.loanPaymentId = null;
    this.loanPaymentFileData = null;
    // reset loanPaymentFrm
    this.loanPaymentFrm.reset();
    // hide modal
    this.modalService.dismissAll();
  }
  async checkPayment() {
    // markAllAsTouched
    this.loanPaymentFrm.markAllAsTouched();
    // validate data
    if (!this.loanPaymentFrm.valid) {
      // send alert
      this.alertSv.showMessage(this.translate.instant('HELPERS.requiredFields'),'warning', this.translate.instant('HELPERS.error'), true);
    } else {
      // check paymentFileData
      if (this.loanPaymentFileData.fileName === null) {
        // send alert
        this.alertSv.showMessage(this.translate.instant('LOANPAYMENTS.loanPaymentMustAddReceipt'), 'warning', this.translate.instant('HELPERS.error'), true);
      } else {
        // evaluatePayment
        await this.evaluatePayment();
      }
    }
  }
  async evaluatePayment() {
    try {
      // get currentDateTime
      const currentDateTime: string = moment().toISOString();
      // get loanPaymentIndex
      const loanPaymentIndex = this.loanPayments.findIndex((loanPayment: LoanPaymentInterface) => loanPayment.id === this.loanPaymentId);
      // get loanPayment
      const loanPayment: LoanPaymentInterface = this.loanPayments[loanPaymentIndex];
      // get payedAmount
      const paymentAmount = this.loanPaymentFrm.controls.paymentAmount.value;
      const payedAmount = typeof paymentAmount === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(paymentAmount)) : parseFloat(paymentAmount);
      const singlePayment = loanPayment.loanPaymentAmount;
      const paymentPercentage = this.loanPaymentFrm.controls.paymentInterestPercentage.value;
      // hide modal
      this.modalService.dismissAll();
      // check if payment and percentage is more than 0
      if (payedAmount > 0 && paymentPercentage > 0) {
        // check payment value
        if (singlePayment !== payedAmount) {
          // readjustPayment
          await this.readjustPayment(loanPayment);
          // hide loader
          this.loaderEvent.emit(null);
        } else {
          // show loader
          this.loaderEvent.emit(this.translate.instant('HELPERS.updating') + ' ' + this.translate.instant('LOANPAYMENTS.loanPayment') + ' ' + this.translate.instant('LOANPAYMENTS.loanPaymentNumberNumber') + ' ' + loanPayment.loanPaymentNumber);
          // update loanPayment data
          loanPayment.loanPaymentAmount = payedAmount;
          loanPayment.loanPaymentStatusLast = 'payed';
          loanPayment.loanPaymentStatusDateLast = currentDateTime;
          loanPayment.loanPaymentReceipt = this.loanPaymentFileData.filePath;
          loanPayment.loanPaymentFileData = this.loanPaymentFileData;
          this.loanPayments[loanPaymentIndex].loanPaymentAmount = payedAmount;
          this.loanPayments[loanPaymentIndex].loanPaymentStatusLast = 'payed';
          this.loanPayments[loanPaymentIndex].loanPaymentStatusDateLast = currentDateTime;
          this.loanPayments[loanPaymentIndex].loanPaymentReceipt = this.loanPaymentFileData.filePath;
          this.loanPayments[loanPaymentIndex].loanPaymentFileData = this.loanPaymentFileData;
          // save payment
          await this.savePayment(loanPayment);
          // hide loader
          this.loaderEvent.emit(null);
        }
        // clear loanPayment
        this.loanPayment = null;
        this.loanPaymentId = null;
        this.loanPaymentFileData = null;
        // reset loanPaymentFrm
        this.loanPaymentFrm.reset();
      } else {
        // send alert
        this.alertSv.showMessage(this.translate.instant('HELPERS.requiredFields'),'warning', this.translate.instant('HELPERS.warning'), true);
      }
      // hide loader
      this.loaderEvent.emit(null);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // hide modal
      this.modalService.dismissAll();
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async openPaymentDocument(modal: any, loanPayment: LoanPaymentInterface) {
    // update loanPayment
    this.loanPayment = loanPayment;
    this.loanPaymentId = loanPayment.id;
    this.loanPaymentFileData = loanPayment.loanPaymentFileData;
    // open modal
    this.modalService.open(modal);
  }
  async closePaymentDocument() {
    // clear loanPayment
    this.loanPayment = null;
    this.loanPaymentId = null;
    // hide modal
    this.modalService.dismissAll();
  }
  // readjust payments
  async openReadjustPayment(modal: any) {
    // open modal
    this.modalService.open(modal);
  }
  async cancelReadjustPayment() {
    // hide modal
    this.modalService.dismissAll();
  }
  async saveReadjustPayment() {
    // markAllAsTouched
    this.loanPaymentFrm.markAllAsTouched();
    // validate data
    if (!this.loanPaymentFrm.valid) {
      // send alert
      this.alertSv.showMessage(this.translate.instant('HELPERS.requiredFields'),'warning', this.translate.instant('HELPERS.warning'), true);
    } else {
      // get loanPaymentIndex
      const loanPaymentIndex = this.loanPayments.findIndex((loanPayment: LoanPaymentInterface) => loanPayment.id === this.loanPaymentId);
      // get loanPayment
      const loanPayment: LoanPaymentInterface = this.loanPayments[loanPaymentIndex];
      // readjustPayment
      await this.readjustPayment(loanPayment);
      // hide modal
      this.modalService.dismissAll();
    }
  }
  async getSuggestedPayment(loanPayment: LoanPaymentInterface) {
    // init loanPaymentInterestPercentage
    const paymentInterestPercentageValue = loanPayment.loanPaymentInterestPercentage;
    const paymentInterestPercentage = paymentInterestPercentageValue / 100;
    // init loanPaymentStart
    let loanPaymentStart: number = 0;
    // init singlePayment
    let singlePayment: number = 0;
    let loanPaymentInterest: number = 0;
    let loanPaymentDebt: number = 0;
    let loanPaymentEnd: number = 0;
    let futureValue: number = 0;
    let numerator: number = 0;
    let denominator: number = 0;
    let newSinglePayment: number = 0;
    // loop loanPayments
    for (let index = 0; index < this.loanPayments.length; index++) {
      // init loanPaymentItem
      let loanPaymentItem: LoanPaymentInterface = this.loanPayments[index];
      // get leftPayments
      const leftPayments: number = this.loanPayments.length - loanPaymentItem.loanPaymentNumber;
      // update loanPaymentStart
      loanPaymentStart = loanPaymentStart === 0 ? loanPaymentItem.loanPaymentStart : loanPaymentStart;
      // update singlePayment
      singlePayment = singlePayment === 0 ? loanPaymentItem.loanPaymentAmount : singlePayment;
      // check if payment status
      if (loanPaymentItem.loanPaymentStatusLast === 'overdued') {
        // get calc data
        futureValue = this.utilitiesSv.roundNumber((singlePayment * Math.pow(1 + paymentInterestPercentage, 1)), 'up', 0);
        loanPaymentInterest = this.utilitiesSv.roundNumber((loanPaymentStart * paymentInterestPercentage), 'up', 0);
        loanPaymentEnd = this.utilitiesSv.roundNumber((loanPaymentStart - (-loanPaymentInterest) + futureValue), 'up', 0);
        loanPaymentDebt = -loanPaymentInterest;
        // calc newSinglePayment
        numerator = loanPaymentEnd * Math.pow((1 + paymentInterestPercentage), leftPayments) * paymentInterestPercentage;
        denominator = Math.pow((1 + paymentInterestPercentage), leftPayments) - 1;
        newSinglePayment = this.utilitiesSv.roundNumber((numerator / denominator), 'up', 0);
        // update loop paymentData
        loanPaymentStart = loanPaymentEnd;
        singlePayment = newSinglePayment;
      } else if (loanPaymentItem.loanPaymentStatusLast === 'pending' && loanPaymentItem.loanPaymentNumber <= loanPayment.loanPaymentNumber) {
        // get calc data
        loanPaymentInterest = this.utilitiesSv.roundNumber((loanPaymentStart * paymentInterestPercentage), 'up', 0);
        // calc newSinglePayment
        newSinglePayment = this.utilitiesSv.roundNumber((singlePayment ), 'up', 0);
        // get loanPaymentDebt
        loanPaymentDebt = this.utilitiesSv.roundNumber((singlePayment - loanPaymentInterest), 'up', 0);
        // update loanPaymentEnd
        loanPaymentEnd = this.utilitiesSv.roundNumber((loanPaymentStart - loanPaymentDebt), 'up', 0);
        // update loop paymentData
        loanPaymentStart = loanPaymentEnd;
        singlePayment = newSinglePayment;
      } else {
        // get calc data
        loanPaymentInterest = this.utilitiesSv.roundNumber((loanPaymentStart * paymentInterestPercentage), 'up', 0);
        loanPaymentDebt = this.utilitiesSv.roundNumber((singlePayment - loanPaymentInterest), 'up', 0);
        loanPaymentEnd = this.utilitiesSv.roundNumber((loanPaymentStart - loanPaymentDebt), 'up', 0);
        loanPaymentEnd = loanPaymentEnd < 0 ? 0 : loanPaymentEnd;
        // update loop paymentData
        loanPaymentStart = loanPaymentEnd;
        singlePayment = newSinglePayment;
      }
    }
    return singlePayment;
  }
  async readjustPayment(loanPayment: LoanPaymentInterface) {
    // show loader
    this.loaderEvent.emit(this.translate.instant('HELPERS.loadingTxt') + ' ' + this.translate.instant('LOANPAYMENTS.loanPayments'));
    // get currentDateTime
    const currentDateTime: string = moment().toISOString();
    // get payedAmount
    const paymentAmount = this.loanPaymentFrm.controls.paymentAmount.value;
    const payedAmount = typeof paymentAmount === 'string' ? parseFloat(this.utilitiesSv.removeDotsFromCurrencyString(paymentAmount)) : parseFloat(paymentAmount);
    // init loanPaymentInterestPercentage
    const paymentInterestPercentageValue = this.loanPaymentFrm.controls.paymentInterestPercentage.value;
    const paymentInterestPercentage = paymentInterestPercentageValue / 100;
    // init loanPaymentStart
    let loanPaymentStart: number = 0;
    // init singlePayment
    let singlePayment: number = 0;
    let loanPaymentInterest: number = 0;
    let loanPaymentDebt: number = 0;
    let loanPaymentEnd: number = 0;
    let futureValue: number = 0;
    let newAmount: number = 0;
    let numerator: number = 0;
    let denominator: number = 0;
    let newSinglePayment: number = 0;
    // check overdued payments
    const overduedPayments: boolean = await this.checkOverduedPayments();
    // loop loanPayments
    for (let index = 0; index < this.loanPayments.length; index++) {
      // init loanPaymentItem
      let loanPaymentItem: LoanPaymentInterface = this.loanPayments[index];
      // show loader
      this.loaderEvent.emit(this.translate.instant('STATUSES.reviewing') + ' ' + this.translate.instant('LOANPAYMENTS.loanPayment') + ' ' + this.translate.instant('LOANPAYMENTS.loanPaymentNumberNumber') + ' ' + loanPaymentItem.loanPaymentNumber);
      // get leftPayments
      const leftPayments: number = this.loanPayments.length - loanPaymentItem.loanPaymentNumber;
      // init addFutureValue
      let addFutureValue: string = null;
      // init paymentDifference
      let paymentDifference: number = 0;
      // update loanPaymentStart
      loanPaymentStart = loanPaymentStart === 0 ? loanPaymentItem.loanPaymentStart : loanPaymentStart;
      // update singlePayment
      singlePayment = singlePayment === 0 ? loanPaymentItem.loanPaymentAmount : singlePayment;
      // check if payment status
      if (loanPaymentItem.loanPaymentStatusLast === 'overdued') {
        // get calc data
        futureValue = this.utilitiesSv.roundNumber((singlePayment * Math.pow(1 + paymentInterestPercentage, 1)), 'up', 0);
        loanPaymentInterest = this.utilitiesSv.roundNumber((loanPaymentStart * paymentInterestPercentage), 'up', 0);
        loanPaymentEnd = this.utilitiesSv.roundNumber((loanPaymentStart - (-loanPaymentInterest) + futureValue), 'up', 0);
        loanPaymentDebt = -loanPaymentInterest;
        // calc newSinglePayment
        numerator = loanPaymentEnd * Math.pow((1 + paymentInterestPercentage), leftPayments) * paymentInterestPercentage;
        denominator = Math.pow((1 + paymentInterestPercentage), leftPayments) - 1;
        newSinglePayment = this.utilitiesSv.roundNumber((numerator / denominator), 'up', 0);
        // update loanPaymentData
        loanPaymentItem.loanPaymentStart = loanPaymentStart;
        loanPaymentItem.loanPaymentAmount = 0;
        loanPaymentItem.loanPaymentInterest = loanPaymentInterest;
        loanPaymentItem.loanPaymentInterestPercentage = paymentInterestPercentageValue;
        loanPaymentItem.loanPaymentDebt = loanPaymentDebt;
        loanPaymentItem.loanPaymentEnd = loanPaymentEnd;
        loanPaymentItem.loanPaymentStatusLast = 'recalculated';
        loanPaymentItem.loanPaymentStatusDateLast = currentDateTime;
        loanPaymentItem.loanPaymentReceipt = loanPaymentItem.loanPaymentFileData.image;
        // update loop paymentData
        loanPaymentStart = loanPaymentEnd;
        singlePayment = newSinglePayment;
        // save payment
        await this.savePayment(loanPaymentItem);
        // update loanPayment data on array
        this.loanPayments[index].loanPaymentStart = loanPaymentItem.loanPaymentStart;
        this.loanPayments[index].loanPaymentAmount = loanPaymentItem.loanPaymentAmount;
        this.loanPayments[index].loanPaymentInterest = loanPaymentItem.loanPaymentInterest;
        this.loanPayments[index].loanPaymentInterestPercentage = loanPaymentItem.loanPaymentInterestPercentage;
        this.loanPayments[index].loanPaymentDebt = loanPaymentItem.loanPaymentDebt;
        this.loanPayments[index].loanPaymentEnd = loanPaymentItem.loanPaymentEnd;
        this.loanPayments[index].loanPaymentStatusLast = loanPaymentItem.loanPaymentStatusLast;
        this.loanPayments[index].loanPaymentStatusDateLast = loanPaymentItem.loanPaymentStatusDateLast;
        this.loanPayments[index].loanPaymentReceipt = loanPaymentItem.loanPaymentReceipt;
        this.loanPayments[index].loanPaymentFileData = this.loanPaymentFileData;
      } else if (loanPaymentItem.loanPaymentStatusLast === 'pending' && loanPaymentItem.loanPaymentNumber <= loanPayment.loanPaymentNumber) {
        // compare payedAmount with loanPaymentAmount
        if (payedAmount > singlePayment) { // payed more than single payment
          // update paymentDifference
          paymentDifference = payedAmount - singlePayment;
          // update singlePayment
          singlePayment = payedAmount;
          // addFutureValue
          addFutureValue = 'up';
        } else if (payedAmount < singlePayment) { // payed less than single payment

          console.log('payedAmount < singlePayment');
          
          // update paymentDifference
          paymentDifference = singlePayment - payedAmount;
          // update singlePayment
          singlePayment = payedAmount;
          // addFutureValue
          addFutureValue = 'down';
        } else if (payedAmount === singlePayment) { // payed equal than single payment

          console.log('payedAmount === singlePayment');

          // update paymentDifference
          paymentDifference = 0;
          // update singlePayment
          singlePayment = payedAmount;
          // addFutureValue
          addFutureValue = 'equal';
        } else if (payedAmount === 0) {

          console.log('payedAmount === 0');

          // update singlePayment
          singlePayment = loanPaymentItem.loanPaymentAmount;
        } else {
          // update singlePayment
          singlePayment = payedAmount;
        }
        // check addFutureValue
        if (addFutureValue !== null) {
          // check addFutureValue
          if (addFutureValue === 'up') {
            // get calc data
            futureValue =  overduedPayments ? this.utilitiesSv.roundNumber((paymentDifference * Math.pow(1 + paymentInterestPercentage, 1)), 'up', 0) : 0;
            loanPaymentInterest = this.utilitiesSv.roundNumber((loanPaymentStart * paymentInterestPercentage), 'up', 0);
            loanPaymentDebt = this.utilitiesSv.roundNumber((payedAmount - loanPaymentInterest), 'up', 0);
            loanPaymentEnd = this.utilitiesSv.roundNumber((loanPaymentStart - loanPaymentDebt + (futureValue)), 'up', 0);
            // get new amount
            newAmount = loanPaymentEnd;
            // calc newSinglePayment
            numerator = newAmount * Math.pow((1 + paymentInterestPercentage), leftPayments) * paymentInterestPercentage;
            denominator = Math.pow((1 + paymentInterestPercentage), leftPayments) - 1;
            newSinglePayment = this.utilitiesSv.roundNumber((numerator / denominator), 'up', 0);
          } else if (addFutureValue === 'down') {
            // get calc data
            futureValue = overduedPayments ? this.utilitiesSv.roundNumber((paymentDifference * Math.pow(1 + paymentInterestPercentage, 1)), 'up', 0) : 0;
            loanPaymentInterest = this.utilitiesSv.roundNumber((loanPaymentStart * paymentInterestPercentage), 'up', 0);
            loanPaymentDebt = this.utilitiesSv.roundNumber((payedAmount - loanPaymentInterest), 'up', 0);
            loanPaymentEnd = this.utilitiesSv.roundNumber((loanPaymentStart - loanPaymentDebt + futureValue), 'up', 0);
            // get new amount
            newAmount = loanPaymentEnd;
            // calc newSinglePayment
            numerator = newAmount * Math.pow((1 + paymentInterestPercentage), leftPayments) * paymentInterestPercentage;
            denominator = Math.pow((1 + paymentInterestPercentage), leftPayments) - 1;
            newSinglePayment = this.utilitiesSv.roundNumber((numerator / denominator), 'up', 0);
          } else if (addFutureValue === 'equal') {
            // get calc data
            loanPaymentInterest = this.utilitiesSv.roundNumber((loanPaymentStart * paymentInterestPercentage), 'up', 0);
            // calc newSinglePayment
            newSinglePayment = this.utilitiesSv.roundNumber((singlePayment ), 'up', 0);
            // get loanPaymentDebt
            loanPaymentDebt = this.utilitiesSv.roundNumber((singlePayment - loanPaymentInterest), 'up', 0);
            // update loanPaymentEnd
            loanPaymentEnd = this.utilitiesSv.roundNumber((loanPaymentStart - loanPaymentDebt), 'up', 0);
            // get new amount
            newAmount = loanPaymentEnd;
          }
          // update loanPaymentData
          loanPaymentItem.loanPaymentStart = loanPaymentStart;
          loanPaymentItem.loanPaymentAmount = payedAmount;
          loanPaymentItem.loanPaymentInterest = loanPaymentInterest;
          loanPaymentItem.loanPaymentInterestPercentage = paymentInterestPercentageValue;
          loanPaymentItem.loanPaymentDebt = loanPaymentDebt;
          loanPaymentItem.loanPaymentEnd = loanPaymentEnd;
          loanPaymentItem.loanPaymentStatusLast = 'payed';
          loanPaymentItem.loanPaymentStatusDateLast = currentDateTime;
          loanPaymentItem.loanPaymentReceipt = loanPaymentItem.loanPaymentFileData.image;
          // update loop paymentData
          loanPaymentStart = loanPaymentEnd;
          singlePayment = newSinglePayment;
          // save payment
          await this.savePayment(loanPaymentItem);
          // update loanPayment data on array
          this.loanPayments[index].loanPaymentStart = loanPaymentItem.loanPaymentStart;
          this.loanPayments[index].loanPaymentAmount = loanPaymentItem.loanPaymentAmount;
          this.loanPayments[index].loanPaymentInterest = loanPaymentItem.loanPaymentInterest;
          this.loanPayments[index].loanPaymentInterestPercentage = loanPaymentItem.loanPaymentInterestPercentage;
          this.loanPayments[index].loanPaymentDebt = loanPaymentItem.loanPaymentDebt;
          this.loanPayments[index].loanPaymentEnd = loanPaymentItem.loanPaymentEnd;
          this.loanPayments[index].loanPaymentStatusLast = loanPaymentItem.loanPaymentStatusLast;
          this.loanPayments[index].loanPaymentStatusDateLast = loanPaymentItem.loanPaymentStatusDateLast;
          this.loanPayments[index].loanPaymentReceipt = loanPaymentItem.loanPaymentReceipt;
          this.loanPayments[index].loanPaymentFileData = this.loanPaymentFileData;
        } else {
          // update loanPaymentData
          loanPaymentItem.loanPaymentAmount = singlePayment,
          loanPaymentItem.loanPaymentStatusLast = 'payed';
          loanPaymentItem.loanPaymentStatusDateLast = currentDateTime;
          // save payment
          await this.savePayment(loanPaymentItem);
          // update loanPayment data on array
          this.loanPayments[index].loanPaymentStart = loanPaymentItem.loanPaymentStart;
          this.loanPayments[index].loanPaymentAmount = loanPaymentItem.loanPaymentAmount;
          this.loanPayments[index].loanPaymentInterest = loanPaymentItem.loanPaymentInterest;
          this.loanPayments[index].loanPaymentInterestPercentage = loanPaymentItem.loanPaymentInterestPercentage;
          this.loanPayments[index].loanPaymentDebt = loanPaymentItem.loanPaymentDebt;
          this.loanPayments[index].loanPaymentEnd = loanPaymentItem.loanPaymentEnd;
          this.loanPayments[index].loanPaymentStatusLast = loanPaymentItem.loanPaymentStatusLast;
          this.loanPayments[index].loanPaymentStatusDateLast = loanPaymentItem.loanPaymentStatusDateLast;
          this.loanPayments[index].loanPaymentReceipt = loanPaymentItem.loanPaymentReceipt;
          this.loanPayments[index].loanPaymentFileData = this.loanPaymentFileData;
        }
      } else if (loanPaymentItem.loanPaymentStatusLast !== 'payed') {
        // get calc data
        loanPaymentInterest = this.utilitiesSv.roundNumber((loanPaymentStart * paymentInterestPercentage), 'up', 0);
        loanPaymentDebt = this.utilitiesSv.roundNumber((singlePayment - loanPaymentInterest), 'up', 0);
        loanPaymentEnd = this.utilitiesSv.roundNumber((loanPaymentStart - loanPaymentDebt), 'up', 0);
        loanPaymentEnd = loanPaymentEnd < 0 ? 0 : loanPaymentEnd;
        // update loanPaymentData
        loanPaymentItem.loanPaymentAmount = newSinglePayment,
        loanPaymentItem.loanPaymentInterest = loanPaymentInterest,
        loanPaymentItem.loanPaymentDebt = loanPaymentDebt,
        loanPaymentItem.loanPaymentEnd = loanPaymentEnd,
        // save payment
        await this.savePayment(loanPaymentItem);
        // update loop paymentData
        loanPaymentStart = loanPaymentEnd;
        singlePayment = newSinglePayment;
        // update loanPayment data on array
        this.loanPayments[index].loanPaymentStart = loanPaymentItem.loanPaymentStart;
        this.loanPayments[index].loanPaymentAmount = loanPaymentItem.loanPaymentAmount;
        this.loanPayments[index].loanPaymentInterest = loanPaymentItem.loanPaymentInterest;
        this.loanPayments[index].loanPaymentInterestPercentage = loanPaymentItem.loanPaymentInterestPercentage;
        this.loanPayments[index].loanPaymentDebt = loanPaymentItem.loanPaymentDebt;
        this.loanPayments[index].loanPaymentEnd = loanPaymentItem.loanPaymentEnd;
        this.loanPayments[index].loanPaymentStatusLast = loanPaymentItem.loanPaymentStatusLast;
        this.loanPayments[index].loanPaymentStatusDateLast = loanPaymentItem.loanPaymentStatusDateLast;
        this.loanPayments[index].loanPaymentReceipt = loanPaymentItem.loanPaymentReceipt;
        this.loanPayments[index].loanPaymentFileData = this.loanPaymentFileData;
      }
    }
  }
  async checkOverduedPayments() {
    // show loader
    this.loaderEvent.emit(this.translate.instant('STATUSES.reviewing') + ' ' + this.translate.instant('LOANPAYMENTS.loanPayments'));
    // loop loanPayments
    for (let index = 0; index < this.loanPayments.length; index++) {
      // init loanPaymentItem
      let loanPaymentItem: LoanPaymentInterface = this.loanPayments[index];
      // check if payment status
      if (loanPaymentItem.loanPaymentStatusLast === 'overdued') {
        // return response
        return true;
      }
    }
    // return response
    return false;
  }
  async savePayment(loanPayment: LoanPaymentInterface) {
    try {
      // set loanPaymentData
      const loanPaymentData: LoanPaymentInterface = {
        loanPaymentStart: loanPayment.loanPaymentStart,
        loanPaymentAmount: loanPayment.loanPaymentAmount,
        loanPaymentInterest: loanPayment.loanPaymentInterest,
        loanPaymentInterestPercentage: loanPayment.loanPaymentInterestPercentage,
        loanPaymentDebt: loanPayment.loanPaymentDebt,
        loanPaymentEnd: loanPayment.loanPaymentEnd,
        loanPaymentStatusLast: loanPayment.loanPaymentStatusLast,
        loanPaymentStatusDateLast: loanPayment.loanPaymentStatusDateLast,
        loanPaymentReceipt: loanPayment.loanPaymentReceipt,
      }
      // update loanPaymentData
      await this.apiSv.updateLoanPayment(loanPayment.id, loanPaymentData);
      // check if payment status is different than pending
      if (loanPayment.loanPaymentStatusLast !== 'pending') {
        // set loanPaymentStatusData
        const loanPaymentStatusData: LoanPaymentStatusInterface = {
          loanPaymentId: loanPayment.id,
          loanPaymentStatus: loanPayment.loanPaymentStatusLast,
          loanPaymentStatusObservation: null
        }
        // add loanPaymentStatusData
        await this.apiSv.addLoanPaymentStatus(loanPaymentStatusData);
      }
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // hide modal
      this.modalService.dismissAll();
      // handle error
      this.filterSv.handleError(error);
    }
  }
}
