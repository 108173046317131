<!-- company -->
<div class="card social-profile mb-4" *ngIf="origin === 'company'">
  <!-- portrait -->
  <div class="card-portrait">
    <img class="portrait-image" [src]="companyPortrait" [alt]="businessName" onError="src = 'assets/images/broken-image-slim.png'" *ngIf="companyPortrait !== null">
    <img class="portrait-no-image" src="assets/images/broken-image-slim.png" [alt]="businessName" *ngIf="companyPortrait === null">
  </div>
  <!-- body -->
  <div class="card-body main-data">
    <!-- edit -->
    <button class="btn btn-primary btn-xs btn-icon-only edit-form-btn" (click)="edit()" *ngIf="editEnable">
      <app-feather-icons [icon]="'edit-3'"></app-feather-icons>
    </button>
    <!-- companyLogo -->
    <div class="social-img-wrap"> 
      <div class="social-img">
        <img [src]="companyLogo" [alt]="businessName" onError="src = 'assets/images/broken-image-slim.png'" *ngIf="companyLogo !== null">
        <img src="assets/images/broken-image-slim.png" alt="Avatar" *ngIf="companyLogo === null">
      </div>
    </div>
    <!-- activity -->
    <div class="activity">
      <h5>{{ companyActivityCode }}</h5>
      <p>{{ companyActivity }}</p>
    </div>
    <!-- names & contact -->
    <div class="social-details">
      <!-- names -->
      <h5 class="mb-1"><b>{{ businessName }}</b></h5>
      <h6>{{ companyName }}</h6>
      <p class="mt-0">{{ companyIdentification }}</p>
      <!-- status -->
      <p class="table-status-txt">
        <span class="status-approved" *ngIf="companyStatus === true">{{ 'STATUSES.active' | translate }}</span>
        <span class="status-rejected" *ngIf="companyStatus === false">{{ 'STATUSES.unactive' | translate }}</span>
      </p>
      <!-- contact options -->
      <ul class="card-social">
        <li>
          <a class="contact-option" [href]="'mailto:' + companyEmail">
            <app-feather-icons class="feather-icon contact-icon email" [icon]="'mail'"></app-feather-icons>
            <span class="social-btn-txt">{{ companyEmail }}</span>
          </a>
        </li>
        <li *ngIf="companyPhone !== null">
          <a class="contact-option" [href]="'tel: +' + companyPhoneCountryCodeCod + companyPhone">
            <app-feather-icons class="feather-icon contact-icon phone" [icon]="'phone-call'"></app-feather-icons>
            <span class="social-btn-txt">+{{ companyPhoneCountryCodeCod }}&nbsp;{{ companyPhone | phonePipe }}</span>
          </a>
        </li>
        <li *ngIf="companyCellphone !== null">
          <a class="contact-option" [href]="'tel: +' + companyCellphoneCountryCodeCod + companyCellphone">
            <app-feather-icons class="feather-icon contact-icon cellphone" [icon]="'smartphone'"></app-feather-icons>
            <span class="social-btn-txt">+{{ companyCellphoneCountryCodeCod }}&nbsp;{{ companyCellphone | phonePipe }}</span>
          </a>
        </li>
        <li *ngIf="companyWhatsapp !== null">
          <a class="contact-option" [href]="'tel: +' + companyWhatsappCountryCodeCod + companyWhatsapp">
            <i class="fa fa-whatsapp contact-icon whatsapp"></i>
            <span class="social-btn-txt">+{{ companyWhatsappCountryCodeCod }}&nbsp;{{ companyWhatsapp | phonePipe }}</span>
          </a>
        </li>
      </ul>
      <!-- social options -->
      <ul class="card-social">
        <li *ngIf="companyUriFacebook !== null">
          <a class="contact-option" [href]="companyUriFacebook">
            <app-feather-icons class="feather-icon contact-icon facebook" [icon]="'facebook'"></app-feather-icons>
            <span class="social-btn-txt">Facebook</span>
          </a>
        </li>
        <li *ngIf="companyUserInstagram !== null">
          <a class="contact-option" [href]="'https://www.instagram.com/' + companyUserInstagram + '/'">
            <app-feather-icons class="feather-icon contact-icon instagram" [icon]="'instagram'"></app-feather-icons>
            <span class="social-btn-txt">Instagram</span>
          </a>
        </li>
        <li *ngIf="companyUserTwitter !== null">
          <a class="contact-option" [href]="companyUserTwitter">
            <app-feather-icons class="feather-icon contact-icon twitter" [icon]="'twitter'"></app-feather-icons>
            <span class="social-btn-txt">Twitter</span>
          </a>
        </li>
        <li *ngIf="companyUriTiktok !== null">
          <a class="contact-option" [href]="'https://www.tiktok.com/@' + companyUriTiktok">
            <svg class="bi bi-tiktok svg-icons contact-icon tiktok" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"> 
              <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z"/> 
            </svg>
            <span class="social-btn-txt">TikTok</span>
          </a>
        </li>
        <li *ngIf="companyUriYoutube !== null">
          <a class="contact-option" [href]="companyUriYoutube">
            <app-feather-icons class="feather-icon contact-icon youtube" [icon]="'youtube'"></app-feather-icons>
            <span class="social-btn-txt">YouTube</span>
          </a>
        </li>
        <li *ngIf="companyUriLinkedin !== null">
          <a class="contact-option" [href]="companyUriLinkedin">
            <app-feather-icons class="feather-icon contact-icon linkedin" [icon]="'linkedin'"></app-feather-icons>
            <span class="social-btn-txt">LinkedIn</span>
          </a>
        </li>
        <li *ngIf="companyWeb !== null">
          <a class="contact-option" [href]="companyWebProtocol + companyWeb">
            <app-feather-icons class="feather-icon contact-icon web" [icon]="'link'"></app-feather-icons>
            <span class="social-btn-txt">Web</span>
          </a>
        </li>
      </ul>
      <!-- address -->
      <div class="row mt-4">
        <div class="col-12">
          <h6 class="text-center">{{ countryName }} - {{ stateName }} - {{ cityName }}</h6>
          <p class="mb-0 text-center">{{ address }}</p>
          <p class="text-center">{{ address2 }}</p>
        </div>
      </div>
      <!-- companyAbout -->
      <div class="row mt-4 mb-4">
        <div class="col-12">
          <h4>{{ 'COMPANY.formLabels.companyAbout' | translate }}</h4>
          <p [innerHTML]="companyAbout"></p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- other company -->
<div class="card social-profile mb-4" *ngIf="origin === 'otherCompany'">
  <!-- body -->
  <div class="card-body">
    <!-- edit -->
    <button class="btn btn-primary btn-xs btn-icon-only edit-form-btn" (click)="edit()" *ngIf="editEnable">
      <app-feather-icons [icon]="'edit-3'"></app-feather-icons>
    </button>
    <!-- basic data -->
    <div class="social-details">
      <h4 class="mb-1"><b>{{ companyName }}</b></h4>
      <h6>{{ companyIdentification }}</h6>
      <!-- activity -->
      <div class="activity">
        <h5>{{ companyActivityCode }}</h5>
        <p>{{ companyActivity }}</p>
      </div>
      <!-- status -->
      <p class="table-status-txt">
        <span class="status-sended" *ngIf="companyStatus === 'pendingForReview'">{{ 'STATUSES.pendingReview' | translate }}</span>
        <span class="status-approved" *ngIf="companyStatus === 'active'">{{ 'STATUSES.active' | translate }}</span>
        <span class="status-rejected" *ngIf="companyStatus === 'unactive'">{{ 'STATUSES.unactive' | translate }}</span>
        <span class="status-approved" *ngIf="companyStatus === true">{{ 'STATUSES.active' | translate }}</span>
        <span class="status-rejected" *ngIf="companyStatus === false">{{ 'STATUSES.unactive' | translate }}</span>
      </p>
    </div>
    <!-- address -->
    <div class="row" *ngIf="address !== null">
      <div class="col-12">
        <h6 class="text-center">{{ countryName }} - {{ stateName }} - {{ cityName }}</h6>
        <p class="mb-0 text-center">{{ address }}</p>
        <p class="text-center">{{ address2 }}</p>
      </div>
    </div>
  </div>
</div>