<!-- form -->
<form class="theme-form" [formGroup]="mainFrm">
  <!-- header -->
  <div class="row mt-4">
    <!-- title -->
    <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
      <h4>{{ 'PROFESSION.profession' | translate }}</h4>
      <p>{{ 'PROFESSION.professionInfo' | translate }}</p>
    </div>
    <!-- help -->
    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 pull-right">
      <div class="btn-with-outside-txt-lbl help-btn" (click)="help()">
        <small class="btn-inner-txt">{{ 'HELPERS.help' | translate }}</small> 
        <a class="btn btn-light btn-xs btn-icon-only btn-icon-text-left">
          <app-feather-icons [icon]="'help-circle'"></app-feather-icons>
        </a>
      </div>
    </div>
  </div>
  <!-- professionName / professionStatus -->
  <div class="row">
    <!-- professionName -->
    <div class="col-12">
      <div class="form-group">
        <label for="professionName">{{ 'PROFESSION.formLabels.professionName' | translate }} <span class="required-lbl">*</span></label>
        <input class="form-control" #professionName id="professionName" type="text" inputmode="text" [placeholder]="'PROFESSION.formPlaceHolders.professionName' | translate" formControlName="professionName">
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.professionName">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('professionName').hasError(error.type) && (mainFrm.get('professionName').dirty || mainFrm.get('professionName').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'PROFESSION.formHelp.professionName' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- professionStatus -->
    <div class="form-group select-fix col-12">
      <span class="caret-select"></span>
      <label for="professionStatusSelect" class="col-form-label">{{ 'STATUSES.status' | translate }} <span class="required-lbl">*</span></label>
      <select class="form-control" #professionStatusSelect id="professionStatusSelect" name="professionStatusSelect" formControlName="professionStatus" [title]="'STATUSES.status' | translate">
        <option value="null" selected disabled>{{ 'HELPERS.selectAnOption' | translate }}</option>
        <option value="pendingForReview">{{ 'STATUSES.pendingReview' | translate }}</option>
        <option value="active">{{ 'STATUSES.activeFemale' | translate }}</option>
        <option value="unactive">{{ 'STATUSES.unactiveFemale' | translate }}</option>
      </select>
      <ng-container class="error-messages" *ngFor="let error of formErrorMessages.professionStatus">
        <small class="text-danger error-msg" *ngIf="mainFrm.get('professionStatus').hasError(error.type) && (mainFrm.get('professionStatus').dirty || mainFrm.get('professionStatus').touched)">
          {{ error.message }}.&nbsp;
        </small>
      </ng-container>
      <p class="help-messages" *ngIf="toggleHelp">
        <small>{{ 'PROFESSION.formHelp.professionStatus' | translate }}</small>
      </p>
    </div>
  </div>
  <!-- buttons -->
  <div class="row mt-4">
    <div class="col">
      <div class="request-form-btns-container">
        <button class="btn btn-light d-block btn-cancel" (click)="cancel()" type="button">
          <span>{{ 'HELPERS.cancel' | translate }}</span>
        </button>
        <button class="btn btn-dark d-block" (click)="save()" type="button">
          <span>{{ 'HELPERS.save' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</form>