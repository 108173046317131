<!-- form -->
<form class="theme-form" [formGroup]="mainFrm">
  <!-- images -->
  <div class="row mb-4" *ngIf="!(origin === 'otherCompany' || origin === 'otherCompanyLoan')">
    <!-- portrait -->
    <div class="col-12 portrait-container" id="aniimated-thumbnials" itemscope="">
      <!-- image -->
      <div class="img-portrait">
        <img [src]="companyPortraitSource" [alt]="businessName" onError="src = 'assets/images/broken-image-slim.png'" *ngIf="companyPortraitSource !== null">
        <img src="assets/images/broken-image-slim.png" [alt]="businessName" *ngIf="companyPortraitSource === null">
      </div>
      <!-- button -->
      <div class="add-icon" (click)="editImage('portrait')" *ngIf="!savedData">
        <app-feather-icons class="feather-icon" [icon]="'camera'"></app-feather-icons>
      </div>
      <div class="edit-icon" (click)="editImage('portrait')" *ngIf="savedData">
        <app-feather-icons class="feather-icon" [icon]="'edit-3'" ></app-feather-icons>
      </div>
    </div>
    <!-- logo -->
    <div class="col-12">
      <div class="logo-container">
        <div class="social-img-wrap">
          <!-- image -->
          <div class="social-img">
            <img [src]="companyLogoSource" [alt]="businessName" onError="src = 'assets/images/broken-image-slim.png'" *ngIf="companyLogoSource !== null">
            <img src="assets/images/broken-image-slim.png" [alt]="businessName" *ngIf="companyLogoSource === null">
          </div>
          <!-- button -->
          <div class="add-icon" (click)="editImage('logo')" *ngIf="!savedData">
            <app-feather-icons class="feather-icon" [icon]="'camera'"></app-feather-icons>
          </div>
          <div class="edit-icon" (click)="editImage('logo')" *ngIf="savedData">
            <app-feather-icons class="feather-icon" [icon]="'edit-3'" ></app-feather-icons>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- file input -->
  <input type="file" #fileInput style="display: none" (change)="onFileSelected($event, imageCropModal)" />
  <!-- header -->
  <div class="row">
    <!-- title -->
    <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
      <h4>{{ 'COMPANY.company' | translate }}</h4>
      <p>{{ 'COMPANY.companyInfo' | translate }}</p>
    </div>
    <!-- help -->
    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 pull-right">
      <div class="btn-with-outside-txt-lbl help-btn" (click)="help()">
        <small class="btn-inner-txt">{{ 'HELPERS.help' | translate }}</small> 
        <a class="btn btn-light btn-xs btn-icon-only btn-icon-text-left">
          <app-feather-icons [icon]="'help-circle'"></app-feather-icons>
        </a>
      </div>
    </div>
  </div>
  <!-- companyActivity -->
  <div class="row mt-4">
    <div class="col-12">
      <div class="form-group select-fix">
        <span class="caret-select"></span>
        <label for="companyActivitySelect" class="col-form-label">{{ 'COMPANY.formLabels.companyActivity' | translate }} <span class="required-lbl">*</span></label>
        <ng-select [items]="allCompanyActivities"
          [selectOnTab]="true"
          bindLabel="companyActivity"
          bindValue="id"
          labelForId="companyActivitySelect"
          [placeholder]="'COMPANY.formPlaceHolders.companyActivity' | translate"
          #companyActivitySelect id="companyActivitySelect" name="companyActivitySelect" formControlName="companyActivity">
        </ng-select>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.companyActivity">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('companyActivity').hasError(error.type) && (mainFrm.get('companyActivity').dirty || mainFrm.get('companyActivity').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'COMPANY.formHelp.companyActivity' | translate }}</small>
        </p>
      </div>
    </div>
  </div>
  <!-- companyIdentification / companyName / businessName / companyConstitutionDate -->
  <div class="row">
    <!-- companyIdentification -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group">
        <label for="companyIdentification">{{ 'COMPANY.formLabels.companyIdentification' | translate }} <span class="required-lbl">*</span></label>
        <input class="form-control" #companyIdentification id="companyIdentification" type="text" inputmode="text" [placeholder]="'COMPANY.formPlaceHolders.companyIdentification' | translate" formControlName="companyIdentification">
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.companyIdentification">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('companyIdentification').hasError(error.type) && (mainFrm.get('companyIdentification').dirty || mainFrm.get('companyIdentification').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'COMPANY.formHelp.companyIdentification' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- companyName -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group">
        <label for="companyName">{{ 'COMPANY.formLabels.companyName' | translate }} <span class="required-lbl">*</span></label>
        <input class="form-control" #companyName id="companyName" type="text" inputmode="text" [placeholder]="'COMPANY.formPlaceHolders.companyName' | translate" formControlName="companyName">
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.companyName">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('companyName').hasError(error.type) && (mainFrm.get('companyName').dirty || mainFrm.get('companyName').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'COMPANY.formHelp.companyName' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- businessName -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="!(origin === 'otherCompany' || origin === 'otherCompanyLoan')">
      <div class="form-group">
        <label for="businessName">{{ 'COMPANY.formLabels.businessName' | translate }}</label>
        <input class="form-control" #businessName id="businessName" type="text" inputmode="text" [placeholder]="'COMPANY.formPlaceHolders.businessName' | translate" formControlName="businessName">
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.businessName">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('businessName').hasError(error.type) && (mainFrm.get('businessName').dirty || mainFrm.get('businessName').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'COMPANY.formHelp.businessName' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- companyConstitutionDate -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="!(origin === 'otherCompany' || origin === 'otherCompanyLoan')">
      <div class="form-group">
        <label for="companyConstitutionDate">{{ 'COMPANY.formLabels.companyConstitutionDate' | translate }} <span class="required-lbl">*</span></label>
        <input class="form-control digits" #companyConstitutionDate id="companyConstitutionDate" type="date" inputmode="text" formControlName="companyConstitutionDate" placeholder="dd-mm-yyyy"/>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.companyConstitutionDate">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('companyConstitutionDate').hasError(error.type) && (mainFrm.get('companyConstitutionDate').dirty || mainFrm.get('companyConstitutionDate').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'COMPANY.formHelp.companyConstitutionDate' | translate }}</small>
        </p>
      </div>
    </div>
  </div>
  <!-- companyAbout -->
  <div class="row" *ngIf="!(origin === 'otherCompany' || origin === 'otherCompanyLoan')">
    <div class="col-12">
      <div class="form-group">
        <label for="companyAboutInput">{{ 'COMPANY.formLabels.companyAbout' | translate }}</label>
        <angular-editor id="companyAboutInput" #companyAboutInput name="companyAboutInput" formControlName="companyAbout" [config]="editorConfig"></angular-editor>
      </div>
    </div>
  </div>
  <!-- companyEmail / companyCellphone -->
  <div class="row" *ngIf="!(origin === 'otherCompany' || origin === 'otherCompanyLoan')">
    <!-- companyEmail -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group">
        <label class="col-form-label">{{ 'COMPANY.formLabels.companyEmail' | translate }}</label>
        <input class="form-control" #companyEmail id="companyEmail" type="text" inputmode="text" [placeholder]="'COMPANY.formPlaceHolders.companyEmail' | translate" formControlName="companyEmail">
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'COMPANY.formHelp.companyEmail' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- companyCellphone -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div [ngClass]="{'hidden': (selectCompanyCellphoneCode)}">
        <label for="companyCellphone">{{ 'COMPANY.formLabels.companyCellphone' | translate }}</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text phone-select-btn" (click)="openSelectPhoneCountry('cellphone')">
              <img class="phone-select-img" [src]="companyCellphoneCountryFlag" [alt]="companyCellphoneCountryName">&nbsp;
              <span class="select-custom-txt phone-select-txt">+{{ companyCellphoneCountryCode }}</span>&nbsp;
              <i class="fa fa-caret-down" aria-hidden="true"></i>
            </span>
          </div>
          <input class="form-control" #companyCellphone id="companyCellphone" type="tel" inputmode="tel" [placeholder]="'COMPANY.formPlaceHolders.cellphone' | translate" formControlName="companyCellphone" (change)="checkPhoneNumber('cellphone')"/>
        </div>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'COMPANY.formHelp.companyCellphone' | translate }}</small>
        </p>
      </div>
      <div class="form-group" [ngClass]="{'hidden': (!selectCompanyCellphoneCode)}">
        <label for="companyCellphoneCountryCode" class="col-form-label required">{{ 'COMPANY.formLabels.countryPhoneCode' | translate }}</label>
        <br>
        <ng-select [items]="allCountries"
          [selectOnTab]="true"
          bindLabel="countryName"
          bindValue="id"
          labelForId="companyCellphoneCountryCodeSelect"
          [placeholder]="'COMPANY.formPlaceHolders.countryPhoneCode' | translate"
          (change)="selectCountry('companyCellphone')"
          #companyCellphoneCountryCodeSelect id="companyCellphoneCountryCodeSelect" name="companyCellphoneCountryCodeSelect" formControlName="companyCellphoneCountryCode">
        </ng-select>
      </div>
    </div>
  </div>
  <!-- companyPhone / companyWhatsapp -->
  <div class="row" *ngIf="!(origin === 'otherCompany' || origin === 'otherCompanyLoan')">
    <!-- companyPhone -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div [ngClass]="{'hidden': (selectCompanyCellphoneCode)}">
        <label for="companyPhone">{{ 'COMPANY.formLabels.companyPhone' | translate }}</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text phone-select-btn" (click)="openSelectPhoneCountry('cellphone')">
              <img class="phone-select-img" [src]="companyPhoneCountryFlag" [alt]="companyPhoneCountryName">&nbsp;
              <span class="select-custom-txt phone-select-txt">+{{ companyPhoneCountryCode }}</span>&nbsp;
              <i class="fa fa-caret-down" aria-hidden="true"></i>
            </span>
          </div>
          <input class="form-control" #companyPhone id="companyPhone" type="tel" inputmode="tel" [placeholder]="'COMPANY.formPlaceHolders.cellphone' | translate" formControlName="companyPhone" (change)="checkPhoneNumber('cellphone')"/>
        </div>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'COMPANY.formHelp.companyPhone' | translate }}</small>
        </p>
      </div>
      <div class="form-group" [ngClass]="{'hidden': (!selectCompanyCellphoneCode)}">
        <label for="companyPhoneCountryCode" class="col-form-label required">{{ 'COMPANY.formLabels.countryPhoneCode' | translate }}</label>
        <br>
        <ng-select [items]="allCountries"
          [selectOnTab]="true"
          bindLabel="countryName"
          bindValue="id"
          labelForId="companyPhoneCountryCodeSelect"
          [placeholder]="'COMPANY.formPlaceHolders.countryPhoneCode' | translate"
          (change)="selectCountry('companyPhone')"
          #companyPhoneCountryCodeSelect id="companyPhoneCountryCodeSelect" name="companyPhoneCountryCodeSelect" formControlName="companyPhoneCountryCode">
        </ng-select>
      </div>
    </div>
    <!-- companyWhatsapp -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div [ngClass]="{'hidden': (selectCompanyCellphoneCode)}">
        <label for="companyWhatsapp">{{ 'COMPANY.formLabels.companyWhatsapp' | translate }}</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text phone-select-btn" (click)="openSelectPhoneCountry('cellphone')">
              <img class="phone-select-img" [src]="companyWhatsappCountryFlag" [alt]="companyWhatsappCountryName">&nbsp;
              <span class="select-custom-txt phone-select-txt">+{{ companyWhatsappCountryCode }}</span>&nbsp;
              <i class="fa fa-caret-down" aria-hidden="true"></i>
            </span>
          </div>
          <input class="form-control" #companyWhatsapp id="companyWhatsapp" type="tel" inputmode="tel" [placeholder]="'COMPANY.formPlaceHolders.cellphone' | translate" formControlName="companyWhatsapp" (change)="checkPhoneNumber('cellphone')"/>
        </div>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'COMPANY.formHelp.companyWhatsapp' | translate }}</small>
        </p>
      </div>
      <div class="form-group" [ngClass]="{'hidden': (!selectCompanyCellphoneCode)}">
        <label for="companyWhatsappCountryCode" class="col-form-label required">{{ 'COMPANY.formLabels.countryPhoneCode' | translate }}</label>
        <br>
        <ng-select [items]="allCountries"
          [selectOnTab]="true"
          bindLabel="countryName"
          bindValue="id"
          labelForId="companyWhatsappCountryCodeSelect"
          [placeholder]="'COMPANY.formPlaceHolders.countryPhoneCode' | translate"
          (change)="selectCountry('companyWhatsapp')"
          #companyWhatsappCountryCodeSelect id="companyWhatsappCountryCodeSelect" name="companyWhatsappCountryCodeSelect" formControlName="companyWhatsappCountryCode">
        </ng-select>
      </div>
    </div>
  </div>
  <!-- location -->
  <div class="row">
    <!-- city -->
    <div class="col-6">
      <!-- label -->
      <div class="row p-0 m-0">
        <div class="col-12  p-0 m-0">
          <div class="form-group">
            <label class="col-form-label">
              <span *ngIf="countryName === null">{{ 'LOCATION.formLabels.countryStateCity' | translate }} <span class="required-lbl">*</span></span>
              <span *ngIf="countryName !== null">{{ countryName }}</span>
              <span *ngIf="stateName !== null"> - {{ stateName }}</span>
              <span *ngIf="cityName !== null"> - {{ cityName }}</span>
            </label>
          </div>
        </div>
      </div>
      <!-- country -->
      <div class="row" *ngIf="country === null">
        <div class="col-12">
          <div class="form-group location-selection">
            <ng-select [items]="allCountries"
              [selectOnTab]="true"
              bindLabel="countryName"
              bindValue="id"
              labelForId="countrySelect"
              [placeholder]="'LOCATION.formPlaceHolders.countrySelect' | translate"
              (change)="selectCountry('location')"
              #countrySelect id="countrySelect" name="countrySelect" formControlName="country">
            </ng-select>
          </div>
        </div>
      </div>
      <!-- state -->
      <div class="row" *ngIf="country != null && state === null">
        <div class="col-10">
          <div class="form-group location-selection">
            <ng-select [items]="allStates"
              [selectOnTab]="true"
              bindLabel="stateName"
              bindValue="id"
              labelForId="stateSelect"
              [placeholder]="'LOCATION.formPlaceHolders.stateSelect' | translate"
              (change)="selectState()"
              #stateSelect id="stateSelect" name="stateSelect" formControlName="state">
            </ng-select>
          </div>
        </div>
        <div class="col-2">
          <a class="btn btn-primary btn-icon-only btn-location-reset" (click)="resetLocationSelect()">
            <app-feather-icons [icon]="'refresh-cw'"></app-feather-icons>
          </a>
        </div>
      </div>
      <!-- city -->
      <div class="row" *ngIf="state != null">
        <div class="col-10">
          <div class="form-group location-selection">
            <ng-select [items]="allCities"
              [selectOnTab]="true"
              bindLabel="cityName"
              bindValue="id"
              labelForId="citySelect"
              [placeholder]="'LOCATION.formPlaceHolders.citySelect' | translate"
              (change)="selectCity()"
              #citySelect id="citySelect" name="citySelect" formControlName="city">
            </ng-select>
          </div>
        </div>
        <div class="col-2">
          <a class="btn btn-primary btn-icon-only btn-location-reset" (click)="resetLocationSelect()">
            <app-feather-icons [icon]="'refresh-cw'"></app-feather-icons>
          </a>
        </div>
      </div>
      <!-- error and help -->
      <div class="row p-0 m-0">
        <div class="col-12  p-0 m-0">
          <div class="form-group location-msgs">
            <ng-container class="error-messages" *ngFor="let error of formErrorMessages.city">
              <small class="text-danger error-msg" *ngIf="mainFrm.get('city').hasError(error.type) && (mainFrm.get('city').dirty || mainFrm.get('country').touched)">
                {{ error.message }}.&nbsp;
              </small>
            </ng-container>
            <p class="help-messages" *ngIf="toggleHelp">
              <small>{{ 'HELPERS.selectThe' | translate }}&nbsp;{{ 'LOCATION.formHelp.countryStateCity' | translate }}</small>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- address -->
    <div class="col-6">
      <div class="form-group">
        <label for="address">{{ 'LOCATION.formLabels.address' | translate }} <span class="required-lbl">*</span></label>
        <input class="form-control" #address id="address" type="text" inputmode="text" [placeholder]="'LOCATION.formPlaceHolders.address' | translate" formControlName="address" (keyup.enter)="setFocus(address2)">
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.address">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('address').hasError(error.type) && (mainFrm.get('address').dirty || mainFrm.get('address').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'LOCATION.formHelp.address' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- address2 -->
    <div class="col-12">
      <div class="form-group">
        <label for="address2">{{ 'LOCATION.formLabels.address2' | translate }}</label>
        <input class="form-control" #address2 id="address2" type="text" inputmode="text" [placeholder]="'LOCATION.formPlaceHolders.address2' | translate" formControlName="address2">
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'LOCATION.formHelp.address2' | translate }}</small>
        </p>
      </div>
    </div>
  </div>
  <!-- companyUriFacebook / companyUserInstagram -->
  <div class="row mt-4" *ngIf="!(origin === 'otherCompany' || origin === 'otherCompanyLoan')">
    <!-- companyUriFacebook -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group">
        <label for="companyUriFacebookInput">{{ 'COMPANY.formLabels.companyUriFacebook' | translate }}</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">url</span>
          </div>
          <input class="form-control" #companyUriFacebookInput id="companyUriFacebookInput" type="text" inputmode="text" [placeholder]="'COMPANY.formPlaceHolders.companyUriFacebook' | translate" formControlName="companyUriFacebook">
        </div>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'COMPANY.formHelp.companyUriFacebook' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- companyUserInstagram -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group">
        <label for="companyUserInstagram">{{ 'COMPANY.formLabels.companyUserInstagram' | translate }}</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">@</span>
          </div>
          <input class="form-control" #companyUserInstagram id="companyUserInstagram" type="text" inputmode="text" [placeholder]="'COMPANY.formPlaceHolders.companyUserInstagram' | translate" formControlName="companyUserInstagram">
        </div>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'COMPANY.formHelp.companyUserInstagram' | translate }}</small>
        </p>
      </div>
    </div>
  </div>
  <!-- companyUserTwitter / companyUriTiktok -->
  <div class="row" *ngIf="!(origin === 'otherCompany' || origin === 'otherCompanyLoan')">
    <!-- companyUserTwitter -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group">
        <label for="companyUserTwitter">{{ 'COMPANY.formLabels.companyUserTwitter' | translate }} <span class="required-lbl">*</span></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">@</span>
          </div>
          <input class="form-control" #companyUserTwitter id="companyUserTwitter" type="text" inputmode="text" [placeholder]="'COMPANY.formPlaceHolders.companyUserTwitter' | translate" formControlName="companyUserTwitter">
        </div>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'COMPANY.formHelp.companyUserTwitter' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- companyUriTiktok -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group">
        <label for="companyUriTiktok">{{ 'COMPANY.formLabels.companyUriTiktok' | translate }}</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">@</span>
          </div>
          <input class="form-control" #companyUriTiktok id="companyUriTiktok" type="text" inputmode="text" [placeholder]="'COMPANY.formPlaceHolders.companyUriTiktok' | translate" formControlName="companyUriTiktok">
        </div>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'COMPANY.formHelp.companyUriTiktok' | translate }}</small>
        </p>
      </div>
    </div>
  </div>
  <!-- companyUriYoutube / companyUriLinkedin -->
  <div class="row" *ngIf="!(origin === 'otherCompany' || origin === 'otherCompanyLoan')">
    <!-- companyUriYoutube -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group">
        <label for="companyUriYoutube">{{ 'COMPANY.formLabels.companyUriYoutube' | translate }}</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">url</span>
          </div>
          <input class="form-control" #companyUriYoutube id="companyUriYoutube" type="text" inputmode="text" [placeholder]="'COMPANY.formPlaceHolders.companyUriYoutube' | translate" formControlName="companyUriYoutube">
        </div>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'COMPANY.formHelp.companyUriYoutube' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- companyUriLinkedin -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="form-group">
        <label for="companyUriLinkedin">{{ 'COMPANY.formLabels.companyUriLinkedin' | translate }}</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">url</span>
          </div>
          <input class="form-control" #companyUriLinkedin id="companyUriLinkedin" type="text" inputmode="text" [placeholder]="'COMPANY.formPlaceHolders.companyUriLinkedin' | translate" formControlName="companyUriLinkedin">
        </div>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'COMPANY.formHelp.companyUriLinkedin' | translate }}</small>
        </p>
      </div>
    </div>
  </div>
  <!-- companyWeb -->
  <div class="row" *ngIf="!(origin === 'otherCompany' || origin === 'otherCompanyLoan')">
    <div class="col-12">
      <div class="form-group">
        <label for="companyWeb">{{ 'COMPANY.formLabels.companyWeb' | translate }}</label>
        <div class="input-group mb-3">
          <div class="input-group-prepend input-dropdown">
            <button class="btn btn-light btn-dropdown" type="button" (click)="toggleWebProtocol()">
              {{ mainFrm.controls.companyWebProtocol.value }}
              <div class="input-dropdown-caret"></div>
            </button>
            <div class="dropdown-menu" [ngClass]="{'d-block': showWebProtocol}">
              <a class="dropdown-item" [ngClass]="{'active': mainFrm.controls.companyWebProtocol.value === 'https://'}" (click)="selectWebProtocol('https://')"><span>https://</span></a>
              <a class="dropdown-item" [ngClass]="{'active': mainFrm.controls.companyWebProtocol.value === 'http://'}" (click)="selectWebProtocol('http://')"><span>http://</span></a>
            </div>
          </div>
          <input class="form-control" #companyWeb id="companyWeb" type="text" inputmode="text" [placeholder]="'COMPANY.formPlaceHolders.companyWeb' | translate" formControlName="companyWeb">
        </div>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'COMPANY.formHelp.companyWeb' | translate }}</small>
        </p>
      </div>
    </div>
  </div>
  <!-- status -->
  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="origin !== 'otherCompanyLoan' && isFinancial">
    <div class="form-group select-fix">
      <span class="caret-select"></span>
      <label for="statusSelect" class="col-form-label">{{ 'COMPANY.formLabels.companyStatus' | translate }} <span class="required-lbl">*</span></label>
      <select class="form-control" #statusSelect id="statusSelect" name="statusSelect" formControlName="companyStatus" [title]="'STATUSES.selectStatus' | translate">
        <option value="null" selected disabled>{{ 'STATUSES.selectStatus' | translate }}</option>
        <option *ngIf="(origin === 'otherCompany' || origin === 'otherCompanyLoan')"value="pendingForReview">{{ 'STATUSES.pendingReview' | translate }}</option>
        <option *ngIf="(origin === 'otherCompany' || origin === 'otherCompanyLoan')"value="active">{{ 'STATUSES.active' | translate }}</option>
        <option *ngIf="(origin === 'otherCompany' || origin === 'otherCompanyLoan')"value="unactive">{{ 'STATUSES.unactive' | translate }}</option>
        <option *ngIf="!(origin === 'otherCompany' || origin === 'otherCompanyLoan')"value="true">{{ 'STATUSES.active' | translate }}</option>
        <option *ngIf="!(origin === 'otherCompany' || origin === 'otherCompanyLoan')"value="false">{{ 'STATUSES.unactive' | translate }}</option>
      </select>
      <ng-container class="error-messages" *ngFor="let error of formErrorMessages.status">
        <small class="text-danger error-msg" *ngIf="mainFrm.get('status').hasError(error.type) && (mainFrm.get('status').dirty || mainFrm.get('status').touched)">
          {{ error.message }}.&nbsp;
        </small>
      </ng-container>
      <p class="help-messages" *ngIf="toggleHelp">
        <small>{{ 'USER.formHelp.status' | translate }}</small>
      </p>
    </div>
  </div>
  <!-- buttons -->
  <div class="row mt-4">
    <div class="col">
      <div class="request-form-btns-container">
        <button class="btn btn-light d-block btn-cancel" (click)="cancel()" type="button">
          <span *ngIf="origin === 'otherCompanyLoan'">{{ 'HELPERS.back' | translate }}</span>
          <span *ngIf="origin !== 'otherCompanyLoan'">{{ 'HELPERS.cancel' | translate }}</span>
        </button>
        <button class="btn btn-dark d-block" (click)="save()" type="button">
          <span *ngIf="origin === 'otherCompanyLoan'">{{ 'HELPERS.next' | translate }}</span>
          <span *ngIf="origin !== 'otherCompanyLoan'">{{ 'HELPERS.save' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</form>
<!-- image crop modal -->
<ng-template #imageCropModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Avatar</h4>
    <button type="button" class="close" aria-label="Close" (click)="imageCroppingClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <image-cropper 
      [imageChangedEvent]="imageChangedEvent" 
      [maintainAspectRatio]="true"
      [containWithinAspectRatio]="containWithinAspectRatio" 
      [aspectRatio]="editImageSelection === 'logo' ? 1 / 1 : 4 / 3" 
      [resizeToWidth]="320"
      [cropperMinWidth]="128" 
      [onlyScaleDown]="true" 
      [roundCropper]="editImageSelection === 'logo' ? true : false"
      [canvasRotation]="canvasRotation" 
      [transform]="transform" 
      [alignImage]="'center'"
      format="png" 
      (imageCropped)="imageCropped($event)"
      (loadImageFailed)="imageLoadImageFailed()">
    </image-cropper>
    <div class="btn-showcase">
      <button class="btn btn-xs btn-primary btn-icon-only" (click)="rotateLeft()"><app-feather-icons [icon]="'rotate-ccw'"></app-feather-icons></button>
      <button class="btn btn-xs btn-primary btn-icon-only" (click)="rotateRight()"><app-feather-icons [icon]="'rotate-cw'"></app-feather-icons></button>
      <!-- <button class="btn btn-xs btn-primary btn-icon-only" (click)="flipHorizontal()"><app-feather-icons [icon]="'more-horizontal'"></app-feather-icons></button>
      <button class="btn btn-xs btn-primary btn-icon-only" (click)="flipVertical()"><app-feather-icons [icon]="'more-vertical'"></app-feather-icons></button> -->
      <button class="btn btn-xs btn-primary btn-icon-only" (click)="zoomOut()"><app-feather-icons [icon]="'zoom-out'"></app-feather-icons></button>
      <button class="btn btn-xs btn-primary btn-icon-only" (click)="zoomIn()"><app-feather-icons [icon]="'zoom-in'"></app-feather-icons></button>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-light d-block" (click)="imageCroppingClose()" type="button">
      <span>{{ 'HELPERS.cancel' | translate }}</span>
    </button>
    <button class="btn btn-dark d-block" (click)="imageCroppingFinish()" type="button">
      <span>{{ 'HELPERS.save' | translate }}</span>
    </button>
  </div>
</ng-template>