// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 29/05/2023
// import
import { Component, EventEmitter, ViewChild, OnInit, OnChanges, Input, Output, ElementRef, SimpleChanges} from '@angular/core';
// translate
import { TranslateService } from '@ngx-translate/core';
// services
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
// interfaces
import { UserInterface } from 'src/app/shared/interfaces/user.interface';
import { CompanyInterface } from 'src/app/shared/interfaces/company.interface';
import { OtherCompanyInterface } from 'src/app/shared/interfaces/other-company.interface';
// component
@Component({
  selector: 'app-user-company-form',
  templateUrl: './user-company-form.component.html',
  styleUrls: ['./user-company-form.component.scss']
})
// class
export class UserCompanyFormComponent implements OnInit, OnChanges {
  // variables
  @Input() origin: string = null;
  @Input() userData: UserInterface = null;
  @Input() companyData: CompanyInterface = null;
  @Output() loaderEvent = new EventEmitter();
  @Output() saveEvent = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();
  savedData: boolean = false;
  company: CompanyInterface = null;
  companyId: string = null;
  // constructor
  constructor(
    public translate: TranslateService,
    public apiSv: ApiService,
    public filterSv: FilterService,
    public alertSv: AlertService,
  ) {
  }
  // life cycle
  async ngOnInit() {
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.companyData) {
      // check for companyData
      if (this.companyData !== null) {
        // update company data
        this.company = this.companyData;
        this.companyId = this.companyData.id;
        // set savedData
        this.savedData = true;
      }
    }
  }
  // loader
  async updateLoader(loaderMsg: any) {
    this.loaderEvent.emit(loaderMsg);
  }
  // navigation
  async cancel() {
    // emit data
    this.cancelEvent.emit(); // Return dat
  }
  // company
  async save(company: CompanyInterface) {
    // update company data
    this.company = company;
    this.companyId = company.id;
    // check if user is been saved before
    if (this.savedData) {
      // update userCompany
      await this.updateUserCompany();
    } else {
      // add userCompany
      await this.addUserCompany();
    }
  }
  async addUserCompany() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('USER.userCompanyLinkingShort'));
      // set companyUserData
      const companyUserData: any = {
        userId: this.userData.id,
        companyId: this.companyId
      }
      // link user with company
      await this.apiSv.linkCompanyUser(companyUserData);
      // set savedData
      this.savedData = true;
      // hide loader
      this.loaderEvent.emit(null);
      // emit data
      this.saveEvent.emit(this.company);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async updateUserCompany() {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // set companyUnlinkUserData
      const companyUnlinkUserData: any = {
        userId: this.userData.id,
        companyId: this.companyId
      }
      // unlink user with company
      await this.apiSv.unlinkCompanyUser(companyUnlinkUserData);
      // set companyLinkUserData
      const companyLinkUserData: any = {
        userId: this.userData.id,
        companyId: this.companyId
      }
      // link user with company
      await this.apiSv.linkCompanyUser(companyLinkUserData);
      // set savedData
      this.savedData = true;
      // hide loader
      this.loaderEvent.emit(null);
      // emit data
      this.saveEvent.emit(this.company);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
}
