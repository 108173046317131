// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 03/06/2023
// import
import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
// translate
import { TranslateService } from '@ngx-translate/core';
// services
import { AuthService } from 'src/app/shared/services/auth.service';
import { EncodingService } from 'src/app/shared/services/encoding.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
// interfaces
import { LoanInterface } from 'src/app/shared/interfaces/loan.interface';
import { UserInterface } from 'src/app/shared/interfaces/user.interface';
// component
@Component({
  selector: 'app-loan-list-table',
  templateUrl: './loan-list-table.component.html',
  styleUrls: ['./loan-list-table.component.scss']
})
// class
export class LoanListTableComponent implements OnInit, OnChanges {
  // variables
  @Input() loansData: any[] = [];
  @Input() isClient: string = null;
  @Input() isAdmin: string = null;
  @Input() isBoard: string = null;
  @Input() isBusinessAgent: string = null;
  @Input() isTreasure: string = null;
  @Input() isCompanyAdmin: string = null;
  @Input() isCompanyAuxiliar: string = null;
  @Output() loaderEvent = new EventEmitter();
  @Output() openLoanEvent = new EventEmitter();
  @Output() openUserEvent = new EventEmitter();
  // loan data
  loans: any[] = [];
  loanId: string = null;
  allSelected: boolean = false;
  // lang data
  currentLang: string = null;
  // constructor
  constructor(
    public translate: TranslateService,
    public authSv: AuthService,
    public encodingSv: EncodingService,
    public apiSv: ApiService,
    public filterSv: FilterService,
    public alertSv: AlertService,
  ) {
    // get currentLang
    const currentLang = this.translate.getDefaultLang();
    // set currentLang
    this.currentLang = currentLang;
  }
  // life cycle
  async ngOnInit() {}
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.loansData) {
      await this.setData();
    }
  }
  // loader
  async updateLoader(loaderMsg: any) {
    this.loaderEvent.emit(loaderMsg);
  }
  // data
  async setData() {
    // clear loans
    this.loans = [];
    // loop payments data
    this.loansData.map(async (loan: LoanInterface) => {
      this.loans.push(loan);
    });
  }
  // actions
  async openLoan(loan: LoanInterface) {
    // open loan
    this.openLoanEvent.emit(loan);
  }
  async openUser(user: UserInterface) {
    // open loan
    this.openUserEvent.emit(user);
  }
}
