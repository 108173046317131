<!-- footer start-->
<div class="container-fluid pt-1 pb-1">
  <div class="row">
    <div class="col-md-9 col-sm-12 footer-copyright">
      <p class="mb-0 footer-txt pull-left">© Copyright {{ today | date:'y' }}. All Rights Reserved. Designed by <a class="think-link dark" href="https://think-in.co/" target="_blank" rel="norel nofollow noopener noreferrer">Think In</a></p>
    </div>
    <div class="col-md-3 col-sm-12 footer-copyright ">
      <p class="mb-0 footer-version pull-right">{{ 'HELPERS.version' | translate }}&nbsp;{{ version }}</p>
    </div>
  </div>
</div>