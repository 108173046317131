<!-- header -->
<app-header-base></app-header-base>
<!-- content -->
<div class="page-wrapper page-wrapper-base">
  <router-outlet></router-outlet>
</div>
<!-- footer -->
<app-footer class="base-footer" [lineation]="'center'"></app-footer>
<!-- loader -->
<app-loader-inner></app-loader-inner>
<!-- whatsapp -->
<a class="whatsapp-btn" target="_blank" rel="noopener noreferrer" href="https://wa.me/+573234815179?text=Hola%20,%20necesito%20ayuda%20con%20la%20plataforma">
  <i class="fa fa-whatsapp" aria-hidden="true"></i>
</a>
