// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 25/05/2023
// tslint:disable: max-line-length
// tslint:disable: no-unused-expression
// import
import { Pipe, PipeTransform } from '@angular/core';
// translate
import { TranslateService } from '@ngx-translate/core';
// services
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
// pipe
@Pipe({
  name: 'timeElapsedPipe'
})
// class
export class TimeElapsedPipe implements PipeTransform {
  // constructor
  constructor(
    public translate: TranslateService,
    private utilitiesSv: UtilitiesService,
  ) { }
  // variables
  mvalue: string;
  transform(value: string): any {
    var timeElapsed =  this.utilitiesSv.timeElapsed(value);
    return timeElapsed;
  }

}
