<div class="file-top-inner">
  <ngx-file-drop [dropZoneLabel]="'HELPERS.uploadInstructions1' | translate" (onFileDrop)="droppedFile($event)">
    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
      <div class="upload-info" (click)="openFileSelector()">
        <app-feather-icons class="upload-icon init" [icon]="'upload-cloud'"></app-feather-icons>
        <span class="upload-txt">
          <span>{{ 'HELPERS.uploadInstructions1' | translate }}</span><br><br>
          <span>{{ 'HELPERS.uploadInstructions2' | translate }}</span>
        </span>
      </div>
    </ng-template>
  </ngx-file-drop>
</div>
<a class="btn btn-link upload-cancel" (click)="cancel()">{{ 'HELPERS.cancel' | translate }}</a>