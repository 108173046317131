// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 03/06/2023
// import
import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
// translate
import { TranslateService } from '@ngx-translate/core';
// services
import { AuthService } from 'src/app/shared/services/auth.service';
import { EncodingService } from 'src/app/shared/services/encoding.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
// interfaces
import { UserInterface } from 'src/app/shared/interfaces/user.interface';
import { UserFinancialInterface } from 'src/app/shared/interfaces/user-financial.interface';
// component
@Component({
  selector: 'app-user-financial-card',
  templateUrl: './user-financial-card.component.html',
  styleUrls: ['./user-financial-card.component.scss']
})
// class
export class UserFinancialCardComponent implements OnInit, OnChanges {
  // variables
  @Input() userData: UserInterface = null;
  @Input() financialData: UserFinancialInterface = null;
  @Input() userBankAccountsData: any = null;
  @Input() editEnable: boolean = false;
  @Input() fileEditEnable: boolean = false;
  @Input() showFamilyInfo: boolean = false;
  @Input() showBankInfo: boolean = false;
  @Output() loaderEvent = new EventEmitter();
  @Output() editEvent = new EventEmitter();
  @Output() updateEvent = new EventEmitter();
  @Output() fileActionEvent = new EventEmitter();
  // user data
  userId: string = null;
  // financial data
  id: string = null;
  totalIncome: number = 0;
  salary: number = 0;
  professionalFees: number = 0;
  otherIncome: number = 0;
  otherIncomeDescription: string = null;
  totalOutcome: number = 0;
  rent: number = 0;
  credits: number = 0;
  familyExpenses: number = 0;
  otherExpenses: number = 0;
  otherExpensesDescription: string = null;
  otherAssets: number = 0;
  otherAssetsDescription: string = null;
  otherLiabilities: number = 0;
  otherLiabilitiesDescription: string = null;
  dependents?: number | 0;
  housingType: string = null;
  originFunds: string = null;
  // user bank account data
  userBankAccounts: any[] = [];
  // constructor
  constructor(
    public translate: TranslateService,
    public authSv: AuthService,
    public encodingSv: EncodingService,
    public apiSv: ApiService,
    public filterSv: FilterService,
    public alertSv: AlertService,
  ) {
  }
  // life cycle
  async ngOnInit() {}
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.financialData) {
      await this.setData();
    }
    if (changes.userBankAccountsData) {
      await this.setBankAccountsData();
    }
  }
  // loader
  async updateLoader(loaderMsg: any) {
    this.loaderEvent.emit(loaderMsg);
  }
  // data
  async setData() {
    // check financialData
    if (this.financialData !== null) {
      this.id = this.financialData.id;
      this.userId = this.financialData.userId;
      this.dependents = this.financialData.dependents;
      this.housingType = this.financialData.housingType;
      this.totalIncome = this.financialData.totalIncome;
      this.salary = this.financialData.salary;
      this.professionalFees = this.financialData.professionalFees;
      this.otherIncome = this.financialData.otherIncome;
      this.totalOutcome = this.financialData.totalOutcome;
      this.rent = this.financialData.rent;
      this.credits = this.financialData.credits;
      this.familyExpenses = this.financialData.familyExpenses;
      this.otherExpenses = this.financialData.otherExpenses;
      this.otherIncomeDescription = this.financialData.otherIncomeDescription;
      this.otherExpensesDescription = this.financialData.otherExpensesDescription;
      this.otherAssetsDescription = this.financialData.otherAssetsDescription;
      this.otherLiabilitiesDescription = this.financialData.otherLiabilitiesDescription;
      this.otherAssets = this.financialData.otherAssets;
      this.otherLiabilities = this.financialData.otherLiabilities;
      this.originFunds = this.financialData.originFunds === 'NULL' ? null : this.financialData.originFunds;
    }
  }
  async setBankAccountsData() {
    // set userBankAccounts
    this.userBankAccounts = this.userBankAccountsData;
  }
  async edit() {
    this.editEvent.emit(this.financialData);
  }
}
