// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 03/06/2023
// import
import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
// translate
import { TranslateService } from '@ngx-translate/core';
// plugins
import Swal from 'sweetalert2';
// services
import { BreadcrumbsService } from 'src/app/shared/services/breadcrumbs.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
// interfaces
import { CompanyInterface } from 'src/app/shared/interfaces/company.interface';
// component
@Component({
  selector: 'app-companies-card',
  templateUrl: './companies-card.component.html',
  styleUrls: ['./companies-card.component.scss']
})
// class
export class CompaniesCardComponent implements OnInit, OnChanges {
  // variables
  @Input() companiesData: any = null;
  @Input() origin: string = null;
  @Input() deleteEnable: boolean = false;
  @Output() loaderEvent = new EventEmitter();
  @Output() openEvent = new EventEmitter();
  @Output() editEvent = new EventEmitter();
  @Output() deleteEvent = new EventEmitter();
  @Output() unlinkEvent = new EventEmitter();
  // companies data
  companies: any[] = [];
  // constructor
  constructor(
    public router: Router,
    public translate: TranslateService,
    public breadcrumbSv: BreadcrumbsService,
    public apiSv: ApiService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    private utilitiesSv: UtilitiesService,
  ) {
  }
  // life cycle
  async ngOnInit() {
    await this.setCompanies();
  }
  async ngOnChanges(changes: SimpleChanges) {
    try {
      if (changes.companiesData) {
        await this.setCompanies();
      }
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  // loader
  async updateLoader(loaderMsg: any) {
    this.utilitiesSv.updateLoader(loaderMsg);
  }
  // data
  async setCompanies() {
    if (this.companiesData !== null) {
      // update companies
      this.companies = this.companiesData;
    }
  }
  openCompany(company: CompanyInterface) {
    // emit data
    this.openEvent.emit(company);
  }
  deleteCompany(company: CompanyInterface) {
    // show alert
    Swal.fire({
      icon: 'error',
      title: this.translate.instant('COMPANY.companyDelete'),
      text: this.translate.instant('COMPANY.companyDeleteAsk'),
      confirmButtonText: this.translate.instant('HELPERS.yes'),
      showCancelButton: true,
      cancelButtonText: this.translate.instant('HELPERS.no'),
      allowOutsideClick: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        // emit data
        this.deleteEvent.emit(company);
      }
    });
  }
  unlinkCompany(company: CompanyInterface) {
    // emit data
    this.unlinkEvent.emit(company);
  }
}
