<!-- table -->
<div class="table-responsive table-payments" [ngClass]="{'select-payments': dataType === 'select-payment'}">
  <table class="table table-hover table-payment-body" [ngClass]="dataType + '-tbl'">
    <thead>
      <tr>
        <th *ngIf="dataType === 'select-payment'" class="col-select align-middle" scope="col">
          <label class="d-block" for="checkAll">
            <input class="checkbox_animated" id="checkAll" type="checkbox" [(ngModel)]="allSelected" (change)="selectAll()">
          </label>
        </th>
        <th class="col-number align-middle" scope="col">
          <p class="table-header-title text-center">#</p>
        </th>
        <th *ngIf="dataType !== 'select-payment'" class="col-start align-middle" scope="col">
          <p class="table-header-title text-center">{{ 'LOAN.loanPaymentStart' | translate }}</p>
        </th>
        <th class="col-amount align-middle" scope="col">
          <p class="table-header-title text-center">{{ 'LOAN.loanPaymentAmount' | translate }}</p>
        </th>
        <th *ngIf="dataType !== 'select-payment'" class="col-interest align-middle" scope="col">
          <p class="table-header-title text-center">{{ 'LOAN.loanPaymentInterest' | translate }}</p>
        </th>
        <th *ngIf="dataType !== 'select-payment'" class="col-debt align-middle" scope="col">
          <p class="table-header-title text-center">{{ 'LOAN.loanPaymentDebt' | translate }}</p>
        </th>
        <th *ngIf="dataType !== 'select-payment'" class="col-end align-middle" scope="col">
          <p class="table-header-title text-center">{{ 'LOAN.loanPaymentEnd' | translate }}</p>
        </th>
        <th *ngIf="dataType === 'full' || dataType === 'payment'" class="col-date align-middle" scope="col">
          <p class="table-header-title text-center">{{ 'LOAN.loanPaymentDate' | translate }}</p>
        </th>
        <th *ngIf="dataType === 'full' || dataType === 'payment'" class="col-status align-middle" scope="col">
          <p class="table-header-title text-center">{{ 'STATUSES.status' | translate }}</p>
        </th>
        <th *ngIf="dataType === 'payment'" class="col-button align-middle" scope="col">
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let loanPayment of loanPayments">
        <td *ngIf="dataType === 'select-payment'" class="col-select align-middle text-center">
          <label class="d-block" [for]="loanPayment.id + '-select-check'">
            <input class="checkbox_animated" [id]="loanPayment.id + '-select-check'" type="checkbox" [(ngModel)]="loanPayment.selected" (change)="selectItem()">
          </label>
        </td>
        <td class="col-number align-middle" scope="col">
          <p class="text-center"><small>{{ loanPayment.loanPaymentNumber }}</small></p>
        </td>   
        <td *ngIf="dataType !== 'select-payment'" class="col-start align-middle" scope="col">
          <p class="text-center"><small>{{ loanPayment.loanPaymentStart | currency:'$' : 'symbol' : '1.0-0' }}</small></p>
        </td>
        <td class="col-amount align-middle" scope="col">
          <p class="text-center"><small>{{ loanPayment.loanPaymentAmount | currency:'$' : 'symbol' : '1.0-0' }}</small></p>
        </td>
        <td *ngIf="dataType !== 'select-payment'" class="col-interest align-middle" scope="col">
          <p class="text-center"><small>{{ loanPayment.loanPaymentInterest | currency:'$' : 'symbol' : '1.0-0' }}</small></p>
        </td>
        <td *ngIf="dataType !== 'select-payment'" class="col-debt align-middle" scope="col">
          <p class="text-center"><small>{{ loanPayment.loanPaymentDebt | currency:'$' : 'symbol' : '1.0-0' }}</small></p>
        </td>
        <td *ngIf="dataType !== 'select-payment'" class="col-end align-middle" scope="col">
          <p class="text-center"><small>{{ loanPayment.loanPaymentEnd | currency:'$' : 'symbol' : '1.0-0' }}</small></p>
        </td>
        <td *ngIf="dataType === 'full' || dataType === 'payment'" class="col-date align-middle" scope="col">
          <p class="text-center" *ngIf="loanPayment.loanPaymentDueDate === null"><small>----</small></p>
          <p class="text-center" *ngIf="loanPayment.loanPaymentDueDate !== null"><small>{{ loanPayment.loanPaymentDueDate | momentPipe: 'date': currentLang: 'DD MMM YYYY' }}</small></p>
        </td>
        <td *ngIf="dataType === 'full' || dataType === 'payment'" class="col-status align-middle" scope="col">
          <p class="table-status-txt text-center">
            <small>
              <span class="status-started" *ngIf="loanPayment.loanPaymentStatusLast === 'pending'">{{ 'STATUSES.pending' | translate }}</span>
              <span class="status-sended" *ngIf="loanPayment.loanPaymentStatusLast === 'payed'">{{ 'STATUSES.payedFemale' | translate }}</span>
              <span class="status-validated" *ngIf="loanPayment.loanPaymentStatusLast === 'recalculated'">{{ 'STATUSES.recalculated' | translate }}</span>
              <span class="status-accepted" *ngIf="loanPayment.loanPaymentStatusLast === 'overdued'">{{ 'STATUSES.overduedFemale' | translate }}</span>
            </small>
          </p>
          <p class="table-status-date text-center" *ngIf="loanPayment.loanPaymentStatusLast === 'overdued'">
            <small>
              {{ 'LOAN.loanPaymentOverduedDays' | translate }}:&nbsp;{{ loanPayment.loanPaymentOverduedDays }}
              <span *ngIf="loanPayment.loanPaymentOverduedDays === 1">{{ 'DATEDATA.days.single' | translate }}</span>
              <span *ngIf="loanPayment.loanPaymentOverduedDays > 1">{{ 'DATEDATA.days.multiple' | translate }}</span>
            </small>
          </p>
          <p class="table-status-date text-center" *ngIf="loanPayment.loanPaymentStatusLast !== 'pending'">
            <small>{{ loanPayment.loanPaymentStatusDateLast | momentPipe: 'date': currentLang: 'DD MMM YYYY' }}</small>
          </p>
        </td>
        <td *ngIf="dataType === 'payment'" class="col-button align-middle">
          <div class="file-controls inline-controls">
            <a class="btn btn-icon-only btn-zoom btn-file-control" *ngIf="loanPayment.loanPaymentStatusLast === 'payed' && loanPayment.loanPaymentReceipt" (click)="openDocument(paymentDocumentModal, loanPayment)">
              <app-feather-icons [icon]="'zoom-in'"></app-feather-icons>
            </a>
            <a class="btn btn-icon-only btn-add btn-file-control" *ngIf="loanPayment.loanPaymentStatusLast === 'pending' && enablePayments" (click)="initPayment(loanPayment)">
              <app-feather-icons [icon]="'credit-card'"></app-feather-icons>
            </a>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>