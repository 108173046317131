<!-- form -->
<form class="theme-form" [formGroup]="mainFrm">
  <!-- header -->
  <div class="row mt-4">
    <!-- title -->
    <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
      <h4>{{ 'CONFIG.formLabels.termsConditions' | translate }}</h4>
    </div>
    <!-- help -->
    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 pull-right">
      <div class="btn-with-outside-txt-lbl help-btn" (click)="help()">
        <small class="btn-inner-txt">{{ 'HELPERS.help' | translate }}</small> 
        <a class="btn btn-light btn-xs btn-icon-only btn-icon-text-left">
          <app-feather-icons [icon]="'help-circle'"></app-feather-icons>
        </a>
      </div>
    </div>
  </div>
  <!-- termsConditions -->
  <div class="row mt-4">
    <div class="col-12">
      <div class="form-group">
        <angular-editor id="termsConditionsInput" #termsConditionsInput name="termsConditionsInput" formControlName="termsConditions" [config]="editorConfig"></angular-editor>
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.termsConditions">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('termsConditions').hasError(error.type) && (mainFrm.get('termsConditions').dirty || mainFrm.get('termsConditions').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'CONFIG.formHelp.termsConditions' | translate }}</small>
        </p>
      </div>
    </div>
  </div>
  <!-- buttons -->
  <div class="row mt-4">
    <div class="col">
      <div class="request-form-btns-container">
        <button class="btn btn-light d-block btn-cancel" (click)="cancel()" type="button" *ngIf="false">
          <span>{{ 'HELPERS.cancel' | translate }}</span>
        </button>
        <button class="btn btn-dark d-block" (click)="save()" type="button">
          <span>{{ 'HELPERS.save' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</form>