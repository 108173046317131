// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 03/10/2023
// import
import { Component, Input } from "@angular/core";
// translate
import { TranslateService } from '@ngx-translate/core';
// services
import { ApiService } from 'src/app/shared/services/api.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
// interfaces
import { CompanyInterface } from "src/app/shared/interfaces/company.interface";
@Component({
  selector: 'app-loan-work-activities',
  templateUrl: './loan-work-activities.component.html',
  styleUrls: ['./loan-work-activities.component.scss']
})
export class LoanWorkActivitiesComponent {
  // variables
  @Input() companyData: CompanyInterface = null;
  // loader
  showLoader: boolean = false;
  loaderMsg: string = null;
  // time query
  public showTimeQuery: boolean = false;
  timeQueryOptions: any[] = ['today', 'week', 'month', 'trimester', 'semester', 'year']
  selectedTimeQuery: string = 'week';
  selectedDateStart: string = null;
  selectedDateEnd: string = null;
  selectedTimeStart: string = null;
  selectedTimeEnd: string = null;
  // loans data
  workActivitiesData: any = null;
  loanTotalsData: any = null;
  dataLoaded: boolean = false;
  // constructor
  constructor(
    public translate: TranslateService,
    public apiSv: ApiService,
    private utilitiesSv: UtilitiesService,
  ) {}
  // life cycle
  async ngOnInit() {
    await this.setTimeQuery('week');
  }
  // actions
  toggleTimeQuery() {
    this.showTimeQuery = !this.showTimeQuery;
  }
  async setTimeQuery(timeQueryOption: string) {
    // update selectedTimeQuery
    this.selectedTimeQuery = timeQueryOption;
    // get selected time
    const selectedTime = this.utilitiesSv.getTimeQuery(timeQueryOption);
    // update times
    this.selectedDateStart = selectedTime.dateStart;
    this.selectedDateEnd = selectedTime.dateEnd;
    this.selectedTimeStart = selectedTime.timeStart;
    this.selectedTimeEnd = selectedTime.timeEnd;
    await this.getLoanWorkActivitiesData();
    // check timeQueryOption
    if (timeQueryOption !== this.selectedTimeQuery) {
      // toggle time query
      this.toggleTimeQuery();
    }
  }
  // data
  async getLoanWorkActivitiesData() {
    return new Promise(async (resolve, reject) => {
      try {
        // show loader
        this.showLoader = true;
        this.loaderMsg = this.translate.instant('HELPERS.loadingTxt') + ' ' + this.translate.instant('REPORT.loanWorkActivities');
        // get loanWorkActivityData
        const loanWorkActivityData = {
          companyId: this.companyData !== null ? this.companyData.id : null,
          timeQueryOption: this.selectedTimeQuery,
          selectedDateStart: this.selectedDateStart,
          selectedDateEnd: this.selectedDateEnd,
          selectedTimeStart: this.selectedTimeStart,
          selectedTimeEnd: this.selectedTimeEnd,
        }
        // get data from api
        await this.apiSv.getLoanWorkActivities(loanWorkActivityData).then(async (loanWorkActivitiesResponse: any)=>{

          console.log('loanWorkActivitiesResponse', loanWorkActivitiesResponse);

          // check data
          if (loanWorkActivitiesResponse.data) {
            // get loan work activities data
            const loanWorkActivitiesData = loanWorkActivitiesResponse.data.workActivities;
            const loanWorkActivitiesTotals = loanWorkActivitiesResponse.data.totals;
            const loanWorkActivityLabels: any[] = [];
            const loanWorkActivitySeries: any[] = [];
            const loanWorkActivityColors: any[] = this.utilitiesSv.getColorsByRepetitions(loanWorkActivitiesData.length);
            // loop loanWorkActivities
            for (let index = 0; index < loanWorkActivitiesData.length; index++) {
              // get loanWorkActivity
              const loanWorkActivity: any = loanWorkActivitiesData[index];
              // init workActivityName
              let workActivityName: string = null;
              // switch workActivity
              switch (loanWorkActivity.workActivity) {
                case 'business':
                  workActivityName = this.translate.instant('USERWORKS.formLabels.workTypeBusiness');
                  break;
                case 'employee':
                  workActivityName = this.translate.instant('USERWORKS.formLabels.workTypeEmployee');
                  break;
                case 'entrepreneur':
                  workActivityName = this.translate.instant('USERWORKS.formLabels.workTypeEntrepreneur');
                  break;
                case 'home':
                  workActivityName = this.translate.instant('USERWORKS.formLabels.workTypeHome');
                  break;
                case 'student':
                  workActivityName = this.translate.instant('USERWORKS.formLabels.workTypeStudent');
                  break;
                case 'pensioner':
                  workActivityName = this.translate.instant('USERWORKS.formLabels.workTypePensioner');
                  break;
              }
              // push loan work activity label
              loanWorkActivityLabels.push(workActivityName);
              // push loan work activity label
              loanWorkActivitySeries.push(loanWorkActivity.workActivityAmount);
            };
            // get workActivities
            const workActivities: any = {
              labels: loanWorkActivityLabels,
              series: loanWorkActivitySeries,
              chart: {
                type: "donut",
                height: 300,
              },
              dataLabels: {
                enabled: false,
              },
              legend: {
                position: "bottom",
                fontSize: "14px",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: 500,
                labels: {
                  colors: loanWorkActivityColors,
                },
                markers: {
                  width: 6,
                  height: 6,
                },
                itemMargin: {
                  horizontal: 7,
                  vertical: 0,
                },
              },
              stroke: {
                width: 10,
                colors: loanWorkActivityColors,
              },
              plotOptions: {
                pie: {
                  expandOnClick: false,
                  donut: {
                    size: "83%",
                    labels: {
                      show: true,
                      name: {
                        offsetY: 4,
                      },
                      total: {
                        show: true,
                        fontSize: "20px",
                        fontFamily: "Montserrat, sans-serif",
                        fontWeight: 500,
                        label: '$' + this.utilitiesSv.convertStringToCurrency(loanWorkActivitiesTotals.toString()),
                        formatter: () => this.translate.instant('REPORT.totalApproved'),
                      },
                    },
                  },
                },
              },
              states: {
                normal: {
                  filter: {
                    type: "none",
                  },
                },
                hover: {
                  filter: {
                    type: "none",
                  },
                },
                active: {
                  allowMultipleDataPointsSelection: false,
                  filter: {
                    type: "none",
                  },
                },
              },
              colors:loanWorkActivityColors,
              responsive: [
                {
                  breakpoint: 1630,
                  options: {
                    chart: {
                      height: 360,
                    },
                  },
                },
                {
                  breakpoint: 1584,
                  options: {
                    chart: {
                      height: 400,
                    },
                  },
                },
                {
                  breakpoint: 1473,
                  options: {
                    chart: {
                      height: 250,
                    },
                  },
                },
                {
                  breakpoint: 1425,
                  options: {
                    chart: {
                      height: 270,
                    },
                  },
                },
                {
                  breakpoint: 1400,
                  options: {
                    chart: {
                      height: 320,
                    },
                  },
                },
                {
                  breakpoint: 480,
                  options: {
                    chart: {
                      height: 250,
                    },
                  },
                },
              ],
            };
            // update workActivities
            this.workActivitiesData = workActivities;
            // update dataLoaded
            this.dataLoaded = true;
          }
          // resolve
          resolve(true);
        }, error=>{
          console.log('error', error);
          reject(error);
        });
      } catch (error) {
        console.log('error', error);
        // hide loader
        this.showLoader = false;
        this.loaderMsg = null;
        reject(error);
      }
    });
  }
}
