<!-- table -->
<div class="table-responsive">
  <table class="table table-hover">
    <!-- table header -->
    <thead>
      <tr>
        <th class="col align-middle" scope="col"><p class="table-header-title">{{ 'COMPANY.company' | translate }}</p></th>
        <th class="col align-middle" scope="col"><p class="table-header-title">{{ 'COMPANY.formLabels.companyIdentification' | translate }}</p></th>
        <th class="col align-middle" scope="col"><p class="table-header-title">{{ 'COMPANY.formLabels.companyEmailShort' | translate }}</p></th>
        <th class="col align-middle" scope="col"><p class="table-header-title">{{ 'COMPANY.formLabels.companyPhone' | translate }}</p></th>
        <th class="col align-middle" scope="col"><p class="table-header-title">{{ 'COMPANY.formLabels.companyCellphoneShort' | translate }}</p></th>
        <th *ngIf="origin !== 'product'" class="col align-middle text-center" scope="col"><p class="table-header-title">{{ 'COMPANY.formLabels.companyStatus' | translate }}</p></th>
        <th *ngIf="origin === 'product'" class="col align-middle text-center" scope="col"></th>
        <th class="col-1 align-middle" scope="col" *ngIf="deleteEnable"></th>
      </tr>
    </thead>
    <!-- table body -->
    <tbody>
      <tr *ngFor="let company of companies">
        <td class="col align-middle table-user" scope="col">
          <div class="media" *ngIf="origin !== 'otherCompanies'">
            <div class="media-left">
              <img class="media-object rounded-circle img-60" [src]="company.companyLogo" [alt]="company.companyName" onError="src = 'assets/images/broken-image-slim.png'">
            </div>
            <div class="media-body media-body-table-item m-l-20">
              <div class="media-body-container">
                <h6 class="media-heading company-name btn-link" (click)="openCompany(company)">{{ company.companyName }}</h6>
              </div>
            </div>
          </div>
          <p class="item-number" *ngIf="origin === 'otherCompanies'" (click)="openCompany(company)">{{ company.companyName }}</p>
        </td>
        <th class="col align-middle" scope="col">
          <p *ngIf="!company.companyIdentification">----</p>
          <p *ngIf="company.companyIdentification">{{ company.companyIdentification }}</p>
        </th>
        <th class="col align-middle" scope="col">
          <p *ngIf="!company.email">----</p>
          <a *ngIf="company.email" [href]="'mailto:' + company.email" target="_blank" rel="noopener noreferrer">{{ company.email }}</a>
        </th>
        <th class="col align-middle" scope="col">
          <p *ngIf="!company.companyPhone">----</p>
          <a *ngIf="company.companyPhone" [href]="'tel:+' + company.companyPhoneCountryCodeCod + company.companyPhone" target="_blank" rel="noopener noreferrer">+{{ company.companyPhoneCountryCodeCod }}&nbsp;{{ company.companyPhone | phonePipe }}</a>
        </th>
        <th class="col align-middle" scope="col">
          <p *ngIf="!company.companyCellphone">----</p>
          <a *ngIf="company.companyCellphone" [href]="'tel:+' + company.companyCellphoneCountryCodeCod + company.companyCellphone" target="_blank" rel="noopener noreferrer">+{{ company.companyCellphoneCountryCodeCod }}&nbsp;{{ company.companyCellphone | phonePipe }}</a>
        </th>
        <td class="col align-middle text-center">
          <p *ngIf="origin !== 'product'" class="table-status-txt">
            <span class="status-sended" *ngIf="company.companyStatus === 'pendingForReview'">{{ 'STATUSES.pendingReview' | translate }}</span>
            <span class="status-approved" *ngIf="company.companyStatus === 'active'">{{ 'STATUSES.active' | translate }}</span>
            <span class="status-rejected" *ngIf="company.companyStatus === 'unactive'">{{ 'STATUSES.unactive' | translate }}</span>
            <span class="status-rejected" *ngIf="company.companyStatus === 'inactive'">{{ 'STATUSES.unactive' | translate }}</span>
            <span class="status-approved" *ngIf="company.companyStatus === true">{{ 'STATUSES.active' | translate }}</span>
            <span class="status-rejected" *ngIf="company.companyStatus === false">{{ 'STATUSES.unactive' | translate }}</span>
          </p>
          <a *ngIf="origin === 'product'" class="btn-link status-txt-rejected" (click)="unlinkCompany(company)">
            <app-feather-icons [icon]="'link-2'"></app-feather-icons>
          </a>
        </td>
        <td *ngIf="deleteEnable" class="col-button align-middle">
          <a class="btn btn-danger btn-xs btn-icon-only" (click)="deleteCompany(company)">
            <app-feather-icons [icon]="'trash'"></app-feather-icons>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>