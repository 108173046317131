<!-- header -->
<div class="row">
  <div class="col-12">
    <h4>{{ 'FORGOT.title' | translate }}</h4>
    <p>{{ 'FORGOT.instructions1' | translate }}&nbsp;<strong>{{ 'FORGOT.instructions2' | translate }}</strong>&nbsp;{{ 'FORGOT.instructions3' | translate }}</p>
  </div>
</div>
<form #emailFormEl id="emailFormEl" [formGroup]="emailForm">
  <!-- email -->
  <div class="row">
    <div class="col">
      <input class="form-control text-center opt-text" type="text" inputmode="text" [placeholder]="'USER.formPlaceHolders.email' | translate" #email id="email" formControlName="email">
    </div>
  </div>
</form>
<!-- buttons -->
<div class="row">
  <div class="col mt-4 text-center">
    <button type="button" (click)="sendCode()" class="btn btn-primary btn-block">{{ 'FORGOT.sendCode' | translate }}</button>
  </div>
</div>
