<!-- form -->
<form class="theme-form" [formGroup]="mainFrm">
  <!-- header -->
  <div class="row mt-4">
    <!-- title -->
    <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
      <h4>{{ 'OCCUPATION.occupation' | translate }}</h4>
      <p>{{ 'OCCUPATION.occupationInfo' | translate }}</p>
    </div>
    <!-- help -->
    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 pull-right">
      <div class="btn-with-outside-txt-lbl help-btn" (click)="help()">
        <small class="btn-inner-txt">{{ 'HELPERS.help' | translate }}</small> 
        <a class="btn btn-light btn-xs btn-icon-only btn-icon-text-left">
          <app-feather-icons [icon]="'help-circle'"></app-feather-icons>
        </a>
      </div>
    </div>
  </div>
  <!-- occupationName / occupationStatus -->
  <div class="row">
    <!-- occupationName -->
    <div class="col-6">
      <div class="form-group">
        <label for="occupationName">{{ 'OCCUPATION.formLabels.occupationName' | translate }} <span class="required-lbl">*</span></label>
        <input class="form-control" #occupationName id="occupationName" type="text" inputmode="text" [placeholder]="'OCCUPATION.formPlaceHolders.occupationName' | translate" formControlName="occupationName">
        <ng-container class="error-messages" *ngFor="let error of formErrorMessages.occupationName">
          <small class="text-danger error-msg" *ngIf="mainFrm.get('occupationName').hasError(error.type) && (mainFrm.get('occupationName').dirty || mainFrm.get('occupationName').touched)">
            {{ error.message }}.&nbsp;
          </small>
        </ng-container>
        <p class="help-messages" *ngIf="toggleHelp">
          <small>{{ 'OCCUPATION.formHelp.occupationName' | translate }}</small>
        </p>
      </div>
    </div>
    <!-- occupationStatus -->
    <div class="form-group select-fix col-12">
      <span class="caret-select"></span>
      <label for="occupationStatusSelect" class="col-form-label">{{ 'STATUSES.status' | translate }} <span class="required-lbl">*</span></label>
      <select class="form-control" #occupationStatusSelect id="occupationStatusSelect" name="occupationStatusSelect" formControlName="occupationStatus" [title]="'STATUSES.status' | translate">
        <option value="null" selected disabled>{{ 'HELPERS.selectAnOption' | translate }}</option>
        <option value="pendingForReview">{{ 'STATUSES.pendingReview' | translate }}</option>
        <option value="active">{{ 'STATUSES.active' | translate }}</option>
        <option value="unactive">{{ 'STATUSES.unactive' | translate }}</option>
      </select>
      <ng-container class="error-messages" *ngFor="let error of formErrorMessages.occupationStatus">
        <small class="text-danger error-msg" *ngIf="mainFrm.get('occupationStatus').hasError(error.type) && (mainFrm.get('occupationStatus').dirty || mainFrm.get('occupationStatus').touched)">
          {{ error.message }}.&nbsp;
        </small>
      </ng-container>
      <p class="help-messages" *ngIf="toggleHelp">
        <small>{{ 'PROFESSION.formHelp.occupationStatus' | translate }}</small>
      </p>
    </div>
  </div>
  <!-- buttons -->
  <div class="row mt-4">
    <div class="col">
      <div class="request-form-btns-container">
        <button class="btn btn-light d-block btn-cancel" (click)="cancel()" type="button">
          <span>{{ 'HELPERS.cancel' | translate }}</span>
        </button>
        <button class="btn btn-dark d-block" (click)="save()" type="button">
          <span>{{ 'HELPERS.save' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</form>