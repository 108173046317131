// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 03/06/2023
// import
import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges  } from '@angular/core';
// translate
import { TranslateService } from '@ngx-translate/core';
// plugins
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// services
import { ApiService } from 'src/app/shared/services/api.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
import { UserBankAccountInterface } from 'src/app/shared/interfaces/user-bank-account.interface';
// component
@Component({
  selector: 'app-loan-documents',
  templateUrl: './loan-documents.component.html',
  styleUrls: ['./loan-documents.component.scss']
})
// class
export class LoanDocumentsComponent implements OnInit, OnChanges {
  // inputs
  @Input() userId: any = null;
  @Input() loanId: any = null;
  @Input() batchId: any = null;
  @Input() loanPaymentId: any = null;
  @Input() target: any = null;
  @Input() editEnable: boolean = false;
  @Input() viewEnable: boolean = true;
  @Input() alignItems: string = 'left';
  @Input() idFrontFileData: any = null;
  @Input() idFrontFileShow: boolean = false;
  @Input() idFrontFileEdit: boolean = false;
  @Input() idBackFileData: any = null;
  @Input() idBackFileShow: boolean = false;
  @Input() idBackFileEdit: boolean = false;
  @Input() requestFileData: any = null;
  @Input() requestFileShow: boolean = false;
  @Input() requestFileEdit: boolean = false;
  @Input() bankFileData: any = null;
  @Input() bankFileShow: boolean = false;
  @Input() bankFileEdit: boolean = false;
  @Input() laborFileData: any = null;
  @Input() laborFileShow: boolean = false;
  @Input() laborFileEdit: boolean = false;
  @Input() promissoryFileData: any = null;
  @Input() promissoryFileShow: boolean = false;
  @Input() promissoryFileEdit: boolean = false;
  @Input() instructionsFileData: any = null;
  @Input() instructionsFileShow: boolean = false;
  @Input() instructionsFileEdit: boolean = false;
  @Input() authorizationFileData: any = null;
  @Input() authorizationFileShow: boolean = false;
  @Input() authorizationFileEdit: boolean = false;
  @Input() disbursementFileData: any = null;
  @Input() disbursementFileShow: boolean = false;
  @Input() disbursementFileEdit: boolean = false;
  @Input() loanPaymentFileData: any = null;
  @Input() loanPaymentFileShow: boolean = false;
  @Input() loanPaymentFileEdit: boolean = false;
  @Input() batchPaymentReceiptFileData: any = null;
  @Input() batchPaymentReceiptFileShow: boolean = false;
  @Input() batchPaymentReceiptFileEdit: boolean = false;
  @Input() acceptanceFileData: any = null;
  @Input() acceptanceFileShow: boolean = false;
  @Input() acceptanceFileEdit: boolean = false;
  @Input() companyPromissoryFileData: any = null;
  @Input() companyPromissoryFileShow: boolean = false;
  @Input() companyPromissoryFileEdit: boolean = false;
  @Input() profileFileData: any = null;
  @Input() profileFileShow: boolean = false;
  @Input() profileFileEdit: boolean = false;
  @Input() synthesisFileData: any = null;
  @Input() synthesisFileShow: boolean = false;
  @Input() synthesisFileEdit: boolean = false;
  @Input() detailedFileData: any = null;
  @Input() detailedFileShow: boolean = false;
  @Input() detailedFileEdit: boolean = false;
  @Input() paymentReceiptFiles: any[] = [];
  @Input() paymentReceiptShow: boolean = false;
  @Input() paymentReceiptEdit: boolean = false;
  @Input() rutFileData: any = null;
  @Input() rutFileShow: boolean = false;
  @Input() rutFileEdit: boolean = false;
  @Input() ccioFileData: any = null;
  @Input() ccioFileShow: boolean = false;
  @Input() ccioFileEdit: boolean = false;
  @Input() repIdFileData: any = null;
  @Input() repIdFileShow: boolean = false;
  @Input() repIdFileEdit: boolean = false;
  @Input() pgFileData: any = null;
  @Input() pgFileShow: boolean = false;
  @Input() pgFileEdit: boolean = false;
  @Input() bankExtractsFileData: any = null;
  @Input() bankExtractsFileShow: boolean = false;
  @Input() bankExtractsFileEdit: boolean = false;
  @Input() pensionerFileData: any = null;
  @Input() pensionerFileShow: boolean = false;
  @Input() pensionerFileEdit: boolean = false;
  @Input() utilitiesFileData: any = null;
  @Input() utilitiesFileShow: boolean = false;
  @Input() utilitiesFileEdit: boolean = false;
  @Input() otherDocument1FileData: any = null;
  @Input() otherDocument1FileShow: boolean = false;
  @Input() otherDocument1FileEdit: boolean = false;
  @Input() otherDocument2FileData: any = null;
  @Input() otherDocument2FileShow: boolean = false;
  @Input() otherDocument2FileEdit: boolean = false;
  @Input() otherDocument3FileData: any = null;
  @Input() otherDocument3FileShow: boolean = false;
  @Input() otherDocument3FileEdit: boolean = false;
  // outputs
  @Output() loaderEvent = new EventEmitter();
  @Output() actionEvent = new EventEmitter();
  // local file data
  idFrontLocalFileData: any = null;
  idBackLocalFileData: any = null;
  requestLocalFileData: any = null;
  bankLocalFileData: any = null;
  laborLocalFileData: any = null;
  promissoryLocalFileData: any = null;
  instructionsLocalFileData: any = null;
  authorizationLocalFileData: any = null;
  disbursementLocalFileData: any = null;
  loanPaymentLocalFileData: any = null;
  batchPaymentReceiptLocalFileData: any = null;
  acceptanceLocalFileData: any = null;
  companyPromissoryLocalFileData: any = null;
  profileLocalFileData: any = null;
  synthesisLocalFileData: any = null;
  detailedLocalFileData: any = null;
  paymentReceiptLocalFiles: any[] = [];
  rutLocalFileData: any = null; 
  ccioLocalFileData: any = null; 
  repIdLocalFileData: any = null; 
  pgLocalFileData: any = null; 
  bankExtractsLocalFileData: any = null; 
  pensionerLocalFileData: any = null; 
  utilitiesLocalFileData: any = null;
  otherDocument1LocalFileData: any = null;
  otherDocument2LocalFileData: any = null;
  otherDocument3LocalFileData: any = null;
  // file data
  fileData: any = null;
  fileTarget: string = null;
  fileAction: string = null;
  fileModalTitle: string = null;
  fileModalImage: string = null;
  // dropzone data
  idFrontShowDropZone: boolean = false;
  idBackShowDropZone: boolean = false;
  requestShowDropZone: boolean = false;
  bankShowDropZone: boolean = false;
  laborShowDropZone: boolean = false;
  promissoryShowDropZone: boolean = false;
  instructionsShowDropZone: boolean = false;
  authorizationShowDropZone: boolean = false;
  disbursementShowDropZone: boolean = false;
  loanPaymentShowDropZone: boolean = false;
  batchPaymentReceiptShowDropZone: boolean = false;
  acceptanceShowDropZone: boolean = false;
  companyPromissoryShowDropZone: boolean = false;
  profileShowDropZone: boolean = false;
  synthesisShowDropZone: boolean = false;
  detailedShowDropZone: boolean = false;
  rutShowDropZone: boolean = false;
  ccioShowDropZone: boolean = false;
  repIdShowDropZone: boolean = false;
  pgShowDropZone: boolean = false;
  bankExtractsShowDropZone: boolean = false;
  pensionerShowDropZone: boolean = false;
  utilitiesShowDropZone: boolean = false;
  otherDocument1ShowDropZone: boolean = false;
  otherDocument2ShowDropZone: boolean = false;
  otherDocument3ShowDropZone: boolean = false;
  // constructor
  constructor(
    public translate: TranslateService,
    public apiSv: ApiService,
    public filterSv: FilterService,
    public alertSv: AlertService,
    private utilitiesSv: UtilitiesService,
    private modalService: NgbModal,
  ) {
  }
  // life cycle
  async ngOnInit() {}
  async ngOnChanges(changes: SimpleChanges) {  
    if (changes.idFrontFileData) {
      this.idFrontLocalFileData = this.idFrontFileData;
    }
    if (changes.idBackFileData) {
      this.idBackLocalFileData = this.idBackFileData;
    }
    if (changes.requestFileData) {
      this.requestLocalFileData = this.requestFileData;
    }
    if (changes.bankFileData) {
      this.bankLocalFileData = this.bankFileData;
    }
    if (changes.laborFileData) {
      this.laborLocalFileData = this.laborFileData;
    }
    if (changes.promissoryFileData) {
      this.promissoryLocalFileData = this.promissoryFileData;
    }
    if (changes.instructionsFileData) {
      this.instructionsLocalFileData = this.instructionsFileData;
    }
    if (changes.authorizationFileData) {
      this.authorizationLocalFileData = this.authorizationFileData;
    }
    if (changes.disbursementFileData) {
      this.disbursementLocalFileData = this.disbursementFileData;
    }
    if (changes.loanPaymentFileData) {
      this.loanPaymentLocalFileData = this.loanPaymentFileData;
    }
    if (changes.batchPaymentReceiptFileData) {
      this.batchPaymentReceiptLocalFileData = this.batchPaymentReceiptFileData;
    }
    if (changes.acceptanceFileData) {
      this.acceptanceLocalFileData = this.acceptanceFileData;
    }
    if (changes.companyPromissoryFileData) {
      this.companyPromissoryLocalFileData = this.companyPromissoryFileData;
    }
    if (changes.profileFileData) {
      this.profileLocalFileData = this.profileFileData;
    }
    if (changes.synthesisFileData) {
      this.synthesisLocalFileData = this.synthesisFileData;
    }
    if (changes.detailedFileData) {
      this.detailedLocalFileData = this.detailedFileData;
    }
    if (changes.paymentReceiptFiles) {
      this.paymentReceiptLocalFiles = this.paymentReceiptFiles;
    }
    if (changes.rutFileData) {
      this.rutLocalFileData = this.rutFileData;
    }
    if (changes.ccioFileData) {
      this.ccioLocalFileData = this.ccioFileData;
    }
    if (changes.repIdFileData) {
      this.repIdLocalFileData = this.repIdFileData;
    }
    if (changes.pgFileData) {
      this.pgLocalFileData = this.pgFileData;
    }
    if (changes.bankExtractsFileData) {
      this.bankExtractsLocalFileData = this.bankExtractsFileData;
    }
    if (changes.pensionerFileData) {
      this.pensionerLocalFileData = this.pensionerFileData;
    }
    if (changes.utilitiesFileData) {
      this.utilitiesLocalFileData = this.utilitiesFileData;
    }
    if (changes.otherDocument1FileData) {
      this.otherDocument1LocalFileData = this.otherDocument1FileData;
    }
    if (changes.otherDocument2FileData) {
      this.otherDocument2LocalFileData = this.otherDocument2FileData;
    }
    if (changes.otherDocument3FileData) {
      this.otherDocument3LocalFileData = this.otherDocument3FileData;
    }
  }
  // loader
  async updateLoader(loaderMsg: any) {
    this.loaderEvent.emit(loaderMsg);
  }
  // files
  async manageFile(fileData: any, fileTarget: string, fileAction: string, modal: any) {
    // check if fileTarget is been managed already
    if (this.fileTarget !== null) {
      // send alert
      this.alertSv.showMessage(this.translate.instant('LOAN.loanDocumentManaging'),'warning', this.translate.instant('HELPERS.warning'), true);
    } else {
      // update fileData
      this.fileData = fileData;
      // update fileTarget
      this.fileTarget = fileTarget;
      // update fileAction
      this.fileAction = fileAction;
      // check fileAction
      switch (fileAction) {
        case 'add':
          // check fileTarget
          switch (fileTarget) {
            case 'idFront':
              this.idFrontShowDropZone = true;
              break;
            case 'idBack':
              this.idBackShowDropZone = true;
              break;
            case 'request':
              this.requestShowDropZone = true;
              break;
            case 'bank':
              this.bankShowDropZone = true;
              break;
            case 'labor':
              this.laborShowDropZone = true;
              break;
            case 'promissory':
              this.promissoryShowDropZone = true;
              break;
            case 'instructions':
              this.instructionsShowDropZone = true;
              break;
            case 'authorization':
              this.authorizationShowDropZone = true;
              break;
            case 'disbursement':
              this.disbursementShowDropZone = true;
              break;
            case 'loanPayment':
              this.loanPaymentShowDropZone = true;
              break;
            case 'batchPaymentReceipt':
              this.batchPaymentReceiptShowDropZone = true;
              break;
            case 'acceptance':
              this.acceptanceShowDropZone = true;
              break;
            case 'companyPromissory':
              this.companyPromissoryShowDropZone = true;
              break;
            case 'profile':
              this.profileShowDropZone = true;
              break;
            case 'synthesis':
              this.synthesisShowDropZone = true;
              break;
            case 'detailed':
              this.detailedShowDropZone = true;
              break;
            case 'paymentReceipts':
              // get paymentReceiptIndex
              const paymentReceiptIndex: any = this.paymentReceiptFiles.findIndex((paymentReceipt: any) => paymentReceipt.id === this.fileData.id);  
              // update paymentReceiptFile
              this.paymentReceiptFiles[paymentReceiptIndex].showDropZone = true;
              break;
            case 'rut':
              this.rutShowDropZone = true;
              break;
            case 'ccio':
              this.ccioShowDropZone = true;
              break;
            case 'repId':
              this.repIdShowDropZone = true;
              break;
            case 'pg':
              this.pgShowDropZone = true;
              break;
            case 'bankExtracts':
              this.bankExtractsShowDropZone = true;
              break;
            case 'pensioner':
              this.pensionerShowDropZone = true;
              break;
            case 'utilities':
              this.utilitiesShowDropZone = true;
              break;
            case 'otherDocument1':
              this.otherDocument1ShowDropZone = true;
              break;
            case 'otherDocument2':
              this.otherDocument2ShowDropZone = true;
              break;
            case 'otherDocument3':
              this.otherDocument3ShowDropZone = true;
              break;
          }
          break;
        case 'edit':
          // check fileTarget
          switch (fileTarget) {
            case 'idFront':
              this.idFrontShowDropZone = true;
              break;
            case 'idBack':
              this.idBackShowDropZone = true;
              break;
            case 'request':
              this.requestShowDropZone = true;
              break;
            case 'bank':
              this.bankShowDropZone = true;
              break;
            case 'labor':
              this.laborShowDropZone = true;
              break;
            case 'promissory':
              this.promissoryShowDropZone = true;
              break;
            case 'instructions':
              this.instructionsShowDropZone = true;
              break;
            case 'authorization':
              this.authorizationShowDropZone = true;
              break;
            case 'disbursement':
              this.disbursementShowDropZone = true;
              break;
            case 'loanPayment':
              this.loanPaymentShowDropZone = true;
              break;
            case 'batchPaymentReceipt':
              this.batchPaymentReceiptShowDropZone = true;
              break;
            case 'acceptance':
              this.acceptanceShowDropZone = true;
              break;
            case 'companyPromissory':
              this.companyPromissoryShowDropZone = true;
              break;
            case 'profile':
              this.profileShowDropZone = true;
              break;
            case 'synthesis':
              this.synthesisShowDropZone = true;
              break;
            case 'detailed':
              this.detailedShowDropZone = true;
              break;
            case 'paymentReceipts':
              // get paymentReceiptIndex
              const paymentReceiptIndex: any = this.paymentReceiptFiles.findIndex((paymentReceipt: any) => paymentReceipt.id === this.fileData.id);
              // update paymentReceiptFile
              this.paymentReceiptFiles[paymentReceiptIndex].showDropZone = true;
              break;
            case 'rut':
              this.rutShowDropZone = true;
              break;
            case 'ccio':
              this.ccioShowDropZone = true;
              break;
            case 'repId':
              this.repIdShowDropZone = true;
              break;
            case 'pg':
              this.pgShowDropZone = true;
              break;
            case 'bankExtracts':
              this.bankExtractsShowDropZone = true;
              break;
            case 'pensioner':
              this.pensionerShowDropZone = true;
              break;
            case 'utilities':
              this.utilitiesShowDropZone = true;
              break;
            case 'otherDocument1':
              this.otherDocument1ShowDropZone = true;
              break;
            case 'otherDocument2':
              this.otherDocument2ShowDropZone = true;
              break;
            case 'otherDocument3':
              this.otherDocument3ShowDropZone = true;
              break;
          }
          break;
        case 'zoom':
          this.showPreview(modal, this.fileData.title, this.fileData.filePath);
          break;
        case 'open':
          this.openFile(this.fileData.filePath);
          break;
        case 'delete':
          this.askDeleteFile(fileTarget);
          break;
      }
    }
  }
  async saveFile(file: any) {
    try {
      // check fileAction
      switch (this.fileAction) {
        case 'add':
          this.addFile(this.fileTarget, file);
          break;
        case 'edit':
          this.updateFile(this.fileTarget, file);
          break;
      }
    } catch (error) {
      console.log('error', error);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async addFile(target: string, file: any) {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // check fileTarget
      switch (target) {
        case 'idFront':
          // set loanUserIdFront
          const loanUserIdFront = {
            userAttachmentType: target,
            userAttachment: file.filePath,
            fileKey: file.fileKey,
          }
          // save userAttachment
          const auxUserIdFront: any = await this.apiSv.addUserAttachment(this.userId, loanUserIdFront);
          // get idFrontId
          const idFrontId = auxUserIdFront.data.id;
          // set idFrontData
          const idFrontData ={
            id: idFrontId,
            parentId: this.userId,
            docType: file.docType,
            file: file.file,
            fileId: idFrontId,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: file.title,
            image: file.image,
            showDropZone: false,
          }
          // update idFrontFileData
          this.idFrontFileData = idFrontData;
          // emit data
          this.sendAction('add', 'idFront', idFrontData);
          break;
        case 'idBack':
          // save userAttachment
          const loanUserIdBack = {
            userAttachmentType: target,
            userAttachment: file.filePath,
            fileKey: file.fileKey,
          }
          // init auxUserIdBack
          const auxUserIdBack: any = await this.apiSv.addUserAttachment(this.userId, loanUserIdBack);
          // get idBackId
          const idBackId = auxUserIdBack.data.id;
          // set idBackData
          const idBackData ={
            id: idBackId,
            parentId: this.userId,
            docType: file.docType,
            file: file.file,
            fileId: idBackId,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: file.title,
            image: file.image,
            showDropZone: false,
          }
          // update idBackFileData
          this.idBackFileData = idBackData;
          // emit data
          this.sendAction('add', 'idBack', idBackData);
          break;
        case 'request':
          // set loanDocumentRequest
          const loanDocumentRequest = {
            loanId: this.loanId,
            loanDocumentType: target,
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          const loanDocumentRequestId: any = await this.apiSv.addLoanDocument(loanDocumentRequest);
          // get requestId
          const requestId = loanDocumentRequestId.data.id;
          // set requestData
          const requestData ={
            id: requestId,
            parentId: this.loanId,
            docType: file.docType,
            file: file.file,
            fileId: requestId,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: file.title,
            image: file.image,
            showDropZone: false,
          }
          // update requestFileData
          this.requestFileData = requestData;
          // emit data
          this.sendAction('add', 'request', requestData);
          break;
        case 'bank':
          // get bankId
          const bankId = this.bankFileData.id;
          // set userBankAccountData
          const userBankAccountData: UserBankAccountInterface = {
            certificateFile: file.filePath,
            certificateFileKey: file.fileKey
          }
          // save bank account on API
          await this.apiSv.updateUserBankAccount(bankId, userBankAccountData);
          // set bankData
          const bankData ={
            id: bankId,
            parentId: this.loanId,
            docType: file.docType,
            file: file.file,
            fileId: bankId,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: file.title,
            image: file.image,
            showDropZone: false,
          }
          // update bankFileData
          this.bankFileData = bankData;
          // emit data
          this.sendAction('add', 'bank', bankData);
          break;
        case 'labor':
          // set loanDocumentLabor
          const loanDocumentLabor = {
            loanId: this.loanId,
            loanDocumentType: target,
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          const loanDocumentLaborId: any = await this.apiSv.addLoanDocument(loanDocumentLabor);
          // get laborId
          const laborId = loanDocumentLaborId.data.id;
          // set laborData
          const laborData ={
            id: laborId,
            parentId: this.loanId,
            docType: file.docType,
            file: file.file,
            fileId: laborId,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: file.title,
            image: file.image,
            showDropZone: false,
          }
          // update laborFileData
          this.laborFileData = laborData;
          // emit data
          this.sendAction('add', 'labor', laborData);
          break;
        case 'promissory':
          // set loanDocumentPromissory
          const loanDocumentPromissory = {
            loanId: this.loanId,
            loanDocumentType: target,
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          const loanDocumentPromissoryId: any = await this.apiSv.addLoanDocument(loanDocumentPromissory);
          // get promissoryId
          const promissoryId = loanDocumentPromissoryId.data.id;
          // set promissoryData
          const promissoryData ={
            id: promissoryId,
            parentId: this.loanId,
            docType: file.docType,
            file: file.file,
            fileId: promissoryId,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: file.title,
            image: file.image,
            showDropZone: false,
          }
          // update promissoryFileData
          this.promissoryFileData = promissoryData;
          // emit data
          this.sendAction('add', 'promissory', promissoryData);
          break;
        case 'instructions':
          // set loanDocumentInstructions
          const loanDocumentInstructions = {
            loanId: this.loanId,
            loanDocumentType: target,
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          const loanDocumentInstructionsId: any = await this.apiSv.addLoanDocument(loanDocumentInstructions);
          // get instructionsId
          const instructionsId = loanDocumentInstructionsId.data.id;
          // set instructionsData
          const instructionsData ={
            id: instructionsId,
            parentId: this.loanId,
            docType: file.docType,
            file: file.file,
            fileId: instructionsId,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: file.title,
            image: file.image,
            showDropZone: false,
          }
          // update instructionsFileData
          this.instructionsFileData = instructionsData;
          // emit data
          this.sendAction('add', 'instructions', instructionsData);
          break;
        case 'authorization':
          // set loanDocumentAuthorization
          const loanDocumentAuthorization = {
            loanId: this.loanId,
            loanDocumentType: target,
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          const loanDocumentAuthorizationId: any = await this.apiSv.addLoanDocument(loanDocumentAuthorization);
          // get authorizationId
          const authorizationId = loanDocumentAuthorizationId.data.id;
          // set authorizationData
          const authorizationData ={
            id: authorizationId,
            parentId: this.loanId,
            docType: file.docType,
            file: file.file,
            fileId: authorizationId,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: file.title,
            image: file.image,
            showDropZone: false,
          }
          // update authorizationFileData
          this.authorizationFileData = authorizationData;
          // emit data
          this.sendAction('add', 'authorization', authorizationData);
          break;
        case 'disbursement':
          // set loanDocumentDisbursement
          const loanDocumentDisbursement = {
            loanId: this.loanId,
            loanDocumentType: target,
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          const loanDocumentDisbursementAux: any = await this.apiSv.addLoanDocument(loanDocumentDisbursement);
          // set disbursementId
          const disbursementId = loanDocumentDisbursementAux.data.id;
          // set disbursementData
          const disbursementData ={
            id: disbursementId,
            parentId: this.loanId,
            docType: file.docType,
            file: file.file,
            fileId: disbursementId,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: file.title,
            image: file.image,
            showDropZone: false,
          }
          // update disbursementFileData
          this.disbursementFileData = disbursementData;
          // emit data
          this.sendAction('add', 'disbursement', disbursementData);
          break;
        case 'loanPayment':
          // set loanPayment
          const loanPayment = {
            loanPaymentReceipt: file.filePath,
          }
          // save loanDocument
          await this.apiSv.updateLoanPayment(this.loanPaymentId, loanPayment);
          // set loanPaymentData
          const loanPaymentData ={
            id: this.loanPaymentId,
            parentId: null,
            docType: file.docType,
            file: file.file,
            fileId: this.loanPaymentId,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: file.title,
            image: file.image,
            showDropZone: false,
          }
          // update loanPaymentFileData
          this.loanPaymentFileData = loanPaymentData;
          // emit data
          this.sendAction('add', 'loanPayment', loanPaymentData);
          break;
        case 'batchPaymentReceipt':
          // set loanPaymentDisbursement
          const loanPaymentDisbursement = {
            batchPaymentReceiptFile: file.filePath,
            batchPaymentReceiptFileKey: file.fileKey,
          }
          // save batch
          await this.apiSv.updateBatch(this.batchId, loanPaymentDisbursement);
          // set batchPaymentReceiptId
          const batchPaymentReceiptId = this.batchId;
          // set parentId
          const parentId = this.batchId;
          // set batchPaymentReceiptData
          const batchPaymentReceiptData ={
            id: batchPaymentReceiptId,
            parentId: parentId,
            docType: file.docType,
            file: file.file,
            fileId: batchPaymentReceiptId,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: file.title,
            image: file.image,
            showDropZone: false,
          }
          // update batchPaymentReceiptFileData
          this.batchPaymentReceiptFileData = batchPaymentReceiptData;
          // emit data
          this.sendAction('add', 'batchPaymentReceipt', batchPaymentReceiptData);
          break;
        case 'acceptance':
          // set loanDocumentAcceptance
          const loanDocumentAcceptance = {
            loanId: this.loanId,
            loanDocumentType: target,
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          const loanDocumentAcceptanceId: any = await this.apiSv.addLoanDocument(loanDocumentAcceptance);
          // get acceptanceId
          const acceptanceId = loanDocumentAcceptanceId.data.id;
          // set acceptanceData
          const acceptanceData ={
            id: acceptanceId,
            parentId: this.loanId,
            docType: file.docType,
            file: file.file,
            fileId: acceptanceId,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: file.title,
            image: file.image,
            showDropZone: false,
          }
          // update acceptanceFileData
          this.acceptanceFileData = acceptanceData;
          // emit data
          this.sendAction('add', 'acceptance', acceptanceData);
          break;
        case 'companyPromissory':
          // set loanDocumentCompanyPromissory
          const loanDocumentCompanyPromissory = {
            loanId: this.loanId,
            loanDocumentType: target,
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          const loanDocumentCompanyPromissoryId: any = await this.apiSv.addLoanDocument(loanDocumentCompanyPromissory);
          // get companyPromissoryId
          const companyPromissoryId = loanDocumentCompanyPromissoryId.data.id;
          // set companyPromissoryData
          const companyPromissoryData ={
            id: companyPromissoryId,
            parentId: this.loanId,
            docType: file.docType,
            file: file.file,
            fileId: companyPromissoryId,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: file.title,
            image: file.image,
            showDropZone: false,
          }
          // update companyPromissoryFileData
          this.companyPromissoryFileData = companyPromissoryData;
          // emit data
          this.sendAction('add', 'companyPromissory', companyPromissoryData);
          break;
        case 'profile':
          // set loanDocumentProfile
          const loanDocumentProfile = {
            loanId: this.loanId,
            loanDocumentType: target,
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          const loanDocumentProfileId: any = await this.apiSv.addLoanDocument(loanDocumentProfile);
          // get profileId
          const profileId = loanDocumentProfileId.data.id;
          // set profileData
          const profileData ={
            id: profileId,
            parentId: this.loanId,
            docType: file.docType,
            file: file.file,
            fileId: profileId,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: file.title,
            image: file.image,
            showDropZone: false,
          }
          // update profileFileData
          this.profileFileData = profileData;
          // emit data
          this.sendAction('add', 'profile', profileData);
          break;
        case 'synthesis':
          // set loanDocumentSynthesis
          const loanDocumentSynthesis = {
            loanId: this.loanId,
            loanDocumentType: target,
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          const loanDocumentSynthesisId: any = await this.apiSv.addLoanDocument(loanDocumentSynthesis);
          // get synthesisId
          const synthesisId = loanDocumentSynthesisId.data.id;
          // set synthesisData
          const synthesisData ={
            id: synthesisId,
            parentId: this.loanId,
            docType: file.docType,
            file: file.file,
            fileId: synthesisId,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: file.title,
            image: file.image,
            showDropZone: false,
          }
          // update synthesisFileData
          this.synthesisFileData = synthesisData;
          // emit data
          this.sendAction('add', 'synthesis', synthesisData);
          break;
        case 'detailed':
          // set loanDocumentDetailed
          const loanDocumentDetailed = {
            loanId: this.loanId,
            loanDocumentType: target,
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          const loanDocumentDetailedId: any = await this.apiSv.addLoanDocument(loanDocumentDetailed);
          // get detailedId
          const detailedId = loanDocumentDetailedId.data.id;
          // set detailedData
          const detailedData ={
            id: detailedId,
            parentId: this.loanId,
            docType: file.docType,
            file: file.file,
            fileId: detailedId,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: file.title,
            image: file.image,
            showDropZone: false,
          }
          // update detailedFileData
          this.detailedFileData = detailedData;
          // emit data
          this.sendAction('add', 'detailed', detailedData);
          break;
        case 'paymentReceipts':
          // set loanDocumentPaymentReceipt
          const loanDocumentPaymentReceipt = {
            loanId: this.loanId,
            loanDocumentType: 'paymentReceipts',
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          const loanDocumentPaymentReceiptId: any = await this.apiSv.addLoanDocument(loanDocumentPaymentReceipt);
          // get receiptId
          const receiptId = loanDocumentPaymentReceiptId.data.id;
          // set paymentReceiptData
          const paymentReceiptData = {
            id: receiptId,
            parentId: this.loanId,
            docType: file.docType,
            file: file.file,
            fileId: receiptId,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: file.title,
            image: file.image,
            showDropZone: false,
          }
          // get paymentReceiptIndex
          const paymentReceiptIndex: any = this.paymentReceiptFiles.findIndex((paymentReceipt: any) => paymentReceipt.id === this.fileData.id); 
          // update paymentReceiptFile
          this.paymentReceiptFiles[paymentReceiptIndex] = paymentReceiptData;
          // update fileData id
          this.fileData.id = receiptId;
          // emit data
          this.sendAction('add', 'paymentReceipts', this.paymentReceiptFiles);
          break;
        case 'rut':
          // set loanDocumentRut
          const loanDocumentRut = {
            loanId: this.loanId,
            loanDocumentType: target,
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          const loanDocumentRutId: any = await this.apiSv.addLoanDocument(loanDocumentRut);
          // get rutId
          const rutId = loanDocumentRutId.data.id;
          // set rutData
          const rutData ={
            id: rutId,
            parentId: this.loanId,
            docType: file.docType,
            file: file.file,
            fileId: rutId,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: file.title,
            image: file.image,
            showDropZone: false,
          }
          // update rutFileData
          this.rutFileData = rutData;
          // emit data
          this.sendAction('add', 'rut', rutData);
          break;
        case 'ccio':
          // set loanDocumentCCIO
          const loanDocumentCCIO = {
            loanId: this.loanId,
            loanDocumentType: target,
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          const loanDocumentCCIOId: any = await this.apiSv.addLoanDocument(loanDocumentCCIO);
          // get ccioId
          const ccioId = loanDocumentCCIOId.data.id;
          // set ccioData
          const ccioData ={
            id: ccioId,
            parentId: this.loanId,
            docType: file.docType,
            file: file.file,
            fileId: ccioId,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: file.title,
            image: file.image,
            showDropZone: false,
          }
          // update ccioFileData
          this.ccioFileData = ccioData;
          // emit data
          this.sendAction('add', 'ccio', ccioData);
          break;
        case 'repId':
          // set loanDocumentRepId
          const loanDocumentRepId = {
            loanId: this.loanId,
            loanDocumentType: target,
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          const loanDocumentRepIdId: any = await this.apiSv.addLoanDocument(loanDocumentRepId);
          // get repIdId
          const repIdId = loanDocumentRepIdId.data.id;
          // set repIdData
          const repIdData ={
            id: repIdId,
            parentId: this.loanId,
            docType: file.docType,
            file: file.file,
            fileId: repIdId,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: file.title,
            image: file.image,
            showDropZone: false,
          }
          // update repIdFileData
          this.repIdFileData = repIdData;
          // emit data
          this.sendAction('add', 'repId', repIdData);
          break;
        case 'pg':
          // set loanDocumentPG
          const loanDocumentPG = {
            loanId: this.loanId,
            loanDocumentType: target,
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          const loanDocumentPGId: any = await this.apiSv.addLoanDocument(loanDocumentPG);
          // get pgId
          const pgId = loanDocumentPGId.data.id;
          // set pgData
          const pgData ={
            id: pgId,
            parentId: this.loanId,
            docType: file.docType,
            file: file.file,
            fileId: pgId,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: file.title,
            image: file.image,
            showDropZone: false,
          }
          // update pgFileData
          this.pgFileData = pgData;
          // emit data
          this.sendAction('add', 'pg', pgData);
          break;
        case 'bankExtracts':
          // set loanDocumentBankExtracts
          const loanDocumentBankExtracts = {
            loanId: this.loanId,
            loanDocumentType: target,
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          const loanDocumentBankExtractsId: any = await this.apiSv.addLoanDocument(loanDocumentBankExtracts);
          // get bankExtractsId
          const bankExtractsId = loanDocumentBankExtractsId.data.id;
          // set bankExtractsData
          const bankExtractsData ={
            id: bankExtractsId,
            parentId: this.loanId,
            docType: file.docType,
            file: file.file,
            fileId: bankExtractsId,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: file.title,
            image: file.image,
            showDropZone: false,
          }
          // update bankExtractsFileData
          this.bankExtractsFileData = bankExtractsData;
          // emit data
          this.sendAction('add', 'bankExtracts', bankExtractsData);
          break;
        case 'pensioner':
          // set loanDocumentPensioner
          const loanDocumentPensioner = {
            loanId: this.loanId,
            loanDocumentType: target,
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          const loanDocumentPensionerId: any = await this.apiSv.addLoanDocument(loanDocumentPensioner);
          // get pensionerId
          const pensionerId = loanDocumentPensionerId.data.id;
          // set pensionerData
          const pensionerData ={
            id: pensionerId,
            parentId: this.loanId,
            docType: file.docType,
            file: file.file,
            fileId: pensionerId,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: file.title,
            image: file.image,
            showDropZone: false,
          }
          // update pensionerFileData
          this.pensionerFileData = pensionerData;
          // emit data
          this.sendAction('add', 'pensioner', pensionerData);
          break;
        case 'utilities':
          // set loanDocumentUtilities
          const loanDocumentUtilities = {
            loanId: this.loanId,
            loanDocumentType: target,
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          const loanDocumentUtilitiesId: any = await this.apiSv.addLoanDocument(loanDocumentUtilities);
          // get utilitiesId
          const utilitiesId = loanDocumentUtilitiesId.data.id;
          // set utilitiesData
          const utilitiesData ={
            id: utilitiesId,
            parentId: this.loanId,
            docType: file.docType,
            file: file.file,
            fileId: utilitiesId,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: file.title,
            image: file.image,
            showDropZone: false,
          }
          // update utilitiesFileData
          this.utilitiesFileData = utilitiesData;
          // emit data
          this.sendAction('add', 'utilities', utilitiesData);
          break;
        case 'otherDocument1':
          // set loanDocumentOtherDocument1
          const loanDocumentOtherDocument1 = {
            loanId: this.loanId,
            loanDocumentType: target,
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          const loanDocumentOtherDocument1Id: any = await this.apiSv.addLoanDocument(loanDocumentOtherDocument1);
          // get otherDocument1Id
          const otherDocument1Id = loanDocumentOtherDocument1Id.data.id;
          // set otherDocument1Data
          const otherDocument1Data ={
            id: otherDocument1Id,
            parentId: this.loanId,
            docType: file.docType,
            file: file.file,
            fileId: otherDocument1Id,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: file.title,
            image: file.image,
            showDropZone: false,
          }
          // update otherDocument1FileData
          this.otherDocument1FileData = otherDocument1Data;
          // emit data
          this.sendAction('add', 'otherDocument1', otherDocument1Data);
          break;
        case 'otherDocument2':
          // set loanDocumentOtherDocument2
          const loanDocumentOtherDocument2 = {
            loanId: this.loanId,
            loanDocumentType: target,
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          const loanDocumentOtherDocument2Id: any = await this.apiSv.addLoanDocument(loanDocumentOtherDocument2);
          // get otherDocument2Id
          const otherDocument2Id = loanDocumentOtherDocument2Id.data.id;
          // set otherDocument2Data
          const otherDocument2Data ={
            id: otherDocument2Id,
            parentId: this.loanId,
            docType: file.docType,
            file: file.file,
            fileId: otherDocument2Id,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: file.title,
            image: file.image,
            showDropZone: false,
          }
          // update otherDocument2FileData
          this.otherDocument2FileData = otherDocument2Data;
          // emit data
          this.sendAction('add', 'otherDocument2', otherDocument2Data);
          break;
        case 'otherDocument3':
          // set loanDocumentOtherDocument3
          const loanDocumentOtherDocument3 = {
            loanId: this.loanId,
            loanDocumentType: target,
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          const loanDocumentOtherDocument3Id: any = await this.apiSv.addLoanDocument(loanDocumentOtherDocument3);
          // get otherDocument3Id
          const otherDocument3Id = loanDocumentOtherDocument3Id.data.id;
          // set otherDocument3Data
          const otherDocument3Data ={
            id: otherDocument3Id,
            parentId: this.loanId,
            docType: file.docType,
            file: file.file,
            fileId: otherDocument3Id,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: file.title,
            image: file.image,
            showDropZone: false,
          }
          // update otherDocument3FileData
          this.otherDocument3FileData = otherDocument3Data;
          // emit data
          this.sendAction('add', 'otherDocument3', otherDocument3Data);
          break;
      }
      // check target and hide dropzone
      switch (target) {
        case 'idFront':
          this.idFrontShowDropZone = false;
          break;
        case 'idBack':
          this.idBackShowDropZone = false;
          break;
        case 'request':
          this.requestShowDropZone = false;
          break;
        case 'bank':
          this.bankShowDropZone = false;
          break;
        case 'labor':
          this.laborShowDropZone = false;
          break;
        case 'promissory':
          this.promissoryShowDropZone = false;
          break;
        case 'instructions':
          this.instructionsShowDropZone = false;
          break;
        case 'authorization':
          this.authorizationShowDropZone = false;
          break;
        case 'disbursement':
          this.disbursementShowDropZone = false;
          break;
        case 'loanPayment':
          this.loanPaymentShowDropZone = false;
          break;
        case 'batchPaymentReceipt':
          this.batchPaymentReceiptShowDropZone = false;
          break;
        case 'acceptance':
          this.acceptanceShowDropZone = false;
          break;
        case 'companyPromissory':
          this.companyPromissoryShowDropZone = false;
          break;
        case 'profile':
          this.profileShowDropZone = false;
          break;
        case 'synthesis':
          this.synthesisShowDropZone = false;
          break;
        case 'detailed':
          this.detailedShowDropZone = false;
          break;
        case 'paymentReceipts':
          // get paymentReceiptIndex
          const paymentReceiptIndex: any = this.paymentReceiptFiles.findIndex((paymentReceipt: any) => paymentReceipt.id === this.fileData.id);  
          // update paymentReceiptFile
          this.paymentReceiptFiles[paymentReceiptIndex].showDropZone = false;
            break;
        case 'rut':
          this.rutShowDropZone = false;
          break;
        case 'ccio':
          this.ccioShowDropZone = false;
          break;
        case 'repId':
          this.repIdShowDropZone = false;
          break;
        case 'pg':
          this.pgShowDropZone = false;
          break;
        case 'bankExtracts':
          this.bankExtractsShowDropZone = false;
          break;
        case 'pensioner':
          this.pensionerShowDropZone = false;
          break;
        case 'utilities':
          this.utilitiesShowDropZone = false;
          break;
        case 'otherDocument1':
          this.otherDocument1ShowDropZone = false;
          break;
        case 'otherDocument2':
          this.otherDocument2ShowDropZone = false;
          break;
        case 'otherDocument3':
          this.otherDocument3ShowDropZone = false;
          break;
      }
      // clear data
      this.fileTarget = null;
      this.fileAction = null;
      // hide loader
      this.loaderEvent.emit(null);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async updateFile(target: string, file: any) {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.saving'));
      // check fileTarget
      switch (target) {
        case 'idFront':
          // delete old file on server
          await this.apiSv.deleteFile(this.fileData.fileKey);
          // set loanUserIdFront
          const loanUserIdFront = {
            userAttachment: file.filePath,
            fileKey: file.fileKey,
          }
          // saver userAttachment
          await this.apiSv.updateUserAttachment(this.fileData.id, loanUserIdFront);
          // set idFrontFileData
          const idFrontFileData = {
            id: this.fileData.id,
            parentId: this.userId,
            docType: this.fileData.docType,
            file: file.file,
            fileId: this.fileData.id,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: this.fileData.title,
            image: this.fileData.image,
            showDropZone: false,
          }
          // update idFrontFileData
          this.idFrontFileData = idFrontFileData;
          // emit data
          this.sendAction('edit', 'idFront', idFrontFileData);
          break;
        case 'idBack':
          // delete old file on server
          await this.apiSv.deleteFile(this.fileData.fileKey);  
          // set loanUserIdBack
          const loanUserIdBack = {
            userAttachment: file.filePath,
            fileKey: file.fileKey,
          }
          // saver userAttachment
          await this.apiSv.updateUserAttachment(this.fileData.id, loanUserIdBack);
          // set idBackFileData
          const idBackFileData = {
            id: this.fileData.id,
            parentId: this.userId,
            docType: this.fileData.docType,
            file: file.file,
            fileId: this.fileData.id,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: this.fileData.title,
            image: this.fileData.image,
            showDropZone: false,
          }
          // update idBackFileData
          this.idBackFileData = idBackFileData;
          // emit data
          this.sendAction('edit', 'idBack', idBackFileData);
          break;
        case 'request':
          // delete old file on server
          await this.apiSv.deleteFile(this.fileData.fileKey);  
          // set loanDocumentRequest
          const loanDocumentRequest = {
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          await this.apiSv.updateLoanDocument(this.fileData.id, loanDocumentRequest);
          // set requestFileData
          const requestFileData = {
            id: this.fileData.id,
            parentId: this.loanId,
            docType: this.fileData.docType,
            file: file.file,
            fileId: this.fileData.id,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: this.fileData.title,
            image: this.fileData.image,
            showDropZone: false,
          }
          // update requestFileData
          this.requestFileData = requestFileData;
          // emit data
          this.sendAction('edit', 'request', requestFileData);
          break;
        case 'bank':
          // delete old file on server
          await this.apiSv.deleteFile(this.fileData.fileKey);
          // get bankId
          const bankId = this.bankFileData.id;
          // set userBankAccountData
          const userBankAccountData: UserBankAccountInterface = {
            certificateFile: file.filePath,
            certificateFileKey: file.fileKey
          }
          // save bank account on API
          await this.apiSv.updateUserBankAccount(bankId, userBankAccountData);
          // set bankFileData
          const bankFileData = {
            id: this.fileData.id,
            parentId: this.loanId,
            docType: this.fileData.docType,
            file: file.file,
            fileId: this.fileData.id,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: this.fileData.title,
            image: this.fileData.image,
            showDropZone: false,
          }
          // update bankFileData
          this.bankFileData = bankFileData;
          // emit data
          this.sendAction('edit', 'bank', bankFileData);
          break;
        case 'labor':
          // delete old file on server
          await this.apiSv.deleteFile(this.fileData.fileKey);  
          // set loanDocumentLabor
          const loanDocumentLabor = {
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          await this.apiSv.updateLoanDocument(this.fileData.id, loanDocumentLabor);
          // set laborFileData
          const laborFileData = {
            id: this.fileData.id,
            parentId: this.loanId,
            docType: this.fileData.docType,
            file: file.file,
            fileId: this.fileData.id,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: this.fileData.title,
            image: this.fileData.image,
            showDropZone: false,
          }
          // update laborFileData
          this.laborFileData = laborFileData;
          // emit data
          this.sendAction('edit', 'labor', laborFileData);
          break;
        case 'promissory':
          // delete old file on server
          await this.apiSv.deleteFile(this.fileData.fileKey);  
          // set loanDocumentPromissory
          const loanDocumentPromissory = {
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          await this.apiSv.updateLoanDocument(this.fileData.id, loanDocumentPromissory);
          // set promissoryFileData
          const promissoryFileData = {
            id: this.fileData.id,
            parentId: this.loanId,
            docType: this.fileData.docType,
            file: file.file,
            fileId: this.fileData.id,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: this.fileData.title,
            image: this.fileData.image,
            showDropZone: false,
          }
          // update promissoryFileData
          this.promissoryFileData = promissoryFileData;
          // emit data
          this.sendAction('edit', 'promissory', promissoryFileData);
          break;
        case 'instructions':
          // delete old file on server
          await this.apiSv.deleteFile(this.fileData.fileKey);  
          // set loanDocumentInstructions
          const loanDocumentInstructions = {
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          await this.apiSv.updateLoanDocument(this.fileData.id, loanDocumentInstructions);
          // set instructionsFileData
          const instructionsFileData = {
            id: this.fileData.id,
            parentId: this.loanId,
            docType: this.fileData.docType,
            file: file.file,
            fileId: this.fileData.id,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: this.fileData.title,
            image: this.fileData.image,
            showDropZone: false,
          }
          // update instructionsFileData
          this.instructionsFileData = instructionsFileData;
          // emit data
          this.sendAction('edit', 'instructions', instructionsFileData);
          break;
        case 'authorization':
          // delete old file on server
          await this.apiSv.deleteFile(this.fileData.fileKey);  
          // set loanDocumentAuthorization
          const loanDocumentAuthorization = {
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          await this.apiSv.updateLoanDocument(this.fileData.id, loanDocumentAuthorization);
          // set authorizationFileData
          const authorizationFileData = {
            id: this.fileData.id,
            parentId: this.loanId,
            docType: this.fileData.docType,
            file: file.file,
            fileId: this.fileData.id,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: this.fileData.title,
            image: this.fileData.image,
            showDropZone: false,
          }
          // update authorizationFileData
          this.authorizationFileData = authorizationFileData;
          // emit data
          this.sendAction('edit', 'authorization', authorizationFileData);
          break;
        case 'disbursement':
          // delete old file on server
          await this.apiSv.deleteFile(this.fileData.fileKey);
          // set loanDocumentDisbursement
          const loanDocumentDisbursement = {
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          await this.apiSv.updateLoanDocument(this.fileData.id, loanDocumentDisbursement);
          // set disbursementFileData
          const disbursementFileData = {
            id: this.fileData.id,
            parentId: this.loanId,
            docType: this.fileData.docType,
            file: file.file,
            fileId: this.fileData.id,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: this.fileData.title,
            image: this.fileData.image,
            showDropZone: false,
          }
          // update disbursementFileData
          this.disbursementFileData = disbursementFileData;
          // emit data
          this.sendAction('edit', 'disbursement', disbursementFileData);
          break;
        case 'loanPayment':
          // delete old file on server
          await this.apiSv.deleteFile(this.fileData.fileKey);
          // set loanPayment
          const loanPayment = {
            loanPaymentReceipt: file.filePath,
          }
          // save loanDocument
          await this.apiSv.updateLoanPayment(this.loanPaymentId, loanPayment);
          // set loanPaymentFileData
          const loanPaymentFileData = {
            id: this.fileData.id,
            parentId: null,
            docType: this.fileData.docType,
            file: file.file,
            fileId: this.fileData.id,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: this.fileData.title,
            image: this.fileData.image,
            showDropZone: false,
          }
          // update loanPaymentFileData
          this.loanPaymentFileData = loanPaymentFileData;
          // emit data
          this.sendAction('edit', 'loanPayment', loanPaymentFileData);
          break;
        case 'batchPaymentReceipt':
          // delete old file on server
          await this.apiSv.deleteFile(this.fileData.fileKey);
          // set loanPaymentDisbursement
          const loanPaymentDisbursement = {
            batchPaymentReceiptFile: file.filePath,
            batchPaymentReceiptFileKey: file.fileKey,
          }
          // save batch
          await this.apiSv.updateBatch(this.batchId, loanPaymentDisbursement);
          // set batchPaymentReceiptFileData
          const batchPaymentReceiptFileData = {
            id: this.fileData.id,
            parentId: this.loanId,
            docType: this.fileData.docType,
            file: file.file,
            fileId: this.fileData.id,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: this.fileData.title,
            image: this.fileData.image,
            showDropZone: false,
          }
          // update batchPaymentReceiptFileData
          this.batchPaymentReceiptFileData = batchPaymentReceiptFileData;
          // emit data
          this.sendAction('edit', 'batchPaymentReceipt', batchPaymentReceiptFileData);
          break;
        case 'acceptance':
          // delete old file on server
          await this.apiSv.deleteFile(this.fileData.fileKey);  
          // set loanDocumentAcceptance
          const loanDocumentAcceptance = {
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          await this.apiSv.updateLoanDocument(this.fileData.id, loanDocumentAcceptance);
          // set acceptanceFileData
          const acceptanceFileData = {
            id: this.fileData.id,
            parentId: this.loanId,
            docType: this.fileData.docType,
            file: file.file,
            fileId: this.fileData.id,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: this.fileData.title,
            image: this.fileData.image,
            showDropZone: false,
          }
          // update acceptanceFileData
          this.acceptanceFileData = acceptanceFileData;
          // emit data
          this.sendAction('edit', 'acceptance', acceptanceFileData);
          break;
        case 'companyPromissory':
          // delete old file on server
          await this.apiSv.deleteFile(this.fileData.fileKey);  
          // set loanDocumentCompanyPromissory
          const loanDocumentCompanyPromissory = {
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          await this.apiSv.updateLoanDocument(this.fileData.id, loanDocumentCompanyPromissory);
          // set companyPromissoryFileData
          const companyPromissoryFileData = {
            id: this.fileData.id,
            parentId: this.loanId,
            docType: this.fileData.docType,
            file: file.file,
            fileId: this.fileData.id,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: this.fileData.title,
            image: this.fileData.image,
            showDropZone: false,
          }
          // update companyPromissoryFileData
          this.companyPromissoryFileData = companyPromissoryFileData;
          // emit data
          this.sendAction('edit', 'companyPromissory', companyPromissoryFileData);
          break;
        case 'profile':
          // delete old file on server
          await this.apiSv.deleteFile(this.fileData.fileKey);  
          // set loanDocumentProfile
          const loanDocumentProfile = {
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          await this.apiSv.updateLoanDocument(this.fileData.id, loanDocumentProfile);
          // set profileFileData
          const profileFileData = {
            id: this.fileData.id,
            parentId: this.loanId,
            docType: this.fileData.docType,
            file: file.file,
            fileId: this.fileData.id,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: this.fileData.title,
            image: this.fileData.image,
            showDropZone: false,
          }
          // update profileFileData
          this.profileFileData = profileFileData;
          // emit data
          this.sendAction('edit', 'profile', profileFileData);
          break;
        case 'synthesis':
          // delete old file on server
          await this.apiSv.deleteFile(this.fileData.fileKey);  
          // set loanDocumentSynthesis
          const loanDocumentSynthesis = {
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          await this.apiSv.updateLoanDocument(this.fileData.id, loanDocumentSynthesis);
          // set synthesisFileData
          const synthesisFileData = {
            id: this.fileData.id,
            parentId: this.loanId,
            docType: this.fileData.docType,
            file: file.file,
            fileId: this.fileData.id,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: this.fileData.title,
            image: this.fileData.image,
            showDropZone: false,
          }
          // update synthesisFileData
          this.synthesisFileData = synthesisFileData;
          // emit data
          this.sendAction('edit', 'synthesis', synthesisFileData);
          break;
        case 'detailed':
          // delete old file on server
          await this.apiSv.deleteFile(this.fileData.fileKey);  
          // set loanDocumentDetailed
          const loanDocumentDetailed = {
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          await this.apiSv.updateLoanDocument(this.fileData.id, loanDocumentDetailed);
          // set detailedFileData
          const detailedFileData = {
            id: this.fileData.id,
            parentId: this.loanId,
            docType: this.fileData.docType,
            file: file.file,
            fileId: this.fileData.id,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: this.fileData.title,
            image: this.fileData.image,
            showDropZone: false,
          }
          // update detailedFileData
          this.detailedFileData = detailedFileData;
          // emit data
          this.sendAction('edit', 'detailed', detailedFileData);
          break;
        case 'paymentReceipts':
          // delete old file on server
          await this.apiSv.deleteFile(this.fileData.fileKey);  
          // get paymentReceiptIndex
          const paymentReceiptIndex: any = this.paymentReceiptFiles.findIndex((paymentReceipt: any) => paymentReceipt.id === this.fileData.id);
          // update paymentReceiptFile
          this.paymentReceiptFiles[paymentReceiptIndex].showDropZone = true;
          // get paymentReceipt
          const paymentReceipt = this.paymentReceiptFiles[paymentReceiptIndex];
          // set loanDocumentPaymentReceipt
          const loanDocumentPaymentReceipt = {
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          await this.apiSv.updateLoanDocument(paymentReceipt.id, loanDocumentPaymentReceipt);
          // set paymentReceiptData
          const paymentReceiptData = {
            id: this.fileData.id,
            parentId: this.loanId,
            docType: this.fileData.docType,
            file: file.file,
            fileId: this.fileData.id,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: this.fileData.title,
            image: this.fileData.image,
            showDropZone: false,
          }
          // update paymentReceiptFile
          this.paymentReceiptFiles[paymentReceiptIndex] = paymentReceiptData;
          // emit data
          this.sendAction('edit', 'paymentReceipts', this.paymentReceiptFiles);
          break;
        case 'rut':
          // delete old file on server
          await this.apiSv.deleteFile(this.fileData.fileKey);  
          // set loanDocumentRut
          const loanDocumentRut = {
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          await this.apiSv.updateLoanDocument(this.fileData.id, loanDocumentRut);
          // set rutFileData
          const rutFileData = {
            id: this.fileData.id,
            parentId: this.loanId,
            docType: this.fileData.docType,
            file: file.file,
            fileId: this.fileData.id,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: this.fileData.title,
            image: this.fileData.image,
            showDropZone: false,
          }
          // update rutFileData
          this.rutFileData = rutFileData;
          // emit data
          this.sendAction('edit', 'rut', rutFileData);
          break;
        case 'ccio':
          // delete old file on server
          await this.apiSv.deleteFile(this.fileData.fileKey);  
          // set loanDocumentCCIO
          const loanDocumentCCIO = {
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          await this.apiSv.updateLoanDocument(this.fileData.id, loanDocumentCCIO);
          // set ccioFileData
          const ccioFileData = {
            id: this.fileData.id,
            parentId: this.loanId,
            docType: this.fileData.docType,
            file: file.file,
            fileId: this.fileData.id,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: this.fileData.title,
            image: this.fileData.image,
            showDropZone: false,
          }
          // update ccioFileData
          this.ccioFileData = ccioFileData;
          // emit data
          this.sendAction('edit', 'ccio', ccioFileData);
          break;
        case 'repId':
          // delete old file on server
          await this.apiSv.deleteFile(this.fileData.fileKey);  
          // set loanDocumentRepId
          const loanDocumentRepId = {
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          await this.apiSv.updateLoanDocument(this.fileData.id, loanDocumentRepId);
          // set repIdFileData
          const repIdFileData = {
            id: this.fileData.id,
            parentId: this.loanId,
            docType: this.fileData.docType,
            file: file.file,
            fileId: this.fileData.id,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: this.fileData.title,
            image: this.fileData.image,
            showDropZone: false,
          }
          // update repIdFileData
          this.repIdFileData = repIdFileData;
          // emit data
          this.sendAction('edit', 'repId', repIdFileData);
          break;
        case 'pg':
          // delete old file on server
          await this.apiSv.deleteFile(this.fileData.fileKey);  
          // set loanDocumentPG
          const loanDocumentPG = {
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          await this.apiSv.updateLoanDocument(this.fileData.id, loanDocumentPG);
          // set pgFileData
          const pgFileData = {
            id: this.fileData.id,
            parentId: this.loanId,
            docType: this.fileData.docType,
            file: file.file,
            fileId: this.fileData.id,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: this.fileData.title,
            image: this.fileData.image,
            showDropZone: false,
          }
          // update pgFileData
          this.pgFileData = pgFileData;
          // emit data
          this.sendAction('edit', 'pg', pgFileData);
          break;
        case 'bankExtracts':
          // delete old file on server
          await this.apiSv.deleteFile(this.fileData.fileKey);  
          // set loanDocumentBankExtracts
          const loanDocumentBankExtracts = {
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          await this.apiSv.updateLoanDocument(this.fileData.id, loanDocumentBankExtracts);
          // set bankExtractsFileData
          const bankExtractsFileData = {
            id: this.fileData.id,
            parentId: this.loanId,
            docType: this.fileData.docType,
            file: file.file,
            fileId: this.fileData.id,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: this.fileData.title,
            image: this.fileData.image,
            showDropZone: false,
          }
          // update bankExtractsFileData
          this.bankExtractsFileData = bankExtractsFileData;
          // emit data
          this.sendAction('edit', 'bankExtracts', bankExtractsFileData);
          break;
        case 'pensioner':
          // delete old file on server
          await this.apiSv.deleteFile(this.fileData.fileKey);  
          // set loanDocumentPensioner
          const loanDocumentPensioner = {
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          await this.apiSv.updateLoanDocument(this.fileData.id, loanDocumentPensioner);
          // set pensionerFileData
          const pensionerFileData = {
            id: this.fileData.id,
            parentId: this.loanId,
            docType: this.fileData.docType,
            file: file.file,
            fileId: this.fileData.id,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: this.fileData.title,
            image: this.fileData.image,
            showDropZone: false,
          }
          // update pensionerFileData
          this.pensionerFileData = pensionerFileData;
          // emit data
          this.sendAction('edit', 'pensioner', pensionerFileData);
          break;
        case 'utilities':
          // delete old file on server
          await this.apiSv.deleteFile(this.fileData.fileKey);  
          // set loanDocumentUtilities
          const loanDocumentUtilities = {
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          await this.apiSv.updateLoanDocument(this.fileData.id, loanDocumentUtilities);
          // set utilitiesFileData
          const utilitiesFileData = {
            id: this.fileData.id,
            parentId: this.loanId,
            docType: this.fileData.docType,
            file: file.file,
            fileId: this.fileData.id,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: this.fileData.title,
            image: this.fileData.image,
            showDropZone: false,
          }
          // update utilitiesFileData
          this.utilitiesFileData = utilitiesFileData;
          // emit data
          this.sendAction('edit', 'utilities', utilitiesFileData);
          break;
        case 'otherDocument1':
          // delete old file on server
          await this.apiSv.deleteFile(this.fileData.fileKey);  
          // set loanDocumentOtherDocument1
          const loanDocumentOtherDocument1 = {
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          await this.apiSv.updateLoanDocument(this.fileData.id, loanDocumentOtherDocument1);
          // set otherDocument1FileData
          const otherDocument1FileData = {
            id: this.fileData.id,
            parentId: this.loanId,
            docType: this.fileData.docType,
            file: file.file,
            fileId: this.fileData.id,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: this.fileData.title,
            image: this.fileData.image,
            showDropZone: false,
          }
          // update otherDocument1FileData
          this.otherDocument1FileData = otherDocument1FileData;
          // emit data
          this.sendAction('edit', 'otherDocument1', otherDocument1FileData);
          break;
        case 'otherDocument2':
          // delete old file on server
          await this.apiSv.deleteFile(this.fileData.fileKey);  
          // set loanDocumentOtherDocument2
          const loanDocumentOtherDocument2 = {
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          await this.apiSv.updateLoanDocument(this.fileData.id, loanDocumentOtherDocument2);
          // set otherDocument2FileData
          const otherDocument2FileData = {
            id: this.fileData.id,
            parentId: this.loanId,
            docType: this.fileData.docType,
            file: file.file,
            fileId: this.fileData.id,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: this.fileData.title,
            image: this.fileData.image,
            showDropZone: false,
          }
          // update otherDocument2FileData
          this.otherDocument2FileData = otherDocument2FileData;
          // emit data
          this.sendAction('edit', 'otherDocument2', otherDocument2FileData);
          break;
        case 'otherDocument3':
          // delete old file on server
          await this.apiSv.deleteFile(this.fileData.fileKey);  
          // set loanDocumentOtherDocument3
          const loanDocumentOtherDocument3 = {
            loanDocument: file.filePath,
            fileKey: file.fileKey,
          }
          // save loanDocument
          await this.apiSv.updateLoanDocument(this.fileData.id, loanDocumentOtherDocument3);
          // set otherDocument3FileData
          const otherDocument3FileData = {
            id: this.fileData.id,
            parentId: this.loanId,
            docType: this.fileData.docType,
            file: file.file,
            fileId: this.fileData.id,
            fileType: file.fileType,
            fileName: file.fileName,
            filePath: file.filePath,
            fileKey: file.fileKey,
            fileDate: file.fileDate,
            title: this.fileData.title,
            image: this.fileData.image,
            showDropZone: false,
          }
          // update otherDocument3FileData
          this.otherDocument3FileData = otherDocument3FileData;
          // emit data
          this.sendAction('edit', 'otherDocument3', otherDocument3FileData);
          break;
      }
      // check target and hide dropzone
      switch (target) {
        case 'idFront':
          this.idFrontShowDropZone = false;
          break;
        case 'idBack':
          this.idBackShowDropZone = false;
          break;
        case 'request':
          this.requestShowDropZone = false;
          break;
        case 'bank':
          this.bankShowDropZone = false;
          break;
        case 'labor':
          this.laborShowDropZone = false;
          break;
        case 'promissory':
          this.promissoryShowDropZone = false;
          break;
        case 'instructions':
          this.instructionsShowDropZone = false;
          break;
        case 'authorization':
          this.authorizationShowDropZone = false;
          break;
        case 'disbursement':
          this.disbursementShowDropZone = false;
          break;
        case 'loanPayment':
          this.loanPaymentShowDropZone = false;
          break;
        case 'batchPaymentReceipt':
          this.batchPaymentReceiptShowDropZone = false;
          break;
        case 'acceptance':
          this.acceptanceShowDropZone = false;
          break;
        case 'companyPromissory':
          this.companyPromissoryShowDropZone = false;
          break;
        case 'profile':
          this.profileShowDropZone = false;
          break;
        case 'synthesis':
          this.synthesisShowDropZone = false;
          break;
        case 'detailed':
          this.detailedShowDropZone = false;
          break;
        case 'paymentReceipts':
          // get paymentReceiptIndex
          const paymentReceiptIndex: any = this.paymentReceiptFiles.findIndex((paymentReceipt: any) => paymentReceipt.id === this.fileData.id);  
          // update paymentReceiptFile
          this.paymentReceiptFiles[paymentReceiptIndex].showDropZone = false;
          break;
        case 'rut':
          this.rutShowDropZone = false;
          break;
        case 'ccio':
          this.ccioShowDropZone = false;
          break;
        case 'repId':
          this.repIdShowDropZone = false;
          break;
        case 'pg':
          this.pgShowDropZone = false;
          break;
        case 'bankExtracts':
          this.bankExtractsShowDropZone = false;
          break;
        case 'pensioner':
          this.pensionerShowDropZone = false;
          break;
        case 'utilities':
          this.utilitiesShowDropZone = false;
          break;
        case 'otherDocument1':
          this.otherDocument1ShowDropZone = false;
          break;
        case 'otherDocument2':
          this.otherDocument2ShowDropZone = false;
          break;
        case 'otherDocument3':
          this.otherDocument3ShowDropZone = false;
          break;
      }
      // clear data
      this.fileTarget = null;
      this.fileAction = null;
      // hide loader
      this.loaderEvent.emit(null);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async askDeleteFile(target: string) {
    Swal.fire({
      icon: 'warning',
      title: this.translate.instant('HELPERS.deleteFile'),
      text: this.translate.instant('HELPERS.deleteFileAsk'),
      confirmButtonText: this.translate.instant('HELPERS.yes'),
      showCancelButton: true,
      cancelButtonText: this.translate.instant('HELPERS.no'),
      customClass: {
        cancelButton: 'btn-swal-cancel'
      },
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        // delete file
        await this.deleteFile(target);
      }
    })
  }
  async deleteFile(target: string) {
    try {
      // show loader
      this.loaderEvent.emit(this.translate.instant('HELPERS.deletingFile'));
      // check fileTarget
      switch (target) {
        case 'idFront':
          // delete old file on server
          await this.apiSv.deleteFile(this.idFrontFileData.fileKey);
          // saver userAttachment
          await this.apiSv.removeUserAttachment(this.idFrontFileData.id);
          // set idFrontFileData
          const idFrontFileData = {
            id: 'idFront',
            parentId: this.userId,
            docType: 'idFront',
            file: null,
            fileId: 'idFront',
            fileType: null,
            fileName: null,
            filePath: null,
            fileKey: null,
            fileDate: null,
            title: this.translate.instant('LOAN.loanDocumentUserIdFront'),
            image: 'assets/svg/icon-document-front.svg',
            showDropZone: false,
          }
          // update idFrontFileData
          this.idFrontFileData = idFrontFileData;
          // emit data
          this.sendAction('delete', 'idFront', this.idFrontFileData);
          break;
        case 'idBack':
          // delete old file on server
          await this.apiSv.deleteFile(this.idBackFileData.fileKey);  
          // saver userAttachment
          await this.apiSv.removeUserAttachment(this.idBackFileData.id);
          // set idBackFileData
          const idBackFileData = {
            id: 'idBack',
            parentId: this.userId,
            docType: 'idBack',
            file: null,
            fileId: 'idBack',
            fileType: null,
            fileName: null,
            filePath: null,
            fileKey: null,
            fileDate: null,
            title: this.translate.instant('LOAN.loanDocumentUserIdBack'),
            image: 'assets/svg/icon-document-reverse.svg',
            showDropZone: false,
          }
          // update idBackFileData
          this.idBackFileData = idBackFileData;
          // emit data
          this.sendAction('delete', 'idBack', this.idBackFileData);
          break;
        case 'request':
          // delete old file on server
          await this.apiSv.deleteFile(this.requestFileData.fileKey);
          // save loanDocument
          await this.apiSv.removeLoanDocument(this.requestFileData.id);
          // set requestFileData
          const requestFileData = {
            id: 'request',
            parentId: this.loanId,
            docType: 'request',
            file: null,
            fileId: 'request',
            fileType: null,
            fileName: null,
            filePath: null,
            fileKey: null,
            fileDate: null,
            title: this.translate.instant('LOAN.loanDocumentRequest'),
            image: 'assets/svg/icon-document-request.svg',
            showDropZone: false,
          }
          // update requestFileData
          this.requestFileData = requestFileData;
          // emit data
          this.sendAction('delete', 'request', this.requestFileData);
          break;
        case 'bank':
          // delete old file on server
          await this.apiSv.deleteFile(this.bankFileData.fileKey);
          // set userBankAccountData
          const userBankAccountData: UserBankAccountInterface = {
            certificateFile: null,
            certificateFileKey: null
          }
          // save bank account on API
          await this.apiSv.updateUserBankAccount(this.bankFileData.id, userBankAccountData);
          // set bankFileData
          const bankFileData = {
            id: this.fileData.id,
            parentId: this.loanId,
            docType: this.fileData.docType,
            file: null,
            fileId: this.fileData.id,
            fileType: null,
            fileName: null,
            filePath: null,
            fileKey: null,
            fileDate: null,
            title: this.translate.instant('LOAN.loanDocumentBank'),
            image: 'assets/svg/icon-document-bank.svg',
            showDropZone: false,
          }
          // update bankFileData
          this.bankFileData = bankFileData;
          // emit data
          this.sendAction('delete', 'bank', this.bankFileData);
          break;
        case 'labor':
          // delete old file on server
          await this.apiSv.deleteFile(this.laborFileData.fileKey);
          // save loanDocument
          await this.apiSv.removeLoanDocument(this.laborFileData.id);
          // set laborFileData
          const laborFileData = {
            id: 'labor',
            parentId: this.loanId,
            docType: 'labor',
            file: null,
            fileId: 'labor',
            fileType: null,
            fileName: null,
            filePath: null,
            fileKey: null,
            fileDate: null,
            title: this.translate.instant('LOAN.loanDocumentLabor'),
            image: 'assets/svg/icon-document-labor.svg',
            showDropZone: false,
          }
          // update laborFileData
          this.laborFileData = laborFileData;
          // emit data
          this.sendAction('delete', 'labor', this.laborFileData);
          break;
        case 'promissory':
          // delete old file on server
          await this.apiSv.deleteFile(this.promissoryFileData.fileKey);
          // save loanDocument
          await this.apiSv.removeLoanDocument(this.promissoryFileData.id);
          // set promissoryFileData
          const promissoryFileData = {
            id: 'promissory',
            parentId: this.loanId,
            docType: 'promissory',
            file: null,
            fileId: 'promissory',
            fileType: null,
            fileName: null,
            filePath: null,
            fileKey: null,
            fileDate: null,
            title: this.translate.instant('LOAN.loanDocumentPromissory'),
            image: 'assets/svg/icon-document-promissory.svg',
            showDropZone: false,
          }
          // update promissoryFileData
          this.promissoryFileData = promissoryFileData;
          // emit data
          this.sendAction('delete', 'promissory', this.promissoryFileData);
          break;
        case 'instructions':
          // delete old file on server
          await this.apiSv.deleteFile(this.instructionsFileData.fileKey);
          // save loanDocument
          await this.apiSv.removeLoanDocument(this.instructionsFileData.id);
          // set instructionsFileData
          const instructionsFileData = {
            id: 'instructions',
            parentId: this.loanId,
            docType: 'instructions',
            file: null,
            fileId: 'instructions',
            fileType: null,
            fileName: null,
            filePath: null,
            fileKey: null,
            fileDate: null,
            title: this.translate.instant('LOAN.loanDocumentInstructions'),
            image: 'assets/svg/icon-document-instructions.svg',
            showDropZone: false,
          }
          // update instructionsFileData
          this.instructionsFileData = instructionsFileData;
          // emit data
          this.sendAction('delete', 'instructions', this.instructionsFileData);
          break;
        case 'authorization':
          // delete old file on server
          await this.apiSv.deleteFile(this.authorizationFileData.fileKey);
          // save loanDocument
          await this.apiSv.removeLoanDocument(this.authorizationFileData.id);
          // set authorizationFileData
          const authorizationFileData = {
            id: 'authorization',
            parentId: this.loanId,
            docType: 'authorization',
            file: null,
            fileId: 'authorization',
            fileType: null,
            fileName: null,
            filePath: null,
            fileKey: null,
            fileDate: null,
            title: this.translate.instant('LOAN.loanDocumentAuthorization'),
            image: 'assets/svg/icon-document-authorization.svg',
            showDropZone: false,
          }
          // update authorizationFileData
          this.authorizationFileData = authorizationFileData;
          // emit data
          this.sendAction('delete', 'authorization', this.authorizationFileData);
          break;
        case 'disbursement':
          // delete old file on server
          await this.apiSv.deleteFile(this.disbursementFileData.fileKey);
          // save loanDocument
          await this.apiSv.removeLoanDocument(this.disbursementFileData.id);
          // set disbursementFileData
          const disbursementFileData = {
            id: 'disbursement',
            parentId: this.loanId,
            docType: 'disbursement',
            file: null,
            fileId: 'disbursement',
            fileType: null,
            fileName: null,
            filePath: null,
            fileKey: null,
            fileDate: null,
            title: this.translate.instant('LOAN.loanDocumentDisbursement'),
            image: 'assets/svg/icon-document-disbursement.svg',
            showDropZone: false,
          }
          // update disbursementFileData
          this.disbursementFileData = disbursementFileData;
          // emit data
          this.sendAction('delete', 'disbursement', this.disbursementFileData);
          break;
        case 'loanPayment':
          // delete old file on server
          await this.apiSv.deleteFile(this.loanPaymentFileData.fileKey);
          // set loanPayment
          const loanPayment = {
            loanPaymentReceipt: null,
          }
          // save loanDocument
          await this.apiSv.updateLoanPayment(this.loanPaymentId, loanPayment);
          // set loanPaymentFileData
          const loanPaymentFileData = {
            id: 'loanPayment',
            parentId: this.loanId,
            docType: 'loanPayment',
            file: null,
            fileId: 'loanPayment',
            fileType: null,
            fileName: null,
            filePath: null,
            fileKey: null,
            fileDate: null,
            title: this.translate.instant('LOAN.loanDocumentDisbursement'),
            image: 'assets/svg/icon-document-payment-receipt.svg',
            showDropZone: false,
          }
          // update loanPaymentFileData
          this.loanPaymentFileData = loanPaymentFileData;
          // emit data
          this.sendAction('delete', 'loanPayment', this.loanPaymentFileData);
          break;
        case 'batchPaymentReceipt':
          // delete old file on server
          await this.apiSv.deleteFile(this.batchPaymentReceiptFileData.fileKey);
          // set loanPaymentDisbursement
          const loanPaymentDisbursement = {
            batchPaymentReceiptFile: null,
            batchPaymentReceiptFileKey: null,
          }
          // save batch
          await this.apiSv.updateBatch(this.batchId, loanPaymentDisbursement);
          // set batchPaymentReceiptFileData
          const batchPaymentReceiptFileData = {
            id: 'batchPaymentReceipt',
            parentId: this.loanId,
            docType: 'batchPaymentReceipt',
            file: null,
            fileId: 'batchPaymentReceipt',
            fileType: null,
            fileName: null,
            filePath: null,
            fileKey: null,
            fileDate: null,
            title: this.translate.instant('LOAN.loanDocumentDisbursement'),
            image: 'assets/svg/icon-document-payment-receipt.svg',
            showDropZone: false,
          }
          // update batchPaymentReceiptFileData
          this.batchPaymentReceiptFileData = batchPaymentReceiptFileData;
          // emit data
          this.sendAction('delete', 'batchPaymentReceipt', this.batchPaymentReceiptFileData);
          break;
        case 'acceptance':
          // delete old file on server
          await this.apiSv.deleteFile(this.acceptanceFileData.fileKey);
          // save loanDocument
          await this.apiSv.removeLoanDocument(this.acceptanceFileData.id);
          // set acceptanceFileData
          const acceptanceFileData = {
            id: 'acceptance',
            parentId: this.loanId,
            docType: 'acceptance',
            file: null,
            fileId: 'acceptance',
            fileType: null,
            fileName: null,
            filePath: null,
            fileKey: null,
            fileDate: null,
            title: this.translate.instant('LOAN.loanDocumentAcceptance'),
            image: 'assets/svg/icon-document-acceptance.svg',
            showDropZone: false,
          }
          // update acceptanceFileData
          this.acceptanceFileData = acceptanceFileData;
          // emit data
          this.sendAction('delete', 'acceptance', this.acceptanceFileData);
          break;
        case 'companyPromissory':
          // delete old file on server
          await this.apiSv.deleteFile(this.companyPromissoryFileData.fileKey);
          // save loanDocument
          await this.apiSv.removeLoanDocument(this.companyPromissoryFileData.id);
          // set companyPromissoryFileData
          const companyPromissoryFileData = {
            id: 'companyPromissory',
            parentId: this.loanId,
            docType: 'companyPromissory',
            file: null,
            fileId: 'companyPromissory',
            fileType: null,
            fileName: null,
            filePath: null,
            fileKey: null,
            fileDate: null,
            title: this.translate.instant('LOAN.loanDocumentCompanyPromissory'),
            image: 'assets/svg/icon-document-company-promissory.svg',
            showDropZone: false,
          }
          // update companyPromissoryFileData
          this.companyPromissoryFileData = companyPromissoryFileData;
          // emit data
          this.sendAction('delete', 'companyPromissory', this.companyPromissoryFileData);
          break;
        case 'profile':
          // delete old file on server
          await this.apiSv.deleteFile(this.profileFileData.fileKey);
          // save loanDocument
          await this.apiSv.removeLoanDocument(this.profileFileData.id);
          // set profileFileData
          const profileFileData = {
            id: 'profile',
            parentId: this.loanId,
            docType: 'profile',
            file: null,
            fileId: 'profile',
            fileType: null,
            fileName: null,
            filePath: null,
            fileKey: null,
            fileDate: null,
            title: this.translate.instant('LOAN.loanDocumentProfile'),
            image: 'assets/svg/icon-document-profile.svg',
            showDropZone: false,
          }
          // update profileFileData
          this.profileFileData = profileFileData;
          // emit data
          this.sendAction('delete', 'profile', this.profileFileData);
          break;
        case 'synthesis':
          // delete old file on server
          await this.apiSv.deleteFile(this.synthesisFileData.fileKey);
          // save loanDocument
          await this.apiSv.removeLoanDocument(this.synthesisFileData.id);
          // set synthesisFileData
          const synthesisFileData = {
            id: 'synthesis',
            parentId: this.loanId,
            docType: 'synthesis',
            file: null,
            fileId: 'synthesis',
            fileType: null,
            fileName: null,
            filePath: null,
            fileKey: null,
            fileDate: null,
            title: this.translate.instant('LOAN.loanDocumentSynthesis'),
            image: 'assets/svg/icon-document-synthesis.svg',
            showDropZone: false,
          }
          // update synthesisFileData
          this.synthesisFileData = synthesisFileData;
          // emit data
          this.sendAction('delete', 'synthesis', this.synthesisFileData);
          break;
        case 'detailed':
          // delete old file on server
          await this.apiSv.deleteFile(this.detailedFileData.fileKey);
          // save loanDocument
          await this.apiSv.removeLoanDocument(this.detailedFileData.id);
          // set detailedFileData
          const detailedFileData = {
            id: 'detailed',
            parentId: this.loanId,
            docType: 'detailed',
            file: null,
            fileId: 'detailed',
            fileType: null,
            fileName: null,
            filePath: null,
            fileKey: null,
            fileDate: null,
            title: this.translate.instant('LOAN.loanDocumentDetailed'),
            image: 'assets/svg/icon-document-payment-receipt.svg',
            showDropZone: false,
          }
          // update detailedFileData
          this.detailedFileData = detailedFileData;
          // emit data
          this.sendAction('delete', 'detailed', this.detailedFileData);
          break;
        case 'paymentReceipts':
          // get paymentReceiptIndex
          const paymentReceiptIndex: any = this.paymentReceiptFiles.findIndex((paymentReceipt: any) => paymentReceipt.paymentReceiptFileId === this.fileData.id); 
          // get paymentReceipt
          const paymentReceipt = this.paymentReceiptFiles[paymentReceiptIndex];
          // delete old file on server
          await this.apiSv.deleteFile(paymentReceipt.paymentReceiptFileKey);
          // save loanDocument
          await this.apiSv.removeLoanDocument(paymentReceipt.paymentReceiptFileId);
          // set paymentReceiptData
          const paymentReceiptData = {
            id: this.fileData.id,
            parentId: this.loanId,
            docType: 'paymentReceipts',
            file: null,
            fileId: this.fileData.id,
            fileType: null,
            fileName: null,
            filePath: null,
            fileKey: null,
            fileDate: null,
            title: paymentReceipt.title,
            image: paymentReceipt.image,
            showDropZone: false,
          }
          // update paymentReceiptFile
          this.paymentReceiptFiles[paymentReceiptIndex] = paymentReceiptData;
          // emit data
          this.sendAction('delete', 'paymentReceipts', this.paymentReceiptFiles);
          break;
        case 'rut':
          // delete old file on server
          await this.apiSv.deleteFile(this.rutFileData.fileKey);
          // save loanDocument
          await this.apiSv.removeLoanDocument(this.rutFileData.id);
          // set rutFileData
          const rutFileData = {
            id: 'rut',
            parentId: this.loanId,
            docType: 'rut',
            file: null,
            fileId: 'rut',
            fileType: null,
            fileName: null,
            filePath: null,
            fileKey: null,
            fileDate: null,
            title: this.translate.instant('LOAN.loanDocumentRut'),
            image: 'assets/svg/icon-document-rut.svg',
            showDropZone: false,
          }
          // update rutFileData
          this.rutFileData = rutFileData;
          // emit data
          this.sendAction('delete', 'rut', this.rutFileData);
          break;
        case 'ccio':
          // delete old file on server
          await this.apiSv.deleteFile(this.ccioFileData.fileKey);
          // save loanDocument
          await this.apiSv.removeLoanDocument(this.ccioFileData.id);
          // set ccioFileData
          const ccioFileData = {
            id: 'ccio',
            parentId: this.loanId,
            docType: 'ccio',
            file: null,
            fileId: 'ccio',
            fileType: null,
            fileName: null,
            filePath: null,
            fileKey: null,
            fileDate: null,
            title: this.translate.instant('LOAN.loanDocumentCCIO'),
            image: 'assets/svg/icon-document-ccio.svg',
            showDropZone: false,
          }
          // update ccioFileData
          this.ccioFileData = ccioFileData;
          // emit data
          this.sendAction('delete', 'ccio', this.ccioFileData);
          break;
        case 'repId':
          // delete old file on server
          await this.apiSv.deleteFile(this.repIdFileData.fileKey);
          // save loanDocument
          await this.apiSv.removeLoanDocument(this.repIdFileData.id);
          // set repIdFileData
          const repIdFileData = {
            id: 'repId',
            parentId: this.loanId,
            docType: 'repId',
            file: null,
            fileId: 'repId',
            fileType: null,
            fileName: null,
            filePath: null,
            fileKey: null,
            fileDate: null,
            title: this.translate.instant('LOAN.loanDocumentRepId'),
            image: 'assets/svg/icon-document-rep-id.svg',
            showDropZone: false,
          }
          // update repIdFileData
          this.repIdFileData = repIdFileData;
          // emit data
          this.sendAction('delete', 'repId', this.repIdFileData);
          break;
        case 'pg':
          // delete old file on server
          await this.apiSv.deleteFile(this.pgFileData.fileKey);
          // save loanDocument
          await this.apiSv.removeLoanDocument(this.pgFileData.id);
          // set pgFileData
          const pgFileData = {
            id: 'pg',
            parentId: this.loanId,
            docType: 'pg',
            file: null,
            fileId: 'pg',
            fileType: null,
            fileName: null,
            filePath: null,
            fileKey: null,
            fileDate: null,
            title: this.translate.instant('LOAN.loanDocumentPG'),
            image: 'assets/svg/icon-document-pg.svg',
            showDropZone: false,
          }
          // update pgFileData
          this.pgFileData = pgFileData;
          // emit data
          this.sendAction('delete', 'pg', this.pgFileData);
          break;
        case 'bankExtracts':
          // delete old file on server
          await this.apiSv.deleteFile(this.bankExtractsFileData.fileKey);
          // save loanDocument
          await this.apiSv.removeLoanDocument(this.bankExtractsFileData.id);
          // set bankExtractsFileData
          const bankExtractsFileData = {
            id: 'bankExtracts',
            parentId: this.loanId,
            docType: 'bankExtracts',
            file: null,
            fileId: 'bankExtracts',
            fileType: null,
            fileName: null,
            filePath: null,
            fileKey: null,
            fileDate: null,
            title: this.translate.instant('LOAN.loanDocumentBankExtracts'),
            image: 'assets/svg/icon-document-bank-extracts.svg',
            showDropZone: false,
          }
          // update bankExtractsFileData
          this.bankExtractsFileData = bankExtractsFileData;
          // emit data
          this.sendAction('delete', 'bankExtracts', this.bankExtractsFileData);
          break;
        case 'pensioner':
          // delete old file on server
          await this.apiSv.deleteFile(this.pensionerFileData.fileKey);
          // save loanDocument
          await this.apiSv.removeLoanDocument(this.pensionerFileData.id);
          // set pensionerFileData
          const pensionerFileData = {
            id: 'pensioner',
            parentId: this.loanId,
            docType: 'pensioner',
            file: null,
            fileId: 'pensioner',
            fileType: null,
            fileName: null,
            filePath: null,
            fileKey: null,
            fileDate: null,
            title: this.translate.instant('LOAN.loanDocumentPensioner'),
            image: 'assets/svg/icon-document-pensioner.svg',
            showDropZone: false,
          }
          // update pensionerFileData
          this.pensionerFileData = pensionerFileData;
          // emit data
          this.sendAction('delete', 'pensioner', this.pensionerFileData);
          break;
        case 'utilities':
          // delete old file on server
          await this.apiSv.deleteFile(this.utilitiesFileData.fileKey);
          // save loanDocument
          await this.apiSv.removeLoanDocument(this.utilitiesFileData.id);
          // set utilitiesFileData
          const utilitiesFileData = {
            id: 'utilities',
            parentId: this.loanId,
            docType: 'utilities',
            file: null,
            fileId: 'utilities',
            fileType: null,
            fileName: null,
            filePath: null,
            fileKey: null,
            fileDate: null,
            title: this.translate.instant('LOAN.loanDocumentUtilities'),
            image: 'assets/svg/icon-document-utilities.svg',
            showDropZone: false,
          }
          // update utilitiesFileData
          this.utilitiesFileData = utilitiesFileData;
          // emit data
          this.sendAction('delete', 'utilities', this.utilitiesFileData);
          break;
        case 'otherDocument1':
          // delete old file on server
          await this.apiSv.deleteFile(this.otherDocument1FileData.fileKey);
          // save loanDocument
          await this.apiSv.removeLoanDocument(this.otherDocument1FileData.id);
          // set otherDocument1FileData
          const otherDocument1FileData = {
            id: 'otherDocument1',
            parentId: this.loanId,
            docType: 'otherDocument1',
            file: null,
            fileId: 'otherDocument1',
            fileType: null,
            fileName: null,
            filePath: null,
            fileKey: null,
            fileDate: null,
            title: this.translate.instant('LOAN.loanDocumentOtherDocument') + '-1',
            image: 'assets/svg/icon-document-other-documents.svg',
            showDropZone: false,
          }
          // update otherDocument1FileData
          this.otherDocument1FileData = otherDocument1FileData;
          // emit data
          this.sendAction('delete', 'otherDocument1', this.otherDocument1FileData);
          break;
        case 'otherDocument2':
          // delete old file on server
          await this.apiSv.deleteFile(this.otherDocument2FileData.fileKey);
          // save loanDocument
          await this.apiSv.removeLoanDocument(this.otherDocument2FileData.id);
          // set otherDocument2FileData
          const otherDocument2FileData = {
            id: 'otherDocument2',
            parentId: this.loanId,
            docType: 'otherDocument2',
            file: null,
            fileId: 'otherDocument2',
            fileType: null,
            fileName: null,
            filePath: null,
            fileKey: null,
            fileDate: null,
            title: this.translate.instant('LOAN.loanDocumentOtherDocument') + '-2',
            image: 'assets/svg/icon-document-other-documents.svg',
            showDropZone: false,
          }
          // update otherDocument2FileData
          this.otherDocument2FileData = otherDocument2FileData;
          // emit data
          this.sendAction('delete', 'otherDocument2', this.otherDocument2FileData);
          break;
        case 'otherDocument3':
          // delete old file on server
          await this.apiSv.deleteFile(this.otherDocument3FileData.fileKey);
          // save loanDocument
          await this.apiSv.removeLoanDocument(this.otherDocument3FileData.id);
          // set otherDocument3FileData
          const otherDocument3FileData = {
            id: 'otherDocument3',
            parentId: this.loanId,
            docType: 'otherDocument3',
            file: null,
            fileId: 'otherDocument3',
            fileType: null,
            fileName: null,
            filePath: null,
            fileKey: null,
            fileDate: null,
            title: this.translate.instant('LOAN.loanDocumentOtherDocument') + '-3',
            image: 'assets/svg/icon-document-other-documents.svg',
            showDropZone: false,
          }
          // update otherDocument3FileData
          this.otherDocument3FileData = otherDocument3FileData;
          // emit data
          this.sendAction('delete', 'otherDocument3', this.otherDocument3FileData);
          break;
      }
      // clear data
      this.fileTarget = null;
      this.fileAction = null;
      // hide loader
      this.loaderEvent.emit(null);
    } catch (error) {
      console.log('error', error);
      // hide loader
      this.loaderEvent.emit(null);
      // handle error
      this.filterSv.handleError(error);
    }
  }
  async cancelFile() {
    // check fileTarget and hide dropzone
    switch (this.fileTarget) {
      case 'idFront':
        this.idFrontShowDropZone = false;
        break;
      case 'idBack':
        this.idBackShowDropZone = false;
        break;
      case 'request':
        this.requestShowDropZone = false;
        break;
      case 'bank':
        this.bankShowDropZone = false;
        break;
      case 'labor':
        this.laborShowDropZone = false;
        break;
      case 'promissory':
        this.promissoryShowDropZone = false;
        break;
      case 'instructions':
        this.instructionsShowDropZone = false;
        break;
      case 'authorization':
        this.authorizationShowDropZone = false;
        break;
      case 'disbursement':
        this.disbursementShowDropZone = false;
        break;
      case 'loanPayment':
        this.loanPaymentShowDropZone = false;
        break;
      case 'batchPaymentReceipt':
        this.batchPaymentReceiptShowDropZone = false;
        break;
      case 'acceptance':
        this.acceptanceShowDropZone = false;
        break;
      case 'companyPromissory':
        this.companyPromissoryShowDropZone = false;
        break;
      case 'profile':
        this.profileShowDropZone = false;
        break;
      case 'synthesis':
        this.synthesisShowDropZone = false;
        break;
      case 'detailed':
        this.detailedShowDropZone = false;
        break;
      case 'paymentReceipts':
        // get paymentReceiptIndex
        const paymentReceiptIndex: any = this.paymentReceiptFiles.findIndex((paymentReceipt: any) => paymentReceipt.id === this.fileData.id);  
        // update paymentReceiptFile
        this.paymentReceiptFiles[paymentReceiptIndex].showDropZone = false;
        break;
      case 'rut':
        this.rutShowDropZone = false;
        break;
      case 'ccio':
        this.ccioShowDropZone = false;
        break;
      case 'repId':
        this.repIdShowDropZone = false;
        break;
      case 'pg':
        this.pgShowDropZone = false;
        break;
      case 'bankExtracts':
        this.bankExtractsShowDropZone = false;
        break;
      case 'pensioner':
        this.pensionerShowDropZone = false;
        break;
      case 'utilities':
        this.utilitiesShowDropZone = false;
        break;
      case 'otherDocument1':
        this.otherDocument1ShowDropZone = false;
        break;
      case 'otherDocument2':
        this.otherDocument2ShowDropZone = false;
        break;
      case 'otherDocument3':
        this.otherDocument3ShowDropZone = false;
        break;
    }
    // clear data
    this.fileTarget = null;
    this.fileAction = null;
  }
  // media
  async showPreview(modal: any, title: string, uri: string) {
    // update modal data
    this.fileModalTitle = this.translate.instant(title);
    this.fileModalImage = uri;
    // open modal
    this.modalService.open(modal, { backdrop: 'static' });
  }
  async closePreview() {
    // clear modal data
    this.fileModalTitle = null;
    this.fileModalImage = null;
    this.fileData = null;
    this.fileTarget = null;
    this.fileAction = null;
    // hide modal
    this.modalService.dismissAll();
  }
  async openFile(uri: string) {
    console.log('uri', uri);
    // open external link file
    this.utilitiesSv.openPDF(uri);
    // clear modal data
    this.fileModalTitle = null;
    this.fileModalImage = null;
    this.fileData = null;
    this.fileTarget = null;
    this.fileAction = null;
  }
  // actions
  async sendAction(action: string, target: any, data: any) {
    const emitData = {
      action,
      target,
      data
    }
    // emit data
    this.actionEvent.emit(emitData);
  }
}
