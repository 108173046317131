<!-- table -->
<div class="table-responsive">
  <table class="table table-hover">
    <!-- table header -->
    <thead>
      <tr>
        <th class="col-9 align-middle" scope="col"><p class="table-header-title">{{ 'POSITION.formLabels.positionName' | translate }}</p></th>
        <th class="col-2 align-middle text-center" scope="col"><p class="table-header-title">{{ 'STATUSES.status' | translate }}</p></th>
        <th class="col-1 align-middle" scope="col"></th>
      </tr>
    </thead>
    <!-- table body -->
    <tbody>
      <tr *ngFor="let position of positions">
        <td class="col-9 align-middle" scope="col">{{ position.positionName }}</td>
        <td class="col-2 align-middle text-center">
          <p class="table-status-txt">
            <span class="status-sended" *ngIf="position.positionStatus === 'pendingForReview'">{{ 'STATUSES.pendingReview' | translate }}</span>
            <span class="status-approved" *ngIf="position.positionStatus === 'active'">{{ 'STATUSES.active' | translate }}</span>
            <span class="status-rejected" *ngIf="position.positionStatus === 'unactive'">{{ 'STATUSES.unactive' | translate }}</span>
          </p>
        </td>
        <td class="col-1 align-middle">
          <a class="btn btn-dark btn-xs btn-icon-only" (click)="editPosition(position)">
            <app-feather-icons [icon]="'edit-3'"></app-feather-icons>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>