<!-- form -->
<form class="theme-form" [formGroup]="mainFrm">
  <!-- header -->
  <div class="row">
    <div class="col">
      <h4>{{ 'LOGIN.signIn' | translate }}</h4>
      <p>{{ 'LOGIN.enterData' | translate }}</p>
    </div>
  </div>
  <!-- data -->
  <div class="row">
    <div class="col">

    </div>
  </div>
</form>
