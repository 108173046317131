<!-- sidbar header -->
<div class="logo-wrapper">
  <a routerLink='/'>
    <img class="img-fluid for-light" [src]="mainUri + '/wp-content/uploads/logos/logo-small-svg.svg'" alt="CrediPremium">
    <img class="img-fluid for-dark" [src]="mainUri + '/wp-content/uploads/logos/logo-small-white-white-svg.svg'" alt="CrediPremium">
  </a>
  <div class="back-btn" (click)="sidebarToggle()">
    <i class="fa fa-angle-left"></i>
  </div>
  <div class="toggle-sidebar" (click)="sidebarToggle()">
    <i data-feather="menu" class="status_toggle middle sidebar-toggle"></i>
  </div>
</div>
<!-- logo -->
<div class="logo-icon-wrapper">
  <a href="javascript:void(0)">
    <img class="img-fluid for-light logo" [src]="mainUri + '/wp-content/uploads/logos/logo-small-svg.svg'" alt="CrediPremium">
    <img class="img-fluid for-dark logo" [src]="mainUri + '/wp-content/uploads/logos/logo-small-white-white-svg.svg'" alt="CrediPremium">
    <img class="img-fluid for-light icon" [src]="mainUri + '/wp-content/uploads/logos/icon-svg-orange.svg'" alt="CrediPremium">
    <img class="img-fluid for-dark icon" [src]="mainUri + '/wp-content/uploads/logos/icon-svg-orange-white.svg'" alt="CrediPremium">
  </a>
</div>
<!-- sidebar nav -->
<nav class="sidebar-main">
  <div class="left-arrow" id="left-arrow" [class.d-none]="leftArrowNone" (click)="scrollToLeft()">
    <app-feather-icons [icon]="'arrow-left'"></app-feather-icons>
  </div>
  <!-- version -->
  <div class="app-version">
    <p class="long"><small>{{ 'HELPERS.version' | translate }}&nbsp;{{ version }}</small></p>
    <p class="short"><small>V.&nbsp;{{ version }}</small></p>
  </div>
  <!-- menu -->
  <div id="sidebar-menu"
    [ngStyle]="{ marginLeft : this.layout.config.settings.layout == 'Rome' || 'Singapore' || 'Barcelona' ? margin + 'px' : '0px'}">
    <ul class="sidebar-links custom-scrollbar">
      <!-- back btn -->
      <li class="back-btn">
        <a href="javascript:void(0)">
          <img class="img-fluid for-light" [src]="mainUri + '/wp-content/uploads/logos/logo-small-svg.svg'" alt="CrediPremium">
          <img class="img-fluid for-dark" [src]="mainUri + '/wp-content/uploads/logos/logo-small-white-orange-svg.svg'" alt="CrediPremium">
        </a>
        <div class="mobile-back text-end" (click)="sidebarToggle()">
          <span>{{ 'HELPERS.back' | translate }}</span>
          <i class="fa fa-angle-right ps-2" aria-hidden="true"></i>
        </div>
      </li>
      <!-- company -->
      <li *ngIf="companyName !== null" class="sidebar-main-title company-info-container ng-star-inserted">
        <div class="ng-star-inserted company-info">
          <h6 class="lan-1 company-name">{{ companyName }}</h6>
          <p *ngIf="role === 'support'" class="lan-2">{{ 'USER.formLabels.roleSupport' | translate }}</p>
          <p *ngIf="role === 'administrator'" class="lan-2">{{ 'USER.formLabels.roleAdministrator' | translate }}</p>
          <p *ngIf="role === 'board'" class="lan-2">{{ 'USER.formLabels.roleBoard' | translate }}</p>
          <p *ngIf="role === 'treasure'" class="lan-2">{{ 'USER.formLabels.roleTreasure' | translate }}</p>
          <p *ngIf="role === 'businessAgent'" class="lan-2">{{ 'USER.formLabels.roleBusinessAgent' | translate }}</p>
          <p *ngIf="role === 'ceo'" class="lan-2">{{ 'USER.formLabels.roleCEO' | translate }}</p>
          <p *ngIf="role === 'manager'" class="lan-2">{{ 'USER.formLabels.roleManager' | translate }}</p>
          <p *ngIf="role === 'auxiliar'" class="lan-2">{{ 'USER.formLabels.roleAuxiliar' | translate }}</p>
          <div class="sidebar-logo-company-container">
            <div class="sidebar-logo-company">
              <img class="img-fluid logo-company" [src]="companyLogo" onError="src = 'https://credipremium.co/wp-content/uploads/logos/icon-svg-orange.svg'" [alt]="companyName">
            </div>
          </div>
        </div>
      </li>
      <!-- menus -->
      <li [class]="menuItem.headTitle1 ? 'sidebar-main-title' : 'sidebar-list'" 
      *ngFor="let menuItem of menuItems" [ngClass]="{active: menuItem.active}">
        <!-- title -->
        <div *ngIf="menuItem.headTitle1">
          <!-- {{ menuItem.headTitle1 }} -->
          <h6 class="lan-1">{{ menuItem.headTitle1 | translate }}</h6>
          <p class="lan-2">{{ menuItem.headTitle2 | translate }}</p>
        </div>
        <!-- badge -->
        <label class="badge main-badge" [ngClass]="{ 'badge-light-primary': !menuItem.active, 'badge-dark-primary': menuItem.active }" *ngIf="menuItem.badgesCount">{{ menuItem.badgeValue }}</label>
        <!-- Sub -->
        <a href="javascript:void(0)" class="sidebar-link sidebar-title" 
            [class.link-nav]="!menuItem.children"
            [ngClass]="{active: menuItem.active}"
            *ngIf="menuItem.type === 'sub'" (click)="toggleNavActive(menuItem)">
          <!-- icon  -->
          <!-- <i data-feather={{ menuItem.icon }} ></i> -->
          <app-feather-icons [icon]="menuItem.icon"></app-feather-icons>
          <!-- title -->
          <span>{{ menuItem.title | translate }}</span>
          <!-- chevron -->
          <div class="according-menu">
            <i class="fa fa-angle-{{ menuItem.active ? 'down' : 'right' }} pull-right" *ngIf="menuItem.children"></i>
          </div>
        </a>
        <!-- Link -->
        <a (click)="goToPage(menuItem.path)" class="sidebar-link sidebar-title"
          [class.link-nav]="!menuItem.children"
          [ngClass]="{active: menuItem.active}"
          *ngIf="menuItem.type === 'link'">
          <app-feather-icons [icon]="menuItem.icon"></app-feather-icons><span>{{ menuItem.title | translate }}</span>
          <div class="according-menu">
            <i class="fa fa-angle-{{ menuItem.active ? 'down' : 'right' }} pull-right" *ngIf="menuItem.children"></i>
          </div>
        </a>
        <!-- External Link -->
        <a href="{{ !menuItem.type ? null : menuItem.path }}" class="sidebar-link sidebar-title"
          [class.link-nav]="!menuItem.children" 
          [ngClass]="{active: menuItem.active}"
          *ngIf="menuItem.type === 'extLink'">
          <app-feather-icons [icon]="menuItem.icon"></app-feather-icons><span>{{ menuItem.title | translate }}</span>
          <div class="according-menu">
            <i class="fa fa-angle-{{ menuItem.active ? 'down' : 'right' }} pull-right" *ngIf="menuItem.children"></i>
          </div>
        </a>
        <!-- External Tab Link -->
        <a href="{{ !menuItem.type ? null : menuItem.path }}" target="_blank" class="sidebar-link sidebar-title"
            [class.link-nav]="!menuItem.children"  
            [ngClass]="{active: menuItem.active}" 
            *ngIf="menuItem.type === 'extTabLink'">
          <app-feather-icons [icon]="menuItem.icon"></app-feather-icons><span>{{ menuItem.title }}</span>
          <div class="according-menu">
            <i class="fa fa-angle-{{ menuItem.active ? 'down' : 'right' }} pull-right" *ngIf="menuItem.children"></i>
          </div>
        </a>
        <!-- 2nd Level Menu -->
        <ul class="sidebar-submenu" [ngClass]="{active: menuItem.active}"
          [ngClass]="{'menu-open': menuItem.active, 'menu-close': !menuItem.active }" *ngIf="menuItem.children"
          [style.display]="menuItem.active ? 'block' : 'none'">
          <li *ngFor="let childrenItem of menuItem.children" [ngClass]="{active: childrenItem.active}">
            <!-- badge -->
            <label class="badge badge-sub" [ngClass]="{ 'badge-light-primary': !childrenItem.active, 'badge-dark-primary': childrenItem.active }" *ngIf="isFinancial && childrenItem.badgesCount">{{ childrenItem.badgeValue }}</label>
            <!-- Sub -->
            <a class="submenu-title" href="javascript:void(0)" *ngIf="childrenItem.type === 'sub'" (click)="toggleNavActive(childrenItem)">
              <!-- text -->
              <span> {{ childrenItem.title | translate }}</span>
              <!-- children -->
              <div class="according-menu">
                <i class="fa fa-angle-{{ childrenItem.active ? 'down' : 'right' }} pull-right" *ngIf="childrenItem.children"></i>
              </div>
            </a>
            <!-- Link -->
            <a class="submenu-title" (click)="goToPage(childrenItem.path)" *ngIf="childrenItem.type === 'link'">
              <span>{{ childrenItem.title | translate }}</span>
              <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
            </a>
            <!-- External Link -->
            <a class="submenu-title" href="{{ !childrenItem.type ? null : childrenItem.path }}" *ngIf="childrenItem.type === 'extLink'"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <span>{{ childrenItem.title | translate }}</span>
              <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
            </a>
            <!-- External Tab Link -->
            <a class="submenu-title" href="{{ !childrenItem.type ? null : childrenItem.path }}" target="_blank"
              *ngIf="childrenItem.type === 'extTabLink'">
              <span>{{ childrenItem.title | translate }}</span>
              <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
            </a>
            <!-- 3rd Level Menu -->
            <ul class="nav-sub-childmenu submenu-content" *ngIf="childrenItem.children"
              [ngClass]="{active: childrenItem.active}"
              [ngClass]="{'menu-open': childrenItem.active, 'menu-close': !childrenItem.active }"
              [style.display]="childrenItem.active ? 'block' : 'none'">
              <li *ngFor="let childrenSubItem of childrenItem.children" [ngClass]="{active: childrenSubItem.active}">
                <!-- badge -->
                <label class="badge badge-sub-sub" [ngClass]="{ 'badge-light-primary': !childrenSubItem.active, 'badge-dark-primary': childrenSubItem.active }" *ngIf="isFinancial && childrenSubItem.badgesCount">{{ childrenSubItem.badgeValue }}</label>
                <!-- Link -->
                <a (click)="goToPage(childrenSubItem.path)"
                  *ngIf="childrenSubItem.type === 'link'">
                  <span> {{ childrenSubItem.title | translate }}</span>
                </a>
                <!-- External Link -->
                <a href="{{ !childrenSubItem.type ? null : childrenSubItem.path }}"
                  *ngIf="childrenSubItem.type === 'extLink'" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}">
                  <span>{{ childrenSubItem.title | translate }}</span>
                </a>
                <!-- External Tab Link -->
                <a href="{{ !childrenSubItem.type ? null : childrenSubItem.path }}" target="_blank"
                  *ngIf="childrenSubItem.type === 'extTabLink'">
                  <span>{{ childrenSubItem.title | translate }}</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <div class="right-arrow" id="right-arrow" [class.d-none]="rightArrowNone" (click)="scrollToRight()">
    <app-feather-icons [icon]="'arrow-right'"></app-feather-icons>
  </div>
</nav>